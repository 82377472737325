<template>
    <iq-card class="mt-3">
        <template v-slot:headerTitle>
            <b-spinner small type="grow" v-if="loader"></b-spinner>
            <h4 class="card-title" v-if="!loader">{{ chart.title }}</h4>
        </template>
        <template v-slot:headerAction>
            <b-form v-if="showDates">
                <b-row class="mt-3">
                    <b-col md="6">
                        <b-form-group label="From" class="row mr-4" label-cols-sm="2" label-for="from">
                            <b-form-input id="from" type="date" v-model="form_dates.from" :max="form_dates.to"
                                :value="form_dates.from" @change="getStats($event)"></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col md="6">
                        <b-form-group label="To" class="row mr-4" label-cols-sm="2" label-for="to">
                            <b-form-input id="to" type="date" v-model="form_dates.to" :min="form_dates.from"
                                :max="form_dates.max" :value="form_dates.to" @change="getStats($event)"></b-form-input>
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-form>

            <ul class="nav nav-pills">
                <li v-for="(item, index) in tabsPeriod" :key="index" class="nav-item">
                    <a href="#" class="nav-link" :class="item.default ? 'active' : ''"
                        @click="getStatsByPeriod($event, item.code)">
                        {{ item.name }}
                    </a>
                </li>
            </ul>
        </template>
        <template v-slot:body>
            <div class="dashboard">
                <b-spinner small type="grow" v-if="loader"></b-spinner>
                <div v-if="!loader">

                    <h3 class="">
                        Total:
                        <span class="cursor-pointer text-primary" v-b-tooltip.hover title="Total">{{
                            moneyDefaultFormat(totalSum) }}</span> / <span class="cursor-pointer text-success"
                            v-b-tooltip.hover title="Total over consumption">{{ moneyDefaultFormat(totalOverSum)
                            }}</span>
                    </h3>

                    <b-row class="mt-4" style="overflow: auto; max-height: 220px;">
                        <b-col v-for="(item, index) in indicators" :key="index" md="2" role="toolbar"
                            class="btn-toolbar w-100 px-0 my-2" aria-label="">
                            <div role="group" class="mr-0 btn-group btn-group-sm col-md-12">
                                <button type="button" class="btn btn-secondary indicator-show" v-b-tooltip v-b-tooltip.hover :title="item.title.length >= 25 ? item.title : ''"
                                    :style="'background-color: ' + chart.bodyData.colors[index]+'; border-color: ' + chart.bodyData.colors[index]+'; border-radius: 5px 5px 0 0'">
                                    {{ item.title | filterLessThan(25) }}
                                </button>
                            </div>
                            <div role="group" class="mr-0 btn-group btn-group-sm col-md-12">
                                <span v-b-tooltip.hover title="Total" class="counter cursor-pointer font-weight-bolder col-md-6"
                                    :style="'color: ' + chart.bodyData.colors[index] + '; border: .5px solid ' + chart.bodyData.colors[index]+'; border-radius: 0 0 0 5px'">
                                    {{ moneyDefaultFormat(parseFloat(item.indicator)) }}
                                </span>
                                <span v-b-tooltip.hover title="Overconsumption" class="counter cursor-pointer font-weight-bolder col-md-6"
                                    :style="'color: ' + chart.bodyData.colors[index] + '; border: .5px solid ' + chart.bodyData.colors[index]+'; border-radius: 0 0 5px 0'">
                                    {{ moneyDefaultFormat(parseFloat(item.overconsumption)) }}
                                </span>
                            </div>


                        </b-col>
                    </b-row>
                </div>

                <div v-if="!loader">
                    <ApexChart v-if="!loader" :element="slug" :chartOption="chart.bodyData" />
                </div>
            </div>
        </template>
    </iq-card>
</template>

<script>

const EVENTS = 5;
const REVENUE_STATS = 6;

const DAY_PERIOD = 0;
const WEEK_PERIOD = 1;
const MONTH_PERIOD = 2;
const YEAR_PERIOD = 3;

import api from '@/api/RestClient'
import Multiselect from "vue-multiselect"
import _ from 'lodash'
import moment from 'moment'
import { helper } from '@/helpers'
import Vue from 'vue'


Vue.filter('filterLessThan', function (value, max = 20) {
    return helper.showMaxCharacters(value, max)
})

export default {
    name: 'CustomKpis',
    props: {
        options: {
            type: [Object],
            default: null
        }
    },
    components: {
        Multiselect,
    },
    mounted() {
        this.setOption();
        this.initIntervalDates();
        this.getStats();
    },
    data() {
        return {
            chart: {
                title: '***',
                type: 'mixes',
                dot: 1,
                bodyData: {
                    chart: {
                        height: 350,
                        type: 'line',
                        stacked: false
                    },
                    stroke: {
                        width: [3, 3, 3, 3, 3, 3, 3, 3, 3],
                        curve: 'smooth'
                    },
                    plotOptions: {
                        bar: {
                            columnWidth: '50%'
                        }
                    },
                    colors: ['#00ca00', '#0084ff', '#FEB019', '#5A2A27', '#FD6A6A', '#662E9B', '#D7263D', '#C5D86D', '#4CAF50'],
                    series: [],
                    fill: {
                        opacity: [0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5],
                        gradient: {
                            inverseColors: false,
                            shade: 'light',
                            type: 'vertical',
                            opacityFrom: 0.85,
                            opacityTo: 0.55,
                            stops: [0, 100, 100, 100]
                        }
                    },
                    labels: [],
                    markers: {
                        size: 0
                    },
                    xaxis: {
                        type: 'category',
                        interval: 4,
                        tickAmount: 10
                    },
                    yaxis: {
                        min: 0,
                        max: 25,
                        tickAmount: 9
                    },
                    tooltip: {
                        shared: true,
                        intersect: false,
                        y: {
                            formatter: function (y) {
                                if (typeof y !== 'undefined') {
                                    return y.toFixed(0)
                                }
                                return y
                            }
                        },
                        x: {},
                    },
                    legend: {
                        labels: {
                            useSeriesColors: true
                        },
                        markers: {
                            customHTML: [
                                function () {
                                    return ''
                                },
                                function () {
                                    return ''
                                },
                                function () {
                                    return ''
                                }
                            ]
                        }
                    }
                }
            },
            indicators: [],
            title: '***',
            slug: null,
            loader: true,
            labels: [],
            tabsPeriod: [
                { code: DAY_PERIOD, name: 'Days', format: 'YYYY-MM-DD', offset: 14, default: true },
                { code: WEEK_PERIOD, name: 'Weeks', format: 'YYYY-MM-DD', offset: 10 },
                { code: MONTH_PERIOD, name: 'Months', format: 'YYYY-MM', offset: 12 },
                { code: YEAR_PERIOD, name: 'Years', format: 'YYYY', offset: 5 },
            ],
            form_dates: {
                from: null,
                to: null,
                max: null,
            },
            periodCode: -1,
            option: null,
            statsData: null,
            organization: null,
            organizations: [],
            showDates: false,
            totalSum: 0,
            totalOverSum: 0
        }
    },
    methods: {
        moneyDefaultFormat(amount) {
            return amount.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        },
        loadSeries() {

            if (this.statsData) {
                this.statsData.series.forEach(serie => {
                    this.chart.bodyData.series.push(serie)
                });
            }
        },
        loadIndicators() {
            if (this.statsData) {
                this.indicators = this.statsData.indicators;
            }
        },
        setTitle() {
            if (this.option) {
                this.chart.title = this.option.title;
            }
        },
        setXaxisType() {
            if (this.option) {
                this.chart.bodyData.xaxis = {
                    type: this.option.axis_type
                }
            }
        },
        chartElement() {
            if (this.option) {
                this.slug = this.option.element;
                this.chart.bodyData.yaxis = {
                    min: 0,
                    max: this.getMaxSerie(this.chart.bodyData.series),
                    tickAmount: 5,
                }
            }
        },
        setLabel() {
            if (this.statsData) {
                this.chart.bodyData.labels = this.statsData.labels;
                let length = this.chart.bodyData.labels.length;
                let from = this.chart.bodyData.labels[0];
                let to = this.chart.bodyData.labels[length - 1];
                this.chart.title = `Revenues from ${from} to ${to}`;
            }

        },
        setOption() {
            if (this.options) {
                this.option = this.options
            }
            const that = this
            if (typeof this.options.exceptPeriod !== "undefined") {
                this.tabsPeriod = _.filter(that.tabsPeriod, function (o) { return !that.options.exceptPeriod.includes(o.code); });
            }
        },
        setPeriodCode(val = 0) {
            this.periodCode = val;
        },
        getStatsByPeriod(event, period = 0) {
            event.preventDefault()
            this.showDates = [DAY_PERIOD, WEEK_PERIOD].includes(period) ? true : false
            let elements = this.$el.querySelectorAll('a.nav-link')
            elements.forEach((noeud) => {
                noeud.classList.remove('active')
            });
            event.target.classList.add('active')
            this.setPeriodCode(period)
        },
        getStats() {
            this.loader = true;
            this.resetData();

            const period = this.option.period;
            this.showDates = [DAY_PERIOD, WEEK_PERIOD].includes(period) ? true : false

            let currentPeriod = _.find(this.tabsPeriod, function (o) { return period == o.code; });

            let format = currentPeriod ? currentPeriod.format : 'YYYY-MM-DD';
            let comparator = currentPeriod ? this.getPeriodNameSingular(currentPeriod.name) : 'day';

            this.getChartData()
                .then(response => {

                    this.statsData = this.getChartDataFormatted(response, format, comparator)

                }).catch(err => {
                    console.log(err)
                }).finally(() => {
                    this.loader = false;
                });
        },
        getPeriodNameSingular(str, singular = true) {
            if (typeof str == "undefined") return null;

            if (str.charAt(str.length - 1) == 's') str = str.slice(0, str.length - 1);
            return singular ? str.toLowerCase() : str;
        },
        getChartDataFormatted(response, format, comparator) {

            let chartData = {
                indicators: [],
                series: [],
                labels: [],
            }

            if (typeof response.data === "undefined") {
                return chartData
            }

            const data = response.data;
            let startDate = moment(data.period.from, format);
            let endDate = moment(data.period.to, format);

            chartData.labels = this.getLabelsFromRangeDates(startDate, endDate, format, `${comparator}s`);

            let totalSum = 0;
            let totalOverSum = 0;
            let totalIndicator = 0;
            let totalTaxGst = 0;
            let totalTaxQst = 0;
            let totalRefund = 0;
            data.items.forEach(item => {
                totalIndicator = 0;
                totalTaxGst = 0;
                totalTaxQst = 0;
                totalRefund = 0;

                let graphItems = _.filter(data.data, function (o) { return item.id == o.plan_id; }); 

                let totalRevenues = this.getSumItemsByAttr(graphItems);

                totalTaxGst = totalTaxGst + this.getSumItemsByAttr(graphItems, 'total_tax_gst')
                totalTaxQst = totalTaxQst + this.getSumItemsByAttr(graphItems, 'total_tax_qst')
                totalRefund = totalRefund + this.getSumItemsByAttr(graphItems, 'total_refund')
               
                totalIndicator = totalRevenues - totalTaxGst - totalTaxQst - totalRefund;
                let indicatorPlan = { title: item.name, indicator: `${totalIndicator}`, overconsumption: `${this.getSumItemsByAttr(graphItems, 'total_overconsumption')}` }

                totalSum += totalRevenues;
                totalOverSum += this.getSumItemsByAttr(graphItems, 'total_overconsumption');

                chartData.indicators.push(indicatorPlan)

                let seriePlan = {
                    name: item.name,
                    type: `area`,
                    data: this.getDataChartByItemsAndLabels(chartData.labels, graphItems, comparator)
                }
                chartData.series.push(seriePlan)
            });

            let dataTotal = [];

            chartData.labels.forEach((label, i) => {
                let total = 0
                chartData.series.forEach((serie, j) => {
                    total = total + parseFloat(serie.data[i])
                });

                dataTotal[i] = total
            });

            let serieTotal = {
                name: 'Total.**',
                type: `area`,
                data: dataTotal
            }
            chartData.series.push(serieTotal)

            // #Total
            this.totalSum = totalSum;
            
            //Total overSum
            this.totalOverSum = totalOverSum;

            // Update X tooltip
            this.chart.bodyData.tooltip.x = {
                formatter: function (x, series, labels = chartData.labels) {
                    return labels[series.dataPointIndex]
                }
            }

            if (comparator == 'week') {
                chartData.labels = this.getLabelsFromRangeDates(startDate, endDate, '[Week ]WW-YYYY', `${comparator}s`);
            }
            return chartData
        },
        getDataChartByItemsAndLabels(labels, items = [], comparator = 'day') {
            let data = [];
            labels.forEach(label => {
                let graphItems = _.filter(items, function (o) { return moment(o.date).isSame(label, comparator); });
                let total_revenue = this.getSumItemsByAttr(graphItems);
                let total_tax_gst = this.getSumItemsByAttr(graphItems, 'total_tax_gst');
                let total_tax_qst = this.getSumItemsByAttr(graphItems, 'total_tax_qst');
                let total_refund = this.getSumItemsByAttr(graphItems, 'total_refund');
                let total = total_revenue - total_tax_gst - total_tax_qst - total_refund;
                data.push(total)
            });
            return data;
        },
        getSumItemsByAttr(items, attr = 'total') {
            const sum = items.reduce((accumulator, object) => {
                if (!Object.hasOwn(object, attr)) {
                    return accumulator + 0;
                }
                return accumulator + object[attr];
            }, 0);
            return sum;
        },
        getLabelsFromRangeDates(startDate, endDate, format = 'YYYY-MM-DD', interval = 'days') {

            let now = startDate.clone(), dates = [];

            while (now.isSameOrBefore(endDate)) {
                dates.push(now.format(format));
                now.add(1, interval);
            }

            return dates;
        },
        getChartData() {
            const account = this.organization ? '&account=' + this.organization.value : '';
            const link = this.option.url + '/' + this.option.period + `?from=${this.form_dates.from}&to=${this.form_dates.to}` + account
            return api.dashboard.statistics(link);
        },
        resetData() {
            this.chart.bodyData.series = [];
            this.chart.bodyData.labels = [];
        },
        arrayMax(arr) {
            let len = arr.length,
                max = -Infinity;
            while (len--) {
                if (Number(arr[len]) > max) {
                    max = Number(arr[len]);
                }
            }
            return max;
        },
        getMaxSerie(series = []) {
            if (!series) return 0;
            let elts = [];
            series.forEach(serie => {
                elts.push(this.arrayMax(serie.data))
            });
            return this.arrayMax(elts);
        },

        initIntervalDates() {
            let date = moment();
            let minDate = date.clone();

            const period = this.option.period;
            let currentPeriod = _.find(this.tabsPeriod, function (o) { return period == o.code; });

            minDate.subtract(currentPeriod.offset, currentPeriod.name.toLowerCase())

            this.form_dates.to = date.format('YYYY-MM-DD');
            this.form_dates.from = minDate.format('YYYY-MM-DD');
            this.form_dates.max = date.format('YYYY-MM-DD');
        },
    },
    watch: {
        'statsData': {
            handler(newValue, oldValue) {
                this.setXaxisType();
                this.loadSeries();
                this.loadIndicators();
                this.setTitle();
                this.chartElement();
                this.setLabel();
            }
        },
        'periodCode': {
            handler(newValue, oldValue) {
                this.option.period = newValue;
                this.resetData();
                this.initIntervalDates();
                this.getStats();
            }
        },
        'organization': {
            handler(newValue, oldValue) {
                this.resetData();
                this.getStats();
            }
        }
    },
}
</script>

<style>
.dashboard {
    text-align: center;
    margin-bottom: 5px;
}

.libolo {
    width: 50%;
    margin: auto;
}

.overconsumption {
    font-size: 0.8rem;
}

.cursor-pointer {
    cursor:pointer;
}

@media screen and (max-width: 600px) {
    .price-week-box {
        margin-right: 0.5rem !important;
    }

    .libolo {
        width: 90% !important;
    }
}
</style>
