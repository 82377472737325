<template>
    <b-container fluid>
        <b-row>
            <b-form-group class="col-md-12 my-0 px-0" label-for="selectcountry">
                <multiselect ref="account-ref" :loading="isLoading" :disabled="isLoading" tag-placeholder="Manage account"
                    placeholder="Select Organization" label="name" track-by="code" v-model="account" :options="accounts"
                    :multiple="true" :taggable="true" @input="onSelect">
                </multiselect>
            </b-form-group>
        </b-row>
    </b-container>
</template>

<script>
import Multiselect from 'vue-multiselect'

import api from '@/api/RestClient'

export default {
    props: {
        _discounts: {
            type: Array,
            default: ()=>[]
        }
    },
    name: 'AccountsMultiselect',
    components: {
        Multiselect,
    },
    data() {
        return {
            isLoading: true,
            account: null,
            accounts: [],
        }
    },
    mounted() {
        this.getDiscounts()
        this.initDiscounts();
    },
    methods: {
        initDiscounts() {
             //console.log(this._discounts);
        },
        getDiscounts() {
            this.isLoading = true;
            var accounts = [];
            api.discounts.list()
                .then(response => {
                    let discounts = response.data;
                    discounts.data.forEach((org) => {
                        let account = {};
                        account.name = org.name;
                        account.code = org.id;
                        accounts.push(account);
                    });
                   
                })
                .catch(err => {
                    console.log(err)
                })
                .finally(() => {
                    setTimeout(() => {
                        this.accounts = accounts;
                        this.isLoading = false;
                        this.$emit('onComplete', this.accounts)
                        if (this._discounts.length > 0) {
                            this.account = this._discounts;
                            console.log(this._discounts);
                        }
                    }, 1000)
                })
        },
        onSelect(selectedOption, id) {
            this.$emit('onSelect', selectedOption)
        }
    },
    watch: {
    },
}
</script>
