<template>
<div>
    <b-row>
        <b-col lg="12">
            <iq-card>
                <template v-slot:headerTitle>
                    <h4 class="card-title">Commissions timeline[{{ affiliate }}]</h4>
                </template> 
                <template v-slot:headerAction>
                    <span class="text-primary cursor-pointer-ek" @click="all">View All <b-spinner small label="Small Spinner" type="grow" v-show="spinner"></b-spinner> </span>
                </template>

                <template v-slot:body>
                    <TimeLine :items="timelineItems" v-show="haveHistories" />
                    <p class="text-center" v-show="!haveHistories">No data founded</p>
                </template>
            </iq-card>
        </b-col>
    </b-row>

</div>
</template>

<script>
import api from '@/api/RestClient'
export default {
    props: {
        commissions: {
            type: Array,
            default: () => []
        },
        affiliate: {
            type: String,
            default:'Eric'
        }
    },
    data() {
        return {
            form: {
                email: '',
                name: '',
                food: null,
                checked: []
            },
            integration_columns: [
                'active',
                'limit',
                'time_limit',
                'state',
                'recurrence',
                'deleted_at',
                'removed_or_disabled_at'
            ],
            show: true,
            timelineItems: [],
            COLORS: [
                'primary',
                'secondary',
                'success',
                'danger',
                'warning',
                'info',
                'dark '
            ],
            LOGS_LIMIT: 6,
            spinner: false,
            haveHistories: false,
            dataLogs: [],
            logs: []
        }
    },
    mounted() {
        this.mergePaymentWithInvoiceLogs();
        this.addTimeLineItems();
        this.addOverFlowClass();
        window.component = this;
    },
    created() {

    },
    methods: {
        colorsByActions(action) {
            let color = 'info'
            switch (action) {
                case 'create':
                    color = 'success'
                    break
                case 'update':
                    color = 'primary'
                    break
                case 'delete':
                    color = 'danger'
                    break
                case 'paid':
                    color = 'default'
                    break
            }
            return color;
        },
        subArray(array = [], start = 0, end = 6) {
            if (!end) {
                end = -1;
            }
            return array.slice(start, end);
        },
        addOverFlowClass() {
            if (this.logs.length > this.LOGS_LIMIT) {
                $('.iq-timeline').parent().addClass('iq-overflow');
            }
        },
        timeLineDescription(item) {
            let label = item.action + ' successfully';
            return `The Integration ${label}`
        },
        getMessage(columns, item) {
            let message = '';
            switch (item.action) {
                case 'update':
                    this.integration_columns.forEach(column => {
                        if (column == 'active' && typeof columns[column] !== 'undefined') {
                            if (columns[column] == -1) message += 'The integration is removed, '
                            else if (columns[column] == 0) message += 'The integration is disabled, '
                            else if (columns[column] == 1) message += 'The integration is enabled, '
                        }
                        if (column == 'limit' && typeof columns[column] !== 'undefined') {
                            //limit per day
                            column = 'limit'

                            if (columns[column] != -1) {
                                message += `The integration limit per day is on ${columns[column]}, `
                            } else message += `The integration have not limit per day, `;
                        }
                        if (column == 'time_limit' && typeof columns[column] !== 'undefined') {
                            // time limitt
                            column = 'time_limit'
                            let time = JSON.parse(columns[column]);
                            let time_to = time['time_limit_to'];
                            let time_from = time['time_limit_from'];
                            if (time_to != null && time_from != null) message += `the events start from ${time_from}  to ${time_to}  , `
                            else message += 'The integration have not time limit, '
                        }
                        if (column == 'recurrence' && typeof columns[column] !== 'undefined') {
                            //recurrence
                            column = 'recurrence'
                            message += `The integration recurrence is now on ${columns[column]}, `
                        }
                    });
                    break;
                case 'restore':
                    message = 'The integration is restored, '
                    break;
                case 'delete':
                    message = `The integration is removed but can be restored, `
                    break;
                case 'create':
                    this.integration_columns.forEach(column => {
                        message += columns && typeof columns[column] !== 'undefined' ? `The ${column} updated with value ${columns[column]}, ` : ``
                    });

                    break;
                default:
                    message = `***, `;
                    break;
            }
            return message;
        },
        formatColumnAction(item) {
            let log_detail = '';
            let columns = JSON.parse(item.columns);
            log_detail = columns ? this.getMessage(columns, item) : ''
            return log_detail;
        },
        timeLineIntegrationColumnsAction(item) {
            let log_detail = this.formatColumnAction(item);
            let collapse = ` <a href='#' class='log_detail'>More <i class="fa fa-angle-down"></i></a> <div style='display: none' class='mt-2'>${log_detail}</div > `

            return collapse;
        },
        addTimeLineItems() {
            var moment = require('moment');
            let i = 0;
            this.spinner = false;
            this.haveHistories = false;
            if (this.dataLogs.length > 0) this.haveHistories = true;
            this.logs = _.orderBy(this.logs, ['created_at'], ['desc'])
            this.logs.forEach(item => {
                let description = `${item.note}`;
                let collapse = item.file_link ? 
                `<div class="cursor-pointer mt-2 download_attachment" data-commission="${item.id}">
                    <i class="ri-download-line"></i> Attachment
                    <span class="spinner-grow spinner-grow-sm d-none" role="status">
                        <span class="sr-only">Loading...</span>
                    </span>
                </div>`: `<div class="mt-2"><i class="fa fa-ban iq-bg-danger"></i></div>`;

                description = `${description} ${collapse}`
                let title = `$${item.total}`;
                var date = new Date(item.created_at);
                let right = moment(date).format('YYYY-MM-DD HH:mm:ss') + ``;
                let line = {
                    color: this.colorsByActions('update'),
                    title: title,
                    right: right,
                    description: description,
                    child: {
                        type: 'img',
                        items: []
                    }
                }
                this.timelineItems.push(line);
                i++;
            });
            let component = this;
            setTimeout(function () {
                let that = this;
                $('.download_attachment').on('click', function (e) {
                    e.preventDefault();
                    $('.download_attachment').find('span').addClass('d-none');
                    $(this).find('span').removeClass('d-none');
                    let commissionId = $(this).attr('data-commission');
                    api.commissions.download(commissionId).then(response => {
                        var fileURL = window.URL.createObjectURL(new Blob([response]));
                         console.log(fileURL);
                        var fileLink = document.createElement('a');
                        var fileName = 'commission-' + Date.now() + '.pdf';

                        fileLink.href = fileURL;
                        fileLink.setAttribute('download', fileName);
                        document.body.appendChild(fileLink);

                        fileLink.click();
                    }).catch(err => {
                        console.log(err);
                        that.$bvToast.toast('Something went wrong', {
                            title: 'Warning',
                            variant: 'warning'
                        });
                    }).finally(() => {
                        $(this).find('span').addClass('d-none');
                    })
                })
            }, 1500)

        },
        formatDateYMD(date) {
            setT
            var d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2)
                month = '0' + month;
            if (day.length < 2)
                day = '0' + day;

            return [year, month, day].join('-');
        },
        all() {
            this.spinner = true;
            setTimeout(() => {
                this.timelineItems = []
                this.logs = [...this.dataLogs];
                this.addTimeLineItems();
                this.addOverFlowClass();
                this.spinner = false;
            }, 2000);
        },
        mergePaymentWithInvoiceLogs() {
            let payment = {
                action: 'paid',
                status: 1,
                completed_by: '',
                type: 'invoice',
                created_at: '',
                id: ''
            };
            this.dataLogs = [];
            this.dataLogs = [...this.commissions];
            this.logs = this.subArray(this.dataLogs, 0, this.LOGS_LIMIT);
        }
    },
    destroyed() {
        this.dataLogs = []
        this.timelineItems = [];
    },
    downloadFile(id = -1, elt = null) {
            api.commissions.download(id).then(response => {
                var fileURL = window.URL.createObjectURL(new Blob([response]));
                var fileLink = document.createElement('a');
                var fileName = 'commission-' + Date.now() + '.pdf';

                fileLink.href = fileURL;
                fileLink.setAttribute('download', fileName);
                document.body.appendChild(fileLink);

                fileLink.click();
            }).catch(err => {
                console.log(err);
                this.$bvToast.toast('Something went wrong', {
                    title: 'Warning',
                    variant: 'warning'
                });
            }).finally(() => {
                 if(elt != null) $(elt).find('span').removeClass('d-none');
            })
        }
}
</script>

<style>
.cursor-pointer-ek {
    cursor: pointer;
}

.iq-overflow {
    overflow-y: scroll;
    height: 50em;
}
</style>
