<template>
    <span v-if="state" 
        class="badge cursor-pointer badge-pill" 
        :class="check.class ?? 'iq-bg-success'">
        {{ check.label ?? 'Checked' }}</span>

    <span v-else 
        class="badge cursor-pointer badge-pill" 
        :class="unCheck.class ?? 'iq-bg-secondary'">{{ unCheck.label ?? 'UnChecked' }}</span>
</template>

<script>

export default {
    name: 'StateAttrCell',
    props: ['row', 'attribute', 'check', 'unCheck'],
    data() {
        return {
            state: null,
        }
    },
    mounted() {
        this.getState();
    },
    methods: {
        getState() {
            const that = this
            let currCode = this.row.item[this.row.field.key]
            let state = currCode ?? false;
            if (state) {
                this.state = state
            }
        }
    }
}
</script>
