<template>
  <div>


    <iq-card>
        <template v-slot:headerTitle >
          <div style="display: flex; justify-content: space-between;">
            <h4 class="card-title"><i class="ri-mail-check-line"></i> ActiveCampaign Settings </h4>
          </div>
          </template>

          <template v-slot:body>
            <b-form @submit="onSubmit">
             <b-row class="mb-2"> 
              <b-col>
                <label for="">Account ID</label>
                <b-input class="form-control" placeholder="Account ID" v-model="form.account"></b-input>
              </b-col>
              <b-col>
                <label for="">Token</label>
                <b-input class="form-control" placeholder="Key token" v-model="form.token"></b-input></b-col>
            </b-row>
             <b-row class="my-3">
              <b-col>
                <label for="">Signup List ID</label>
              <b-input class="form-control" placeholder="list" v-model="form.reception_list"></b-input></b-col></b-row>
             <b-row class="my-2"><b-col>
              <label for="">Enter Tag ID</label>
              <b-input class="form-control" placeholder="enter tag" v-model="form.enter_tag"></b-input></b-col>
             <b-col>
              <label for="">Signed up tag ID</label>
              <b-input class="form-control" placeholder="signed up tag" v-model="form.signed_up_tag"></b-input>
            </b-col>
            <b-col>
              <label for="">Create First Integration</label>
              <b-input class="form-control" placeholder="create integration tag" v-model="form.create_integration"></b-input>
            </b-col>
            <b-col>
              <label for="">Subscribe to a plan</label>
              <b-input class="form-control" placeholder="Subscribe to plan tag" v-model="form.subscribe_plan"></b-input>
            </b-col>
          </b-row>
            

            <b-row class="text-right" style="justify-content: end;padding: 15px;">
              <b-button type="submit" variant="primary">update</b-button>

            </b-row>

            </b-form>
          </template>
    </iq-card>


  </div>
</template>

<script>
import Vue from 'vue'
import Multiselect from "vue-multiselect"
import Spinner1 from '@/components/loaders/spinner1'
import api from '@/api/RestClient'

export default {

  name: "ActiveCampaginSettings",
  components: {
    Spinner1,
    Multiselect
  },
  mounted() {
    this.fetchActiveCampaignDetails()
    this.announcement_status = this.announcement_status;

  
  },
  data() {
    return {
      loading: true,
      announcement_status:false,
      steps:[],
      form : {
        account:null,
        token:null,
        reception_list:null,
        enter_tag:null,
        signed_up_tag:null,
        create_integration:null,
        subscribe_plan:null
      },
      types: [
        'info',
        'warning', 
        'danger',
      ],
      current_step_index : null,
      onsubmit:false,

    }
    }
    ,
    methods :{
      fetchActiveCampaignDetails(){
        api.settings.fetchActiveCampaign().then((response)=>{
         
          this.form.account = response.data.account_id;
          this.form.token = response.data.token;
          this.form.reception_list = response.data.reception_list;
          this.form.enter_tag = response.data.enter_tag;
          this.form.signed_up_tag = response.data.signed_up_tag;
          this.form.create_integration = response.data.create_integration;
          this.form.subscribe_plan = response.data.subscribe_plan;
        })
      },
      updateAnnounceStatus(){
       
      },
      onSubmit(event) {
        event.preventDefault()
        api.settings.updateActiveCampagin(this.form).then(response=>{
          console.log(response);
          if(response.data.success){

            let swal = {
              icon: 'success',
              title: 'Announcement details updated successfully',
              text: 'details updated successfully',
              type: 'success',
              confirmButtonText: 'OK',
              showCloseButton: true,
            }

            api.settings.fetchActiveCampaign()
            
            .then((response)=>{
              
              if(response.data.success){

                this.form.account = response.data.account_id;
                this.form.token = response.data.token;
                this.form.reception_list = response.data.reception_list;
                this.form.enter_tag = response.data.enter_tag;
                this.form.signed_up_tag = response.data.signed_up_tag;
                this.form.create_integration = response.data.create_integration;
                this.form.subscribe_plan = response.data.subscribe_plan;

              }
            }).catch((response)=>{
             //
            });
            
            this.$swal(swal)
          }
        })
      },

    }
}

</script>