<template>
    <div>
        <b-form @submit="onSubmit" v-if="show">
            <div style="display: flex; flex-direction: row-reverse;">
                <b-form-checkbox :disabled="!all" v-model="checked" name="check-button" @change="onSwitch" switch class="mb-3 ml-2">
                    Refund all?
                </b-form-checkbox>
            </div>

            <b-form-group id="input-group-1" label="" label-for="input-1"
                description="Please specify the refund amount">
                <b-form-input :disabled="checked" id="input-1" v-model="form.amount" type="text" placeholder="Enter amount"
                    required></b-form-input>
            </b-form-group>
            <b-form-group
              label="Note"
              label-for="textarea-refund"
              description="Add a note that explains the reason for this action"
              class="mb-0"
            >
              <b-form-textarea
                id="textarea-refund"
                v-model="form.note"
                placeholder="Enter your note"
                required></b-form-textarea>
            </b-form-group>
            <b-row>
                <b-col md="6" class="mt-3">
                    <b-form-checkbox v-model="form.useStripe" name="check-button" switch class="mb-3">
                            Process The Refund?
                    </b-form-checkbox>
                </b-col>
                <b-col md="6" class="text-right">
                    <b-button type="submit" variant="primary" class="mt-3">
                        Submit
                        <b-spinner small label="Small Spinner" type="grow" v-if="onSubmitSpinner"></b-spinner>
                    </b-button>
                </b-col>
            </b-row>
        </b-form>
    </div>
</template>

<script>
import api from '@/api/RestClient'
export default {
    name: 'Refund',
    props: ['invoice', 'all'],
    mounted() {
    },
    data() {
        return {
            form: {
                amount: '',
                note: null,
                status: false,
                useStripe: false,
            },
            checked: false,
            show: true,
            onSubmitSpinner: false
        }
    },
    methods: {
        onSubmit(event) {
            event.preventDefault()
            
            let resp = {
                status: 1,
                message: ''
            };
            this.onSubmitSpinner = true;
            this.form.status = this.all;
            api.invoices.refund(this.invoice.id, this.form).then(response => {
                resp.message = response.message
                this.$emit('onRefunded', resp);
            }).catch(err => {
                resp.status = 0;
                resp.message = err.response.data.message
                this.$emit('onRefunded', resp);
            }).finally(() => {
                this.onSubmitSpinner = false
            })
        },
        onSwitch() {
            this.form.amount = 0;
            if (this.checked) this.form.amount = this.invoice.amount;
        }
    }
}
</script>