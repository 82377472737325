<template>
    <div>
        <div v-if="noPaymentMethod" class="bg-danger text-white mb-2 p-3 pb-1 rounded">
            <h3 class="text-white"><i class="ri-error-warning-line"></i> A credit card is needed</h3>
            <p class="pb-0">You do not have a payment method listed. Please <button class="btn btn-danger bg-danger-soft"
                    @click='openModal'>add one</button></p>
        </div>

    </div>
</template>


<script>
import {
    loadStripe
} from '@stripe/stripe-js'

import {
    mapGetters
} from "vuex";

import api from '@/api/RestClient'

export default {
    name: 'PaymentMethodAlert',
    mounted() {
        this.loading = true;
        api.billing.getPaymentMethods()
            .then(resp => {

                let data = resp.data;
                this.loading = false;

                if (data.success && data.data) {
                    this.paymentsMethod = data.data;

                    this.noPaymentMethod = !this.paymentsMethod.length > 0;
                } else {


                    this.noPaymentMethod = true;
                }


            })
            .catch(error => {
                if (error.response) {
                    if (error.response.status == 401) {
                        this.$store.dispatch('Auth/resetUser', false);
                        this.$router.go(this.$router.currentRoute)
                    } else if (error.response.status == 500) {

                        this.$bvToast.toast('something went wrong!', {
                            title: 'Error',
                            variant: 'danger'
                        });
                    }

                    this.$bvToast.toast('something went wrong!', {
                        title: 'Error',
                        variant: 'danger'
                    })
                }
            });

    },
    data() {
        return {
            modal: false,
            errors: [],
            fails: false,
            paymentsMethod: [],
            address: {},
            success: false,
            loading: true,
            noPaymentMethod: false,
            countries: []
        }
    },
    methods: {

        openModal() {
            this.$emit('openModal');
        },

    },
    computed: {
        ...mapGetters('Auth', ['user'])
    },
}

</script>

<style>
.bg-danger-soft {
  background-color: #ff6666;
}
.bg-danger-soft:hover {
  background-color: #c82333
}
</style>