<template>
    <multiselect v-model="value" tag-placeholder="Empty" :allow-empty="false" deselect-label="Can't remove this value"
        placeholder="Select platform" label="name" track-by="code" :options="platforms" :multiple="false" :taggable="true"
        @input="emitInput">
        <template slot="singleLabel" slot-scope="props">
            <img class="option__image img-responsive mx-2" :src="getImageURL(props.option)" alt="Platform image"
                :width="logoWidth">
            <span class="option__desc">
                <span class="option__title">{{ platformName(props.option.name) }}</span>
            </span>
        </template>

        <template slot="option" slot-scope="props">
            <div class="option__desc">
                <img class="option__image img-responsive mx-2" :src="getImageURL(props.option)" alt="Platform label image"
                    :width="logoWidth">
                <span class="option__title">{{ platformName(props.option.name) }}</span>
            </div>
        </template>
    </multiselect>
</template>

<script>
import Multiselect from 'vue-multiselect'
import { PLATFORMS_ITEMS } from "@/constantes";
import { helper } from '@/helpers';

export default {
    name: 'PlatformsSelect',
    components: {
        Multiselect,
    },
    props: {
        platform: {
            type: [Object, String],
            default: '',
        },
        disabled: {
            type: [Boolean],
            default: false,
        },
    },
    data() {
        return {
            isLoading: true,
            value: null,
            platforms: [],
            logoWidth: 28,
        }
    },
    created() {
        this.loadPlatform()
    },
    mounted() {

        if (this.disabled) {
            this.$refs['main'].$el.querySelector('div.multiselect__select').classList.add('d-none')
        }
    },
    methods: {
        loadPlatform() {
            this.platforms = this.platformsSorted
            let platform = null
            const that = this
            if (typeof this.platform == 'string') {
                platform = _.find(that.platforms, function (o) { return o.name == that.platform; });
            } else if (typeof this.platform == 'object') {
                platform = this.platform
            }

            if (platform) this.value = platform
        },
        platformName(name) {
            return helper.platformFormatName(name);
        },
        getImageURL(option) {
            try {
                return require(`@/assets/images/platforms/${option.img}`)
            } catch (e) {
                return require(`@/assets/images/platforms/default.png`)
            }
        },
        emitInput(selectedOption) {
            this.$emit('input', selectedOption)
        }
    },
    computed: {
        platformsSorted() {
            return _.sortBy(PLATFORMS_ITEMS, ['name', 'code']);
        }
    },
    watch: {
    },
}
</script>
