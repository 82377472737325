export default function (axios) {
  return {
    list: (params = {}) => {
      return axios.get(`/lo_daily_limit_auto_updates`, { params })
        .then(response => response.data);
    },
    get: (id = null) => {
      let url = id ? `/lo_daily_limit_auto_updates/${id}` : `/lo_daily_limit_auto_updates`
      return axios.get(url)
        .then(response => response.data);
    },
    post: (data = {}) => {
      return axios.post('/lo_daily_limit_auto_updates', data)
        .then(response => response.data);
    },
    put: (id, data) => {
      return axios.put(`/lo_daily_limit_auto_updates/${id}`, data)
        .then(response => response.data)
    },
    delete: async (id) => {
      return axios.delete(`/lo_daily_limit_auto_updates/${id}`)
        .then(response => response.data)
    },
    getNames: (params = {}) => {
      return axios.get(`/lo_daily_limit_auto_updates/get-names`, { params })
        .then(response => response.data);
    },
  }
}
