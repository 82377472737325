import Qs from 'qs';

export default function (axios) {
  return {
    lists: (params = {}) => {
      return axios.get(`validations-emails/lists`, { params })
        .then(response => response.data);
    },
    import: (params = {}) => {
      return axios.post(`validations-emails/import`, params, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
        .then(response => response.data);
    },
    generateCsv: (list_id) => {
      return axios.get(`validations-emails/lists/${list_id}/generateCsv`, { responseType: 'blob' })
        .then(response => response.data);
    },
    downloadCsv: (file) => {
      return axios.get(`validations-emails/download/${file}`, { responseType: 'blob' })
        .then(response => response.data);
    },
    downloadSampleCsv: (url) => {
      return axios.get(url, { responseType: 'blob' })
        .then(response => response.data);
    },
  }
}
