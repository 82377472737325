<template>
  <div>
    <div v-if="!isAccountActive" class="row p-4">
          <div class="col-md-12">
            <b-alert :show="true" variant="danger" class="bg-white">
              <div class="iq-alert-icon">
                <i class="ri-information-line"></i>
              </div>
              <div class="iq-alert-text">Your Account is Currently <b>Inactive</b>.</div>
            </b-alert>
          </div>
        </div>
  </div>
</template>



<script>


export default {
  name: 'ConsumptionBar',

  props: [
    'service_id',
    'isAccountActive'
  ],

  mounted() {

    if ( !this.isAccountActive) {
      helper.alertAccountInactive(this.$swal)
    }

  },
 

  data() {
    return { }
  },
  methods: {

  }
}
</script>
