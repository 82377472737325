<template>
<div>
    <img src="/img/img-success.c151c48f.png" width="80" alt="">
    <h1 class="mt-3 mb-0">Success !</h1>
    <p>Thank you for your registration, you can now connect to the platform.</p>
    <div class="d-inline-block w-100">

        <a class="btn btn-primary mt-3" href="/"><i class="ri-home-4-line"></i>Go To Dashboard</a>
    </div>
</div>
</template>

  
<script>
export default {
    name: 'ConfirmMail1',
    data() {
        return {
            email: ''
        }
    },
    mounted() {
        window.email = this.$route.query.email;
        if(this.$route.query.via) this.rewardfulTracking();
    },
    methods: {
        rewardfulTracking() {
            console.log(window.email);
            rewardful('ready', function () {
                rewardful('convert', {
                    email: window.email
                });
            });
        }
    }
}
</script>
