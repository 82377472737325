import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue'
import { VuejsDatatableFactory } from 'vuejs-datatable'
import App from './App.vue'
import VueTour from 'vue-tour'
// import './registerServiceWorker'
import router from './router'
import store from './store/index'
import _ from 'lodash'
import HighchartsVue from 'highcharts-vue'
import Raphael from 'raphael/raphael'
import VueScrollProgressBar from '@guillaumebriday/vue-scroll-progress-bar'
import VueSweetalert2 from 'vue-sweetalert2';
import './plugins/analytics';

// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';
import VueNativeNotification from 'vue-native-notification'

import configureMomentTimezone from './plugins/moment-timezone'; 

global.Raphael = Raphael

const components = require.context('./components/sofbox')
_.forEach(components.keys(), (fileName) => {
  const componentConfig = components(fileName)
  const componentName = fileName.split('/').pop().split('.')[0]
  Vue.component(componentName, componentConfig.default || componentConfig)
})
require('vue-tour/dist/vue-tour.css')

Vue.filter('reverse', function (value) {
  // slice to make a copy of array, then reverse the copy
  return value.slice().reverse()
})
Vue.use(HighchartsVue)

Vue.use(VueScrollProgressBar)

Vue.config.productionTip = false

Vue.use(VuejsDatatableFactory)

Vue.use(VueSweetalert2);

Vue.use(VueTour);


Vue.use(VueNativeNotification, {
  requestOnNotify: true
})

async function initMomentTimezone() { 
  await configureMomentTimezone(); 
}

initMomentTimezone();

let vm = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

window.vm = vm
