<template>
    <b-container fluid>
        <b-row>
            <b-col lg="12">
                <iq-card body-class="p-0">
                    <template v-slot:body>
                        <div class="iq-edit-list">
                            <tab-nav :pills="true" class="iq-edit-profile d-flex">
                                <tab-nav-items class="p-0" :active="true" href="#personal-information"
                                    title="Personal Information" :class="{
                                        'col-md-6': (isAdmin || (user.id == item.id)), 'col-md-12': (!isAdmin && (user.id != item.id))
                                    }" />
                                <tab-nav-items v-if="isAdmin || user.id == item.id" class="col-md-6 p-0" :active="false"
                                    href="#chang-pwd" title=" Change Password" />
                            </tab-nav>
                        </div>
                    </template>
                </iq-card>
            </b-col>
            <b-col lg="12">
                <div class="iq-edit-list-data">
                    <tab-content id="pills-tabContent-2">
                        <tab-content-item :active="true" id="personal-information">
                            <iq-card>
                                <template v-slot:headerTitle>
                                    <h4 class="card-title">Personal Information</h4>
                                </template>
                                <template v-slot:body>
                                    <b-row>
                                        <b-form-group class="col-md-12 my-auto d-flex justify-content-end">
                                            <div
                                                class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color">
                                                <div class="custom-switch-inner">
                                                    <label class="mr-2" for="label-status">Status</label>
                                                    <input type="checkbox" class="custom-control-input bg-primary"
                                                        name="label-status" v-model="form.status" id="label-status"
                                                        :disabled="!isAdmin && (user.account.user_id == item.id)">
                                                    <label class="custom-control-label" for="label-status"
                                                        data-on-label="On" data-off-label="Off">
                                                    </label>
                                                </div>
                                            </div>
                                        </b-form-group>
                                    </b-row>
                                    <div class="new-user-info">
                                        <b-form @submit="update">
                                            <b-row>
                                                <b-form-group class="col-md-6" label="Name: *" label-for="name">
                                                    <b-form-input id="name" placeholder="Enter Name" v-model="form.name"
                                                        required></b-form-input>
                                                    <div class="text-danger" id="full_name">{{ errors.full_name }}</div>
                                                </b-form-group>
                                                <b-form-group class="col-md-6" label="Email: *" label-for="email">
                                                    <b-form-input type="email" id="email" placeholder="Email"
                                                        v-model="form.email" required :disabled="!isAdmin">
                                                    </b-form-input>
                                                    <div class="text-danger" id="email_address">{{
                                                        errors.email_address
                                                    }}
                                                    </div>
                                                </b-form-group>
                                                <b-form-group v-if="hasAccount()" class="col-sm-12" label-for="Organization"
                                                    label="Organization:">
                                                    <multiselect v-model="form.account" id="account"
                                                        tag-placeholder="Add as new Organization"
                                                        placeholder="Select Organization or Create One" label="name"
                                                        track-by="code" :options="organizations" :multiple="false"
                                                        :taggable="true" @input="" @tag="handleAddAccount" @search-change=""
                                                        :disabled="!isAdmin">
                                                    </multiselect>
                                                    <div class="text-danger" id="organization">{{ errors.organization }}
                                                    </div>
                                                </b-form-group>

                                                <b-form-group v-if="isAdmin" class="col-sm-12" label-for="selectcountry"
                                                    label="Role:">

                                                    <multiselect v-model="form.roles" tag-placeholder="Add this as new role"
                                                        placeholder="Select platform" label="name" track-by="code"
                                                        :options="roles" :multiple="true" :taggable="true"
                                                        :aria-required="true" @input="onRoleChange" @remove="manageAccounts" @select="manageAccounts">
                                                    </multiselect>
                                                    <div class="text-danger" id="roles">{{ errors.roles }}</div>

                                                </b-form-group>

                                                <b-form-group v-if="canManageAccount" class="col-sm-12" label-for="Accounts"
                                                        label="Accounts:">
                                                    <multiselect v-model="form.accounts" tag-placeholder="Add as new Accounts"
                                                        placeholder="Select Accounts" label="name" track-by="code"
                                                        :loading= "isLoadManagerAccounts" :options="organizations" :multiple="true" :taggable="true" :disabled="!isAdmin">
                                                    </multiselect>
                                                    
                                                    <div class="text-danger" id="organization">{{ errors.accounts }}</div>
                                                </b-form-group>
                                                
                                                <b-col sm="12" lg="12" v-if="isAdmin">
                                                    <div class="form-group">
                                                        <label for="address">Address</label>
                                                        <b-form-textarea id="address" placeholder="Enter Address" rows="6"
                                                            v-model="form.address">
                                                        </b-form-textarea>
                                                    </div>
                                                </b-col>
                                            </b-row>
                                            <b-button class="float-right" variant="primary" type="submit"
                                                :disabled="onsubmit">
                                                Edit user
                                                <b-spinner small type="grow" v-show="onsubmit"></b-spinner>
                                            </b-button>
                                        </b-form>
                                    </div>
                                </template>
                            </iq-card>
                        </tab-content-item>
                        <tab-content-item v-if="isAdmin || user.id == item.id" :active="false" id="chang-pwd">
                            <iq-card>
                                <template v-slot:headerTitle>
                                    <h4 class="card-title">Change Password</h4>
                                </template>
                                <template v-slot:body>
                                    <b-form @submit="reset">
                                        <b-form-group>
                                            <label for="npass">New Password:</label>
                                            <b-form-input type="password" id="npass" placeholder="Password"
                                                v-model="pass.password" required>
                                            </b-form-input>
                                            <div class="text-danger" id="password">{{ errors.password }}</div>
                                        </b-form-group>
                                        <b-form-group>
                                            <label for="vpass">Verify Password:</label>
                                            <b-form-input type="password" id="vpass" placeholder="Repeat Password"
                                                v-model="pass.password_confirmation" required></b-form-input>
                                            <div class="text-danger" id="password">{{ errors.repeat_password }}</div>
                                        </b-form-group>
                                        <b-button variant="primary" type="submit" :disabled="change">
                                            Change password
                                            <b-spinner small type="grow" v-show="change"></b-spinner>
                                        </b-button>
                                    </b-form>
                                </template>
                            </iq-card>
                        </tab-content-item>
                    </tab-content>
                </div>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import {
    sofbox
} from '@/config/pluginInit'
import Multiselect from "vue-multiselect"
import api from '@/api/RestClient';
import {
    helper
} from '@/helpers'
import { mapGetters } from 'vuex'

export default {
    name: 'EditUser',
    components: {
        Multiselect,
    },
    props: {
        item: {
            type: [Object],
            default: () => null,
        },
    },
    mounted() {
        sofbox.index()
        this.getRoles();
        this.getAccounts();
        this.setUserId();
        this.getUser();
    },
    data() {
        return {
            onsubmit: false,
            change: false,
            user_id: null,
            form: {
                name: null,
                email: null,
                account: null,
                roles: null,
                address: null,
                accounts: [],
                status: 0
            },
            pass: {
                password: null,
                password_confirmation: null,
            },
            roles: [],
            organizations: [],
            errors: {},
            accounts: [],
            canManageAccount: false,
            isLoadManagerAccounts: true
        }
    },
    methods: {
        update(e) {
            e.preventDefault();
            const organization = this.form.account
            if (this.form.account?.name) {
                this.form.account = this.form.account?.name
            } else {
                this.form.account = null
            }

            this.checkUpdateForm();
            let itemUpdate = null;
            if (helper.isEmpty(this.errors)) {
                if (this.user_id) {
                    this.onsubmit = true;
                    const user_id = this.user_id;
                    const data = this.form;
                    api.users.put(user_id, data).then(response => {
                        this.onsubmit = false;
                        let variant = 'danger';
                        let message = 'Something went wrong'
                        if ('error' in response) {
                            const keys = Object.keys(response.error);
                            keys.forEach(key => {
                                message = response.error[key][0];
                                this.toast('User', variant, message);
                            });
                        }
                        if ('success' in response && response.success) {
                            variant = 'success';
                            message = response.message;
                            itemUpdate = response.data;
                            this.$swal('Update User', message, 'success')
                                .then((result) => {
                                    this.$emit('modalHide', itemUpdate)
                                })
                        }
                    }).catch(err => {

                        this.$swal('Add User', err.response.data.message, 'error')
                    }).finally(() => {
                        this.form.account = organization;
                        this.onsubmit = false;
                    })
                } else {
                    this.toast('Warning', 'danger', 'Ensure user exists please');
                }
            }
        },
        reset(e) {
            e.preventDefault();
            this.checkPassWordForm();
            let itemUpdate = null;
            let toastObj = { solid: true }

            if (helper.isEmpty(this.errors)) {
                if (this.user_id) {
                    this.change = true;
                    const user_id = this.user_id;
                    const data = this.pass;
                    api.auth.adminResetPass(user_id, data).then(response => {
                        this.change = false;
                        let variant = 'danger';
                        let message = 'Something went wrong'
                        if ('error' in response) {
                            const keys = Object.keys(response.error);
                            keys.forEach(key => {
                                message = response.error[key][0];
                                this.toast('Password', variant, message);
                            });
                        }
                        if ('success' in response && response.success) {
                            variant = 'success';
                            message = response.message;
                            this.toast('Password', variant, message);
                        }
                        toastObj.title = 'Password Update'
                        toastObj.variant = variant
                        toastObj.message = message
                    }).catch(err => {
                        this.toast('Success!', 'success', response.message)
                    }).catch(err => {
                        this.toast('Oh!', 'danger', 'Something went wrong')
                    })
                        .finally(() => {
                            this.change = false;
                            this.$emit('modalHide', toastObj)
                        })
                } else {
                    this.toast('Warning', 'danger', 'Ensure user exists please');
                }
            }
        },
        logout() {
            api.auth.logout().then(response => {

                this.$bvToast.toast(response.message, {
                    title: response.error ? 'Warning' : 'Success',
                    variant: response.error ? 'warning' : 'success'
                });
                this.$store.dispatch('Auth/updateLoggedIn', false);
                setTimeout(() => {
                    window.location.href = '/';
                }, 2500, this);
            }).catch(err => {
                this.$bvToast.toast('Something went wrong', {
                    title: 'Error!',
                    variant: 'danger'
                });
            }).finally(() => { })

        },
        getRoles() {
            api.roles.all().then(response => {
                this.roles = response.data;
            }).catch(err => {
                console.log(err);
            }).finally(() => {
                // here code ...
            })
        },
        getUser() {
            var user = null;

            if (this.user_id) {
                api.users.get(this.user_id).then(response => {
                    user = response.data;
                    this.form.name = user.name;
                    this.form.email = user.email;
                    let account = user.account ? _.find(this.organizations, function (o) { return o.id == user.account.id }) : null;
                    this.form.account = account;
                    this.form.address = user?.profile?.address;
                    this.form.status = user.status;
                    let roles = [];
                    user.roles.forEach(role => {
                        roles.push({
                            code: role.id,
                            name: role.name
                        })
                    });
                    this.form.roles = roles;
                }).catch(err => {
                    this.toast('Warning', 'danger', 'Something went wrong');
                }).finally(() => {
                    if (!this.form.account && user?.account) {
                        this.getAccount(user?.account.id)
                    }
                })
            } else {
                this.toast('Warning', 'danger', 'Ensure user exists please');
            }

        },
        getAccount(id = null) {
            if (!id) return;

            this.onsubmit = true;
            // this.form.account = null
            api.accounts.get(id)
                .then(response => {
                    if (response.success) {
                        let selected = { code: response.data.id, name: response.data.name };
                        this.organizations.push(selected)
                        this.form.account = selected
                    }
                })
                .catch(err => {
                    this.toast('Oh!', 'danger', 'Something went wrong')
                })
                .finally(() => {
                    this.onsubmit = false;
                })
        },
        setUserId() {
            this.user_id = this.item.id;
        },
        resetErrors() {
            this.errors = {}
        },
        checkUpdateForm() {
            this.resetErrors();
            if (!this.form.name) {
                this.errors.full_name = 'Name required.';
            }
            if (!this.form.email) {
                this.errors.email_address = 'Email required.';
            }
            if (!this.form.account && this.hasAccount()) {
                this.errors.organization = 'Organization required.';
            }
            if (this.form.roles.length == 0) {
                this.errors.roles = 'Role(s) requiered';
            }
            if (!helper.validMail(this.form.email)) {
                this.errors.email_address = 'Email format did not matched.';
            }

            if(this.canManageAccount && !this.form.accounts.length) {
                this.errors.accounts = 'Select Accounts can\'t be empty';
            }
        },
        checkPassWordForm() {
            this.resetErrors();
            if (!this.pass.password) {
                this.errors.password = 'Password required.';
            }
            if (!this.pass.password_confirmation) {
                this.errors.repeat_password = 'Repeat password required.';
            }
            if (!helper.equal(this.pass.password, this.pass.password_confirmation)) {
                this.errors.repeat_password = 'Password did not matched';
            }
            if (helper.checkStringLegth(this.pass.password, 6)) {
                this.errors.password = 'Password must be at least 6 characters';
            }
        },
        toast(title = "success", variant = "success", message = "Account chargerd") {
            this.$bvToast.toast(message, {
                title: title,
                variant: variant
            })
        },
        getAccounts() {
            this.onsubmit = true;
            // this.form.account = null
            api.accounts.all({per_page: -1}).then(response => {
                this.organizations = _.map(response.data, function(o) {
                    return {code: o.id, name: o.name}
                });

            }).catch(err => {
                this.toast('Oh!', 'danger', 'Something went wrong')
            })
                .finally(() => {
                    this.onsubmit = false;
                })
        },
        handleAddAccount(data) {
            this.onsubmit = true;

            if (!data || data == '') {
                this.toast('Oh!', 'danger', 'Something went wrong')
            }

            api.accounts.post({ name: data }).then(response => {
                if (response.success) {
                    let newAccount = { code: response.data.id, name: response.data.name };
                    this.organizations.push(newAccount)
                    this.form.account = newAccount
                }
            }).catch(err => {
                this.toast('Oh!', 'danger', 'Something went wrong')
            })
                .finally(() => {
                    this.onsubmit = false;
                })
        },
        onRoleChange(roles) {
            this.form.accountOn = false
            this.manageAccounts();
            const that = this
            _.forEach(roles, function (role) {
                if (['Client'].includes(role.name)) {
                    that.form.accountOn = true
                }
            });
        },
        manageAccounts() {  
            const ACCOUNT_MANAGER = 'accounts manager';
            const CLIENT = 'client';
            
            let roleClient = _.filter(this.form.roles, function (r) {
                return typeof r.name !== 'undefined' && r.name.toLowerCase() === CLIENT
            });

            let roleAccountsManager = _.filter(this.form.roles, function (r) {
                return typeof r.name !== 'undefined' &&  r.name.toLowerCase() === ACCOUNT_MANAGER
            });

            if (!_.isEmpty(roleAccountsManager) && _.isEmpty(roleClient)) {
                this.form.account = null;
                this.getManagerAccounts();
            } else {
                this.accounts = [];
                this.form.accounts = [];
                this.canManageAccount = false;
            }
        },
        getManagerAccounts() {
            this.isLoadManagerAccounts = true;
            api.accounts.manager(this.form.email)
                .then(response => {
                    this.form.accounts = _.map(response.data, function(o) {
                        return {code: o.id, name: o.name}
                    })
                })
                .catch(err => {
                    console.log(err);
                })
                .finally(() => {
                    this.onsubmit = false;
                    this.canManageAccount = true;
                    this.isLoadManagerAccounts = false;
                })
        },
        hasAccount() {
            const CLIENT = 'client';
            
            let roles = _.filter(this.form.roles, function (r) {
                return r.name.toLowerCase() === CLIENT
            });
            
            if (!_.isEmpty(roles)) {
                return true;
            }

            return false
        }
    },
    computed: {
        ...mapGetters({
            isAdmin: 'Auth/isAdmin',
            isAccounting: 'Auth/isAccounting',
            isManager: 'Auth/isManager',
            isClient: 'Auth/isClient',
            user: 'Auth/user',
            isOwnerAccount: 'Auth/isOwnerAccount',
        })
    },
    watch: {
        "form.roles": {
            handler(olVal, newVal) {
                this.manageAccounts();
            }
        }

    }
}
</script>
