<template>
    <span v-if="row.item.status == 1" class="badge cursor-pointer iq-bg-success badge-pill">Active</span>
    <span v-else-if="row.item.status == 0" class="badge cursor-pointer iq-bg-danger badge-pill">Inactive</span>
    <span v-else class="badge cursor-pointer iq-bg-secondary badge-pill">Deleted</span>
</template>

<script>

export default {
    name: 'StatusCell',
    props: ['row'],
}
</script>
