<template>
  <!-- START Balances Card  -->
  <div>
    <iq-card class="p-4">

      <template v-if="account.name" v-slot:headerTitle>
          <h4 class="card-title">Balances</h4>
          <p  >account : {{ account.name || ''}}</p>
      </template>

      <div class="p-4">
        <!-- START FILTER OPTIONS ROW  -->
        <b-row class="my-2" align-h="between">

          <!-- START Sow Option Input -->
          <b-col md="2" >
            <b-form-group
              label-for="per-page-select"
              label-align-sm="left"
          
              class="mb-0"
            >
              <b-form-select
                id="per-page-select"
                v-model="perPage"
                :options="pageOptions"
              
              ></b-form-select>
            </b-form-group>
          </b-col>
          <!-- END Sow Option Input -->


          <!-- START Filter Input -->
          <b-col md="4">
            <b-form-group

              
              class="mb-0"
            >
              <b-input-group >
                <b-form-input
                  id="filter-input"
                  v-model="filter"
                  type="search"
                  placeholder="Type to Search"
                ></b-form-input>

              </b-input-group>
            </b-form-group>
          </b-col>
          <!-- END Filter Input -->

        </b-row>
        <!-- END FILTER OPTIONS ROW  -->


        <b-table
          borderless
          hover
          :items="balances"
          :fields="fields"
          :current-page="currentPage"
          :per-page="perPage"
          :filter="filter"
          :filter-included-fields="filterOn"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :busy="loading.table"
          stacked="md"
          show-empty
          @filtered="onFiltered" >   

          <template #table-busy>
            <div class="text-center p-4 d-flex justify-content-center align-items-center">
              <b-spinner variant="primary"> </b-spinner>
              <span class="text-primary p-2"><small> Fetching balances, please wait...</small></span>
            </div>
          </template>

          <template #cell(limit)="row">

            <span v-if="parseInt(row.item.limit) == -1" class="text-primary p-2"><small> unlimited</small></span>
           
            <span v-else> {{ row.item.limit  }}</span>
          </template>

          <template #cell(ends_at)="row">

            <span v-if="row.item.ends_at==null"> <small class="badge badge-secondary">no end date</small></span>
            <span v-else> {{ row.item.ends_at  }}</span>
          </template>

          <template #cell(service_id)="row">
              <span > {{ row.item.service.name  }}</span>
            </template>

          <template #cell(actions)="row">

            <b-button v-b-modal.modal-log-balance    variant="bg-white text-muted border mr-1 mb-1" size="sm" @click="update(row.item)" ><i class="ri-history-line  m-0"></i></b-button>
            <b-button v-b-modal.modal-edit-balance   variant=" iq-bg-info mr-1 mb-1" size="sm" @click="update(row.item)" ><i class="ri-ball-pen-fill m-0"></i></b-button>
            <b-button v-b-modal.modal-add-balance    variant=" iq-bg-success mr-1 mb-1" size="sm" @click="update(row.item)" ><i class="ri-add-fill m-0"></i></b-button>
            <b-button v-b-modal.modal-remove-balance variant=" iq-bg-danger mr-1 mb-1" size="sm" @click="update(row.item)"><i class="ri-subtract-fill m-0"></i></b-button>

          </template>

        
        </b-table>


        <!-- START PAGINATION TABLE-->
        <b-row align-h="end">

          <b-col md="3" class="my-1">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="fill"
              size="sm"
              class="my-0"
            ></b-pagination>
          </b-col>
        </b-row>
        <!-- END PAGINATION TABLE-->

      </div>


      </iq-card>

      <!----  Modals   ---->

      <AddBalanceModal 
                        :balance="selected_balance" 
                        :account="account" 
                        @balanceAdded="fetchBalances"/>

      <RemoveBalanceModal 
                          :balance="selected_balance" 
                          :account="account" 
                          @balanceRemoved="fetchBalances"/>

      <UpdateLimitBalanceModal 
                          :balance="selected_balance" 
                          :account="account" 
                          @balanceLimitUpdated="fetchBalances" />

      <BalanceLogModal  :updateLogs="updateLogs"
                        :balance="selected_balance" 
                        :account="account" />

  </div>
  <!-- START Balances Card  -->

</template>


<script>
import { sofbox } from '../../../config/pluginInit'
import api from '@/api/RestClient';
import AddBalanceModal from './modals/AddBalanceModal.vue';
import RemoveBalanceModal from './modals/RemoveBalanceModal.vue';
import UpdateLimitBalanceModal from './modals/UpdateLimitBalanceModal.vue'
import BalanceLogModal from './modals/BalanceLogModal.vue'
var moment = require('moment'); 


export default {
  name:'account-balances',
  props: ['account'],
  components: {

    AddBalanceModal,
    RemoveBalanceModal,
    BalanceLogModal,
    UpdateLimitBalanceModal

  },
  mounted(){
    this.fetchBalances();
  },
  data(){
    return {
      balance_id : null,
      balances:[],
      loading:{
        modal: false,
        table: false
      },
      is_unlimited:false,
      selected_balance:null,
      // Table data : 
      fields: [
          { key: 'service_id' , label: 'Service', sortable: true, sortDirection: 'desc' },
          { key: 'balance'    , label: 'Balance', sortable: true, class: 'text-center' },
          { key: 'consumption', label: 'Consumption', sortable: true, class: 'text-center' },
          { key: 'ends_at'    , label: 'Ends at', sortable: true, class: 'text-center' },
          { key: 'limit'      , label: 'Limit', sortable: true, class: 'text-center' },
          { key: 'actions'    , label: 'Actions' ,class: 'text-center'}
      ],

      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      updateLogs:false
    
        
    }
  },
  methods : {
    formSubmit(e){

      e.preventDefault();

      this.loading.modal = true;

      const account_id = this.account.id;
      const data       = this.formData;

      if( this.is_unlimited ) 
      {
       
        data.limit = -1;
      }

      api.balances.update(account_id,this.balance_id,data).then(response => {

          
          let variant = 'danger';
          let message = 'Something went wrong'

          if ('success' in response) {

              variant = response.success ? 'success' : 'danger';
              message = response.message;
              this.toast('account', variant, message);

              if (response.success){

                  this.$refs['balance'].hide();

                  this.loading.table   = true;

                  this.fetchBalances();
                  
              }
          }

          this.loading.modal = false;


      }).catch( (error) => {
    
          this.toast('Balance', 'error', error.response.data.message);
          this.loading.modal = false;
      });


    },
    update(balance){
      this.selected_balance = balance;


    },
    toast(title = "success", variant = "success", message = "Organization updated successfully") {
      this.$bvToast.toast(message, {
          title: title,
          variant: variant
      })
    },
    fetchBalances(){

      this.loading.table = true;


        api.balances.list(this.account.id).then(response=>{


          this.loading.table = false;

          this.balances =  response.data.balances.map((balance)=>{
            balance.ends_at = (balance.ends_at) ? moment(balance.ends_at).format('YYYY-MM-DD') : null;
            return balance;
          });

          this.updateLogs = !this.updateLogs ;

          this.totalRows = response.data.balances.length;
        }).catch(error =>{
          console.log(error);
          this.loading.table = false;
          this.balances  = [];
        });
    },
    // table : 
    
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    }
  },
  watch: { 
    account: function(newVal, oldVal) { // watch it
      this.fetchBalances();
    }
  }
  
}
</script>

<style scoped>
.table-hover tbody tr:hover {
    color: #212529;
    background-color: rgb(171 216 225 / 15%);
}
</style>