<template>
    <fieldset>
        <legend></legend>

        <div class="form-card text-left">
            <b-row>
                <b-col class="my-auto" md="12" v-show="!toggleIntegration">
                    <b-alert class="bg-white w-100" :show="true" :variant="isConnected ? 'success' : 'secondary'">
                        <div class="iq-alert-text w-100">
                            <h3 v-if="title" class="alert-heading">
                                <i class="ri-information-line fa-lg"></i> {{ title }}
                            </h3>
                            <p>
                                Your Automation has been successfully added.
                                <br>
                                See Automation Details
                            </p>
                            <hr>

                            <b-row class="mb-0">
                                <b-form-group class="col-md-6" label="Source" label-for="source">
                                    <multiselect v-model="form.source" :value="form.source" placeholder="Select the source"
                                        label="name" track-by="code" :options="form.sourceOptions" :multiple="false"
                                        :taggable="false" :disabled="true">

                                        <template slot="option" slot-scope="props">
                                            {{ props.option.name }}
                                        </template>

                                        <template slot="singleLabel" slot-scope="props">
                                            {{ props.option.name }}
                                        </template>

                                    </multiselect>
                                </b-form-group>

                                <b-form-group class="col-md-6" label="Automation Name" label-for="name">
                                    <b-form-input type="text" placeholder="Automation Name" v-model="form.name"
                                        readonly="readonly"></b-form-input>
                                </b-form-group>
                            </b-row>

                            <b-row
                                v-if="form.platform && ![platform_codes.INBOXSUITE, platform_codes.WEBHOOK].includes(form.platform.code)"
                                class="mb-0">

                                <b-form-group class="col-md-6" label="Platform Selected" label-for="platform">
                                    <span v-if="!form.platform" class="badge badge-light mx-2">Not Defined</span>
                                    <span v-else class="badge badge-light mx-1 my-auto">
                                        <span v-if="getPlatformImage(form.platform)" class="mx-2">
                                            <img style="width: 1.3em;" :src="getPlatformImage(form.platform)">
                                        </span>

                                        <span class="mx-2">{{ form.platform.nickname }}</span>
                                        <span v-if="!form.platform.nickname" class="mx-2">{{
                                            platformName(form.platform.name)
                                        }}</span>

                                    </span>
                                </b-form-group>
                                <b-form-group class="col-md-6" :label="list_selected_label" label-for="lists">
                                    <multiselect v-model="form.list" value="form.list" tag-placeholder="Empty"
                                        :placeholder="'listPlaceholder'" label="name" track-by="code" :options="[]"
                                        :multiple="true" return="code" :allow-empty="true" :taggable="false"
                                        :disabled="true">


                                        <template slot="option" slot-scope="props">
                                            <div class="option__desc">

                                                <span class="option__title">

                                                    <span v-if="props.option.type">

                                                        <i v-if="props.option.type == 'segment'" class="ri-flashlight-line"
                                                            style="color:#5ad"></i>
                                                        <i v-else class="ri-list-check color-primary"
                                                            style="color:#5ad"></i>

                                                    </span>

                                                    <span v-else><i class="ri-list-check color-primary"
                                                            style="color:#5ad"></i></span>

                                                    <span style="font-wight:bold;"> {{ props.option.name }} </span>

                                                    <span style="font-size:11px; color:#888"> {{ props.option.code }}</span>


                                                </span>

                                            </div>

                                        </template>


                                    </multiselect>
                                </b-form-group>

                            </b-row>
                            <b-row v-else class="mb-0">
                                Recap WebHook-InboxSuite Comming Soon
                            </b-row>


                            <b-row class="mb-0">

                                <b-form-group v-show="form.recordState && form.recordState.length" class="col"
                                    label="Active States Triggers" label-for="record-state">
                                    <multiselect v-model="form.recordState" :value="form.recordState"
                                        placeholder="Select record states" label="name" track-by="code"
                                        :options="form.cleanOptions" :multiple="true" :taggable="false" :disabled="true">
                                    </multiselect>
                                </b-form-group>

                                <b-form-group v-show="form.action" class="col"
                                    label="Active Actions Triggers" label-for="actions">
                                    <multiselect v-model="form.action" :value="form.action"
                                        placeholder="Select record states" label="name" track-by="code"
                                        :options="form.actions" :multiple="true" :taggable="false" :disabled="true">
                                    </multiselect>
                                </b-form-group>

                                <b-form-group v-show="form.timeSpent" class="col" label="" label-for="time-spent">
                                    <label>
                                        Time Spent between
                                    </label>
                                    <div class="row">
                                        <b-col md="6" class="">
                                                <b-form-input type="text" :value="form.timeSpentFrom" :disabled="true"></b-form-input>
                                        </b-col>
                                        <b-col md="6" class="">
                                            <b-form-input type="text" :value="form.timeSpentTo" :disabled="true"></b-form-input>
                                        </b-col>
                                    </div>

                                </b-form-group>

                                <b-form-group v-show="form.has_recurrent" class="col" label="Recurrent" label-for="recurrent">
                                  <b-form-input v-model="form.recurrent_interval" readonly></b-form-input>
                                </b-form-group>
                            </b-row>

                        </div>
                    </b-alert>
                </b-col>
            </b-row>

        </div>

    </fieldset>
</template>
<script>
import Multiselect from "vue-multiselect"
import { helper } from '@/helpers';
import api from '@/api/RestClient'
import { PLATFORMS_CODE } from "@/constantes";
import moment from 'moment'

export default {
    name: "StepRecap",
    props: ['form', 'errors', 'title', 'automation'],
    components: {
        Multiselect
    },
    data() {
        return {
            script: null,
            suppScript: null,
            toggleIntegration: false,
            useSuppression: false,
            isLoading: false,
            isConnected: false,
            suppIsConnected: false,
            urlScript: '',
            suppUrlScript: '',
            checkIsLoading: false,
            checkSuppIsLoading: false,
            scriptErrors: {},
            platform_codes: PLATFORMS_CODE,
            list_selected_label: "Lists Selected"
        }
    },
    mounted() {
        let script = helper.generateScript(this.form.code);
        this.script = script.innerHTML;
        this.$emit('onFinish', this.form);
        let suppScript = helper.generateSuppressionScript(this.form.code);
        this.suppScript = suppScript.innerHTML;
        let platformName = this.form.platform.name.toLowerCase();
        let isTagPlatForm = ['ontraport', 'convertkit', 'keap', 'gohighlevel'].includes(platformName);
        this.list_selected_label = isTagPlatForm ? 'Tags Selected' : 'Lists Selected';
    },
    methods: {
        copyScript(e) {
            e.preventDefault();
            let script = helper.generateScript(this.form.code);
            this.script = script.innerHTML;

            if (this.script) {
                navigator.clipboard.writeText(this.script)
                this.$bvToast.toast('Script Copied', {
                    title: 'Copy Script',
                    variant: 'info'
                })
            }
        },
        copySuppScript(e) {
            e.preventDefault();
            let script = helper.generateSuppressionScript(this.form.code);
            this.suppScript = script.innerHTML;

            if (this.suppScript) {
                navigator.clipboard.writeText(this.suppScript)
                this.$bvToast.toast('Suppression Script Copied', {
                    title: 'Copy Suppression Script',
                    variant: 'info'
                })
            }
        },
        checkInstallation($event, isSupp = false) {
            $event.preventDefault();
            this.scriptErrors = {};

            if (this.form.id) {
                // this.isLoading = true;
                let params = {};
                let url = this.setCheckingType(isSupp);

                // Check url is valid
                if (url) {

                    if (!helper.isValidURL(url.trim())) {

                        let message = 'Please insert valid URL Website.'
                        if (!isSupp) {
                            this.scriptErrors.scriptWebsite = message;
                        } else {
                            this.scriptErrors.suppScriptWebsite = message;
                        }

                        this.checkIsLoading = false;
                        this.checkSuppIsLoading = false;
                        return;
                    }

                    params.url = url;

                }

                let liveLeadSourceId = this.form.id;

                // Condition for Suppression Script
                if (isSupp) {
                    params.supp = isSupp;
                }

                api.geSources.checkScriptInstallation(liveLeadSourceId, params)
                    .then((response) => {

                        if (response.success) {
                            if (!isSupp) {
                                this.isConnected = true;
                            } else {
                                this.suppIsConnected = true;
                            }

                            this.$swal('Pixel Script Installation', response.message, 'success')
                        }

                    })
                    .catch((error) => {
                        let message = error.response.data.message;

                        if (typeof error.response.data.message !== 'undefined') {
                            message = error.response.data.message;
                        }

                        this.$swal('Pixel Script Installation Error!', message, 'warning')
                    })
                    .finally(() => {
                        helper.delay(2000).then(() => {
                            this.checkIsLoading = false;
                            this.checkSuppIsLoading = false;
                        })
                    })
            }
        },
        setCheckingType(isSupp) {
            let url = null;

            if (!isSupp) {
                this.isConnected = false;
                this.checkIsLoading = true;

                if (this.urlScript) {
                    url = this.urlScript;
                }
            } else {
                this.suppIsConnected = false;
                this.checkSuppIsLoading = true;

                if (this.suppUrlScript) {
                    url = this.suppUrlScript;
                }
            }

            return url;
        },
        getPlatformImage(platform) {
            const imgFileName = platform.name.toLowerCase();
            try {
                return require(`@/assets/images/platforms/${imgFileName}.png`);
            } catch (e) {
                return null;
            }
        },
        platformName(name) {
            return helper.platformFormatName(name);
        },
        isValidTime(time) {

            // this.errors.timeSpent = '';

            let format = 'HH:mm:ss';
            const TIME = moment(time, format);
            const minTime = moment('00:00:00', format);
            const maxTime = moment('00:15:00', format);

            let state = TIME.isBetween(minTime, maxTime, undefined, '[]');

            // if (!state) this.errors.timeSpent = 'You must set a time between 00:00:00 & 00:15:00 minutes';
            return state;

        },
    }
};
</script>
