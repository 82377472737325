<template>
    <b-container fluid>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">Discounts List</h4>
                    </template>

                    <template v-slot:headerAction>
                        <b-button v-b-modal variant="primary" @click="handleAdd($event)" class="text-right mr-1">Add
                            Discount</b-button>
                    </template>

                    <template v-slot:body>
                        <b-row>
                            <b-col lg="12">
                                <DtDiscounts ref="dt-discounts" @handleEdit="handleEdit" />
                            </b-col>
                        </b-row>
                    </template>
                </iq-card>
            </b-col>
        </b-row>

        <!-- Start Add Discount Modal-->
        <b-modal :id="addModal.id" :ref="addModal.id" :title="addModal.title" size="md" centered hide-footer
            class="bg-secondary py-0">
            <AddEditDiscount col=12 :modal="addModal" @modalHide="closeModal(addModal)"></AddEditDiscount>
        </b-modal>
        <!-- END Add Discount Modal-->

        <!-- Start Edit Discount Modal-->
        <b-modal :id="editModal.id" :ref="editModal.id" :title="editModal.title" size="md" centered hide-footer
            class="bg-secondary py-0">
            <AddEditDiscount col=12 :item="editModal.item" :modal="editModal" @modalHide="closeModal(editModal)"></AddEditDiscount>
        </b-modal>
        <!-- END Edit Discount Modal-->
    </b-container>
</template>

    
<script>
import { sofbox } from "@/config/pluginInit";
import api from '@/api/RestClient'
import store from '@/store/index'
import { mapGetters } from 'vuex'
import AddEditDiscount from './children/AddEditDiscount'
import DtDiscounts from './children/DtDiscounts';
import Spinner1 from '@/components/loaders/spinner1'
import _ from 'lodash'

export default {
    name: "DiscountList",
    components: {
        AddEditDiscount,
        DtDiscounts,
        Spinner1,
    },
    created() {
        this.isAdmin = this.admin;
    },
    mounted() {
        sofbox.index();
    },
    data() {
        return {
            isAdmin: false,
            addModal: {
                id: 'modal-add-discount',
                title: 'Add Discount',
                item: {}
            },
            editModal: {
                id: 'modal-edit-discount',
                title: 'Edit Discount',
                item: {}
            },
        };
    },
    computed: {
        ...mapGetters({
            admin: 'Auth/isAdmin',
            client: 'Auth/isClient',
        })
    },
    methods: {
        closeModal(item) {
            this.$root.$emit('bv::refresh::table', this.$refs['dt-discounts'].id)
        },
        handleAdd(event) {
            this.addModal.title = `Add New Discount`
            this.$root.$emit('bv::show::modal', this.addModal.id)
        },
        handleEdit(item) {
            if (!item) {
                return
            }
            this.editModal.title = `Edit Discount [${item?.name}]`
            this.editModal.item = item
            this.$root.$emit('bv::show::modal', this.editModal.id)
        }
    },
    watch: {},
};
</script>

    
<style>
.actionClass {
    width: 18%;
}

.timeClass,
.createdatClass,
.accountClass {
    width: 10%;
}

textarea.form-control {
    line-height: 25px;
}

.cursor-default {
    cursor: default;
}

.table th,
.table td {
    padding: 0.55rem;
    vertical-align: top;
}

.action {
    width: 100%;
    height: 43.5px;
}
</style>
