<template>
    <div>

        <div class="p-4 pt-0 rounded m-3 mt-0 text-center">


            <form @submit.prevent="submit" ref="deletedForm">
                <div class="form-group dash" @dragover="dragover" @dragleave="dragleave" @drop="drop">

                    <h1 style="color: #0084ff;"><i class="fa fa-upload"></i></h1>

                    <h4 v-if="!uploading">Drag & Drop file Here or <label class="text-sm"
                            style="font-size:0.85rem;cursor: pointer;padding: 5px 15px;background: #e5f2ff;border-radius: 5px;color: #19f;"
                            :for="'file' + '-delete'" v-if="!isDragging"> Choose a file </label></h4>
                    <h5 v-else>Uploading...</h5>


                    <input :id="'file' + '-delete'" type="file" @change="handelFileUpload" class="inputfile"
                        ref="file-delete" accept=".csv">


                    <div class="progress py-2" v-show="uploading">
                        <div class="progress-bar" role="progressbar" :aria-valuenow="progress" aria-valuemin="0"
                            aria-valuemax="100">{{ progress }}%</div>
                    </div>

                    <div class="p-2">
                        <span v-if="file_path"><i class="fas fa-file"></i> {{ file_path.name }}</span>
                    </div>


                </div>

                <div v-if="alert_message" class="alert alert-danger" style="background-color: #fcecec42;"> <i
                        class="fas fa-warning m-1"></i>{{ alert_message }}</div>

            </form>


            <div class="alert alert-info px-4" style="color: #0087bb;background-color: #e5faff4d;">
                <i class="fas fa-info m-1"></i> <a href="/documents/webhook_file_example.csv" class="px-1"
                    style="color:#55aadd !important;"> Click here </a> to download our suppression file example template
            </div>

        </div>
    </div>
</template>
<script>

import api from '@/api/RestClient'
import axios from 'axios';
import { PLATFORMS_CODE } from '../../constantes';

export default {

    name: 'RemoveContactsFile',

    props: ['list', 'platform'],

    mounted() {

        this.column = this.col;

        this.list[0] = {
            name: '',
            code: ''
        };

    },
    data: () => ({

        file_path: null,
        uploading: false,
        alert_message: null,
        progress: 0,
        isDragging: false

    }),
    methods: {
        onChange() {
            this.file_path = this.$refs['file-delete'].files[0];
            this.submit();
        },

        dragover(e) {
            e.preventDefault();
            this.isDragging = true;
        },
        dragleave() {
            this.isDragging = false;
        },
        drop(e) {

            e.preventDefault();
            this.$refs['file-delete'].files = e.dataTransfer.files;
            this.onChange();
            this.isDragging = false;
        },

        handelFileUpload(event) {

            this.file_path = event.target.files[0];
            this.submit();
        },
        async submit() {

            this.uploading = true;
            this.alert_message = null;

            let form_data = new FormData();

            const file_type = this.file_path.type;

            if (file_type == "text/csv") {

                form_data.append('list', this.file_path);

                try {

                    let response = null;

                    switch (this.platform.code) {

                        case PLATFORMS_CODE.INBOXSUITE:

                            response = await api.inboxSuitePlatform.removeList(this.list.id, form_data, {

                                onUploadProgress: e => {

                                    this.progress = Math.round(e.loaded * 100 / e.total);
                                }

                            }).then(result => {

                                if (result.data.success) {
                                    this.$swal('Suppression List Added', result.data.message, 'success')
                                        .then((ok) => {

                                            this.successUploadingFile(result.data);

                                        })
                                }

                            });

                            break;

                        case PLATFORMS_CODE.WEBHOOK:

                            response = await api.webHookPlatform.removeList(this.list.id, form_data, {

                                onUploadProgress: e => {

                                    this.progress = Math.round(e.loaded * 100 / e.total);
                                }

                            }).then(result => {

                                if (result.data.success) {
                                    this.$swal('Suppression List Added', result.data.message, 'success')
                                        .then((ok) => {

                                            this.successUploadingFile(result.data);

                                        })
                                }

                            });

                            break;
                        case PLATFORMS_CODE.IBG_ESP:

                            response = await api.inboxgeekPlatform.removeList(form_data, {

                                onUploadProgress: e => {

                                    this.progress = Math.round(e.loaded * 100 / e.total);
                                }

                            });

                            break;
                    }

                    if (response) {

                        if (response.data.success) {

                            if (this.list[0].code != null) {

                                this.list[0].code = response.data.data?.list_id;

                                this.$emit('removedList', this.list);

                            }

                        } else {

                            this.file_path = null;
                            this.alert_message = "Invalid file structure";
                            this.list[0].code = null;
                        }

                    }




                } catch (error) {

                    this.file_path = null;
                    this.alert_message = "Invalid file structure";
                    this.list[0].code = null;

                }
            } else {


                this.file_path = null;
                this.alert_message = "Invalid file format. file must be of type text/csv";
                this.list[0].code = null;
            }



            this.uploading = false;
        },
        successUploadingFile(response) {

            if (response) {

                if (response.success) {

                    if (this.list[0].code != null) {

                        this.list[0].code = response.data?.list_id;

                        if (this.list && this.list.id && response.data) {
                            this.$emit('removedList', response.data);
                        }

                    }

                } else {

                    this.file_path = null;
                    this.alert_message = "Invalid file structure";
                    this.list[0].code = null;
                }

            }
        }
    }
}
</script>



<style scoped>
.dash {

    background: #cfe1ff0f;
    border-radius: 10px;
    padding: 1rem;
    border: 1px dashed #0084ff;

}

.inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.inputfile+label {
    font-size: 1.25em;
    font-weight: 700;
    color: white;
    background-color: black;
    display: inline-block;
}

.inputfile:focus+label,
.inputfile+label:hover {
    background-color: red;
}

.inputfile+label {
    cursor: pointer;
    /* "hand" cursor */
}
</style>