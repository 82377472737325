export default function (axios) {
  return {
    list: (params = {}) => {
      return axios.get(`/discounts`, { params })
        .then(response => response.data);
    },
    get: (discountID) => {
      return axios.get(`/discounts/${discountID}`)
        .then(response => response.data);
    },
    checkCodeAvailable: (discountID, discountCode) => {
      return axios.get(`/discounts/code-available?code=${discountID}&value=${discountCode}`)
        .then(response => response.data);
    },
    post: (discountData = {}) => {
      return axios.post('/discounts', discountData)
        .then(response => response.data);
    },
    put: (discountID, discountData) => {
      return axios.put(`/discounts/${discountID}`, discountData)
        .then(response => response.data)
    },
    delete: (discountID) => {
      return axios.delete(`/discounts/${discountID}`)
        .then(response => response.data)
    },
    restore: (id) => {
      return axios.post(`/discounts/${id}/restore`, {})
        .then(response => response.data);
    },
    apply: (discount_code, subscription_id) => {
      return axios.post(`/discounts/apply-on-subscription`, {
        code: discount_code,
        subscription: subscription_id,
      })
        .then(response => response.data);
    },
  }
}
