export default function (axios) {
  return {
    statistics: (url) => {
      return axios.get(url)
        .then(response => response.data);
    },
    accounts: () => {
      return axios.get(`/accounts/get-accounts-names`)
        .then(response => response.data);
    },
  }
}
