<template>
  <!--  MODAL Add Balances  -->
  <b-row>
         <b-col lg="12" md="12" style="user-select: none;">
             <b-modal  id="subscription-option-modal"  ref="subscription-option-modal" hide-footer>
                  <template #modal-title>
                    Subscription Options
                    <p class="m-0 small">{{ account.name }} | Plan: {{  subscription.plan.name }} </p>
                </template>
                
               <b-form @submit="submit" class="pb-2">

                  <b-row v-for="option in options[subscription?.service_id]" :key="option" align-h="between" class="py-2">
                    <b-col>
                      <span for="">{{ snakeToTitleCase(option) }}</span>
                    </b-col>
                    <b-col class="text-right">
                      <b-form-checkbox switch v-model="formData[option]" size="lg"></b-form-checkbox>
                    </b-col>
                  </b-row>

                  <hr>
                   <div class="text-right">
                       <b-button variant="secondary" size="sm" class="mr-1" @click="$refs['subscription-option-modal'].hide();loading.modal = false;"> Cancel </b-button>
                       <b-button variant="primary" size="sm" type="submit" :disabled="loading.modal"> Apply <b-spinner small type="grow" v-show="loading.modal"></b-spinner></b-button>

                      </div>
                 </b-form>

             </b-modal>
         </b-col>
     </b-row>
     <!-- END MODAL Balances  -->

</template>

<script>
import api from '@/api/RestClient';

export default {

 name:'subscription-option-modal',
 props:['account','subscription'],
 mounted(){
  this.fetchOptionVariables()
 },
 data(){
   return {
      options : [],
     loading: {
       modal:false
     },
     formData : {
       time_limit  : false
     },

   }
 },
 methods : {

    fetchOptionVariables(){
      api.subscriptions.fetchOptionVariables().then((response)=>{
        let current_options = [];
        Object.keys(response.data).forEach(service_id => {

          current_options[service_id] = [];

          Object.keys(response.data[service_id]).forEach(key => {


              current_options[service_id].push(key);

          });

        })


        this.options = current_options;
      })
    },
   submit( event ) {

     event.preventDefault();

     this.loading.modal = true;

     const data       = this.formData;

     api.subscriptions.updateOption(this.subscription.id,data).then(response => {

         
         let variant = 'danger';
         let message = 'Something went wrong'

         if ('success' in response) {

             variant = response.success ? 'success' : 'danger';
             message = response.message;
             this.toast('Subscription Option', variant, message);

             if (response.success){

                 this.$refs['subscription-option-modal'].hide();

                 this.loading.table   = true;
 
             }
         }

         this.loading.modal = false;


     }).catch( (error) => {

         this.toast('Subscription Option', 'danger', error.response.data.message);
         this.loading.modal = false;

     });


   },

   toast(title = "success", variant = "success", message = "Organization updated successfully") {

     this.$bvToast.toast(message, {

         title   : title,
         variant : variant
     })

   },

   snakeToTitleCase(value) {
    if (!value) return ''
            //ref: https://gist.github.com/kkiernan/91298079d34f0f832054
            return value.split('_').map(function(item) {
                return item.charAt(0).toUpperCase() + item.substring(1);
            }).join(' ');
   }

 },
 watch:{
  subscription(new_value,old_value) {


    
    if(new_value.options){

      let options = JSON.parse(new_value.options)

      Object.keys(options).forEach(key => {
        this.formData[key] = options[key];
      });

    }
    

  }
 }
 
}
</script>