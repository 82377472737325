<template>
    <b-container fluid>
        <iq-card>
            <template v-slot:headerTitle>
                <h4 class="card-title">New User Information</h4>
            </template>
            <template v-slot:body>
                <div class="new-user-info">
                    <b-form @submit="create">
                        <b-row>
                            <b-form-group class="col-md-6" label="Name: *" label-for="name">
                                <b-form-input id="name" placeholder="Enter Name" v-model="form.name"
                                    required></b-form-input>
                                <div class="text-danger" id="full_name">{{ errors.full_name }}</div>
                            </b-form-group>
                            <b-form-group class="col-md-6" label="Email: *" label-for="email">
                                <b-form-input type="email" id="email" placeholder="Email" v-model="form.email" required>
                                </b-form-input>
                                <div class="text-danger" id="email_address">{{ errors.email_address }}</div>
                            </b-form-group>
                            <b-form-group v-if="isAdmin" class="col-md-6" label="Password:" label-for="password">
                                <b-form-input type="password" id="password" placeholder="Password"
                                    v-model="form.password" required>
                                </b-form-input>
                                <div class="text-danger" id="password">{{ errors.password }}</div>
                            </b-form-group>
                            <b-form-group v-if="isAdmin" class="col-md-6" label="Repeat Password:"
                                label-for="password_confirmation">
                                <b-form-input type="password" id="password_confirmation" placeholder="Repeat Password"
                                    v-model="form.password_confirmation" required></b-form-input>
                                <div class="text-danger" id="repeat_password">{{ errors.repeat_password }}</div>
                            </b-form-group>

                            <b-form-group v-if="form.accountOn || isAdmin" class="col-sm-12" v-show="false"
                                label-for="Organization" label="Organization:">
                                <multiselect v-model="form.account" tag-placeholder="Add as new Organization"
                                    placeholder="Select Organization or Create One" label="name" track-by="code"
                                    :options="organizations" :multiple="false" :taggable="true" @tag="handleAddAccount"
                                    :loading="onsubmit" :disabled="!isAdmin">
                                </multiselect>
                                <div class="text-danger" id="organization">{{ errors.organization }}</div>
                            </b-form-group>

                            <b-form-group v-if="isAdmin" class="col-sm-12" label-for="selectcountry" label="Role:">
                                <multiselect v-model="form.roles" tag-placeholder="Add this as new role"
                                    placeholder="Select platform" label="name" track-by="code" :options="roles"
                                    :multiple="true" :taggable="true" @input="onRoleChange"></multiselect>
                                <div class="text-danger" id="roles">{{ errors.roles }}</div>
                            </b-form-group>

                            <b-form-group v-if="canManageAccount" class="col-sm-12" label-for="Accounts"
                                label="Accounts:">
                                <multiselect v-model="form.accounts" tag-placeholder="Add as new Accounts"
                                    placeholder="Select Accounts" label="name" track-by="code"
                                    :loading="isLoadManagerAccounts" :options="organizations" :multiple="true"
                                    :taggable="true" :disabled="!isAdmin">
                                </multiselect>
                                <div class="text-danger" id="organization">{{ errors.accounts }}</div>
                            </b-form-group>
                            
                            <b-form-group v-else-if="isAdmin" class="col-sm-12" label-for="Accounts"
                                label="Accounts:">
                                <multiselect v-model="account" tag-placeholder="Add as new Accounts"
                                    placeholder="Select Accounts" label="name" track-by="code" :options="organizations"
                                    :multiple="false" :taggable="true" @remove="manageAccounts" @tag="handleAddAccount"
                                    :loading="onsubmit" :disabled="!isAdmin">
                                </multiselect>
                            </b-form-group>



                            <b-col sm="12" lg="12" v-if="isAdmin">
                                <div class="form-group">
                                    <label for="address">Address</label>
                                    <b-form-textarea id="address" placeholder="Enter Address" rows="6"
                                        v-model="form.address">
                                    </b-form-textarea>
                                </div>
                            </b-col>
                        </b-row>
                        <b-button class="float-right" variant="primary" type="submit" :disabled="onsubmit">
                            <b-spinner small type="grow" v-show="onsubmit"></b-spinner>
                            Add New User
                        </b-button>
                    </b-form>
                </div>
            </template>
        </iq-card>
    </b-container>
</template>

<script>
import {
    sofbox
} from '../../config/pluginInit'
import store from '../../store/index'
import Multiselect from 'vue-multiselect'
import {
    helper
} from '@/helpers'
import api from '@/api/RestClient'
import { mapGetters } from 'vuex'

export default {
    name: 'AddUser',
    components: {
        Multiselect
    },
    mounted() {
        sofbox.index()
    },
    created() {
        this.getRoles();
        if(this.isAdmin) {
            this.getAccounts();
        }
    },
    data() {
        return {
            onsubmit: false,
            selectedrole: null,
            selectedCountry: null,
            organizations: [],
            roles: [],
            account: null,
            form: {
                name: '',
                email: '',
                password: '',
                password_confirmation: '',
                roles: null,
                organization: null,
                accountOn: false,
                account: null,
                accounts: [],
                address: null
            },
            accounts: [],
            errors: {},
            canManageAccount: false,
            isLoadManagerAccounts: true
        }
    },
    methods: {
        create(event) {
            event.preventDefault();
            this.onsubmit = true;
            this.checkForm();
            let response = null
            let title = "Success";
            let type = "success";
            let toastObj = {
                title: title,
                variant: type,
                solid: true
            }

            if (this.form.account?.name) {
                this.account = this.form.account
            }
            this.form.account = this.account?.name

            if (helper.isEmpty(this.errors)) {

                api.users.post(this.form).then(data => {
                    if (data.success) {
                        response = data.data
                        toastObj.item = data
                        toastObj.message = data.message

                        this.$swal('Create User', data.message, 'success')
                            .then((result) => {
                                this.$emit('modalHide')
                            })
                    }
                }).catch(err => {
                    toastObj.title = "Error";
                    toastObj.variant = "error";
                    response = null;
                    if ('errors' in err.response.data) {
                        let errors = err.response.data.errors

                        if ('name' in errors) this.toast('Error', 'danger', errors.name[0]);
                        if ('email' in errors) {
                            this.errors.email_address = errors.email[0];
                            // this.toast('Error', 'danger', errors.email[0]);
                            toastObj.message = errors.email[0];
                        }
                        if ('organization' in errors) this.toast('Error', 'danger', errors.organization[0]);
                        if ('account' in errors) this.toast('Error', 'danger', 'organization required');
                        if ('roles' in errors) this.toast('Error', 'danger', errors.roles[0]);

                        if (this.isAdmin) {
                            if ('password' in err.errors) this.toast('Error', 'danger', errors.password[0]);
                            if ('password_confirmation' in err.errors) this.toast('Error', 'danger', errors.password_confirmation[0]);
                        }
                    }


                    this.$swal('Add User', toastObj.message, 'error')
                })
                    .finally(() => {
                        this.onsubmit = false;

                        if (response) {
                            this.$emit('modalHide', toastObj);
                        }
                    })
            } else {
                this.onsubmit = false;
            }
        },
        getRoles() {
            api.roles.all().then(response => {
                this.roles = response.data;

                // 
                if (this.isOwnerAccount && this.roles.length == 1) {
                    this.form.roles = this.roles[0]
                }
            }).catch(err => {
                this.toast('Oh!', 'danger', 'Something went wrong')
            })
                .finally(() => {
                    this.onsubmit = false;
                })
        },
        getAccounts() {
            this.onsubmit = true;
            api.accounts.all({per_page: -1}).then(response => {
                this.organizations = _.map(response.data, function (o) {
                    return {code: o.id, name: o.name}
                });
            }).catch(err => {
                this.toast('Oh!', 'danger', 'Something went wrong')
            })
                .finally(() => {
                    if (!this.isAdmin && this.user.account_id) {
                        this.getAccount(this.user.account_id)
                    }

                    this.onsubmit = false;
                })
        },
        getAccount(id = null) {
            if (!id) return;

            this.onsubmit = true;
            // this.form.account = null
            api.accounts.get(id)
                .then(response => {
                    if (response.success) {
                        let selected = { code: response.data.id, name: response.data.name };
                        this.organizations.push(selected)
                        this.form.account = selected
                    }
                })
                .catch(err => {
                    this.toast('Oh!', 'danger', 'Something went wrong')
                })
                .finally(() => {
                    this.onsubmit = false;
                })
        },
        handleAddAccount(data) {
            this.onsubmit = true;
            if (!data || data == '') {
                this.toast('Oh!', 'danger', 'Something want wrong')
            }
            const TYPE_CLIENT = 1;
            const ACCOUNT_ACTIVE = 1;
            api.accounts.post({ name: data, type: TYPE_CLIENT , status: ACCOUNT_ACTIVE }).then(response => {
                if (response.success) {
                    let newAccount = { code: response.data.id, name: response.data.name };
                    this.organizations.push(newAccount)
                    this.account = newAccount;
                    this.accounts.push(newAccount);
                }
            }).catch(err => {
                this.toast('Oh!', 'danger', 'Something went wrong')
            })
                .finally(() => {
                    this.onsubmit = false;
                })
        },
        onRoleChange(roles) {
            this.form.accountOn = false
            this.manageAccounts();
            const that = this
            _.forEach(roles, function (role) {
                if (['Client'].includes(role.name)) {
                    that.form.accountOn = true;
                    that.canManageAccount = false;
                }
            });
        },
        resetErrors() {
            this.errors = {}
        },
        checkForm() {
            this.resetErrors();
            if (!this.form.name) {
                this.errors.full_name = 'Name required.';
            }
            if (!this.form.email) {
                this.errors.email_address = 'Email required.';
            }
            if (this.isAdmin) {
                if (!this.form.password) {
                    this.errors.password = 'Password required.';
                }
                if (!this.form.password_confirmation) {
                    this.errors.repeat_password = 'Repeat password required.';
                }
            }

            let accountRequire = false;
            _.forEach(this.form.roles, function (role) {
                if (['Client'].includes(role.name)) {
                    accountRequire = true
                }
            });
            if (accountRequire && !this.form.account) {
                this.errors.organization = 'Organization required.';
            }
            if (!this.form.roles) {
                this.errors.roles = 'Role(s) required';
            }
            if (!helper.equal(this.form.password, this.form.password_confirmation)) {
                this.errors.repeat_password = 'Password did not matched';
            }

            if(this.canManageAccount && !this.form.accounts.length) {
                this.errors.accounts = 'Select Accounts can\'t be empty';
            }
        },
        toast(title = "success", variant = "success", message = "Account chargerd") {
            this.$bvToast.toast(message, {
                title: title,
                variant: variant
            })
        },
        manageAccounts() {
            const ACCOUNT_MANAGER = 'accounts manager';
            const CLIENT = 'client';
            
            let roleClient = _.filter(this.form.roles, function (r) {
                return typeof r.name !== 'undefined' && r.name.toLowerCase() === CLIENT
            });

            let roleAccountsManager = _.filter(this.form.roles, function (r) {
                return typeof r.name !== 'undefined' &&  r.name.toLowerCase() === ACCOUNT_MANAGER
            });

            if (!_.isEmpty(roleAccountsManager) && _.isEmpty(roleClient)) {
                this.form.account = null;
                this.getManagerAccounts();
            } else {
                this.accounts = [];
                this.form.accounts = [];
                this.canManageAccount = false;
            }
        },
        getManagerAccounts() {
            this.isLoadManagerAccounts = true;
            api.accounts.manager(this.form.email)
                .then(response => {
                    this.form.accounts = _.map(response.data, function(o) {
                        return {code: o.id, name: o.name}
                    })
                })
                .catch(err => {
                    console.log(err);
                })
                .finally(() => {
                    this.onsubmit = false;
                    this.canManageAccount = true;
                    this.isLoadManagerAccounts = false;
                })
        },
    },
    computed: {
        ...mapGetters({
            isAdmin: 'Auth/isAdmin',
            isAccounting: 'Auth/isAccounting',
            isManager: 'Auth/isManager',
            isClient: 'Auth/isClient',
            user: 'Auth/user',
            isOwnerAccount: 'Auth/isOwnerAccount',
        })
    },
    watch: {
        "accounts": {
            handler(newVal, oldVal) {
                this.form.accounts = _.map(newVal, 'code');
            }
        },
        "form.roles": {
            handler(olVal, newVal) {
                this.manageAccounts();
            }
        }
    }
}
</script>
