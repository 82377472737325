export default function (axios) {
  return {
    list: (params = {}) => {
      return axios.get(`/notification_types`, { params })
        .then(response => response.data);
    },
    getNames: (params = {}) => {
      return axios.get(`/notification_types/get-names`, { params })
        .then(response => response.data);
    },
    get: (notifTypeID) => {
      return axios.get(`/notification_types/${notifTypeID}`)
        .then(response => response.data);
    },
    post: (data = {}) => {
      return axios.post('/notification_types', data)
        .then(response => response.data);
    },
    put: (id, data) => {
      return axios.put(`/notification_types/${id}`, data)
        .then(response => response.data)
    },
    delete: async (id, data) => {
      return axios.delete(`/notification_types/${id}`, { data: data })
        .then(response => response.data)
    },
    restore: (id) => {
      return axios.post(`/notification_types/${id}/restore`, {})
        .then(response => response.data);
    },
    profile: () => {
      return axios.get(`/notification_types/profile`)
        .then(response => response.data);
    },
    saveProfileNotifications: (id, data) => {
      return axios.post(`/notification_types/profile/${id}`, data)
        .then(response => response.data)
    },

  }
}
