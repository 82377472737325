<template>
    <div><span v-html="rawHtml"></span></div>
</template>

<script>
import IgBtn from '@/components/inboxgeek/btn/Default.vue'
import { helper } from '@/helpers'

export default {
    name: 'DtNotifActions',
    props: ['row'],
    components: {
        IgBtn
    },
    data() {
        return {
            rawHtml: '--'
        }
    },
    mounted() {
        if(typeof this.row !== 'undefined') {
            this.rawHtml = this.row.item.message
        }
    },
    methods: {
        onClick(event, row, ref) {

            if (typeof this.$refs[ref] !== 'undefined') {
                this.$refs[ref].setState();
            }

            this.$emit('onClick', { row, event, ref: this.$refs[ref] })
        },
    }
}

</script>
