<template>
  <b-modal :id="id" :ref="id" :title="geSource.name + ' Script'" cancel-title="Close" size="lg" @show="initModal">

    <PartialFinish ref="step-script" v-bind="{ form: geSource, errors: {}, title: 'Copy your Script!' }" />

    <template #modal-footer="{ ok, cancel }">
      <button v-b-modal.modal-close_visit class="btn btn-secondary" @click="cancel" :disabled="isLoading">
        Close
      </button>
      <button v-b-modal.modal-close_visit class="btn btn-primary" @click="ok" :disabled="isLoading">
        Ok
      </button>
    </template>
  </b-modal>
</template>

<script>
import PartialFinish from './partials/Finish.vue'
import _ from 'lodash';
import api from '@/api/RestClient'
import { helper } from '../../../helpers';

export default {
  name: 'GeScriptModal',
  components: {
    PartialFinish,
  },
  props: ['id', 'geSource'],
  data() {
    return {
      isLoading: false,
    }
  },
  methods: {
    initModal() {
      this.isLoading = false;
    },
  }
}
</script>