<template>
  <div>
    <div v-if="!showCreateNewPlatform">
      <b-row> <b-col><b-input placeholder="search" v-model="search" /></b-col></b-row>


      <div class="my-4" style="    overflow-y: scroll;max-height: 410px;overflow-x: clip;padding-right: 8px;">
        <div v-if="loading">
          <div class="text-center p-4 d-flex justify-content-center align-items-center">
            <b-spinner variant="primary"> </b-spinner>
            <span class="text-primary p-2"><small> Fetching platforms, please wait...</small></span>

          </div>

        </div>
        <div v-else>
          <b-row v-if="platforms.length == 0" class="text-center">

            <div class="w-100 mb-2" style="color:#f6386b">No platform is found</div>


            <div @click="showCreateNewPlatform = true" class="rounded btn btn-primary text-white m-auto p-2 px-4 mt-4"
              @mouseover="isHovering = true" @mouseout="isHovering = false">
              <h5 class="text-white">

                Create new Platform

              </h5>

            </div>

          </b-row>

          <b-row v-else style="align-items:stretch;    gap: 15px 0px;">

            <b-col cols="4" align-v="center">
              <div @click="showCreateNewPlatform = true; $emit('toggleNextBtn', false);" class="rounded ibg-new-platform"
                style="height: 100%;" @mouseover="isHovering = true" @mouseout="isHovering = false">
                <h5 class="ibg-new-platform-button" style="padding-top: 17%;">

                  Create new Platform

                </h5>

              </div>
            </b-col>

            <b-col v-for="platform in filtered_platforms" :key="platform.id" @click="selectPlatform(platform)"
              style="cursor: pointer;" cols="4">
              <div class="bg-white border shadow-sm rounded pt-5 px-2 pb-2"
                :class="{ selected: isSelected(platform), 'inactive-platform': (isConnected(platform) == false) }"
                style="height:100%">

                <!-- <span class="spot-light active-spot"
                  :class="[isConnected(platform) ? 'active-spot' : 'inactive-spot']"></span>
                <span class="text-status text-success" v-if="isConnected(platform)">connected</span>
                <span class="text-status text-muted" v-else>disconnected</span> -->
                <NetWorkState :platform="platform"></NetWorkState>

                <b-row class="row" align-v="center">
                  <b-col align-self="center" class="text-center" cols="4" style="height:5rem;">
                    <img style="width: 70%;margin-top: 0.4rem;" :src="getImageURL(platform.name)">
                  </b-col>
                  <b-col cols="8">
                    <h4>{{ platform.nickname }}</h4>
                    <h6> {{ platformName(platform.name) }}</h6>

                    <span v-if="platform.account != '' && platform.account != null">{{ platform.account }}<br></span>
                    <span>{{ platform.key }}</span>


                  </b-col>
                </b-row>

              </div>
            </b-col>


          </b-row>

        </div>





      </div>
    </div>

    <div v-else>

      <createNewPlatform :account="account" @platformCreated="platformCreated"></createNewPlatform>

      <b-row>


        <b-col class="text-center px-4">
          <div class="line mx-auto mt-2 mb-4"></div>
          <button class="btn mx-auto btn-secondary btn-soft-primary w-100"
            @click="showCreateNewPlatform = false">Choose an existing platform <i
              class="ri-arrow-go-back-line"></i></button></b-col>
      </b-row>


    </div>
  </div>
</template>

<script>

import api from '@/api/RestClient'
import createNewPlatform from '@/components/inboxgeek/createNewPlatform';
import { helper } from '@/helpers';
import NetWorkState from "@/views/Platforms/children/NetWorkState.vue";
import {mapGetters} from "vuex";

export default {
  name: "ExistPlatforms",
  props: ['account', 'selectedPlatform','service'],
  components: {
    createNewPlatform,
    NetWorkState
  },
  mounted() {
    if (this.selectedPlatform) {
      this.selected_platform = this.selectedPlatform;
    }
    this.$emit('toggleNextBtn', false);
    this.fetchExistingPlatforms(this.account);
    this.refreshPlatformsStore();
  },
  data() {
    return {
      platforms: [],
      selected_platform: null,
      search: '',
      isHovering: false,
      showCreateNewPlatform: false,
      loading: false
    }
  },
  methods: {
    platformCreated(platform_details) {

      this.fetchExistingPlatforms(this.account).finally((response) => {

        this.platforms.map((platform) => {

          if (platform_details.id == platform.id) {

            this.selectPlatform(platform);

          }

        });

      });
      this.showCreateNewPlatform = false;

    },
    fetchExistingPlatforms(account) {
      this.loading = true;
      return api.platforms.index(account.accountid,this.service).then((response) => {
        this.platforms = response.data;
        this.loading = false;
      }).catch((error) => {
        console.log('failed to fetch platforms');
        this.loading = false;
      });

    },
    platformName(name) {
      return helper.platformFormatName(name);
    },
    selectPlatform(platform) {

      if (this.isConnected(platform)) {

        this.selected_platform = platform;
        this.$emit('platformSelected', platform);

        this.$emit('toggleNextBtn', true);
      }

    },

    isSelected(platform) {
      if (this.selected_platform) {

        return (this.selected_platform.id === platform.id);
      }
      return false;
    },

    getImageURL(platform) {
      try {
        return require(`@/assets/images/platforms/${platform.toLowerCase()}.png`)
      } catch (e) {
        return require(`@/assets/images/platforms/default.png`)
      }
    },
    isConnected(platform) {
      return (platform.validation_code != 113 || platform.validation_code == null)
    },
    refreshPlatformsStore() {
       api.settings.getPlatformConfigs().then(response => {
          this.$store.dispatch('updatePlatformConfigs', response.data);

        }).catch((error)=>{
          console.log(error);
        });
    },
    setPlatform(platform) {
      if(!platform) {
        this.selected_platform = platform;
        // this.$emit('toggleNextBtn', true);
        return;
      }
      let selected_platform = _.find(this.filtered_platforms, function (o) { return platform.id == o.id; });
      if (selected_platform) {
        if (this.isConnected(platform)) {
          this.selected_platform = platform;
          this.$emit('toggleNextBtn', true);
        }
      }
    }
  },
  computed: {
    ...mapGetters({platformConfigs : 'platformConfigs'}),
    filtered_platforms() {

      let active_platforms =  this.platformConfigs.filter((platform)=>{
        return ( platform[this.service ?? 'lo_service']===1 &&  platform.active === 1 ) ;
      }).map((platform)=>{ return platform.name.toLowerCase(); });

      if (this.search != '') {

        return this.platforms.filter((platform) => {

          if (
              (
                  platform.name.toLowerCase().includes(this.search.toLowerCase()) ||
                  platform.nickname?.toLowerCase().includes(this.search.toLowerCase())  ||
                  platform.account?.toLowerCase().includes(this.search.toLowerCase())
              )
              && active_platforms.includes(platform.name.toLowerCase())
          ) {
            return platform;
          }
        })
      }

      return this.platforms.filter((platform) => {

        if (active_platforms.includes(platform.name.toLowerCase())) {
          return platform;
        }
      });
    }
  }
}
</script>

<style scoped>
.line{
  position: relative;
  height: 1px;
  width: 100%;
  margin: 36px 0;
  background-color: #d4d4d4;
}
.line::before{
  content: 'Or';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #FFF;
  color: #8b8b8b;
  padding: 0 15px;
}
.inactive-platform {
  background-color: #f4f7fd !important;
  box-shadow: inset 0 0 20px #dde2ed !important;
  cursor: default !important;
}

.inactive-platform {
  background-color: #f4f7fd !important;
  box-shadow: inset 0 0 20px #dde2ed !important;
}

.active-spot {
  background: #1add47;
}

.inactive-platform img {
  filter: grayscale(100%);
  opacity: 0.5;
}

.inactive-spot {
  background: #575757;
}

.spot-light {

  width: 15px;
  height: 14px;
  border-radius: 40px;
  position: absolute;
  left: 30px;
  top: 25px;

}

.text-status {
  position: absolute;
  left: 50px;
  top: 20px;
}

.ibg-new-platform {
  text-align: center;
  border: unset;
  cursor: pointer;
}

.ibg-new-platform-button {
  background: #ffd0dd57;
  height: 100%;
  width: 100%;
  padding: 2.2rem;
  color: #ff0054;
  border-radius: 8px;
}

.ibg-new-platform-button:hover {
  background-color: #f6386b !important;
  color: white !important;
}

.ibg-new-platform h5:hover {
  color: inherit;
}

.selected {
  border: 2px solid #ff8bac !important;
  box-shadow: 0px 2px 9px 0px #dd406c61 !important;
  outline-offset: -12px;
}
</style>