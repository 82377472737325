<template>
    <b-container fluid>
        <b-row>
            <b-col lg="12">
                <iq-card body-class="p-0">
                    <template v-slot:body>
                        <!-- isOwnerAccount -->
                        <div class="iq-edit-list" v-on:click='switch_tab'>
                            <tab-nav :pills="true" class="iq-edit-profile d-flex">
                                <tab-nav-items class="col p-0"
                                     :active="true"
                                    href="#general-settings" title="General settings" />
                                <tab-nav-items v-if="isOwnerAccount" class="col p-0" :active="false"
                                    href="#account-users" title="Account Users" />

                                    <tab-nav-items class="col p-0" :active="false"
                                    href="#account-api-access" title="API Access" />

                                   <!---<tab-nav-items class="col p-0" :active="false"
                                    href="#cookie-generator" title="Cookie Generator" />-->
                            </tab-nav>
                        </div>
                    </template>
                </iq-card>
            </b-col>
            <b-col lg="12">
                <div class="iq-edit-list-data">
                    <tab-content>
                        <tab-content-item :active="true" id="general-settings">
                            <b-row>
                                <b-col>
                                    <iq-card>
                                        <template v-slot:headerTitle>
                                            <h4 class="card-title">Notification Settings</h4>
                                        </template>
                                        <template v-slot:body>
                                            <b-col v-if="isLoading" class="d-flex justify-content-center py-5">
                                                <b-spinner small label="Small Spinner" type="grow"></b-spinner>
                                            </b-col>
                                            <b-form ref="form-settings" v-else @submit.prevent="updateUserNotificationTypes"
                                                class="mb-5">
                                                <b-row class="text-primary">
                                                    <b-col>
                                                        <p class="information-border-*">

                                                            <span class="text-primary mb-3">Receive Email Notifications for:</span>

                                                            <span class="badge badge-primary mx-2 text-lowercase">
                                                                {{ user.email }}
                                                            </span>

                                                            <span v-if="user.account">
                                                                <small
                                                                    class="text-dark font-weight-bold">Organization:</small>
                                                                <span class="badge badge-primary mx-2 text-uppercase">
                                                                    {{ user.account.name }}
                                                                </span>
                                                            </span>

                                                        </p>

                                                        <b-col v-for="(item, index) in notifTypes" :key="index" md="12"
                                                            class="mt-2 pl-0 py-1 d-flex"
                                                            v-if="!exceptNotif.includes(item.name)">
                                                            <div
                                                                class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color my-auto">
                                                                <div class="custom-switch-inner cursor-pointer">
                                                                    <input type="checkbox"
                                                                        :id="`notifications-${notifAttr(item)}`"
                                                                        class="custom-control-input bg-primary"
                                                                        v-model="form.notifications[notifAttr(item)]"
                                                                        @input="updateUserNotificationTypes()"
                                                                        :class="update_spinner ? 'disabled' : ''"
                                                                        :disabled="update_spinner">
                                                                    <label :for="`notifications-${notifAttr(item)}`"
                                                                        data-on-label="ON" data-off-label="OFF"
                                                                        class="custom-control-label"></label>
                                                                </div>
                                                                <label class="ml-2 text-primary cursor-pointer my-auto"
                                                                    :for="`notifications-${notifAttr(item)}`"
                                                                    :disabled="update_spinner">
                                                                    {{ customCapitalize(item.name) }}
                                                                </label>
                                                            </div>

                                                            <input
                                                                v-if="(currSettingEmail == item) || (form.emails[notifAttr(item)])"
                                                                :id="'email' + index" :ref="'email' + index" type="email"
                                                                placeholder="Enter email" class="form-control ml-auto"
                                                                v-model="form.emails[notifAttr(item)]" style="width: 70%;"
                                                                :readonly="update_spinner || !form.notifications[notifAttr(item)]">


                                                            <button
                                                                v-if="!form.emails[notifAttr(item)] && currSettingEmail != item"
                                                                class="btn btn-sm mx-1 iq-bg-primary mb-1 my-auto"
                                                                :class="(currSettingEmail == item) || (form.emails[notifAttr(item)]) ? '' : 'ml-auto'"
                                                                @click="showEmail(item)" type="button"
                                                                :disabled="currSettingEmail == item || update_spinner">
                                                                <i class="ri-mail-add-fill"></i>
                                                                <span>Add Email</span>
                                                            </button>
                                                            <span v-else class="my-auto">
                                                                <button v-if="currSettingEmail == item"
                                                                    class="btn btn-sm mx-1 iq-bg-secondary mb-1 my-auto"
                                                                    :class="(currSettingEmail == item) || (form.emails[notifAttr(item)]) ? '' : 'ml-auto'"
                                                                    @click="currSettingEmail = null" type="button"
                                                                    :disabled="update_spinner">
                                                                    <i class="ri-close-line"></i>
                                                                    <span>Cancel</span>
                                                                </button>
                                                                <button v-else
                                                                    class="btn btn-sm mx-1 iq-bg-danger mb-1 my-auto"
                                                                    :class="(currSettingEmail == item) || (form.emails[notifAttr(item)]) ? '' : 'ml-auto'"
                                                                    @click="clearEmail(item, index)" type="button"
                                                                    :disabled="update_spinner">
                                                                    <i class="ri-mail-close-line"></i>
                                                                    <span>Clear</span>
                                                                </button>
                                                            </span>


                                                        </b-col>

                                                    </b-col>
                                                </b-row>
                                                <b-button type="submit" variant="primary" class="float-right mr-3 mt-2"
                                                    :disabled="update_spinner">Save
                                                    <b-spinner small type="grow" v-show="update_spinner"></b-spinner>
                                                </b-button>
                                            </b-form>
                                        </template>
                                    </iq-card>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col>
                                    <iq-card>
                                        <template v-slot:headerTitle>
                                            <h4 class="card-title">Custom Portal Settings</h4>
                                        </template>
                                        <template v-slot:body>
                                            <b-col v-if="isLoading" class="d-flex justify-content-center py-5">
                                                <b-spinner small label="Small Spinner" type="grow"></b-spinner>
                                            </b-col>
                                            <b-form ref="form-other-settings" v-else @submit.prevent="updateOtherSettings"
                                                class="mb-5">
                                                <b-row class="text-primary">
                                                    <b-col>

                                                        <b-col v-for="(item, index) in otherSettings" :key="index" md="12"
                                                            class="mt-2 pl-0 py-1 d-flex">
                                                            <div
                                                                class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color my-auto">
                                                                <div class="custom-switch-inner cursor-pointer">
                                                                    <input type="checkbox"
                                                                        :id="`user_settings-${notifAttr(item)}`"
                                                                        class="custom-control-input bg-primary"
                                                                        v-model="form.otherSettings[notifAttr(item)]"
                                                                        @input="updateOtherSettings()"
                                                                        :class="update_spinner ? 'disabled' : ''"
                                                                        :disabled="update_spinner">
                                                                    <label :for="`user_settings-${notifAttr(item)}`"
                                                                        data-on-label="ON" data-off-label="OFF"
                                                                        class="custom-control-label"></label>
                                                                </div>
                                                                <label class="ml-2 text-primary cursor-pointer my-auto"
                                                                    :for="`user_settings-${notifAttr(item)}`"
                                                                    :disabled="update_spinner">
                                                                    {{ customCapitalize(item.name) }}
                                                                </label>
                                                            </div>

                                                            <input
                                                                v-if="(currSettingEmail == item) || (form.emails[notifAttr(item)])"
                                                                :id="'email' + index" :ref="'email' + index" type="email"
                                                                placeholder="Enter email" class="form-control ml-auto"
                                                                v-model="form.emails[notifAttr(item)]" style="width: 70%;"
                                                                :readonly="update_spinner || !form.otherSettings[notifAttr(item)]">

                                                        </b-col>

                                                    </b-col>
                                                </b-row>
                                                <b-button type="submit" variant="primary" class="float-right mr-3 mt-2"
                                                    :disabled="update_spinner">Save
                                                    <b-spinner small type="grow" v-show="update_spinner"></b-spinner>
                                                </b-button>
                                            </b-form>
                                        </template>
                                    </iq-card>
                                </b-col>
                            </b-row>
                        </tab-content-item>

                        <tab-content-item v-if="isOwnerAccount" :active="false" id="account-users">
                            <iq-card>
                                <template v-slot:headerTitle>
                                    <h4 class="card-title">Manage Account Users</h4>
                                    <p class="information-border-*">
                                        <span>
                                            <small class="font-weight-bold">Organization:</small>
                                        </span>
                                        <span class="badge badge-primary mx-2">
                                            {{ user.account.name }}
                                        </span>
                                    </p>
                                </template>
                                <template v-slot:headerAction>
                                    <b-button v-b-modal.modal-add-user variant="primary" class="text-right mr-1">
                                        Add User
                                    </b-button>
                                </template>
                                <template v-slot:body>

                                    <DataTable v-if="renderDT" ref="notification_type-table" :url="'/users'"
                                        v-bind="{ columns: dtColumns, customCells: dtCells, sort: 'name' }"
                                        @onClick="handleBtn" @onSwitch="handleSwitchAttr" />


                                </template>
                            </iq-card>
                        </tab-content-item>


                        <tab-content-item  :active="false" id="account-api-access">
                            <iq-card>
                                <template v-slot:headerTitle>
                                    <h4 class="card-title">Api Access</h4>

                                </template>
                                <template v-slot:headerAction>
                                    <b-button v-show="!api_token" variant="primary" @click="generateToken" class="text-right mr-1">
                                        generate new API token
                                    </b-button>
                                </template>
                                <template v-slot:body>

                                  <div class="alert alert-danger my-2" style="background-color: #fcecec8a;">
                                    <h3 class="text-danger pr-2" ><i class="ri-error-warning-line"></i></h3>
                                    <hr>
                                    <p>Your access token is a confidential piece of information that grants you access to your account. Do not share it with anyone, as sharing your access token could compromise the security of your account and the data it contains. Keep your token secure and never disclose it to others.</p>
                                  </div>

                                  <div style="position: relative;">
                                  <textarea name="token" ref="token"  v-model="crypted_api_token" class="form-control p-4" id="" cols="30" rows="4"></textarea>

                                  <button class="btn btn-secondary" @click="showToken" style="position: absolute; bottom: 10px; right: 10px; border-radius: 100px; padding-left: 10px; padding-right: 5px; padding-top: 6px; padding-bottom: 4px;">
                                    <i v-if="!token_is_visible" class="ri-eye-2-line"></i>
                                    <i v-else class="ri-file-copy-line"></i>
                                </button>

                                </div>
                                <span v-show="show_copy_alert"  class="alert alert-info my-2" style="color: #00bcd9;border-color: #4fd6f4;background-color: #e5faff40;">Token copied to clipboard</span>

                                  <!--  -->
                                </template>
                            </iq-card>
                        </tab-content-item>

                        <tab-content-item  :active="false" id="cookie-generator">
                            <iq-card>
                                <template v-slot:headerTitle>
                                    <h4 class="card-title">Cookie Generator</h4>

                                </template>

                                <template v-slot:body>
                                  <form>
                                   <div class="row">
                                    <div class="col">
                                        <label>Title</label>
                                        <input type="text" v-model="cookie.title" class="form-control">

                                        <label>Description</label>
                                        <textarea class="form-control" v-model="cookie.description"  id="" cols="30" rows="4"></textarea>
                                    </div>

                                    <div class="col">
                                        <label>Color</label>
                                        <input type="color" v-model="cookie.color" class="form-control">

                                        <label>Link text</label>
                                        <input type="text" v-model="cookie.link_text" class="form-control">

                                        <label>Link</label>
                                        <input type="text" v-model="cookie.link" class="form-control">
                                    </div>
                                   </div>

                                </form>
                                <div class="row my-2">
                                    <div class="col">
                                        <label for="">Script</label>
                                        <textarea class="form-control p-4" v-model="script" cols="30" rows="5" readonly></textarea>

                                    </div>

                                </div>


                                </template>
                            </iq-card>
                        </tab-content-item>
                    </tab-content>

                </div>
            </b-col>
        </b-row>

        <!-- Start Add User Modal-->
        <b-modal :id="addModal.id" :ref="addModal.id" :title="addModal.title" size="lg" centered hide-footer>
            <AddUser col=12 @modalHide="closeModal(addModal)"></AddUser>
        </b-modal>
        <!-- END Add User Modal-->

        <!-- Start Edit User Modal-->
        <b-modal :id="editModal.id" :ref="editModal.id" :title="editModal.title" size="lg" centered hide-footer>
            <EditUser col=12 :item="editModal.item" @modalHide="closeModal(editModal)"></EditUser>
        </b-modal>
        <!-- END Edit User Modal-->
    </b-container>
</template>

<script>
import {
    sofbox
} from '../../config/pluginInit'
import api from '@/api/RestClient'
import {
    helper
} from '@/helpers'
import { mapGetters } from 'vuex'
import DataTable from '@/components/inboxgeek/tables/DataTable.vue';
import AddUser from './AddUser'
import EditUser from './children/EditUser'
import DtUserRoles from '@/views/User/children/DtUserRoles.vue';
import DtUserName from '@/views/User/children/DtUserName.vue';
import IBGSwitchCell from '@/components/inboxgeek/tables/IBGSwitchCell.vue'
import DtUserActions from '@/views/User/children/DtUserActions.vue';
import StatusCell from '@/components/inboxgeek/tables/StatusCell.vue'
import {DEFAULT_USER_SETTINGS} from "@/constantes";
import _ from 'lodash';

export default {
    name: 'ProfileEdit',
    components: {
        DataTable,
        AddUser,
        EditUser
    },
    beforeCreate() {
        sofbox.openLoader();
    },
    mounted() {
        sofbox.index()
        this.loadSettings();
        if(this.UserApiToken){
            this.api_token = this.UserApiToken;
        }
        // this.fetchNotificationTypes();
    },
    updated() {
        this.errors = [];
    },

    data() {
        return {
            show_copy_alert:false,
            token_is_visible:false,
            cookie : {
                title : 'We respect your personal privacy',
                description : 'We and our third-party partners use cookies and other tracking technologies to provide a proactive support experience, enhance site navigation, analyze site usage, and assist our marketing efforts.',
                link_text : 'Learn more',
                link : 'https://help.inboxgeek.com/liveleads-compliance/',
                color : '#555',
            },
            api_token: '',
            notifTypes: [],
            otherSettings: [],
            profileSettings: {},
            errors: [],
            fails: false,
            success: false,
            update_spinner: false,
            pass_spinner: false,
            isLoading: true,
            form: {
                emails: {},
                otherSettings: {},
                notifications: {},
            },
            currSettingEmail: null,
            addModal: {
                id: 'modal-add-user',
                title: 'Add User',
                item: {}
            },
            editModal: {
                id: 'modal-edit-user',
                title: 'Edit User',
                item: {}
            },
            // Datatable
            renderDT: true,
            dtColumns: [
                { label: 'Name', key: 'name', class: 'text-left', sortable: true, sortDirection: 'desc' },
                { label: 'Email', key: 'email', class: 'text-left', sortable: true, sortDirection: 'desc' },
                {
                    label: 'Created at', key: 'created_at', class: 'text-center', sortable: true, sortDirection: 'desc',
                    formatter: (value, key, item) => {
                        let dt = new Date(value);
                        return helper.formatDate(dt)
                    }
                },
                { label: 'Status', key: 'status', class: 'text-center', sortable: true, sortDirection: 'desc' },
                { label: 'Actions', key: 'actions', class: 'text-center', sortable: false, sortDirection: 'desc' },
            ],
            dtCells: [
                {
                    key: 'name',
                    component: DtUserName,
                    event: null,
                },
                {
                    key: 'role',
                    component: DtUserRoles,
                    event: null,
                },
                {
                    key: 'status',
                    component: StatusCell,
                    event: null,
                },
                {
                    key: 'actions',
                    component: IBGSwitchCell,
                    event: 'onSwitch',
                    props: {
                        attribute: 'status',
                    }
                }
            ],
            notifTypeModal: {
                id: 'modal-add-user',
                title: 'Add User To Account',
                item: {}
            },
            exceptNotif: ['No Payment', 'Checkout Session', 'Subscription', 'Live Report']
        }
    },
    methods: {
        showToken(){
            if(this.token_is_visible){


                if (navigator.clipboard) {

                        navigator.clipboard.writeText(this.api_token)
                        .then(() => {})
                        .catch((err) => {
                            console.error('Failed to copy text to clipboard:', err);
                        });
                }else{
                    this.$refs.token.focus();
                    document.execCommand('copy');
                }
                this.show_copy_alert = true;
                this.token_is_visible = false;
                return;
            }

            this.token_is_visible = true;


        },
        notifAttr(notif) {
            return notif.name.toLowerCase().split(" ").join("_")
        },
        fetchNotificationTypes() {
            this.isLoading = true;
            api.notificationTypes.list()
                .then((response) => {
                    if (response.success) {
                        this.notifTypes = response.data
                    }
                })
                .catch((error) => {
                    console.log(helper.formatErrorResponse(error))
                })
                .finally(() => {
                    helper.delay(2000).then(() => this.isLoading = false)
                })
        },
        switch_tab(e) {
            this.errors = [];
            this.success = false;
        },
        loadSettings() {
            this.isLoading = true;
            const excepts = ['welcome emails', 'password reset', 'account disconnection', 'payment failed'];
            this.form.notifications;

            api.notificationTypes.profile()
                .then((response) => {

                    if (response.success) {
                        this.notifTypes = _.filter(response.data.notification_types, (o) => { return !excepts.includes(o.name) })
                        this.otherSettings = response.data.user_settings ? _.unionBy(response.data.user_settings, DEFAULT_USER_SETTINGS, 'nickname') : DEFAULT_USER_SETTINGS;
                        this.displaySettings(response.data)
                    }
                })
                .catch((error) => {
                    console.log(helper.formatErrorResponse(error))
                })
                .finally(() => {
                    // return;
                    helper.delay(2000).then(() => this.isLoading = false)
                })

        },
        displaySettings(data) {

            this.profileSettings = data

            // Notification Settings
            this.notifTypes.forEach(notifType => {
                let attr = this.notifAttr(notifType);
                let notif = _.find(this.profileSettings.profile_notifications, (o) => { return notifType.name == o.name; });

                this.form.notifications[attr] = (!notif && !["daily event limit reached"].includes(notifType.name)) || (typeof notif?.pivot?.active != 'undefined' && notif.pivot.active) ? true : false;
                this.form.emails[attr] = notif && notif?.pivot?.email ? notif.pivot.email : null;
                notifType.showEmail = this.form.emails[attr] ? true : false;
            });

            // User Settings
            this.otherSettings.forEach(setting => {
                let attr = this.notifAttr(setting);
                this.form.otherSettings[attr] = setting.value && parseInt(setting.value) == 1 ? true : false;
            });
        },
        post(id, data) {

            api.notificationTypes.post(id, data).then(response => {
                if (response.success) {
                    this.displaySettings(response.data)
                    this.toast('Updated!', 'success', response.message);
                }
            }).catch(err => {
                helper.formatErrorResponse(err).forEach(error => {
                    console.log(error)
                    this.toast('Oh!', 'danger', 'Something went wrong')
                });
            })
                .finally(() => {
                    this.update_spinner = false;
                })
        },
        update(id, data) {
            this.update_spinner = true;

            api.notificationTypes.saveProfileNotifications(id, data).then(response => {
                if (response.success) {
                    this.displaySettings(response.data)
                    this.toast('Updated!', 'success', response.message);
                }
            }).catch(err => {
                helper.formatErrorResponse(err).forEach(error => {
                    console.log(error)
                    this.toast('Oh!', 'danger', 'Something went wrong')
                });

            })
                .finally(() => {
                    helper.delay(2000).then(() => {
                        this.currSettingEmail = null;
                        this.update_spinner = false
                    })
                })
        },
        updateSettings(id, data) {

            this.update_spinner = true;

            api.userSettings.saveUserSettings(id, data)
                .then(response => {
                    if (response.success) {
                        console.log( response.data )
                        // this.displaySettings(response.data)
                        this.toast('Updated!', 'success', response.message);
                    }
                }).catch(error => {
                    let message = ''
                    if (error.response) {
                        if (error.response.status == 500) {
                            message = 'Something went wrong!';
                        } else {
                            message = error.response.message;
                        }

                        this.toast('Error!', 'danger', message);
                    }


                }).finally(() => {
                    helper.delay(2000).then(() => {
                        this.update_spinner = false
                    })
                });

        },
        updateUserNotificationTypes() {
            this.checkUpdateForm();

            let data = []

            helper.delay(500).then(() => {
                this.profileSettings.notification_types.forEach(notifType => {
                    let value = this.form.notifications[this.notifAttr(notifType)]
                    let email = this.form.emails[this.notifAttr(notifType)]

                    let settingData = { code: notifType.id, value: value }

                    if (email) {
                        settingData.email = email;
                    }

                    data.push(settingData)
                });


                if (helper.isEmpty(this.errors)) {
                    this.update_spinner = true;
                    const userID = this.$store.getters['Auth/user'].id;

                    this.update(userID, data);
                }
            })
        },
        updateOtherSettings() {
            // this.checkUpdateForm();

            let data = [];

            helper.delay(500).then(() => {
                // console.log( this.form.otherSettings, this.otherSettings, this.form );

                this.otherSettings.forEach(setting => {

                    let value = this.form.otherSettings[this.notifAttr(setting)]
                    let settingData = { ...setting, value: value }

                    data.push(settingData)
                });

                console.log( 'data', data );

                if (helper.isEmpty(this.errors)) {
                    const userID = this.$store.getters['Auth/user'].id;
                    this.updateSettings(userID, data);
                }
            })

        },
        generateToken(){
            api.auth.generateToken().then((response) => {

                this.$store.dispatch('Auth/updateUserApiToken',response.authorization.token);
                this.api_token = response.authorization.token;


            }).catch((error)=>{

            });
        },
        resetErrors() {
            this.errors = {}
        },
        checkUpdateForm() {
            this.resetErrors();
        },
        toast(title = "success", variant = "success", message = "Account charged") {
            this.$bvToast.toast(message, {
                title: title,
                variant: variant
            })
        },
        handleBtn(data) {

            if (!data.event || !data.row) {
                return
            }

            let action = data.event.title.toLowerCase();

            this.ge_source = data.row;


            if (action == 'edit') {

                this.handleEdit(data.row)
            }

            helper.delay(2000).then(() => {
                if (data.ref) {
                    data.ref.setState(false);
                }
            });
        },
        handleSwitchAttr(data) {
            if (data.row.id == this.user.account.user_id) {
                data.input.checked = !data.input.checked;
                return;
            }

            if (!data.input || !data.row || !data.key) {
                return
            }

            let itemCode = data.row.id
            let form = { name: data.row.name, email: data.row.email, account: data.row.account }
            form[data.key] = data.input.checked ? true : false


            let toast = { message: 'Something went wrong!', title: 'Error', variant: 'danger' }

            return api.users.put(itemCode, form)
                .then(result => {
                    toast = { message: result?.message, title: 'Success', variant: 'success' }
                })
                .catch((error) => {
                    data.input.checked = !data.input.checked;
                    let errors = [];
                    toast.message = '';

                })
                .finally(() => {
                    this.$root.$bvToast.toast(toast.message, toast);
                    this.$root.$emit('bv::refresh::table', this.$refs['notification_type-table'].id)
                });
        },
        handleEdit(item) {
            if (!item) {
                return
            }
            this.editModal.title = `Edit User ${item?.name}`
            this.$root.$emit('bv::show::modal', this.editModal.id)
        },
        closeModal(modal) {
            if (modal.id == this.addModal.id) {
                this.$swal('User Added', 'User Added Successfully', 'success')
            } else if (modal.id == this.editModal.id) {
                this.$swal('User Updated', 'User Updated Successfully', 'success')
            } else {
                console.log( modal.item )
                // this.$swal(modal.title, modal.message, modal.variant)
            }

            if (modal.id) {
                this.$root.$emit('bv::hide::modal', modal.id)
                this.$root.$emit('bv::refresh::table', this.$refs['notification_type-table'].id)
            }

        },
        inputModel(item) {
            console.log(this.form.emails, this.notifAttr(item))
            return this.form.emails[this.notifAttr(item)]
        },
        showEmail(item) {
            this.currSettingEmail = null;
            if (typeof item !== 'undefined') {
                this.currSettingEmail = item;
            }
        },
        clearEmail(item) {
            this.currSettingEmail = item;

            this.$nextTick(() => {
                this.currSettingEmail = null;
                this.form.emails[this.notifAttr(item)] = null;
            });
        },
        customCapitalize(str, forceAll = false) {
            return helper.customCapitalize(str, forceAll);
        }
    },
    computed: {
        ...mapGetters({
            isAdmin: 'Auth/isAdmin',
            isClient: 'Auth/isClient',
            user: 'Auth/user',
            isOwnerAccount: 'Auth/isOwnerAccount',
            UserApiToken : 'Auth/UserApiToken'
        }),

        crypted_api_token(){
            if(this.api_token)
            {
                if(this.token_is_visible) {

                    return this.api_token;
                }

                return '*'.repeat(24*18);
            }
            return null;
        },
        script(){

           let script =  `function createModal() {
                        var modal = document.createElement("div");
                        modal.id = "modal";
                        modal.style.display = "block";
                        modal.style.position = "fixed";
                        modal.style.bottom = "2em";
                        modal.style.right = "2em";
                        modal.style.zIndex=999;
                        modal.style.backgroundColor = "rgb(255 255 255)";
                        modal.style.padding = "2em";
                        modal.style.border = "1px solid rgb(255 254 254)";
                        modal.style.borderRadius = "15px";
                        modal.style.boxShadow = "0px 0px 10px rgba(0, 0, 0, 0.2)";
                        modal.style.opacity = 1;
                        modal.style.transition = "opacity 0.5s ease 0s";
                        modal.style.color = "#7d899c";
                        modal.style.fontFamily = "sans-serif";

                        if (window.matchMedia("(max-width: 768px)").matches){
                        modal.style.width =  "calc(100% - 4em)";
                        }else{
                        modal.style.width = "500px";
                        }

                        var modalContent = document.createElement("div");
                        modalContent.innerHTML = '
                        <span style="position: absolute; top: 20px; right: 20px; cursor: pointer;" onclick="closeModal()">&#215;</span>
                        <h3 style="margin-top: 5px; margin-bottom: 5px;">[header]</h3>
                        <p style="margin-bottom: 10px;margin-top: 10px;">[messag]</p>
                        <a style="margin-top:4px; margin-bottom:4px; color:[color]" href="[link]">[link_text]</a>
                        ';

                        modal.appendChild(modalContent);
                        document.body.appendChild(modal);
                    }

                    // Function to close the modal
                    function closeModal() {
                        var modal = document.getElementById("modal");
                        modal.style.display = "none";
                    }

                    createModal();`;

           script = script.replace('[header]',this.cookie.title);
           script = script.replace('[message]',this.cookie.description);
           script = script.replace('[color]',this.cookie.color);
           script = script.replace('[link]',this.cookie.link);
           script = script.replace('[link_text]',this.cookie.link_text);

           return script;
        }
    }
}
</script>


<style>
.organisation-name {
    color: red;
}
</style>
