<template>

  <div>
    <b-modal size="lg"  id="applySuppressionList" ref="applySuppressionList" hide-footer
             title="Apply suppression lists">

      <b-form @submit="onSubmit" class="p-4 pb-0">

        <ExistedSuppressionList :source_id="source?.id" ></ExistedSuppressionList>

        <div class="d-flex justify-content-between">

          <label for="">Select suppression list</label>
          <b-form-checkbox v-model="upload_suppression_list" name="choose exiting list" switch>upload new one</b-form-checkbox>
        </div>


        <div v-if="!upload_suppression_list">
          <SuppressionListSelect @selected="suppressionListSelected"></SuppressionListSelect>
        </div>
        <div v-else>

        <div class="text-left my-2">

                <label for="input-live" class="text-left">Name:</label>
                <b-input   v-model="form.name"></b-input>
              </div>

              <UploadFileFormElement
                  :accepted_type="['text/csv']"
                  :upload-request-function="api.suppressionList.create"
                  @listUploadedEvent="suppressionListUploaded"
                  :max_allowed_files="1"></UploadFileFormElement>

              <div>
                  <b-checkbox v-model="form.apply_to_all_sources">Apply to all existing sources</b-checkbox>
              </div>

              <div v-show="error" class="alert mt-2 alert-danger">{{error_message}}</div>
        </div>
        <hr>
        <div class="text-right">
          <button type="submit" class="btn btn-primary mx-2" :disabled="loading">
            <b-spinner v-if="loading" small type="grow"></b-spinner> Apply  <span v-if="loading">...</span> </button>

          <a class="btn btn-light" @click="cancel">Cancel</a>

        </div>

      </b-form>
    </b-modal>
  </div>




</template>


<script>
import SuppressionListSelect from '@/components/inboxgeek/inputs/SuppressionListSelect.vue'
import SuppressionListField from '@/components/inboxgeek/fileds/SuppressionListField.vue'
import ExistedSuppressionList from '@/components/inboxgeek/widgets/ExistedSuppressionListBySource';

import api from "@/api";
import UploadFileFormElement from "@/components/inboxgeek/fileds/uploadFileFieldElemets/UploadFileFormElement";

export default {

  name : 'ModalApplySuppressionList',
  props: ['source'],
  components : {
    UploadFileFormElement,
    SuppressionListField,
    SuppressionListSelect,
    ExistedSuppressionList
  },
  mounted(){
      this.form.source_id = this.source?.id;
      this.form.account_id = this.source?.account_id;
  },
  data(){
    return {
      upload_suppression_list:false,
      form : {
        existed_lists:false,
        list_id : null,
        apply_to_all_sources  : false,
        source_id:null,
        account_id:null,
        name:null,
        selected_lists:[]
      },
      error: false,
      error_message: 'an error accrued, try again later',
      api:api,
      loading:false

    }
  },
  methods : {

    onSubmit(e) {

      e.preventDefault();
      this.loading = true;
      if(this.validated()){

          api.suppressionList.apply(this.form).then((response)=>{
            this.loading = false;
            this.$refs['applySuppressionList'].hide();

          }).catch((error)=>{
            this.loading = false;
          });
      }else{
        this.error = true;
        this.error_message = "please provide a name for this suppression list";
      }

    },
    validated(){

      console.log(this.form.existed_lists,this.form.selected_lists.length );
      if(this.form.existed_lists) return this.form.selected_lists.length >0;

      return (!!this.form.list_id) && (!!this.form.name);
    },
    suppressionListUploaded(result){

        this.form.existed_lists   = false;
        this.form.selected_lists  = [];
        this.error                = false;
        this.form.list_id         = result.response.list_id;

    },
    suppressionListSelected(lists){

      this.form.existed_lists = true;
      this.form.name = 'exited lists';
      this.form.selected_lists = lists;
      this.form.list_id = lists[0].list_id;

    },
    cancel(){
      this.$refs['applySuppressionList'].hide();
    }


  },
  watch:{
    source(newValue,oldValue)  {
      this.form.account_id = newValue.account_id;
      this.form.source_id = newValue.id;
    }
  }

}


</script>