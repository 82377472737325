<template>
    <b-container fluid>
                        
        <ChatStatsGroup></ChatStatsGroup>

        <CustomChart :options="chat_chart"></CustomChart>

    </b-container>
</template>

<script>

import { sofbox } from "@/config/pluginInit";
import ChatStatsGroup from './children/ChatStatsGroup'

import CustomChart from '@/components/charts/CustomChart.vue'

const week = 1
const month = 2
const day = 0
const year = 3

export default {
    name: 'ChatReport',
    components: {
        ChatStatsGroup,
        CustomChart
    },
    props: {},
    data() {
        return {
            chat_chart: {
                url: 'chat/charts',
                period: day,
                title: 'Chat Stats Chart',
                element: 'oxu5',
                axis_type: 'category',
                // key: 5,
                itemLabel: "event_id",
                exceptPeriod: [year],
                exceptDatesFor: [week, month],
                indicator: ""
            },
        }
    },
    methods: {},
    mounted() {
        sofbox.index();
    }
}
</script>