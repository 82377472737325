<template>
  <div>
    <Loader />
    <div class="wrapper">
      <NavBarStyle1 class="checkout-header" title="Checkout" :homeURL="{ name: 'dashboard1.home' }" :logo="logo">
        <template slot="responsiveRight">
          <div class="d-inline-block w-100 text-center p-3">
            <a class="iq-bg-danger iq-sign-btn" href="#" role="button" @click.prevent="logout" style="float: right;">Sign out<i class="ri-login-box-line ml-2"></i></a>
          </div>
        </template>
      </NavBarStyle1>
      <router-view/>
    </div>
  </div>
</template>
<script>
import Loader from '../components/sofbox/loader/Loader'
import { sofbox } from '../config/pluginInit'
import NavBarStyle1 from '../components/sofbox/navbars/NavBarStyle1'
import logo from '../assets/images/logo.png'



export default {
  name: 'BlankLayout',
  components: {
    Loader, NavBarStyle1
  },
  data () {
    return {
      logo: logo
    }
  },
  mounted () {
  },
  methods:{
  },
}
</script>
<style>
  @import url("../assets/css/custom.css");
  .modal-backdrop {
    opacity: .5;
  }
</style>
