<template>
  <div>
    <img src="https://coderthemes.com/hyper/saas/assets/images/mail_sent.svg" width="80"  alt="">
    <h1 class="mt-3 mb-0">Success !</h1>
    <p>A email has been send to {{email}}. Please check for an email from company and click on the included link to reset your password.</p>
    <div class="d-inline-block w-100">

      <a class="btn btn-primary mt-3" href="/"><i class="ri-home-4-line"></i>Back to Home</a>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ConfirmMail1',
  data(){
    return{
      email:''
    }
  },
  mounted(){
    this.email = this.$route.params.email;
  }
}
</script>
