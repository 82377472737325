import Qs from 'qs';

export default function (axios) {
  return {
    list: (params = {}) => {
      return axios.get(`/notifications`, { params })
        .then(response => response.data);
    },
    post: (data = {}) => {
      return axios.post('/notifications', data)
        .then(response => response.data);
    },
    put: (id, data) => {
      return axios.put(`/notifications/${id}`, data)
        .then(response => response.data)
    },


    // Old entity notification
    getNotifications: (offset = 0, limit = 25) => {
      return axios.get(`notifications?offset=${offset}&limit=${limit}`)
        .then(response => response.data);
    },
    getAdminNotifications: (params = {}) => {
      return axios.get(`notifications/admin`, { params })
        .then(response => response.data);
    },
    setStatusAsSeen: (id) => {
      return axios.put(`notifications/${id}/seen`)
        .then(response => response.data);
    }
  }
}
