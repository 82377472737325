<template>
    <li class="nav-item">
        <a href="#" class="search-toggle iq-waves-effect">
            <i class="ri-notification-2-line"></i>
            <span v-if='newsCount && notifications.length' class="bg-danger dots"></span>
        </a>
        <div class="iq-sub-dropdown iq-sub-dropdown-custom">
            <div class="iq-card iq-card-block iq-card-stretch iq-card-height shadow-none m-0">
                <div class="iq-card-body p-0">
                    <div class="bg-info p-3">
                        <h5 class="mb-0 text-white small">
                            <span class="h6">
                                Notifications
                                <small v-if="newsCount > 0" class="pt-1 small">
                                    <small class="badge badge-primary" :id="'tooltip-no-read-count'">
                                        {{ newsCount }}
                                    </small> 

                                    <b-tooltip :target="'tooltip-no-read-count'" placement="top">
                                        Message{{ newsCount > 1 ? 's' : '' }} no Read
                                    </b-tooltip>
                                </small> 
                            </span>
                        </h5>
                    </div>

                    <a href="#" class="iq-sub-card border border-muted" v-for="(notification, i) in notifications.slice(0,7)" :key="i"
                        @click="showNotification(notification, $event)">
                        <div class="media align-items-center">
                            <div class="media-body ml-3 position position-relative">
                                <!-- Notification Status -->
                                <span v-if="!notification.seen" class="spot-seen active-spot active-spot"></span>

                                <h6 class="mb-0" :class="{ 'seen': notification.seen }">
                                    {{ notification.title.substring(0, 20) + "..." }}
                                </h6>
                                <small v-if="notification.message.length > msgMaxLength" class="float-left font-size-12 text-muted" :class="{ 'font-weight-bold': !notification.seen }" v-html="notification.message.substring(0, msgMaxLength) + '...'"></small>
                                <small v-else class="float-left font-size-12 text-muted" :class="{ 'font-weight-bold': !notification.seen }" v-html="notification.message"></small>

                                <small class="float-right font-size-12 text-muted" :id="'tooltip-datetime-'+i">{{ fromNow(notification.event_time) }}</small>

                                <b-tooltip :target="'tooltip-datetime-'+i" placement="top">
                                    <span class="text-nowrap px-1"><i class="ri-calendar-line"></i></span> 
                                    <span class="text-nowrap">{{ dateFormat(notification.event_time, 'YYYY-MM-DD') }}</span> 
                                    <span class="text-nowrap px-1"><i class="ri-time-line"></i></span> 
                                    <span class="text-nowrap">{{ dateFormat(notification.event_time, 'HH:mm') }}</span>
                                </b-tooltip>
                            </div>
                        </div>
                    </a>

                    <span v-if="!notifications.length" href="#" class="iq-sub-card text-secondary">
                        <div class="media align-items-center">
                            <p style="margin: 0 auto;" class="mb-0">No notifications</p>
                        </div>
                    </span>
                    
                    
                </div>
            </div>

            <footer class="card-footer" :style="`width: 100%; bottom: 0; position: sticky;`">

                <router-link v-if="isAdmin" :to="{ name: 'notifications.admin' }" class="show-all-notifications">
                    <strong>Go To Notifications Page</strong>
                </router-link>
                <router-link v-else :to="{ name: 'notifications' }" class="show-all-notifications">
                    <strong>show all</strong>
                </router-link>

            </footer>
        </div>
    </li>
</template>

<script>
import api from '@/api/RestClient'
import moment from 'moment'
import {mapGetters} from 'vuex'

export default {
    name: 'Notifications',
    props: {},
    data() {
        return {
            notifications: [],
            newsCount: 0,
            msgMaxLength: 40,
        }
    },
    mounted() {
        this.getUserNotifications();
        
        this.$root.$on('refresh:notificationEvents', () => {
            this.getUserNotifications();
        });

    },
    methods: {
        getUserNotifications() {
            api.notificationEvents.last()
                .then(result => {
                    let response = result;
                    
                    if (response.success) {
                        this.notifications = response.data.data;
                        this.newsCount = response.data.unseen;
                    }
                });
        },
        showNotification(notification, event) {

            let routeName = 'notifications';
            if (this.isAdmin) routeName = 'notifications.admin';
            if (this.$store.getters['Auth/user'].id == notification.user_id) {
                this.notifications.map(notif => {
                    if (notif.id == notification.id)
                        notif.seen = true;
                });
                this.newsCount--;
            }

            this.$router.replace({
                name: routeName,
                params: {
                    id: notification.id
                }
            }).catch(() => { });
        },
        fromNow(date) {
            try {
                const dateConverted = moment(date).fromNow(true);
                return dateConverted
            } catch (e) {
                return '--'
            }
        },
        dateFormat(date, format = 'YYYY-MM-DD HH:mm:ss') {
            const d = new Date(date);
            return moment(d).format(format);
        },
    },
    computed: {
        ...mapGetters({
            isAdmin: 'Auth/isAdmin',
            isClient: 'Auth/isClient',
        }),
    },
    watch: {
        $route(to, from) {
            this.notifications.map(notif => {
                notif.seen = false;

            });
            this.newsCount--;
        }
    },
}
</script>

<style>
.seen {
    font-weight: 500;
}

.show-all-notifications {
    display: block;
    text-align: center;
    padding: 10px;
}

.spot-seen {
    width: 15px;
    height: 14px;
    border-radius: 40px;
    position: absolute;
    right: 10px;
    top: 5px;
}
.text-status {
    position: absolute;
    right: 5px;
    top: 0px;
}
.iq-sub-dropdown .iq-card-body {
    padding-top: 0px !important;
}

.iq-sub-dropdown-custom {
    width: 360px !important;
    right: -70px !important;
}
</style>
