<template>
<div>
    <b-col lg="6" sm="12" offset-lg="3">
        <iq-card>
            <template v-slot:headerTitle>
                <h4 class="card-title">Acccount add</h4>
            </template>
            <template v-slot:body>
                <b-form>
                    <b-form-group class="row" label-cols-sm="2" label="Name:" label-for="email">
                        <b-form-input id="name" placeholder="Enter account"></b-form-input>
                    </b-form-group>
                    <b-form-group class="row" label-cols-sm="2" label="Code:" label-for="code">
                        <b-form-input id="code" placeholder="Enter accound code"></b-form-input>
                    </b-form-group>
                    <b-form-group label="Note" label-cols-sm="2" label-for="exampleFormControlTextarea1">
                        <b-form-textarea id="exampleFormControlTextarea1" rows="5"></b-form-textarea>
                    </b-form-group>
                    <b-form-group>
                        <b-form-checkbox>Remember me</b-form-checkbox>
                    </b-form-group>
                    <b-button type="submit" variant="primary">Submit</b-button>
                    <b-button type="submit" variant="none" class="iq-bg-danger ml-3">Cancle</b-button>
                </b-form>
            </template>
        </iq-card>
    </b-col>
</div>
</template>

<script>
import {
    sofbox
} from '../../config/pluginInit'

export default {
    name: 'BlankPage',
    mounted() {
        sofbox.index()
    }
}
</script>
