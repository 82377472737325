<template>
    <div class="">

        <div class="col" v-for="(item, index) in valuesOptions" :key="index">
          <p class="mb-1">
            Daily limit: {{item.value}}

            <span v-if="item.isDefault" class="badge badge-pill badge-success">Default</span>


            <span v-for="(option, k) in item.options" :key="k">
              <span class="badge badge-pill badge-light">{{option.option_name}} - min: {{option.min}} - max: {{option.max}}</span>
            </span>

          </p>

        </div>

    </div>
</template>

<script>
import IgBtn from '@/components/inboxgeek/btn/Default.vue'
import { helper } from '@/helpers'

export default {
    name: 'DtLoDailyLimitAutoUpdateOptions',
    props: ['row'],
    components: {
        IgBtn
    },
    mounted() {

        setTimeout(() => {
          let valuesOptions = [];

          // TODO loop on values array
          for (let key in this.row.item.values) {
            let valueItem = {
              isDefault: this.row.item.values[key].is_default,
              value: this.row.item.values[key].value,
              options: [],
              code: this.row.item.values[key].id,
            };
            const valueItemOptions = _.filter(this.row.item.options, o => o.lo_daily_limit_auto_update_value_id == this.row.item.values[key].id);

            // TODO found options for each value loop

            valueItem.options = _.map(valueItemOptions, (optionData) => {
                let option = {
                  option_name: optionData.option_name,
                  min: optionData.min,
                  max: optionData.max,
                  code: optionData.id
                }
                return option
            })

            valuesOptions.push(valueItem);
          }

          this.valuesOptions = valuesOptions;

        }, 600)

    },
    data() {
        return {
            valuesOptions: [],
        }
    },
    methods: {
    }
}

</script>
