<template>
 <!--  MODAL Remove Balances  -->
 <b-row>
          <b-col lg="12" md="12">
              <b-modal  id="modal-remove-balance"  ref="remove-balance" hide-footer title="Remove Balance">
                  
                <b-form @submit="submit" class="p-4">
                  
                    <b-form-group  id="input-group-1" label=""  description="">
                      <label for="input-1" >Remove balance :</label>                        
                      <b-form-input  id="input-balance" v-model="formData.event_amount" type="number" placeholder="Event Amount to be removed" required></b-form-input>
                    </b-form-group>

                    <b-form-group id="input-group-1" label="notes :" label-for="input-1" description="">
                      <b-form-textarea rows="2" max-rows="2" id="input-description" v-model="formData.description" placeholder="Describe why the balance is updated" class="mb-2" required></b-form-textarea>
                      
                    </b-form-group>


                    <div class="text-right">
                        <b-button variant="danger" type="submit" :disabled="loading.modal"> <b-spinner small type="grow" v-show="loading.modal"></b-spinner> Remove Balance </b-button>
                    </div>
                  </b-form>

              </b-modal>
          </b-col>
      </b-row>
      <!-- END MODAL Balances  -->

</template>

<script>

import api from '@/api/RestClient';

export default {

 name:'remove-balance-modal',
 props:['account','balance'],
 mounted(){
   
 },
 data(){
   return {
    loading: {
        modal:false
      },
     formData : {
       event_amount : null,
       description: null
     },

   }
 },
 methods : {
   submit( event ) {

     event.preventDefault();

     this.loading.modal = true;
     const data         = this.formData;

     api.balances.remove(this.account.id,this.balance.id,data).then(response => {

         let variant = 'danger';
         let message = 'Something went wrong';

         if ('success' in response) {

              variant = response.success ? 'success' : 'danger';
              message = response.message;

             this.toast('account', variant, message);

             if (response.success){

                 this.$refs['remove-balance'].hide();

                 this.loading.table   = true;

                 this.$emit('balanceRemoved');
                 
             }
         }

         this.loading.modal = false;


     }).catch( (error) => {

         this.toast('Balance', 'error', error.response.data.message);
         this.loading.modal = false;

     });


   },
   toast(title = "success", variant = "success", message = "Organization updated successfully") {
     this.$bvToast.toast(message, {
         title: title,
         variant: variant
     })
   },
 }
 
}
</script>