<template>
    <span>

        <span v-if="!values.length" class="badge badge-light mx-2">Not Defined</span>
        <span v-for="(value, i) in values" :key="i" class="mx-1">
            <span class="badge badge-primary mx-2"> {{ value.value }}</span>
        </span>

    </span>
</template>

<script>
import { helper } from '@/helpers'
import { mapGetters } from 'vuex'


export default {
    name: 'DtLoDailyLimitValues',
    props: ['row'],
    components: {
    },
    data() {
        return {
            values: []
        }
    },
    mounted() {
        console.log( this.row, 'this.row' )
        this.loadLists()
    },
    methods: {
        loadLists() {
            let values = this.row.item.values;

            values.forEach((value) => {
                this.values.push(value);
            });
        },
    },
    computed: {
        ...mapGetters({
            user: 'Auth/user',
            isOwnerAccount: 'Auth/isOwnerAccount',
        }),
    }
}

</script>
