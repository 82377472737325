<template>
    <multiselect v-model="value" :value="value" placeholder="Enter Email to add" label="email" track-by="email"
        :options="options" :multiple="true" :taggable="true" @input="onChange" @tag="addEmail"></multiselect>
</template>

<script>
import Multiselect from "vue-multiselect"
import _ from 'lodash'
import { helper } from '@/helpers'

export default {
    name: 'InputTag',
    props: {
        options: {
            type: [Array],
            default: () => { return [] }
        }
    },
    components: {
        Multiselect
    },
    mounted() {
        this.setValue();
    },
    data() {
        return {
            value: null,
        }
    },
    methods: {
        setValue() {

            helper.delay(2000).then(() => {
                this.value = this.options;
            })

        },
        onChange(data) {
            this.$emit('onUpdateUsers', data)
        },
        addEmail(email) {
            this.value.push({ email: email })
            this.$emit('onUpdateUsers', this.value)
        }
    },
    watch: {
        input1(val) {
            this.state = val.length > 0 ? true : false
        }
    },
}
</script>