<template>
<b-container fluid>
    <b-row>
        <b-form-group class="col-md-12 mt-3" label-for="selectcountry">
            <multiselect :loading="isLoading" :disabled="isLoading" tag-placeholder="Manage service" placeholder="Choose service" label="name" track-by="code" v-model="account" deselect-label="Can't remove this value" :allow-empty="false" :options="accounts" :multiple="false" :taggable="true" @input="onSelect">
            </multiselect>
        </b-form-group>
    </b-row>
</b-container>
</template>

<script>
import Multiselect from 'vue-multiselect'

import api from '@/api/RestClient'

export default {
    name: 'servicesMultiselect',
    components: {
        Multiselect,
    },
    data() {
        return {
            isLoading: true,
            account: null,
            accounts: [],
            defaultService: 1
        }
    },
    mounted() {
        this.getAccounts()
    },
    methods: {
        getAccounts() {
            var accounts = [];
            api.services.list()
                .then(response => {
                    response.data.forEach((org) => {
                        let account = {};
                        account.name = org.name;
                        account.code = org.id;
                        accounts.push(account);
                    });
                })
                .catch(err => {
                    console.log(err)
                })
                .finally(() => {
                    setTimeout(() => {
                        this.accounts = accounts;
                        this.account = this.accounts.find(account => account.code === this.defaultService);
                        this.onSelect(this.account)
                        this.isLoading = false;
                    }, 1000)
                })
        },
        onSelect(selectedOption) {
            this.$emit('onSelect', selectedOption)
        }
    },
    watch: {},
}
</script>
