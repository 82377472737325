<template>
  <div class="p-0">
    <h5 class="text-dark mb-2" style="display: block;width: 100%"> Existing lists attached to this source</h5>


    <b-card  v-show="loading.lists">
      <b-skeleton animation="fade" width="85%"></b-skeleton>
      <b-skeleton animation="fade" width="55%"></b-skeleton>
      <b-skeleton animation="fade" width="70%"></b-skeleton>
    </b-card>

    <div v-show="existed_lists.length === 0 && !loading.lists" class="p-4 mb-2 rounded text-center" style="background-color: #e9edf459;">No suppression lists attached to this source</div>


    <div v-for="(existed_list,index) in existed_lists" class="py-2 my-2 px-2 rounded position-relative" :key="existed_list.id" style="color: #777D74; border: 0;background-color: #e9edf459;">

      <small class="text-dark" style="display: block;width: 100%">{{  existed_list?.name }}</small>
      <span class="btn btn-sm btn-outline-danger" style="position: absolute; right: 5px; top: 7px; padding-top: 1px; padding-bottom: 1px;" @click="detachList(existed_list?.id)">x</span>

      <div style="display: block;width: 100%">
        <p style="color: #34899c">

          <b-badge v-if="existed_list?.state === 0" class="bg-info mr-2">processing</b-badge>
          <b-badge v-else-if="existed_list?.state === 1" class="bg-success mr-2">synced</b-badge>
          <b-badge v-else class="bg-danger mr-2">error</b-badge>

          <span class="mr-2"><small>contacts</small> <b-badge class="bg-dark"> {{existed_list?.total_contacts}} </b-badge></span>
          <span>Created at : {{existed_list?.created_at}}</span></p>
      </div>
    </div>
  </div>
</template>


<script>

import api from "@/api";

export default {
   name : 'ExistedSuppressionListBySource',
  props : ['source_id'],
  mounted() {
    this.fetchExistingLists();
  },
  data() {
     return {
       loading : {
         lists : false,
       },
       existed_lists:[]
     }
  },
  methods : {
     fetchExistingLists(){
       this.loading.lists = true;
       api.geSources.fetchSuppressionLists(this.source_id).then(response => {
         this.loading.lists=false;
         this.existed_lists = response.data;
       }).catch(error => {
         this.loading.lists = false;
       })
     },
    detachList(id){
      api.suppressionList.detach({ "source_id" : this.source_id,"id" : id }).then(response => {
        this.existed_lists = this.existed_lists.filter((list) => {
          return id !== list.id;
        });
      }).catch(error => {
        this.loading.lists = false;
      })
    }

  }


}



</script>
