<template>
  <b-container fluid>
    <b-modal id="addLoDailyLimitAutoUpdateWizardModal" ref="addLoDailyLimitAutoUpdateWizardModal" title="New Logic" size="xl" centered hide-footer :no-close-on-backdrop="true">

      <AddLoDailyLimitAutoUpdateWizardModalNav :steps="steps" :current_step_index="current_step_index"></AddLoDailyLimitAutoUpdateWizardModalNav>

      <b-row>
        <b-col lg="12" sm="12" class="p-4">
            <div v-if="currentStep">
              <component
                  :account="account"
                  :is="currentStep.component"
                  :form="form"
                  :lists="lists"
                  :loadingProp="loading"
                  @back="handelBackEvent"
                  @cancel="handelCancelEvent"
                  @next="nextStep"
                  @finish="finishWizard"
                  @preloadLists="handelPreloadListsEvent"

              />
            </div>

        </b-col>
      </b-row>
    </b-modal>
  </b-container>
</template>


<script>

import FinishLoDailyLimitAutoUpdateWizardModal from '@/components/inboxgeek/modals/Wizards/LoDailyLimitAutoUpdate/steps/FinishLoDailyLimitAutoUpdateWizardModal.vue';
import LogicLoDailyLimitAutoUpdateWizardModal from '@/components/inboxgeek/modals/Wizards/LoDailyLimitAutoUpdate/steps/LogicLoDailyLimitAutoUpdateWizardModal.vue';
import OptionsLoDailyLimitAutoUpdateWizardModal from '@/components/inboxgeek/modals/Wizards/LoDailyLimitAutoUpdate/steps/OptionsLoDailyLimitAutoUpdateWizardModal.vue';
import AddLoDailyLimitAutoUpdateWizardModalNav from '@/components/inboxgeek/modals/Wizards/LoDailyLimitAutoUpdate/AddLoDailyLimitAutoUpdateWizardModalNav.vue';
import api from "@/api";
import { mapGetters } from "vuex";

export default {
  name:'CreateLoDailyLimitAutoUpdateWizardModal',
  props:['account'],
  components:{
    FinishLoDailyLimitAutoUpdateWizardModal,
    LogicLoDailyLimitAutoUpdateWizardModal,
    OptionsLoDailyLimitAutoUpdateWizardModal,
    AddLoDailyLimitAutoUpdateWizardModalNav
  },
  data(){
    return{
      current_step_index:0,
      form:{},
      lists:[],
      loading:false,
      steps:[{
        index:0,
        title:'Logic Information',
        name:'logic',
          component:LogicLoDailyLimitAutoUpdateWizardModal.name,
        icon:'ri-profile-line'
      },{
        index:1,
        title:'Conditions',
        name:'options',
        component:OptionsLoDailyLimitAutoUpdateWizardModal.name,
        icon:'ri-file-list-line'
      },{
        index:2,
        title:'Summary',
        name:'finish-step',
        component:FinishLoDailyLimitAutoUpdateWizardModal.name,
        icon:'ri-check-fill'
      }
      ]

    }
  },
  methods:{

    nextStep(data){

      // save emitted data into the form

      if(data){
        this.form[this.steps[this.current_step_index].name] = data;

      }

      this.current_step_index++;

    },

    handelBackEvent(){

      // remove current step data if exist:

      if(this.form[this.steps[this.current_step_index].name]){
        delete this.form[this.steps[this.current_step_index].name];
      }

      this.current_step_index--;
    },

    handelPreloadListsEvent(platform){

      this.loading=true;
      // fetch lists directly after selecting platform ( saves time to user in the upcoming steps )
      this.lists = [];

      let params = {
        platform_name : platform.name.toLowerCase(),
        entities      : 'lists',
        platform_id   : platform.id
      };

      api
          .platforms
          .fetchServices(params)
          .then((response)=>{
            this.loading=false;
            this.lists = response.data;
          }).catch(error=>{
            this.loading = false;
      });
    },

    finishWizard(){

      // after user confirms data form , send create request, returns response and close the modal.
        this.loading = true;

        let formData = {
          name: this.form.logic.name,
          options: this.form.options.options,
          account: this.isAdmin ? this.account : null
        };
        // console.log('test data', formData); return;

        api.loDailyLimitAutoUpdates.post(formData)
            .then((response)=>{

                this.loading = false;

                this.$swal('success', 'LiveOpens Daily Limit Auto Update successfully created', 'success');
                this.$emit('LoDailyLimitAutoUpdateCreated');
                this.handelCancelEvent();


            }).catch((error)=>{
              this.loading = false;
              let message = 'Something went wrong!';

              if (error.response && error.response.status !== 500) {
                message = error.response.data.message
              }

              this.$swal('error', message, 'error');

        });
    },

    handelCancelEvent(){
      this.form = {};
      this.$refs['addLoDailyLimitAutoUpdateWizardModal'].hide();
      this.current_step_index=0;

    }

  },
  computed:{
    ...mapGetters('Auth', ['user', 'isAdmin']),
    currentStep(){

      // update current steps when the index is changed

      let current_step = null;

      this.steps
          .forEach((step)=>{
            if(this.current_step_index === step.index){
              current_step = step;
            }
          });

      return current_step;
    }
  }
}

</script>
