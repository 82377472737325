<template>

    <li class="nav-item dropdown">
        <a href="#" class="search-toggle iq-waves-effect active" >
            <span class="ripple rippleEffect" style="width: 75px; height: 75px; top: 5.5px; left: -7.5px;"></span>
            <i class="ri-message-3-line"></i>
            <span v-if="newChats" class="bg-danger dots"></span>
            <!-- <span v-else class="badge badge-pill badge-primary badge-up count-mail">{{ newChats }}</span> -->
        </a>
        <div class="iq-sub-dropdown iq-sub-dropdown-custom">
            <div class="iq-card shadow-none m-0">
                <div class="iq-card-body p-0 ">
                    <div class="bg-primary p-3">
                        <h5 class="mb-0 text-white">All Messages<small class="badge  badge-light float-right pt-1">{{
                                newChats }}</small></h5>
                    </div>
                    <div style="overflow-y: scroll;max-height: 300px">
                      <router-link v-for="(chat, index) in limitedItems" :key="index"
                                   :to="{ name: 'chats.admin', query: { id: chat.id } }" class="iq-sub-card px-4 py-0"
                                   :class="{ 'font-weight-bolder': !chat.status }" :style="{'background' : (chat.status == 1) ? 'rgb(246 246 246)' : '#fff' }">
                        <div class="media align-items-center" >
                          <div class="text-info">
                            <img :src="(chat.status == 1) ?
                               'https://ui-avatars.com/api/?rounded=true&name='+chat.client?.name
                               :  'https://ui-avatars.com/api/?rounded=true&background=random&name='+chat.client?.name" alt="">
                          </div>
                          <div class="media-body ml-3 text-dark">
                            <h6 class="m-0 mt-1" :style="{'color' : (chat.status == 1) ? 'rgb(165 165 165)' : '' }">{{ chat.client ? chat.client.name : '--' }}</h6>
                            <p class="m-0" :style="{'color' : (chat.status == 1) ? 'rgb(165 165 165)' : '' }"><small>{{ chat.name }}</small></p>
                            <small class="float-right" :style="{'color' : (chat.status == 1) ? 'rgb(165 165 165)' : '' }">{{ getDateChatRoom(chat) }}</small>
                          </div>
                        </div>
                      </router-link>
                    </div>


                    <router-link :to="{ name: 'chats.admin' }" class="show-chats">
                       <span class="btn btn-primary p-2 w-100 mt-2">Go To Chats Page</span>
                    </router-link>
                </div>
            </div>
        </div>
        <div v-show="notification.message" style="width: 350px;position: fixed;bottom: 10px;right: 10px;padding: 1rem;background: white;color: #666;" class="border rounded shadow-sm"><h6 style="
            color: white;
            background-color: #dd406c;
            margin: -17px -1rem 5px -1rem;
            padding: 1rem;
            border-radius: 7px 7px 0px 0px;
        "> New Message from {{ notification.client?.name  ?? 'client'}}  </h6>
                <p style="
            margin-bottom: 0;
            padding: 5px;
        "><small>{{ notification.message?.message }}</small></p></div>
    </li>


</template>

<script>
import api from '@/api/RestClient'
import moment from 'moment'
import {mapGetters} from 'vuex'
import Pusher from 'pusher-js';
import squareLogo from '@/assets/images/min-logo.png';

const notification = {
  title: 'Chat message',
  options: {
    icon: squareLogo,
    body: 'New Chat message!'
  },
  events: {
    onerror: function () {
        console.log('Custom error event was called');
    },
    onclick: function () {
        console.log('Custom click event was called');
        notification.close();
        window.parent.focus();
    },
    onclose: function () {
        console.log('Custom close event was called');
    },
    onshow: function () {
        console.log('Custom show event was called');
    }
  }
}

export default {
    name: 'ChatNotifications',
    props: {},
    data() {
        return {
            chatRooms: [],
            newChats: 0,
            maxItemsNotif: 5,
          notification:{
              client:null,
            message:null,
          }
        }
    },
    mounted() {
        this.getChatRooms();

        const pusher  = new Pusher(process.env.VUE_APP_PUSHER_APP_KEY, {cluster: 'eu',});
        const channel = pusher.subscribe('support.chat.room');

        channel.bind('notification', (data)=> {
          this.lunchNotification(data);
          this.getChatRooms()
          this.notifyNewMessage();
        });

    },
    methods: {
        getChatRooms() {


            // TODO Get all rooms 
            // If new room Add Pusher Listener 

            api.default.get('/rooms/admin/chats')
                .then(response => {

                    this.chatRooms = response.data

                    let newChatRooms = _.filter(response.data, (item) => {
                        return !item.status;
                    });
                    this.newChats = newChatRooms.length;
                })

        },
        messageArrived(data) {

            let chatRoom = _.find(this.newChats, {'id': parseInt(data.chat_room_id)});

            if(chatRoom && !data.client_id) {
                chatRoom.status = 1;
                _.remove(this.newChats, obj => obj === chatRoom);
            }

            // Notify only for client message
            if(data.client_id) this.notifyNewMessage();

        },
        getDateChatRoom(chatRoom) {
            const time = moment(chatRoom.created_at);

            const formattedDate = time.format('DD MMM');

            // const formattedTime12Hour = time.format('h:mm:ss A');

            return formattedDate;
        },
        notifyNewMessage() {
            
            // Check if the browser supports notifications
            if (!("Notification" in window)) {
                alert("This browser does not support desktop notification");
            } else if (Notification.permission === "granted") {
                this.notifyNewChatMessage();
            }

        },
        lunchNotification(data){
            this.playNotificationSound();

            this.notification.message = data.message;
            this.notification.client = data.client;

          setTimeout(() => {

            this.notification.message = null;
            this.notification.client = null;

          }, 2500);
        },
        playNotificationSound(){

          let audio = new Audio(require('@/assets/sounds/notification-sound.mp3'));
          audio.play();
          document.title = document.title.replace(' ( new message )','') + ' ( new message )';
        },
        notifyNewChatMessage() {
            this.$notification.show(notification.message?.message)
        },
        newChatRoom(room) {

            let chatRoom = _.find(this.chatRooms, {'id': room.id});

            if(!chatRoom) {
                this.chatRooms.unshift(room);

                const pusher = new Pusher(process.env.VUE_APP_PUSHER_APP_KEY, { cluster: 'eu', });

                const channel = pusher.subscribe('support.chat.' + room.id);
                channel.bind('message', (message) => { this.messageArrived(message) });
            }

        },
    },
    computed: {
        ...mapGetters({
            isAdmin: 'Auth/isAdmin',
            isClient: 'Auth/isClient',
        }),
        limitedItems() {
            return this.chatRooms;
        }
    },
    watch: {
        // $route(to, from) {
        //     this.notifications.map(notif => {
        //         notif.seen = false;

        //     });
        //     this.newChats--;
        // }
    },
}
</script>

<style>
.seen {
    font-weight: 500;
}

.show-all-notifications {
    display: block;
    text-align: center;
    padding: 10px;
}

.spot-seen {
    width: 15px;
    height: 14px;
    border-radius: 40px;
    position: absolute;
    right: 10px;
    top: 5px;
}

.text-status {
    position: absolute;
    right: 5px;
    top: 0px;
}

.iq-sub-dropdown .iq-card-body {
    padding-top: 0px !important;
}

.show-chats {
    display: block;
    text-align: center;
    padding: 10px;
}
.iq-sub-dropdown-custom {
     width: 360px !important;
    right: -10em !important;
}
</style>
