<template>
    <b-modal :id="id" :ref="id" :title="source ? 'Edit Source - ' + source.name : 'Add New Source'" ok-title="Next"
        cancel-title="Previous" size="xl" @show="initModal" @ok="handleNext" @cancel="handleBack" @hidden="hideModal">

        <b-row>
            <b-col lg="12" sm="12">
                <iq-card v-if="renderModal">
                    <template v-slot:body>
                        <b-col md="12">
                            <b-form id="ll-edit-form-wizard2" class="mt-4">

                                <div v-if="renderStep">
                                    <component :is="'PartialName'" ref="step-source" v-bind="{ form, errors }">
                                    </component>


                                    <fieldset v-if="!editPlatform && (source.platform || form.platform)">
                                        <iq-card class="px-0">
                                            <h3 class="text-left mb-4">Platform & List Informations:

                                                <b-form-checkbox v-model="form.sendRecords" name="check-button"
                                                    class="float-right small" button-variant="primary" size="md" switch
                                                    @input="">
                                                    send records
                                                </b-form-checkbox>
                                            </h3>


                                            <div v-if="showPlatform" class="card-body iq-card-body pt-0 pb-1 px-0">

                                                <div v-if="dataIsLoading" class="text-center d-inline-block my-2">
                                                    <span class="fa-stack fa-lg">
                                                        <i class="fa fa-spinner fa-spin"></i>
                                                        <!-- fa-stack-2x fa-fw -->
                                                    </span>&emsp;Processing ...
                                                </div>
                                                <b-row v-else md="12">
                                                    <!-- <Transition name="fade"> -->
                                                    <b-form-group class="col-md-6" label="" label-for="platform"
                                                        :disabled="true">
                                                        <label for="">
                                                            Platform

                                                            <span v-if="showPlatformIndicator">
                                                                <span class="badge badge-secondary mx-1">
                                                                    {{ source.platform_recorded.nickname ?? '--' }}
                                                                </span> &rarr;
                                                            </span>


                                                            <span v-if="form.platform_recorded"
                                                                class="badge badge-primary mx-1">
                                                                {{ form.platform_recorded.nickname ??
                                                                    form.platform_recorded.name }}
                                                            </span>
                                                        </label>
                                                        <PlatformsMultiselect class="p-0"
                                                            :platform="form.platform && form.platform_code ? form.platform.name : null"
                                                            :disabledProp="true">
                                                        </PlatformsMultiselect>
                                                    </b-form-group>

                                                    <b-form-group v-show="form.list || source.lists.length" class="col-md-6"
                                                        :disabled="true">
                                                        <label for="">Reception List</label>

                                                        <b-form-input v-if="source.lists.length && !form.list" type="text" :value="source.lists[0].list_name"></b-form-input>
                                                        <b-form-input v-else-if="form.list && form.list.name" type="text" :value="form.list.name"></b-form-input>
                                                    </b-form-group>
                                                    <!-- </Transition> -->
                                                </b-row>

                                            </div>
                                        </iq-card>
                                    </fieldset>

                                    <!-- <b-button v-show="!editPlatform" block variant="info" class="mb-3 mr-1"
                                        @click="editPlatform = true">
                                        <i class="ri-pencil-line"></i>Edit Platform & Reception List
                                    </b-button> -->

                                    <fieldset v-show="editPlatform">
                                        <component :is="'PartialPlatform'" ref="step-platform"
                                            v-bind="{ form, errors, btnAdd: false, isConnected: isConnected }"
                                            @onAddPlatform="cleanPlatformStep" @platformSelected="handlePlatformSelect"
                                            @updatePlatform="selectFromAdd" @updateAccount="updateAccount"
                                            @onCustomLink="handleCustomLink" @onNewPlatform="handlePlatformAdd"
                                            @updateApiKey="handleApiKey">
                                        </component>

                                        <component :is="'PartialIntegration'" ref="step-integration"
                                            v-bind="{ form, errors, btnAdd: false, isConnected: isConnected }"
                                            @updateList="form.list = $event">
                                        </component>

                                        <b-button block variant="success" class="mb-3 mr-1" @click="editPlatform = false">
                                            <i class="ri-check-double-line"></i>Done
                                        </b-button>
                                    </fieldset>


                                </div>
                                <!-- End Dynamic call Current step component -->

                                <!-- END Add Setting Modal-->

                            </b-form>
                        </b-col>

                    </template>
                </iq-card>
            </b-col>
        </b-row>

        <template #modal-footer="{ ok, cancel, hidden }">

            <button class="btn mx-2 btn-secondary" v-if="btnBackChoise" :disabled="isLoading">
                Choose an existing platform <i class="ri-arrow-go-back-line"></i>
            </button>

            <button v-if="!liveLeadSource.id" class="btn btn-primary" @click="ok"
                :disabled="isLoading || editPlatform">

                <b-spinner small type="grow" v-show="isLoading"></b-spinner>
                Save Change
            </button>

            <button v-else class="btn mx-2 btn-secondary" @click="hideModal()">
                Done
            </button>

        </template>
    </b-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import store from '@/store/index'
import api from '@/api/RestClient'
import Multiselect from 'vue-multiselect'
import PartialName from './partials/Name.vue'
import PartialFinish from './partials/Finish.vue'
import PartialPlatform from './partials/Platform.vue'
import PartialIntegration from './partials/Integration.vue'
import PartialIntegrationLiveLead from '../children/IntegrationLiveLead.vue'
import _ from 'lodash';
import { services, helper } from '@/helpers';
import { PLATFORMS_CODE, OAUTH_ESP } from "@/constantes";
import PlatformsMultiselect from '@/components/inboxgeek/inputs/PlatformsMultiselect'


export default {
    name: 'SourceModalSingleStepEdit',
    components: {
        Multiselect,
        PartialName,
        PartialFinish,
        PartialPlatform,
        PartialIntegration,
        PartialIntegrationLiveLead,
        PlatformsMultiselect
    },
    props: ['id', 'organization', 'source'],
    computed: {
        ...mapGetters({
            isAdmin: 'Auth/isAdmin',
            isClient: 'Auth/isClient',
            user: 'Auth/user',
        }),
        showPlatform() {
            return this.form.platform_code && this.form.sendRecords;
        },
        showPlatformList() {
            return this.form.platform_code && this.form.list && this.form.sendRecords;
        },
        showPlatformIndicator() {
            return this.form.platform_recorded
                &&
                (this.source.platform && this.source.platform?.nickname && (this.source.platform?.nickname != this.form.platform_recorded?.nickname))
        }
    },
    created() {
    },
    mounted() {
        this.form = this.resetForm();
    },
    data() {
        return {
            isLoading: true,
            renderModal: true,
            renderStep: true,
            btnBackChoise: false,
            hideNextBtn: false,
            currStep: 0,
            currStepComponent: null,
            oautESP: OAUTH_ESP,
            form: {},
            steps: [
                { name: 'website-tab', title: 'Source', component: 'PartialName', active: true, done: false, fields: ['name', 'website'], icon: 'ri-pages-fill' },
                { name: 'platform-tab', title: 'Platform', component: 'PartialPlatform', active: true, done: false, fields: ['platform_code', 'account_id', 'api_key'], icon: 'ri-apps-fill' },
                { name: 'integration-tab', title: 'Reception List', component: 'PartialIntegration', active: true, done: false, fields: ['list'], icon: 'ri-play-list-add-fill' },
                { name: 'finish-tab', title: 'Finish', component: 'PartialFinish', active: false, done: false, fields: [], icon: 'ri-check-double-line' },
            ],
            errors: {},
            p_records: [],
            showSkip: true,
            showIntegrationLiveLeads: true,
            liveLeadSource: {},
            isConnected: false,
            showScript: false,
            editPlatform: false,
            dataIsLoading: false,
        }
    },

    methods: {
        initModal() {

            this.hideNextBtn = false;
            this.isLoading = false;
            this.currStep = 0;
            this.currStepComponent = this.steps[this.currStep].component;
            this.liveLeadSource = {};
            this.editPlatform = false;

            this.isConnected = false;
            this.resetErrors();

            this.steps.forEach(element => {
                element.active = false
                element.done = false
            });

            this.steps[0].active = true
            this.form = this.resetForm()

            this.resetStepComp();

            this.setPlatform();

            this.resetModal();
        },
        resetForm() {
            return !this.source ? {
                name: '',
                website: '',
                source: '',
                platform_code: null,
                platform: {},
                account_id: '',
                api_key: null,
                list: null,
                lists: [],
                custom: { isVisible: null, checkbox: null, link: null },
                enableLimit: false,
                limit: -1,
                organization: this.isAdmin ? this.organization : this.user.account_id,
                sendRecords: true,
                duplicateByAccount: true,
                duplicateByType: null,
                recurrent: null
            } : this.getSource()
        },
        resetModal() {
            this.renderModal = false;

            this.$nextTick(() => {
                this.renderModal = true;
            })
        },
        updateFieldsRequirePlatform() {
            this.isLoading = true;
            const CURR_STEP_COMP = _.find(this.steps, function (o) { return o.name == 'platform-tab'; });

            if (!this.$refs['step-platform'].showCols.accountId) {
                if (CURR_STEP_COMP.fields.includes('account_id')) CURR_STEP_COMP.fields.pop('account_id')
            } else {
                if (!CURR_STEP_COMP.fields.includes('account_id')) CURR_STEP_COMP.fields.push('account_id')
            }

            if (
                (typeof this.source.platform !== 'undefined' && this.source.platform)
                && CURR_STEP_COMP.fields.includes('api_key')
                && this.form.platform_code) {

                CURR_STEP_COMP.fields = _.remove(CURR_STEP_COMP.fields, function (field) {
                    return field !== 'api_key';
                });
            }

            if (
                (typeof this.source.platform !== 'undefined' && this.source.platform)
                && CURR_STEP_COMP.fields.includes('platform_code')
                && this.form.platform_code) {

                CURR_STEP_COMP.fields = _.remove(CURR_STEP_COMP.fields, function (field) {
                    return field !== 'platform_code';
                });
            }
        },
        async handleNext(e) {
            e.preventDefault();
            this.isLoading = true;
            this.showSkip = false;

            this.updateFieldsRequirePlatform()

            if (!this.formIsValid()) {
                await helper.delay(300).then(() => {

                    if (!this.form.platform_code) {
                        this.$swal('Invalid Form Data', 'Please Check Form to Continue!', 'warning')
                    }

                    this.$refs['step-source'].showErrors();

                    this.isLoading = false;
                })
                return
            } else {
                this.handleSubmit();
            }

        },
        handleBack(e) {
            e.preventDefault()
            this.showSkip = true;

            const STEP = this.steps[this.currStep]

            if (this.currStep > 0) {
                this.currStep--
                this.currStepComponent = this.steps[this.currStep].component

                STEP.active = false
                this.steps[this.currStep].done = false
            }

        },
        saveWithoutPlatform() {
            this.hideNextBtn = true;

            this.$swal({
                title: 'Are you sure?',
                text: `Do You Want Save Source Without Platform and Reception List?`,
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'Cancel!',
                showCloseButton: true,
                showLoaderOnConfirm: true
            }).then((result) => {
                if (result.value) {
                    this.handleSubmit(true)
                } else {
                    this.isLoading = false;
                    this.hideNextBtn = false;
                }
            })
        },
        cleanPlatformStep() {


        },
        showStepIntegration() {
            this.$emit('showIntegrationModal', this.form);
        },
        handlePlatformSelect(data, goToNext = true) {
            this.resetErrors();

            this.isLoading = true;
            this.showSkip = true;

            const CURR_COMP = this.$refs['step-platform'];

            this.cleanReceptionList();

            if (this.form.platform_code && this.form.platform_recorded && (this.form.platform_recorded.id == data.id)) {
                this.form.platform_code = null;
                this.form.platform_recorded = null;

                helper.delay(1000).then(() => {
                    this.cleanPlatform();
                });
                return
            }

            let esp_platform = _.find(CURR_COMP.platforms, function (o) {
                return o.name == data.name;
            });


            if (esp_platform && esp_platform.code) {
                this.form.platform_code = esp_platform.code
            }

            if (data && data.account) {
                this.form.account_id = data.account
            }
            this.form.api_key = data.key ?? null;
            this.form.platform = esp_platform;

            if (!goToNext && esp_platform) {

                this.form.platform = data
                this.form.platform_code = esp_platform.code
            }

            if (typeof esp_platform === 'object' && typeof esp_platform.code !== 'undefined') {

                this.form.platform_recorded = data

                let _data = { ...data, code: esp_platform.code, img: esp_platform.img }
                if (!data.api_key) _data.api_key = this.form.api_key

                if (esp_platform.code == PLATFORMS_CODE.INBOXSUITE) {
                    if (goToNext) this.goToNextStep();
                    return;
                }


                return this.fetchLists(_data)
                    .then((response) => {

                        if (response.success) {

                            if (esp_platform.code == PLATFORMS_CODE.CONVERKIT) response.data = response.data.tags;
                            if (esp_platform.code == PLATFORMS_CODE.BLASTABLE) response.data = response.data.data.records;

                            this.lists = services.default.formatLists(response.data, esp_platform)
                            this.selectFromAdd(data)
                            this.form.lists = response.data
                            // this.form.list = null;
                            this.$refs['step-integration'].setReceptionList(this.form.list)

                            this.isLoading = false;
                        }

                    })
                    .catch((err) => {
                        if (esp_platform.code != PLATFORMS_CODE.WEBHOOK) this.$swal('Checking Api Failed', 'Error or this Api is not valid', 'error')
                        this.isLoading = false
                        // this.form.list = null
                        this.form.lists = []
                        this.showSkip = true;
                        this.$refs['step-integration'].setReceptionList(this.form.list)
                        return []
                    })

            }

        },
        selectFromAdd(value) {
            let esp_platform = value;
            const CURR_COMP = this.$refs['step-platform'];

            let _esp_platform = _.find(CURR_COMP.platforms, function (o) {
                return o.name == value.name;
            });

            if (_esp_platform) {
                this.form.platform_code = _esp_platform.code
            }

        },
        updateAccount(data) {

        },
        handleCustomLink(custom) {
            this.form.custom = custom
        },
        handlePlatformAdd(value) {
        },
        handleApiKey(value) {
        },
        async handleCheckForm() {


            let result = { valid: true, data: [] };

            let platform_recorded = this.form.platform_recorded ?? null

            if (!this.formIsValid()) {
                result.false = false
                this.$swal('Select Platform or Add New', 'Please Add Platform or Select once to continue!', 'warning')
                return new Promise((resolve, reject) => reject(result));
            }

            if (platform_recorded) {
                let fetchLists = async () => {

                    return await this.loadPlatformLists(platform_recorded)
                        .then((response) => {

                            result.valid = true
                            if (Array.isArray(response)) {
                                this.form.lists = response
                                result.data = response
                            }

                        })
                        .catch((error) => {
                            this.$swal('Checking Api Failed', 'Error or this Api is not valid', 'error')
                            result.valid = false
                            this.isLoading = false
                            return new Promise((resolve, reject) => reject(result));
                        })

                }

                await fetchLists()
            }


            return new Promise(resolve => resolve(result));
        },
        resetErrors() {
            this.errors = {}
        },
        checkStepSource() {
            if (!this.form.name || !this.form.name.trim()) {
                this.errors.name = 'Name is required.';
            }
            if (!this.form.website || !this.form.website.trim()) {
                this.errors.website = 'Website is required.';
            }
            if (!helper.isValidURL(this.form.website.trim())) {
                this.errors.website = 'Please insert valid URL Website.';
            }

            if(this.form.duplicateByType == "recurrent" && (!this.form.recurrent || !this.form.recurrent.trim())) {
                this.errors.recurrent = 'Recurrent is required.';
            }
        },
        checkStepPlatform() {

            if (this.form.sendRecords) {
                const CURR_COMP = this.$refs['step-platform']

                const PLATFORM_FIELDS = _.find(this.steps, function (o) { return o.name == 'platform-tab'; });
                let _PLATFORM_FIELDS = PLATFORM_FIELDS ? PLATFORM_FIELDS.fields : []


                if (CURR_COMP.showCols.accountId && (!this.form.account_id || typeof this.form.account_id == 'undefined')) {
                    this.errors.account_id = 'Account is required.';
                }
                if (!this.form.platform_code && (_PLATFORM_FIELDS.includes('platform_code'))) {
                    this.errors.platform_code = 'Platform is required.';
                }
                if ((!this.form.api_key || !this.form.api_key.trim()) && (_PLATFORM_FIELDS.includes('api_key'))) {
                    this.errors.api_key = 'Api is required.';
                }
            }

        },
        checkStepIntegration() {
            if (this.form.platform_code && !this.form.list) {
                this.errors.lists = 'List is required.';
            }
        },
        formIsValid() {
            this.resetErrors();

            this.checkStepSource();

            return helper.isEmpty(this.errors);
        },
        callbackCheckForm() {

            // Trigger next
            this.goToNextStep()

        },
        async goToNextStep() {
            await helper.delay(1500)

            const STEP = this.steps[this.currStep]

            this.currStep++
            this.currStepComponent = this.steps[this.currStep].component

            STEP.done = true
            this.steps[this.currStep].active = true
            this.isLoading = false;

        },
        getPostPlatform() {
            return {
                name: this.form.platform.name,
                account: this.form.account_id,
                key: this.form.api_key,
                organization: this.isAdmin ? this.organization : null
            }
        },
        isValidJson(value) {

            if (typeof value == "undefined") {
                return null
            }

            if (/^[\],:{}\s]*$/.test(value.replace(/\\["\\\/bfnrtu]/g, '@').
                replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']').
                replace(/(?:^|:|,)(?:\s*\[)+/g, ''))) {
                let token = JSON.parse(value)

                if (typeof token.accessToken !== 'undefined') token = token.accessToken
                else if (typeof token.access_token !== 'undefined') token = token.access_token

                if (typeof token.errors !== 'undefined' || typeof token.error !== 'undefined') {
                    return null
                }

                return token
            }

            return value
        },

        // Fetch and Post
        async fetchLists(platform) {


            let esp_code = platform.code

            let customUrl = null


            this.form.custom.link = null


            if (this.form.custom && (typeof this.form.custom.checkbox !== 'undefined')) {
                if (this.form.custom.checkbox) customUrl = this.form.custom.link
            }


            let options = {
                entity: [PLATFORMS_CODE.ONTRAPORT].includes(platform.code) ? 'tags' : 'lists',
                account: platform.account,
                organization: platform.account_id,
                platform_id: platform.id,
                customUrl: customUrl
            }


            let _params = services.default.getListParamsByEspAndPlatform(platform, this.form.platform_recorded, options)

            return api.platforms.fetchServices(_params)
        },
        loadPlatformLists(data) {
            const currComponent = this.$refs['step-platform']


            let esp_platform = _.find(currComponent.platforms, function (o) { return o.name == data.name; });
            this.form.platform = esp_platform


            if (typeof esp_platform === 'object' && typeof esp_platform.code !== 'undefined') {
                this.form.platform_recorded = data
                this.form.platform = esp_platform.code
                this.form.account_id = data.account
                this.form.api_key = data.api_key

                let _data = { ...data, code: esp_platform.code, img: esp_platform.img }
                if (!data.api_key) _data.api_key = this.form.api_key

                return this.fetchLists(_data)

                    .then((response) => {

                        if (response.success) {


                            if (this.form.platform.code == PLATFORMS_CODE.CONVERKIT) response.data = response.data.tags;
                            if (this.form.platform.code == PLATFORMS_CODE.BLASTABLE) response.data = response.data.data.records;

                            this.lists = services.default.formatLists(response.data, esp_platform)
                            this.selectFromAdd(data)

                            return response.data
                        }
                    })
                    .catch((err) => { return [] })
            }
        },
        handleSubmit(skipSteps = false) {

            this.isLoading = true;

            let message = 'Something went wrong!', title = 'Error!', success = 'danger', icon = 'error';


            // Check form Validation
            if (this.formIsValid() || this.hideNextBtn) {


                if (this.isAdmin && this.organization) {
                    this.form.organization = this.organization
                }

                const data = this.formData();
                let apiAction = null;

                if (typeof this.source !== 'undefined' && this.source.id) {
                    let code = this.source.id;
                    apiAction = api.geSources.put(code, data);
                } else {
                    apiAction = api.geSources.post(data);
                }

                // send ajax query and interpret result for trigger next step
                apiAction.then((response) => {
                    if (response.success) {
                        this.liveLeadSource = response.data.resource;
                        this.form.code = this.liveLeadSource.code

                        icon = 'success'
                        title = 'Success'
                        message = response.message
                        success = 'success'

                        if (!this.liveLeadSource.platform_id) {
                            this.showIntegrationLiveLeads = false;
                        }

                    }

                })
                    .catch((error) => {
                        console.log('Errors')
                    })
                    .finally(() => {
                        this.$swal({
                            title: title,
                            icon: icon,
                            text: message,
                            type: success,
                            showCancelButton: false,
                            showCloseButton: true,
                        }).then((result) => {
                            helper.delay(3000).then(() => {
                                this.isLoading = false;
                                this.hideNextBtn = false;
                                if (this.liveLeadSource) {
                                    this.$emit('add', this.liveLeadSource)
                                    // this.$bvModal.hide(this.id)
                                }
                            });
                        })
                    })


            } else {
                this.isLoading = false;
                if (this.form.platform_code) {
                    this.$swal({
                        title: 'LiveLeads Update Source',
                        icon: 'warning',
                        text: 'Please Check Form Data and ReTry',
                        type: 'warning',
                        showCancelButton: false,
                        showCloseButton: true,
                    })
                }
            }


        },
        formData() {
            let duplicateByType = null;

            if(this.form.duplicateByType == "recurrent") {
                duplicateByType = this.form.recurrent;
            } else if (this.form.duplicateByType == "no_by_account") {
                duplicateByType = -2;
            } else if (this.form.duplicateByType == "no_by_source") {
                duplicateByType = -1;
            }

            return {
                name: this.form.name,
                website: this.form.website,
                source: this.form.source,
                platform: this.form.platform_recorded ? this.form.platform_recorded.id : null,
                lists: this.form.list,
                organization: this.isAdmin ? this.organization : null,
                limit: this.form.limit,
                duplicate_by_account: this.form.duplicateByAccount ?? false,
                duplicate_by_type : duplicateByType
            }
        },
        hideModal() {
            this.$bvModal.hide(this.id)
        },
        getSource() {
            if (typeof this.source !== 'undefined' && this.source) {
                let list = null;

                if (this.source.lists && this.source.lists.length) {
                    list = { code: this.source.lists[0].id, name: this.source.lists[0].list_name };

                    // WEBHOOK Only
                    if (this.source.platform && this.source.platform.name.toLowerCase() == 'webhook') {
                        list.code = this.source.lists[0].p_list_id ?? null;
                        list.link = JSON.parse(this.source.lists[0].custom_columns).link;
                    }
                }

                let duplicateByType = null;
                let recurrent = null;

                if(this.source.duplicate_by_type < 0) {

                    if(this.source.duplicate_by_type == -2){ duplicateByType = "no_by_account"; }
                    else if (this.source.duplicate_by_type == -1){ duplicateByType = "no_by_source"; }

                } else {
                    duplicateByType = "recurrent";
                    recurrent = this.source.duplicate_by_type;
                }

                return {
                    id: this.source.id,
                    name: this.source.name,
                    website: this.source.website,
                    platform_code: this.source.platform_id,
                    platform: this.source.platform,
                    account_id: this.source.account_id,
                    api_key: null,
                    list: list,
                    lists: this.source.lists,
                    custom: { isVisible: null, checkbox: null, link: null },
                    enableLimit: this.source.limit !== -1 ? true : false,
                    limit: this.source.limit,
                    organization: this.source.account_id,
                    duplicateByAccount: this.source.duplicate_by_account ? true : false,
                    duplicateByType: duplicateByType,
                    recurrent: recurrent
                }
            }

            return {}
        },
        resetStepComp() {
            this.renderStep = false;

            this.$nextTick(() => {
                this.renderStep = true;
            })
        },
        setPlatform() {
            if (this.source.platform) {
                this.dataIsLoading = true;

                if (typeof this.form.platform == 'number' && this.form.platform.platform_recorded) {
                    this.form.platform = this.form.platform_recorded;
                }

                helper.delay(1000).then(() => {
                    if (typeof this.$refs['step-platform'].$refs['exist-platform'] !== 'undefined' && typeof this.$refs['step-platform'].$refs['exist-platform'].setPlatform == 'function') {
                        const data = { ...this.source.platform, code: this.source.platform.id }
                        this.handlePlatformSelect(data, true);

                        let existComponent = this.$refs['step-platform'].$refs['exist-platform']
                        existComponent.setPlatform({ ...this.source.platform, code: this.source.platform.id })

                        this.setReceptionList();

                        this.isLoading = false;
                        this.loadindPlatform = false;
                    }
                });
            }

        },
        setReceptionList() {
            if (this.source.lists && this.source.lists.length) {
                if (!this.dataIsLoading) { this.dataIsLoading = true; }

                helper.delay(1300).then(() => {

                    if (typeof this.$refs['step-integration'].setReceptionList == 'function') {
                        let existComponent = this.$refs['step-integration']

                        if (this.source.lists.length) existComponent.setReceptionList(this.source.lists[0])
                    }

                    this.toggleSpinnerPlatform();

                });
            }
            this.toggleSpinnerPlatform();
        },
        handleDone() {
            this.editPlatform = false;
        },
        toggleSpinnerPlatform(value = true) {
            helper.delay(2000).then(() => {
                this.dataIsLoading = !value;
            });
        },
        cleanPlatform() {
            if (typeof this.$refs['step-platform'].$refs['exist-platform'] !== 'undefined' && typeof this.$refs['step-platform'].$refs['exist-platform'].setPlatform == 'function') {
                const CURR_STEP_COMP = _.find(this.steps, function (o) { return o.name == 'platform-tab'; });
                if (!CURR_STEP_COMP.fields.includes('platform_code')) CURR_STEP_COMP.fields.push('platform_code')

                let existComponent = this.$refs['step-platform'].$refs['exist-platform']
                existComponent.setPlatform(null)
                this.isLoading = false;
            }
        },
        cleanReceptionList() {
            this.form.list = null
            this.form.lists = []
            if (typeof this.$refs['step-integration'].setReceptionList == 'function') {
                let existComponent = this.$refs['step-integration']
                existComponent.setReceptionList()
            }
        }
    }
}
</script>

<style>
select.form-control[size],
select.form-control[multiple] {
    height: 45px !important;
}

.pt-0,
.py-0 {
    padding-top: 6px !important;
}

.select2-container {
    width: 100% !important;
}

.select2-search--dropdown .select2-search__field {
    width: 98%;
}

h3 {
    font-size: 1.5em;
}

#top-tab-list {
    margin: 0 -10px 45px;
    overflow: hidden;
    color: #777D74;
}

.spinnerAddTag {
    position: relative;
    top: -3.5rem;
    left: 95.9%;
}

.fit-image {
    width: 50% !important;
}

.iq-card {
    background: #ffffff;
    border-radius: 5px;
    margin-bottom: 30px;
    border: none;
    box-shadow: none !important;
}

.iq-card .iq-card-header {
    padding: 0 20px;
    min-height: 60px;
    border-bottom: 0px solid #f2edff;
    align-items: center !important;
}

.modal-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: .8rem 1rem;
    border-bottom: 0px solid #dee2e6;
    border-top-left-radius: calc(.3rem - 1px);
    border-top-right-radius: calc(.3rem - 1px);
}

div.swal2-html-container,
div#swal2-html-container {
    overflow-y: hidden;
}

tbody td[data-label="Reception List"] {
    max-width: 180px;
}

@media only screen and (max-width: 768px) {
    #top-tab-list li a {
        background: #e5f2ff;
        color: #0084ff;
        display: block;
        padding: 15px;
        margin: 0 1px;
        border-radius: 5px;
        -webkit-border-radius: 5px;
        text-decoration: none;
        font-size: 12px;
    }

    .justify-content-center .col-7 {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .spinnerAddTag {
        position: relative;
        top: -3rem;
        left: 85.9%;
    }
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
