import { Add } from '@/actions/modules/Integrations/AddIntegrations';
import api from '@/api/RestClient';
import { PLATFORMS_ITEMS, PLATFORMS_CODE, COLUMNS_GRID, OAUTH_ESP } from "@/constantes";
import { services, helper } from '@/helpers';
import { ifElse } from 'ramda';

export default function _default(axios) {
    return {
        maps: {
            listsMap: (obj, code, name,type=null) => {
                let item = {}
                item.code = obj[code]
                if (obj['p_list_id']) {
                    item.list_id = obj['p_list_id']

                }
                item.name = obj[name]

                if(type) {
                    item.type = obj[type]
                }
                return item
            },
            tagsMap: (obj, code, name) => {
                let item = {}
                item.code = obj[code]
                item.name = obj[name]
                return item
            }
        },

        hasSetDailyLimit(COMP) {
            return (COMP.form.ableLimitPerDay && COMP.form.limit > 0)
        },
        hasSetValidTimeLimit(COMP) {
            let isValid = false;
            return (COMP.form.ableLimitTime && helper.validateTime(COMP.form.time_limit_from) && helper.validateTime(COMP.form.time_limit_to));
        },
        getFormDataIntegration(comp) {

            let organizationId = comp.organization.accountid;
            let platformName = comp.form.platform.name.toLowerCase();

            let tag = comp.$refs["step-integration"].tagSelected;
            let list = (comp.$refs["step-list"].list);
            let platform = comp.form.p_id;

            var integration = {};
            var listSelected = {};
            var times = {}
            var platformSelected = {};


            // integration data
            integration.name = comp.form.integrationName;

            if (comp.form.platform.code == PLATFORMS_CODE.EXPERTSENDER) {
                integration.tag_id = comp.form.CustomEventId;
                integration.tag_name = comp.form.CustomEventName;

            } else if (comp.form.platform.code == PLATFORMS_CODE.SENDINBLUE) {

                integration.tag_name = comp.form.sendin_blue_event;

            } else if (comp.form.platform.code == PLATFORMS_CODE.INBOX) {

                integration.tag_name = comp.form.customTag;

            } else {
                integration.tag_name = tag.name;
                integration.tag_id = tag.code;
            }

            let recurrence = parseInt(comp.form.recurrence);
            integration.recurrence = (recurrence > 0) ? recurrence : 0;

            integration.limit = comp.form.limit ? comp.form.limit : -1;

            times.time_limit_from = comp.form.time_limit_from;
            times.time_limit_to = comp.form.time_limit_to;

            comp.$data.timeToAlert = false;
            comp.$data.timeFromAlert = false;

            if (comp.form.ableLimitTime) {
                let isTimesValid = false
                let isValidTimeTo = helper.validateTime(comp.form.time_limit_to);
                let isValidTimeFrom = helper.validateTime(comp.form.time_limit_from);

                if (isValidTimeTo && isValidTimeFrom) isTimesValid = true;

                if (!isTimesValid) {
                    if (!isValidTimeTo) comp.$data.timeToAlert = true;
                    if (!isValidTimeFrom) comp.$data.timeFromAlert = true;
                    comp.submitNextBtn = false;
                    comp.$refs["step-integration"].nextSpinner = false;
                    return null;
                }
            } else {
                times.time_limit_from = null;
                times.time_limit_to = null;
            }

            integration.time_limit = times;

            // platform data
            platformSelected.name = platform.name;
            platformSelected.id = platform.code;
            platformSelected.account = comp.form.accountid;
            platformSelected.key = comp.form.apikey;

            // OAUTH_ESP for constantes Oauth
            if (OAUTH_ESP.includes(platform?.code)) {
                let platformID = null;

                platformID = localStorage.getItem(`${platformName}.platform_id`)

                platformSelected.account = comp.form.oAccountID.code;
                platformSelected.oauth = platformID;
            }

            // Blastable additional script
            if ((platform.code == PLATFORMS_CODE.BLASTABLE) && (comp.custom.checkbox && comp.custom.link)) platformSelected.account = comp.custom.link;

            // list data
            listSelected.name = list.name;
            listSelected.id = list.code;

            // resume
            let dataIntegration = {
                organizationId: organizationId,
                integration: integration,
                list: list,
                platform: comp.form.p_id,
                field : comp.form.field ?? null
            }

            var bodyFormData = new FormData();
            bodyFormData.append('data', JSON.stringify(dataIntegration));
            return bodyFormData;
        },

        initializeShowAnotherCols(COMP) {
            if (typeof COMP.showCols.apiKey !== 'undefined') COMP.showCols.apiKey = true;
            if (typeof COMP.showCols.accountId !== 'undefined') COMP.showCols.accountId = true;
            COMP.showCols.secretKey = false;
            COMP.showCols.tags = true;
            COMP.showCols.event = false;
            COMP.showCols.sendin_blue_event = false;
            COMP.showCols.customTag = false;
            COMP.showCols.groupCol = false;
            COMP.showCols.auth2Btn = false;

            COMP.$refs["step-platform"].apiTokenLabel = 'API key/Token: *';
            COMP.$refs["step-platform"].apiTokenPlaceholder = 'API key';

            COMP.$refs["step-platform"].cols.platformCol = COLUMNS_GRID.COL12;
            COMP.$refs["step-platform"].cols.accoundIdCol = COLUMNS_GRID.COL12;
            COMP.$refs["step-platform"].cols.apiKeyCol = COLUMNS_GRID.COL12;

            if (typeof COMP.$refs["step-platform"].$refs.nextStep1 !== 'undefined') {
                COMP.$refs["step-platform"].$refs.nextStep1?.classList.remove('d-none')
            }

            if (typeof COMP.$refs["step-platform"].$refs?.auth2AccountsGroup !== 'undefined') {
                COMP.$refs["step-platform"].$refs.auth2AccountsGroup?.classList.add('d-none')
            }
            COMP.$refs["step-platform"].accountIsVisible = true;

            COMP.$refs["step-integration"].tagLabel = 'Tag: *'

            COMP.$refs["step-platform"].auth2.addNew = true
            COMP.$refs["step-platform"].auth2.platform = null;
            COMP.$refs["step-platform"].auth2.platforms = [];
            COMP.$refs["step-platform"].auth2.listAccounts = [];
            COMP.$refs["step-platform"].custom.isVisible = false;
        },

        initializeAccountRequire(COMP, platformId) {
            COMP.$refs["step-platform"].accountLabel = 'Account ID: *';
            COMP.$refs["step-platform"].accountPlaceholder = 'Account ID';


            COMP.$refs["step-platform"].apiTokenLabel = 'API key/Token: *';
            COMP.$refs["step-platform"].apiTokenPlaceholder = 'API key';

            COMP.$refs["step-platform"].cols.platformCol = COLUMNS_GRID.COL12;
            COMP.$refs["step-platform"].cols.accoundIdCol = COLUMNS_GRID.COL12;
            COMP.$refs["step-platform"].cols.apiKeyCol = COLUMNS_GRID.COL12;

            COMP.$refs["step-platform"].showCols.accountId = true;
            COMP.$refs["step-platform"].accountIsVisible = true;
            COMP.$refs["step-platform"].showCols.apiKey = true;
        },

        initializeListAndTagLabels(that, code) {
            this.initializeListLabels(that, code)
            this.initializeTagLabels(that, code)
        },
        initializeListLabels(that, code) {
            let stepListComp = (typeof that.$refs["step-list"] !== "undefined") ? that.$refs["step-list"] : that;

            // Conditionals labels list
            stepListComp.listLabel = 'List: *';
            stepListComp.listPlaceholder = 'Select Lists';


            if ([PLATFORMS_CODE.CAMPAIGNER, PLATFORMS_CODE.BLASTABLE].includes(code)) {
                stepListComp.listLabel = 'Contact list *'
            }

            if ([PLATFORMS_CODE.BEEHIIV].includes(code)) {
                stepListComp.tagLabel = 'Contact list *'
            }

            if (code == PLATFORMS_CODE.ONTRAPORT) {
                stepListComp.listLabel = 'Group *'
            }

            if ([PLATFORMS_CODE.CONVERKIT, PLATFORMS_CODE.KEAP].includes(code)) {
                stepListComp.listLabel = 'Tag *';
                stepListComp.listPlaceholder = 'Select Tags';

                if (code == PLATFORMS_CODE.CONVERKIT) {
                    stepListComp.listLabel = 'Contact tag *'
                }
            }

            if (code == PLATFORMS_CODE.GOHIGHLEVEL) {
                stepListComp.listLabel = 'Tag: *'
            }
        },
        initializeTagLabels(that, code) {
            // Conditionals labels tag
            that.$refs["step-integration"].tagLabel = 'Tag *'
            that.$refs["step-integration"].tagPlaceholder = 'Create tag'
            that.$refs["step-integration"].selectTagPlaceholder = 'Select tag'

            console.log('intialize tags',code,PLATFORMS_CODE.BEEHIIV);

            if ([PLATFORMS_CODE.BEEHIIV].includes(code)) {
                that.$refs["step-integration"].tagLabel = 'Automations *';
                that.$refs["step-integration"].tagPlaceholder = 'Create automations';
                that.$refs["step-integration"].selectTagPlaceholder = 'Select automation'
            }

            if ([PLATFORMS_CODE.CAMPAIGNER, PLATFORMS_CODE.BLASTABLE, PLATFORMS_CODE.HUBSPOT, PLATFORMS_CODE.SENDGRID].includes(code)) {
                that.$refs["step-integration"].tagLabel = 'List *';
                that.$refs["step-integration"].tagPlaceholder = 'Create list';
                that.$refs["step-integration"].selectTagPlaceholder = 'Select list'
            }

            if (code == PLATFORMS_CODE.KLAVIYO || code == PLATFORMS_CODE.LISTRAK) {
                that.$refs["step-integration"].tagLabel = 'Event *'
                that.$refs["step-integration"].tagPlaceholder = 'Create Event';
                that.$refs["step-integration"].selectTagPlaceholder = 'Select event'
            }

            if (code == PLATFORMS_CODE.ONGAGE) {
                that.$refs["step-integration"].tagLabel = 'Custom field *'
                that.$refs["step-integration"].tagPlaceholder = 'Custom field';
                that.$refs["step-integration"].selectTagPlaceholder = 'Custom field'
            }

        },
        handleAddIntegration(that, btn, currentFs, nextFs, current) {
            let bodyFormData = this.getFormDataIntegration(that)

            btn.disabled = true
            that.submitNextBtn = true;
            var title = 'Ooops ..';
            var alert = 'error';
            var message = '';

            if (!bodyFormData) {
                btn.disabled = false
                that.submitNextBtn = false;
                that.nextSpinner = false;
            }
            else {
                api.integrations.post(bodyFormData)
                    .then((resp) => {
                        var responseData = resp.data;
                        if (responseData.id) {
                            title = 'Success!';
                            alert = 'success';
                            message = 'Your integration is turned on and will begin sending events once contacts have synced. If your email platform automation is not ready, turn off this integration now. To learn more, click <a target="_blank" href="https://help.inboxgeek.com/your-inboxgeek-account/#1679424669960-cc4ec555-bc63">here</a>'
                        }

                        that.submitNextBtn = false;
                        that.integrationId = resp.data.integration_id

                        // if success
                        if (responseData.id) {
                            // CLEAN FORM
                            that.$refs["step-platform"].cleanForm()
                            that.$refs["step-list"].cleanForm()
                            that.$refs["step-integration"].cleanForm()

                            that.$refs["step-finish"].setIntegrationID(responseData.id)
                            that.nextStep(nextFs, currentFs, current)
                        }
                        else { that.currentStep = 2; return false }
                    })
                    .catch((error) => {
                        if (error.response.data.message) message = error.response.data.message
                    })
                    .finally(() => {
                        btn.disabled = false
                        that.submitNextBtn = false;
                        that.nextSpinner = false;
                        that.$root.$emit('reloadIntegrationsAndList');
                        that.$swal(title, message, alert);
                    })
            }

        },

        handleAlertAddIntegration(that, compStep, btn, currentFs, nextFs, current) {
            let link = "https://help.inboxgeek.com/your-inboxgeek-account/#1679424669960-cc4ec555-bc63"
            that.$swal({
                title: 'Are you sure?',
                html:
                    `<span>
                        You have not set a daily limit for your integration. Are you sure you want to continue? <br>
                        <small>**To learn more about setting your daily limit, click <a target="_blank" href="${link}">HERE</a></small>
                    <span>`,
                type: 'info',
                showCancelButton: true,
                confirmButtonText: 'Continue',
                cancelButtonText: 'Go back',
                showCloseButton: true,
                showLoaderOnConfirm: true
            }).then((result) => {
                if (result.value) {
                    this.handleAddIntegration(that, btn, currentFs, nextFs, current)
                }
                compStep.nextSpinner = false
                btn.disabled = false
            })
        },
        onNext(that, btn, step, compStep) {
            var currentFs, nextFs, previousFs // fieldsets
            const STEP_PLATFORM = 0
            const STEP_LIST = 1
            const STEP_INTEGRATION = 2
            const STEP_FINISH = 3
            var current = step
            // var _current = 0
            that.currentStep = step
            var steps = $('fieldset').length
            // const MAX_STEP_LENGTH = 5;
            // if(steps > MAX_STEP_LENGTH) steps = MAX_STEP_LENGTH;
            that.setProgressBar(current, steps)

            // fields to validate
            var fields = [
                '#alertPlatform',
                '#alertKey',
                '#alertAccountId',
                '#alertLists',
                '#alertName',
                '#alertTag',
                '#alertCustomApiLink',
                '#alertListName',
            ];

            // Next btn action
            if (btn) btn.disabled = true
            compStep.nextSpinner = true

            // Clean old validation message
            that.errors = [];
            fields.forEach(id => {
                $(id).addClass('d-none');
            });


            let platformName = (typeof that.form.platform?.name !== "undefined") ? that.form.platform?.name : null;
            let platformCode = (typeof that.form.platform?.code !== "undefined") ? that.form.platform?.code : null;

            // Validation Platform
            if (typeof that.form.platform === "undefined" || !that.form.platform) {
                that.errors.push('#alertPlatform')
            }

            if (that.form?.platform?.code == PLATFORMS_CODE.WEBHOOK) that.form.apikey = "";

            // Validation Api key
            if (that.form.platform && (!OAUTH_ESP.includes(platformCode)) && (that.form.apikey == null || that.form.apikey == "")) {
                if (![PLATFORMS_CODE.WEBHOOK, PLATFORMS_CODE.IBG_ESP].includes(platformCode)) that.errors.push('#alertKey')
            }

            // Validation Account ID
            if (that.form.platform && ([PLATFORMS_CODE.MAROPOST, PLATFORMS_CODE.ONTRAPORT, PLATFORMS_CODE.ACTIVE_CAMPAIGN, PLATFORMS_CODE.CONVERKIT].includes(platformCode))) if (that.form.accountid == null) that.errors.push('#alertAccountId')

            // Validation Account ID [Oauth]
            if (that.form.platform && (OAUTH_ESP.includes(platformCode))) if (that.form.oAccountID == null) that.errors.push('#alertAccountId')

            // Validation Custom API link [BLASTABLE]
            if (that.form.platform && (platformCode == PLATFORMS_CODE.BLASTABLE) && (that.custom.checkbox && !that.custom.link)) that.errors.push('#alertCustomApiLink')

            this.initializeListAndTagLabels(that, platformCode)

            // StepList
            if (that.currentStep == STEP_LIST) {
                this.handleStepList(that, compStep, platformCode)
            }
            // STEP_INTEGRATION
            if (that.currentStep == STEP_INTEGRATION) {
                this.handleStepIntegration(that, compStep, platformCode);
            }

            // Checking errors array
            if (that.errors.length > 0) {
                that.errors.forEach(id => {
                    let message = 'This field must not be empty';

                    if([PLATFORMS_CODE.WEBHOOK, PLATFORMS_CODE.INBOXSUITE].includes(platformCode) && id == '#alertTag') {
                        message = 'Enter a valid URL';
                    }
                    $(id).text(message).removeClass('d-none');
                });
                btn.disabled = false
                compStep.nextSpinner = false
                return
            }

            currentFs = $(btn).parent()
            nextFs = $(btn).parent().next()

            if (that.currentStep == STEP_PLATFORM) {
                this.handleStepPlatform(that, compStep, btn, currentFs, nextFs)
            } else if (that.currentStep == STEP_INTEGRATION) {
                // Not daily limit set checking
                let isValidDelayTime = this.hasSetDailyLimit(compStep)

                if (!isValidDelayTime) {
                    this.handleAlertAddIntegration(that, compStep, btn, currentFs, nextFs, current)
                } else this.handleAddIntegration(that, btn, currentFs, nextFs, current)
            } else if (that.currentStep != STEP_FINISH) {
                this.handleStepFinish(that, compStep, btn, currentFs, nextFs, current)
            }


        },

        handleStepPlatform(that, compStep, btn, currentFs, nextFs) {
            let service = null
            let platformName = that.form.platform.name.toLowerCase();
            if ((typeof services[platformName] !== "undefined")) service = services[platformName]

            if (that.form.platform.code == PLATFORMS_CODE.INBOXSUITE) {

                axios.post('platforms/inboxsuite/validate/api-keys', { "api_key": that.form.apikey, "platform_id": that.form.p_id }).then((response) => {

                    compStep.nextSpinner = false;
                    btn.disabled = false;

                    that.nextStep(nextFs, currentFs, 0);

                }).catch((error) => {

                    that.$swal('Checking API Failed', 'This Api Token is not valid', 'error');

                    that.currentStep = 0;
                    compStep.nextSpinner = false;
                    btn.disabled = false;

                });

            } else if (![PLATFORMS_CODE.WEBHOOK, PLATFORMS_CODE.IBG_ESP].includes(that.form.platform.code)) {
                that.fetchLists(that, compStep, btn, currentFs, nextFs);
            } else {
                compStep.nextSpinner = false
                btn.disabled = false
                that.nextStep(nextFs, currentFs, 0)
            }

            if (that.form) {
                return that.form
            }
        },
        handleStepList(that, compStep, platformCode) {
            that.errors = [];

            if (compStep.list.length == 0) that.errors.push('#alertLists')

            compStep.tagLabel = 'Tag *'
            compStep.tagPlaceholder = 'Create tag'
            compStep.selectTagPlaceholder = 'Select tag'

            if ([PLATFORMS_CODE.CAMPAIGNER, PLATFORMS_CODE.BLASTABLE, PLATFORMS_CODE.HUBSPOT].includes(platformCode)) { compStep.tagPlaceholder = 'Create list'; compStep.tagLabel = 'List *'; compStep.selectTagPlaceholder = 'Select list' }
            if (platformCode == PLATFORMS_CODE.ONTRAPORT) {
                compStep.listLabel = 'Group *'
            }

            if (platformCode == PLATFORMS_CODE.KLAVIYO) {

                that.tagLabel = 'Event *'
                that.selectTagPlaceholder = 'Select an event'
                that.tagPlaceholder = 'Create Event'
            }

            if (platformCode == PLATFORMS_CODE.AWEBER) {
                that.tagLabel = 'List *'
                that.tagPlaceholder = 'Create List'
                that.selectTagPlaceholder = 'Select List'
            }

            if (platformCode == PLATFORMS_CODE.WEBHOOK) {
                if (compStep.list.length > 0)
                    if (!compStep.list[0].name) that.errors.push('#alertListName');

                that.tagLabel = 'Trigger link : *';
                that.tagPlaceholder = 'Trigger link';
                that.selectTagPlaceholder = 'Trigger link';
            }



            if (platformCode == PLATFORMS_CODE.INBOXSUITE) {

                if (compStep.list.length > 0)
                    if (!compStep.list[0].name || compStep.list[0].name == "") that.errors.push('#alertListName');



                compStep.tagLabel = 'Campaign ID *';
                compStep.tagPlaceholder = 'Campaign id';
                compStep.selectTagPlaceholder = 'Campaign id';

            }
            if (platformCode == PLATFORMS_CODE.ONGAGE) {

                that.tagLabel = 'Custom field : *';
                that.tagPlaceholder = 'Custom field';
                that.selectTagPlaceholder = 'Custom field';
            }


            if (that.form) {
                return that.form
            }
        },
        handleStepIntegration(that, compStep, platformCode) {

            compStep.timeFromAlert = false;
            compStep.timeToAlert = false;

            that.errors = [];

            // Validation Integration name
            if (["", null].includes(compStep.form.integrationName)) that.errors.push('#alertName')

            if (compStep.form.ableLimitTime && !services.default.hasSetValidTimeLimit(compStep)) {
                if (!helper.validateTime(compStep.form.time_limit_from)) compStep.timeFromAlert = true
                if (!helper.validateTime(compStep.form.time_limit_to)) compStep.timeToAlert = true
            }


            if (
                ![PLATFORMS_CODE.EXPERTSENDER, PLATFORMS_CODE.WEBHOOK, PLATFORMS_CODE.INBOXSUITE, PLATFORMS_CODE.SENDINBLUE, PLATFORMS_CODE.INBOX, PLATFORMS_CODE.IBG_ESP].includes(platformCode)
                &&
                (!compStep?.tagSelected || compStep?.tagSelected.length == 0)
            ) {
                that.errors.push('#alertTag')
            }

            if (platformCode == PLATFORMS_CODE.WEBHOOK) { // webhook validations
                let triggerLink = compStep.tagSelected.name;

                if (triggerLink && triggerLink.trim() !== '') {
                    try {
                        let url = new URL(triggerLink);
                    } catch (_) {
                        that.errors.push('#alertTag');
                    }
                } else{
                    that.errors.push('#alertTag');
                }
            }
            if (platformCode == PLATFORMS_CODE.INBOXSUITE) { // inboxsuite validations

                if (compStep?.tagSelected?.name == '' || typeof compStep?.tagSelected?.name === "undefined") {

                    that.errors.push('#alertTag');

                }
            }

            if (that.form) {
                return that.form
            }
        },
        handleStepFinish(that, compStep, btn, currentFs, nextFs, current) {
            btn.disabled = false
            compStep.nextSpinner = false
            that.nextStep(nextFs, currentFs, current)
        },
        capitalize(s) {
            return s[0].toUpperCase() + s.slice(1);
        },
        mainOAuth(that, click, code = null) {
            that.auth2.btnAuthorize = true;
            that.auth2.inProgress = true;
            let authUrl = null;
            let params = {};
            let auth2ServiceApi = null;
            let servicePlatform = null;
            let platformName = that.form.platform.name.toLowerCase();
            var swalObj = {
                text: 'You will be redirected to the authentication page. Click "Ok" to continue',
                type: 'info',
                showCancelButton: true,
                confirmButtonText: 'Ok',
                cancelButtonText: 'Cancel!',
                showCloseButton: true,
                showLoaderOnConfirm: true
            };

            that.auth2.state = '';
            // localStorage.removeItem('oauth.install');
            localStorage.removeItem('oauth.refresh');
            localStorage.removeItem('oauth.params');


            // New Oauth version
            if (OAUTH_ESP.includes(code)) {
                swalObj.title = `InboxGeek ${this.capitalize(platformName)} Integration`

                if (typeof services[platformName] !== "undefined")
                    servicePlatform = services[platformName];

                if (servicePlatform) {
                    if (typeof servicePlatform.oauthGetAuthURL === "function")
                        auth2ServiceApi = servicePlatform.oauthGetAuthURL();
                }

            }

            if (auth2ServiceApi) {
                const defaultObj = this;
                auth2ServiceApi.then(function (result) {
                    defaultObj.oauthAuthorizeData(that, result, code)
                })
                    .catch(function (error) {
                        that.$swal({
                            title: swalObj.title,
                            text: 'An error has occurred. Please try again',
                            type: 'warning',
                            confirmButtonText: 'Ok'
                        })
                    })
                    .finally(function () {
                        that.auth2.btnAuthorize = true;
                        that.auth2.inProgress = true;

                        click.disabled = true

                        if (OAUTH_ESP.includes(code) && servicePlatform) {
                            if (that.$store.getters.auth2Service.authUrl) {

                                that.$swal(swalObj).then((result) => {
                                    if (result.value) defaultObj.showWindowOauth(that, servicePlatform, result, click)
                                    else {
                                        that.auth2.btnAuthorize = false;
                                        that.auth2.inProgress = false;
                                        click.disabled = false
                                    }
                                });
                            }
                        }
                    })
            }
        },

        oauthAuthorizeData(that, result, code) {

            if (result.success && (typeof result?.data?.url !== "undefined")) {
                that.$store.dispatch('updateOAuth2Organization', that.organizationId);
                if (result?.data?.url)
                    that.$store.dispatch('updateOAuth2AuthUrl', result.data.url);
                if (result?.data?.params)
                    that.$store.dispatch('updateOAuth2AuthParams', result.data.params);

                localStorage.setItem('oauth.install', params);
            }

            if (result.success && (typeof result?.data?.authorizationUrl !== "undefined")) {

                var authUrl = null;
                var params = {};

                if (result?.data?.authorizationUrl) if (typeof result?.data?.authorizationParams !== "undefined") authUrl = result?.data?.authorizationUrl;
                if (result?.data?.authorizationParams) if (typeof result?.data?.authorizationParams !== "undefined") params = JSON.parse(result.data.authorizationParams);

                if (result?.data?.params) if (typeof result?.data?.authorizationParams !== "undefined") params = JSON.parse(result.data.params);

                that.$store.dispatch('updateOAuth2AuthUrl', authUrl);
                that.$store.dispatch('updateOAuth2AuthParams', params);
                that.$store.dispatch('updateOAuth2Organization', that.organizationId);
            }
        },
        showWindowOauth(that, servicePlatform, result, click) {
            if (!result.value) {
                that.auth2.btnAuthorize = false;
                that.auth2.inProgress = false;
                click.disabled = false

                that.$bvToast.toast('Your Integration process has been cancel', {
                    title: 'Warning',
                    variant: 'warning'
                });
            }

            var _action = that.auth2.state
            var oauthRefresh, _params = null;
            let platformName = that.form.platform.name.toLowerCase();

            var w = 800,
                h = 600;

            // Fixes dual-screen position                             Most browsers      Firefox
            const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
            const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

            const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
            const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

            const systemZoom = width / window.screen.availWidth;
            const left = (width - w) / 2 / systemZoom + dualScreenLeft
            const top = (height - h) / 2 / systemZoom + dualScreenTop


            let params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=${w / systemZoom},height=${h / systemZoom},left=${left},top=${top}`;
            var _oauthWindow = window.open(that.$store.getters.auth2Service.authUrl, 'oauth', params);

            var intervalID = setInterval(function () {

                oauthRefresh = localStorage.getItem('oauth.refresh');
                _params = localStorage.getItem('oauth.params') ? JSON.parse(localStorage.getItem('oauth.params')) : {};
                _params.account = that.organization.accountid

                if (oauthRefresh) {
                    let auth2ServiceApi = servicePlatform.oauthGetAccessToken(_params)
                    auth2ServiceApi.then(function (result) {

                        if (typeof _default(axios).oauthLoadData === "function") _default(axios).oauthLoadData(that, result, platformName)
                    })
                        .catch(function (error) {
                            that.$swal({
                                title: `InboxGeek ${platformName} Integration`,
                                text: 'An error has occurred. Please try again',
                                type: 'warning',
                                confirmButtonText: 'Ok'
                            })
                        })
                        .finally(function () {
                            setTimeout(() => {
                                if (typeof _default(axios).oauthDone === "function") _default(axios).oauthDone(that, click)
                            }, 1500);
                        })
                    clearInterval(intervalID)
                }

                else if (_oauthWindow.closed && !oauthRefresh) {
                    that.$bvToast.toast('Please authenticate to complete your integration', {
                        title: 'Warning', variant: 'warning'
                    });
                    clearInterval(intervalID)
                }

                if (_oauthWindow.closed || !_oauthWindow) {
                    that.auth2.btnAuthorize = false
                }
            }, 2000);
        },
        getOAuthPlatform(COMP, platform_id) {

            COMP.auth2.btnAuthorize = true;
            COMP.auth2.inProgress = true;

            // To reinstall Oauth
            if (typeof this.reinitializeOauth === "function") this.reinitializeOauth(COMP)


            let platformName = COMP.form.platform.name.toLowerCase();
            let servicePlatform = null;
            if (typeof services[platformName] !== "undefined") servicePlatform = services[platformName]

            let auth2Service = null;
            if ((servicePlatform) && (typeof servicePlatform.oauthGetAccountInfo === "function"))
                auth2Service = servicePlatform.oauthGetAccountInfo({ platform_id: platform_id })

            const that = this
            auth2Service.then(function (result) {
                if (
                    typeof that.oauthLoadData === "function"
                    && (typeof servicePlatform.oauthGetAccountInfo === "function")
                ) that.oauthLoadData(COMP, result, platformName)

            })
                .catch(function (error) {
                    let account = null
                    if (typeof servicePlatform?.oauthDataLabel === "function") account = servicePlatform.oauthDataLabel()

                    COMP.auth2.listAccounts = [];
                    COMP.auth2.addNew = true
                    if (COMP.$refs["step-platform"]) {
                        COMP.$refs["step-platform"].accountLabel = account?.label;
                        COMP.$refs["step-platform"].accountPlaceholder = account?.placeholder;
                    }

                })
                .finally(function () {
                    COMP.auth2.btnAuthorize = false;
                    COMP.auth2.inProgress = false;
                })
        },
        oauthLoadData(COMP, result, _name = null) {
            if (result.success && (typeof result?.data?.platform_id !== "undefined" && result?.data?.platform_id)) {
                // Test and use store state
                localStorage.setItem(`${_name}.platform_id`, result.data.platform_id);

                var mapFunc = () => { };
                var account = {};
                var dataArray = [];

                let platform = COMP.form.platform
                let platformName = platform.name.toLowerCase();

                if ((typeof services[platformName] !== "undefined")) {

                    if (typeof services[platformName].oauthGetUsers === "function")
                        dataArray = services[platformName].oauthGetUsers(result?.data)

                    if (typeof services[platformName].oauthDataLabel === "function")
                        account = services[platformName].oauthDataLabel()

                    if (typeof services[platformName].oauthDataMap === "function")
                        mapFunc = (obj) => services[platformName].oauthDataMap(obj)

                }


                if (dataArray.length) {
                    const _data = _.map(dataArray, (item) => mapFunc(item))

                    COMP.auth2.listAccounts = _data;
                    COMP.auth2.addNew = true // New line
                    COMP.$refs["step-platform"].accountLabel = account.label;
                    COMP.$refs["step-platform"].accountPlaceholder = account.placeholder;
                }
                else if (typeof this.reinitializeOauth === "function") this.reinitializeOauth(COMP)

                COMP.nextStep1 = false;
                $('.next').attr('disabled', false);

                COMP.showCols.auth2Btn = false;
                COMP.$refs["step-platform"].$refs.nextStep1.classList.remove('d-none')
            }
        },
        reinitializeOauth(COMP) {
            COMP.form.oAccountID = null;
            COMP.auth2.platform = null;
            COMP.auth2.platforms = [];
            COMP.auth2.listAccounts = [];
        },
        oauthDone(COMP, btn) {
            btn.disabled = false
            COMP.form.oAccountID = null;
            COMP.auth2.btnAuthorize = false;
            COMP.auth2.inProgress = false;
        },
        showAnotherCols(COMP) {

            let platform = COMP.form.platform
            let platformId = platform.code;
            let platformName = platform.name.toLowerCase();
            let service = null
            if ((typeof services[platformName] !== "undefined")) service = services[platformName]

            // console.log(platformName); return;
            this.initializeShowAnotherCols(COMP)

            if (platform && ([PLATFORMS_CODE.MAROPOST, PLATFORMS_CODE.ACTIVE_CAMPAIGN, PLATFORMS_CODE.CONVERKIT].includes(platformId))) {
                this.initializeAccountRequire(COMP, platformId)
            }

            if (typeof service?.customShowAnotherCols === 'function') service.customShowAnotherCols(COMP)

            if (OAUTH_ESP.includes(platformId)) {
                if (typeof COMP.showCols.apiKey !== 'undefined') COMP.showCols.apiKey = true;
                if (typeof COMP.showCols.accountId !== 'undefined') COMP.showCols.accountId = false;
                if (typeof COMP.cols.apiKeyCol !== 'undefined') COMP.cols.apiKeyCol = COLUMNS_GRID.COL12;
                COMP.cols.platformCol = COLUMNS_GRID.COL6;

                let action = COMP.$route.query.action;

                if (typeof this.initializeOauth === 'function' && !COMP.form?.p_id) this.initializeOauth(COMP)

                if (typeof service?.updateLabels === 'function') service.updateLabels(COMP)
                if (typeof service?.oauthLoadPlatforms === 'function') service.oauthLoadPlatforms(COMP)


                if (COMP.auth2.listAccounts.length) {
                    COMP.$refs["step-platform"].$refs.nextStep1.classList.remove('d-none')
                }
            }
        },

        initializeOauth(COMP) {
            if (typeof COMP.showCols.apiKey !== 'undefined') COMP.showCols.apiKey = false;
            COMP.showCols.auth2Btn = true;
            if (typeof COMP.showCols.accountId !== 'undefined') COMP.showCols.accountId = true;

            COMP.auth2.addNew = true;
            COMP.auth2.btnAuthorize = true;
            COMP.auth2.inProgress = true;

            if (typeof COMP.$refs["step-platform"] !== 'undefined') COMP.$refs["step-platform"].accountIsVisible = false;
            if (typeof COMP.$refs["step-platform"] !== 'undefined') COMP.$refs["step-platform"].$refs.nextStep1.classList.add('d-none')
            if (typeof COMP.$refs["step-platform"] !== 'undefined' && typeof COMP.$refs["step-platform"].$refs.auth2AccountsGroup !== 'undefined') COMP.$refs["step-platform"].$refs.auth2AccountsGroup.classList.remove('d-none')

        },
        oauthLoadOlds(COMP, result) {
            if (result.success && (typeof result?.data?.platforms !== "undefined")) {
                const _old_platforms = _.map(result.data.platforms, (obj, key) => {
                    let item = {}
                    item.code = obj.id
                    item.name = `${obj.name} [${obj.account}]`
                    return item
                })
                COMP.auth2.platforms = _old_platforms;
                COMP.auth2.addNew = false;
            } else {
                COMP.auth2.addNew = true
            }
        },

        fetchPlatformData(that, service = null, listId = null) {
            let _params = {};
            let platform = that.form.platform.code;
            let platformName = that.form.platform.name.toLowerCase();
            let entity = service ? service : 'lists';

            if(platformName=="ongage" && entity=="tags") {
                entity = 'fields';
            }
            let requestParams = this.getListRequestParams(entity, {
                "code": platform,
                "platform": platformName,
                "comp": that,
                "item": {},
                "listId": listId
            });

            // if(OAUTH_ESP.include(platform)) {
            //     requestParams.platform_id =
            // }

            return api.platforms.fetchServices(requestParams);
        },
        loadLists(that, code, result, nextObj = {}) {

            let data = result.data

            if (result.success) {

                if (code == PLATFORMS_CODE.CONVERKIT) data = result.data.tags;
                if (code == PLATFORMS_CODE.SENDLANE) data = result.data.data;
                if (code == PLATFORMS_CODE.BLASTABLE) data = result.data.data.records;

                let platform = _.find(PLATFORMS_ITEMS, function (o) { return o.code == code; });

                let lists = (typeof that.formatLists == "function") ? that.formatLists(data) : this.formatLists(data, platform)

                // that.lists = lists
                that.listdisabled = false

                if (typeof that.$refs['step-list'] !== "undefined") {
                    that.$refs['step-list'].cleanForm()
                    that.$refs['step-list'].setLists(lists);
                    that.$refs['step-list'].listdisabled = false;
                } else {
                    that.setLists(lists);
                }


                if (typeof that.nextStep == "function" && (typeof nextObj.nextFs !== "undefined")) {
                    that.nextStep(nextObj.nextFs, nextObj.currentFs, nextObj.current)
                }
            } else {

                if (code == PLATFORMS_CODE.BLASTABLE) {
                    const data = result.json();
                    data.then(value => {
                        that.$swal('Checking Api Result', value?.data?.error ?? message, success);
                    })
                } else that.$swal('Checking Api Result', message, success);

                if (typeof that.currentStep !== "undefined") {
                    that.currentStep = 0;
                }
            }
        },
        formatLists(items, platform) {


            var servicePlatform = null
            var platformName = platform.name.toLowerCase();
            const defaultService = this

            if (typeof platform === "undefined" || !platform) return [];

            let strId = 'id';
            let strName = 'name'

            if ((typeof services[platformName] !== "undefined")) servicePlatform = services[platformName]
            if (servicePlatform?.labels?.listID) strId = servicePlatform.labels.listID
            if (servicePlatform?.labels?.listName) strName = servicePlatform.labels.listName

            if (PLATFORMS_CODE.BLASTABLE == platform.code && items.data) {
                items = items.data.records
            }

            return _.map(items, (obj, key) => {
                let item = {}

                if (typeof servicePlatform?.maps?.listsMap === "function") {
                    item = servicePlatform.maps.listsMap(obj)
                } else {
                    item = defaultService.maps.listsMap(obj, strId, strName)
                }
                return item
            })
        },
        getListRequestParams(service, options = { code, platform, comp, item, listId }) {
            let _params = {};

            let comp = (typeof options.comp != "undefined") ? options?.comp : null;

            let platform = (typeof options.code != "undefined")
                ? options?.code
                : ((typeof comp.form.platform.code != "undefined") ? comp.form.platform.code : null);

            let platformName = (typeof options.platform != "undefined")
                ? options?.platform
                : ((typeof comp.form.platform.name != "undefined") ? comp.form.platform.name : null);


            let entity = service ? service : 'lists';
            let customLink = null;

            // Specific platform
            if ((platform == PLATFORMS_CODE.BLASTABLE) && (typeof comp?.custom !== "undefined") && comp?.custom?.link) {
                customLink = comp?.custom?.checkbox ?? null;
            }


            const myAccountId = ((typeof customLink !== "undefined") && customLink)
                ? comp?.custom?.link : (comp?.form?.accountid ?? options?.item.platform_account);


            const myAuthToken = comp?.form?.apikey ?? this.isValidJson(options?.item?.key);
            // End Specific platform


            let account = OAUTH_ESP.includes(platform) ? comp?.form?.oAccountID?.code : myAccountId;


            //_params.token = myAuthToken;
            //_params.account = account;
            _params.platform = platform;
            _params.entities = entity;

            _params.platform_id = comp.form?.p_id;


            let stepListComp = (typeof comp?.$refs["step-list"] !== "undefined") ? comp?.$refs["step-list"] : null;

            if (entity == 'tags') {

                if (PLATFORMS_CODE.MAILCHIMP == platform) {
                    let mailchimpListIDs = [];

                    if (stepListComp) {
                        stepListComp.lists.forEach(list => {
                            mailchimpListIDs.push(list.code);
                        });
                    }

                    _params.list_ids = mailchimpListIDs.join(',')
                }

                if (PLATFORMS_CODE.AWEBER == platform) {
                    if (stepListComp && stepListComp.lists.length) {
                        _params.list_id = stepListComp.lists[0].code;
                    }
                }

                if (PLATFORMS_CODE.EMAIL_OCTOPUS == platform) {
                    let octopusListIDs = [];
                    if (stepListComp) {
                        stepListComp.list.forEach(list => {
                            octopusListIDs.push(list.code);
                        });
                    }

                    _params.list_ids = octopusListIDs.join(',')
                }

                if (PLATFORMS_CODE.LISTRAK == platform) {
                    _params.listId = options.listId
                }
            }


            if (comp && OAUTH_ESP.includes(platform)) {
                let platform_id = null;
                let oauthPrefix = '';

                oauthPrefix = platformName
                platform_id = comp?.form?.p_id ?? localStorage.getItem(`${platformName}.platform_id`);
                if (platform_id) _params.platform_id = platform_id
            }

            if (platform == PLATFORMS_CODE.INBOX && (typeof comp?.form?.p_id !== "undefined")) {
                _params.platform_id = comp.form.p_id
            }

            if (typeof options.item.platform_id !== "undefined") _params.platform_id = options.item.platform_id

            let platformService = this.getServicePlatform(_params);
            _params.platform_name = platformService.name.toLowerCase();


            return _params
        },
        getListParamsByEspAndPlatform(esp, platform = null, options = { entity: 'lists', token: null, customUrl: null, account: null, platform_id: null, organization: null, isOauth: false }) {

            let _params = {};

            let esp_code = (typeof esp.code !== "undefined") ? esp.code : null;
            let esp_name = (typeof esp.name !== "undefined") ? esp.name : null;


            let customLink = null;


            // Specific platform
            if ((esp_code == PLATFORMS_CODE.BLASTABLE) && options.customUrl) {
                customLink = options.customUrl;
            }

            const myAccountId = customLink
                ? customLink : (options.organization ?? null);


            // const myAuthToken = options.token;

            // _params.token = myAuthToken;
            _params.account = options.account ?? null;
            _params.platform_id = options.platform_id ?? null;
            _params.platform = esp_code;
            _params.entities = options.entity;


            let platform_id = platform?.id ?? (platform?.code ?? null);


            if (esp_code == PLATFORMS_CODE.INBOX && (typeof options.organization !== "undefined")) {
                _params.organization = options.organization
            }

            if (platform_id) {
                _params.platform_id = platform_id
            }

            _params.platform_name = esp_name.toLowerCase();


            return _params
        },
        isValidJson(value) {
            if (typeof value == "undefined") {
                return null
            }

            if (/^[\],:{}\s]*$/.test(value.replace(/\\["\\\/bfnrtu]/g, '@').
                replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']').
                replace(/(?:^|:|,)(?:\s*\[)+/g, ''))) {

                return value ? (JSON.parse(value))?.accessToken : null
            }

            return value
        },
        getPlatformService(comp, entity) {
            this.fetchPlatformData(comp, entity)
                .then(function (results) {

                    var responses = 'data' in results.data ? results.data : results;
                    results = responses.data;
                    if (comp.$data.form.platform.code == PLATFORMS_CODE.CAMPAIGNER) results = results.Lists;
                    if (comp.$data.form.platform.code == PLATFORMS_CODE.BLASTABLE) results = results.records;
                    if (comp.$data.form.platform.code == PLATFORMS_CODE.CONVERKIT) results = results.tags;
                    let resps = results.map(function (result) {

                        var item = {}
                        var originResult = result
                        result = helper.json2array(result)
                        item.name = [PLATFORMS_CODE.MAROPOST, PLATFORMS_CODE.ACTIVE_CAMPAIGN, PLATFORMS_CODE.AWEBER, PLATFORMS_CODE.GET_RESPONSE, PLATFORMS_CODE.MAILCHIMP].includes(comp.$data.form.platform.code) && entity == 'lists' ? result[2] : result[1]
                        item.code = result[0]
                        if (comp.$data.form.platform.code == PLATFORMS_CODE.ACTIVE_CAMPAIGN && entity == 'lists') item.code = parseInt(result[46]);

                        if (comp.$data.form.platform.code == PLATFORMS_CODE.ACTIVE_CAMPAIGN && entity == 'tags') {
                            var _name = result[1];
                            item.code = parseInt(result[10]);
                        }

                        if (comp.$data.form.platform.code == PLATFORMS_CODE.GET_RESPONSE) {
                            var _name = result[0]?.name;
                            if (entity == 'tags') item.code = result[0]?.tagId;
                        }

                        if (comp.$data.form.platform.code == PLATFORMS_CODE.HUBSPOT) {
                            item.code = originResult.listId;
                            item.name = originResult.name + " [" + originResult.listType + "]";
                        }

                        if (comp.$data.form.platform.code == PLATFORMS_CODE.MAILCHIMP && entity == 'tags') {
                            var _name = result[1];
                            item.code = result[0];
                        }

                        if (typeof _name !== "undefined") item.name = _name;
                        return item;
                    })

                    // To clean dynamic list in step 3 - for Hubspot
                    resps.forEach((item, i) => {
                        if (!item) delete resps[i];
                    });

                    if (entity == "tags") {
                        comp.$data.tags = resps;

                        if ([PLATFORMS_CODE.CONVERKIT].includes(comp.$data.form.platform.code)) comp.$data.lists = resps;

                        // Set tags [EMAIL_OCTOPUS]
                        if ([PLATFORMS_CODE.EMAIL_OCTOPUS].includes(comp.$data.form.platform.code)) {
                            comp.$refs["step-integration"].setTags(resps)
                        }
                    }
                    else {
                        comp.$data.lists = resps;
                        if (comp.$data.form.platform.code == PLATFORMS_CODE.AWEBER && entity == 'lists') {
                            const tags = JSON.parse(JSON.stringify(resps));
                            comp.$data.tags = tags;
                        }
                        if (comp.$data.form.platform.code == PLATFORMS_CODE.CAMPAIGNER) {
                            const tags = JSON.parse(JSON.stringify(resps));
                            comp.$data.tags = tags;
                        }
                    }
                })
                .catch(function (error) {
                    console.log(error);
                })
                .finally(function () {
                    if ((entity == "lists") && (!comp.$refs["step-list"].lists.length)) {
                        comp.$swal('Data Not loaded', 'An error has occurred. Please try again', 'warning').then((result) => {
                            if (typeof comp.$refs["step-list"].$refs.previous !== "undefined") comp.$refs["step-list"].$refs.previous.click();
                        })
                    }
                })
        },
        getServicePlatform(params) {
            if (!params.platform) return false;
            var platform = _.find(PLATFORMS_ITEMS, function (o) { return o.code == params.platform; });
            return platform;
        },
        addTagPlatform(comp, data, listId = null) {

            let _params = {};
            var platform = comp.form.platform.code;
            var platformName = comp.form.platform.name.toLowerCase();


            if (platform == PLATFORMS_CODE.GET_RESPONSE) {

                if (/[^a-zA-Z0-9 _]/.test(data)) {
                    comp.$swal("Invalid Tag name", "You can use the English alphabet, numbers, and underscores (“_”).", "warning");
                    return null;
                }
            }

            if ((platform == PLATFORMS_CODE.BLASTABLE) && comp.custom.link) var customLink = comp.custom.checkbox ?? null;

            comp.$data.spinnerAddTag = true;

            const myAccountId = ((typeof customLink !== "undefined") && customLink) ? comp.custom.link : comp.form.accountid;
            const myAuthToken = comp.form.apikey;

            var account = ((OAUTH_ESP.includes(platform)) ? comp.form?.oAccountID?.code : myAccountId);
            // _params.account = account;
            // _params.token = myAuthToken;
            _params.platform = platform;
            _params.entities = 'tags';

            if(platform == PLATFORMS_CODE.ONGAGE) {
                _params.entities = 'fields';
            }

            _params.platform_id = comp.form.p_id;
            _params.tag = data;
            _params.listId = listId;


            // Blastable Add list & Mailchimp Add Tag
            if ([PLATFORMS_CODE.BLASTABLE, PLATFORMS_CODE.MAILCHIMP].includes(platform)) {
                if (comp.$refs["step-list"].lists.length) _params.list_uid = comp.$refs["step-list"].lists[0].code;
            }

            // EmailOctopus add Tag
            if ([PLATFORMS_CODE.EMAIL_OCTOPUS].includes(platform)) {
                if (comp.$refs["step-list"].list.length) _params.list_id = comp.$refs["step-list"].list[0].code;
            }

            let service = null;
            if ((typeof services[platformName] !== "undefined")) service = services[platformName]

            _params.platform_name = platformName;

            var tagName = null;
            var tagCode = null;

            var title = 'Ooops ...';
            var message = 'An Something went wrong, please try later';
            var success = 'error';
            var tagLabel = 'Tag';



            api.platforms.addTag(_params)
                .then((response) => {
                    var results = 'data' in response.data ? response.data : response;
                    if (response.success) {

                        if ([PLATFORMS_CODE.KLAVIYO, PLATFORMS_CODE.OMNISEND].includes(comp.$data.form.platform.code)) {
                            tagLabel = 'Event'
                            tagName = data;
                        }

                        var tagData = {}

                        if (service && typeof service?.getTagAdded === "function") tagData = service.getTagAdded(results)
                        else tagData = this.getTagAdded(results, comp.$data.form.platform.code, data)

                        comp.$refs["step-integration"].tags.push(tagData)
                        comp.$refs["step-integration"].tagSelected = tagData

                        title = 'Success!';
                        message = tagLabel + ' ' + data + ' has created successfully';
                        success = 'success';
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        message = error.response.data.errors
                    } else if (error.request) {
                        console.log(error.request);
                    } else {
                        message = error.message;
                    }
                })
                .finally(() => {
                    comp.$refs["step-integration"].isLoading = false;
                    comp.$refs["step-integration"].spinnerAddTag = false;
                    comp.$swal(title, message, success)
                })
        },
        getTagAdded(result, code = null, data = '') {
            result = 'data' in result ? result.data : result;
            if (code == PLATFORMS_CODE.KLAVIYO) {
                return { code: null, name: data }
            } else return { code: result.id, name: result.name }
        },
    }
}
