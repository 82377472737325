<template>
    <b-container fluid>
        <b-row>
            <b-col sm="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{ pageTitle }}</h4>
                    </template>
                    <template v-slot:body>
                        <div v-show="loader">
                            <spinner1></spinner1>
                        </div>
                        <div v-show="!loader">
                            <b-table :id="tableName" class="mt-4" striped bordered :fields="columns">

                            </b-table>
                        </div>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
import Spinner1 from '../../components/loaders/spinner1'
import $ from 'jquery'
import store from '../../store/index'
import router from '@/router'
export default {
    name: 'ibgdt',
    components: {
        Spinner1,
    },
    props: {
        fields: {
            type: Array,
            default: null
        },
        drawCallback: {
            type: Function,
            default: null
        },
        columnsTable: {
            type: Array,
            default: null
        },
        title: {
            type: String,
            default: null
        },
        perPage: {
            type: Number,
            default: 25
        },
        url: {
            type: String,
            default: null
        },
        orderable: {
            type: Array,
            default: []
        },
        filter: {
            type: Number,
            default: 1
        },
        tableName: {
            type: String,
            default: 'table'
        },
    },
    mounted() {
        this.pageTitle = this.title;
        this.columns = this.fields
       
    },
    data() {
        return {
            loader: false,
            columns: [],
            pageTitle: '***',
            table: null
        }
    },
    methods: {
        initTable() {
            const token = store.getters['Auth/tokenAs'] ?? store.getters['Auth/token'];

            $('table#' + this.tableName).DataTable().destroy();
            let that = this;
            let table = $('#' + this.tableName).DataTable({
                serverSide: true,
                processing: true,
                autoWidth: false,
                ajax: {
                    url: this.url,
                    type: 'POST',
                    beforeSend: function (xhr) {
                        xhr.setRequestHeader('Authorization', "Bearer " + token);
                    },
                    error: function (jqXHR, exception) {
                        if (jqXHR.status === 401) {
                            store.dispatch('resetUser', false).then(() => router.push({
                                name: 'auth1.sign-in1'
                            }))
                        }
                    },
                },
                dom: "<'row justify-content-between' <'col-md-6 col-sm-12' f><'col-md-6 sm-12' <'user-list-files d-flex float-right' B>>> <t 'row'><'row justify-content-between mt-3'<'col-md-6' i><'col-md-6' p>>",
                buttons: {
                    dom: {
                        button: {
                            tag: 'a',
                            className: ''
                        }
                    }
                },
                order: [[this.filter, 'desc']],
                "pageLength": this.perPage,
                "columnDefs": [
                    { "orderable": false, "targets": this.orderable },
                ],
                drawCallback: function () {
                    $('.table').parent('div').addClass('table-responsive')
                    if ('showChart' in that.$parent) that.$parent.showChart = false;
                    $('.dataTables_paginate > .pagination').addClass('justify-content-end mb-0')
                    $(".dataTables_processing").removeClass('card');
                    $(".dataTables_processing").css({ 'position': 'absolute', 'top': '50%', 'left': '50%' })
                    $('[data-toggle="tooltip"]').tooltip()
                    $('table.dataTable').css({ width: '100%' })
                    if ('submitload' in that.$parent) that.$parent.submitload = false;
                    that.drawCallback();
                },
                createdRow: function (tr, tdsContent) {
                    const name = that.$options.name;
                    if (name == "ibgdt") {
                        if (tdsContent['validation_color']) {
                            const cl = 'badge-' + tdsContent['validation_color'];
                            const title = tdsContent['validation_title']
                            $(tr).attr('data-toggle', "tooltip");
                            $(tr).attr('data-placement', "top");
                            $(tr).attr('title', title);
                            $(tr).addClass(cl);
                        }
                    }
                },
                language: {
                    search: '',
                    searchPlaceholder: 'Search',
                    processing: "<span class='fa-stack fa-lg'>\n\
                            <i class='fa fa-spinner fa-spin fa-stack-2x fa-fw'></i>\n\
                       </span>&emsp;Processing ...",
                },
                columns: this.columnsTable
            });
            this.table = table;
            if ('table' in this.$parent) this.$parent.table = this.table;
            if (this.tableName == 'integrations') this.$parent.$parent.$parent.$parent.integrationdt.table = this.table;
            if (this.tableName == 'lists') this.$parent.$parent.$parent.$parent.list.table = this.table
        },
    },
    watch: {
        'columns': {
            handler(old, value) {
                setTimeout(() => {
                    this.initTable();
                }, 200);
            }
        }
    }
}
</script>
