<template>
    <b-col md="12">
        <div class="text-center">
            <b-spinner type="grow" label="Loading..."></b-spinner>
        </div>
    </b-col>
</template>
<script>
export default {
  name: 'Spinner1',
  props: {

  },
  mounted () {
  },
  computed: {

  }
}
</script>
