import { render, staticRenderFns } from "./ServiceAnnounceLiveOpensSvg.vue?vue&type=template&id=03491940"
var script = {}
import style0 from "./ServiceAnnounceLiveOpensSvg.vue?vue&type=style&index=0&id=03491940&prod&xmlns=http%3A%2F%2Fwww.w3.org%2F1999%2Fxhtml&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports