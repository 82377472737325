<template>
    <b-container fluid>

        <!-- Table title -->
        <b-row class="mb-3">
            <b-col lg="4" class="my-1">
                <h4 class="card-title">Integrations</h4>
            </b-col>
        </b-row>

        <!-- User Interface controls -->
        <div class="row">
            <div class="col-md-3 col-sm-6 my-auto">
                <div id="integrations_filter" class="">
                    <label>
                        <input type="search" id="filter-input" v-model="filter" class="form-control form-control-sm"
                            placeholder="Search" aria-controls="integrations">
                    </label>
                </div>
            </div>

            <Transition>
                <div v-if="selected.length" class="col-md-9 col-sm-12 mb-2 xs-mx-auto">
                    <b-alert :show="true" variant="secondary" class="py-1 my-0 px-2">
                        <div class="iq-alert-icon">
                            <i class="ri-information-line"></i>
                        </div>
                        <div class="iq-alert-text">
                            <span>
                                {{ (selected.length == to && selected.length > 1) ? 'All' : '' }} <b>{{ selected.length
                                }}</b>
                                Integration{{ selected.length > 1 ? 's' : '' }}
                                {{ selected.length < totalRows ? 'on this page' : 'recorded' }} are selected. </span>

                                    <b-button v-if="selected.length < totalRows" variant="light" @click="selectAllItems">
                                        Select all <b>{{ totalRows }}</b> Integrations
                                    </b-button>
                                    <b-button v-else-if="selected.length == totalRows" variant="light"
                                        @click="clearSelection">
                                        Clear Selection
                                    </b-button>


                                    <b-dropdown dropright variant='none' no-caret class="float-right">
                                        <template #button-content>
                                            <!-- Group actions -->

                                            <button v-b-tooltip.hover
                                                :title="`Grouped action on` + (selected.length ? ` ${(selected.length)} Integration${(selected.length > 1 ? 's' : '')} selected` : '')"
                                                aria-haspopup="true" aria-expanded="false" type="button"
                                                class="btn dropdown-toggle float-right btn-none p-0 m-0">
                                                <span><i class="ri-more-2-fill fa-lg"></i></span>
                                            </button>

                                        </template>

                                        <b-dropdown-item :disabled="!selected.length"
                                            @click="handleSelectedAction('turn-on')">
                                            <i v-b-tooltip class="text-success ri-toggle-fill fa-lg m-0"></i>
                                            Turn on
                                        </b-dropdown-item>

                                        <b-dropdown-item :disabled="!selected.length"
                                            @click="handleSelectedAction('turn-off')">
                                            <i class="text-secondary ri-toggle-line fa-lg m-0"></i>
                                            Turn off
                                        </b-dropdown-item>

                                        <b-dropdown-item :disabled="!selected.length"
                                            @click="handleSelectedAction('delete')">
                                            <i class="text-danger ri-delete-bin-line i-delete fa-lg m-0"></i>
                                            Delete
                                        </b-dropdown-item>
                                    </b-dropdown>

                        </div>
                    </b-alert>

                </div>
            </Transition>



            <div class="my-auto col-md-3 sm-12 d-none">

                <div class="user-list-files d-flex float-right">
                    <div class="dt-buttons btn-group flex-wrap">
                        <a tabindex="0" aria-controls="integrations" href="#"
                            class="buttons-copy buttons-html5"><span>Copy</span>
                        </a>
                        <a tabindex="0" aria-controls="integrations" href="#" class="buttons-csv buttons-html5">
                            <span>CSV</span>
                        </a>
                        <a tabindex="0" aria-controls="integrations" href="#" class="buttons-print">
                            <span>Print</span>
                        </a>
                    </div>
                </div>

            </div>
        </div>


        <!-- Main table element -->
        <b-table class="ig-text-wrap" ref="table" :id="id" striped bordered :responsive="$store.getters['Auth/isAdmin']"
            :busy="isBusy" :items="getData" :fields="fields" :current-page="currentPage" :per-page="perPage"
            :filter="filter" :filter-included-fields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection" stacked="md" show-empty small @filtered="onFiltered"
            @sort-changed="sortingChanged" @row-hovered="onRowHovered" @row-unhovered="onRowUnhovered">

            <!-- A custom formatted header cell for field 'selected' -->
            <template #head(selected)="row">
                <b-form-checkbox id="select-all" v-model="selectAll" name="selected" :indeterminate.sync="indeterminate"
                    @input="selectRow">
                </b-form-checkbox>
            </template>

            <template #table-busy>
                <div class="text-center d-inline-block my-2">
                    <span class='fa-stack fa-lg'>
                        <i class='fa fa-spinner fa-spin fa-stack-2x fa-fw'></i>
                    </span>&emsp;Processing ...
                </div>
            </template>

            <template #empty="scope">
                <p class="mb-1">No matching records found</p>
            </template>

            <!-- Cell selected -->
            <template v-slot:cell(selected)="row">
                <div class="text-center">
                    <input type="checkbox" v-model="row.item.selected" :checked="isChecked(row.item)"
                        @input="hasChangeRowSelect($event, row.item)" />
                </div>
            </template>

            <!-- Cell lists -->
            <template #cell(lists)="row">
                <span class="" v-b-tooltip v-b-tooltip.hover :title="row.value">
                    {{ row.value | filterLessThan(20) }}
                </span>
            </template>

              <template #cell(platform)="row">
                <span class="" v-b-tooltip v-b-tooltip.hover :title="row.value">
                    {{ platformName(row.value) }}
                </span>
            </template>
            


            <!-- Cell status -->
            <template #cell(active)="row">
                <SwitchCell :row="row" @onSwitchStatus="onUpdateItem" />
            </template>

            <!-- Cell actions -->
            <template #cell(actions)="row">
                <div class="d-flex justify-content-center">
                    <button type="button" class="btn iq-bg-primary mr-1 btn-sm" v-b-tooltip.hover data-toggle="tooltip"
                        title="Histories" @click="histories(row.item)">
                        <i class="fa fa-history m-0"></i>
                    </button>
                    <button @click="editItem(row.item, row.index, $event.target)" type="button"
                        class="btn iq-bg-success mr-1 btn-sm" data-toggle="tooltip" data-placement="top" title="Edit">
                        <i class="ion-edit m-0" title="Edit integration"></i>
                    </button>
                    <button v-if="row.item.active >= 0" @click="sendFlow(row.item, row.index, $event.target)" type="button"
                        class="btn iq-bg-primary mr-1 btn-sm" data-toggle="tooltip" data-placement="top"
                        title="Send test Flow">
                        <i class="fa fa-paper-plane m-0" title="Test event"></i>
                    </button>
                    <button v-if="row.item.active >= 0" @click="removeItem(row.item, row.index, $event.target)"
                        type="button" class="btn iq-bg-danger btn-sm" data-toggle="tooltip" data-placement="top"
                        title="Delete">
                        <i title="Delete" class="ri-delete-bin-line i-delete m-0 delete_integration_icone"></i>
                        <div class="spinner-grow spinner-grow-sm delete_integration_spiner d-none" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </button>
                </div>
            </template>

        </b-table>

        <!-- Datatble pagination -->
        <b-row class="justify-content-between mt-3">
            <b-col md="9" class="my-1">
                <div class="dataTables_info">
                    <b-row class="dataTables_info justify-content-between" id="lists_info" role="status" aria-live="polite">
                        <b-col md="10" class="">
                            Showing {{ from }}
                            to {{ to }} of {{ totalRows }} entries
                        </b-col>
                        <b-col md="2" class="">
                            <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm">
                            </b-form-select>
                        </b-col>
                    </b-row>
                </div>
            </b-col>
            <b-col md="3" class="my-1">
                <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm"
                    class="my-0"></b-pagination>
            </b-col>
        </b-row>
        <!-- Modals Components -->

        <!-- Info modal -->
        <b-modal v-if="renderModalUpdate" :id="editModal.id" :title="editModal.title" size="lg" centered :ref="editModal.id"
            hide-footer @hide="resetEditModal">
            <ModalUpdateIntegration :item="editModal.item" :form="{}" col=12 @modalHide="hideUpdateModalHide">
            </ModalUpdateIntegration>
        </b-modal>
        <!-- Modals SendFlow -->
        <b-modal :id="sendFlowModal.id" size="md" title="Integration Test" :ref="sendFlowModal.id" hide-footer>
            <testFlow :integrationId="sendFlowModal.item.id" col=12></testFlow>
        </b-modal>

    </b-container>
</template>

<script>
import Spinner1 from '@/components/loaders/spinner1'
import TestFlow from '@/components/inboxgeek/sendflow'
import SwitchCell from '@/components/inboxgeek/tables/SwitchCell.vue'
import ModalUpdateIntegration from '../modals/ModalUpdateIntegration.vue'
import ActiveCell from '@/components/inboxgeek/tables/ActiveCell.vue'
import LoadingCell from '@/components/inboxgeek/tables/LoadingCell.vue'

import store from '@/store/index'
import { helper } from '@/helpers'
import api from '@/api/RestClient'
import {
    INTEGRATION_STATES
} from "../../../constantes";
import Vue from 'vue'
import { mapGetters } from 'vuex'

Vue.filter('filterLessThan', function (value, max = 20) {
    return helper.showMaxCharacters(value, max)
})

const SERVICE_ID = 1;

export default {
    name: 'DtIntegrations',
    props: ['details'],
    components: {
        Spinner1,
        TestFlow,
        ModalUpdateIntegration,
        ActiveCell,
        LoadingCell,
        SwitchCell
    },
    data() {
        return {
            id: 'dt-integrations',
            rowTooltip: null,
            isBusy: false,
            items: [],
            fields: [
                {
                    key: 'selected',
                    label: '',
                    sortable: false,
                    sortDirection: 'desc',
                    class: 'text-center'
                },
                {
                    key: 'name',
                    label: 'Integration',
                    sortable: true,
                    sortDirection: 'desc',
                    class: 'text-center'
                },
                {
                    key: 'tag_name',
                    label: 'Tag',
                    sortable: true,
                    sortDirection: 'desc',
                    class: 'text-left'
                },
                {
                    key: 'lists',
                    label: 'Lists',
                    formatter: (value, key, item) => {
                        let listContent = ''
                        let listsFormated = []
                        item.lists.forEach(list => {
                            listsFormated.push(list.list_name)
                        });

                        // listContent = helper.showMaxCharacters( listsFormated.join(" | "), 20)
                        listContent = listsFormated.join(" | ")

                        return listContent
                    },
                    sortable: false,
                    class: 'text-left'
                },
                {
                    key: 'platform',
                    label: 'Platform',
                    sortable: false,
                    sortDirection: 'desc',
                    class: 'text-center'
                },
                {
                    key: 'account',
                    label: 'Organizations',
                    sortable: false,
                    sortDirection: 'desc',
                    class: 'text-center'
                },
                {
                    key: 'time_limit',
                    label: 'Time limit',
                    formatter: (value, key, item) => {
                        let time_limit = JSON.parse(item.time_limit)
                        let isTimesValide = helper.validateTime(time_limit.time_limit_to)
                        if (!isTimesValide) time_limit = {
                            time_limit_to: null,
                            time_limit_from: null
                        }
                        item.time_limit = JSON.stringify(time_limit)
                        return isTimesValide ? time_limit.time_limit_from + ' to ' + time_limit.time_limit_to : '--'
                    },
                    class: 'text-center timeClass'
                },
                {
                    key: 'limit',
                    label: 'Limit/Day',
                    class: 'text-center',
                    formatter: (value, key, item) => {
                        return item.limit != -1 ? item.limit : '--'
                    },
                },
                {
                    key: 'created_by',
                    label: 'Created by',
                    class: 'text-center',
                    formatter: (value, key, item) => {
                        return !item.user ? '--' : item?.user?.name
                    },
                },
                {
                    key: 'created_at',
                    label: 'Created at',
                    sortable: true,
                    class: 'text-center',
                    formatter: (value, key, item) => {
                        var dt = new Date(item.created_at);
                        return helper.formatDate(dt)
                    },
                },
                {
                    key: 'active',
                    label: 'Status',
                    sortable: true,
                    class: 'text-center'
                },
                { key: 'actions', label: 'Actions', class: 'text-center' }
            ],
            totalRows: 1,
            currentPage: 1,
            perPage: 25,
            pageOptions: [10, 25, 50, 100, {
                value: 250,
                text: "Show a lot"
            }],
            sortBy: 'active',
            sortDesc: true,
            sortDirection: 'asc',
            filter: null,
            filterOn: ["name", "tag_name"],
            account: null,
            from: null,
            to: null,
            editModal: {
                id: 'edit-modal',
                title: '',
                item: {}
            },
            sendFlowModal: {
                id: 'send-flow-modal',
                title: '',
                item: {}
            },

            selected: [],
            selectAll: true,
            indeterminate: false,
            itemInOperate: null,
            renderModalUpdate: false,
            links: [],
            filterData: null
        }
    },
    computed: {
        ...mapGetters({
            isClient: 'Auth/isClient',
        }),
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return {
                        text: f.label,
                        value: f.key
                    }
                })
        }
    },
    mounted() {
        this.loadFields()
        this.renderModalUpdate = true;
    },
    methods: {
        platformName(name) {
            return helper.platformFormatName(name);
        },
        integrationList(lists) {
            let obj = lists.find(o => o.validation_code != null);
            return obj?.validation_code;
        },
        async getData(ctx) {
            await this.delay();
            return await this.fetchData(ctx);
        },
        async fetchData(ctx) {
            var items = []
            this.items = [];
            this.isBusy = true;
            let queryParams = {}
            if (typeof ctx !== "undefined") {
                queryParams = {
                    search: this.filter,
                    page: ctx.currentPage,
                    per_page: ctx.perPage,
                    sort_by: ctx.sortBy,
                    sort_desc: ctx.sortDesc,
                    // filter_on: JSON.stringify(this.filterOn),
                    account: this.account ? this.account.code : null
                }

                if (this.filterData) {
                    queryParams.filters = this.filterData;
                }
            }

            return api.integrations.list(queryParams)
                .then((response) => {
                    this.selected = []

                    items = response.data.data
                    this.totalRows = response.data.total
                    this.currentPage = response.data.current_page
                    this.perPage = response.data.per_page
                    this.items = items
                    this.from = response.data.from
                    this.to = response.data.to
                    this.links = response.data.links

                    items = _.map(items, (row) => {
                        if (this.selectAll) {
                            row.selected = true
                            let alreadyAdded = _.find(this.selected, function (o) { return row.id == o; });
                            if (!alreadyAdded) {
                                this.selected.push(row.id)
                            }
                        } else {
                            row.selected = false
                        }

                        return row
                    })
                    return items
                })
                .catch((err) => {
                    return []
                })
                .finally(() => {
                    setTimeout(() => {
                        this.indeterminate = false
                        this.isBusy = false;
                        this.$emit('onFinishedFilter');
                    }, 400);
                })
        },
        delay: () => {
            return new Promise(resolve => setTimeout(resolve, 2000));
        },
        loadFields() {
            let fields = _.map(this.fields, (item) => {
                if (store.getters['Auth/isAdmin']) {
                    return item;
                } else
                    if (!["account", "created_by"].includes(item.key)) return item;
            })
            this.fields = fields;
        },

        setAccount(account) {
            this.account = account
            this.currentPage = 1
            this.$root.$emit('bv::refresh::table', this.id)
        },
        onRowHovered(item, _, event) {
            if (store.getters['Auth/isAdmin']) {
                let options = {
                    title: INTEGRATION_STATES[item.platforms_integration_validation_code] ? INTEGRATION_STATES[item.platforms_integration_validation_code]['message'] : (
                        INTEGRATION_STATES[item.integrations_validation_code] ? INTEGRATION_STATES[item.integrations_validation_code]['message'] : (this.integrationList(item.lists) ? INTEGRATION_STATES[this.integrationList(item.lists)]['message'] : (
                            INTEGRATION_STATES[item.accounts_integration_validation_code] ? INTEGRATION_STATES[item.accounts_integration_validation_code]['message'] : ''
                        ))),
                    trigger: 'hover',
                }
                $(event.target).tooltip(options)
            }

        },
        onRowUnhovered() {
            this.rowTooltip = null;
        },
        onFiltered(filteredItems) {
            this.selectAll = null
            // this.totalRows = filteredItems.length
        },
        sortingChanged(ctx) {
            this.currentPage = 1
        },

        // ACTIONS
        selectRow() {
            this.selected = []

            if (this.selectAll) {
                for (let i in this.items) {
                    this.selected.push(this.items[i].id);
                }
            } else {
                this.selected = []
            }
        },
        isChecked(item) {

            if (this.selected.includes(item.id)) {
                item.selected = true
            } else {
                item.selected = false
            }

            return 'checked' ? this.selected.includes(item.id) : '';
        },
        hasChangeRowSelect($event, item) {

            if (!this.selected.includes(item.id) && $event.target.checked) {
                this.selected.push(item.id)
            } else {
                this.selected = _.remove(this.selected, function (n) {
                    return n !== item.id
                });
            }

            if (this.selected.length) {
                this.indeterminate = true;
            } else {
                this.indeterminate = false;
            }

        },
        handleSelectedAction(action = 'turn-on') {

            let checkShowAlert  = !this.isClient ? {state: null} : helper.showAlertAddNewItem(SERVICE_ID);

            if (checkShowAlert.state && checkShowAlert.alert) {
                this.$swal(checkShowAlert.alert);
                return
            }

            if (!this.selected.length) {
                return
            }

            this.itemInOperate = true;
            const that = this;

            let bulkData = { active: -1 }

            let alertObject = {
                title: 'Are you sure?',
                text: `This action will permenantly delete ` + (this.selected.length ? ` ${(this.selected.length)} Integration${(this.selected.length > 1 ? 's' : '')}` : ''),
                type: 'danger',
                showCancelButton: true,
                confirmButtonText: 'Delete',
                showCloseButton: true,
                showLoaderOnConfirm: true,
                confirmButtonColor: '#e64141',

                showLoaderOnConfirm: true,
                allowOutsideClick: () => !that.$swal.isLoading()
            }

            switch (action) {
                case 'turn-on':
                    alertObject.type = 'success'
                    alertObject.text = `If your ` + `${(this.selected.length ?? '')}` + ` Integration${(this.selected.length > 1 ? 's' : 'Integration')}` + ` shares contacts with any other synced list on the InboxGeek platform, events will begin sending as soon as today.`
                    alertObject.confirmButtonText = 'Turn on'
                    alertObject.confirmButtonColor = '#00ca00'
                    bulkData = { active: 1 }
                    break

                case 'turn-off':
                    alertObject.type = 'question'
                    alertObject.text = `If an integration is turned off for longer than 1:00a EST the following day, the contacts synced to that list will need 24 hours to re-sync once the integration is turned on again.`
                    alertObject.confirmButtonText = 'Turn off'
                    alertObject.confirmButtonColor = '#0084ff'
                    bulkData = { active: 0 }
                    break

                case 'delete':
                    break

                default:
                    break
            }

            let swalObj = {
                type: 'success',
                confirmButtonText: 'OK',
                showCloseButton: true,
            }

            alertObject.preConfirm = () => {

                that.$swal.update({
                    title: 'In Progress', text: 'Please wait, action in progress ...', showConfirmButton: false, allowOutsideClick: false, showCloseButton: false,
                })

                if (action == 'delete') {

                    return api.integrations.delete(that.selected[0], { items: that.selected })
                        .then(result => {
                            swalObj.title = 'Delete!';
                            swalObj.text = result.message;
                            this.indeterminate = false
                        })
                        .catch(function (error) {
                            swalObj.type = 'warning';
                            swalObj.title = 'Warning!';
                            swalObj.text = 'Something went wrong!';
                        })
                }

                return api.integrations.put(that.selected[0], { items: that.selected, data: bulkData })
                    .then(result => {
                        swalObj.title = 'Success!';
                        swalObj.text = result.message;
                        this.indeterminate = false
                    })
                    .catch(function (error) {
                        swalObj.type = 'warning';
                        swalObj.title = 'Warning!';
                        swalObj.text = 'Something went wrong!';
                    })

            };

            that.$swal(alertObject)
                .then((result) => {
                    that.itemInOperate = false;

                    if (result.isConfirmed) {
                        that.selected = [];
                        that.selectAll = false;
                        that.$swal(swalObj)
                        that.$root.$emit('bv::refresh::table', that.id);
                    }
                })

        },
        accountIsInactive() {
            console.log( this.details )
            return false;
            return (this.isClient && !this.details.account.status)
        },
        editItem(item, index, button) {
            this.editModal.title = `Edit integration ${item?.name}`
            this.editModal.item = item
            this.$root.$emit('bv::show::modal', this.editModal.id, button)
        },
        resetEditModal() {
            this.editModal.title = ''
            this.editModal.item = {}
        },
        hideUpdateModalHide(success) {
            this.forceRerenderModalUpdate();
            setTimeout(() => {
                if (success) {
                    this.$root.$emit('bv::hide::modal', this.editModal.id)
                    this.$root.$emit('bv::refresh::table', this.id)
                }
            }, 2000)
        },
        sendFlow(item, index, button) {

            if (this.isClient && !helper.checkIfAccountSubscriptionByService(SERVICE_ID)) {
                helper.showAlertRemainingByService(this.$swal, SERVICE_ID)
                return
            }

            this.sendFlowModal.item = item;
            this.$root.$emit('bv::show::modal', this.sendFlowModal.id, button)
        },
        histories(item) {
            this.$emit('onShowInvoiceHistories', item);
        },
        removeItem(item, index, button) {
            this.$swal({
                title: 'Are you sure?',
                text: `This action will permenantly delete ${item.name} integration.`,
                type: 'danger',
                showCancelButton: true,
                confirmButtonText: 'Yes Delete it!',
                cancelButtonText: 'No, Keep it!',
                showCloseButton: true,
                showLoaderOnConfirm: true
            }).then((result) => {
                if (result.value) {
                    let swalObj = {
                        type: 'success',
                        confirmButtonText: 'OK',
                        showCloseButton: true,
                    }

                    api.integrations.delete(item.id)
                        .then(result => {
                            swalObj.title = 'Success!';
                            swalObj.text = result.message;
                        })
                        .catch(function (error) {
                            swalObj.type = 'warning';
                            swalObj.title = 'Warning!';
                            swalObj.text = 'Something went wrong!';
                        })
                        .finally(() => {
                            this.$swal(swalObj)
                            this.$root.$emit('bv::refresh::table', this.id);
                        });
                }
            })
        },
        showAlertBalance() {
            console.log('showAlertBalance', this.details )
            return false;
            return this.isClient
                && (!this.details.remaining
                    || (this.details.limit != -1 && (this.details.remaining + this.details.limit <= 0))
                )

        },
        showAlertRemaining() {
            const that = this
            let date = this.details.plan ? this.details.plan.next_billing_date ?? 'undefined' : '--';

            let alertMessage = `It\'s appears you are out of events until [${date}]. If you\'d like to activate your overconsumption feature to continue sending events until your next billing cycle, please visit your dashboard`;

            this.$swal({
                title: 'Uh, oh...',
                text: alertMessage,
                type: 'info',
                confirmButtonText: 'Got it!',
                showCloseButton: true,
            })
        },
        onUpdateItem(data) {

            if (!data.input || !data.row) {
                return
            }


            if (this.isClient && !helper.checkIfAccountSubscriptionByService(SERVICE_ID)) {
                helper.showAlertRemainingByService(this.$swal, SERVICE_ID)
                data.input.checked = !data.input.checked
                return
            }

            let itemCode = data.row.id
            let form = { active: data.input.checked ? 1 : 0 }

            let toast = { message: 'Something went wrong!', title: 'Error', variant: 'danger' }

            return api.integrations.put(itemCode, form)
                .then(result => {
                    toast = { message: result?.message, title: 'Success', variant: 'success' }
                })
                .catch((error) => {
                    this.$root.$emit('bv::refresh::table', this.id)
                })
                .finally(() => {
                    this.$root.$bvToast.toast(toast.message, toast);
                    this.$root.$emit('bv::refresh::table', this.id)
                });
        },
        forceRerenderModalUpdate() {
            this.renderModalUpdate = false;
            this.$nextTick(() => {
                this.renderModalUpdate = true
            })
        },

        itemIsUpdating(item) {
            return item.selected && this.selected.includes(item.id) && this.itemInOperate
        },

        selectAllItems($event) {
            let links = _.filter(this.links, function (o) { return o.url && !['Next &raquo;'].includes(o.label); });

            let queryParams = {
                search: this.filter,
                sort_by: this.sortBy,
                sort_desc: this.sortDesc,
                account: this.account ? this.account.code : null,
                per_page: this.perPage,
            }

            let allItemsPromises = _.map(links, (item) => {
                let page = item.url.split("?page=")[1]
                queryParams.page = page
                return api.integrations.list(queryParams)
            })


            const that = this

            Promise.all(allItemsPromises).then((values) => {

                _.forEach(values, function (resp) {
                    if (resp.success) {
                        _.forEach(resp.data.data, function (row) {
                            let alreadyAdded = _.find(that.selected, function (o) { return row.id == o; });
                            if (!alreadyAdded) {
                                that.selected.push(row.id)
                            }
                        });
                    }
                });

                that.selectAll = true;
                that.indeterminate = false;
            });
        },
        clearSelection($event) {
            this.selectAll = false
            this.indeterminate = false;
            this.selected = []
        },
        filterTable(params) {
            this.filterData = JSON.stringify({
                status: params.status?.code ?? '',
                platform: params.platform ? _.map(params.platform, 'name') : [],
                daily_cap: params.daily_cap ?? '',
                date_to: params.date_to?? '',
                date_from: params.date_from ?? '',
                plans: params.plans ? _.map(params.plans, 'code') : [],
                platform: params.platform ? _.map(params.platform, 'name') : [],
                account_type: params.account_type ? params.account_type.code : '',

            });
            this.$root.$emit('bv::refresh::table', this.id)
        }
    },
    watch: {}
}
</script>

<style>
.ig-text-wrap {
    word-wrap: break-word;
}

ul.dropdown-menu.show {
    display: inline-block !important;
}

.dropdown-toggle::after {
    display: none !important;
}

li a.dropdown-item,
a[target] {
    padding: 10px 5px;
    text-decoration: none;
}

.dots-animate {
    animation: shadow-pulse-dots 1s infinite;
}

.v-enter-active,
.v-leave-active {
    transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}

.dropdown.b-dropdown {
    float: right !important;
}
</style>
