import { Add } from '@/actions/modules/Integrations/AddIntegrations';
import { COLUMNS_GRID } from "@/constantes";
import api from '@/api/RestClient';

export default function platform(axios) {
    return {
        labels: {
            listID: "id",
            listName: "name",
            tagID: "id",
            tagName: "name",
        },
        customShowAnotherCols(COMP) {
            if(typeof COMP.showCols.apiKey !== 'undefined')  COMP.showCols.apiKey = true;
            if(typeof COMP.showCols.accountId!== 'undefined')  COMP.showCols.accountId = false;

            COMP.showCols.tags = false;
            COMP.showCols.sendin_blue_event = true;
            if(typeof COMP.showCols.apiKey !== 'undefined')  COMP.showCols.apiKey = true;
            if(typeof COMP.showCols.accountId!== 'undefined')  COMP.showCols.accountId = true;
            COMP.apiTokenLabel = 'Api Key:*';

            if(typeof COMP.$refs["step-platform"] !== 'undefined') COMP.$refs["step-platform"].accountLabel = 'MA Key:*';
            if(typeof COMP.$refs["step-platform"] !== 'undefined') COMP.$refs["step-platform"].accountPlaceholder = 'MA Key';

            if(typeof COMP.accountLabel !== 'undefined') COMP.accountLabel = 'App ID: *';
            if(typeof COMP.accountLabel !== 'undefined') COMP.accountPlaceholder = 'App ID';


            COMP.apiTokenPlaceholder = 'API key';
            if(typeof COMP.cols.apiKeyCol !== 'undefined')  COMP.cols.apiKeyCol = COLUMNS_GRID.COL12;

        },
        getTagAdded(result) {
            result = 'data' in result ? result.data : result;
            return { code: result.ListID, name: result.Name }
        },
    }
}