<template>
  <div>
    <b-modal size="xl" id="LoDailyLimitAutoUpdate" ref="LoDailyLimitAutoUpdate" hide-footer
      class="custom-modal-content modal-xl-custom" title="Daily Limit Auto Update">

          <!-- START FILTER OPTIONS ROW  -->
          <b-row class="mb-3 pt-4" align-h="between">

              <!-- START Filter Input -->
              <b-col md="10">
                <b-form-group class="mb-0">
                  <b-input-group>

                    <b-form-input id="filter-input" v-model="filter" type="search" placeholder="Type to Search"></b-form-input>

                  </b-input-group>
                </b-form-group>
              </b-col>
              <!-- END Filter Input -->

              <b-col md="2" class="text-right my-auto">
                  <button class="btn btn-primary" v-b-modal.addLoDailyLimitAutoUpdateWizardModal>Add New Logic</button>
              </b-col>

          </b-row>
          <!-- END FILTER OPTIONS ROW  -->



          <!-- lo_daily_limit_auto_updates -->
          <DataTable v-if="renderDT"
            ref="dt-lo_daily_limit_auto_updates" :url="'/lo_daily_limit_auto_updates'"
            v-bind="{ columns: columns, customCells: dtCells, organization: isAdmin ? account : user.account_id, filters: filters, hasSearch: false }" @onClick="handleBtn"  />



    </b-modal>


    <CreateLoDailyLimitAutoUpdateWizardModal :account="account" @LoDailyLimitAutoUpdateCreated="forceRenderTable()"></CreateLoDailyLimitAutoUpdateWizardModal>

    <UpdateLoDailyLimitAutoUpdateWizardModal v-bind="{account: account, item: editLoDailyLimitAutoUpdateModal.item}" @LoDailyLimitAutoUpdateUpdated="forceRenderTable()"></UpdateLoDailyLimitAutoUpdateWizardModal>

  </div>
</template>


<script>

import CreateLoDailyLimitAutoUpdateWizardModal from '@/components/inboxgeek/modals/Wizards/LoDailyLimitAutoUpdate/CreateLoDailyLimitAutoUpdateWizardModal.vue'
import UpdateLoDailyLimitAutoUpdateWizardModal from '@/components/inboxgeek/modals/Wizards/LoDailyLimitAutoUpdate/UpdateLoDailyLimitAutoUpdateWizardModal.vue'

import Multiselect from 'vue-multiselect'
import DataTable from "@/components/inboxgeek/tables/DataTable.vue";
import DtLoDailyLimitValues from "@/views/Integration/children/DtLoDailyLimitValues";
import DtLoDailyLimitOptions from "@/views/Integration/children/DtLoDailyLimitOptions";
import DtLoDailyLimitAutoUpdateActions from '@/views/Apps/children/DtLoDailyLimitAutoUpdateActions.vue';
import DtLoDailyLimitAutoUpdateOptions from '@/views/Apps/children/DtLoDailyLimitAutoUpdateOptions.vue';

import api from "@/api";
import { helper } from '@/helpers';
import { PREFERENCES_AUTO_UPDATE_LIST } from "@/constantes";

import { mapGetters } from "vuex";
import _ from 'lodash';

export default {

  name: 'LoDailyLimitAutoUpdate',
  props: ['account'],
  components: {
    CreateLoDailyLimitAutoUpdateWizardModal,
    UpdateLoDailyLimitAutoUpdateWizardModal,
    Multiselect,
    DataTable
  },
  computed: {
    ...mapGetters('Auth', ['user', 'isAdmin']),
    columns() {
      let columns = [
        { label: 'Name', key: 'name', class: 'text-left', sortable: true, sortDirection: 'desc' },
        { label: 'Conditions', key: 'options', class: 'text-left', sortable: true, sortDirection: 'desc' },
        {
          label: 'Created at', key: 'created_at', class: 'text-center', sortable: true, sortDirection: 'desc',
          formatter: (value, key, item) => {
            let dt = new Date(value);
            return helper.formatDate(dt)
          }
        },
        { label: 'Actions', key: 'actions', class: 'text-center', sortable: false, sortDirection: 'desc' },
      ];

      return columns;
    }
  },
  mounted() {
  },
  data() {
    return {
      editLoDailyLimitAutoUpdateModal: {
        title: '--',
        item: null,
      },

      loader: {
        saving: false,
        table: false
      },
      filter: null,
      filters: {},
      renderDT: true,
      dtCells: [
        {
					key: 'options',
					component: DtLoDailyLimitAutoUpdateOptions,
					event: null,
				},
        {
					key: 'actions',
					component: DtLoDailyLimitAutoUpdateActions,
					event: 'onClick',
				}
      ],

      error: false,
      errors: {},
      error_message: 'an error accrued, try again later',


    }
  },
  methods: {
    handleBtn(data) {

      if (!data.event || !data.row) {
        return
      }

      let action = data.event.title.toLowerCase();

      // this.ge_source = data.row;

      if (action == 'edit') {
        this.handleEditLoDailyLimitAutoUpdate(data.row)
      }

      if (action == 'delete') {
        this.handleDeleteLoDailyLimitAutoUpdate(data.row)
      }

      helper.delay(2000).then(() => {
        if (data.ref) {
          data.ref.setState(false);
        }
      });

    },
    handleEditLoDailyLimitAutoUpdate(item) {
			if (!item || !item.id) {
				return
			}


			this.editLoDailyLimitAutoUpdateModal.title = `Edit LiveOpens Daily Limit Auto Update - ${item.name}`
			this.editLoDailyLimitAutoUpdateModal.item = item

      setTimeout(() => {
        this.$root.$emit('bv::show::modal', 'editLoDailyLimitAutoUpdateWizardModal')
      }, 500)

		},
    handleDeleteLoDailyLimitAutoUpdate(item) {
			if (!item || !item.id) {
				return
			}

      this.$swal({
          title: "Are you sure to delete LiveOpens Daily Limit Auto Update?",
          text: 'Deleting this item disables the "Daily Limit Auto Update" setting for all associated integrations.',
          icon: 'warning',
          confirmButtonText: "Yes, please delete",
          cancelButtonText: "Cancel",
          showCancelButton: true,
          showCloseButton: true,
          showLoaderOnConfirm: true,
          customClass: {
            confirmButton: 'btn btn-primary'
          },
      }).then((result) => {
          if (result.isConfirmed) {

            api.loDailyLimitAutoUpdates.delete(item.id)
              .then((response) => {

                this.$swal('LiveOpens Daily Limit Auto Update', response.message, "success");
                this.forceRenderTable();

              })
              .catch((error) => {

                // handle error
                if (error.response.data.errors) {
                  let title = 'Oops ...';
                  this.$swal(title, error.response.data.errors.message, "error");
                }

              })

          }
      })

		},
    forceRenderTable() {
        this.renderDT = false;

        this.$nextTick(() => {
            this.renderDT = true;
        });
    },
  },
  watch: {
    "filter": {
      handler(newValue, oldValue) {
        if(this.$refs['dt-lo_daily_limit_auto_updates'] && typeof this.$refs['dt-lo_daily_limit_auto_updates'].setFilter == 'function') {
          this.$refs['dt-lo_daily_limit_auto_updates'].setFilter(newValue);
        }
      }
    }
  }

}



</script>

<style>
#LoDailyLimitAutoUpdate .modal-xl {
  /* Set a custom width for the modal content */
  max-width: 80vw !important;
  /* Adjust the width as needed */
}

.swal2-confirm.btn.btn-primary.swal2-styled{
  background-color: #f6386b !important;
}
</style>
