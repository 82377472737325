<template>
    <b-container fluid>
        <!-- Main table element -->
        <b-table :items="items" :fields="fields" :current-page="currentPage" :per-page="perPage" :filter="filter"
            :filter-included-fields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection" stacked="md" show-empty small @filtered="onFiltered">
            <template #cell(name)="row">
                {{ row.value.first }} {{ row.value.last }}
            </template>

            <template #cell(list_quality)="row">
                {{ row.value }}
                <i v-if="row.value > 75" class="ml-1 ri-arrow-up-fill text-success"></i>
                <i v-else-if="row.value > 50  && row.value <= 75" class="ml-1 ri-arrow-up-fill text-warning"></i>
                <i v-else class="ml-1 ri-arrow-down-fill text-danger"></i>
            </template>

            <template #row-details="row">
                <b-card>
                    <ul>
                        <li v-for="(value, key) in row.item" :key="key">{{ key }}: {{ value }}</li>
                    </ul>
                </b-card>
            </template>
        </b-table>

        <!-- User Interface controls -->
        <b-row>
            <b-col sm="7" md="6" class="my-1">
                <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm"
                    class="my-0"></b-pagination>
            </b-col>
        </b-row>

        <!-- Info modal -->
        <b-modal :id="infoModal.id" :title="infoModal.title" ok-only @hide="resetInfoModal">
            <pre>{{ infoModal.content }}</pre>
        </b-modal>
    </b-container>
</template>

<script>
export default {
    data() {
        return {
            items: [],
            fields: [
                {
                    key: 'date',
                    label: 'Date',
                    formatter: (value, key, item) => {
                        return value;
                    },
                    sortable: true,
                    sortByFormatted: true,
                    filterByFormatted: true
                },
                { key: 'total_contacts', label: 'Total Contacts', sortable: true, class: 'text-center' },
                { key: 'total_tracked_contacts', label: 'Total Tracked Contacts', sortable: true, class: 'text-center' },
                { key: 'events_received', label: 'Events Received', sortable: true, sortDirection: 'desc' },
                { key: 'list_quality', label: 'List Quality' }
            ],
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            infoModal: {
                id: 'info-modal',
                title: '',
                content: ''
            }
        }
    },
    props: {
        row: {
            type: Object,
            default(rawProps) {
                return {}
            }
        }

    },
    computed: {
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return { text: f.label, value: f.key }
                })
        }
    },
    mounted() {
        // Set the initial number of items
        this.items = this.row.logs;
        this.totalRows = this.items.length
    },
    methods: {
        info(item, index, button) {
            this.infoModal.title = `Row index: ${index}`
            this.infoModal.content = JSON.stringify(item, null, 2)
            this.$root.$emit('bv::show::modal', this.infoModal.id, button)
        },
        resetInfoModal() {
            this.infoModal.title = ''
            this.infoModal.content = ''
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        }
    }
}
</script>