<template>
  <div class="iq-card iq-card-block iq-card-stretch">
    <div class="iq-card-body p-4">
      <b-row>
        <b-col class="d-flex flex-column justify-content-center align-items-center p-4" md="8">
          <div>
            <h2>Have you heard about our {{ serviceLabel }} service?</h2>

            <p>{{ serviceDescription }}</p>
            
            <router-link alt="Subscribe now" class="btn btn-primary"
                    :to="{ name: 'services.plan', params: { service: serviceCode } }">Subscribe now</router-link>
          </div>

        </b-col>
        <b-col md="4">
          <ServiceAnnounceLiveOpensSvg v-show="serviceCode == liveOpensID"></ServiceAnnounceLiveOpensSvg>
          <ServiceAnnounceSvg v-show="serviceCode == liveLeadsID"></ServiceAnnounceSvg>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import ServiceAnnounceSvg from '@/components/inboxgeek/svg/ServiceAnnounceSvg.vue'
import ServiceAnnounceLiveOpensSvg from '@/components/inboxgeek/svg/ServiceAnnounceLiveOpensSvg.vue'


import api from '@/api/RestClient'
import { SERVICES } from '@/constantes';
import {
    mapGetters
} from "vuex";


export default {
  name: 'AnnounceService',
  props: ['serviceCode'],
  components: {
    ServiceAnnounceSvg,
    ServiceAnnounceLiveOpensSvg
  },
  methods: {

    subscribeToNewService(){

      const accountId = this.$store.getters['Auth/user'].account_id;

      api.subscriptions.subscribeSubscription(accountId).then(function(){

          //console.log('subscribe');

      }).catch(function(){

      //  console.log('test not working');


      }).finally(()=>{
      
        api.plans.planByAccount(accountId, this.serviceCode).then(response => {

          if (response.success && response.data) {

            // TODO Update store subscriptions data
            let subscriptions = this.UserSubscriptions;
            this.$store.dispatch('Auth/updateUserSubscriptions', subscriptions[this.serviceCode]);

            // dispatch  updateLiveLeadsSubscribed
            if (this.serviceCode == SERVICES.LIVE_OPENS.id) {
              this.$store.dispatch('Auth/updateLiveOpenersSubscribed', response.data && response.data.is_trial);
            } else if (this.serviceCode == SERVICES.LIVE_LEADS.id) {
              this.$store.dispatch('Auth/updateLiveLeadsSubscribed', response.data && response.data.is_trial);
            }

          }
        }).then(()=>{
          location.reload();
        });
                       

      });
    }
  },
  computed: {
    ...mapGetters({
      UserSubscriptions: 'Auth/UserSubscriptions'
    }),
    serviceName() {
      let name = 'LiveOpens';

      if(this.serviceCode == SERVICES.LIVE_LEADS.id) {
        name = 'LiveLeads';
      }

      return name;
    },
    serviceLabel() {
      let name = 'LiveOpens';

      if(this.serviceCode == SERVICES.LIVE_LEADS.id) {
        name = 'new LiveLeads';
      }

      return name;
    },
    serviceDescription() {
      let description = '';

      if(this.serviceCode == SERVICES.LIVE_OPENS.id) {
        description = 'LiveOpens delivers your email to your subscriber the moment they are active in their inbox. By creating an API integration with your Emailing Service Platform, InboxGeek will sync with a list chosen by you, and then send events (API calls) to your integrated platform every time one of your subscribers is active in their inbox. Want to try it out? Ask your dedicated account manager how you can get access to this email marketing game changer!';
      } else if (this.serviceCode == SERVICES.LIVE_LEADS.id) {
        description = 'LiveLeads captures the site visitors that don’t enter their personal information into any of your site forms. These fresh leads are yours to target and remarket to as you please. Want to try it out? Ask your dedicated account manager how you can get access to the newest service InboxGeek has to offer!';
      }

      return description;
    },
    liveOpensID() {
      return SERVICES.LIVE_OPENS.id
    },
    liveLeadsID() {
      return SERVICES.LIVE_LEADS.id
    },
  }
}
</script>
