<template>
  <div>

      <div class="pt-0 rounded  mt-0 text-center"  @dragover="dragover" @dragleave="dragleave" @drop="drop">

        <form @submit.prevent="submit" ref="uploadForm">
          <div class="form-group dash">

            <h1 v-if="!uploaded" style="color: #00bbff;"><i class="fa fa-upload"></i></h1>
            <h1 v-else style="color: #00bbff;"><i class="fa fa-check-circle-o"></i></h1>

            <h4 v-if="uploaded">
              Your suppression list uploaded successfully
            </h4>
            <h4 v-else-if="!uploading">
              Drag & Drop file Here or <label class="text-sm" style="font-size:0.85rem;cursor: pointer;padding: 5px 15px;background: #e5f2ff;border-radius: 5px;color: #19f;" for="file" v-if="!isDragging"> Choose a file </label>
            </h4>
            <h5 v-else>Uploading...</h5>


            <input id="file" type="file" @change="handelFileUpload" class="inputfile" ref="file" accept=".csv">


            <div class="progress py-2" v-show="uploading">
              <div class="progress-bar" role="progressbar" :aria-valuenow="progress" aria-valuemin="0"
                   aria-valuemax="100">{{ progress }}%</div>
            </div>

            <div class="p-2">
              <span v-if="file_path"><i class="fas fa-file"></i> {{ file_path.name }}</span>
            </div>


          </div>

          <div v-if="alert_message" class="alert alert-danger" style="background-color: #fcecec42;"> <i
              class="fas fa-warning m-1"></i>{{ alert_message }}</div>

        </form>





        <div class="alert alert-info px-4" v-show="!uploaded" style="color: #0087bb;background-color: #e5faff4d;">
          <i class="fas fa-info m-1"></i> <a href="/documents/webhook_file_example.csv" class="px-1" style="color:#55aadd !important;"> Click here </a> to download our file example template
        </div>

      </div>

    </div>
</template>



<script>


import {PLATFORMS_CODE} from "@/constantes";
import api from "@/api";

export default {
  name: 'SuppressionListField',
  props: [],
  components : {

  },
  mounted() {
    this.list[0] = {name: '', code: ''};
  },
  data() {
    return {
      uploaded:false,
      choose_existing_suppression_list:true,
      alert_message:false,
      uploading:false,
      isDragging:false,
      file_path:null,
      progress:null,
      current_list:null,
      list:{  },
      name:null
    }
  },
  methods: {

    handelFileUpload(event) {
      this.file_path = event.target.files[0];
      this.submit();
    },

    dragover(e) {
      e.preventDefault();
      this.isDragging = true;
    },
    dragleave() {
      this.isDragging = false;
    },
    drop(e) {

      e.preventDefault();
      this.file_path = e.dataTransfer.files[0];
      this.submit();
      this.isDragging = false;
    },
    async submit() {
      this.uploading      = true;
      this.alert_message  = null;

      let form_data = new FormData();

      const file_type = this.file_path.type;

      if (file_type === "text/csv") {

        form_data.append('list', this.file_path);
        try {


          let response = await api.suppressionList.create(form_data, {

            onUploadProgress: e => {

              this.progress = Math.round(e.loaded * 100 / e.total);
            }

          });


          if (response) {

            if (response.success) {

              this.uploaded = true;

              this.$emit('suppressionListUploaded',  {'code' : response.data?.list_id , 'name' : 'suppression list'});

            } else {
              this.uploaded = false;
              this.file_path = null;
              this.alert_message = "Invalid file structure";
              this.current_list[0].code = null;
            }

          }




        } catch (error) {

          this.file_path = null;
          this.alert_message = "Invalid file structure";
          this.list[0].code = null;

        }
      } else {


        this.file_path = null;
        this.alert_message = "Invalid file format. file must be of type text/csv";
        this.current_list[0].code = null;
      }



      this.uploading = false;
    }
  }
}
</script>




<style scoped>
.dash {

  background: rgba(207, 229, 255, 0.06);
  border-radius: 10px;
  padding: 1rem;
  border: 1px dashed #00b7ff;

}

.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.inputfile+label {
  font-size: 1.25em;
  font-weight: 700;
  color: white;
  background-color: black;
  display: inline-block;
}

.inputfile:focus+label,
.inputfile+label:hover {
  background-color: red;
}

.inputfile+label {
  cursor: pointer;
  /* "hand" cursor */
}
</style>