<template>
  <div>


    <iq-card>
        <template v-slot:headerTitle >
          <div style="display: flex; justify-content: space-between;">
            <h4 class="card-title"><i class="ri-play-circle-line"></i> Video Walkthrough Settings </h4>
          </div>
          </template>
          <template v-slot:headerAction>
            <b-form-checkbox v-model="video_status" @change="$emit('updateVideoStatus',video_status)" class="mx-2" name="check-button" switch> Active </b-form-checkbox>

          </template>
          <template v-slot:body>
            <b-form @submit="onSubmit">
             <b-row class="mb-2"> 
              <b-col>
                <b-form-input name="title" type="text" v-model="form.title"  placeholder="title"></b-form-input>
              </b-col>
              <b-col><b-form-input name="link" type="text" v-model="form.link"   placeholder="video link"></b-form-input></b-col>
            </b-row>
             <b-row class="mb-2"><b-col>
              <b-form-textarea name="description"  v-model="form.description" placeholder="description" id="" cols="30" rows="3"></b-form-textarea></b-col>
            </b-row>

            <b-row class="text-right" style="justify-content: end;padding: 15px;">
              <b-button type="submit" variant="primary">update</b-button>

            </b-row>

            </b-form>
          </template>
    </iq-card>


  </div>
</template>

<script>
import Vue from 'vue'
import Spinner1 from '@/components/loaders/spinner1'
import api from '@/api/RestClient'

export default {

  name: "VideoWalkthroughSettings",
  props:['videoStatus'],
  components: {
    Spinner1,
  },
  mounted() {
    this.video_status = this.videoStatus;

    api.settings.getVideowalkthroughStatus()
    
    .then((response)=>{
      
      if(response.success){
        
        this.video_status = (response.data== 1) ? true: false;
      }else{
        this.video_status =false;
      }
    }).catch((response)=>{
     
      this.video_status = false;
    });


    api.settings.fetchVideoDetails().then(response =>{
      this.form.link = response.data.link;
      this.form.title = response.data.title;
      this.form.description = response.data.description;
    })
  },
  data() {
    return {
      loading: true,
      video_status:false,
      steps:[],
      form : {
        title:null,
        link:null,
        description:null
      },
      current_step_index : null,
      onsubmit:false,
      loading : false
    }
    }
    ,
    methods : {
      onSubmit(event) {
        event.preventDefault()
        api.settings.updateVideoDetails(this.form).then(response=>{
          if(response.success){

            let swal = {
              icon: 'success',
              title: 'video walkthrough details updated successfully',
              text: 'details updated successfully',
              type: 'success',
              confirmButtonText: 'OK',
              showCloseButton: true,
            }

            api.settings.fetchVideoDetails().then(response =>{
              this.form.link = response.data.link;
              this.form.title = response.data.title;
              this.form.description = response.data.description;
            })
            
            this.$swal(swal)
          }
        })
      },

    }
}

</script>