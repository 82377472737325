<template>
     <b-row>
        <b-form-group class="col-md-12 my-2" label-for="selectcolumns" :label="label">
            <multiselect :loading="isLoading" :disabled="isLoading" tag-placeholder="Select columns"
                placeholder="Select columns" label="name" track-by="code" v-model="selected_" :options="columns"
                :multiple="true" :taggable="true" @input="onSelect">
            </multiselect>
        </b-form-group>
    </b-row>
</template>

<script>
import Multiselect from 'vue-multiselect'

export default {
    name: 'columnsMultiselect',
    computed: {
    },
    props: {
        'cols': {
            type: Array,
            default() {
                return [];
            }
        },
        'selected': {
            type: Array,
            default() {
                return ["tag_name"];
            }
        },
        'label': {
            type: String,
            default: '***'
        },
        
    },
    components: {
        Multiselect,
    },
    data() {
        return {
            isLoading: false,
            selected_: [],
            columns: [],
        }
    },
    mounted() {
    },
    methods: {
        onSelect(selectedOption, id) {
            this.$emit('onSelect', selectedOption)
        }
    },
    watch: {
        'cols': {
            handler(newVal, oldVal) {
                this.columns = newVal;
            }
        },
        'selected': {
            handler(newVal, oldVal) {
                if (newVal) {
                    this.selected_ = _.filter(this.columns, function (o) { return newVal.includes(o.code); });
                }
                setTimeout(() => {
                    this.isLoading = false;
                }, 1000);
               
            }
        },
        'selected_': {
            handler(newVal, oldVal) {
               this.$emit('onSelect', newVal)
            }
        },
    },
}
</script>
<style>
label[for=selectcolumns] {
    font-weight: 600;
}
</style>