<template>
    <div class="d-flex justify-content-center">

        <IgBtn :ref="'btn-edit'" :row="row" title="Edit Notification" :btnClass="'btn iq-bg-success mx-1 btn-sm'"
            :btnTitle="'Edit Notification'" :icon-class="'ri-edit-box-line text-success fa-lg mx-auto'"
            @onClick="onClick({ ...$event.target, title: 'edit' }, row.item, 'btn-edit')"></IgBtn>

        <IgBtn :ref="'btn-show'" :row="row" title="Show Notification" :btnClass="'btn iq-bg-info mx-1 btn-sm'"
            :btnTitle="'Show Content Notification'" :icon-class="'ri-eye-line text-info fa-lg mx-auto'"
            @onClick="onClick({ ...$event.target, title: 'show' }, row.item, 'btn-show')"></IgBtn>

    </div>
</template>

<script>
import IgBtn from '@/components/inboxgeek/btn/Default.vue'
import { helper } from '@/helpers'

export default {
    name: 'DtNotifActions',
    props: ['row'],
    components: {
        IgBtn
    },
    methods: {
        onClick(event, row, ref) {

            if (typeof this.$refs[ref] !== 'undefined') {
                this.$refs[ref].setState();
            }

            this.$emit('onClick', { row, event, ref: this.$refs[ref] })
        },
    }
}

</script>
