<template>
  <b-container fluid>
    <b-tabs :pills="true" class="nav-fill" id="pills-tab-1">

      <b-tab :active="true" role="tab" :ariaSelected="true" title="General" class="my-3">

        <iq-card v-for="(item, index) in settings" :key="index" v-if="item.value !== 'emails'">
          <template v-slot:headerTitle>
            <h4 class="card-title">
              <i :class="item.icon"></i> {{ item.name }}
            </h4>
          </template>
          <template v-slot:headerAction>
            <div>
              <b-button v-b-modal variant="primary" @click="handleAdd($event, item.value)" class="text-right my-auto mb-4"
                v-b-tooltip.hover title="Add Setting" :disabled="loading">
                Add {{ item.name }}
                <i class="fa fa-plus fa-lg mx-2"></i>
              </b-button>
            </div>
          </template>
          <template v-slot:body>
            <b-row v-if="loading">

              <iq-card class="col-md-12">
                <template v-slot:body>
                  <Spinner1 />
                </template>
              </iq-card>

            </b-row>
            <div v-else>
              <b-row v-if="!item.data.length">
                <b-col md="12">
                  No settings found.
                </b-col>
              </b-row>
              <b-row v-else-if="item.value !== 'emails'">

                <b-col v-for="(param, j) in item.data" :key="j" v-if="!param.name.startsWith('support_chat')" md="12"
                  :class="{ 'd-flex my-1': item.value !== 'credentials' }">

                  <label :for="param.name">
                    {{ param.name | filterCapitalize }}
                    <span class="badge cursor-pointer badge-secondary badge-pill"
                      :class="{ 'iq-bg-success': param.status == 1, 'iq-bg-warning': param.status != 1 }"> {{ param.status
                        ==
                        1 ? 'Active' : 'Inactive' }} </span>
                  </label>



                  <div class="ml-auto">
                    <!-- <button v-show="!showField(param) && item.value !== 'credentials'" 
                      class="btn btn-sm mx-1 iq-bg-danger float-right" 
                      @click="handleRemoveSetting(param)">
                        <i class="ri-delete-bin-line"></i> 
                        Remove
                    </button> -->

                    <button v-show="!showField(param) && item.value !== 'credentials'" 
                      class="btn btn-sm mx-1 iq-bg-primary float-right" 
                      @click="focusField(param)">
                        <i class="ri-pencil-line"></i> 
                        Edit
                    </button>
                    

                    <span v-show="!showField(param)" :class="{ 'float-right my-1': item.value !== 'credentials' }"
                      @click="focusField(param)">

                      <span v-if="item.value !== 'credentials'" class="text-primary">{{ param.value }}</span>

                    </span>
                    <b-input-group v-if="editSetting && showField(param)" class="">

                      <b-form-input :ref="`${param.type}-${param.id}`" v-model="editSetting.value"
                        @focus="focusField(param)" @blur="blurField(param)">
                      </b-form-input>

                      <b-input-group-append>
                        <button type="button" class="btn btn-primary fa-xl mr-0" @click="saveSetting">
                          <i class="ri-save-2-fill"></i>
                          Save
                        </button>
                        <!-- <button v-show="!showField(param) && item.value !== 'credentials'" 
                          class="btn btn-sm mx-1 iq-bg-primary float-right" 
                          @click="focusField(param)">
                            <i class="ri-pencil-line"></i> 
                            Edit
                        </button> -->
                      </b-input-group-append>
                    </b-input-group>

                  </div>
                </b-col>

              </b-row>
              <b-row v-else-if="item.value === 'emails'">
                <b-col v-for="(param, j) in item.data" :key="j" md="12" class="d-flex">
                  <label :for="param.name">
                    {{ param.name | filterCapitalize }}
                    <span class="badge cursor-pointer badge-secondary badge-pill"
                      :class="{ 'iq-bg-success': param.status == 1, 'iq-bg-warning': param.status != 1 }"> {{ param.status
                        ==
                        1 ? 'Active' : 'Inactive' }} </span>
                  </label>
                </b-col>
              </b-row>

            </div>

          </template>
        </iq-card>


       <PlatformConfigurationSettings></PlatformConfigurationSettings>
      </b-tab>


      <b-tab :active="false" role="tab" :ariaSelected="true" title="Support Chat" class="my-3">
        
        <SupportChatConfigurationSettings></SupportChatConfigurationSettings>
        
        <SupportChatAutoQuestion></SupportChatAutoQuestion>

      </b-tab>

      <b-tab :active="false" role="tab" :ariaSelected="true" title="Application Tour" class="my-3">
        <AppTourGuideSettings :appTourGuideStatus="appTourStatus" @updateAppTourGuidStatus="updateTourStatus">
        </AppTourGuideSettings>
      </b-tab>

      <b-tab :active="false" role="tab" :ariaSelected="true" title="Announces" class="my-3">
        <AnnouncementSettings></AnnouncementSettings>
        <AnnounceCardSettings></AnnounceCardSettings>
      </b-tab>

      <b-tab :active="false" role="tab" :ariaSelected="true" title="Walkthrough" class="my-3">
        <VideoWalkthroughSettings :videoStatus="videoStatus" @updateVideoStatus="updateVideoStatus">
        </VideoWalkthroughSettings>
      </b-tab>

      <b-tab :active="false" role="tab" :ariaSelected="true" title="App Campaign" class="my-3">
        <ActiveCampainSettings></ActiveCampainSettings>
      </b-tab>

      <!-- Notifications -->
      <b-tab :active="false" role="tab" :ariaSelected="true" title="Notifications" class="my-3">
        <NotificationSettings></NotificationSettings>
      </b-tab>


    </b-tabs>


    <!-- Start Add Setting Modal-->
    <b-modal :id="settingModal.id" :ref="settingModal.id" :title="settingModal.title" size="md" centered hide-footer
      class="bg-secondary py-0">
      <FormAppSetting col=12 :modal="settingModal" :types="settings" :type="settingType"
        @modalHide="closeModal(settingModal)" @handleSubmit="handleSubmit" />
    </b-modal>
    <!-- END Add Setting Modal-->

    <!-- Start Edit Setting Modal-->
    <b-modal :id="editModal.id" :ref="editModal.id" :title="editModal.title" size="md" centered hide-footer
      class="bg-secondary py-0">
      <!-- <AddEditDiscount col=12 :item="editModal.item" :modal="editModal" @modalHide="closeModal(editModal)">
      </AddEditDiscount> -->
    </b-modal>
    <!-- END Edit Setting Modal-->


    <!-- Start Edit Setting Modal-->

    <!-- END Edit Setting Modal-->
  </b-container>
</template>


<script>

import { sofbox } from "@/config/pluginInit";
import api from '@/api/RestClient'
import store from '@/store/index'
import { mapGetters } from 'vuex'
import FormAppSetting from './children/FormAppSetting'
import AppTourGuideSettings from '@/components/inboxgeek/settings/AppTourGuideSettings'
import AnnouncementSettings from '@/components/inboxgeek/settings/AnnouncementSettings'
import AnnounceCardSettings from '@/components/inboxgeek/settings/AnnounceCardSettings'
import VideoWalkthroughSettings from '@/components/inboxgeek/settings/VideoWalkthroughSettings'
import ActiveCampainSettings from '@/components/inboxgeek/settings/ActiveCampainSettings'
import NotificationSettings from '@/components/inboxgeek/settings/NotificationSettings'
// import NotificationTypesCard from '@/components/inboxgeek/settings/NotificationTypesCard'
// import DtNotifications from '@/components/inboxgeek/settings/DtNotifications'
import DtNotificationTypes from '@/components/inboxgeek/settings/DtNotificationTypes'
import DtNotificationTypeEvents from '@/components/inboxgeek/settings/DtNotificationTypeEvents'
import DataTable from './children/DataTable.vue'
import Spinner1 from '@/components/loaders/spinner1'
import { helper } from '@/helpers'
import PlatformConfigurationSettings from '@/components/inboxgeek/settings/PlatformConfigurationSettings.vue';
import SupportChatConfigurationSettings from '@/components/inboxgeek/settings/SupportChatConfigurationSettings.vue';
import SupportChatAutoQuestion from '@/components/inboxgeek/settings/SupportChatAutoQuestion.vue';
import _ from 'lodash'
import Vue from 'vue'

Vue.filter('filterCapitalize', function (value, all = null) {
  return helper.customCapitalize(value, all)
})

export default {
  name: "AppSettings",
  components: {
    AnnounceCardSettings,
    VideoWalkthroughSettings,
    AnnouncementSettings,
    AppTourGuideSettings,
    ActiveCampainSettings,
    NotificationSettings,
    PlatformConfigurationSettings,
    SupportChatConfigurationSettings,
    SupportChatAutoQuestion,
    // DtNotificationTypes,
    // DtNotificationTypeEvents,
    // DtNotifications,
    FormAppSetting,
    DataTable,
    Spinner1,
  },
  beforeMount() {
  },
  mounted() {
    sofbox.index();
    this.fetchSettings()
    api.settings.getAppTourStatus()

      .then((response) => {
        if (response.success) {
          this.appTourStatus = (response.data == 1) ? true : false;
        } else {
          this.appTourStatus = false;
        }
      }).catch((response) => {
        this.appTourStatus = false;
      });


  },
  data() {
    return {
      loading: true,
      steps: [],
      appTourStatus: false,
      videoStatus: false,
      settingModal: {
        id: 'modal-add-setting',
        title: 'Add App Setting',
        item: {}
      },
      editModal: {
        id: 'modal-edit-setting',
        title: 'Edit App Setting',
        item: {}
      },
      app_settings: [],
      settingType: null,
      settings: [
        {
          name: 'General',
          value: 'general',
          icon: 'ri-function-line',
          data: []
        },
        {
          name: 'Emails',
          value: 'emails',
          icon: 'ri-mail-settings-line',
          data: []
        },
        {
          name: 'Credentials',
          value: 'credentials',
          icon: 'ri-key-fill',
          data: []
        }
      ],
      editSetting: null,
      removeSetting: null,
      errors: {},
    };
  },
  computed: {
    ...mapGetters({
      admin: 'Auth/isAdmin',
      client: 'Auth/isClient',
    })
  },
  methods: {
    focusField(param) {
      if (param.type !== 'credentials') {
        param.edit = true
        this.editSetting = param;
      }
    },
    blurField(param) {
      console.log('Blur')
      // param.edit = false
      // this.editSetting = null;
      // this.$refs[`${param.type}-${param.id}`].blur()
    },
    showField(param) {
      return this.editSetting == param
    },
    saveSetting(e) {
      e.preventDefault()

      this.onsubmit = true;
      // this.checkForm();

      if (helper.isEmpty(this.errors)) {
        if (this.editSetting.id) {
          this.updateItem()
        } else {
          // this.addNewItem()
        }
      } else {
        // Can emit error
        this.onsubmit = false;
      }
    },
    updateItem() {
      let isValidForm = helper.isEmpty(this.errors)
      const id = this.editSetting.id
      let data = { id: this.editSetting.id, name: this.editSetting.name, value: this.editSetting.value }

      if (!isValidForm) {
        this.onsubmit = false;
        return;
      }

      api.settings.put(id, data)
        .then(data => {
          if ('success' in data) {
            this.handleSubmit({ ...data.data, new: false })
          } else {
            //that.handleError(data)

            console.log('error in update settings');
          }
        })
        .catch(errors => {
          console.log('error in update settings');
        })
        .finally(() => {
          this.onsubmit = false;
          // param.edit = false
          this.editSetting = null;
        })
    },
    fetchSettings() {
      this.loading = true


      api.settings.list({ page: 1, per_page: 50 })
        .then(response => {
          if ('success' in response) {
            this.app_settings = response.data.data
          }
        })
        .catch(errors => {
          // that.handleError(errors)
        })
        .finally(() => {

          _(this.settings).forEach(function (group) {
            group.data = [];
          });

          _(this.app_settings).forEach( (setting) => {

            let currentSetting = _.find(this.settings, (o) => { return o.value === setting.type })

            if (currentSetting) {
              currentSetting.data.push(setting)
            }

          });

          this.formatTeamEmails();

          setTimeout(() => { this.loading = false }, 1500)

        })
    },
    formatTeamEmails() {

      let emailSetting = _.find(this.settings, function (o) { return o.value == 'emails'; });

      let teamEmails = [];
      let types = {};
      if (emailSetting) {
        _(emailSetting.data).forEach(function (setting) {
          let alreadyAdd = _.find(teamEmails, function (o) { return o == setting.value; });
          if (!alreadyAdd) {
            teamEmails.push(setting.value)
            types[setting.value] = []
          }

          let alreadyAddType = _.find(types[setting.value], function (o) { return o == setting.name; });
          if (!alreadyAddType) {
            types[setting.value].push(setting.name)
          }
        });
      }
      return types
    },
    closeModal(modal) {
      this.$root.$emit('bv::refresh::table', modal.id)
    },
    handleAdd(event, type = null) {
      this.settingType = type;
      this.settingModal.title = `Add App Setting`
      this.$root.$emit('bv::show::modal', this.settingModal.id)
    },
    handleEdit(item) {
      if (!item) {
        return
      }
      this.editModal.title = `Edit Setting [${item?.name}]`
      this.editModal.item = item
      this.$root.$emit('bv::show::modal', this.editModal.id)
    },
    handleSubmit(data) {


      let swal = {
        icon: 'success',
        title: 'App Setting Added',
        text: 'Setting added successfully',
        type: 'success',
        confirmButtonText: 'OK',
        showCloseButton: true,
      }

      if (!data || !data.id) {
        swal.text = 'Something went wrong'
        swal.type = 'danger'
        swal.icon = 'warning'

        this.$swal(swal)
        return
      }

      if (data.id && !data.new) {
        swal.title = 'App Setting Updated'
        swal.text = 'Setting updated successfully'
      }

      this.$swal(swal).then((result) => {
        this.fetchSettings()
        this.$root.$emit('bv::hide::modal', this.settingModal.id)
      })
    },
    jsonToArray(json) {
      let result = JSON.parse(json)
      console.log(result)
      return JSON.parse(json)
    },
    updateTourStatus(status) {
      api.settings.updateAppTourStatus(status).then((response) => {
        if (response.success == true) {


          this.$bvToast.toast('App tour guide status successfully updated.', {
            title: 'Success',
            variant: 'success'
          });
        } else {
          this.$bvToast.toast('failed to update App tour guide status.', {
            title: 'failed',
            variant: 'error'
          });
        }
      })
    },
    updateVideoStatus(status) {
      api.settings.updateVideoStatus(status).then((response) => {
        if (response.success == true) {


          this.$bvToast.toast('App tour guide status successfully updated.', {
            title: 'Success',
            variant: 'success'
          });
        } else {
          this.$bvToast.toast('failed to update App tour guide status.', {
            title: 'failed',
            variant: 'error'
          });
        }
      })
    },
    handleRemoveSetting(param) {
      
      if (param.type !== 'credentials') {
        param.edit = true
        this.removeSetting = param;
      }

      this.onsubmit = true;
      // this.checkForm();

      if (helper.isEmpty(this.errors)) {
        if (this.removeSetting.id) {
          this.removeSettingItem()
        }
      } else {
        // Can emit error
        this.onsubmit = false;
      }
    },
    removeSettingItem() {
      let isValidForm = helper.isEmpty(this.errors)
      const id = this.removeSetting.id
      // let data = { id: this.removeSetting.id, name: this.removeSetting.name, value: this.removeSetting.value }

      if (!id) {
        this.onsubmit = false;
        return;
      }
      // console.log( 'data:id', id ); return;

      api.settings.delete(id)
        .then(data => {
          if ('success' in data) {
            console.log( 'data:delete', data );

            // this.callbackRemove(data.data);
          } else {
            console.log('error in remove settings');
          }
        })
        .catch(errors => {
          console.log('error in remove settings');
        })
        .finally(() => {
          this.onsubmit = false;
          this.removeSetting = null;
        })
    },
    callbackRemove(data, message = null) {

      let swal = {
        icon: 'success',
        title: 'App Setting Remove',
        text: message ?? 'Setting remove successfully',
        type: 'success',
        confirmButtonText: 'OK',
        showCloseButton: true,
      }

      if (!data || !data.id) {
        swal.text = message ?? 'Something went wrong'
        swal.type = 'danger'
        swal.icon = 'warning'

        this.$swal(swal)
        return
      }

      this.$swal(swal).then((result) => {
        this.fetchSettings()
        this.$root.$emit('bv::hide::modal', this.settingModal.id)
      })
    },
  },
  watch: {},
};
</script>


<style>
.actionClass {
  width: 18%;
}

.timeClass,
.createdatClass,
.accountClass {
  width: 10%;
}

textarea.form-control {
  line-height: 25px;
}

.cursor-default {
  cursor: default;
}

.table th,
.table td {
  padding: 0.55rem;
  vertical-align: top;
}

.action {
  width: 100%;
  height: 43.5px;
}</style>
