<template>
<div class="d-flex justify-content-center">
    <div v-if="hasJourneys(row.item.platform)">
        <IgBtn :ref="'btn-chart'" :row="row" title="Show Chart" :btnClass="'btn iq-bg-primary mx-1 btn-sm'" :btnTitle="'Show Chart'" :icon-class="'fa fa-eye mx-auto'" @onClick="onClick({ ...$event.target, title: 'chart' }, row.item, 'btn-chart')"></IgBtn>
    </div>
    <div class="text-primary" v-else>
        <span><i class="fa fa-times" aria-hidden="true"></i></span>
    </div>

    <div>
        <IgBtn :ref="'btn-unlink'" :row="row" title="Unlink Automation" :btnClass="'btn iq-bg-danger mx-1 btn-sm'" :btnTitle="'Unlink Automation'" :icon-class="'fa ri-link-unlink mx-auto'" @onClick="onClick({ ...$event.target, title: 'unlink' }, row.item, 'btn-unlink')"></IgBtn>
    </div>
</div>
</template>

<script>
import IgBtn from '@/components/inboxgeek/btn/Default.vue'
import {
    helper
} from '@/helpers'

export default {
    name: 'LoAutomationActions',
    props: ['row'],
    components: {
        IgBtn
    },
    data() {
        return {}
    },
    mounted() {},
    methods: {
        onClick(event, row, ref) {

            if (typeof this.$refs[ref] !== 'undefined') {
                this.$refs[ref].setState();
            }

            this.$emit('onClick', {
                row,
                event,
                ref: this.$refs[ref]
            })
        },
        hasJourneys(platform_name) {
            return [
                'maropost',
                'expertsender',
                'ongage',
                'campaigner',
                'mailchimp',
                'sendgrid',
            ].includes(platform_name.toLowerCase());
        }
    }
}
</script>
