<template>
  <div>
    <div v-if="isAccountActive" class="row px-4 my-2 mb-0">
      <div class="iq-progress-bar-linear d-inline-block w-100 float-left my-3">
        <b-progress :max="100" height="1rem" data-toggle="tooltip" v-b-tooltip.hover top
          :title="`${events} (${Number(percent).toFixed(2)}%)` + ` | ${remainingFormatNumber} ${remainingService}`">
          <b-progress-bar :value="percent" :label="`${events} (${Number(percent).toFixed(2)}%)`"></b-progress-bar>
        </b-progress>



      </div>
      <p>You have used {{ percent }}% of your {{ labelEventService }} this month so far.</p>
    </div>
  </div>
</template>



<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { helper } from '@/helpers'

Vue.filter('filterCapitalize', function (value, all = null) {
  return helper.customCapitalize(value, all)
})

Vue.filter('filterFormatNumber', function (value) {
  return helper.formatNumber(value)
})

const LIVE_OPEN_SERVICE = 1;
const LIVE_LEADS_SERVICE = 2;

export default {
  name: 'ConsumptionBar',
  mounted() {

  },
  props: ['service_id','isAccountActive','events','percent','remaining'],

  data() {
    return { }
  },
  methods: {

  },
  computed: {
    ...mapGetters({
      isClient: 'Auth/isClient',
    }),
    remainingFormatNumber() {
      return this.remaining.toLocaleString();
    },
    remainingService() {
      let label = '';

      if(this.service_id == LIVE_OPEN_SERVICE) {
        label = 'Events Remaining'
      } else if(this.service_id == LIVE_LEADS_SERVICE) {
        label = 'Records Remaining'
      }

      return label;
    },
    labelEventService() {
      let label = '';

      if(this.service_id == LIVE_OPEN_SERVICE) {
        label = 'Events'
      } else if(this.service_id == LIVE_LEADS_SERVICE) {
        label = 'Records'
      }

      return label;
    }
  }
}
</script>

<style>
.consumption-bar-balance {
  font-size: 12px;
}

.account-status {
  padding-left: 10px;
  display: block;
}

@media (max-width: 991px) {
  .consumption-bar {
    font-size: 12px;
  }

  .consumption-bar-number {
    font-size: 21px;
    line-height: 2;
  }

  .consumption-bar-plan-used {
    /*border: none;*/
  }

  .consumption-bar div.col-lg-3 {
    max-width: 50%;
  }

  .consumption-bar-title {
    display: block;
    width: 100%;
    white-space: nowrap;
  }
}
</style>
