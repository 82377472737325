<template>
    <b-container fluid>

        <!-- Table title -->
        <b-row class="mb-3">
            <b-col lg="4" class="my-1">
                <h4 class="card-title">Activities List</h4>
            </b-col>
        </b-row>

        <!-- User Interface controls -->
        <b-row class="mb-3">

            <b-col lg="4" class="my-1">
                <b-input-group size="md">
                    <b-form-input id="filter-input" v-model="filter" type="search" placeholder="Search"></b-form-input>
                </b-input-group>
            </b-col>

        </b-row>

        <!-- Main table element -->
        <b-table ref="table" :id="id" striped bordered :responsive="$store.getters['Auth/isAdmin']" :busy="isBusy"
            :items="getData" :fields="fields" :current-page="currentPage" :per-page="perPage" :filter="filter"
            :filter-included-fields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection" stacked="md" show-empty small @filtered="onFiltered"
            @sort-changed="sortingChanged">

            <template #table-busy>
                <div class="text-center d-inline-block my-2">
                    <span class='fa-stack fa-lg'>
                        <i class='fa fa-spinner fa-spin fa-stack-2x fa-fw'></i>
                    </span>&emsp;Processing ...
                </div>
            </template>

            <template #empty="scope">
                <p class="mb-1">No matching records found</p>
            </template>

            <!-- Cell status -->
            <template #cell(status)="row">
                <span class="badge cursor-pointer badge-secondary badge-pill" :class="row.item.status.color">
                    {{ row.item.status.name }}
                </span>
            </template>

        </b-table>

        <!-- DataTable pagination -->
        <b-row class="justify-content-between mt-3">
            <b-col md="9" class="my-1">
                <b-row class="dataTables_info justify-content-between" id="lists_info" role="status" aria-live="polite">
                    <b-col md="10" class="">
                        Showing {{ from }}
                        to {{ to }} of {{ totalRows }} entries
                    </b-col>
                    <b-col md="2" class="">
                        <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm">
                        </b-form-select>
                    </b-col>
                </b-row>
            </b-col>
            <b-col md="3" class="my-1">
                <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm"
                    class="my-0"></b-pagination>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import Spinner1 from '@/components/loaders/spinner1'
import store from '@/store/index'
import api from '@/api/RestClient'

export default {
    name: 'DtLists',
    components: {
        Spinner1,
    },
    data() {
        return {
            id: 'dt-live-reports',
            isBusy: false,
            items: [],
            fields: [{
                key: 'id',
                label: '#ID',
                sortable: false,
                sortDirection: 'desc',
                class: 'text-center'
            },
            {
                key: 'description',
                label: 'Activities',
                sortable: false,
                sortDirection: 'desc',
                class: 'text-left description'
            },
            {
                key: 'act',
                label: 'Action',
                sortable: false,
                thStyle: {
                    width: "20em"
                },
                class: 'text-center'
            },
            {
                key: 'user_name',
                label: 'User',
                sortable: false,
                sortDirection: 'desc',
                class: 'text-center'
            },
            {
                key: 'account',
                label: 'Organization',
                sortable: false,
                sortDirection: 'desc',
                class: 'text-center'
            },
            {
                key: 'created_at',
                label: 'Dates',
                sortable: true,
                class: 'text-center'
            }

            ],
            totalRows: 1,
            currentPage: 1,
            perPage: 25,
            pageOptions: [25, 50, 100, {
                value: 100,
                text: "Show a lot"
            }],
            sortBy: '',
            sortDesc: true,
            sortDirection: 'desc',
            filter: null,
            filterOn: ["description", "user", "method", "organization"],
            account: -1,
            dates: null,
            $search: '',
            from: null,
            to: null
        }
    },
    computed: {
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return {
                        text: f.label,
                        value: f.key
                    }
                })
        }
    },
    mounted() {
        this.loadFields()
    },
    methods: {
        async getData(ctx) {
            await this.delay();
            return await this.fetchData(ctx);
        },
        async fetchData(ctx) {

            var items = []
            this.items = [];
            this.isBusy = true;
            let queryParams = {}
            if (typeof ctx !== "undefined") {
                queryParams = {
                    search: this.filter,
                    page: ctx.currentPage,
                    per_page: ctx.perPage,
                    sort_by: ctx.sortBy,
                    sort_desc: ctx.sortDesc,
                    filter_on: JSON.stringify(this.filterOn),
                }
            }

            return api.logs.all(queryParams)
                .then((response) => {
                    items = response.data.data;
                    this.totalRows = response.data.total
                    this.currentPage = response.data.current_page
                    this.perPage = response.data.per_page
                    this.items = items
                    this.from = response.data.from
                    this.to = response.data.to
                    return items
                })
                .catch((err) => {
                    console.log(err)
                    return []
                })
                .finally(() => {
                    setTimeout(() => {
                        this.isBusy = false;
                        this.$root.$emit('updatedTable');
                    }, 400);
                })
        },
        delay: () => {
            return new Promise(resolve => setTimeout(resolve, 2000));
        },
        loadFields() {
            let fields = _.map(this.fields, (item) => {
                if (store.getters['Auth/isAdmin']) {
                    return item;
                } else
                    if (!["account"].includes(item.key)) return item;
            })
            this.fields = fields;
        },

        // Filter
        onFiltered(filteredItems) {
        },
        sortingChanged(ctx) {
            this.currentPage = 1
        },
    },
    watch: {}
}
</script>

<style>
td.description>div {
    width: 35em;
    display: block;
}
</style>
