<template>
    <span>

        <span v-if="!lists.length" class="badge badge-light mx-2">Not Defined</span>
        <span v-for="(list, i) in lists" :key="i" class="mx-1">

            <!-- Platform -->
            <span v-if="list.platform && getPlatformImage(list.platform)" class="mx-2">
                <img style="width: 1.3em;" :src="getPlatformImage(list.platform)">
            </span>

            <span v-if="list.platform" class="mx-2">{{ list.platform.nickname }}</span>
            <span v-if="list.platform && !list.platform.nickname" class="mx-2">{{ platformName(list.platform.name) }}</span>

            <!-- List -->
            <span class="badge badge-primary mx-2"> {{ listNameFormatted(list) | filterLessThan }}</span> <br>
        </span>

    </span>
</template>

<script>
import { helper } from '@/helpers'
import { mapGetters } from 'vuex'
import Vue from 'vue'
Vue.filter('filterLessThan', function (value, max = 20) {
    return helper.showMaxCharacters(value, max)
})

export default {
    name: 'AutomationListsAndPlatform',
    props: ['row'],
    components: {
    },
    data() {
        return {
            // platform: null,
            lists: []
        }
    },
    mounted() {
        this.loadLists();
        // this.loadPlatform();
    },
    methods: {
        loadLists() {
            let lists = this.row.item.lists;

            lists.forEach((list) => {
                this.lists.push(list);
            });
        },
        listNameFormatted(list) {
            return `${list.name} - ${list.list_id}`;
        },
        platformName(name) {
            return helper.platformFormatName(name);
        },
        // loadPlatform() {
        //     console.log( 'this.row.item', this.row.item )
        //     this.platform = this.row.item.platform ?? null
        // },
        getPlatformImage(platform) {
            const imgFileName = platform.name.toLowerCase();
            try {
                return require(`@/assets/images/platforms/${imgFileName}.png`);
            } catch (e) {
                return null;
            }
        },
    },
    computed: {
        ...mapGetters({
            user: 'Auth/user',
            isOwnerAccount: 'Auth/isOwnerAccount',
        })
    }
}

</script>

export default {
    name: 'DtLists',
    props: ['row'],
    components: {
    },
    data() {
        return {
            lists: []
        }
    },
    mounted() {
        this.loadLists()
    },
    methods: {
        loadLists() {
            let lists = this.row.item.lists;

            console.log( 'loadLists', this.row.item.lists, this.row.item )
            lists.forEach((list) => {
                this.lists.push(list);
            });
        },
        listNameFormatted(list) {
            return `${list.list_name} - ${list.p_list_id}`;
        }
    },
    computed: {
        ...mapGetters({
            user: 'Auth/user',
            isOwnerAccount: 'Auth/isOwnerAccount',
        })
    }
}

</script>
