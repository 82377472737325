<template>
<div>
    <b-form @submit="onSubmit" @reset="onReset" v-if="show">
        <b-form-group id="input-group-1" label="Organization:" label-for="input-1" description="We'll never share your email with anyone else.">
            <accounts :organization="account" @onSelect="organization"></accounts>
        </b-form-group>

        <b-form-group label="Date" label-for="name-input" invalid-feedback="due-date is required" v-if="onUpdate">
            <b-form-input v-model="form.date" id="type-date" type="date"></b-form-input>
        </b-form-group>

        <b-form-group label="Due date" label-for="name-input" invalid-feedback="due-date is required" v-if="onUpdate">
              <b-form-input v-model="form.due_date" id="type-date" type="date"></b-form-input>
        </b-form-group>

        <b-form-group label="Status" label-for="name-input" invalid-feedback="Status is required" v-if="onUpdate">
            <b-form-select v-model="form.status" :options="options" class="mb-3" value-field="item" text-field="name" disabled-field="notEnabled"></b-form-select>
        </b-form-group>

        <b-form-group id="description" label="Description:" label-for="description">
            <b-form-textarea v-model="form.description" id="description" placeholder="Description" rows="3"></b-form-textarea>
        </b-form-group>
        <b-button type="submit" variant="primary">Submit <b-spinner small label="Small Spinner" type="grow" v-show="submit_spinner"></b-spinner></b-button>
    </b-form>
</div>
</template>

<script>
import accounts from "./accounts.vue";
import { INVOICE_STATES } from "@/constantes";
import _ from 'lodash'
import moment from 'moment'
export default {
    
    props: {
        onFinish: {
            type: Boolean,
            default: false
        },
        invoice: {
            type: Object,
            default: {}
        }
    },
    mounted() {
        if (this.onFinish) this.submit_spinner = false;
        if (!_.isEmpty(this.invoice)) {
            this.initInvoice();
            this.onUpdate = true;

        }
    },
    components: {
        accounts
    },
    data() {
        return {
            form: {
                description: null,
                account_id: null,
                due_date: this.dateNow(),
                date: this.dateNow(),
                status: 0
            },
            account: {},
            show: true,
            submit_spinner: false,
            options: INVOICE_STATES,
            onUpdate: false
        }
    },
    methods: {
        initInvoice() {
            this.form.description = this.invoice.description;
            this.form.status = this.invoice.status;
            this.account = this.invoice.account
            this.form.account_id = this.invoice.account.code;
            this.form.due_date = this.invoice.due_date;
            this.form.date = this.invoice.date;

            console.log(this.invoice);
        },
        onSubmit(event) {
            event.preventDefault()

            this.submit_spinner = true;
            let message = 'This account field must not be empty';

            if (this.form.account_id == null) {
                this.submit_spinner = false;
                this.$emit('onAccountNull', message);
                return;
            }
            if (!_.isEmpty(this.invoice)) this.$emit('onUpdateInvoice', this.form);
            else this.$emit('onCreateInvoice', this.form);
        },
        onReset(event) {
            event.preventDefault()
            // Reset our form values
            this.form.email = ''
            this.form.name = ''
            this.form.food = null
            this.form.checked = []
            // Trick to reset/clear native browser form validation state
            this.show = false
            this.$nextTick(() => {
                this.show = true
            })
        },
        organization(item) {
            if (item) {
                this.form.account_id = item.code;
            }
        },
        dateNow() {
            let now = moment(new Date()).format("YYYY-MM-DD");
            return now;
        }
    }
}
</script>
