<template>
  <div v-if="isAccountActive" class="row px-2" style="    margin-bottom: -20px; margin-top: 0;">

    <div class="col d-flex flex-column">
      <h6 class="consumption-bar-title">{{ titleEvent }}:</h6>
      <div v-if="loading" class="d-flex justify-content-center">
        <b-spinner small label="Small Spinner" type="grow"></b-spinner>
      </div>
      <h3 v-else class="consumption-bar-number m-0">{{ remaining | filterFormatNumber }}</h3>
      <!-- remaining -->
    </div>
    <div class="col ">
      <div class="d-flex flex-column">
        <h5 class="consumption-bar-title">Overconsumption Limit : </h5>
        <div v-if="loading" class="d-flex justify-content-center">
          <b-spinner small label="Small Spinner" type="grow"></b-spinner>
        </div>
        <div v-else>
          <h3 class="consumption-bar-number m-0">

            <span v-if="limit == -1"><small class="small">Unlimited</small></span>
            <span v-else>{{ limit | filterFormatNumber }}</span>

            <small>

              <b-button v-if="!freeAccount" v-b-modal.update-event-limit @click="getCurrentBalanceLimit"
                class="bg-white border-0 text-primary" style="padding-left: 7px;padding-right: 2px;">

                <i class="fas fa-pen"></i>
              </b-button>
            </small>
          </h3>

          <br>

        </div>
      </div>
      <small class="d-block">
      </small>
    </div>
    <div class="col d-flex flex-column">
      <h5 class="consumption-bar-title">{{ contactLabel }} :</h5>
      <div v-if="loading" class="d-flex justify-content-center">
        <b-spinner small label="Small Spinner" type="grow"></b-spinner>
      </div>
      <h3 v-else class="consumption-bar-number m-0">{{ contacts | filterFormatNumber }}</h3>
    </div>
  </div>
</template>



<script>

import Vue from 'vue'
import api from '@/api/RestClient'
import { mapGetters } from 'vuex'
import { helper } from '@/helpers'
import billingDetailsOpenEvent from '@/components/charts/billingDetailsOpenEvent.vue'

Vue.filter('filterCapitalize', function (value, all = null) {
  return helper.customCapitalize(value, all)
})

Vue.filter('filterFormatNumber', function (value) {
  return helper.formatNumber(value)
})

const LIVE_OPEN_SERVICE = 1;
const LIVE_LEADS_SERVICE = 2;

export default {
  name: 'ConsumptionBar',
  components: {
    'billingDetailsOpenEvent': billingDetailsOpenEvent
  },
  mounted() {

  },
  props: ['service_id','remaining','loading','limit','freeAccount','contacts'],

  data() {
    return {
      is_unlimited: false,
      form: {
        limit: 0
      },
      modal_loader: false,
      events: 0,
      percent: 0,
      balance: 0,
      plan: null,
      progress: false,
      profile: null,
      isAccountActive: true,
      showEditOverconsumption: false,
      serviceData: null,
    }
  },
  methods: {
    clientHasBalance() {
      return this.isClient && (this.limit !== -1 && (this.remaining + this.limit <= 0))
    },
    onSubmit(e) {

      this.modal_loader = true;
      e.preventDefault();
      if (this.freeAccount) return;
      if (this.is_unlimited) this.form.limit = -1;

      const data = {
        limit: this.form.limit
      };

      api.balances.updateLimit(data).then(response => {

        if (response.success) {

          const data = response.data;
          this.limit = data.limit;

          this.$bvToast.toast('Limit successfully updated', {
            title: 'Success',
            variant: 'success'
          });

          this.$refs.updateLimitModal.hide();
        }

        this.modal_loader = false;

      }).catch(error => {
        this.modal_loader = false;

        this.$bvToast.toast('failed to update the limit', {
          title: 'Error',
          variant: 'danger'
        });
      })


    },
    getCurrentBalanceLimit() {
      this.is_unlimited = (this.limit == -1);

      this.form.limit = (this.is_unlimited) ? 0 : this.limit;
    },
    showAlertRemaining() {
      let serviceName = this.service_id == LIVE_OPEN_SERVICE ? 'LiveOpens' : 'LiveLeads';
      if (this.clientHasBalance() && this.serviceData) {
        helper.showAlertRemaining(this, this.serviceData, serviceName)
      }
    }
  },
  computed: {
    ...mapGetters({
      isClient: 'Auth/isClient',
    }),
    profileFirstname() {
      if (!this.profile) {
        return ';)';
      }
      return this.profile?.first_name;
    },
    remainingFormatNumber() {
      return this.remaining.toLocaleString();
    },
    contactLabel() {
      let label = '';
      switch (this.service_id) {
        case 1:
          label = 'Contacts'
          break;
        case 2:
          label = 'Records'
          break;
        default:
          break;
      }
      return label;
    },
    titleEvent() {
      let eventLavel = 'Events Remaining';

      if(this.service_id == 2) {
        eventLavel = 'Records Remaining';
      }

      return eventLavel;
    }
  }
}




</script>
