export default function auth({ next, store }) {
    if (!store.getters['Auth/loggedIn']) {
        return next({
            name: 'auth1.sign-in1'
        })
    }


    return next()
}
