<template>

<div>
  <div class="d-flex justify-content-between">
    <h4>
      Select an existing List
    </h4>

    <b-form-checkbox v-model="upload_new_lists" name="check-button" switch>
      Upload new list
    </b-form-checkbox>
  </div>
  <div v-if="!upload_new_lists">


  <multiselect  ref="account-ref" :loading="isLoading" :disabled="isLoading" tag-placeholder="Manage account"
                placeholder="Select List"  v-model="existing_lists"  label="list_name"  track-by="id" return="id"
                :multiple="true"  @input="onSelect"  :options="fetchedLists" >
    <template slot="option" slot-scope="props">
      <div class="option__desc">

                                <span class="option__title">

                                    <span v-if="props.option.type">

                                        <i class="ri-list-check color-primary" style="color:#5ad"></i>

                                    </span>

                                    <span v-else><i class="ri-list-check color-primary" style="color:#5ad"></i></span>

                                    <span style="font-wight:bold;"> {{ props.option.list_name }} </span>


                                </span>

      </div>
    </template>

  </multiselect>

  </div>
  <div v-else>
    <div class="p-4 pt-0 rounded m-3 mt-0 text-center">

     <UploadFileFormElement
         :accepted_type="['text/csv','application/zip']"
         :uploadRequestFunction="api.webHookPlatform.uploadList"
          @listUploadedEvent="uploadedList">
     </UploadFileFormElement>

    </div>

  </div>

</div>


</template>

<script>
import api from "@/api";

import UploadedFileElement from "@/components/inboxgeek/fileds/uploadFileFieldElemets/uploadedFileElement";
import UploadFileFormElement from "@/components/inboxgeek/fileds/uploadFileFieldElemets/UploadFileFormElement";

import multiselect from "vue-multiselect";

export default {

  name: "UploadFileFiled",
  props:['list','platform'],

  components:{

    UploadFileFormElement,
    UploadedFileElement,

    multiselect
  },
  mounted() {
    this.fetchLists(this.platform);
  },
  data(){
    return{
      upload_new_lists:false,
      isLoading:false,
      existing_lists:[],
      file_path:null,
      show_progress:0,
      progress:0,
      fetchedLists:[],
      api:api
    }
  },
  methods:{
    fetchLists(platform){

      api.webHookPlatform.fetchLists({platform_id: platform.p_id}).then(response=>{
        console.log('fetched lists',response);
        this.fetchedLists = response.data;
      }).catch(error=>{

      });

    },

    uploadedList(result){

      result.uploaded_files[result.last_uploaded_file.index].code= result.response.list_id;

      this.$emit('uploadedList',
          result.uploaded_files.filter(file=>{

              return (  typeof file.code !== 'undefined' );
          })
      );

    },
    onSelect(){
      this.$emit('uploadedList',this.existing_lists.map((list)=>{
        return {
          'code':list.list_id,
          'name': list.list_name
        }
      }));
    }
  },
  watch:{
    platform(newPlatform,oldPlatform){
      this.fetchLists(newPlatform);
    }
  }
}
</script>


