<template>
    <b-container fluid>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <b-row class="mt-4" v-show="false">
                            <b-col md="4">
                                <b-form-group label="From" label-for="from">
                                    <b-form-input id="from" type="date" v-model="dates.from" :max="dates.to"
                                        :value="dates.from"></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col md="4">
                                <b-form-group label="To" label-for="to">
                                    <b-form-input id="to" type="date" v-model="dates.to" :min="dates.from" :max="dates.max"
                                        :value="dates.to"></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col md="4">
                                <b-form-group label="To" label-for="submit">
                                    <b-button class="action" variant="primary" :disabled="submitload" @click="onSubmit">
                                        <b-spinner small type="grow" v-if="submitload"></b-spinner>
                                        Submit
                                    </b-button>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </template>

                    <template v-slot:headerAction class="mt-3">

                        <b-form v-if="isAdmin" class="mx-3">
                            <AccountsMultiselect @onComplete="onAccountsLoaded" @onSelect="onSelectAccount" />
                        </b-form>

                    </template>

                    <template v-slot:body>

                        <!-- Custom Filters row -->
                        <b-row>
                            <b-col lg="12">
                                <div class="container-fluid">
                                    <b-row class="d-flex justify-content-end p-3">
                                        <b-button class="mb-2" v-b-tooltip.hover title="Filter" variant="primary"
                                            @click="isFilter = !isFilter"> <i class="fa fa-filter mr-0"></i> </b-button>
                                        <Transition name="fade">
                                            <b-col lg="12" md="12" v-show="isFilter" class="alert-secondary">
                                                <LoAutomationStatsFilters ref="dt-filters" :dtColumns="dtColumns" :platforms_array="platforms_co" @onFilter="onFilter" @onSelectedColumns="selectedColumns">
                                                </LoAutomationStatsFilters>
                                            </b-col>
                                        </Transition>
                                    </b-row>
                                </div>
                            </b-col>
                        </b-row>
                        <!-- End Custom Filters row -->

                        <DataTable v-if="renderDT" ref="lo-automations-table" :url="'/lo-automations'"
                            v-bind="{ columns: columns, customCells: dtCells, organization: accountId, filters: filters }"
                            @onClick="handleBtn" @filterUpdate="showChart = false"/>

                    </template>
                </iq-card>
            </b-col>
        </b-row>

        <!-- Chart -->
        <b-row v-if="loAutomationStat" ref="lo-automation-stat-chart" class="bloc-charts">
            <b-col md="12" v-if="showChart">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4>{{ loAutomationStat.integration_name }} LiveOpens Automation Stats</h4>
                    </template>
                    <template v-slot:body>

                        <b-row v-if="showDates" class="justify-content-center my-4">

                            <b-form class="my-auto" inline>

                                <b-form-group label="" class="" label-cols-sm="2" label-for="from">
                                    <b-form-input class="px-1" type="date" v-model="options.dates.from"
                                        :max="options.dates.to" :value="options.dates.from"
                                        @input="graph(loAutomationStat)"></b-form-input>
                                </b-form-group>

                                <label class="my-auto px-2">To</label>

                                <b-form-group label="" class="" label-cols-sm="2" label-for="to">
                                    <b-form-input class="px-1" type="date" v-model="options.dates.to"
                                        :min="options.dates.from" :max="options.dates.max" :value="options.dates.to"
                                        @input="graph(loAutomationStat)"></b-form-input>
                                </b-form-group>
                            </b-form>

                        </b-row>

                        <b-row class="d-flex justify-content-center text-center">
                            <b-button-toolbar v-if="statsData" :class="{'d-none': loAutomationStatsChart.columnsHide.includes(item.title)}"
                                v-for="(item, index) in statsData.indicators" :key="index" aria-label="">
                                <b-button-group size="sm" class="mr-0">
                                    <b-button :style="{ 'background-color': loAutomationStatsChart.bodyData.colors[index], 'border-color': loAutomationStatsChart.bodyData.colors[index] }"
                                        :class="isHide(item.title) ? 'indicator-disabled' : ''">{{ item.title }}</b-button>

                                </b-button-group>
                                <b-input-group size="sm" :append="formatDecimal(item.indicator)" class="mr-3">
                                </b-input-group>
                            </b-button-toolbar>
                        </b-row>

                        <b-col v-if="chartLoading" md="12" class="text-center">
                            <b-spinner small type="grow"></b-spinner>
                        </b-col>

                        <ApexChart v-else-if="!chartLoading && showChart" :element="'slug'"
                            :chartOption="loAutomationStatsChart.bodyData" :columnsHide="loAutomationStatsChart.columnsHide" />
                    </template>
                </iq-card>
            </b-col>
        </b-row>

    </b-container>
</template>

<script>
import { sofbox } from "@/config/pluginInit";
import Multiselect from "vue-multiselect";
import AccountsMultiselect from "@/views/children/AccountsMultiselect.vue";
import { mapGetters } from 'vuex'
import { helper } from '@/helpers'
import Spinner1 from '@/components/loaders/spinner1'
import DataTable from '@/components/inboxgeek/tables/DataTable.vue'
import _ from 'lodash'

import DtPlatform from './children/DtPlatform.vue';
import Actions from '@/views/Integration/children/LoAutomationActions.vue'

import api from '@/api/RestClient'
import moment from 'moment'

// For Filters - to change with new page filters
import LoAutomationStatsFilters from "./children/LoAutomationStatsFilters.vue";

const PERIOD_DAY = 0;
const PERIOD_WEEK = 1;
const PERIOD_MONTH = 2;
const PERIOD_YEAR = 3;
const IS_MANAGER = helper.hasRoles(['ROLE_ADMIN', 'ROLE_SUPER_ADMIN', 'ROLE_ACCOUNTS_MANAGER']);

const SERVICE_ID = 1;

export default {
    name: "UiDataTable",
    components: {
        AccountsMultiselect,
        DataTable,
        Multiselect,
        Spinner1,
        LoAutomationStatsFilters
    },
    mounted() {
        sofbox.index();
        this.initDates();
        this.initIntervalDates();
        this.loadDisplayColumns();
    },
    data() {
        return {
            loader: false,
            isFilter: false,
            renderDT: true,
            isFilterLists: false,
            filters: {},
            admin: {
                account: {
                    code: null,
                    name: ''
                }
            },
            accountId: null,
            isAdmin: IS_MANAGER,
            dates: {
                to: null,
                from: null,
            },
            renderModalAdd: false,
            loadStats: false,
            params: {},
            submitload: false,
            is_manager: false,
            dtCells: [
                {
                    key: 'platform',
                    component: DtPlatform,
                    event: null,
                },
                {
                    key: 'actions',
                    component: Actions,
                    event: 'onClick',
                }
            ],
            fields: [
                { label: 'Automation Name', key: 'automation_name', class: 'text-left', sortable: false, sortDirection: 'desc' },
                { label: 'Integration Name', key: 'integration_name', class: 'text-left', sortable: false, sortDirection: 'desc' },
                { label: 'Platform', key: 'platform', class: 'text-left', sortable: false, sortDirection: 'desc' },
                {
                    label: 'Daily Cap', key: 'daily_cap', class: 'text-center', sortable: false, sortDirection: 'desc',
                    formatter: (value, key, item) => {
                        return (value == -1) ? 'Unlimited' : value;
                    }
                },
                { label: 'Total Event', key: 'total_events_sent', class: 'text-center', sortable: false, sortDirection: 'desc' },
                {
                    label: 'Total Sends', key: 'total_sends', class: 'text-center', sortable: false, sortDirection: 'desc',
                    formatter: (value, key, item) => {
                        return this.getDtValueIfDefined(value);
                    }
                },
                {
                    label: 'Total Delivered', key: 'total_delivered', class: 'text-center', sortable: false, sortDirection: 'desc',
                    formatter: (value, key, item) => {
                        return this.getDtValueIfDefined(value);
                    }
                },
                {
                    label: 'Delivered Rate', key: 'delivered_rate', class: 'text-center', sortable: false, sortDirection: 'desc',
                    formatter: (value, key, item) => {
                        return this.getDtValueIfDefined(value);
                    }
                },
                {
                    label: 'Total Opens', key: 'total_opens', class: 'text-center', sortable: false, sortDirection: 'desc',
                    formatter: (value, key, item) => {
                        return this.getDtValueIfDefined(value);
                    }
                },
                {
                    label: 'Opens Rate', key: 'opens_rate', class: 'text-center', sortable: false, sortDirection: 'desc',
                    formatter: (value, key, item) => {
                        return this.getDtValueIfDefined(value);
                    }
                },
                {
                    label: 'Unique Opens', key: 'total_unique_opens', class: 'text-center', sortable: false, sortDirection: 'desc',
                    formatter: (value, key, item) => {
                        return this.getDtValueIfDefined(value);
                    }
                },
                {
                    label: 'Unique Open Rate', key: 'unique_open_rate', class: 'text-center', sortable: false, sortDirection: 'desc',
                    formatter: (value, key, item) => {
                        return this.getDtValueIfDefined(value);
                    }
                },
                {
                    label: 'Total Clicks', key: 'total_clicks', class: 'text-center', sortable: false, sortDirection: 'desc',
                    formatter: (value, key, item) => {
                        return this.getDtValueIfDefined(value);
                    }
                },
                {
                    label: 'Clicks Rate', key: 'clicks_rate', class: 'text-center', sortable: false, sortDirection: 'desc',
                    formatter: (value, key, item) => {
                        return this.getDtValueIfDefined(value);
                    }
                },
                {
                    label: 'Bounces', key: 'total_bounces', class: 'text-center', sortable: false, sortDirection: 'desc',
                    formatter: (value, key, item) => {
                        return this.getDtValueIfDefined(value);
                    }
                },
                {
                    label: 'Complaints', key: 'total_complaints', class: 'text-center', sortable: false, sortDirection: 'desc',
                    formatter: (value, key, item) => {
                        return this.getDtValueIfDefined(value);
                    }
                },
                {
                    label: 'Complaints Rate', key: 'complaints_rate', class: 'text-center', sortable: false, sortDirection: 'desc',
                    formatter: (value, key, item) => {
                        return this.getDtValueIfDefined(value);
                    }
                },
                {
                    label: 'Date', key: 'created_at', class: 'text-center', sortable: false, sortDirection: 'desc',
                    formatter: (value, key, item) => {
                        let dt = new Date(value);
                        return helper.formatDate(dt)
                    }
                },
                { label: 'Actions', key: 'actions', class: 'text-center', sortable: false, sortDirection: 'desc' },
            ],
            dtColumns: {
                options: [],
                selected: [],
                defaults: [
                    "automation_name",
                    "integration_name",
                    "platform",
                    "total_sends",
                    "total_clicks",
                    "total_opens",
                    "actions",
                ]
            },
            loAutomationStat: null,
            optionsColumns: [],
            selectColumns: [],
            hiddenColumns: [],

            // Chart component data
            yaxis: 5,
            periodCode: 0,
            showDates: true,
            chartLoading: false,
            showChart: false,
            options: { label: 'event_id', period: PERIOD_DAY, dates: {} },
            statsData: null,
            chartData: [],
            loAutomationStatsChart: {
                title: '***',
                type: 'line-area',
                dot: 1,
                bodyData: {
                    chart: {
                        height: 350,
                        type: 'area',
                        stacked: false,
                        events: {
                            // click: (event, chartContext, config) => {
                            //     let date = config.globals.categoryLabels[config.dataPointIndex];
                            //     if (date) {
                            //         this.detailsRedirect(date)
                            //     }
                            // },
                            legendClick: (chartContext, seriesIndex, config) => {
                                this.clearHideColumns(chartContext, seriesIndex)
                            }
                        },
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        curve: 'smooth'
                    },
                    plotOptions: {
                        bar: {
                            columnWidth: '50%'
                        }
                    },
                    colors: ['#00ca00', '#0084ff', '#FEB019', '#5A2A27', '#FD6A6A', '#662E9B', '#D7263D', '#C5D86D', '#4CAF50'],
                    series: [],
                    fill: {
                        opacity: [0.5, 0.5],
                        gradient: {
                            inverseColors: false,
                            shade: 'light',
                            type: 'vertical',
                            opacityFrom: 0.85,
                            opacityTo: 0.55,
                            stops: [0, 100, 100, 100]
                        }
                    },
                    labels: [],
                    markers: {
                        size: 0
                    },
                    xaxis: {
                        type: 'category',
                        interval: 4,
                        tickAmount: 10
                    },
                    yaxis: {
                        min: 0,
                        max: () => this.yaxis,
                        tickAmount: 9
                    },
                    tooltip: {
                        shared: true,
                        intersect: false,
                        y: {
                            formatter: function (y) {
                                if (typeof y !== 'undefined') {
                                    return y.toFixed(0)
                                }
                                return y
                            }
                        },
                        x: {},
                    },
                    legend: {
                        labels: {
                            useSeriesColors: true
                        },
                        markers: {
                            customHTML: [
                                function () {
                                    return ''
                                },
                                function () {
                                    return ''
                                },
                                function () {
                                    return ''
                                }
                            ]
                        }
                    }
                },
                columnsHide: [],
            },
            tabsPeriod: [
                { code: PERIOD_DAY, name: 'Days', format: 'YYYY-MM-DD', offset: 30 },
                { code: PERIOD_WEEK, name: 'Weeks', format: 'YYYY-MM-DD', offset: 10 },
                { code: PERIOD_MONTH, name: 'Months', format: 'YYYY-MM', offset: 6 },
                { code: PERIOD_YEAR, name: 'Years', format: 'YYYY', offset: 1 },
            ],
        };
    },
    methods: {
        manageActionFromList() {
            const COMP = this;
            let action = COMP.$route.query.action;
            let uriData = {
                ...COMP.$route.query
            };

            if (action) {
                if (['aweber_integration', 'keap_integration', 'mailchimp_integration', 'hubspot_integration'].includes(action)) {
                    if (uriData?.error) COMP.$swal('Authorization IG', 'IG App ' + uriData?.error, 'warning');
                    else COMP.$swal('Authorization IG', 'IG App successfully authorized', 'success');
                    localStorage.setItem('oauth.params', JSON.stringify(uriData));
                    localStorage.setItem('oauth.refresh', true);
                    setTimeout(() => {
                        window.close();
                    }, 4000)
                }
            }
        },
        onAccountsLoaded(data) {
            const that = this;
            setTimeout(() => {
                that.manageActionFromList(that)
            }, 500);
        },
        onSelectAccount(accountSelected) {

            this.showChart = false;

            if (this.isAdmin) {
                if (accountSelected) {

                    this.admin.account = accountSelected
                    this.accountId = accountSelected.code;

                } else {

                    delete this.admin.account
                    this.accountId = null;

                }
            }

            // this.showChart = false;
            // this.loadStats = false;
            this.forceRenderTable();
        },
        forceRenderTable() {
            this.renderDT = false;
            this.showChart = false

            this.$nextTick(() => {
                this.renderDT = true;
            });
        },
        initDates() {

            var date = helper.dateToYMD(new Date());
            var minDate = helper.dateToYMD(helper.AddOrSubractDays(new Date(), 6, false));

            this.dates.to = date;
            this.dates.from = minDate;
            this.dates.max = date;
        },
        onSubmit() {
            this.submitload = true;
            this.loadStats = false;
            let date = {
                from: this.dates.from,
                to: this.dates.to
            }
        },
        filterColums(cols, selected, defaults) {
            this.dtColumns.options = cols;
            this.dtColumns.selected = selected;
            this.dtColumns.defaults = defaults;
        },
        finishedFilter() {
            this.$refs['dt-filters'].finishFilter();
        },
        selectedColumns(cols) {

            this.selectColumns = this.getColumnsKeys(cols, 'code');

            this.onSelect(cols);

            // this.saveDisplayColumnsPreference(this.selectColumns);
            // this.forceRenderTable();

        },
        onSelect(cols) {

            // cols = _.map(cols, 'code');

            // cols = [...cols, ...this.dtColumns.defaults];
            let fields = _.map(this.fields, (field) => {
                if (this.selectColumns.includes(field.key)) {
                    field.class = "text-center";
                } else {
                    field.class = "text-center d-none";
                }
                return field;
            })
            this.fields = fields;

            this.saveDisplayColumnsPreference(this.selectColumns);
        },
        saveDisplayColumnsPreference(cols) {
            const userId = this.user.id;

            this.selectColumns = this.getColumnsKeys(cols, 'code');

            api.users.saveLoAutomationStatsColumns(userId, cols)
                .then((response) => {
                    if (response.success && response.data) {
                        const columns = JSON.parse(response.data.columns)
                    }
                });

        },
        getColumnsKeys(data, attr = 'key') {
            return _.map(data, attr);
        },
        filterTable(params) {
            let filters = {
                platform: params.platform ? _.map(params.platform, 'name') : [],
                platform_account: params.platform_account ?? '',
                daily_cap: params.daily_cap ?? '',
                // status: params.status?.code ?? '',
                date_to: params.date_to ?? '',
                date_from: params.date_from ?? ''
            };

            return filters;
        },
        handleBtn(data) {
            this.showChart = false;

            if (!data.event || !data.row) {
                return
            }

            let action = data.event.title.toLowerCase();
            this.loAutomationStat = data.row;


            if (action == 'chart' && (typeof data.row.id !== "undefined")) {
                this.getLoAutomationStats(data.row, data.ref)
            }

            if (action == 'unlink' && (typeof data.row.id !== "undefined")) {
                this.unlinkAutomation(data.row)
            }

            helper.delay(2000).then(() => {
                if (data.ref && typeof data.ref.setState == 'function') {
                    data.ref.setState(false);
                }
            });

        },
        getLoAutomationStats(loAutomationStat, ref = null) {

            if (!loAutomationStat.id || !loAutomationStat.integration_name) {
                return
            }
            this.showChart = false;
            this.loAutomationStat = loAutomationStat;

            this.chartLoading = true;

            helper.delay(1000).then(() => {
                $([document.documentElement, document.body]).animate({
                    scrollTop: $(".bloc-charts").offset().top
                }, 1500)
                this.graph(loAutomationStat);
                if (ref && typeof ref.setState == 'function') {
                    ref.setState(false);
                }
            });

        },
        unlinkAutomation(item) {

            if(!item.id) { return; }

            api.loAutomations.unlink(item.integration_id)
                .then((response) => {
                    if(response.success) {
                        this.$swal('', `<p>You’ve unlinked your InboxGeek integration from your <span class="font-weight-bold">${item.platform}</span> automation.<br><span class="small font-weight-bold">You will no longer see your statistical reporting in InboxGeek for this automation</span><p>`, 'success')
                            .then((confirm) => {
                                this.$refs['lo-automations-table'].resetTable()
                            })
                    }
                })
                .catch((error) => {

                    if (error.response.status == 500) {
                        this.$bvToast.toast('something went wrong!', {
                            title: `Error Unlink Integration ${item.integration_name}`,
                            variant: 'danger'
                        });
                        return;
                    }

                    this.$bvToast.toast(error.response.data.errors.message, {
                        title: `Error Unlink Integration ${item.integration_name}`,
                        variant: 'danger'
                    })

                })
        },
        sortChanged(ctx) {
            this.showChart = false
            this.chartLoading = false
        },
        initIntervalDates() {
            let date = moment();
            let minDate = date.clone();

            const period = this.options.period;
            let currentPeriod = _.find(this.tabsPeriod, function (o) { return period == o.code; });

            minDate.subtract(currentPeriod.offset, currentPeriod.name.toLowerCase())

            this.options.dates.to = date.format('YYYY-MM-DD');
            this.options.dates.from = minDate.format('YYYY-MM-DD');
            this.options.dates.max = date.format('YYYY-MM-DD');
        },
        graph(item) {

            this.chartLoading = true

            let options = {
                "lo_automation": item.id,
                "from": this.options.dates.from,
                "to": this.options.dates.to,
            }

            api.stats.sourceLoAutomationStatsCharts(options)
                .then(response => {

                    if (response.success) {
                        this.statsData = this.getChartDataFormatted(response);
                        this.loAutomationStatsChart.bodyData.series = this.statsData.series;
                        this.loAutomationStatsChart.bodyData.labels = this.statsData.labels;

                        this.chartData = response.data.data

                        this.showChart = false;
                        this.$nextTick(() => {
                            this.showChart = true;
                        });
                    }

                })
                .catch(err => {
                    this.$bvToast.toast(err.response.data.message, {
                        title: 'Error',
                        variant: 'danger',
                    })
                })
                .finally(() => {
                    helper.delay(2000).then(() => {
                        this.chartLoading = false;
                    });

                })

        },
        setYAxis() {
            const max = this.getMaxSerie(this.loAutomationStatsChart.bodyData.series);

            let addMarge = max ? (max + (max * 10) / 100) : 10;

            this.yaxis = max + Math.ceil(addMarge); // set Chart bodyData.yaxis.max dynamically

            this.loAutomationStatsChart.bodyData.yaxis = {
                min: 0,
                max: addMarge,
                tickAmount: 5,
            }
        },
        arrayMax(arr) {
            let len = arr.length,
                max = -Infinity;
            while (len--) {
                if (Number(arr[len]) > max) {
                    max = Number(arr[len]);
                }
            }
            return max;
        },
        getMaxSerie(series = []) {
            if (!series) return 0;
            let elts = [];
            series.forEach(serie => {
                elts.push(this.arrayMax(serie.data))
            });
            return this.arrayMax(elts);
        },
        getChartDataFormatted(response, format = 'YYYY-MM-DD', comparator = 'day') {
            // this.loAutomationStatsChart.columnsHide = [];

            let chartData = {
                indicators: [],
                series: [],
                labels: [],
            }

            if (typeof response.data === "undefined") {
                return chartData
            }

            const that = this
            const data = response.data;

            let startDate = moment(data.period.from, format);
            let endDate = moment(data.period.to, format);

            chartData.labels = this.getLabelsFromRangeDates(startDate, endDate, format, `${comparator}s`);

            data.items.forEach(item => {
                let graphItems = _.filter(data.data, (o) => {

                    let label = null;
                    if (typeof this.options.label !== "undefined") {
                        label = (o.hasOwnProperty(this.options.label))
                            ? this.options.label : null
                    }

                    return item.id == o[label];
                });

                let indicatorPlan = {
                    title: item.name,
                    indicator: `${this.getSumItemsByAttr(graphItems)}`,
                    disabled: item.disabled ?? false,
                }
                if ((typeof item.disabled !== 'undefined' && item.disabled)) {
                    let alreadyHide = _.find(this.loAutomationStatsChart.columnsHide, (o) => { return indicatorPlan.title == o; });

                    if (!alreadyHide) {
                        this.loAutomationStatsChart.columnsHide.push(indicatorPlan.title)
                    }
                }
                chartData.indicators.push(indicatorPlan)

                let seriePlan = {
                    name: item.name,
                    type: `area`,
                    data: this.getDataChartByItemsAndLabels(chartData.labels, graphItems, comparator)
                }
                chartData.series.push(seriePlan)
            });

            // Update X tooltip
            this.loAutomationStatsChart.bodyData.tooltip.x = {
                formatter: function (x, series, labels = chartData.labels) {
                    return labels[series.dataPointIndex]
                }
            }

            if (comparator == 'week') {
                chartData.labels = this.getLabelsFromRangeDates(startDate, endDate, '[Week ]WW-YYYY', `${comparator}s`);
            }
            return chartData
        },
        getLabelsFromRangeDates(startDate, endDate, format = 'YYYY-MM-DD', interval = 'days') {

            let now = startDate.clone(), dates = [];

            while (now.isSameOrBefore(endDate)) {
                dates.push(now.format(format));
                now.add(1, interval);
            }

            return dates;
        },
        getDataChartByItemsAndLabels(labels, items = [], comparator = 'day') {
            let data = [];
            labels.forEach(label => {
                let graphItems = _.filter(items, function (o) { return moment(o.date).isSame(label, comparator); });
                data.push(this.getSumItemsByAttr(graphItems))
            });
            return data;
        },
        getSumItemsByAttr(items, attr = 'total') {
            const sum = items.reduce((accumulator, object) => {
                if (!Object.hasOwn(object, attr) || object.event_id == 'all_time') {
                    return accumulator + 0;
                }
                return accumulator + parseFloat(object[attr]);
            }, 0);

            const decimal = sum && Number.isInteger(sum) ? 0 : 2;
            return sum ? sum.toFixed(decimal) : 0;
        },
        resetChartData() {
            this.loAutomationStatsChart.bodyData.series = [];
            this.loAutomationStatsChart.bodyData.labels = [];
        },
        clearHideColumns(chartContext, index) {
            let currIndicator = this.statsData.indicators[index];

            const that = this;
            if (_.includes(this.loAutomationStatsChart.columnsHide, currIndicator.title)) {
                this.loAutomationStatsChart.columnsHide = _.remove(this.loAutomationStatsChart.columnsHide, function (popItem) {
                    return popItem !== currIndicator.title;
                });
                currIndicator.disabled = false;
            } else {
                let alreadyHide = _.find(that.loAutomationStatsChart.columnsHide, function (o) { return currIndicator.title == o; });

                if (!alreadyHide) {
                    this.loAutomationStatsChart.columnsHide.push(currIndicator.title)
                }
                currIndicator.disabled = true;
            }
        },
        isHide(name) {
            return _.includes(this.loAutomationStatsChart.columnsHide, name)
        },
        formatDecimal(nStr) {
            nStr += '';
            var x = nStr.split('.');
            var x1 = x[0];
            var x2 = x.length > 1 ? '.' + x[1] : '';
            var rgx = /(\d+)(\d{3})/;
            while (rgx.test(x1)) {
                x1 = x1.replace(rgx, '$1' + ',' + '$2');
            }
            return x1 + x2;
        },
        loadDisplayColumns() {

            let cols = [];
            let selected = [];
            let defaults = [];

            let defaultColumns = _.map(this.fields, 'key');
            defaults = defaultColumns;

            cols = defaultColumns.map((key) => {
                let name = helper.customCapitalize(this.replaceAllOccurrences(key, '_', ' '))
                return {code: key, name: name};
            });

            api.users.loAutomationStatsColumns(this.user.id)
                .then((response) => {

                    if (response.success && response.data && JSON.parse(response.data.columns).length) {

                        let selectColumns = JSON.parse(response.data.columns)

                        if(selectColumns.length) {
                            selected = selectColumns.map((key) => {
                                return key;
                            });
                        }

                        this.selectColumns = selected;
                    } else {
                        defaults = this.dtColumns.defaults;
                        selected = defaults;
                    }
                })
                .finally(() => {
                    this.filterColums(cols, selected, defaults)
                })

        },
        replaceAllOccurrences(string, searchString, replaceString) {
            return string.split(searchString).join(replaceString);
        },
        // ***************
        onFilter(data) {

            this.filters = this.filterTable(data);

            if (typeof this.$refs['lo-automations-table'].resetTable === 'function') {
                this.$refs['lo-automations-table'].resetTable()
            }

            this.forceRenderTable();

            helper.delay(2000).then(() => {
                if (typeof this.$refs['dt-filters'].finishFilter === 'function') {
                    this.$refs['dt-filters'].finishFilter();
                }
            })
        },
        getDtValueIfDefined(value) {
            if(!value) {
                return '--';
            }

            const decimal = value && !(value % 1) ? 0 : 3;
            return value ? parseFloat(value).toFixed(decimal) : 0;
        }
    },
    computed: {
        ...mapGetters({
            isClient: 'Auth/isClient',
            user: 'Auth/user',
            UserSubscriptions: 'Auth/UserSubscriptions',
            platforms_co: 'platformConfigs'
        }),
        columns() {
            let columns = this.fields;

            // if(this.selectColumns.length) {
            //     columns = columns.filter((column) => { return this.selectColumns.includes(column.key) })
            // }

            return columns;

        }
    },
    watch: {
        'periodCode': {
            handler(newValue, oldValue) {
                this.options.period = newValue;

                this.resetChartData();
                this.initIntervalDates();
                if (this.liveLeadSource) {
                    this.graph(this.liveLeadSource)
                }
            },
            deep: true
        },
        'statsData': {
            handler(newValue, oldValue) {
                this.setYAxis();
            }
        },
    },
};
</script>
<style>
#myTabContent-5 {
    padding-bottom: 2em !important;
    /* margin: 0 5px; */
}

.actionClass {
    width: 18%;
}

.timeClass,
.createdatClass,
.accountClass {
    width: 10%;
}

textarea.form-control {
    line-height: 25px;
}

.cursor-default {
    cursor: default;
}

.table th,
.table td {
    padding: 0.55rem;
    vertical-align: top;
}

#edit-modal___BV_modal_body_ {
    background-color: #f3f7fd;
}

#label-btn {
    visibility: hidden;
}</style>
