<template>
	<div>

		<iq-card>
			<template v-slot:headerTitle>
				<h4 class="card-title"><i class="ri-notification-line"></i> Notifications Events </h4>
			</template>
			<!-- <template v-slot:headerAction>
				<b-row class="text-right" style="justify-content: end;padding: 15px;">
					<b-button type="submit" variant="primary" @click="handleAdd($event)">
						Add Notification Type
					</b-button>
				</b-row>
			</template> -->
			<template v-slot:body>

				<DataTable v-if="renderDT" ref="notification_events-table" :url="'/notification_events'"
					v-bind="{ columns: dtColumns, customCells: dtCells, sort: 'name' }" />

			</template>
		</iq-card>


		<!-- Start Add Setting Modal-->
		<b-modal :id="notifTypeModal.id" :ref="notifTypeModal.id" :title="notifTypeModal.title" size="md" centered
			hide-footer class="bg-secondary py-0">
			<!-- Inner Modal Add Notification Type -->
			<FormNotifType col=12 :modal="notifTypeModal" :item="notifTypeModal.item"
				@modalHide="closeModal(notifTypeModal)" @handleSubmit="handleSubmit" />
		</b-modal>
		<!-- END Add Setting Modal-->

	</div>
</template>

<script>
import Multiselect from "vue-multiselect";
import Spinner1 from '@/components/loaders/spinner1';
import api from '@/api/RestClient';
import DataTable from '@/components/inboxgeek/tables/DataTable.vue';
import DtNotifRoles from '@/views/Apps/children/DtNotifRoles.vue';
import DtNotifActions from '@/views/Apps/children/DtNotifActions.vue';
import FormNotifType from '@/views/Apps/children/FormNotifType';
import { helper } from '@/helpers'
import IBGSwitchCell from '@/components/inboxgeek/tables/IBGSwitchCell.vue'

export default {

	name: "DtNotificationTypeEvents",
	components: {
		Spinner1,
		Multiselect,
		DataTable,
		DtNotifRoles,
		DtNotifActions,
		FormNotifType
	},
	mounted() {
	},
	data() {
		return {
			loading: true,
			isFilter: false,
			renderDT: true,
			filters: {},
			dtColumns: [
				{ label: 'Name', key: 'name', class: 'text-left', sortable: true, sortDirection: 'desc' },
				{ label: 'Content', key: 'content', class: 'text-left', sortable: true, sortDirection: 'desc' },
				{
                    label: 'Created at', key: 'created_at', class: 'text-center', sortable: true, sortDirection: 'desc',
                    formatter: (value, key, item) => {
                        let dt = new Date(value);
                        return helper.formatDate(dt)
                    }
                },
				{ label: 'State', key: 'state', class: 'text-center', sortable: true, sortDirection: 'desc' },
			],
			dtCells: [],
			notifTypeModal: {
				id: 'modal-add-notifEvent',
				title: 'Add Notification Event',
				item: {}
			},
		}
	}
	,
	methods: {
		handleAdd(event) {
			this.notifTypeModal.item = null;
			this.notifTypeModal.title = `Add Notification Event`;
			this.$root.$emit('bv::show::modal', this.notifTypeModal.id)
		},
		closeModal(modal) {
			this.$root.$emit('bv::refresh::table', modal.id)
		},
		handleSubmit(data) {

			const that = this

			let swal = {
				icon: 'success',
				title: 'Notification Event Added',
				text: 'Notification Event Added Successfully',
				type: 'success',
				confirmButtonText: 'OK',
				showCloseButton: true,
			}

			if (!data || !data.id) {
				swal.text = 'Something went wrong'
				swal.type = 'danger'
				swal.icon = 'warning'

				this.$swal(swal)
				return
			}

			if (data.id && !data.new) {
				swal.title = 'Notification Event Updated'
				swal.text = 'Notification Event updated successfully'
			}

			this.$swal(swal).then((result) => {
				that.$root.$emit('bv::hide::modal', this.notifTypeModal.id)
				this.$root.$emit('bv::refresh::table', this.$refs['notification_events-table'].id)
			})
		},
		handleBtn(data) {

			if (!data.event || !data.row) {
				return
			}

			let action = data.event.title.toLowerCase();

			this.ge_source = data.row;


			if (action == 'edit') {

				this.handleEdit(data.row)

			}

			helper.delay(2000).then(() => {
				if (data.ref) {
					data.ref.setState(false);
				}
			});
		},
		handleEdit(item) {
			if (!item || !item.id) {
				return
			}
			this.notifTypeModal.title = `Edit Notification Event - ${item?.name}`
			this.notifTypeModal.item = item
			this.$root.$emit('bv::show::modal', this.notifTypeModal.id)
		},
		handleSwitchAttr(data) {

			if (!data.input || !data.row || !data.key) {
				return
			}

			let itemCode = data.row.id
			let form = {}
			form[data.key] = data.input.checked ? true : false

			let toast = { message: 'Something went wrong!', title: 'Error', variant: 'danger' }

			return api.notificationTypes.put(itemCode, form)
				.then(result => {
					toast = { message: result?.message, title: 'Success', variant: 'success' }
				})
				.catch((error) => {
					data.input.checked = !data.input.checked;
					let errors = [];
					toast.message = '';

				})
				.finally(() => {
					this.$root.$bvToast.toast(toast.message, toast);
				});
		},
		removeItem(item) {

			if (!item.id) {
				return
			}

			this.$swal({
				title: 'Are you sure?',
				html: `<p>Once you delete this Notification Event</p>`,
				type: 'danger',
				showCancelButton: true,
				confirmButtonText: 'Yes Delete it!',
				cancelButtonText: 'No, Keep it!',
				showCloseButton: true,
				showLoaderOnConfirm: true
			}).then((result) => {
				if (result.value) {
					let swalObj = {
						type: 'success',
						confirmButtonText: 'OK',
						showCloseButton: true,
					}

					api.notificationTypes.delete(item.id)
						.then(result => {
							swalObj.title = 'Success!';
							swalObj.text = result.message;
						})
						.catch(function (error) {
							swalObj.type = 'warning';
							swalObj.title = 'Warning!';
							swalObj.text = 'Something went wrong!';
						})
						.finally(() => {
							this.$swal(swalObj)
							this.$root.$emit('bv::refresh::table', this.$refs['notification_events-table'].id)
						});
				}
			})
		},
	}
}

</script>