<template>
  <div>


    <iq-card>
        <template v-slot:headerTitle >
          <div style="display: flex; justify-content: space-between;">
            <h4 class="card-title"><i class="ri-guide-fill"></i> Application Tour Steps </h4>
          </div>
          </template>
          <template v-slot:headerAction>
            <b-form-checkbox v-model="guide_status" @change="$emit('updateAppTourGuidStatus',guide_status)" class="mx-2" name="check-button" switch> Active </b-form-checkbox>

            <b-button  v-b-modal.modal-add-app-tour-steps variant="primary"  class="text-right mx-2 pull-right" @click="initializeForm();" v-b-tooltip.hover title="Add Setting">Add Step</b-button>

          </template>
          <template v-slot:body>

            <div v-if="loading" class="text-center p-4 d-flex justify-content-center align-items-center" >
              <b-spinner variant="primary"> </b-spinner>
              <span class="text-primary p-2"><small> Fetching Steps, please wait...</small></span>
            </div>

            <b-row v-else v-for=" (step,index) in steps" :key="'_'+step.content" class="p-2 py-4" style="border-top: 1px solid #eee;">
              <b-col cols="1" class="d-flex align-items-start flex-column">
                <button class="btn btn-sm mt-auto mx-1 iq-bg-light" v-if="index!=0" @click="up(index)"><i class="ri-arrow-up-s-line"></i></button>
                <button class="btn btn-sm mt-auto mx-1 iq-bg-light"  v-if="index!=steps.length-1" @click="down(index)"><i class="ri-arrow-down-s-line"></i></i></button>
               
              </b-col>
              <b-col cols="8">
              
                <h3> <i  :class="step.header.icon + ' text-primary'"></i> {{ step.header.title }}</h3> 

                <div v-html="step.content"></div>
              </b-col>

              <b-col class="my-auto text-center" cols="1">
                <span class="badge iq-bg-dark">
                {{ step.params.placement }}
                </span>
              </b-col>
              <b-col class="text-right my-auto">
           
                <button class="btn btn-sm mx-1 iq-bg-primary" v-b-modal.modal-edit-app-tour-step @click="editStep(index)"><i class="ri-pencil-line"></i> Edit</button>
                <button class="btn btn-sm mx-1 iq-bg-danger"  @click="deleteStep(index)"><i class="ri-delete-bin-line"></i> Remove</button>
              </b-col>
            </b-row>
          </template>
    </iq-card>
   


   <b-modal id="modal-add-app-tour-steps"  ref="modal-add-app-tour-steps" title="Add App Tour Step" size="md" centered hide-footer
      class="bg-secondary p-4">

      <b-form @submit="handleSubmit" class="px-2">
            <div class="row py-2">
              <div class="col">
                <label for="">Title</label>
                <b-form-input v-model="form.title" type="text"></b-form-input>
              </div>
            </div>

            <div class="row py-2">
              <div class="col">
                <label for="">Icon</label>
                <b-form-input id="icon" v-model="form.icon" placeholder="icon class" required></b-form-input>
              </div>
            </div>

            <div class="row py-2">
              <div class="col">
                <label for="">Description</label>
                <textarea class="form-control" v-model="form.description" cols="4" rows="4"></textarea>
              </div>
            </div>
            
            <div class="row py-2">
              <div class="col">
                <label for="">Source</label>
                <input class="form-control"  v-model="form.source" id="" />
              </div>

              <div class="col">
                <label for="">Direction</label>
                <select class="form-control"  v-model="form.direction" id="">
                  <option value="up">up</option>
                  <option value="down">down</option>
                  <option value="right">right</option>
                  <option value="left" selected>left</option>
                </select>
              </div>


            </div>
            
            <div class="row">
              <div class="col text-right p-3">

                <b-button variant="primary" type="submit" :disabled="onsubmit">
                      <b-spinner small type="grow" v-show="onsubmit"></b-spinner>
                      Add Step
                  </b-button>

            
              </div>
            </div>
      </b-form>
    </b-modal>

    <b-modal id="modal-edit-app-tour-step"  ref="modal-edit-app-tour-step" title="Update App Tour Step" size="md" centered hide-footer
      class="bg-secondary p-4">

      <b-form @submit="update" class="px-2">
            <div class="row py-2">
              <div class="col">
                <label for="">Title</label>
                <b-form-input v-model="form.title" type="text"></b-form-input>
              </div>
            </div>

            <div class="row py-2">
              <div class="col">
                <label for="">Icon</label>
                <b-form-input id="icon" v-model="form.icon" placeholder="icon class" required></b-form-input>
              </div>
            </div>

            <div class="row py-2">
              <div class="col">
                <label for="">Description</label>
                <textarea class="form-control" v-model="form.description" cols="4" rows="4"></textarea>
              </div>
            </div>
            
            <div class="row py-2">
              <div class="col">
                <label for="">Source</label>
                <input class="form-control"  v-model="form.source" id="" />
              </div>

              <div class="col">
                <label for="">Direction</label>
                <select class="form-control"  v-model="form.direction" id="">
                  <option value="up">up</option>
                  <option value="down">down</option>
                  <option value="right">right</option>
                  <option value="left">left</option>
                </select>
              </div>


            </div>
            
            <div class="row">
              <div class="col text-right p-3">

                <b-button variant="info" type="submit" :disabled="onsubmit">
                      <b-spinner small type="grow" v-show="onsubmit"></b-spinner>
                      update Step
                  </b-button>

            
              </div>
            </div>
      </b-form>
    </b-modal>

  </div>
</template>

<script>
import Vue from 'vue'
import Spinner1 from '@/components/loaders/spinner1'
import api from '@/api/RestClient'

export default {

  name: "AppTourGuideSettings",
  props:['appTourGuideStatus'],
  components: {
    Spinner1,
  },
  mounted() {
    this.guide_status = this.appTourGuideStatus;
    this.fetchTourAppStatus();
    this.fetchTourAppSteps();


  },
  data() {
    return {
      loading: true,
      guide_status:false,
      steps:[],
      form : {
        title:null,
        icon:null,
        description:null,
        source:null,
        direction:"left"
      },
      current_step_index : null,
      onsubmit:false,
      loading : false
    }
    }
    ,
    methods : {
      fetchTourAppStatus(){
        api.settings.getAppTourStatus() .then(response => { 

          this.guide_status = ( response.data == 1 ) ? true : false; 
       });

      },
      fetchTourAppSteps(){
        this.loading = true;
        api.userMetadata.appTour({ page: 1, per_page: 50 }) .then(response => { if ('success' in response) { this.steps = response.data; } }).finally(()=>{
          this.loading = false;
        });

      },
      editStep(step_index) {
      
        this.current_step_index = step_index;
        this.form.title       = this.steps[step_index].header.title;
        this.form.icon        = this.steps[step_index].header.icon;
        this.form.description = this.steps[step_index].content;
        this.form.source      = this.steps[step_index].target;
        this.form.direction   = this.steps[step_index].params.placement;
      },
      update(e){
        e.preventDefault()

        this.onsubmit = true;

         this.steps[this.current_step_index].header.title  = this.form.title;
         this.steps[this.current_step_index].header.icon  = this.form.icon;
         this.steps[this.current_step_index].content  = this.form.description;
         this.steps[this.current_step_index].target  = this.form.source;
         this.steps[this.current_step_index].params.placement  = this.form.direction;
      
        api.userMetadata.updateStepTourGuid({"steps" : this.steps })  
        
        .then( response => this.$refs['modal-edit-app-tour-step'].hide() )  
        
        .finally(() => {

            this.onsubmit = false;
            this.fetchTourAppSteps();
            this.initializeForm();
        });

      },

      deleteStep(step_index){

        this.$swal({
                title: 'Are you sure?',
                text: 'You can\'t revert your action',
                type: 'warning',
                showCancelButton: true,
                confirmButtonClass:'btn-primary',
                confirmButtonText: 'Yes Delete it!',
                cancelButtonText: 'No, Keep it!',
                showCloseButton: true,
                showLoaderOnConfirm: true
            }).then((result) => {

                if (result.value) {

                  this.steps.splice(step_index, 1);
                  api.userMetadata.updateStepTourGuid({"steps" : this.steps })  .then(()=> this.fetchTourAppSteps() )  .finally(() => { this.onsubmit = false;});
                }
            })

       
        
      },

      up(step_index) {
       
        const fromIndex = step_index;
        const toIndex = step_index - 1;

        const element = this.steps.splice(fromIndex, 1)[0];
        this.steps.splice(toIndex, 0, element);

        api.userMetadata.updateStepTourGuid({"steps" : this.steps })  
        .then(() => {

          this.$bvToast.toast('Setting updated successfully', {
                    title: 'Success',
                    variant: 'success'
                });
   
        });

      },
      down(step_index){
 
        const fromIndex = step_index;
        const toIndex = step_index+1;

        const element = this.steps.splice(fromIndex, 1)[0];
        this.steps.splice(toIndex, 0, element);

        api.userMetadata.updateStepTourGuid({"steps" : this.steps })  
        .then(() => {

          this.$bvToast.toast('Setting updated successfully', {
                    title: 'Success',
                    variant: 'success'
                });
            
        });

      },
      initializeForm(){
        this.form ={
            title:null,
            icon:null,
            description:null,
            source:null,
            direction:'left'
          };
      },

      handleSubmit(e)
      {

        e.preventDefault()

        this.onsubmit = true;

        api.userMetadata.addStepTourGuid(this.form)

        .then(data => {
           
          this.fetchTourAppSteps();

          this.$refs['modal-add-app-tour-steps'].hide();
        }).catch(data=>{
          console.log('something went wrong');
        })
        .finally(() => {
          this.initializeForm();
            this.onsubmit = false;
        })

      }

    }
}

</script>