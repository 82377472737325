<template>
    <b-container fluid>
        <div class="card pt-4 mb-4 pl-4 pr-4">
            <div v-show="!isLoad" class="header text-center mb-4">
                <h2>Choose Your Plan</h2>
                <h3>{{ service }}</h3>
                <p v-show="false">If you need more info about our pricing, please check <a href="#">Pricing Guidelines</a>
                </p>
            </div>
            <b-row align-v="center" class="justify-content-md-center change-plan">

                <loader :isLoad="isLoad"></loader>

                <b-col class=" mt-2" md="3" v-if="!subscriptionUsed && trialPlan">
                    <iq-card body-class="border_plan text-center iq-bg-info" :class="'bg-primary'">

                        <template v-if="trialPlan" v-slot:body>
                            <span class="font-size-16 text-uppercase" v-if="trialPlan && trialPlan.name !== ''">{{ trialPlan.name
                            }}</span>
                            <h2 class="mb-4 display-3 font-weight-bolder" :class="trialPlan && trialPlan.id == id ? 'text-white' : ''">
                                {{ trialPlan.balance.toLocaleString() }}
                                <small class="font-size-14 d-block mb-4"
                                    :class="trialPlan.id == id ? 'text-white' : 'text-muted'">{{ eventNameService
                                    }}s</small>
                                <span class="font-size-12 d-block mb-1">${{ trialPlan.unit_price
                                }}/{{ eventNameService }}</span>
                                <span class="font-size-12 d-block">Valid for one month</span>
                            </h2>
                            <b-button @click="startTrialVersion($route.params.service)" class="btn btn-primary mt-5"
                                alt="Subscribe now">
                                <span class="spinner-grow spinner-grow-sm" v-show="spinner && plan == trialPlan.id"
                                    role="status" aria-hidden="true"></span>
                                <span class="sr-only">Loading...</span>
                                Try for Free
                            </b-button>
                        </template>
                    </iq-card>
                </b-col>
                <b-col class="price-tag mt-2" md="3" v-show="!isLoad" v-for="(item, index) in plans" :key="index"
                    v-if="!item.is_trial">
                    <iq-card body-class="border_plan text-center" :class="item.id == id ? 'bg-primary text-white' : ''">

                        <template v-slot:body>
                            <span class="font-size-16 text-uppercase" v-if="item.plan !== ''">{{ item.name }}</span>
                            <h2 class="mb-4 display-3 font-weight-bolder" :class="item.id == id ? 'text-white' : ''">
                                {{ item.balance.toLocaleString() }}
                                <small class="font-size-14 d-block mb-4"
                                    :class="item.id == id ? 'text-white' : 'text-muted'">{{ eventNameService }}s</small>
                                <span class="font-size-12 d-block mb-1">${{ item.unit_price }}/{{ eventNameService }}</span>
                                <span class="font-size-12 d-block">${{ item.price }}/month </span>
                            </h2>
                            <span v-if="false">Billed {{ billedPeriod }}</span>
                            <h3 v-if="false" class="font-size-13" :class="item.id == id ? 'text-white' : 'text-muted'">
                                <span>{{ item.balance.toLocaleString() }}</span> <span class="font-size-12">{{
                                    eventNameService }}s</span>
                            </h3>
                            <b-button v-if="item.id != id" :variant="item.active ? 'light' : 'primary'" class="mt-5 "
                                :class="item.buttonClass" @click='choosePlan(item)'>
                                <span class="spinner-grow spinner-grow-sm" v-show="spinner && plan == item.id" role="status"
                                    aria-hidden="true"></span>
                                <span class="sr-only">Loading...</span>
                                <span>Choose</span>
                            </b-button>
                            <div v-else class="mt-5">
                                <p>&nbsp;</p>
                            </div>
                        </template>
                    </iq-card>
                </b-col>

            </b-row>

            <b-row v-show="!isLoad">
                <b-col lg="6" offset-lg="3" class="mb-5">
                    <h4>All subscriptions include:</h4>
                    <ul v-if="serviceId == 1" class="list-unstyled line-height-4 style mb-0">
                        <li><i class="fa fa-check"></i> Free Integration Onboarding</li>
                        <li><i class="fa fa-check"></i> Premier Management Support</li>
                        <li><i class="fa fa-check"></i> Full access to Email Expertise & Auditing</li>
                        <li><i class="fa fa-check"></i> Exclusive Access to Upcoming Beta Features</li>
                    </ul>
                    <ul v-else-if="serviceId == 2" class="list-unstyled line-height-4 style mb-0">
                        <li><i class="fa fa-check"></i> Automated Lead Validation, Cleaning & Delivery</li>
                        <li><i class="fa fa-check"></i> Customizable Lead Suppression Solutions</li>
                        <li><i class="fa fa-check"></i> Premier Management Support & Onboarding</li>
                        <li><i class="fa fa-check"></i> Full Access to Email Expertise & Auditing</li>
                        <li><i class="fa fa-check"></i> Exclusive Access to Upcoming Beta Features</li>
                    </ul>

                </b-col>
            </b-row>
        </div>

        <div class="row justify-content-center">
            <div class="col-sm-8 text-center">
                <p>Need more than 50,000 {{ eventNameService }}s a month? Schedule a call for details <a
                        href="https://calendly.com/inboxgeek/enterprise">InboxGeek Enterprise Call - Team InboxGeek</a>
                    or email <a href="mailto:support@inboxgeek.com">support@inboxgeek.com</a></p>
            </div>
        </div>
        <div class="modal plan-changed" v-if='modal' tabindex="-1" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-body">
                        <div class="success" v-if='success'>
                            <i class="fas fa-check-circle fa-8x success"></i>
                            <h4>GREAT</h4>
                            <h5>Plan successfully changed</h5>
                        </div>
                        <div class="loading" v-if='loading'>
                            <div class="d-flex justify-content-center">
                                <div class="spinner-border" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer" v-if='success' style="display: flex;">
                        <button type="button" @click='close' class="btn btn-secondary" data-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
    </b-container>
</template>

<style scoped>
.change-plan {
    min-height: 40vh;
}

.plan-changed h4,
.plan-changed h5 {
    text-align: center;
    margin-top: 5px;
}
</style>

<script>
import {
    sofbox
} from '../../config/pluginInit'
import api from '@/api/RestClient'
import Loader from '@/components/inboxgeek/Loader'
import _ from 'lodash'
import {
    mapGetters
} from "vuex";
import { SERVICES, SERVICES_BY_ID } from "@/constantes";
import moment from 'moment'
import { helper } from '@/helpers'

const ON_TWELVE_MONTH = 12;
const ON_ONE_MONTH = 0;
export default {
    data() {
        return {
            id: null,
            plans: [],
            currentPlan: null,
            paymentMethod: '',
            cardElement: null,
            // plan:[],
            plan: 0,
            modal: false,
            loading: false,
            spinner: false,
            success: false,
            paymentMethods: [],
            planPeriod: ON_ONE_MONTH,
            planPeriodText: 'ON_ONE_MONTH',
            isLoad: true,
            discounts: [{
                text: 'Yearly save 15%',
                class: '',
                discount: 15,
                discountText: 'TWELVE_MONTHS'
            },
            {
                text: 'Monthly',
                class: 'active',
                discount: 0,
                discountText: 'ON_MONTHS'
            }
            ],
            billedPeriod: 'monthly',
            service: SERVICES_BY_ID[this.$route.params.service].name,
            serviceId: this.$route.params.service,
            trialPlan: null,
            agrementLink: "https://inboxgeek.com/services-agreement/",
            subscriptionUsed: true
        }
    },
    name: 'BillingPLans',

    components: {
        Loader,
    },
    computed: {
        ...mapGetters('Auth', ['user', 'isAdmin', 'isClient', 'UserSubscriptions', 'lastSubscriptions', 'livePlans']),
        eventNameService() {
            let name = null;
            let serviceID = this.$route.params.service;

            if (serviceID == SERVICES.LIVE_OPENS.id) { name = 'event'; }
            else if (serviceID == SERVICES.LIVE_LEADS.id) { name = 'record'; }
            return helper.capitalizeFirstLetter(name);
        },
        showTrialCard() {
            let serviceId = this.$route.params.service;
            let serviceNotUsed = !this.subscriptionUsed || (this.UserSubscriptions[serviceId] == 'undefined' || !this.UserSubscriptions[serviceId]);
            console.log( 'Test', this.subscriptionUsed, this.UserSubscriptions[serviceId], serviceNotUsed, !serviceNotUsed )

            return this.isClient && !this.isLoad && this.trialPlan && serviceNotUsed;
        }
    },
    beforeCreate() {
        sofbox.openLoader();
    },
    mounted() {
        sofbox.index()
        // this.getService();
        this.getPlans({ service: this.$route.params.service });
        // this.getCurrentPlan();
    },
    methods: {
        getService() {
            api.services.get(this.$route.params.service).then((response) => {
                if (response.data) {

                    this.service = response.data.name;
                }

            })
        },
        getPlans(param = {}) {
            this.isLoad = true;
            api.plans.getActive(param).then(resp => {
                if (resp.success) {
                    this.plans = resp.data;
                    this.trialPlan = _.find(resp.data, (o) => { return o.is_trial == 1 });
                }
            })
                .catch(error => {
                    if (error.response) {
                        this.$bvToast.toast('something went wrong!', {
                            title: 'Error',
                            variant: 'danger'
                        });
                    }
                })
                .finally(() => {
                    this.isLoad = false;
                    this.fetchSubscriptionAlreadyUsed(this.$route.params.service)

                });
        },
        getPlan(id = null) {
            api.plans.get(id).then(resp => {
                this.plan = resp.data;
            })
                .catch(error => {
                    console.log(error);
                })
                .finally(() => { });
        },
        getCurrentPlan(serviceId = null) {
            this.spinner = true;
            if (!serviceId) {
                serviceId = this.$route.params.service;
            }
            api.plans.planByAccount(this.user.account_id, serviceId)
                .then(resp => {

                    if (resp.success && resp.data) {
                        // TODO - Find last Service Subscription and dispatch corresponding store function
                        let liveServicesPlans = this.livePlans;
                        liveServicesPlans[serviceId] = resp.data;

                        let lastServicesPlans = this.lastSubscriptions;
                        lastServicesPlans[serviceId] = resp.data;

                        this.currentPlan = resp.data;
                        if (serviceId == SERVICES.LIVE_OPENS.id) {
                            this.$store.dispatch('Auth/updateLiveOpenersSubscribed', resp.data && !resp.data.is_trial);
                        } else if (serviceId == SERVICES.LIVE_LEADS.id) {
                            this.$store.dispatch('Auth/updateLiveLeadsSubscribed', resp.data && !resp.data.is_trial);
                        }

                        this.$store.dispatch('Auth/updateLivePlans', liveServicesPlans);

                    }
                }).catch(error => {
                    console.log(error);
                }).finally(() => { this.spinner = false; });
        },
        choosePlan(item) {
            
            let service = this.$route.params.service ?? -1;
            let subscriptions = this.livePlans;
            let currentPlan = _.find(subscriptions, function (subscription) {
                return subscription && subscription.service_id == service 
            });

            let date = currentPlan?.subscription_end_date ?? null;
            let subscription_end_date = date ? moment(date).format('MM/DD/YYYY') : '--'
            let display = !_.isUndefined(currentPlan) ? 'block' : 'none';
            let html = `<div style='text-align: left; font-size: 17px;'>
                        <p style='display:${display}'><b>Current plan:</b> ${currentPlan?.name} ending ${subscription_end_date}&nbsp;&nbsp;$${currentPlan?.price}</p> 
                        <p><b>New plan:</b> ${item.name}; 
                                starting ${this.getPlanEndDate()}; 
                                $${item.price}
                            </p>
                        <div style='#'>
                            <input type="checkbox" id="services-agreement" name="services-agreement" value="1" disabled checked>
                            By clicking "Yes", you accept
                            <a target="_blank" href="${this.agrementLink}"><small>InboxGeek\'s Services Agreement</small></a>
                        </div>
                    </div>
                    `

            let discountCode = null
            this.$swal({
                title: 'Subscription Confirmation',
                html: html,
                type: 'info',
                showCancelButton: true,
                confirmButtonText: 'Yes, Change my Plan',
                cancelButtonText: 'Cancel',
                showCloseButton: true,
                showLoaderOnConfirm: true,
                preConfirm: (discount_code) => {
                    // discountCode = discount_code
                },
            }).then((result) => {
                if (result.value) {
                    this.changeSubscription(item.id, discountCode)
                } else {
                    this.spinner = false;
                }
            })

        },
        changeSubscription(plan, discount = null) {
            this.loading = true;
            this.modal = true;
            this.success = false;
            this.$data.spinner = true;
            this.plan = plan;
            const accountId = this.$store.getters['Auth/user'].account_id;

            let data = {
                plan_id: plan,
                period: this.planPeriodText,
                discount_value: this.planPeriod,
                discount_code: discount,
                account_id: accountId
            }

            const serviceID = this.$route.params.service;

            if (serviceID == SERVICES.LIVE_LEADS.id) {
                api.subscriptions.changeSubscriptionBeta(accountId, data)
                    .then(resp => {
                        this.id = plan;
                        this.success = true;
                        this.loading = false;
                        this.redirectToBilling();
                    })
                    .catch(error => {
                        let message = error?.response?.data?.message ?? 'something went wrong!';
                        this.modal = false;
                        this.$bvToast.toast(message, {
                            title: 'Error',
                            variant: 'danger'
                        });
                    })
                    .finally(() => {
                        this.spinner = false;
                        this.getServiceCurrentSubscription(serviceID)
                    });
            } else if (serviceID == SERVICES.LIVE_OPENS.id) {
                api.subscriptions.changeSubscription(accountId, data)
                    .then(resp => {

                        if (resp.success) {
                            this.success = true;
                            this.loading = false;
                            this.redirectToBilling();
                        }

                    })
                    .catch(error => {
                        let message = error?.response?.data?.message ?? 'something went wrong!';
                        if (error.response.status == 500) {
                            message = 'something went wrong!'
                        }
                        this.modal = false;
                        this.$bvToast.toast(message, {
                            title: 'Error',
                            variant: 'danger'
                        });
                    })
                    .finally(() => {
                        this.spinner = false;
                        this.getServiceCurrentSubscription(serviceID)
                    });
            }

        },
        getPlanEndDate() {
            let month = 1
            if (this.planPeriod == ON_TWELVE_MONTH) month = 12;
            return this.addMonths(month).toISOString().slice(0, 10);
        },
        addMonths(numOfMonths, date = new Date()) {
            date.setMonth(date.getMonth() + numOfMonths);

            return date;
        },
        close(e) {
            e.stopPropagation();
            this.modal = false;
        },
        onSwitch(item) {
            _.map(this.discounts, function (discount) {
                discount.class = '';
            });
            item.class = 'active';
            this.planPeriod = item.discount
            this.planPeriodText = item.discountText
            let param = {};
            if (item.discount)
                param = {
                    discount: item.discount,
                    period: item.discountText
                }

            this.billedPeriod = 'monthly'
            if (item.discountText == 'TWELVE_MONTHS') this.billedPeriod = 'annually'
            this.getPlans(param);
        },
        startTrialVersion(serviceID) {
            if (this.isClient) {
                let startTrialDate = moment();
                const endTrialDate = moment().add(30, 'days');

                let html = `<div style='text-align: left; font-size: 17px;'>
                        <p><b>New plan:</b> ${this.trialPlan.name}; 
                            starting ${startTrialDate.format('DD-MM-YYYY')} - ending ${endTrialDate.format('DD-MM-YYYY')}; 
                            $${this.trialPlan.price}
                        </p>
                        <div style='#'>
                            <input type="checkbox" id="services-agreement" name="services-agreement" value="1" disabled checked>
                            By clicking "Yes", you accept
                            <a target="_blank" href="${this.agrementLink}"><small>InboxGeek\'s Services Agreement</small></a>
                        </div>
                    </div>`

                return this.$swal({
                    title: 'Trial Plan Subscription Confirmation',
                    html: html,
                    type: 'info',
                    showCancelButton: true,
                    confirmButtonText: 'Yes, Activate Trial Now',
                    cancelButtonText: 'Cancel',
                    showCloseButton: true,
                    showLoaderOnConfirm: true,
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.getTrial(serviceID)
                    }
                })
            }
        },
        getTrial(serviceID) {
            this.isLoad = true;

            return api.subscriptions.freeSubscription(this.user.account_id, serviceID)
                .then(response => {
                    if (response.success) {
                        let lastServicesPlans = this.lastSubscriptions;
                        let livePlans = this.livePlans;

                        this.UserSubscriptions[serviceID] = response.data;
                        
                        livePlans[serviceID] = response.data;
                        lastServicesPlans[serviceID] = response.data;

                        this.$store.dispatch('Auth/updateUserSubscriptions', this.UserSubscriptions);

                        this.$store.dispatch('Auth/updateLivePlans', livePlans);
                        this.$store.dispatch('Auth/updateLastSubscriptions', lastServicesPlans);

                        this.$swal.fire({
                            title: 'Activate Trial',
                            text: response.message,
                            icon: 'success'
                        }).then((confirm) => {
                            this.isLoad = false;
                            this.redirectToBilling();
                        })

                    }
                })
                .catch((error) => {
                    let message = error?.response?.data?.message ?? 'something went wrong!';
                    if (error.response.status == 500) {
                        message = 'something went wrong!'
                    }

                    this.isLoad = false;

                    this.$bvToast.toast(message, {
                        title: 'Error Activate Trial',
                        variant: 'danger'
                    });
                });
        },
        getServiceCurrentSubscription(serviceID) {
            if (this.isClient) {
                this.spinner = true;

                const accountId = this.user.account_id;

                api.accounts.getCurrentSubscription(accountId, { service_id: serviceID })
                    .then((response) => {
                        if (response.success) {
                            this.UserSubscriptions[serviceID] = response.data;
                            this.$store.dispatch('Auth/updateUserSubscriptions', this.UserSubscriptions);
                            this.getCurrentPlan(serviceID)
                        }
                    })
                    .finally(() => {
                        this.spinner = false;
                    })


            }
        },
        redirectToBilling() {
            setTimeout(() => {
                window.location.href = '/user/billing';
            }, 1500);
        },
        fetchSubscriptionAlreadyUsed(serviceId) {

            if(this.isClient) {

                api.subscriptions.accountAlreadyUseService(this.user.account_id, serviceId)
                .then((response) => {

                    if (response.success) {
                        this.subscriptionUsed = response.data ?? false;
                    }

                })
            }
            
        },
        accountNeverUsedService() {

            // return false
            // let serviceId = this.$route.params.service;
            // return this.subscriptionUsed || (this.UserSubscriptions[serviceId] !== 'undefined');

            let serviceId = this.$route.params.service;

            console.log( 'Test', this.subscriptionUsed )
            console.log( !this.subscriptionUsed , this.subscriptionUsed)
            
            return this.isClient && !this.isLoad && this.trialPlan && !this.subscriptionUsed;
        },
    },
}
</script>
<style>
.display-3 {
    font-size: 2.5rem;
    font-weight: 300;
    line-height: 1.2;
}

.discount {
    display: flex;
    justify-content: inherit;
    margin-bottom: 0cm;
}

a.discountBtn {
    border: 1px solid;
    margin: 0px 2px;
}

.border_plan {
    border: 10px solid #dee2e6 !important;
}

.border_plan:hover {
    margin-top: -30px;
    transition: margin-top .3s linear;
}

.style {
    margin: 0 7em;
    font-weight: 600;
}

@media (max-width: 700px) {
    .style {
        margin: 0 0;
        font-weight: 600;
    }

    #loading-center {
        position: inherit !important;
    }
}
</style>
