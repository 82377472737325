<template>

  <div class="w-100">

    <b-row class="my-2">
      <b-col>
        <label for="">API Key/Token :</label>
        <b-input v-model="platform.apikey" placeholder="Api key"></b-input>
      </b-col>
    </b-row>
  
    <b-row>
      <b-col class="text-right mt-2">
        <button :disabled="!isAllowed" class="btn btn-primary" @click.prevent="createPlatform">Create Platform</button>
      </b-col>
    </b-row>
  
  
  
  </div>
  
  
  </template>
  
  <script>
    export default {
      name: 'BlastableInput',
      components: {
      },
      props: ['platform_nickname'],
      mounted(){
  
      },
      
      data(){
        return {
          platform: {
            account:null,
            apikey:null
          }
        }
      },
      methods: {
        createPlatform(){
          this.$emit('createPlatform',this.platform);
        }
      },
        computed : {
            isAllowed(){
                return this.platform_nickname != null && this.platform_nickname.trim() != ''; 
            }
        }
  
    }
  
  </script>