<template>
  <form @submit.prevent="submit" ref="uploadForm" >
    <!-- Drag and drop Dash Zone -->

      <div class="form-group dash mb-0 text-center"  @dragover="dragover" @dragleave="dragleave" @drop="drop">

        <h1 style="color: #0084ff;"><i class="fa fa-upload"></i></h1>

        <h4 v-if="!uploading">Drag & Drop file Here or <label class="text-sm" style="font-size:0.85rem;cursor: pointer;padding: 5px 15px;background: #e5f2ff;border-radius: 5px;color: #19f;"
                                                              for="file" v-if="!isDragging"> Choose a file </label></h4>
        <h5 v-else>Uploading...</h5>

        <input id="file" type="file" @change="handelFileUpload" class="inputfile" ref="file" accept=".csv">

      </div>

    <!-- END - Drag and drop Dash Zone -->

    <!-- Upload Limit Instructions -->

      <div class="d-flex justify-content-between mb-4" >
        <span class="text-muted" ><small>Supported formats: {{ accepted_type.join(", ") }}</small></span>
        <span class="text-muted" ><small>Maximum size:{{ max_allowed_size }}</small></span>
      </div>

    <!-- END - Upload Limit Instructions -->

    <!--  Uploaded files Progress -->

      <div  v-for="file in files" :key="file">
        <UploadedFileElement :file="file" @remove=""></UploadedFileElement>
      </div>

    <!--  END - Uploaded files Progress -->

    <div v-if="alert_message" class="alert alert-danger" style="background-color: #fcecec42;"> <i
        class="fas fa-warning m-1"></i>{{ alert_message }}</div>


    <div class="alert alert-info px-4" style="color: #0087bb;background-color: #e5faff4d;">
      <i class="fas fa-info m-1"></i> <a href="/documents/webhook_file_example.csv" class="px-1"
                                         style="color:#55aadd !important;"> Click here </a> to download our file example template
    </div>

  </form>

</template>

<script>
import UploadedFileElement from "@/components/inboxgeek/fileds/uploadFileFieldElemets/uploadedFileElement";
export default {
  name: "UploadFileFormElement",
  props:{
    accepted_type:{
      default:['text/csv','application/zip'],
      type: Array
    },
    max_allowed_size:{
      default:'250MB',
      type: String
    },
    uploadRequestFunction:{
      type:Function
    },
    max_allowed_files:{
      default:-1,
      type: Number
    }
  },
  components:{
    UploadedFileElement
  },
  data(){
    return {
      files:[],
      isDragging:false,
      isLoading:false,
      uploading:false,
      alert_message:'',
    }
  },
  methods:{
    handelFileUpload(event){
      this.submit(event);
    },
    dragover(e) {

      e.preventDefault();
      this.isDragging = true;
    },
    dragleave() {

      this.isDragging = false;
    },
    drop(e) {

      e.preventDefault();


      const files = e.dataTransfer.files;

      files.forEach((file)=>{
        this.submit(event, file);
      });


      this.isDragging = false;
    },
    submit(event,file_uploaded){

     if( this.max_allowed_files>0 && this.files.length >= this.max_allowed_files){
       this.alert_message = 'max allowed files is reached';
       return;
     }
      const file = (file_uploaded) ? file_uploaded : event.target.files[0];

      if(!file) return;
      const file_name = file.name;
      const form_data = new FormData();
      const file_type = file.type;


      if ( this.accepted_type.includes(file_type) ) {


        form_data.append('list', file);

        let current_file = {index:this.files.length,name: file_name, loading: 0,completed:false,error:{message:'',has_error:false}};
        this.files.push(current_file);

        this.show_progress = true;

        this.uploadRequestFunction( form_data, {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          onUploadProgress: ( progressEvent ) => {

            this.files[current_file.index].loading =  Math.round( ( progressEvent.loaded / progressEvent.total ) * 100  );

          }
        }).then(response => {

          if (response.success) {

            this.files[current_file.index].completed = true;

            this.$emit('listUploadedEvent',{
              last_uploaded_file:current_file,
              uploaded_files: this.files,
              response:response.data
            });

          }

        }).catch(error=>{

          this.files[current_file.index].error.has_error = true;
          this.files[current_file.index].error.message = 'invalid file structure';

        });


      }
    },
  }
}
</script>


<style scoped>
.dash {

  background: #cfe1ff0f;
  border-radius: 10px;
  padding: 1rem;
  border: 1px dashed #0084ff;

}

.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.inputfile+label {
  font-size: 1.25em;
  font-weight: 700;
  color: white;
  background-color: black;
  display: inline-block;
}

.inputfile:focus+label,
.inputfile+label:hover {
  background-color: red;
}

.inputfile+label {
  cursor: pointer;
  /* "hand" cursor */
}
</style>