<template>
    <b-form @submit="handleSubmit">
        <b-row class="mb-3 px-3">

            <b-form-group class="col-md-12 my-2 px-1" label-for="name">
                <b-row align-h="between" class="px-3">
                    <label for="discount_status">Name: *</label>
                    <b-form-checkbox switch v-model="form.active" :disabled="form.active == -1">{{
                        form.active ? 'Active' : 'Inactive'
                    }}</b-form-checkbox>
                </b-row>
                <b-form-input placeholder="Enter Name" v-model="form.name" required></b-form-input>
                <div class="text-danger" id="error_name">{{ errors.name }}</div>
            </b-form-group>

            <b-form-group class="col-md-12 my-2 px-1" label-for="name">
                <b-row align-h="between" class="px-3">
                    <label for="discount_status">Subject: *</label>
                </b-row>
                <b-form-input placeholder="Enter Name" v-model="form.subject" required></b-form-input>
                <div class="text-danger" id="error_name">{{ errors.title }}</div>
            </b-form-group>

            <b-form-group class="col-sm-12 my-2 px-1" label-for="notificationType" label="Notification Type:">
                <multiselect v-model="notificationType" tag-placeholder="Add this as new Notification Type"
                    placeholder="Select Notification Type" label="name" track-by="code" :options="notificationTypes"
                    :multiple="false" :taggable="true">
                </multiselect>
                <div class="text-danger" id="error_notificationType">{{ errors.notificationType }}</div>
            </b-form-group>

            <b-form-group v-if="notificationType && nicknames.length" class="col-sm-12 my-2 px-1"
                label-for="notificationType" label="Nickame:">
                <multiselect v-model="nickname" tag-placeholder="Add this as new NickName" placeholder="Select NickName"
                    label="name" track-by="name" :options="nicknames" :multiple="false" :taggable="true">
                </multiselect>
                <!-- <div class="text-danger" id="error_notificationType">{{ errors.notificationType }}</div> -->
            </b-form-group>

            <b-form-group class="col-md-12 my-2 px-1" label-for="name">
                <b-row align-h="between" class="px-3">
                    <label for="discount_status">Is Mail:</label>
                    <b-form-checkbox switch v-model="form.is_mail">
                        {{ form.is_mail ? 'Is Mail' : 'No Mail' }}
                    </b-form-checkbox>
                </b-row>
            </b-form-group>

            <!-- BTN Set Team Members -->
            <b-form-group class="col-md-12 my-2 px-1" label-for="name">
                <b-row align-h="between" class="px-3">
                    <label for="discount_status">Emails Team:</label>
                    <b-form-checkbox switch v-model="showTeamInput">
                        {{ showTeamInput ? 'Show Team Members' : 'Hidden Emails' }}
                    </b-form-checkbox>
                </b-row>
            </b-form-group>


            <b-form-group v-if="showTeamInput" class="col-md-12 my-2 px-1" label-for="name">
                <b-row align-h="between" class="px-3">
                    <label for="discount_status" class="col-12 px-0">
                        Team Members:

                        <span class="float-right col-10 px-0">
                            <InputTag :options="form.emails" @onUpdateUsers="onUpdateUsers">
                            </InputTag>
                        </span>
                    </label>
                </b-row>

            </b-form-group>


            <b-form-group class="col-md-12 my-2 px-1" label-for="message">

                <b-form-group v-if="notificationType && templateVariable" class="col-sm-12 my-2 px-1" label-for="tags">
                    <label v-if="templateVariable.data.length" class="small" for="tags">Variables Available: </label>

                    <b-col v-if="templateVariable.data.length" class="float-right col-10 px-0">
                        <button type="button" v-for="(name, i) in templateVariable.data" :key="i"
                            class="btn btn-sm btn-info text-lowercase text-light font-weight-bolder mr-2"
                            @click.prevent="addVariableTemplate(name)" v-b-tooltip.top="`Add ${name}`">
                            {{ "\{\{" + name + "\}\}" }}
                        </button>
                    </b-col>
                </b-form-group>

                <VueEditor ref="editor" v-model="form.message" class="w-100" />
                <div class="text-danger" id="error_name">{{ errors.message }}</div>
            </b-form-group>
        </b-row>

        <b-row class="pull-right px-0 mr-1">
            <b-button ref="btn-submit" variant="primary" type="submit" :disabled="onsubmit">
                <b-spinner small type="grow" v-show="onsubmit"></b-spinner>
                {{ submitLabel }}
            </b-button>
        </b-row>

    </b-form>
</template>

<script>
import { helper } from '@/helpers'
import api from '@/api/RestClient'
import _ from 'lodash'
import Vue from 'vue'
import moment from 'moment'
import Multiselect from "vue-multiselect"
import { VueEditor } from "vue2-editor";
import { NOTIFICATION_VARIABLES } from "@/constantes";
import InputTag from "./InputTag.vue";

export default {
    name: 'FormNotification',
    props: {
        item: {
            type: [Object],
            default: null
        }
    },
    components: {
        Multiselect,
        VueEditor,
        InputTag
    },
    mounted() {
        this.setForm();
        this.setSubmitLabel();
        this.getNotificationTypes();
    },
    data() {
        return {
            submitLabel: '',
            onsubmit: false,
            form: {
                code: null,
                active: true,
                is_mail: false,
                title: null,
                message: null,
                notificationType: null,
                nickname: null,
                users: null,
            },
            formInit: {},
            errors: {},
            disabledRoles: false,
            notificationType: null,
            notificationTypes: [],
            nickname: null,
            nicknames: [],
            templateVariable: null,
            notificationsNicknames: [
                { code: 1, title: 'Invoice', data: [{ name: 'payment_success' }] },
                { code: 2, title: 'Missed Payment', data: [{ name: 'payment_failed' }, { name: 'no_payment' }] },
                { code: 3, title: 'Out of Events', data: [] },
            ],
            teamGroup: null,
            teamGroups: [],
            teamUsers: [],
            showTeamInput: true,
        }
    },
    methods: {
        setForm() {
            let form = this.getForm()
            if (form) {
                this.form = form
            }

            this.formInit = Vue.util.extend({}, this.form);

            console.log( "this.form.emails", this.form.emails )
        },
        getForm() {
            if (!this.item || typeof this.item == "undefined") {
                return null
            }

            let form = {
                code: this.item.id,
                name: this.item.name,
                subject: this.item.subject,
                message: this.item.message,
                active: this.item.active == 1 ? true : false,
                is_mail: this.item.is_mail == 1 ? true : false,
                emails: this.item.emails ? JSON.parse(this.item.emails) : [],
            }

            if (typeof this.item !== 'undefined' && this.item.nickname) {
                this.nickname = this.item.nickname
            }

            return form
        },
        setSubmitLabel() {
            this.submitLabel = 'Add Notification'
            if (this.item && (typeof this?.item !== 'undefined') && this.item?.id) {
                this.submitLabel = `Update Notification`
            }
        },
        emitHandleSubmit(data, isNew = true) {
            this.resetForm()
            this.$emit('handleSubmit', { ...data, new: isNew })
        },
        addNewItem() {
            let isValidForm = helper.isEmpty(this.errors);
            let data = { ...this.form, nickname: this.nickname ?? null };

            const that = this;

            if (!isValidForm) {
                this.onsubmit = false;
                return;
            }
            api.notifications.post(data)
                .then(response => {

                    if (response.success) {
                        this.emitHandleSubmit(response.data, true)
                    } else {
                        that.handleError(data)
                    }
                })
                .catch(errors => {
                    that.handleError(errors)
                })
                .finally(() => {
                    this.onsubmit = false;
                })
        },
        updateItem() {
            let isValidForm = helper.isEmpty(this.errors)
            const id = this.form.code
            let data = { ...this.form, nickname: this.item.nickname ?? null };
            const that = this

            if (!isValidForm) {
                this.onsubmit = false;
                return;
            }
            // console.log('Display NickName', this.nickname, this.form.nickname, this.item.nickname); return;

            api.notifications.put(id, data)
                .then(data => {
                    if ('success' in data) {
                        this.emitHandleSubmit(data.data, false)
                    } else {
                        that.handleError(data)
                    }
                })
                .catch(errors => {
                    that.handleError(errors)
                })
                .finally(() => {
                    this.onsubmit = false;
                })
        },
        resetErrors() {
            this.errors = {}
        },
        checkForm() {
            this.resetErrors();

            if (typeof this.form.name === 'string' && (!this.form.name || !this.form.name.trim())) {
                this.errors.name = 'Name is required.';
            }

            if (typeof this.form.subject === 'string' && (!this.form.subject || !this.form.subject.trim())) {
                this.errors.title = 'Subject is required.';
            }

            if (typeof this.form.message === 'string' && (!this.form.message || !this.form.message.trim())) {
                this.errors.name = 'Message' + ' is required.';
            }
        },
        handleSubmit(e) {
            e.preventDefault()

            this.onsubmit = true;
            this.checkForm();

            if (helper.isEmpty(this.errors)) {
                if (this.form.code) {
                    this.updateItem()
                } else {
                    this.addNewItem()
                }
            } else {
                // Can emit error
                this.onsubmit = false;
            }
        },
        handleError(errors) {
            this.resetErrors()
            const that = this

            errors = this.formatErrorResponse(errors)
            // Make loop
            _.forOwn(errors, function (value, key) {
                that.errors[`setting_${key}`] = errors[key][0]
            });

            this.$emit('handleSubmit', null)
        },
        formatErrorResponse(errors) {
            if (errors.response) {
                errors = errors.response.data.errors
            } else if (errors.request) {
                errors = errors.request
            } else {
                errors = errors.message;
            }
            return errors
        },
        resetForm() {
            this.form = {
                code: null,
                name: null,
                subject: null,
                description: null,
                roles: null,
            }
        },
        deleteParam(e, ref) {
            document.getElementById(`param-${ref}`).remove()
            _.remove(this.params, function (param) {
                return param.name === ref;
            });
        },
        getNotificationTypes() {
            const that = this;

            api.notificationTypes.getNames()
                .then(response => {

                    if (response.success) {
                        let notificationTypes = response.data.map((notif) => {
                            return {
                                code: notif.id,
                                name: notif.name,
                                notificationID: notif.notification_type_id,
                            }
                        });

                        this.notificationTypes = notificationTypes;

                        if (this.item && typeof this.item.notification_type !== "undefined") {
                            let notificationType = _.find(that.notificationTypes, (o) => { return o.code == that.item?.notification_type?.id })
                            if (notificationType) this.notificationType = notificationType;
                        }
                    }

                }).catch(err => {
                    console.log(err);
                }).finally(() => {
                    const TypeID = this.notificationType && typeof this.notificationType !== 'undefined'
                        ? this.notificationType.code : null

                    this.updateVariablesTemplate(TypeID)
                })
        },
        addVariableTemplate(variableName) {
            let dataText = `{{ ${variableName} }} `
            let selection = this.$refs.editor.quill.getSelection(true);
            this.$refs.editor.quill.insertText(selection.index, dataText);
        },
        updateVariablesTemplate(TypeID = null) {
            this.nicknames = [];
            this.nickname = null;
            this.templateVariable = null;

            const that = this;

            if (TypeID) {
                let item = _.find(NOTIFICATION_VARIABLES, (o) => { return o.typeId == TypeID })
                if (item && typeof item !== 'undefined') {
                    this.templateVariable = item;
                }

                let notificationsNickname = _.find(this.notificationsNicknames, (o) => { return o.code == TypeID })
                if (notificationsNickname && typeof notificationsNickname !== 'undefined') {
                    this.nicknames = notificationsNickname.data;

                    // Set Item Current Nickname
                    if (Array.isArray(this.nicknames) && that.item) {
                        let nickname = _.find(that.nicknames, (o) => { return o.name == that.item.nickname });
                        this.nickname = nickname;
                    }
                }
            }
        },
        getRoleUsers(roles) {

            return Promise.all(roles.map((role) => {
                return api.roles.roleUsers(role.code)
                    .then(response => {

                        if (response.success && response.data.length) {

                            let users = _.map(response.data, (user) => {
                                return {
                                    email: user.email
                                }
                            })

                            this.teamUsers = _.union(this.teamUsers, users)
                        }
                    })
                    .catch(error => {
                        console.log(error)
                    });
            }));
        },
        onSelectRoleUsers(data) {
            // console.log( data )
        },
        onUpdateUsers(data) {
            this.onsubmit = true;

            this.$nextTick(() => {
                this.onsubmit = false;
                this.form.emails = data;
            });
        }
    },
    computed: {
        isSameInit() {
            return _.isEqual(this.form, this.formInit);
        },
        emailTypeOptions() {
            let options = _.map(this.emailsOptions, (option) => {
                return option.value
            })
            return options
        }
    },
    watch: {
        "form": {
            handler(newVal, oldVal) {
                if (!this.isSameInit) {
                    // this.$refs['btn-submit'].disabled = false
                } else {
                    // this.$refs['btn-submit'].disabled = true
                }
            },
            deep: true
        },
        "notificationType": {
            handler(newVal, oldVal) {
                const TypeID = newVal && typeof newVal !== 'undefined' ? newVal.code : null
                this.updateVariablesTemplate(TypeID)
            },
            deep: true
        },
    },
}
</script>

<style>
.cursor-pointer {
    cursor: pointer;
}
</style>