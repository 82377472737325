import axios from 'axios'
import store from "../store";
import api from '@/api/RestClient'

// Platform services
import ActiveCampaignHelper from './platforms/activecampaign'
import AweberHelper from './platforms/aweber'
import BlastableHelper from './platforms/blastable'
import CampaignerHelper from './platforms/campaigner'
import ConvertkitHelper from './platforms/convertkit'
import EmailOctopusHelper from './platforms/emailoctopus'
import ExpertsenderHelper from './platforms/expertsender'
import GetResponseHelper from './platforms/getresponse'
import InboxSuiteHelper from './platforms/inboxsuite'
import HubspotHelper from './platforms/hubspot'
import KeapHelper from './platforms/keap'
import KlaviyoHelper from './platforms/klaviyo'
import MailchimpHelper from './platforms/mailchimp'
import OntraportHelper from './platforms/ontraport'
import SendlaneHelper from './platforms/sendlane'
import MumaraHelper from './platforms/mumara'
import WebhookHelper from './platforms/webhook'
import MailwizzHelper from './platforms/mailwizz'
import BrevoHelper from './platforms/brevo'
import InboxHelper from './platforms/inbox'
import GoHighLevelHelper from './platforms/gohighlevel'
import OngageHelper from './platforms/ongage'
import Beehiiv from './platforms/beehiiv'
import DefaultServiceHelper from './platforms/_default'

import {SERVICES_BY_ID, SERVICES} from "@/constantes";
import moment from 'moment'


// Default axios client.
let axiosInstance = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL + '/api',
    timeout: 30000
});
// Response interceptor.
axiosInstance.interceptors.response.use((response) => response, (error) => {
    if (error && error.response && error.response.status === 401) {
        store.dispatch('Auth/resetUser', false).then(() => router.push({
            name: 'auth1.sign-in1'
        }))
    }
    return Promise.reject(error);
}
);

const ALERT_DELAY_HIDE = 60000 * 60; // milisecond * minutes numbers

axiosInstance.interceptors.request.use((config) => {
    if (config.noAuth) {
        return config;
    };
    const token = store.getters['Auth/tokenAs'] ?? store.getters['Auth/token'];
    config.headers.Authorization = "Bearer " + token;
    return config
});

export const helper = {
    equal(val1, val2) {
        return val1 === val2;
    },
    isEmpty(obj) {
        return Object.keys(obj).length === 0;
    },
    toast(title = "success", variant = "success", message = "Account chargerd") {
        this.$bvToast.toast(message, {
            title: title,
            variant: variant
        })
        return 1;
    },
    validMail(mail) {
        return /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()\.,;\s@\"]+\.{0,1})+([^<>()\.,;:\s@\"]{2,}|[\d\.]+))$/.test(mail);
    },
    checkStringLegth(string = '', size = 1) {
        return string.length <= size;
    },
    dateToYMD(date) {
        var d = date.getDate();
        var m = date.getMonth() + 1; //Month from 0 to 11
        var y = date.getFullYear();
        return '' + y + '-' + (m <= 9 ? '0' + m : m) + '-' + (d <= 9 ? '0' + d : d);
    },
    AddOrSubractDays(startingDate, number, add) {
        if (add) {
            return new Date(new Date().setDate(startingDate.getDate() + number));
        } else {
            return new Date(new Date().setDate(startingDate.getDate() - number));
        }
    },

    show(msg = 'message') {
        console.log(msg);
    },
    ahAxios(comp, settings, options = {}) {
        httpClient(settings)
            .then(function (response) {
                // Datatable
                if (options) {
                    if ('tableName' in options) options.callback(comp, response, options.tableName, options.isRefrech);
                    else if ('item' in options) options.callback(comp, response, options.item);
                    else options.callback(comp, response);
                }
            }).catch(function (error) {
                // handle error
                if (error.response) {
                    if (error.response.status == 401) {
                        comp.$store.dispatch('resetUser', false);
                        comp.$router.go(comp.$router.currentRoute)
                    } else if (error.response.status == 500) {
                        alert('Oops, something went wrong!  The team have been notified.');
                    }
                }
            }).finally(() => {
                if ('tableName' in options) {
                    this.inlineDatatableDef(options.tableName, comp);
                }
            });
    },
    request(comp, options, callback) {
        return new Promise((resolve, reject) => {
            httpClient(options)
                .then(function (response) {
                    callback.action(comp, response, callback.data);
                });
        })
    },
    formatDecimal(nStr) {
        nStr += '';
        var x = nStr.split('.');
        var x1 = x[0];
        var x2 = x.length > 1 ? '.' + x[1] : '';
        var rgx = /(\d+)(\d{3})/;
        while (rgx.test(x1)) {
            x1 = x1.replace(rgx, '$1' + ',' + '$2');
        }
        return x1 + x2;
    },
    moneyDefaultFormat(amount) {
        return amount.toLocaleString('en-US', { maximumFractionDigits: 6, style: 'currency', currency: 'USD' });
    },
    dateRange(startDate, endDate, steps = 1) {
        const dateArray = [];
        startDate = (startDate == 0 ? new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000) : new Date(startDate));
        endDate = (endDate == 0 ? new Date() : new Date(endDate));

        let currentDate = startDate;

        while (currentDate <= new Date(endDate)) {
            dateArray.push(this.formatDate(new Date(currentDate)));
            // Use UTC date to prevent problems with time zones and DST
            currentDate.setUTCDate(currentDate.getUTCDate() + steps);
        }

        return dateArray;
    },
    formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    },
    refreshToken() {
        console.log('success');
    },
    alertAction(comp) {
        comp.$swal({
            title: 'Are you sure?',
            text: 'You can\'t revert your action',
            type: 'info',
            showCancelButton: true,
            confirmButtonText: 'Yes Delete it!',
            cancelButtonText: 'No, Keep it!',
            showCloseButton: true,
            showLoaderOnConfirm: true
        }).then((result) => {
            if (result.value) {
                const id = item.id.split('#')[1];
                const data = {};
                data.id = id;
                var options = {
                    method: 'DELETE',
                    url: '/integration/delete/' + id,
                }
                // callback axios request
                var response = {
                    action: function (that, resp) {
                        var data = resp.data;
                        if ('error' in data) that.toast('Error', 'danger', data.error.name[0])
                        else if ('success' in data) {
                            let index = that.rows.indexOf(item)
                            that.rows.splice(index, 1)
                            that.$swal('Deleted', data.message, 'success')

                            tableDT(that, 'lists', 'lists-table', true);

                        }
                    }
                }
                request(comp, options, response)
            } else {
                comp.$swal('Cancelled', 'Your file is still intact', 'info')
            }
        })
    },
    json2array(json) {
        var result = [];
        var keys = Object.keys(json);
        keys.forEach(function (key) {
            result.push(json[key]);
        });
        return result;
    },
    setUserRoleLevel(comp) {
        var roles = comp.$store.getters['Auth/user'].roles;
        let roleIds = roles.map((role) => {
            return role["id"];
        });

        if (jQuery.inArray(1, roleIds) !== -1) comp.isAdmin = true;
        else comp.isClient = true;
    },
    getAccounts(comp) {
        // Get Accounts
        var options = {
            method: "get",
            url: "/accounts",
        };
        var response = {
            action: function (that, resp) {
                var orgnanizations = resp?.data?.data?.data;
                var accounts = [];
                var account;
                orgnanizations.forEach((org) => {
                    account = {};
                    account.name = org.name;
                    account.code = org.id;
                    accounts.push(account);
                });
                that.$data.organizations = accounts;
                // if (accounts.length)
                //   that.$data.formAccount.organizations = { code: null, name: null };
            },
        };
        helper.request(comp, options, response);
    },
    loadRefreshAccount(comp, accountID = null, refresh = null) {
        // console.log(accountID);
        var option = {
            method: "GET",
            url: comp.isAdmin
                ? "/admin/list" + (accountID ? `/${accountID}` : "")
                : "/integration/list",
        };
        // IntegrationList datatanle
        var result1 = helper.datatable(comp, option, "integration-table", refresh);
        var option2 = {
            method: "GET",
            url: comp.isAdmin
                ? "/admin/lists" + (accountID ? `/${accountID}` : "")
                : "lists",
        };
        // List datatanle
        var result2 = helper.datatable(comp, option2, "lists-table", refresh);

    },
    refreshDT(comp, tableName, url) {
        let options = {
            'refresh': true,
        }
        helper.tableDT(comp, url, tableName, options);
    },
    validateTime(time) {
        if (time) return time.match(/\d+:\d+/) ? true : false;
        else return false;
    },
    customCapitalize(value, forceAll = null) {
        if (!value) return '--'
        value = value.toString()
        return value.charAt(0).toUpperCase() + (!forceAll ? value.slice(1) : value.slice(1).toUpperCase())
    },
    formatNumber(value) {
        if (!value) return 0
        return value.toLocaleString()
    },
    formatErrorResponse(errors) {
        if (errors.response) {
            errors = errors.response.data.errors
        } else if (errors.request) {
            errors = errors.request
        } else {
            errors = errors.message;
        }
        return errors
    },
    hideSecretChart(value) {
        value.replaceAll(/\*/, "*");
    },
    accountIsActive(account) {

    },
    alertAccountInactive($swal) {
        return $swal.fire({
            title: 'Your Account is Currently Inactive',
            html: `<p>If you have any questions about your account, please contact us at <a target="_blank" href="mailto:support@inboxgeek.com">support@inboxgeek.com</a></p>`,
            icon: 'info',
            confirmButtonText: 'OK',
            showCloseButton: true,
        })
    },
    alertAccountNoSubscriptions($swal) {
        return $swal.fire({
            title: 'Your Account has No Active Subscription',
            html: `<p>Your Account has no subscription to a service.<br> To benefit from InboxGeek services, please contact us at <a target="_blank" href="mailto:support@inboxgeek.com">support@inboxgeek.com</a></p>`,
            icon: 'info',
            confirmButtonText: 'OK',
            showCloseButton: true,
        })
    },
    checkIfAccountIsActive($swal) {
        if (store.getters['Auth/isClient']) {
            let user = store.getters['Auth/user'];

            if (user && user.account && !user.account.status) {
                return helper.alertAccountInactive($swal)
                .then((confirm) => {
                    setTimeout(() => {
                        console.log( 'Must logout', confirm );
                        helper.logout();
                    }, 500, this);

                });
            }

        }

    },
    checkIfUserIsActive($swal) {

        if (store.getters['Auth/isClient']) {
            let user = store.getters['Auth/user'];
            let swalAlert = null;

            if (user && !user.status) {
                swalAlert = {
                    title: 'Your Profile is Inactive',
                    html: `<p>Your profile has been Disabled, please contact your dedicated account manager or email a team member at <a target="_blank" href="mailto:support@inboxgeek.com">support@inboxgeek.com</a></p>`,
                    icon: 'info',
                    confirmButtonText: 'OK',
                    showCloseButton: true,
                }
            }

            if (swalAlert) {
                return $swal.fire(swalAlert);
            }

            return;
        }

    },
    checkServiceHasBalance($swal, serviceID = SERVICES.LIVE_OPENS.id) {
        if (store.getters['Auth/isClient']) {

            let swalAlert = null;
            let user = store.getters['Auth/user'];
            let subscriptions = store.getters['Auth/UserSubscriptions'];
            let currentSubscription = subscriptions[serviceID];

            let alertKey = `alert.service_balance:${serviceID}:${user.account.name}:${user.id}:`;
            const showAlert = localStorage.getItem(alertKey);

            let labelFirst = '--';
            let labelSecond = '--';
            let alertMessage = null;
            let headerClass = null;
            let badgeClass = 'badge-primary';

            if (serviceID == SERVICES.LIVE_OPENS.id) {
                labelFirst = 'events';
                labelSecond = 'sending event';
                headerClass = 'bg-primary';
            }

            if (serviceID == SERVICES.LIVE_LEADS.id) {
                labelFirst = 'records';
                labelSecond = 'receiving records';
                headerClass = 'bg-info';
                badgeClass = 'badge-info';
            }

            let serviceName = SERVICES_BY_ID[serviceID].name;

            if (!currentSubscription) {

                alertMessage = `<p>Your <span class="badge ${badgeClass}">${serviceName}</span> Service has not Active Subscription. 
                Please visit the billing dashboard and select a subscription.</p> <p>If you have any questions about your account, please contact us at <a target="_blank" href="mailto:support@inboxgeek.com">support@inboxgeek.com</a></p>`;

            } else {

                api.stats
                    .getConsumptionDataService(serviceID)
                    .then(response => {
                        if (response.success) {
                            const data = response.data;

                            let meta_data = store.getters['Auth/UserMetaData'];

                            if(!data.plan) {
                                // Case not active subscription for service

                                alertMessage = `<p>It appears your <span class="badge ${badgeClass}">${serviceName}</span> has not active Subscription. Please visit the billing dashboard and select a subscription.</p> <p>If you have any questions about your account, please contact us at <a target="_blank" href="mailto:support@inboxgeek.com">support@inboxgeek.com</a></p>`;


                                $swal.fire({
                                    title: `Oh?`,
                                    html: alertMessage,
                                    showCloseButton: true,
                                    showCancelButton: true,
                                    confirmButtonText: "ok",
                                    cancelButtonText: "don't show me this again",
                                    customClass: {
                                        title: headerClass+ ' text-light',
                                    }
                                }).then((confirm) => {
                                    if(confirm.isConfirmed) {
                                        localStorage.setItem(alertKey, true)
                                    }

                                    if ( confirm.isDismissed) {

                                        api.userMetadata.updateUserMetadata(serviceName +'_trail_ends',true).then(response =>{


                                            store.dispatch('Auth/updateUserMetaDta', {
                                                key: serviceName +'_trail_ends',
                                                value:"1"
                                            });
                                        });

                                    }
                                });

                            }

                            if (data.plan && data.plan.is_trial ) {
                                // Case Trial - check if expire

                                let isValidSubscription = null;
                                let time = currentSubscription.ends_at ? moment(currentSubscription.ends_at)
                                    : currentSubscription && !currentSubscription.ends_at;

                                if ((time !== true && time > moment() && currentSubscription && currentSubscription.active) || time === true) isValidSubscription = true;

                                if(!isValidSubscription) {
                                    alertMessage = `<p>It appears your <span class="badge ${badgeClass}">${serviceName}</span> trial has ended. 
                                <br>To extend your trial, please contact your dedicated account manager or email a team member at <a href="mailto:support@inboxgeek.com" class="font-italic">support@inboxgeek.com</a></p>`;

                                   let show_message = true;
                                   meta_data.forEach(data=>{

                                       if(data['key'] === serviceName +'_trail_ends' && data['value']=='1') {
                                           show_message = false;
                                       }
                                   });

                                   if(show_message){
                                        $swal.fire({
                                            title: `Oh?`,
                                            html: alertMessage,
                                            showCloseButton: true,
                                            showCancelButton: true,
                                            confirmButtonText: "ok",
                                            cancelButtonText: "Don't show me this again",
                                            customClass: {
                                                title: headerClass+ ' text-light',
                                            }
                                        }).then((result) => {

                                            if ( result.isDismissed) {

                                                api.userMetadata.updateUserMetadata(serviceName +'_trail_ends',true).then(response =>{


                                                    store.dispatch('Auth/updateUserMetaDta', {
                                                        key: serviceName +'_trail_ends',
                                                        value:"1"
                                                    });
                                                });

                                            }
                                        });
                                   }
                                }
                            }

                            if ( (data.plan && !data.plan.is_trial) && currentSubscription && !helper.checkIfAccountSubscriptionByService(serviceID) ) {
                                const endFrom = moment(currentSubscription.ends_at);

                                // Case subscription is expire
                                alertMessage = `<p>Your Plan <span class="badge ${badgeClass}">${serviceName}</span> has been closed since ${endFrom.format('DD-MM-YYYY')}.<br>If you have any questions about your account, please contact us at <a target="_blank" href="mailto:support@inboxgeek.com">support@inboxgeek.com</a></p>`;

                                let show_message = true;
                                meta_data.forEach(data=>{

                                    if(data['key'] === serviceName +'_plan_closed' && data['value']=='1') {
                                        show_message = false;
                                    }
                                });

                                if(show_message) {
                                    $swal.fire({
                                        title: `Oh?`,
                                        html: alertMessage,
                                        showCloseButton: true,
                                        showCancelButton: true,
                                        confirmButtonText: "ok",
                                        cancelButtonText: "don't show me this again",
                                        customClass: {
                                            title: headerClass + ' text-light',
                                        }
                                    }).then((confirm) => {
                                        if (confirm.isConfirmed) {
                                            localStorage.setItem(alertKey, true)
                                        }

                                        if (confirm.isDismissed) {

                                            api.userMetadata.updateUserMetadata(serviceName + '_plan_closed', true).then(response => {


                                                store.dispatch('Auth/updateUserMetaDta', {
                                                    key: serviceName + '_plan_closed',
                                                    value: "1"
                                                });
                                            });

                                        }

                                    });
                                }
                            }

                            if(data.plan && (data.limit + data.remaining <= 0 && data.limit != -1 )) {
                                // Case balance empty

                                alertMessage = `<p>It appears your <span class="badge ${badgeClass}">${serviceName}</span> have reached the ${labelFirst} limit. If you\'d like to activate your overconsumption feature to continue.</br>If you have any questions about your account, please contact us at <a target="_blank" href="mailto:support@inboxgeek.com">support@inboxgeek.com</a></p>`;

                                let show_message = true;
                                meta_data.forEach(data=>{

                                    if(data['key'] === serviceName +'_limit_reached' && data['value']=='1') {
                                        show_message = false;
                                    }
                                });

                                if(show_message) {
                                $swal.fire({
                                    title: `Oh?`,
                                    html: alertMessage,
                                    showCloseButton: true,
                                    showCancelButton: true,
                                    confirmButtonText: "ok",
                                    cancelButtonText: "don't show me this again",
                                    customClass: {
                                        title: headerClass+ ' text-light',
                                    }
                                }).then((confirm) => {
                                    if(confirm.isConfirmed) {
                                        localStorage.setItem(alertKey, true)
                                    }

                                    if ( confirm.isDismissed) {

                                        api.userMetadata.updateUserMetadata(serviceName +'_limit_reached',true).then(response =>{


                                            store.dispatch('Auth/updateUserMetaDta', {
                                                key: serviceName +'_limit_reached',
                                                value:"1"
                                            });
                                        });

                                    }
                                });
                            }

                            }
                        }
                    })
                    .catch(function (error) {
                        if (error.response) {
                            // handle error
                        }
                    });
            }

        }
    },
    checkIfAccountSubscriptionByService(serviceID) {
        let subscriptions = store.getters['Auth/UserSubscriptions'];
        let serviceSubscription = subscriptions[serviceID];
        let isValidSubscription = false;

        if(!serviceSubscription) {
            return false;
        }

        let ends_at = serviceSubscription.ends_at ?? (serviceSubscription.next_billing_date ?? null);

        let time = ends_at ? moment(ends_at)
            : serviceSubscription && !ends_at;

        if ((time !== true && time > moment() && serviceSubscription && serviceSubscription.active) || time === true) isValidSubscription = true;

        return serviceSubscription && isValidSubscription
    },
    showAlertRemainingByService($swal, serviceID = SERVICES.LIVE_OPENS.id) {
        let subscriptions = store.getters['Auth/UserSubscriptions'];
        let serviceSubscription = subscriptions[serviceID];

        let labelFirst = '--';
        let labelSecond = '--';
        let alertMessage = null;
        let headerClass = null;

        let date = '--';
        if(serviceSubscription && serviceSubscription.ends_at) { date = serviceSubscription.ends_at }
        else if(serviceSubscription && serviceSubscription.next_billing_date) { date = serviceSubscription.next_billing_date }

        if (serviceID == SERVICES.LIVE_OPENS.id) {
            labelFirst = 'events';
            labelSecond = 'sending event';
            headerClass = 'bg-primary';
        } else if (serviceID == SERVICES.LIVE_LEADS.id) {
            labelFirst = 'records';
            labelSecond = 'receiving records';
            headerClass = 'bg-info';
        }

        alertMessage = `Your account is out of <i>${labelFirst}</i> until <i>${date}</i>. If you\'d like to activate your overconsumption feature to continue <i>${labelSecond}</i> until your next billing cycle, please visit your dashboard`;

        return $swal.fire({
            title: 'Uh, oh...',
            html: alertMessage,
            confirmButtonText: 'Got it!',
            showCloseButton: true,
            customClass: {
                title: headerClass+ ' text-light',
            }
        })
    },
    checkAccountAlerts($swal) {

        if (store.getters['Auth/isClient']) {
            const subscriptions = store.getters['Auth/UserSubscriptions'];

            // Inactive Account Alert
            let user = store.getters['Auth/user'];
            if (user && user.account && !user.account.status) {
                return helper.alertAccountInactive($swal)
                    .then((confirm) => {
                        helper.logout();
                    });
            }

            if(!subscriptions[SERVICES.LIVE_OPENS.id] && !subscriptions[SERVICES.LIVE_LEADS.id]) {
                // let alertKey = 'alert.no_subscription.hide';
                let user = store.getters['Auth/user'];
                let alertKey = `alert.no_subscription:${user.account.name}:${user.id}:`;
                const showAlert = localStorage.getItem(alertKey);

                if(!showAlert) {
                    return helper.alertAccountNoSubscriptions($swal)
                        .then((confirm) => {
                            localStorage.setItem(alertKey, true)
                        });
                }

            }

            if(subscriptions[SERVICES.LIVE_OPENS.id]) {
                helper.checkServiceHasBalance($swal, SERVICES.LIVE_OPENS.id);
            }

            if(subscriptions[SERVICES.LIVE_LEADS.id]) {
                helper.checkServiceHasBalance($swal, SERVICES.LIVE_LEADS.id);
            }

        }

    },
    setLocalStorageItemWithExpiry(key, value, expiryTimeInMs) {
        const now = new Date()

        // `item` is an object which contains the original value
        // as well as the time when it's supposed to expire
        const item = {
            value: value,
            expiry: now.getTime() + expiryTimeInMs,
        }
        localStorage.setItem(key, JSON.stringify(item))
    },
    getLocalStorageItemWithExpiry(key) {
        const itemStr = localStorage.getItem(key)

        if (!itemStr) {
            return null
        }
        const item = JSON.parse(itemStr)
        const now = new Date()

        if (now.getTime() > item.expiry) {
            localStorage.removeItem(key)
            return null
        }
        return item.value
    },
    showMaxCharacters(value, max = 10) {
        if (value.length < max) return value

        return value.substring(0, max - 1) + "..."
    },
    popUpCreateIntegration(comp) {
        return comp.$swal({
            title: 'Reminder',
            text: `InboxGeek will only send events to US contacts. It is recommended to start with a list size of at least 20k for best integration performance.`,
            icon: 'info',
            confirmButtonText: 'OK',
            showCloseButton: true,
        })
    },
    listSelected(list) {
        if (list) {
            this.list = [{
                'code': list.code,
                'name': list.name,
                'type': 'list'
            }];
        } else {
            this.list = [];
        }
    },
    segmentSelected(segments, list) {
        if (list) {

            this.list = segments.map((segment) => {
                console.log(segment.code + '-' + list.code);
                return {
                    'code': segment.code + '-' + list.code,
                    'name': segment.name,
                    'type': 'segment'
                };
            })

        } else {
            this.list = [];
        }
    },
    showAlertRemaining(comp, data = {}, serviceName = null) {

        let serviceID = this.getComponentServiceID(comp);
        console.log(serviceID);

        if ((!data || typeof data === undefined) && (comp.consumption_details || comp.details)) {
            if (typeof comp.consumption_details !== undefined) data = comp.consumption_details
            else if (typeof comp.details !== undefined) comp.consumption_details
        }

        let date = data.plan ? data.plan.next_billing_date ?? 'undefined' : '--';

        let labelFirst = '--';
        let labelSecond = '--';

        if (serviceID == SERVICES.LIVE_OPENS.id) {
            labelFirst = 'events';
            labelSecond = 'sending event';
        } else if (serviceID == SERVICES.LIVE_LEADS.id) {
            labelFirst = 'records';
            labelSecond = 'receiving records';

        }

        let alertMessage = ``;
        alertMessage += `<p>It appears <span class="badge badge-primary">${serviceName}</span> is out of <span class="font-italic">${labelFirst}</span> until [${date}]. If you\'d like to activate your overconsumption feature to continue <span class="font-italic">${labelSecond}</span> until your next billing cycle, please visit your dashboard</p>`;

        comp.$swal({
            title: 'Uh, oh...',
            customClass: "remaining-alert",
            html: alertMessage,
            type: 'info',
            confirmButtonText: 'Got it!',
            showCloseButton: true,
        })
    },
    isValidURL(str) {
        let pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
        return !!pattern.test(str);
    },
    getImageURL(imgSrc) {
        try {
            return require(`${imgSrc}`)
        } catch (e) {
            return require(`@/assets/images/platforms/default.png`)
        }
    },
    delay(timeout = 2000) {
        return new Promise(resolve => setTimeout(resolve, timeout));
    },
    generateScript(code = '') {
        const div = document.createElement("div");
        const script = this.buildScript(code);
        div.appendChild(script);

        return div
    },
    generateSuppressionScript(code = '') {
        const div = document.createElement("div");
        const script = this.buildScript(code, true);
        div.appendChild(script);

        return div
    },
    buildScript(code = '', suppScript = false) {
        const script = document.createElement("script");
        let route = '/ge_sources/script/';
        script.setAttribute("type", "text/javascript");
        if (suppScript) {
            script.setAttribute("data-action", "cnv");
            route = route + 'suppression/'
        }


        script.setAttribute("data-id", "alocdn-ldr");
        script.setAttribute("data-label", code); // source code
        script.setAttribute("src", process.env.VUE_APP_BASE_URL+'/api' + route + code); // link of IBG API script
        script.setAttribute("async", true);
        script.setAttribute("defer", true);

        return script;
    },
    getMinMaxValues(data) {
        const result = data.filter(x => !isNaN(x)).reduce((acc, x) => {
            acc.max = Math.max(acc.max, x);
            acc.min = Math.min(acc.min, x);
            return acc;
        }, { min: Number.POSITIVE_INFINITY, max: Number.NEGATIVE_INFINITY })
        return result
    },
    formatJsonApiKey(value) {
        if (typeof value == "undefined") {
            return null
        }

        if (/^[\],:{}\s]*$/.test(value.replace(/\\["\\\/bfnrtu]/g, '@').
            replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']').
            replace(/(?:^|:|,)(?:\s*\[)+/g, ''))) {

            let token = JSON.parse(value)
            let access_token = token?.access_token ?? token?.accessToken

            return access_token ?? value
        }

        return value
    },
    redirectToLiveLeadsRecords(comp, dateFrom = null, dateTo = null, serie = null) {
        let message = `You will be redirect to LiveLeads Records page`;

        if (dateFrom && dateTo) {
            message += ` from ${dateFrom} to ${dateTo}`;
        } else if (!dateTo && dateFrom) {
            message += ` ${dateFrom}`;
        } else if (!dateFrom && dateTo) {
            message += ` ${dateTo}`;
        }

        return comp.$swal({
            title: 'Are you sure?',
            text: message,
            type: 'info',
            confirmButtonText: 'Yes',
            showCancelButton: true,
            showCloseButton: true,
            showLoaderOnConfirm: true
        }).then((result) => {
            if (result.value) {
                let params = {};
                let query = {};

                if (typeof comp.liveLeadSource !== 'undefined') {
                    params.id = comp.liveLeadSource.id
                }

                if (comp.showChart) {
                    query.from = comp.options.dates.from;
                    query.to = comp.options.dates.to;
                }

                if (dateFrom) {
                    query.from = dateFrom
                }
                if (dateTo) {
                    query.to = dateTo
                }
                if (serie) {
                    query.serie = serie
                }

                comp.$router.push({ name: 'liveleads.records', params, query })
            }
        })
    },
    getCurrentServiceName(service = SERVICES.LIVE_OPENS.id) {
        let serviceName = null;

        if (service == SERVICES.LIVE_OPENS.id) {serviceName = 'LiveOpens';}
        else if (service == SERVICES.LIVE_LEADS.id) {serviceName = 'LiveLeads';}


        return serviceName;
    },
    getComponentServiceID(comp) {
        let serviceID = null;
        if (typeof comp.service != 'undefined') {
            serviceID = comp.service
        }

        if (typeof comp.service_id != 'undefined') {
            serviceID = comp.service_id
        }
        return serviceID
    },
    platformFormatName(platform) {
        let name = platform?.toLowerCase();
        switch (name) {
            case 'inbox':
                name = 'INBOX';
                break;
            case 'hubspot':
                name = 'HubSpot';
                break;
            case 'convertkit':
                name = 'ConvertKit';
                break;
            case 'aweber':
                name = 'AWeber';
                break;
            case 'inboxsuite':
                name = 'Inbox Suite';
                break;
            case 'activecampaign':
                name = 'ActiveCampaign';
                break;
            case 'getresponse':
                name = 'GetResponse';
                break;
            case 'expertsender':
                name = 'ExpertSender';
                break;
            case 'emailoctopus':
                name = 'EmailOctopus';
                break;
            case 'sendgrid':
                name = 'SendGrid';
                break;
            case 'gohighlevel':
                name = 'HighLevel';
                break;
            case 'mailsend':
                name = 'MailSend';
                break;
            default:
                name = this.capitalizeFirstLetter(name);
                break;
        }

        return name;
    },
    capitalizeFirstLetter(string) {
        if (!string) return string;
        return string ? string.charAt(0).toUpperCase() + string.slice(1) : '';
    },
    create_UUID() {
        let dt = new Date().getTime();
        let uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            let r = (dt + Math.random() * 16) % 16 | 0;
            dt = Math.floor(dt / 16);
            return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
        return uuid;
    },
    formatLists(form) {

        let platformName = typeof form.platform == 'number' && form.platform_recorded?.name
            ? form.platform_recorded.name.toLowerCase()
            : form.platform?.name ? form.platform.name.toLowerCase() : '--';

        if (typeof platformName === "undefined" || !platformName || form.lists.length < 0) return [];

        var servicePlatform = null

        const defaultService = services.default


        let strId = 'id';
        let strName = 'name'

        if ((typeof services[platformName] !== "undefined")) servicePlatform = services[platformName]
        if (servicePlatform?.labels?.listID && servicePlatform?.maps?.listsMap) strId = servicePlatform.labels.listID
        if (servicePlatform?.labels?.listName) strName = servicePlatform.labels.listName

        if (servicePlatform?.labels?.tagID && servicePlatform?.maps?.tagsMap) strId = servicePlatform.labels.tagID
        if (servicePlatform?.labels?.tagName) strName = servicePlatform.labels.tagName

        let data = _.map(form.lists, (obj, key) => {
            let item = {}

            if (typeof servicePlatform?.maps?.listsMap === "function" ||
                typeof servicePlatform?.maps?.tagsMap === "function") {
                item = ['ontraport'].includes(platformName) ? servicePlatform.maps.tagsMap(obj) :
                    typeof servicePlatform?.maps?.listsMap === "function"
                        ? servicePlatform.maps.listsMap(obj) : defaultService.maps.listsMap(obj, strId, strName)
            } else {

                item = ['ontraport'].includes(platformName) ? defaultService.maps.tagsMap(obj, strId, strName) : defaultService.maps.listsMap(obj, strId, strName)
            }
            return item
        });

        return data
    },
    hasRoles(roles = [],) {
        let user_roles = store.getters['Auth/user'].roles;
        return !_.isEmpty(_.filter(user_roles, function (r) {
            return roles.includes(r.slug);
        }))
    },
    dispatchEventUserLoginAs(data, $store) {
        store.actions.dispatch('Auth/updateLiveOpenersSubscribed', false);
        store.actions.dispatch('Auth/updateLiveLeadsSubscribed', false);

        let userAs = data;
        let user = userAs.user;
        let subscribed = userAs.subscribed;
        let roles = user.roles.map(function (role) {
            return role.slug;
        });

        store.actions.dispatch('Auth/updateTokenAs', data.access_token);
        store.actions.dispatch('Auth/updateUserAdmin', this.user);

        let admin = false;
        if (roles.includes('ROLE_SUPER_ADMIN')) admin = true;
        user['role_id'] = 1;

        store.actions.dispatch('Auth/updateisAdmin', admin);
        store.actions.dispatch('Auth/updateisClient', !admin);
        store.actions.dispatch('Auth/updateUser', user);


        let serviceSubscription, serviceSubscription2 = null;


        // Dispatch Subscriptions services
        if (!admin) {
            serviceSubscription = typeof userAs.live_services_subscriptions[1] != 'undefined'
            serviceSubscription2 = typeof userAs.live_services_subscriptions[2] != 'undefined'
        }
        store.actions.dispatch('Auth/updateIsSubscribed', subscribed);
        store.actions.dispatch('Auth/updateUserSubscriptions', userAs.live_services_subscriptions);
        if (serviceSubscription) {
            let _serviceSubscription = userAs.live_services_subscriptions[1];
            store.actions.dispatch('Auth/updateLiveOpenersSubscribed', serviceSubscription && _serviceSubscription.active);
        }
        if (serviceSubscription2) {
            let _serviceSubscription = userAs.live_services_subscriptions[2];
            store.actions.dispatch('Auth/updateLiveLeadsSubscribed', serviceSubscription2 && _serviceSubscription.active);
        }
    },
    async logout() {
        return api.auth.logout()
            .then(response => {

                if(response.success) {
                    localStorage.clear();
                    store.dispatch('Auth/updateLoggedIn', false);
                    window.location.href = '/';
                }

            })

    },
    showAlertOutOfEventOrEmptyBalance(serviceId = null) {
        if(!serviceId) return {state: false, alert: null};
        
        let livePlans = store.getters['Auth/livePlans'];
        let userSubscriptions = store.getters['Auth/UserSubscriptions'];
        let lastPlans = store.getters['Auth/lastSubscriptions'];

        let livePlan = livePlans[serviceId];
        let currentSubscription = userSubscriptions[serviceId];
        let lastPlan = lastPlans[serviceId];
        
        let state = null;
        let alert = null;

        let labelFirst = '--';
        let labelSecond = '--';
        let alertMessage = null;
        let headerClass = null;

        let date = '--';
        let isEnded = null;
        let alreadyHadPlan = false;

        // Event Name formatting 
        if (serviceId == SERVICES.LIVE_OPENS.id) {
            labelFirst = 'events';
            labelSecond = 'sending event';
            headerClass = 'bg-primary';
        } else if (serviceId == SERVICES.LIVE_LEADS.id) {
            labelFirst = 'records';
            labelSecond = 'receiving records';
            headerClass = 'bg-info';
        }

        // If account has currentSubscription but balance empty - Show alert
        if(livePlan && livePlan.balance) {
            let balanceIsEmpty = livePlan.balance && livePlan.balance.limit != -1 && (livePlan.balance.balance + livePlan.balance.limit <= 0);
            let balanceIsEnded = livePlan.balance.limit != -1 && (livePlan.balance.ends_at && moment(livePlan.balance.ends_at) < moment());
            alreadyHadPlan = true;
            
            if(balanceIsEmpty) {
                state = true;
                alertMessage = `Your Account's Balance of <i>${labelFirst}</i> is empty.`;
            } else if (balanceIsEnded) {
                state = true;
                alertMessage = `Your Account is out of <i>${labelFirst}</i> since <i>${livePlan.balance.ends_at}</i>.<br>`;
            }
        }

        // If account has current LivePlan but date is expire - Show alert
        if(livePlan && livePlan.subscription_end_date && moment(livePlan.subscription_end_date) < moment()) {

            isEnded = true;
            date = livePlan.subscription_end_date;
            
        } else if (livePlan && !livePlan.subscription_end_date && livePlan.next_billing_date && moment(livePlan.next_billing_date) < moment()) {

            isEnded = true;
            date = livePlan.next_billing_date;
        }

        if(livePlan && livePlan.is_trial && isEnded) {
            alertMessage = `Your free trial has ended.<br>`;
        }

        // If account has not current LivePlan but has lastPlan - Show alert
        if(!livePlan && lastPlan) {

            // In this case date is lastPlan.subscription_end_date || lastPlan.next_billing_date if lastPlan is defined
            if(lastPlan.subscription_end_date || lastPlan.next_billing_date) { date = lastPlan.subscription_end_date ?? lastPlan.next_billing_date }

            alertMessage = `Your Account is out of <i>${labelFirst}</i> since <i>${date}</i>.`;

            alertMessage += ` If you\'d like to activate your overconsumption feature to continue <i>${labelSecond}</i> until your next billing cycle, please contact support at <a target="_blank" href="mailto:support@inboxgeek.com">support@inboxgeek.com</a><br>`;

        }


        if(alertMessage) {

            state = true;

            alertMessage += alreadyHadPlan ? `Click <a href="/user/billing">here</a> to upgrade your plan or turn on Overconsumption.` : `Click <a href="/user/billing">here</a> to subscribe to a Plan.`;


            alert = {
                title: 'Uh, oh...',
                html: alertMessage,
                confirmButtonText: 'Got it!',
                showCloseButton: true,
                customClass: {
                    title: headerClass+ ' text-light',
                }
            }
        }
        
        return {state: state, alert: alert}
    }
}

export const services = {
    default: DefaultServiceHelper(axiosInstance),

    aweber: AweberHelper(axiosInstance),
    activecampaign: ActiveCampaignHelper(axiosInstance),
    blastable: BlastableHelper(axiosInstance),
    campaigner: CampaignerHelper(axiosInstance),
    convertkit: ConvertkitHelper(axiosInstance),
    emailoctopus: EmailOctopusHelper(axiosInstance),
    expertsender: ExpertsenderHelper(axiosInstance),
    hubspot: HubspotHelper(axiosInstance),
    inboxsuite: InboxSuiteHelper(),
    getresponse: GetResponseHelper(axiosInstance),
    keap: KeapHelper(axiosInstance),
    klaviyo: KlaviyoHelper(axiosInstance),
    mailchimp: MailchimpHelper(axiosInstance),
    ontraport: OntraportHelper(axiosInstance),
    sendlane: SendlaneHelper(axiosInstance),
    webhook: WebhookHelper(axiosInstance),
    mailwizz: MailwizzHelper(axiosInstance),
    mumara: MumaraHelper(axiosInstance),
    brevo: BrevoHelper(axiosInstance),
    inbox: InboxHelper(axiosInstance),
    gohighlevel: GoHighLevelHelper(axiosInstance),
    beehiiv:Beehiiv(axiosInstance),
    ongage: OngageHelper(axiosInstance)
};
