<template>
    <span>

        <span v-if="!options.length" class="badge badge-light mx-2">Not Defined</span>
        <span v-for="(option, i) in options" :key="i" class="mx-1">
            <span class="badge badge-info mx-2">
                {{ option.option_name }}
                <span v-if="option.min">Min: {{ option.min }} - </span>
                <span v-if="option.max">Max: {{ option.max }}</span>
            </span>

        </span>

    </span>
</template>

<script>
import { helper } from '@/helpers'
import { mapGetters } from 'vuex'


export default {
    name: 'DtLoDailyLimitOptions',
    props: ['row'],
    components: {
    },
    data() {
        return {
            options: []
        }
    },
    mounted() {
        this.loadOptions()
    },
    methods: {
        loadOptions() {
            let options = this.row.item.options;

            options.forEach((option) => {
                this.options.push(option);
            });
        },
    },
    computed: {
        ...mapGetters({
            user: 'Auth/user',
            isOwnerAccount: 'Auth/isOwnerAccount',
        }),
    }
}

</script>
