<template>
    <b-container fluid>
      <b-modal id="addSupportTicketCommentModal" 
        ref="addSupportTicketCommentModal" 
        :title="ticket && ticket.comment ? 'Update Ticket Comment' : 'Add Ticket Comment' + ' - ' + (ticket && ticket.subject ? ticket.subject : '--')" 
        size="xl" centered
        hide-footer @hidden="$emit('hide', null)" @show="comment = ticket ? ticket.comment : null">
  
        <b-form-group class="col-md-12">
  
          <b-row class="my-2">

            <!-- <b-col v-if="ticket" md="12">
              <label for="">Subject Support Ticket: *</label>
              <input type="text" class="form-control" :value="ticket.subject" readonly>
            </b-col> -->
  
            <b-col md="12" class="mt-3">
              <label for="">Comment: *</label>
  
              <VueEditor v-model="comment" class="w-100" />
  
              <small v-if="errors?.comment" class="text-danger">Support Comment is required</small>
            </b-col>
  
          </b-row>
        </b-form-group>
  
  
        <!-- Navigation buttons -->
        <hr>
  
        <div class="d-flex justify-content-end" style="gap:5px">
            <!-- <button class="btn btn-light" @click="$emit('cancel')">Cancel</button> -->
            <button class="btn btn-primary d-flex align-items-center " :disabled="!valid" @click="submit">
                {{ticket && ticket.comment ? 'Update Comment' : 'Add Ticket Comment'}}
            </button>
        </div>
  
      </b-modal>
    </b-container>
  </template>
  
  
  <script>
  
  import api from "@/api";
  import { mapGetters } from "vuex";
  import { VueEditor } from "vue2-editor";
  
  export default {
    name:'AddSupportTicketCommentModal',
    props:['ticket'],
    components:{
      VueEditor
  
    },
    data(){
      return{
        comment: null,
        errors:{
          comment:false,
        },
        onsubmit: false,
      }
    },
    methods:{
      submit(e) {
        e.preventDefault();
        this.onsubmit = true;
  
        if(!this.valid) {
          return;
        }
  
        let data = { comment: this.comment };
  
        api.supportTickets.put(this.ticket.id, data)
          .then(response => {
  
                console.log(response.data);
              
                this.$root.$emit('bv::hide::modal', 'addSupportTicketCommentModal')
                this.$swal('Ticket Comment', 'Ticket Comment update Successfully', 'success')
                // this.$root.$emit('create-ticket-message', response.data);
                
          }).catch(err => {
              console.log(err);
          })
          .finally(() => {
              this.onsubmit = false;
          })
      },
  
    },
    computed:{
      ...mapGetters('Auth', ['user', 'isAdmin', 'isClient']),
      currentStep(){
  
        // update current steps when the index is changed
  
        let current_step = null;
  
        this.steps
            .forEach((step)=>{
              if(this.current_step_index === step.index){
                current_step = step;
              }
            });
  
        return current_step;
      },
      valid(){
  
        return this.comment;
  
      }
    }
  }
  
  </script>  