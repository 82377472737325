<template>
    <li class="nav-item dropdown">
        <a href="#" class="search-toggle iq-waves-effect active" @click="goToTicketPage">
            <span class="ripple rippleEffect" style="width: 75px; height: 75px; top: 5.5px; left: -7.5px;"></span>
            <i class="ri-ticket-line"></i>
            <span v-if="tickets.length" class="bg-danger dots"></span>
            <!-- <span v-else class="badge badge-pill badge-primary badge-up count-mail">{{ allTickets }}</span> -->
        </a>
        <div class="iq-sub-dropdown iq-sub-dropdown-custom">
            <div class="iq-card shadow-none m-0">
                <div class="iq-card-body p-0 ">
                    <div class="bg-primary p-3">
                        <h5 class="mb-0 text-white">All Tickets<small class="badge  badge-light float-right pt-1">{{
                                allTickets }}</small></h5>
                    </div>
                    <router-link v-for="(ticket, index) in tickets" :key="index"
                        :to="{ name: 'chats.tickets', query: { id: ticket.id }}" class="iq-sub-card">
                        <div class="media align-items-center">
                            <div class="text-info">
                                <i class="ri-account-circle-fill fa-2x"></i>
                            </div>
                            <div class="media-body ml-3 text-dark">
                                <h6 class="mb-0">{{ ticket.subject }}</h6>
                                <p class="mb-0">{{ ticket.user.name }}</p>
                                <small class="float-right font-size-12">{{ getTicketDate(ticket, 'DD MMM') }}</small>
                            </div>
                        </div>
                    </router-link>
                    <router-link :to="{ name: 'chats.tickets' }" class="show-chats">
                        <strong>Go To Tickets Page</strong>
                    </router-link>
                </div>
            </div>
        </div>
    </li>
</template>

<script>
import api from '@/api/RestClient'
import {mapGetters} from 'vuex'
import moment from 'moment'
import Pusher from 'pusher-js';

export default {
    name: 'ChatNotifications',
    props: {},
    data() {
        return {
            tickets: [],
            allTickets: 0,
            msgMaxLength: 40
        }
    },
    mounted() {
        this.getTickets();

        // this.$root.$on('refresh:notificationEvents', () => {
        //     this.getChatsMessages();
        // });
    },
    methods: {
        getTickets() {

            api.default.get('/tickets/notifications')
            // api.default.get('/support_tickets/all')
                .then(response => {
                    this.tickets = response.data

                    this.allTickets = response.data.length;

                    // this.$root.$emit('ibg:refresh-ticket', this.tickets)

                    // const pusher  = new Pusher(process.env.VUE_APP_PUSHER_APP_KEY, {cluster: 'eu',});

                    // _.forEach(this.tickets, (ticket) => {
                        
                    //     const channel = pusher.subscribe('support.ticket.' + ticket.id);
                    //     channel.bind('message',  (data)=> {this.ticketSubmit(data); });

                    // })

                })

        },
        getTicketDate(ticket, format = 'YYYY-MM-DD') {
            
            const myDate = moment(ticket.created_at);

            const formattedDate = myDate.format(format);

            return formattedDate;

        },
        ticketSubmit(data) {
            console.log('ticketSubmit', data)
        },
        goToTicketPage($event) {
            if(!this.tickets.length) {
                $event.preventDefault();
                $event.stopPropagation();

                this.$router.push({ name: 'chats.tickets' })
            }
        }
    },
    computed: {
        ...mapGetters({
            isAdmin: 'Auth/isAdmin',
            isClient: 'Auth/isClient',
        }),
    },
}
</script>

<style>
.seen {
    font-weight: 500;
}

.show-all-notifications {
    display: block;
    text-align: center;
    padding: 10px;
}

.spot-seen {
    width: 15px;
    height: 14px;
    border-radius: 40px;
    position: absolute;
    right: 10px;
    top: 5px;
}

.text-status {
    position: absolute;
    right: 5px;
    top: 0px;
}

.iq-sub-dropdown .iq-card-body {
    padding-top: 0px !important;
}

.show-chats {
    display: block;
    text-align: center;
    padding: 10px;
}

.iq-sub-dropdown-custom {
    width: 360px !important;
    right: -9em !important;
}
</style>
