<template>
    <fieldset>
        <h3 class="text-left mb-4">{{ title }} </h3>


        <b-row class="text-left">
            <b-form-group class="col-md-12"
                :label="title" label-for="list">
                <div v-if="form.platform && ![platform_codes.OMNISEND, platform_codes.BEEHIIV,platform_codes.INBOXSUITE, platform_codes.WEBHOOK].includes(form.platform.esp.code)">
                    <multiselect v-model="list" tag-placeholder="Empty" :placeholder="listPlaceholder" label="name"
                        track-by="code" :options="formatLists" :multiple="false" return="code" :allow-empty="true"
                        :taggable="false" :disabled="listsDisabled">


                        <template slot="option" slot-scope="props">
                            <div class="option__desc">

                                <span class="option__title">

                                    <span v-if="props.option.type">

                                        <i v-if="props.option.type == 'segment'" class="ri-flashlight-line"
                                            style="color:#5ad"></i>
                                        <i v-else class="ri-list-check color-primary" style="color:#5ad"></i>

                                    </span>

                                    <span v-else><i class="ri-list-check color-primary" style="color:#5ad"></i></span>

                                    <span style="font-wight:bold;"> {{ props.option.name }} </span>

                                    <span style="font-size:11px; color:#888"> {{ props.option.code }}</span>


                                </span>

                            </div>

                        </template>


                    </multiselect>

                    <small v-if="errors.lists" ref="errors_lists" class="text-danger"
                        :class="{ 'd-none': !errors.lists }">{{
                            errors.lists
                        }}</small>
                </div>
                <div v-else-if="form.platform && [platform_codes.INBOXSUITE, platform_codes.WEBHOOK].includes(form.platform.esp.code)">
                    <!-- <b-form-input id="input-list" type="text" placeholder="source url" value="inboxgeek.com"
                        @input="inputList" required></b-form-input> -->

                    <b-form-group label-for="list_link">

                        <!-- Webhook -->
                        <b-input-group v-if="platform_codes.WEBHOOK == form.platform.esp.code" class="mt-2">
                            <b-form-input type="text" :value="form.platform.account" @change="inputListWebHook"
                                required :disabled="true"></b-form-input>

                            <b-input-group-append v-if="form.platform.account" class="cursor-pointer">
                                <b-input-group-text>
                                    <i class="ri-clipboard-line" @click.prevent="clickboardCopy(form.platform.account)"></i>
                                </b-input-group-text>
                            </b-input-group-append>
                        </b-input-group>

                        <!-- InboxSuite -->
                        <!-- <b-input-group v-if="platform_codes.INBOXSUITE == form.platform.esp.code" class="mt-2">
                            <b-form-input type="text" :value="form.platform.account" @change="inputListWebHook"
                                required :disabled="true"></b-form-input>

                            <b-input-group-append v-if="form.platform.account" class="cursor-pointer" :label="JSON.stringify(form.platform)">
                                <b-input-group-text>
                                    <i class="ri-clipboard-line" @click.prevent="clickboardCopy(form.platform.account)"></i>
                                </b-input-group-text>
                            </b-input-group-append>
                        </b-input-group> -->

                    </b-form-group>
                    <small v-if="errors.list_link" ref="errors_list_link" class="text-danger" :class="{ 'd-none': !errors.list_link }">
                        {{ errors.list_link }}
                    </small>

                </div>

              <div v-if="form.platform && [platform_codes.BEEHIIV].includes(form.platform.esp.code)">

                <BeehiivCustomFieldsInput :list="list" :customFields="formatLists" :platform="platform" @customFieldSelected="customFieldSelected"></BeehiivCustomFieldsInput>

                <small v-if="errors.lists" ref="errors_lists" class="text-danger"
                       :class="{ 'd-none': !errors.lists }">{{
                    errors.lists
                  }}</small>

              </div>
            </b-form-group>


            <!-- PLATFORM TAG -->
            <b-form-group class="col-md-12" v-if="['aweber', 'mailchimp'].includes(form.platform.name.toLowerCase())"
            :label="tagTitle" label-for="tag">
            <div>
                    <!-- LISTS OR TAGS -->
                <multiselect v-model="tag" :loading="isLoading" tag-placeholder="Empty" :placeholder="selectTagPlaceholder" label="name"
                        track-by="code" :options="tags" :multiple="false" return="code" :allow-empty="true"
                        :taggable="false" :disabled="is_disabled">


                        <template slot="option" slot-scope="props">
                            <div class="option__desc">

                                <span class="option__title">

                                    <span v-if="props.option.type">

                                        <i v-if="props.option.type == 'segment'" class="ri-flashlight-line"
                                            style="color:#5ad"></i>
                                        <i v-else class="ri-list-check color-primary" style="color:#5ad"></i>

                                    </span>

                                    <span v-else><i class="ri-list-check color-primary" style="color:#5ad"></i></span>

                                    <span style="font-wight:bold;"> {{ props.option.name }} </span>

                                    <span style="font-size:11px; color:#888"> {{ props.option.code }}</span>


                                </span>

                            </div>

                        </template>


                </multiselect>

                <small v-if="errors.tag" ref="errors_tags" class="text-danger"
                    :class="{ 'd-none': !errors.tag }">{{
                        errors.tag
                    }}</small>
                    <!-- END LISTS OR TAGS -->
            </div>

            </b-form-group>
            <!-- PLATFORM TAG -->
            <b-form-group class="col-md-12" v-if="form.platform && [platform_codes.OMNISEND].includes(form.platform.esp.code)"
                :label="tagTitle" label-for="tag">
                <div>
                    <multiselect id="range-1" v-model="list" tag-placeholder="Add this as new tag" placeholder="Add Tags" label="name" track-by="code" :options="formatLists" :multiple="false" :taggable="true" :allow-empty="false" :loading="omnisend_isLoading" @tag="tagInput"  ></multiselect>

                    <small v-if="errors.contacts_tag" ref="errors_list_link" class="text-danger" :class="{ 'd-none': !errors.contacts_tag }">
                        {{ errors.contacts_tag }}
                    </small>
                </div>
            </b-form-group>
        </b-row>

    </fieldset>
</template>
<script>
import Multiselect from 'vue-multiselect'
import { services, helper } from '@/helpers';
import { PLATFORMS_CODE } from "@/constantes";
import BeehiivCustomFieldsInput from '@/components/inboxgeek/fileds/BeehiivCustomFieldsInput';
import api from '@/api/RestClient'

export default {
    name: "StepIntegration",
    props: {
        form: {
            default: () => { },
        },
        platform: {
            default: () => { },
        },
        errors: {
            default: () => { },
        },
        showCols: {
            default: () => { },
        },
        tagPlaceholder: {
            default: () => 'Create tag',
        },
        selectTagPlaceholder: {
            default: () => 'Select tag',
        },
    },
    components: {
        Multiselect,
      BeehiivCustomFieldsInput
    },
    data() {
        return {
            isLoading: false,
            is_disabled: true,
            platform_codes: PLATFORMS_CODE,
            addNewInteg: true,
            listLabel: 'List: *',
            listPlaceholder: 'Select Lists',
            listsDisabled: false,
            list: null,
            lists: [],
            title: 'Select Your Reception List:',
            webhook: { listID: '', name: '', link: '' },

            tagSelected: null,
            tags: [],
            selectedtag: {},
            tagLabel: null,
            tagTitle: 'Select Your Tag *:',
            isDisabledTag: false,
            tag: null,
            omnisend_isLoading: false

        }
    },
    mounted() {

        this.list = this.form.list ?? [];
        this.showErrors();
        this.setWebHookList();

        if (this.form.isListOfTags) {
            this.title = 'Select Your Tag:';
            this.listPlaceholder = 'Select Tag';
        }

        if (this.platform_codes.WEBHOOK == this.form.platform.esp.code) {
            this.webhook.listID = this.create_UUID;
            this.title = 'Trigger Link';
        }
    },
    computed: {
        formatLists() {
            return helper.formatLists(this.form);
        },
        create_UUID() {
            let dt = new Date().getTime();
            let uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                let r = (dt + Math.random() * 16) % 16 | 0;
                dt = Math.floor(dt / 16);
                return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
            });
            return uuid;
        }
    },
    methods: {
      customFieldSelected(custom_field){
        console.log("custom_field",custom_field);
        if(custom_field){
          this.list = {
            code : custom_field.code,
            name : custom_field.name
          };
        }
      },
        showErrors() {
            const that = this
            _.forOwn(that.errors, function (error, key) {
                const ref = `errors_${key}`
                if (typeof that.$refs[ref] !== 'undefined') that.$refs[ref].classList.remove('d-none')
            });
        },
        resetErrors() {
            const that = this
            _.forOwn(this.errors, function (error, key) {
                const ref = `errors_${key}`
                if (typeof that.$refs[ref] !== 'undefined') that.$refs[ref].classList.add('d-none')
            });
        },
        inputList(input) {
            this.list = {
                code: input,
                name: input
            }
        },
        tagInput(tag) {

            if (tag) {
                this.omnisend_isLoading = true
                let code = tag;
                let platform_id = this.form.platform.id
                api.integrations.checkOmniTag(code, platform_id).then(response => {
                    let total = response.data.length;
                    if (total == 0) {
                        this.$swal('Error tag', `${tag ?? 'undefined'} tag doesn't exist`, 'error')
                    } else {
                        this.omnisend_tag.push({
                            'code': tag,
                            'name': tag,
                            'type': 'list'
                        });
                        this.list = this.omnisend_tag;
                    }
                    this.omnisend_isLoading = false;
                }).catch((error) => {
                    console.log(error);
                    this.$bvToast.toast(`Something want wrong`, {
                        title: 'Error',
                        variant: 'danger'
                    });
                    this.omnisend_isLoading = false;
                }).finally(() => {
                    //this.omnisend_isLoading = false;
                })
            } else {
                this.list = [];
            }
        },
        inputListWebHook(input) {

            let listID = this.create_UUID;
            if ((typeof this.form.platform.esp != 'undefined') && this.platform_codes.WEBHOOK == this.form.platform.esp.code) {
                if (this.form.platform.account) listID = this.form.platform.account;
            }


            if ((typeof this.form.platform.esp != 'undefined') && (this.platform_codes.WEBHOOK != this.form.platform.esp.code)) return;

            if ((typeof this.form.platform.esp != 'undefined')  && this.platform_codes.WEBHOOK == this.form.platform.esp.code) this.webhook.listID = listID;

            this.list = {
                code: this.webhook.listID, // replace By uuid
                name: this.webhook.name,
                link: this.form.platform.account,
            }

        },
        setReceptionList(list = null) {

            if (!list) {
                this.list = null;
                return;
            }

            const that = this
            let receptionList = _.find(this.formatLists, function (o) { return list.p_list_id == o.code; });

            if (receptionList) {
                this.list = receptionList;
            }
        },
        setWebHookList() {

            if (this.platform_codes.WEBHOOK == this.form.platform.esp.code && !this.form.list) {
                if (this.form.lists.length) {
                    let receptionList = this.form.lists[0];
                    let link = JSON.parse(receptionList.custom_columns) ? JSON.parse(receptionList.custom_columns).link : null;

                    this.webhook = {
                        listID: receptionList.p_list_id ?? this.create_UUID,
                        name: receptionList.list_name,
                        link: link
                    }
                    this.inputListWebHook();

                }
            } else if (this.form.list) {
                this.webhook = {
                    listID: this.form.list.code ?? this.create_UUID,
                    name: this.form.list.name,
                    link: this.form.list.link
                }
            }
        },
        clickboardCopy(text) {
            navigator.clipboard.writeText(text)
            this.$bvToast.toast('Reception List ID Copied', {
                title: 'Copy Reception List ID',
                variant: 'info'
            })
        },


        handleTag(data) {
            // this.isLoading = true;
            // this.$emit('onCheckTag', data);
        },
        handleAddTag(newTag) {
            // this.$emit('onAddTag', newTag)
        },
        getTags(list_id) {
            this.isLoading = true;
            let params = {
                'entities'      : 'tags',
                'platform_name' : this.form.platform.name.toLowerCase(),
                'platform_id': this.form.platform.id,
                'list_ids': list_id,
            };
            return api.platforms.fetchServices(params).then((response) => {
                this.tags = _.map(response.data, function (tag) {
                    return {
                        code: tag.id,
                        name: tag.name
                    }
                })
            }).catch((error) => {

            }).finally(() => {
                this.isLoading  = false;
                this.is_disabled =  false;
            });
        }
    },
    watch: {
        "errors": {
            handler(newVal, oldVal) {
                this.showErrors()
            },
            deep: true
        },
        "list": {
            handler(newVal, oldVal) {
                if (!_.isEmpty(newVal)) {
                    const list_id = newVal.code;
                    this.getTags(newVal.code);
                }
                this.$emit('updateList', newVal)
            },
            deep: true
        },
        "tag": {
            handler(newVal, oldVal) {
                this.$emit('updateTag', newVal)
            },
            deep: true
        },
    }
};
</script>
