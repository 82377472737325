export default function (axios) {
  return {
    list: (params = {}) => {
      return axios.get(`/integrations`, { params })
        .then(response => response.data);
    },
    get: (integrationId) => {
      return axios.get(`/integrations/${integrationId}`)
        .then(response => response.data);
    },
    post: (integrationData = {}) => {
      return axios.post('/integrations', integrationData)
        .then(response => response.data);
    },
    put: (integrationId, integrationData) => {
      return axios.put(`/integrations/${integrationId}`, integrationData)
        .then(response => response.data)
    },
    delete: async (integrationId, data) => {
      return axios.delete(`/integrations/${integrationId}`, { data: data })
        .then(response => response.data)
    },
    restore: (id) => {
      return axios.post(`/integrations/${id}/restore`, {})
        .then(response => response.data);
    },
    sendTestFlow: (integrationId, email) => {
      return axios.get(`integrations/${integrationId}/send-test-flow?email=${email}` )
        .then(response => response.data);
    },
    bulk: (bulkAction, bulkPost = {}) => {
      return axios.post('/integrations/bulk/' + bulkAction, bulkPost)
        .then(response => response.data);
    },
    bulkDailyLimit: (bulkData = {}) => {
      return axios.post('/integrations/bulk-daily-limit', bulkData)
        .then(response => response.data);
    },
    checkTag: (tagId, accountId) => {
      return axios.get(`integrations/account/${accountId}/tags/${tagId}/check`)
        .then(response => response.data);
    },
    checkOmniTag: (code, platform_id) => {
      return axios.get(`platforms/omnisend/tags/${code}/contacts?platform_id=${platform_id}`)
        .then(response => response.data);
    },
    schedule:(integrationId,schedule) => {
      return axios.put('/integrations/' + integrationId + '/schedule', schedule)
          .then(response => response.data);
    }
  }
}
