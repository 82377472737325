export default function (axios) {
  return {
    get: (params = {}) => {
      return axios.get(`/referrals`, { params })
        .then(response => response.data);
    },
    post: (data = {}) => {
      return axios.post('/referrals', data)
        .then(response => response.data);
    },
    put: (id, data) => {
      return axios.put(`/referrals/${id}`, data)
        .then(response => response.data)
    },
    delete: (id) => {
      return axios.delete(`/referrals/${id}`)
        .then(response => response.data)
    },
  }
}
