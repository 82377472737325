<template>
    <iq-card>
        <template v-slot:headerTitle>
            <h4 class="card-title">{{ chart.title }}</h4>
        </template>
        <template v-slot:headerAction>
            <ul class="nav nav-pills">
                <li v-for="(item, index) in menus" :key="index" class="nav-item">
                    <a href="#" :v-model="week_or_month" v-if="menuItem(item)" class="nav-link" :class="nav_class(item)"
                        @click.prevent="getStatsByPeriod(item)">{{ item.name }}</a>
                </li>
            </ul>
        </template>
        <template v-slot:body>
            <div class="libolo">
                <b-row class="mt-2" v-if="showOrganization">
                    <b-col md="7" v-if="[-1, 0].includes(week_or_month)">
                        <b-row>
                            <b-col md="5" class="col-md-10px">
                                <b-form-group label="From" label-for="from">
                                    <b-form-input id="from" type="date" v-model="form_dates.from" :max="form_dates.to"
                                        :value="form_dates.from"></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col md="5" class="col-md-10px">
                                <b-form-group label="To" label-for="to">
                                    <b-form-input id="to" type="date" v-model="form_dates.to" :min="form_dates.from"
                                        :max="form_dates.max" :value="form_dates.to"></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col md="2" class="col-md-10px">
                                <b-form-group label="To" label-for="submit">
                                    <b-button class="action" variant="primary" :disabled="submitload" @click="onSubmit">
                                        <b-spinner small type="grow" v-if="submitload"></b-spinner>
                                        <i v-if="!submitload" title="Submit" class="fa fa-paper-plane m-0"></i>
                                    </b-button>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-col>
                    <b-col :md="organizationSelectBox">
                        <b-form-group label="Select Organization">
                            <multiselect v-model="organization" deselect-label="Can't remove this value" track-by="name"
                                label="name" placeholder="Select organization" :options="organizations" :searchable="true"
                                :allow-empty="true">
                            </multiselect>
                        </b-form-group>
                    </b-col>

                </b-row>
            </div>
            <div class="dashboard">
                <b-spinner small type="grow" v-if="loader"></b-spinner>
                <div class="d-flex justify-content-around mt-4" v-if="!loader">
                    <div v-for="(item, index) in indicators" :key="index" class="price-week-box mr-1">
                        <span>{{ item.title }}</span>
                        <h2><span class="counter">{{ formatDecimal(item.indicator) }}</span>
                        </h2>
                    </div>
                </div>
                <div v-if="!loader">
                    <ApexChart v-if="!loader" :element="slug" :chartOption="chart.bodyData" />
                </div>
            </div>
        </template>
    </iq-card>
</template>

<script>
const TOTAL_ORGANIZATIONS = 1;
const ACTIVE_AND_INACTIVE_ORGANIZATIONS = 2;
const ORGANIZATION_PER_SUBSCRIPTION = 3;
const CANCELED_ORGANIZATION = 4;
const EVENTS = 5;
const REVENUE_STATS = 6;
const GROUP_BY_MONTH = 2;
const GROUP_BY_WEEK = 1;
const STATUS_NULL = 1;
const STATUS_NOT_NULL = -1;

const groupBy = require('lodash/groupBy');
const moment = require('moment');
function arrayMax(arr) {
    var len = arr.length,
        max = -Infinity;
    while (len--) {
        if (Number(arr[len]) > max) {
            max = Number(arr[len]);
        }
    }
    return max;
};

function tooltipLabelFormatter(val, categories) {
    let index = val - 1;
    if (window.PERIOD == GROUP_BY_MONTH) return categories[index];
    let label = categories[parseInt(index)];
    let year = label.split('-')[0];
    let week = label.split(' ')[1];
    let weekRange = getWeekRange(week, year);
  
    return  categories[index] + ' (' + weekRange.startDay + ' to ' + weekRange.endDay +')';
}

function getWeekRange(weekNo, yearNo) {
    let firstDayofYear = new Date(yearNo, 0, 1);

    if (firstDayofYear.getDay() > 4) {
        let weekStart = new Date(yearNo, 0, 1 + (weekNo - 1) * 7 - firstDayofYear.getDay() + 8);
        let weekEnd = new Date(yearNo, 0, 1 + (weekNo - 1) * 7 - firstDayofYear.getDay() + 8 + 5);
        return { startDay: formatDateYMD(weekStart), endDay: formatDateYMD(weekEnd) }
    }
    else {
        let weekStart = new Date(yearNo, 0, 1 + (weekNo - 1) * 7 - firstDayofYear.getDay() + 1);
        let weekEnd = new Date(yearNo, 0, 1 + (weekNo - 1) * 7 - firstDayofYear.getDay() + 1 + 5);
        return { startDay: formatDateYMD(weekStart), endDay: formatDateYMD(weekEnd) }
    }
}


function formatDateYMD(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}
import api from '@/api/RestClient'
import Multiselect from "vue-multiselect"
import AccountsMultiselect from "@/views/children/AccountsMultiselect.vue";
import { helper } from '@/helpers'

export default {
    name: 'ApexDashCharts',
    props: {
        options: {
            type: Object,
            default: null
        }
    },
    components: {
        Multiselect,
        AccountsMultiselect
    },
    mounted() {
        this.setOption();
        this.chartKey();
        this.getStats();

        this.setActiveOganization();
        this.setOrganization();
        window.component = this;
    },
    created() {
        this.initDates();
    },
    methods: {
        formatDecimal(nStr) {
            nStr += '';
            var x = nStr.split('.');
            var x1 = x[0];
            var x2 = x.length > 1 ? '.' + x[1] : '';
            var rgx = /(\d+)(\d{3})/;
            while (rgx.test(x1)) {
                x1 = x1.replace(rgx, '$1' + ',' + '$2');
            }
            return x1 + x2;
        },
        setTitle() {
            if (this.option) {
                this.chart.title = this.option.title;
            }
        },
        setXaxisType() {
            if (this.option) {
                this.chart.bodyData.xaxis = {
                    type: this.option.axis_type
                }
            }
        },
        chartElement() {
            if (this.option) {
                this.slug = this.option.element;
                this.chart.bodyData.yaxis = {
                    min: 0,
                    max: this.getMaxSerie(this.chart.bodyData.series),
                    tickAmount: 5,
                }
            }
        },
        chartKey() {
            if (this.option) {
                this.keyy = this.option.key;
                this.day_week_month = this.option.period;
            }
        },
        setOrganization() {
            if ([EVENTS, REVENUE_STATS].includes(this.option.key)) this.showOrganization = true;
        },
        setOption() {
            if (this.options) {
                this.option = this.options
            }
        },
        setWeekOrMonth(val = 0) {
            this.week_or_month = val;
        },
        arrayMax(arr) {
            return arr.reduce(function (p, v) {
                return (p > v ? p : v);
            })
        },

        subArray(serie, interval = 10) {
            let n;
            let lastElement;
            if (serie.length > interval) {
                lastElement = serie[serie.length - 1];
                n = serie.length - interval;
                serie = serie.slice(n, -1);
                serie.push(lastElement);
            }
            return serie;
        },
        defineLabels(series) {
            let dataLabel = [];
            let labels = [];
            let dataLabels = [];
            let statsByYear = groupBy(series, (dt) => moment(dt.created_at).year());
            let years = _.keys(statsByYear);
            let year;
            let keys;
            let _labels = []
            let statByPeriod = _.map(statsByYear, function (y) {
                if (window.PERIOD == GROUP_BY_MONTH) {
                    return groupBy(y, (dt) => moment(dt.created_at).month());
                }
                return groupBy(y, (dt) => moment(dt.created_at).week());
            })
            for (let i = 0; i < statByPeriod.length; i++) {

                year = years[i];
                keys = _.keys(statByPeriod[i]);
                dataLabel = _.map(keys, function (k) {
                    k = parseInt(k) + 1;
                    _labels.push(k + '-' + year);
                    if (window.PERIOD == GROUP_BY_MONTH) {
                        return year + '-' + window.MONTHS[k - 1];
                    }
                    return year + '-Week ' + k;
                });
                dataLabels.push(dataLabel);
            }
            labels = this.subArray(dataLabels.flat());
            this.chart.bodyData.labels = labels;
            this.labelKeys = _labels;
            return labels;
        },
        totalObjectArray(array = []) {
            let sum = 0;
            for (let index = 0; index < array.length; index++) {
                sum += array[index].length;
                
            }
            return sum;
        },
        defineSeries(series, name = '***', isCumulus = true) {

            let dataSeries = [];
            let dataSerie = [];
            let newSeries = [];
            let sum = 0;
            let cumul = 0;
            let serie = []
            let item = {}
            let indicator = {}
            let statByPeriod;
            
            dataSeries = [];
            dataSerie = [];
            sum = 0;
            cumul = 0;
            serie = []
            item = {}
            indicator = {}

            let statsByYear = groupBy(series, (dt) => moment(dt.created_at).year());
            let years = _.keys(statsByYear);
            statByPeriod = _.map(statsByYear, function (y) {
                if (window.PERIOD == GROUP_BY_MONTH) {
                    return groupBy(y, (dt) => moment(dt.created_at).month());
                }
                return groupBy(y, (dt) => moment(dt.created_at).week());
            })

            for (let i = 0; i < statByPeriod.length; i++) {
                dataSerie = _.map(statByPeriod[i], function (y) {
                    return y.length;
                });
                dataSeries.push(dataSerie);
            }
            for (let index = 0; index < this.labelKeys.length; index++) {
                newSeries[index] = 0;
            }
            statByPeriod.forEach((stat, k) => {
                let year = years[k];
                Object.keys(stat).forEach((k, i) => {
                    let key = parseInt(k) + 1;
                    let total = (Object.values(stat)[i]).length
                    let searchVal = key + '-' + year;

                    this.labelKeys.forEach((labelKey, index) => {
                        if (labelKey == searchVal) {
                            newSeries[index] = total;
                        }
                    });
                });
                
            });
            serie = newSeries;
            
            if (isCumulus) {
                sum = 0;
                serie = serie.map(x => sum += x);
                cumul = this.arrayMax(serie)
            } else cumul = serie[serie.length - 1]
           serie = this.subArray(serie);

            item = {
                "name": name,
                "type": "area",
                "data": serie
            }

            indicator = {
                "title": name,
                "indicator": cumul
            }

            this.indicators.push(indicator);
            this.chart.bodyData.series.push(item);

            return this.chart.bodyData.series;
        },
        getStats() {
            const url = this.option.url;
            const period = this.option.period;
            const component = this.option.key;
            const account = this.organization;

            this.request(url, period, component, account).then(response => {
                this.statsData = [];
                this.resetData();
                window.PERIOD = this.week_or_month;
                window.MONTHS = this.MONTHS;
                let series = component == 3 ? response['subscriptions'] : response;
                let dataSeries = [];
                let labelSeries = [];
               
                // generate labels
                this.defineLabels(series)
                let compt = _.filter(
                    this.COMPONENTS, function (o) {
                        return o.code == component;
                    }
                );
                let conditions = compt[0].conditions;
                if (component == 3) {
                    this.COMPONENTS[component - 1].conditions.length = 0;
                    response['plans'].forEach(plan => {
                        let pln = {
                                column: 'plan_id', value: plan.id, title: plan.name, isCumulus: true
                        }
                        this.COMPONENTS[component - 1].conditions.push(pln);
                    });
                }
                conditions.forEach(condition => {
                    dataSeries = _.filter(
                        series, function (o) {
                            if (condition.column == 'deleted_at' && condition.value == STATUS_NULL) {
                                return o[condition.column] == null;
                            } else if (condition.column == 'deleted_at' && condition.value == STATUS_NOT_NULL) {
                                return o[condition.column] != null;
                            } else return o[condition.column] == condition.value;
                        }
                    );
                    //generate series
                    this.defineSeries(dataSeries, condition.title, condition.isCumulus)
                })
            }).catch(err => {
                console.log(err)
            });
        },
        request(url, interval, index, account = null) {
            const stats = new Promise((resolve, reject) => {
                const account_id = account ? '&account_id=' + account.value : '';
                let link = url + '/' + interval + '?c=' + index + account_id
                if (index == 5 && [-1, 0].includes(this.week_or_month)) {
                    link += '&from=' + this.form_dates.from + '&to=' + this.form_dates.to
                }
                if(typeof this.options.service !== 'undefined') {
                    link += '&service='+this.options.service
                }

                api.dashboard.statistics(link).then(response => {
                    resolve(response);
                }).catch(err => {
                    reject(err)
                }).finally(() => {
                    this.submitload = false;
                    this.loader = false;
                });
            });
            return stats;
        },
        resetData() {
            this.indicators = [];
            this.chart.bodyData.series = [];
            this.chart.bodyData.labels = [];
        },
        setActiveOganization() {
            if ([EVENTS, REVENUE_STATS].includes(this.option.key)) {
                api.dashboard.accounts().then(response => {
                    const accountData = response.data;
                    const accounts = response.data;
                    const organizations = accounts.map(item => {
                        const container = {};

                        container.name = item.name;
                        container.value = item.id;

                        return container;
                    })
                    this.organizations = organizations;
                });
            }
        },
        getMaxSerie(series = []) {
            if (!series) return 0;
            let elts = [];
            series.forEach(serie => {
                elts.push(arrayMax(serie.data))
            });
            return arrayMax(elts);
        },

        onSelectAccount(accountSelected) {
            if (store.getters['Auth/isAdmin']) {
                if (accountSelected) { } else { }
            }
        },
        initDates() {

            var date = helper.dateToYMD(new Date());
            var minDate = helper.dateToYMD(helper.AddOrSubractDays(new Date(), 19, false));

            this.form_dates.to = date;
            this.form_dates.from = minDate;
            this.form_dates.max = date;
        },
        onSubmit() {
            this.submitload = true;
            this.loader = true;
            this.resetData();
            this.getStats();
        },
        menuItem(item) {
            return item.components.includes(this.options.key);
        },
        nav_class(item, index) {
            return item.code == this.options.period ? 'active' : '';
        },
        getStatsByPeriod(item) {
            const period = item.code;
            this.setWeekOrMonth(period)
        }
    },
    watch: {
        'statsData': {
            handler(newValue, oldValue) {
                this.setXaxisType();
                this.setTitle();
                this.chartElement();
                this.chartKey();
            }
        },
        'week_or_month': {
            handler(newValue, oldValue) {
                switch (newValue) {
                    case -1:
                    case 0:
                        this.organizationSelectBox = 4
                        break;

                    default:
                        this.organizationSelectBox = 12
                        break;
                }
                this.loader = true;
                this.resetData();
                this.option.period = newValue;
                this.getStats();
            }
        },
        'organization': {
            handler(newValue, oldValue) {
                this.loader = true;
                this.resetData();
                this.getStats();
            }
        }
    },
    data() {
        return {
            chart: {
                title: '***',
                type: 'mixes',
                dot: 1,
                bodyData: {
                    chart: {
                        height: 350,
                        type: 'line',
                        stacked: false
                    },
                    stroke: {
                        width: [3, 3, 3, 3, 3, 3, 3, 3, 3],
                        curve: 'smooth'
                    },
                    plotOptions: {
                        bar: {
                            columnWidth: '50%'
                        }
                    },
                    colors: ['#00ca00', '#0084ff', '#FEB019', '#5A2A27', '#FD6A6A', '#662E9B', '#D7263D', '#C5D86D', '#4CAF50'],
                    series: [],
                    fill: {
                        opacity: [0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5],
                        gradient: {
                            inverseColors: false,
                            shade: 'light',
                            type: 'vertical',
                            opacityFrom: 0.85,
                            opacityTo: 0.55,
                            stops: [0, 100, 100, 100]
                        }
                    },
                    labels: [],
                    markers: {
                        size: 0
                    },
                    xaxis: {
                        type: 'category',
                        interval: 4,
                        tickAmount: 10
                    },
                    yaxis: {
                        min: 0,
                        max: 25,
                        tickAmount: 9
                    },
                    tooltip: {
                        shared: true,
                        intersect: false,
                        y: {
                            formatter: function (y) {
                                if (typeof y !== 'undefined') {
                                    return y.toFixed(0)
                                }
                                return y
                            }
                        },
                        x: {
                            formatter: function (x) {
                                if (typeof x !== 'undefined') {
                                    return tooltipLabelFormatter(x.toFixed(0), this.categoryLabels)
                                }
                                return tooltipLabelFormatter(x, this.categoryLabels)
                            }
                        },
                    },
                    legend: {
                        labels: {
                            useSeriesColors: true
                        },
                        markers: {
                            customHTML: [
                                function () {
                                    return ''
                                },
                                function () {
                                    return ''
                                },
                                function () {
                                    return ''
                                }
                            ]
                        }
                    }
                },
            },
            showOrganization: false,
            indicators: [
                {
                    "title": "Total",
                    "indicator": 226
                }
            ],
            title: '***',
            slug: null,
            keyy: null,
            loader: true,
            labels: [],
            week_or_month: -1,
            option: null,
            statsData: null,
            organization: null,
            organizations: [],
            y_week_month: null,
            form_dates: {
                from: null,
                to: null
            },
            submitload: false,
            organizationSelectBox: 5,
            menus: [
                {
                    code: 0,
                    name: 'Days',
                    components: [5, 6] // components see definition on top
                },
                {
                    code: 1,
                    name: 'Weeks',
                    components: [1, 2, 3, 4, 5] // components see definition on top
                },
                {
                    code: 2,
                    name: 'Months',
                    components: [1, 2, 3, 4, 5, 6]// components see definition on top
                },
                {
                    code: 3,
                    name: 'Years',
                    components: [6] // components see definition on top
                },
            ],
            MONTHS: [
                'Jan',
                'Feb',
                'Mar',
                'Apr',
                'May',
                'Jun',
                'Jui',
                'Aug',
                'Sep',
                'Oct',
                'Nov',
                'Dec'
            ],
            COMPONENTS: [
                {
                    code: 1,
                    conditions: [
                        { column: 'status', value: 1, title: 'Active' },
                    ]
                },
                 {
                    code: 2,
                    conditions: [
                        { column: 'status', value: 1, title: 'Active', isCumulus: true },
                        { column: 'status', value: 0, title: 'Inactive', isCumulus: true },
                        { column: 'status', value: 1, title: 'Signup', isCumulus: false },
                    ]
                },
                {
                    code: 3,
                    conditions: [
                    ]
                },
                 {
                    code: 4,
                    conditions: [,
                        { column: 'status', value: 0, title: 'Inactive', isCumulus: true },
                    ]
                }
            ],
            labelKeys: []
        }
    }
}
</script>

<style>
.dashboard {
    text-align: center;
    margin-bottom: 5px;
}

.libolo {
    width: 50%;
    margin: auto;
}

.col-md-10px {
    position: relative;
    width: 100%;
    padding-right: 10px;
    padding-left: 10px;
}

@media screen and (max-width: 600px) {
    .price-week-box {
        margin-right: 0.5rem !important;
    }

    .libolo {
        width: 90% !important;
    }
}</style>
