<template>
    <b-col md="12">
        <iq-card>
            <template v-slot:headerTitle>
                <h4>{{ item.title }}</h4>
            </template>
            <template v-slot:body>
                <MorrisChart :element="item.type" :type="item.type" :xKeys="item.xKeys" :data="item.bodyData"
                    :colors="item.colors" :yKeys="item.yKeys" :labels="item.labels" :xLabels="item.xLabels" />
            </template>
        </iq-card>
    </b-col>
</template>
<script>
export default {
    name: 'ibgMrx',
    props: {
        item: {
            type: Object,
            default: {}
        },
    },
    mounted() {
        this.showOrHideChart();
    },
    data() {
        return ({
            showChart: false,
        })
    },
    methods: {
        showOrHideChart(val) {
            this.showChart = val;
        }
    },
    watch: {
    }
}
</script>
