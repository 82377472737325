<template>
    <b-container fluid>


        <!-- User Interface controls -->
        <b-row class="mb-3">

            <b-col lg="4" class="my-1">
                <b-input-group size="md">
                    <b-form-input id="filter-input" v-model="filter" type="search" placeholder="Search"></b-form-input>
                </b-input-group>
            </b-col>

        </b-row>

        <!-- Main table element -->
        <b-table ref="table" :id="id" striped bordered :busy="isBusy" :items="getData" :fields="fields"
            :current-page="currentPage" :per-page="perPage" :filter="filter" :filter-included-fields="filterOn"
            :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" stacked="md" show-empty
            small @filtered="onFiltered" @sort-changed="sortingChanged">

            <template #thead-top="data">
            <b-tr>
              <b-th colspan="2" variant="success"><span class="sr-only">Name and ID</span></b-th>
              <b-th colspan="3" variant="secondary">Referrals</b-th>
              <b-th colspan="2" variant="primary">Commissions</b-th>
              <b-th colspan="2" variant="danger">Revenue</b-th>
            </b-tr>
          </template> 

            <template #table-busy>
                <div class="text-center d-inline-block my-2">
                    <span class='fa-stack fa-lg'>
                        <i class='fa fa-spinner fa-spin fa-stack-2x fa-fw'></i>
                    </span>&emsp;Processing ...
                </div>
            </template>

            <template #empty="scope">
                <p class="mb-1">No matching records found</p>
            </template>

            <!-- Cell type -->
            <template #cell(type)="row">
                <div class="badge"
                    :class="{ 'badge-info': row.item.type == 'amount', 'badge-primary': row.item.type == 'percentage' }">
                    {{ row.item.type }}
                </div>
            </template>

        </b-table>

        <!-- DataTable pagination -->
        <b-row class="justify-content-between mt-3">
            <b-col md="9" class="my-1">
                <div class="dataTables_info">
                    <b-row class="dataTables_info justify-content-between" id="lists_info" role="status"
                        aria-live="polite">
                        <b-col md="10" class="">
                            Showing {{ from }}
                            to {{ to }} of {{ totalRows }} entries
                        </b-col>
                        <b-col md="2" class="">
                            <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm">
                            </b-form-select>
                        </b-col>
                    </b-row>
                </div>
            </b-col>
            <b-col md="3" class="my-1">
                <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm"
                    class="my-0"></b-pagination>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import Spinner1 from '@/components/loaders/spinner1'
import store from '@/store/index'
import api from '@/api/RestClient'
import { helper } from '@/helpers'
export default {
    name: 'DtDiscounts',
    components: {
        Spinner1,
    },
    data() {
        return {
            id: 'dt-discounts',
            isBusy: false,
            items: [],
            fields: [
                {
                    key: 'id',
                    label: 'Name',
                    sortable: true,
                    formatter: (value, key, item) => {
                        return item.name
                    },
                    sortDirection: 'desc',
                    class: 'text-left'
                },
                {
                    key: 'name',
                    label: 'Joined',
                    sortable: true,
                    sortDirection: 'desc',
                    class: 'text-center',
                    formatter: (value, key, item) => {
                        var dt = new Date(item.created_at);
                        return helper.formatDate(dt)
                    },
                },
                {
                    key: 'visitors',
                    label: 'Visitors',
                    sortable: true,
                    sortDirection: 'desc',
                    class: 'text-center',
                },
                {
                    key: 'leads',
                    label: 'Leads',
                    sortable: true,
                    sortDirection: 'desc',
                    class: 'text-center',
                },
                {
                    key: 'conv',
                    label: 'Conv',
                    sortable: true,
                    sortDirection: 'desc',
                    class: 'text-center',
                },
                {
                    key: 'Unpaid',
                    label: 'Unpaid',
                    sortable: true,
                    sortDirection: 'desc',
                    class: 'text-center',
                    formatter: (value, key, item) => {
                        let total = this.total(item.accounts);
                        let total_due = Math.round((total * item.commission) / 100, 2)
                        let total_paid = this.calculateSum(item.commissions, 'total');
                        total = total_due - total_paid;
                        return helper.moneyDefaultFormat(total);
                    }
                },
                {
                    key: 'paid',
                    label: 'Paid',
                    sortable: true,
                    sortDirection: 'desc',
                    class: 'text-center',
                    formatter: (value, key, item) => {
                        let total = this.calculateSum(item.commissions, 'total');
                        return helper.moneyDefaultFormat(total);
                    }
                },
                {
                    key: 'gross',
                    label: 'Gross',
                    sortable: true,
                    sortDirection: 'desc',
                    class: 'text-center',
                    formatter: (value, key, item) => {
                        let total = this.total(item.accounts);
                        return helper.moneyDefaultFormat(parseFloat(total));
                    },
                },
                {
                    key: 'net',
                    label: 'Net',
                    sortable: false,
                    class: 'text-center',
                    formatter: (value, key, item) => {
                        let total = this.total(item.accounts);
                        let total_due = Math.round((total * item.commission) / 100, 2)
                        total = total - total_due;
                        return helper.moneyDefaultFormat(total);
                    },
                },

            ],
            totalRows: 1,
            currentPage: 1,
            perPage: 25,
            pageOptions: [25, 50, 100, {
                value: 100,
                text: "Show a lot"
            }],
            sortBy: 'created_at',
            sortDesc: true,
            sortDirection: 'asc',
            filter: null,
            filterOn: ["name"],
            account: -1,
            dates: null,
            $search: '',
            from: null,
            to: null,
        }
    },
    computed: {
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return {
                        text: f.label,
                        value: f.key
                    }
                })
        }
    },
    mounted() {
        this.loadFields()
    },
    methods: {
        calculateSum(array, property) {
            const total = array.reduce((accumulator, object) => {
                return accumulator + object[property];
            }, 0);

            return total;
        },
        total(data) {
             let total = 0;
            if (data.length > 0) {
                let accounts = data;
                accounts.forEach(account => {
                    if (account.revenue_stats.length > 0) {
                        let revenue_stats = account.revenue_stats;
                        revenue_stats.forEach(revenue_stat => {
                            total += revenue_stat.revenue;
                        });
                    }
                });
            }
           
            return `${total}`
        },
        async getData(ctx) {
            await this.delay();
            return await this.fetchData(ctx);
        },
        async fetchData(ctx) {

            var items = []
            this.items = [];
            this.isBusy = true;
            let queryParams = {}
            if (typeof ctx !== "undefined") {
                queryParams = {
                    search: this.filter,
                    page: ctx.currentPage,
                    per_page: ctx.perPage,
                    sort_by: ctx.sortBy,
                    sort_desc: ctx.sortDesc,
                    filter_on: JSON.stringify(this.filterOn),
                }
            }

            return api.referrals.get(queryParams)
                .then((response) => {
                    items = response.data.data;
                    this.totalRows = response.data.total
                    this.currentPage = response.data.current_page
                    this.perPage = response.data.per_page
                    this.items = items
                    this.from = response.data.from
                    this.to = response.data.to
                    return items
                })
                .catch((err) => {
                    console.log(err)
                    return []
                })
                .finally(() => {
                    setTimeout(() => {
                        this.isBusy = false;
                        this.$root.$emit('updatedTable');
                    }, 400);
                })
        },
        delay: () => {
            return new Promise(resolve => setTimeout(resolve, 2000));
        },
        loadFields() {
            let fields = _.map(this.fields, (item) => {
                if (store.getters['Auth/isAdmin']) {
                    return item;
                } else
                    if (!["account"].includes(item.key)) return item;
            })
            this.fields = fields;
        },

        // Filter
        onFiltered(filteredItems) {
        },
        sortingChanged(ctx) {
            this.currentPage = 1
        },
        restore(item) {
            this.$swal({
                title: 'Are you sure?',
                text: 'You can\'t revert your action',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes Delete it!',
                cancelButtonText: 'No, Keep it!',
                showCloseButton: true,
                showLoaderOnConfirm: true
            }).then((result) => {
                const id = item.id;

                if (result.value && parseInt(id) > 0) {

                    api.discounts.restore(id)
                        .then(response => {
                            let varian = response.success ? 'success' : 'danger';
                            this.$bvToast.toast('Discount Restore', varian, response.message);
                            if (response.success) this.$root.$emit('bv::refresh::table', this.id);
                        })
                        .catch(error => {
                            let errors = helper.formatErrorResponse(error)
                            console.log(errors)
                            this.$bvToast.toast('message', {
                                title: 'Error',
                                variant: 'Something went wrong!'
                            })
                        })
                }
            })
        },
        copyDiscountCode(e, item) {

            if (!item || !item.discount_code) return

            navigator.clipboard.writeText(item.discount_code)

            this.$bvToast.toast('Discount Code Copied', {
                title: 'Copy Discount Code',
                variant: 'info'
            })
        },
        edit(item) {
            this.$emit('handleEdit', item)
        },
        remove(item) {
            this.$swal({
                title: 'Are you sure?',
                text: 'You can\'t revert your action',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes Delete it!',
                cancelButtonText: 'No, Keep it!',
                showCloseButton: true,
                showLoaderOnConfirm: true
            }).then((result) => {
                if (result.value) {
                    api.referrals.delete(item.id).then(response => {
                        let varian = response.success ? 'success' : 'danger';
                        this.toast('Delete referral', varian, response.message);
                        if (response.success) this.$root.$emit('bv::refresh::table', this.id);
                    })
                } else {
                    this.$swal('Cancelled', 'Your file is still intact', 'info')
                }
            })
        },
        toast(title = "success", variant = "success", message = "Discount added") {
            this.$bvToast.toast(message, {
                title: title,
                variant: variant
            })
        },
    },
    watch: {}
}
</script>

<style>
td.lists>div,
td.integration>div {
    width: 10em;
}

td.tag>div,
td.account>div {
    width: 8em;
}

.user-flex {
    justify-content: center;
}
</style>
