export default function (axios) {
  return {
    getUserSettings: (userId, nickname = null) => {
      return axios.get(`/user_settings/user/${userId}${(nickname ? '/'+nickname : '')}`)
        .then(response => response.data)
    },
    saveUserSettings: (userId, data) => {
      return axios.post(`/user_settings/user/${userId}`, data)
        .then(response => response.data)
    },

  }
}
