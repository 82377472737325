<template>
    <nav class="iq-sidebar-menu iq-sidebar-footer position-absolute w-100" :class="horizontal ? 'd-xl-none' : ''" style="bottom: 7em;">
        <List :items="helpMenu" :open="true" :horizontal="horizontal" />
    </nav>
</template>

<script>
import List from '@/components/sofbox/menus/ListStyle1.vue'

export default {
    name: 'HelpMenu',
    props: {
        horizontal: { type: Boolean }
    },
    components: {
        List
    },
    data() {
        return {
            helpMenu: [
                {
                    "title": "Help",
                    "name": "help.section",
                    "is_heading": false,
                    "is_heading_admin": false,
                    "is_heading_client": true,
                    "is_active": false,
                    "link": "",
                    "class_name": "text-primary",
                    "is_icon_class": true,
                    "icon": "ri-chat-1-line",
                    "children": [
                        {
                            "title": "Portal Guide",
                            "name": "help.guide",
                            "is_active": false,
                            "is_heading_admin": false,
                            "is_heading_client": true,
                            "link": "//help.inboxgeek.com",
                            "class_name": "",
                            "is_icon_class": true,
                            "attr_target": "_blank",
                            "icon": "ri-layout-right-2-line"
                        },
                        {
                            "title": "Integration How-to",
                            "name": "help.integration",
                            "is_active": false,
                            "is_heading_admin": false,
                            "is_heading_client": true,
                            "link": "//help.inboxgeek.com/lo-integration-guides/",
                            "class_name": "",
                            "is_icon_class": true,
                            "attr_target": "_blank",
                            "icon": "ri-list-settings-line"
                        },
                        {
                            "title": "FAQ's",
                            "name": "help.faqs",
                            "is_active": false,
                            "is_heading_admin": false,
                            "is_heading_client": true,
                            "link": "//help.inboxgeek.com/frequently-asked-questions/",
                            "class_name": "",
                            "is_icon_class": true,
                            "attr_target": "_blank",
                            "icon": "ri-question-line"
                        },
                        {
                            "title": "My Tickets",
                            "name": "help.support",
                            "is_active": false,
                            "is_heading_admin": false,
                            "is_heading_client": true,
                            "link": "/my-tickets",
                            "class_name": "",
                            "is_icon_class": true,
                            "attr_target": null,
                            "icon": "ri-ticket-line"
                        }
                    ]
                }
            ]
        }
    }
}
</script>
