<template>
    <b-container fluid class="mt-4 mt-md-0">
            <b-row class="row">
                <b-col lg="12" md="12">
                    <b-tabs :pills="true" class="nav-fill" id="pills-chart">

                        <b-tab :active="true" id="accounts-tab-fill" href="#accounts-tab"
                            ariaControls="accounts-tab" role="tab" :ariaSelected="true" title="Accounts" class="mb-3">
                            
                            <b-row class="my-3">
                                <Accounts/>
                            </b-row>
                            
                        </b-tab>

                        <b-tab :active="false" id="manage-users-tab-fill" href="#manage-users-tab"
                            ariaControls="manage-users-tab" role="tab" :ariaSelected="false" title="Manage Users"
                            class="mb-3">

                            <b-row class="my-3">
                                <UserList/>
                            </b-row>

                        </b-tab>

                    </b-tabs>
                </b-col>
            </b-row>
    </b-container>
</template>
<script>
import { sofbox } from '@/config/pluginInit'
import Accounts from '@/views/Account/Accounts'
import UserList from '@/views/User/UserList'

export default {
    name: 'AccountTabs',
    components: {
        Accounts,
        UserList,
    },
    mounted() {
        sofbox.index();
    },
}
</script>