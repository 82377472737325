<template>

  <div class="w-100">

    <b-row class="my-2">
      <b-col>
        <label for="">API Key/Token :</label>
        <b-input v-model="platform.apikey" placeholder="Api key"></b-input>
      </b-col>
    </b-row>


    <b-row>
        <div class="col">
            <div
                class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color">
                <div class="custom-switch-inner">
                    <label class="mr-2">Sending domain</label>
                    <input type="checkbox" class="custom-control-input bg-primary"
                        v-model="custom.checkbox" id="blastableSwitch" @click="cleanError()">
                    <label class="custom-control-label" for="blastableSwitch" data-on-label="On"
                        data-off-label="Off">
                    </label>
                </div>
            </div>
            <b-form-input v-show="custom.checkbox" v-model="platform.account" type="text" id="blastable-link"
                name="blastable-link" placeholder="example.com" :disabled="!custom.checkbox">
            </b-form-input>
            <small ref="alertCustomApiLink" id="alertCustomApiLink" class="text-danger d-none"></small>
        </div>
    </b-row>
         
  
    <b-row>
      <b-col class="text-right mt-2">
        <button :disabled="!isAllowed" class="btn btn-primary" @click.prevent="createPlatform"> <b-spinner v-if="isLoading" small type="grow"></b-spinner>  Create Platform</button>
      </b-col>
    </b-row>
  
  
  
  </div>
  
  
  </template>
  
  <script>
  
  import api from '@/api/RestClient'


    export default {
      name: 'BlastableInput',
      components: {
      },
      props: ['platform_nickname'],
      mounted(){
        
      },
      
      data(){
        return {
          isDisabled:false,
          isLoading:false,

          custom : {
            checkbox : false,
          },  
          platform: {
            account:null,
            apikey:null
          }
        }
      },
      methods: {
        createPlatform(){

          this.isLoading = true;
          this.isDisabled = true;

            api.platforms
          .verifyToken('blastable',this.platform.account,this.platform.apikey)
          .then(()=>{
           
            this.$emit('createPlatform',this.platform);
            this.isLoading = false;
          }).catch((error)=>{
            this.isLoading = false;
            this.isDisabled = false;
            let swal = {
              icon: 'error',
              title: 'Failed',
              text: 'Failed to authenticate, please check your credentials and try again',
              type: 'error',
              confirmButtonText: 'OK',
              showCloseButton: true,
            }

            this.$swal(swal)

          });
        }
      },
        computed : {
            isAllowed(){
              return ( this.platform_nickname != null && this.platform_nickname.trim() != '' ) && ! this.isDisabled ; 
            }
        }
  
    }
  
  </script>