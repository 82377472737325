<template>
  <iq-card class="p-2">
    <template v-slot:headerTitle>
      <h4 class="card-title">
        <i class=""></i> Support Chat Questions
      </h4>
    </template>

    <template v-slot:headerAction>

      <div class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color mx-3">

        <label class="my-auto" for="chat-question-suggestion">Question suggestions</label>

        <div class="custom-switch-inner my-auto pt-1">
            <input type="checkbox" data-toggle="tooltip" data-placement="top" title="Active Status"
                id="chat-question-suggestion"  
                name="chat-question-suggestion" 
                data-id="chat-question-suggestion" 
                data-name="chat-question-suggestion" 
                v-model="chatQuestionsState" 
                @input="updateSupportChatQuestionState($event.target)"
                class="custom-control-input bg-primary">

            <label for="chat-question-suggestion" data-on-label="ON" data-off-label="OFF" class="custom-control-label"></label>

        </div>
    </div>

      <button class="btn btn-primary" v-b-modal.modal-add-edit-question :disabled="isLoading">
        <b-spinner small type="grow" v-show="isLoading"></b-spinner>
        Add Question
      </button>

    </template>

    <template v-slot:body>

      <DataTable v-if="renderDT" ref="chat_questions-table" :url="'/chat_questions'"
        v-bind="{ columns: columns, customCells: dtCells }" @onSwitch="handleSwitchAttr" @onClick="handleBtn" />

    </template>

    <b-modal id="modal-add-edit-question" ref="modal-add-edit-question" :title="modal.item ? modal.title : 'Add Chat Question'" size="lg" centered
      hide-footer class="bg-secondary p-4" :no-close-on-backdrop="true" @show="initModal" @hidden="resetForm()">

      <b-form @submit="handleSubmit" class="px-2">
        <div class="row py-2">
          <b-col class="my-2" md="12">
            <label class="" for="parent-question">
              <b-form-checkbox v-model="form.has_parent" name="check-button" button-variant="primary" size="md" switch @change="">
                Has Parent Question
              </b-form-checkbox>
            </label>

            <b-form-group v-if="form.has_parent" label="Select Parent Question">
                <multiselect v-model="form.parent" deselect-label="" 
                  track-by="code" 
                  label="question" 
                  placeholder="Select parent question" 
                  :options="questions" 
                  :searchable="true" :allow-empty="true" :loading="isLoading" :disabled="isLoading || onsubmit">
                </multiselect>

                <small v-if="errors?.parent" ref="errors_parent" class="text-danger" :class="{ 'd-none': !errors.parent }">Parent is require</small>

            </b-form-group>


          </b-col>

          <b-col class="my-2" md="12">
            <label for="question">Question</label>

            <b-form-input v-model="form.question" type="text"></b-form-input>

            <small v-if="errors?.question" ref="errors_question" class="text-danger" :class="{ 'd-none': !errors.parent }">Question is require</small>
          </b-col>

          <b-col class="my-2" md="12">
            <label for="question">Response</label>

            <b-textarea v-model="form.response" placeholder="Response"></b-textarea>

            <small v-if="errors?.response" ref="errors_response" class="text-danger" :class="{ 'd-none': !errors.response }">Response is require</small>
          </b-col>
        </div>

        <div class="row">
          <div class="col text-right p-3">
            <b-button variant="primary" type="submit" :disabled="isLoading || onsubmit">
              <b-spinner small type="grow" v-show="onsubmit"></b-spinner>
              {{ !modal.item ? 'Add Question' : 'Update Question'}}
            </b-button>
          </div>
        </div>
      </b-form>
    </b-modal>

  </iq-card>
</template>


<script>

import _ from 'lodash'
import api from "@/api";
import { helper } from '@/helpers';
import Multiselect from 'vue-multiselect'
import DataTable from '@/components/inboxgeek/tables/DataTable.vue'
import IBGSwitchCell from '@/components/inboxgeek/tables/IBGSwitchCell.vue'
import DtSupportChatQuestionActions from '@/views/Apps/children/DtSupportChatQuestionActions.vue';


export default {
  name: "SupportChatAutoQuestion",
  props: {
    supportChatQuestion: {
        type: [Object],
        default: () => null
    },
  },
  components: {
    DataTable,
    Multiselect
  },
  data() {
    return {
      isLoading: false,
      onsubmit: false,
      form: {
        has_parent: false,
        parent: null,
        question: null,
        response: null,
      },
      questions: [],
      errors: { parent: false, question: false, response: false },
      modal: {
        title: '',
        item: null,
      },
      renderDT: true,
      dtCells: [
        {
          key: 'status',
          component: IBGSwitchCell,
          event: 'onSwitch',
          props: {
              attribute: 'status',
          }
        },
        {
					key: 'actions',
					component: DtSupportChatQuestionActions,
					event: 'onClick',
				}
      ],
      chatQuestionsState: null
    }
  },
  mounted() {
    this.getSupportChatQuestionsState();
  },
  methods: {
    getQuestions() {
      this.isLoading = true;

      api.supportChatQuestions.getQuestions()
        .then((response) => {
          this.questions = response.data.map((question) => {
            if(this.modal?.item && this.modal?.item?.id == question.id) return;

            return {
              'code':  question.id,
              'question': question.question
            }
          }).filter(item => item !== undefined);

        })
        .catch((error) => {
          console.error(err);
          // handle error
          if (error.response.data.errors) {
            let title = 'Oops ...';
            this.$swal(title, error.response.data.errors.message, "error");
          }
        })
        .finally(() => {
          if(this.modal.item && this.modal.item?.parent_id) {
            const PARENT = _.find(this.questions, (o) => { return o.code == this.modal.item.parent_id });

            if(PARENT) {
              this.form.has_parent = true;
              this.form.parent = PARENT;
            } else {
              this.form.has_parent = false;
              this.form.parent = null;
            }
          }

          this.isLoading = false
        })
    },
    handleSubmit(e) {
      e.preventDefault();

      this.onsubmit = true;

      let message = 'Something went wrong!', title = 'Error!', success = 'danger', icon = 'error';


      // Check form Validation
      if (this.formIsValid()) {

          const data = this.form;

          let code = null;
          let apiAction = null;

          if (typeof this.modal.item !== 'undefined' && this.modal.item?.id) {
              code = this.modal.item.id;
              apiAction = api.supportChatQuestions.put(code, data);
          } else {
              apiAction = api.supportChatQuestions.post(data);
          }

          // send ajax query and interpret result for trigger next step
          apiAction.then((response) => {

              icon = 'success'
              title = 'Success'
              message = response.message
              success = 'success'

              this.$root.$emit('bv::refresh::table', this.$refs['chat_questions-table'].id);
                
              this.$root.$emit('bv::hide::modal', 'modal-add-edit-question');


          })
              .catch((error) => {
                  // handle error
                  if (error.response.data.errors) {
                    let title = 'Oops ...';
                    this.$swal(title, error.response.data.errors.message, "error");
                  }
              })
              .finally(() => {
                  this.$swal({
                      title: title,
                      icon: icon,
                      text: message,
                      type: success,
                      showCancelButton: false,
                      showCloseButton: true,
                  }).then((result) => {
                      helper.delay(3000).then(() => {
                          this.onsubmit = false;
                      });
                  })
              })

      } else {

          helper.delay(300).then(() => {
              this.showErrors();
          })

          this.onsubmit = false;
      }

    },
    showErrors() {
        const that = this
        _.forOwn(that.errors, function (error, key) {
            const ref = `errors_${key}`
            if (typeof that.$refs[ref] !== 'undefined') that.$refs[ref].classList.remove('d-none')
        });
    },
    resetErrors() {
        this.errors = {parent: false, question: false, response: false};

        const that = this
        _.forOwn(this.errors, function (error, key) {
            const ref = `errors_${key}`
            if (typeof that.$refs[ref] !== 'undefined') that.$refs[ref].classList.add('d-none')
        });
    },
    formIsValid() {

      this.resetErrors();

      if(!this.form.question) {
        this.errors.question = true;
      }

      if(!this.form.response) {
        this.errors.response = true;
      }

      if(this.form.has_parent && !this.form.parent) {
        this.errors.parent = true;
      }

      return (!this.errors.parent && !this.errors.question && !this.errors.response);
;
    },
    handleSwitchAttr(data) {

			if (!data.input || !data.row || !data.key) {
				return
			}

			let itemCode = data.row.id
			let form = {}
			form[data.key] = data.input.checked ? 1 : 0

			let toast = { message: 'Something went wrong!', title: 'Error', variant: 'danger' }


			return api.supportChatQuestions.put(itemCode, form)
				.then(result => {
					toast = { message: result?.message, title: 'Success', variant: 'success' }
				})
				.catch((error) => {
					data.input.checked = !data.input.checked;
					let errors = [];
					toast.message = '';

				})
				.finally(() => {
					this.$root.$bvToast.toast(toast.message, toast);
				});
    },
    initModal() {

      if(this.modal.item) {
        this.form = {
          has_parent: this.modal.item.parent_id ? true : false,
          parent: null,
          question: this.modal.item.question,
          response: this.modal.item.response,
        }
      }

      this.getQuestions();

    },
    resetForm() {
      this.modal.item = null;

      this.form = {
        has_parent: false,
        parent: null,
        question: null,
        response: null,
      }
    },
    handleBtn(data) {

      if (!data.event || !data.row) {
        return
      }

      let action = data.event.title.toLowerCase();

      if (action == 'edit') {
        this.handleEditSupportChatQuestion(data.row)
      }

      if (action == 'delete') {
        this.handleDeleteSupportChatQuestion(data.row)
      }

      helper.delay(2000).then(() => {
        if (data.ref) {
          data.ref.setState(false);
        }
      });

    },
    handleEditSupportChatQuestion(item) {
			if (!item || !item.id) {
				return
			}


			this.modal.title = `Edit Support Chat Question - ${item.question}`
			this.modal.item = item

      setTimeout(() => {
        this.$root.$emit('bv::show::modal', 'modal-add-edit-question')
      }, 500)

		},
    handleDeleteSupportChatQuestion(item) {
			if (!item || !item.id) {
				return
			}

      this.$swal({
          title: "Are you sure to delete Support Chat Question?",
          text: 'Deleting this item will dissociate it from all of its child elements.',
          icon: 'warning',
          confirmButtonText: "Yes, please delete",
          cancelButtonText: "Cancel",
          showCancelButton: true,
          showCloseButton: true,
          showLoaderOnConfirm: true,
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-secondary'
          },
      }).then((result) => {
          if (result.isConfirmed) {

            api.supportChatQuestions.delete(item.id)
              .then((response) => {

                this.$swal('Support Chat Question', response.message, "success");
                this.$root.$emit('bv::refresh::table', this.$refs['chat_questions-table'].id);

              })
              .catch((error) => {

                // handle error
                if (error.response.data.errors) {
                  let title = 'Oops ...';
                  this.$swal(title, error.response.data.errors.message, "error");
                }

              })

          }
      })

		},
    getSupportChatQuestionsState() {

      api.settings.getSettingStatus('support_chat_questions')
        .then(response => {

          const data = response.data;

          if (data.status === 1) {
            this.chatQuestionsState = true;
          }

        });
    },
    updateSupportChatQuestionState(input) {

			let state = input.checked ? 1 : 0;

      api.settings.updateAppSettingStatus('support_chat_questions', state)
        .then(response => {

          const data = response.data;

          if (data.status === 1) {
            this.chatQuestionsState = true;
          } else {
            this.chatQuestionsState = false;
          }

					this.$root.$bvToast.toast(response.message, {title: 'Success', variant: 'success'});

        }).catch((error) => {
					input.checked = !input.checked;
					// toast.message = '';
					this.$root.$bvToast.toast('Something went wrong!', {title: 'Warning', variant: 'danger'});

				});
    }
  },
  computed: {
    columns() {
      let columns = [
        { label: 'Question', key: 'question', class: 'text-left', sortable: true, sortDirection: 'desc' },
        { label: 'Response', key: 'response', class: 'text-left', sortable: true, sortDirection: 'desc' },
        { label: 'Parent Question', key: 'parent.question', class: 'text-center', sortable: false, sortDirection: 'desc' },
        { label: 'Status', key: 'status', class: 'text-center', sortable: true, sortDirection: 'desc' },
        { label: 'Actions', key: 'actions', class: 'text-center', sortable: false, sortDirection: 'desc' },
      ];

      return columns;
    },
  },
  watch: {
    "form.has_parent": {
      handler(newValue, oldValue) {
        if(!newValue) {
          this.form.parent = null;
        }
      },
      deep: true
    }
  }
}

</script>

<style scoped>
</style>