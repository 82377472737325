<template>
  <b-modal :id="id" :ref="id" title="Add New Source" ok-title="Next" cancel-title="Previous" size="xl" @show="initModal"
    @ok="handleNext" @cancel="handleBack">

    <b-row>
      <b-col lg="12" sm="12">
        <iq-card v-if="renderModal">
          <template v-slot:body>
            <b-form id="form-wizard2" class="mt-4">
              <ul id="top-tab-list" class="d-flex justify-content-center p-0">

                <li v-for="(item, index) in steps" :key="index" :id="item.name" class=""
                  :class="{ 'active': item.active, 'done': item.done }">
                  <b-link href="javascript:void();">
                    <i :class="item.icon"></i><span>{{ item.title }}</span>
                  </b-link>
                </li>

              </ul>

              <!-- Dynamic call Current step component -->
              <div>

                <component :is="currStepComponent" ref="current-step"
                  v-bind="{ form, errors, btnAdd: false, title: 'Well done!', isConnected: isConnected }"
                  @onAddPlatform="cleanPlatformStep" @platformSelected="handlePlatformSelect"
                  @updatePlatform="selectFromAdd" @updateAccount="updateAccount" @onCustomLink="handleCustomLink"
                  @onNewPlatform="handlePlatformAdd" @updateApiKey="handleApiKey" @updateList="form.list = $event"
                  @onFinish="onFinishStep">
                </component>

              </div>
              <!-- End Dynamic call Current step component -->

              <!-- END Add Setting Modal-->

            </b-form>
          </template>
        </iq-card>
      </b-col>
    </b-row>

    <template #modal-footer="{ ok, cancel }">

      <button class="btn mx-2 btn-secondary" v-if="btnBackChoise" :disabled="isLoading">
        Choose an existing platform <i class="ri-arrow-go-back-line"></i>
      </button>

      <button v-if="currStep > 0 && currStep !== steps.length - 1" v-b-modal.modal-close_visit class="btn btn-dark"
        @click="cancel" :disabled="isLoading">
        Previous
      </button>

      <button v-if="currStepComponent == 'PartialPlatform' && showSkip" v-b-modal.modal-close_visit class="btn btn-light"
        @click="skipStep()" :disabled="isLoading">
        <b-spinner small type="grow" v-show="isLoading"></b-spinner>
        Skip
      </button>

      <!-- <b-button
        v-if="isLastStep() && showIntegrationLiveLeads && (isSubscribeLiveOpen || isAdmin) && hasPlatform"
        variant="primary" @click="showStepIntegration">Create liveOpens integration</b-button> -->

      <!-- BTN Add Automation for current source -->
      <b-button v-if="isLastStep()" variant="primary" @click.prevent="showModalAutomation">Create LiveLeads Automation</b-button>


      <button v-if="!hideNextBtn && currStep !== steps.length - 1" class="btn btn-primary"
        :class="{ 'btn-primary': (currStep < steps.length - 1), 'btn-dark': currStep == steps.length - 1 }" @click="ok"
        :disabled="isLoading">

        <b-spinner small type="grow" v-show="isLoading"></b-spinner>
        {{ 'Next' }}
      </button>

      <button class="btn mx-2 btn-secondary" v-if="currStep == steps.length - 1" @click="hideModal">
        Done
      </button>

    </template>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import store from '@/store/index'
import api from '@/api/RestClient'
import Multiselect from 'vue-multiselect'
import PartialName from './partials/Name.vue'
import PartialFinish from './partials/Finish.vue'
import PartialPlatform from './partials/Platform.vue'
import PartialIntegration from './partials/Integration.vue'
import PartialIntegrationLiveLead from '../children/IntegrationLiveLead.vue'
import _ from 'lodash';
import { services, helper } from '@/helpers';
import { PLATFORMS_CODE, OAUTH_ESP, SERVICES } from "@/constantes";
import { customRef } from 'vue'

const CURRENT_SUBSCRIPTION = 1;
const SUBSCRIPTION_ACTIVE = 1;

export default {
  name: 'GeModalAdd',
  components: {
    Multiselect,
    PartialName,
    PartialFinish,
    PartialPlatform,
    PartialIntegration,
    PartialIntegrationLiveLead,
  },
  props: ['id', 'organization'],
  computed: {
    ...mapGetters({
      isAdmin: 'Auth/isAdmin',
      isClient: 'Auth/isClient',
      user: 'Auth/user',
    })
  },
  mounted() {
    this.currentPlan(this.user.account_id)
  },
  data() {
    return {
      isLoading: false,
      renderModal: true,
      btnBackChoise: false,
      hideNextBtn: false,
      currStep: 0,
      currStepComponent: null,
      oautESP: OAUTH_ESP,
      is_skip: false,
      form: this.resetForm(),

      steps: [
        { name: 'website-tab', title: 'Source', component: 'PartialName', active: true, done: false, fields: ['name', 'website'], icon: 'ri-pages-fill' },
        // { name: 'platform-tab', title: 'Platform', component: 'PartialPlatform', active: true, done: false, fields: ['platform_code', 'account_id', 'api_key'], icon: 'ri-apps-fill' },
        // { name: 'integration-tab', title: 'Reception List', component: 'PartialIntegration', active: true, done: false, fields: ['list'], icon: 'ri-play-list-add-fill' },
        { name: 'finish-tab', title: 'Script', component: 'PartialFinish', active: false, done: false, fields: [], icon: 'ri-file-copy-2-line' },
      ],
      errors: {},
      p_records: [],
      showSkip: true,
      hasPlatform: false,
      showIntegrationLiveLeads: true,
      liveLeadSource: null,
      isConnected: false,
      isSubscribeLiveOpen: false,
      cookiePolicyText: "Don’t get caught out of compliance. Not sure you’re prepared?" +
          '<br><a class="text-danger" href="https://help.inboxgeek.com/liveleads-compliance/" target="_blank">Learn more here</a>.'
    }
  },

  methods: {
    initModal() {
      this.hideNextBtn = false;
      this.isLoading = false;
      this.currStep = 0;
      this.currStepComponent = this.steps[this.currStep].component;
      this.isConnected = false;

      this.steps.forEach(element => {
        element.active = false
        element.done = false
      });

      this.steps[0].active = true
      this.form = this.resetForm()
      this.resetModal()
      this.resetErrors();
    },
    resetForm() {
      return {
        name: '',
        website: '',
        source: '',
        platform_code: null,
        platform: {},
        account_id: '',
        api_key: null,
        list: null,
        lists: [],
        custom: { isVisible: null, checkbox: null, link: null },
        isListOfTags: false,
        has_suppression_lists:false,
        suppression_lists:[],
        duplicateByAccount: true,
      }
    },
    resetModal() {
      this.renderModal = false;

      this.$nextTick(() => {
        this.renderModal = true;
      })
    },
    updateFieldsRequirePlatform() {

      const CURR_STEP_COMP = _.find(this.steps, function (o) { return o.name == 'platform-tab'; });
      this.isLoading = false;
      if (this.currStepComponent == 'PartialPlatform') {
        if (CURR_STEP_COMP.fields.includes('account_id')) CURR_STEP_COMP.fields.pop('account_id')
      } else {

        if (!CURR_STEP_COMP.fields.includes('account_id')) CURR_STEP_COMP.fields.push('account_id')
      }

    },
    async handleNext(e) {

      e.preventDefault()

      if (this.formIsValid() && (this.currStepComponent == 'PartialPlatform' || this.currStepComponent == "PartialName")) {
        // this.goToNextStep()
        // console.log('Here test abort process'); return;
      }

      this.isLoading = false;
      this.showSkip = true;

      if (this.isAdmin && this.organization) {
        this.form.organization = this.organization
      }

      if (this.currStepComponent == 'PartialPlatform') {
        this.updateFieldsRequirePlatform()
      }


      if (!this.formIsValid()) {
        this.isLoading = false;
        await helper.delay(300).then(() => this.$refs['current-step'].showErrors())
        if (this.currStepComponent == 'PartialPlatform') {
          this.$swal('Please Select or Create Platform', 'Please select a Platform or Add New to Continue. You can Skip this step!', 'warning')
        }
        return
      }


      if ((this.currStep + 1) < this.steps.length - 1) {

        this.handleCheckForm()
          .then((result) => {

            if (Array.isArray(result.data)) {
              this.form.lists = result.data
            }

            this.callbackCheckForm(result)

            this.isLoading = false;
          })
          .catch((error) => {
            this.isLoading = false
          })

      } else if ((this.currStep + 1) === this.steps.length - 1) {

        this.handleSubmit()

      } else {
        this.initModal()
      }

    },
    handleBack(e) {

      e.preventDefault()

      this.showSkip = true;

      const STEP = this.steps[this.currStep]

      if (this.currStep > 0) {
        this.currStep--
        this.currStepComponent = this.steps[this.currStep].component

        STEP.active = false
        this.steps[this.currStep].done = false
      }

    },
    skipStep() {
      this.hideNextBtn = true;


      if (this.currStepComponent == 'PartialPlatform') {
        //this.goToNextStep()
        this.is_skip = true;
        this.handleSubmit()
      }
    },
    cleanPlatformStep() {


    },
    showStepIntegration() {
      this.$emit('showIntegrationModal', this.form);
    },
    showModalAutomation() {
      this.$emit('showAutomationModal', this.liveLeadSource.id);
    },
    handlePlatformSelect(data) {

      this.isLoading = true;
      this.showSkip = false;

      const CURR_COMP = this.$refs['current-step'];

      let esp_platform = _.find(CURR_COMP.platforms, function (o) {
        return o.name == data.name;
      });

      this.form.platform_code = esp_platform.code
      this.form.account_id = data.account
      this.form.api_key = data.key ?? null
      this.form.platform = esp_platform


      if (typeof esp_platform === 'object' && typeof esp_platform.code !== 'undefined') {

        this.form.platform_recorded = data

        let _data = { ...data, code: esp_platform.code, img: esp_platform.img }
        if (!data.api_key) _data.api_key = this.form.api_key

        if (esp_platform.code == PLATFORMS_CODE.INBOXSUITE) {
          this.isLoading = false;
          return;
        }

        if ([PLATFORMS_CODE.WEBHOOK].includes(esp_platform.code)) {
          this.isLoading = false;
          this.form.lists = [];
          // this.showSkip = true;
          return;
        }

        return this.fetchLists(_data)
          .then((response) => {

            if (response.success) {

              if (esp_platform.code == PLATFORMS_CODE.CONVERKIT) response.data = response.data.tags;
              if (esp_platform.code == PLATFORMS_CODE.BLASTABLE) response.data = response.data.data.records;

              this.lists = services.default.formatLists(response.data, esp_platform)
              this.selectFromAdd(data)
              this.form.lists = response.data

              this.isLoading = false;
            }

          })
          .catch((err) => {
            this.$swal('Checking Api Failed', 'Error or this Api is not valid', 'error')
            this.isLoading = false
            this.form.lists = []
            this.showSkip = true;
            return []
          })

      }

    },
    selectFromAdd(value) {

      let esp_platform = value;
      const CURR_COMP = this.$refs['current-step'];
      this.form.platform_code = null;

      if (typeof esp_platform.id !== 'undefined') {
        this.form.platform_code = esp_platform.id;
      }

    },
    updateAccount(data) {

    },
    handleCustomLink(custom) {
      this.form.custom = custom
    },
    handlePlatformAdd(value) {
    },
    handleApiKey(value) {
    },
    async handleCheckForm() {


      let result = { valid: true, data: [] };

      /* Step list check validity */

      if (this.currStepComponent == 'PartialPlatform') {

        /* Get list for next step */

        let platform_recorded = this.form.platform_recorded ?? null

        if (!this.formIsValid()) {
          result.false = false
          this.$swal('Select Platform or Add New', 'Please Add Platform or Select once to continue!', 'warning')
          return new Promise((resolve, reject) => reject(result));
        }

        if (platform_recorded) {
          let fetchLists = async () => {

            return await this.loadPlatformLists(platform_recorded)
              .then((response) => {

                result.valid = true
                if (Array.isArray(response)) {
                  this.form.lists = response
                  const currComponent = this.$refs['current-step']
                  let esp_platform = _.find(currComponent.platforms, function (o) { return _.lowerCase(o.name) == _.lowerCase(platform_recorded.name); });
                  this.form.isListOfTags = [PLATFORMS_CODE.ONTRAPORT].includes(esp_platform.code) ? true : false;
                  result.data = response
                }

              })
              .catch((error) => {
                this.$swal('Checking Api Failed', 'Error or this Api is not valid', 'error')
                result.valid = false
                this.isLoading = false
                return new Promise((resolve, reject) => reject(result));
              })

          }

          await fetchLists()
        }

      }

      return new Promise(resolve => resolve(result));
    },
    resetErrors() {
      this.errors = {}
    },
    formIsValid() {
      let fields = this.steps[this.currStep].fields
      this.resetErrors();

      if (this.currStepComponent == 'PartialName') {
        if (!this.form.name || !this.form.name.trim()) {
          this.errors.name = 'Name is required.';
        }
        if (!this.form.website || !this.form.website.trim()) {
          this.errors.website = 'Website is required.';
        }
        if (!helper.isValidURL(this.form.website.trim())) {
          this.errors.website = 'Please insert valid URL Website.';
        }

        if(this.form.has_suppression_lists && this.form.suppression_lists.length === 0){
          this.errors.suppression = 'Please upload a suppression list.';
        };

        if(this.form.duplicateByType == "recurrent" && (!this.form.recurrent || !this.form.recurrent.trim())) {
          this.errors.recurrent = 'Recurrent is required.';
        }
      }

      if (this.currStepComponent == 'PartialPlatform') {


        if (!this.form.platform_code) {
          this.errors.platform_code = 'Platform is required.';
        }



      }

      if (this.currStepComponent == 'PartialIntegration') {
        if (PLATFORMS_CODE.WEBHOOK == this.form.platform_code) {
          let listNameNotValid = (!this.form.list || (this.form.list && (!this.form.list.name || typeof this.form.list.name == 'undefined' || this.form.list.name.trim() == ''))) && !this.is_skip;
          let listLinkNotValid = (!this.form.list || (this.form.list && (!this.form.list.link || typeof this.form.list.link == 'undefined' || this.form.list.link.trim() == ''))) && !this.is_skip;

          if (listNameNotValid) this.errors.list_name = 'List is required.';

          if (listLinkNotValid) this.errors.list_link = 'Link is required.';
        }
      }

      return helper.isEmpty(this.errors);
    },
    callbackCheckForm() {

      // Update by plaform selection selection
      if (this.currStepComponent == 'PartialPlatform') {

        const POST_PLATFORM = this.getPostPlatform()


        // manage oauth case with POST_PLATFORM const

      }

      // Trigger next
      this.goToNextStep()

    },
    async goToNextStep() {
      // await helper.delay(1500)

      const STEP = this.steps[this.currStep]

      this.currStep++
      this.currStepComponent = this.steps[this.currStep].component

      STEP.done = true
      this.steps[this.currStep].active = true


      this.isLoading = false;

    },
    getPostPlatform() {
      return {
        name: this.form.platform.name,
        account: this.form.account_id,
        key: this.form.api_key,
        organization: this.isAdmin ? this.organization : null
      }
    },
    isValidJson(value) {

      if (typeof value == "undefined") {
        return null
      }

      if (/^[\],:{}\s]*$/.test(value.replace(/\\["\\\/bfnrtu]/g, '@').
        replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']').
        replace(/(?:^|:|,)(?:\s*\[)+/g, ''))) {
        let token = JSON.parse(value)

        if (typeof token.accessToken !== 'undefined') token = token.accessToken
        else if (typeof token.access_token !== 'undefined') token = token.access_token

        if (typeof token.errors !== 'undefined' || typeof token.error !== 'undefined') {
          return null
        }

        return token
      }

      return value
    },

    // Fetch and Post
    async fetchLists(platform) {


      let esp_code = platform.code

      let customUrl = null


      this.form.custom.link = null


      if (this.form.custom && (typeof this.form.custom.checkbox !== 'undefined')) {
        if (this.form.custom.checkbox) customUrl = this.form.custom.link
      }

      // const token = this.isValidJson(platform.key) ?? platform.key;

      let options = {
        entity: [PLATFORMS_CODE.ONTRAPORT].includes(platform.code) ? 'tags' : 'lists',
        // token: token,
        account: platform.account,
        organization: platform.account_id,
        platform_id: platform.id,
        customUrl: customUrl
      }


      let _params = services.default.getListParamsByEspAndPlatform(platform, this.form.platform_recorded, options)

      return api.platforms.fetchServices(_params)
    },
    loadPlatformLists(data) {
      const currComponent = this.$refs['current-step']


      let esp_platform = _.find(currComponent.platforms, function (o) { return o.name == data.name; });
      this.form.platform = esp_platform


      if (typeof esp_platform === 'object' && typeof esp_platform.code !== 'undefined') {
        this.form.platform_recorded = data
        this.form.platform = esp_platform.code
        this.form.account_id = data.account
        this.form.api_key = data.api_key

        let _data = { ...data, code: esp_platform.code, img: esp_platform.img }
        if (!data.api_key) _data.api_key = this.form.api_key

        return this.fetchLists(_data)

          .then((response) => {

            if (response.success) {


              if (this.form.platform.code == PLATFORMS_CODE.CONVERKIT) response.data = response.data.tags;
              if (this.form.platform.code == PLATFORMS_CODE.BLASTABLE) response.data = response.data.data.records;

              this.lists = services.default.formatLists(response.data, esp_platform)
              this.selectFromAdd(data)

              return response.data
            }
          })
          .catch((err) => { return [] })
      }
    },
    handleSubmit() {

      this.isLoading = true;

      let message = 'Something went wrong!', title = 'Error!', success = 'danger', icon = 'error';
      let displayBanner = null;

      // Check form Validation
      if (this.formIsValid() || this.hideNextBtn) {


        if (this.isAdmin && this.organization) {
          this.form.organization = this.organization
        }

        const data = this.formData();

        // send ajax query and interpret result for trigger next step
        api.geSources.post(data)
          .then((response) => {

            if (this.is_skip)
              this.goToNextStep();

            if (response.success) {
              this.liveLeadSource = response.data.resource;
              this.form.id = this.liveLeadSource.id
              this.form.code = this.liveLeadSource.code

              this.goToNextStep()

              icon = 'success'
              title = 'Success'
              message = response.message
              success = 'success'
              displayBanner = true
            }

            this.is_skip = false;

          })
          .catch((error) => {
            console.log('Errors')
          })
          .finally(() => {
            this.$swal({
              title: title,
              icon: icon,
              text: message,
              type: success,
              showCancelButton: false,
              showCloseButton: true,
            }).then((result) => {

              // Show Cookie Policy
              if (displayBanner) {
                this.showCookiePolicy();
              }

              this.isLoading = false;
              this.hideNextBtn = false;
              this.$emit('add', {})
            })

          })


      } else {
        console.log('Form not valid', this.errors)
      }


    },
    formData() {
      let duplicateByType = null;

      if(this.form.duplicateByType == "recurrent") {
        duplicateByType = this.form.recurrent;
      } else if (this.form.duplicateByType == "no_by_account") {
        duplicateByType = -2;
      } else if (this.form.duplicateByType == "no_by_source") {
        duplicateByType = -1;
      }
      
      return {
        name: this.form.name,
        website: this.form.website,
        source: this.form.source,
        platform: this.form.platform_recorded ? this.form.platform_recorded.id : null,
        lists: this.form.list,
        organization: this.isAdmin ? this.organization : null,
        has_suppression_lists : this.form.has_suppression_lists,
        suppression_lists : this.form.suppression_lists,
        duplicate_by_account : this.form.duplicateByAccount ?? false,
        duplicate_by_type : duplicateByType
      }
    },
    hideModal() {
      this.$refs[this.id].hide();
    },
    onFinishStep(form) {
      this.hasPlatform = typeof form.platform == "number";
    },
    currentPlan() {
      api.subscriptions.list(this.user.account_id)
        .then((response) => {
          let liveLeadSubscription = _.filter(response.data, function (o) {
            return o.status == CURRENT_SUBSCRIPTION && o.active == SUBSCRIPTION_ACTIVE && o.service_id == SERVICES.LIVE_OPENS.id;
          })
          if (!_.isEmpty(liveLeadSubscription)) this.isSubscribeLiveOpen = true;
        })
        .catch((error) => {
          console.log('Errors')
        })
        .finally(() => {
          console.log(this.isSubscribeLiveOpen);
        })
    },
    isLastStep() {
      return this.currStep === this.steps.length - 1;
    },
    showCookiePolicy() {
      this.$swal({
        title: 'Heads up!',
        type: 'danger',
        icon: 'info',
        html: `<p class="font-weight-bold">${this.cookiePolicyText}</p>`,
        showCancelButton: true,
        confirmButtonText: 'I\'ve got it covered',
        cancelButtonText: 'I\'ll do that now',
        showCloseButton: true,
        showLoaderOnConfirm: true,
        customClass: {
          confirmButton: 'btn btn-primary'
        },
      })
    }
  }
}
</script>

<style>
select.form-control[size],
select.form-control[multiple] {
  height: 45px !important;
}

.pt-0,
.py-0 {
  padding-top: 6px !important;
}

.select2-container {
  width: 100% !important;
}

.select2-search--dropdown .select2-search__field {
  width: 98%;
}

h3 {
  font-size: 1.5em;
}

#top-tab-list {
  margin: 0 -10px 45px;
  overflow: hidden;
  color: #777D74;
}

.spinnerAddTag {
  position: relative;
  top: -3.5rem;
  left: 95.9%;
}

.fit-image {
  width: 50% !important;
}

.iq-card {
  background: #ffffff;
  border-radius: 5px;
  margin-bottom: 30px;
  border: none;
  box-shadow: none !important;
}

.iq-card .iq-card-header {
  padding: 0 20px;
  min-height: 60px;
  border-bottom: 0px solid #f2edff;
  align-items: center !important;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: .8rem 1rem;
  border-bottom: 0px solid #dee2e6;
  border-top-left-radius: calc(.3rem - 1px);
  border-top-right-radius: calc(.3rem - 1px);
}

div.swal2-html-container,
div#swal2-html-container {
  overflow-y: hidden;
}
.swal2-confirm.btn.btn-primary.swal2-styled{
  background-color: #f6386b !important;
}
@media only screen and (max-width: 768px) {
  #top-tab-list li a {
    background: #e5f2ff;
    color: #0084ff;
    display: block;
    padding: 15px;
    margin: 0 1px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    text-decoration: none;
    font-size: 12px;
  }

  .justify-content-center .col-7 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .spinnerAddTag {
    position: relative;
    top: -3rem;
    left: 85.9%;
  }
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
