<template>
    <b-container fluid>
        
        <b-row>
            <b-col md="3" class="pb-5 px-0">
                <TicketSideMenu :groupTickets="groupTickets"></TicketSideMenu>
            </b-col>

            <b-col md="9" class="mail-box-detail">
                <TicketBoxContent :groupTickets="groupTickets"></TicketBoxContent>
            </b-col>
        </b-row>

    </b-container>
</template>

<script>
import { sofbox } from "@/config/pluginInit";
import Spinner1 from '@/components/loaders/spinner1'
import _ from 'lodash'
import { mapGetters } from 'vuex'
import api from '@/api/RestClient'
import moment from 'moment'
import Pusher from 'pusher-js';

import TicketSideMenu from './children/TicketSideMenu';
import TicketBoxContent from './children/TicketBoxContent';

export default {
    name: "AdminTicketChat",
    components: {
        Spinner1,
        TicketSideMenu,
        TicketBoxContent,
    },
    mounted() {
        sofbox.index();

        _.forEach(this.groupTickets, (groupTicket) => {
            this.getGroupTicketData(groupTicket);
        })

        this.$root.$on('ibg:refresh-group-tickets', (firstItem) => {
            
            _.forEach(this.groupTickets, (groupTicket) => {
                this.getGroupTicketData(groupTicket);
            })

        })

    },
    computed: {
        ...mapGetters({
            isAdmin: 'Auth/isAdmin',
            isClient: 'Auth/isClient',
            user: 'Auth/user',
        })
    },
    methods: {
        getGroupTicketData(groupTicket) {


            api.default.get('/support_tickets?type='+groupTicket.typeCode)
                .then(response => {

                    groupTicket.data = response.data;

                    // this.inboxTickets = response.data.data

                    // const pusher = new Pusher(process.env.VUE_APP_PUSHER_APP_KEY, { cluster: 'eu', });

                    // _.forEach(this.inboxTickets, (ticket) => {

                    //     // const channel = pusher.subscribe('support.ticket.' + room.id);
                    //     // channel.bind('message',  (data)=> {this.messageArrived(data); });

                    // })

                })
        },
        getTickets() {

            api.default.get('/support_tickets')
                .then(response => {

                    this.inboxTickets = response.data.data

                    const pusher = new Pusher(process.env.VUE_APP_PUSHER_APP_KEY, { cluster: 'eu', });

                    _.forEach(this.inboxTickets, (ticket) => {

                        // const channel = pusher.subscribe('support.ticket.' + room.id);
                        // channel.bind('message',  (data)=> {this.messageArrived(data); });

                    })

                })
        },
        getTicketMessages(ticket) {
            this.messagesIsLoading = true;
            this.chatTicket = null

            api.default.get('/support_tickets/' + ticket.id + '/messages')
                .then(response => {

                    this.chatTicketMessages = response.length ? response : []

                })
                .finally(() => {

                    this.messagesIsLoading = false;
                    this.chatTicket = ticket;
                })
        },
        getDateTimeMessage(message) {
            const time = moment(message.created_at);

            const formattedTime = time.format('HH:mm');

            // const formattedTime12Hour = time.format('h:mm:ss A');

            return formattedTime;
        },
        sendTicketMessage() {

            if (!this.responseMessage) {
                return;
            }

            api.default.post('/support_tickets/' + this.chatTicket.id + '/messages', {
                message: this.responseMessage
            }).then(response => {
                this.responseMessage = '';

                if (response && response.length) {
                    this.chatTicketMessages = response;
                }

            })
                .catch((error) => {
                    // this.$store.dispatch('Auth/updateSupportchatTicketID', null);
                })
        },
        messageArrived(data) {

            if (data.chat_room_id == this.chatTicket.id) {
                // Current Chat Room Display
                this.chatTicketMessages.unshift(data);
            }

            // Find badge  count for unread messages for chat room and update it

        },
        showTicketDetails($event) {

            const parentLi = this.getParentByTagName($event.target, 'li')
            const targetElement = $event.target;

            const nextSibling = parentLi ? this.getNextSiblingByClass(parentLi, 'email-app-details') : null;
            if (nextSibling) {
                nextSibling.classList.add('show');
            }

        },
        hideTicketDetails($event) {
            const parentDiv = this.getParentByClass($event.target, 'email-app-details')

            if (parentDiv) {
                parentDiv.classList.remove('show');
            }

        },
        getParentByClass(element, className) {
            const parentElement = element.closest('.'+className);

            if (parentElement) {
                return parentElement;
            }

            return null
        },
        getParentByTagName(element, tagName) {
            
            let parent = element.parentNode; // Start from the parent node of the given element
            while (parent) {
                if (parent.tagName.toLowerCase() === tagName.toLowerCase()) {
                return parent;
                }
                parent = parent.parentNode; // Move up to the next parent node
            }
            return null; // If no parent with the specified tag name is found

        },
        getNextSiblingByClass(element, className) {
            let nextSibling = element.nextSibling;
            while (nextSibling) {
                if (nextSibling.tagName === 'DIV' && nextSibling.classList.contains(className)) {
                    return nextSibling;
                }
                nextSibling = nextSibling.nextSibling;
            }
            return null;
        }
    },
    watch: {
    },
    data() {
        return {
            roomsIsLoading: false,
            messagesIsLoading: false,
            inboxTickets: [],
            chatTicket: null,
            chatTicketMessages: [],
            users: [
                { code: 1, name: 'Client 1' },
                { code: 2, name: 'Client 2' },
                { code: 3, name: 'Client 3' },
                { code: 4, name: 'Client 4' },
                { code: 5, name: 'Client 5' },
            ],
            responseMessage: null,
            showUserDetail: false,
            groupTickets: [
                {name: 'Open', label: 'open', typeCode: 1, icon: 'ri-mail-line', data: null},
                {name: 'Closed', label: 'closed', typeCode: -1, icon: 'ri-close-circle-fill', data: null},
                {name: 'Trash', label: 'trash', typeCode: -10, icon: 'ri-delete-bin-line', data: null},
            ]
        };
    },
};
</script>

<style>
    .chat-data .tab-content {
        position: relative;
    }

    .chat-start {
        display: flex;
        justify-content: center;
        align-items: center;
        /* height: calc(100vh - 10rem); */
        flex-direction: column;
    }

    span.avatar-status {
        position: absolute;
        bottom: 8px;
        top: auto;
        left: auto;
        right: 0;
    }
</style>