<template>
    <div>
        <b-form @submit.prevent="onSubmit">
            <b-form-textarea id="textarea" v-model="form.note" placeholder="Enter something..." rows="3"
                max-rows="6"></b-form-textarea>

             <b-button type="submit" variant="primary" class="mt-2">Submit <b-spinner v-if="isSubmit" small label="Small Spinner" type="grow"></b-spinner> </b-button>
        </b-form>
    </div>
</template>

<script>
export default {
    data() {
        return {
            form: {
                note: ''
            },
            isSubmit: false
        }
    },
    props: {
        note: {
            type: String,
            default: ''
        }
    },
    computed: {
        getNote() {
            return this.form.note == '' ? this.note : this.form.note;
        }
    },
    mounted() {
        this.form.note = this.note;
    },
    methods: {
        onSubmit() {
            this.isSubmit = true;
            this.$emit('onSaveNote', this.form);
        }
    }
}
</script>