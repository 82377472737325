<template>

<div>
  <b-row class="my-2">
    <b-col>
      <label for="">APIv2 address :</label>
      <b-input placeholder="APIv2 address :" v-model="platform.account" label=""></b-input>
    </b-col>
  </b-row>

  <b-row class="my-2">
    <b-col>
      <label for="">API Key :</label>
      <b-input placeholder="API Key" v-model="platform.apikey" label="API Key :"></b-input>
    </b-col>
  </b-row>

  <b-row>
      <b-col class="text-right mt-2">
        <button :disabled="!isAllowed" class="btn btn-primary" @click.prevent="createPlatform"> <b-spinner v-if="isLoading" small type="grow"></b-spinner>  Create Platform</button>
      </b-col>
    </b-row>
  

</div>


</template>

<script>
import api from '@/api/RestClient'

  export default {
    name: 'ExpertSenderPlatformInputs',
    components: {
    },
    props: ['platform_nickname'],
    mounted(){

    },
    
    data(){
        return {
          isDisabled:false,
          isLoading:false,
          platform: {
            account:null,
            apikey:null
          }
        }
      },
      methods: {
        createPlatform(){
          this.isLoading = true;
          this.isDisabled = true;

          api.platforms
          .verifyToken('expertsender',this.platform.account,this.platform.apikey)
          .then(()=>{
           
            this.$emit('createPlatform',this.platform);
            this.isLoading = false;
            
          }).catch((error)=>{
            this.isLoading = false;
            this.isDisabled = false;
            let swal = {
              icon: 'error',
              title: 'Failed',
              text: 'Failed to authenticate, please check your credentials and try again',
              type: 'error',
              confirmButtonText: 'OK',
              showCloseButton: true,
            }

            this.$swal(swal)

          });
        }
      },
        computed : {
           isAllowed(){
              return ( this.platform_nickname != null && this.platform_nickname.trim() != '' ) && ! this.isDisabled ; 
            }
        }
  }

</script>