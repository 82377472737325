
<template>
    <b-container fluid>

            <b-col sm="12" >
                <iq-card class="p-2">

                    <template v-slot:headerTitle>
                        <h4 class="card-title">Accounts</h4>
                    </template>

                     <template v-if="isAdmin" v-slot:headerAction ><b-button v-b-modal.modal-new-account variant="primary" @click="add_new_account=true"  class="text-right mr-1">  Add Account </b-button></template>


                    <template v-slot:body>

                            <!-- START FILTER OPTIONS ROW  -->
                            <b-row class="my-2" align-h="between">

                                <!-- START Sow Option Input -->
                                <b-col md="2" >
                                    <b-form-group label-for="per-page-select" label-align-sm="left" class="mb-0">
                                        <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions"></b-form-select>
                                    </b-form-group>
                                </b-col>
                                <!-- END Sow Option Input -->


                                <!-- START Filter Input -->
                                <b-col md="4">
                                    <b-form-group class="mb-0" >

                                        <b-input-group >

                                            <b-form-input id="filter-input" v-model="filter" type="search" placeholder="Type to Search" ></b-form-input>

                                        </b-input-group>

                                    </b-form-group>

                                </b-col>
                                <!-- END Filter Input -->

                            </b-row>
                            <!-- END FILTER OPTIONS ROW  -->



                            <b-table
                            borderless
                            hover
                            :items="accounts"
                            :fields="fields"
                            :current-page="currentPage"
                            :per-page="perPage"
                            :filter="filter"
                            :filter-included-fields="filterOn"
                            :sort-by.sync="sortBy"
                            :sort-desc.sync="sortDesc"
                            :sort-direction="sortDirection"
                            :busy="loading.table"
                            stacked="md"
                            show-empty
                            @filtered="onFiltered" >

                            <template #table-busy>
                                <div class="text-center p-4 d-flex justify-content-center align-items-center">
                                <b-spinner variant="primary"> </b-spinner>
                                <span class="text-primary p-2"><small> Fetching Accounts, Please wait...</small></span>
                                </div>
                            </template>

                            <template #cell(type)="row">
                                {{ row.item.type_name }}

                            </template>

                            <template #cell(billing_address_country)="row">
                                {{ row.item.billing_address_country ? countryNameFromCode(row.item.billing_address_country) : '--' }}
                            </template>

                            <template #cell(user)="row">
                                {{ row.item.user ? row.item.user.name : '--' }}
                            </template>

                            <template #cell(created_at)="row">

                                {{ dateFormat(row.item.created_at) }}

                            </template>

                            <template #cell(status)="row">

                                <StatusCell :row="row" />

                            </template>

                            <template #cell(referral)="row">

                                {{ row.item.referral ? row.item.referral.name : '--' }}

                            </template>

                            <template #cell(actions)="row">

                                <b-dropdown>
                                    <template #button-content variant="light">
                                        <!-- For Dropdown -->
                                        <i v-if="!row.item.isLoading" class="ri-more-2-fill fa-lg mx-auto btn btn-light"></i>

                                        <b-spinner v-else small label="Small Spinner" type="grow" class="m-2 text-dark"></b-spinner>
                                        <!-- End Dropdown -->
                                    </template>
                                    <b-dropdown-item v-if="row.item.type_name == 'client' && isAdmin" @click="showDetails('Subscriptions',row.item)">
                                        <b-button v-b-tooltip.top="'Account Subscription'" variant="light iq-bg-light" class="btn-sm mx-1 bg-white" size="sm" ><i class="ri-indent-decrease"></i></b-button>
                                        Subscriptions
                                    </b-dropdown-item>

                                    <b-dropdown-item v-show="isAdmin" @click="showDetails('Balances',row.item)">
                                        <b-button v-b-tooltip.top="'Account Balance(s)'" variant="light iq-bg-light" class="btn-sm mx-1 bg-white" size="sm"><i class="ri-bill-line"></i></b-button>
                                        Balances
                                    </b-dropdown-item>

                                    <b-dropdown-item v-show="isAdmin" @click="showDetails('Discount', row.item)">
                                        <b-button v-b-tooltip.top="'Show Account Discount(s)'" variant="light iq-bg-light" class="btn-sm mx-1 bg-white" size="sm"><i class="ri-bill-line"></i></b-button>
                                        Discount
                                    </b-dropdown-item>

                                    <b-dropdown-item @click="edit(row.item)">
                                        <b-button v-b-tooltip.top="'Edit Account'" variant=" iq-bg-info mr-1 mb-1" size="sm"><i class="ri-ball-pen-fill m-0"></i></b-button>
                                        Edit
                                    </b-dropdown-item>

                                    <b-dropdown-item @click.prevent="showBillingInfo(row)">
                                        <b-button v-b-tooltip.top="billing_info_title" variant=" iq-bg-secondary mr-1 mb-1" size="sm"><i class="ri-arrow-down-circle-line m-0"></i></b-button>
                                        {{ billing_info_title }}
                                    </b-dropdown-item>

                                    <b-dropdown-item v-if="(row.item.id != user.id) && row.item.user && isAdmin" @click="loginAs(row.item)">
                                        <b-button v-b-tooltip.top="'Login as Owner: '+row.item.user.email" variant=" iq-bg-success mr-1 mb-1" size="sm"><i class="ri-login-box-line m-0"></i></b-button>
                                        Login as Owner
                                    </b-dropdown-item>

                                    <b-dropdown-item v-show="isAdmin" @click="destroy(row.item)">
                                        <b-button v-b-tooltip.top="'Delete Account'" variant=" iq-bg-danger mr-1 mb-1" size="sm"><i class="ri-delete-bin-line m-0"></i></b-button>
                                        Delete Account
                                    </b-dropdown-item>
                                </b-dropdown>

                            </template>

                            <!-- <template v-slot:row-details="{ row }"> -->
                            <template v-slot:row-details="data">
                                <iq-card class="text-left my-1">
                                    <p v-if="data.item.billing_address_main" class="my-1">
                                        <strong>{{ data.item.billing_address_main }}</strong>,
                                        {{ data.item.billing_address_country ? countryNameFromCode(data.item.billing_address_country) : '--' }},
                                        {{ data.item.billing_address_state ?? '--' }},
                                        {{ data.item.billing_address_city ?? '--' }}
                                    </p>
                                     <p v-if="['CA'].includes(data.item.billing_address_country)" class="my-1">
                                        <strong>GST Tax:</strong> {{ data.item.gst_tax_num ?? '--' }},
                                        <strong>QST Tax:</strong> {{ data.item.qst_tax_num ?? '--' }}
                                    </p>
                                </iq-card>
                            </template>


                            </b-table>


                            <!-- START PAGINATION TABLE-->
                            <b-row align-h="end">

                                <b-col md="3" class="my-1">
                                    <b-pagination
                                        v-model="currentPage"
                                        :total-rows="totalRows"
                                        :per-page="perPage"
                                        align="fill"
                                        size="sm"
                                        class="my-0"
                                    ></b-pagination>
                                </b-col>
                            </b-row>
                            <!-- END PAGINATION TABLE-->


                    </template>
                </iq-card>
            </b-col>

            <!-- START Subscription or Balances Card  -->
            <b-col sm="12" v-if="detailed_component != null && selected_account != null " ref="account_details">

                <component :is="detailed_component" :account="selected_account"></component>

            </b-col>
            <!-- START Subscription or Balances Card  -->


        <!-- START Account Modal-->
        <b-row>
            <b-col lg="12" md="12">
                <b-modal  id="modal-new-account"  ref="account" hide-footer :title="modal.title">
                    <b-form @submit="onSubmit">

                        <b-row>
                            <b-col lg="6" md="6">
                                <b-form-group>
                                    <div
                                        class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color">
                                        <div class="custom-switch-inner">
                                            <label class="mr-2">Status</label>
                                            <input type="checkbox" class="custom-control-input bg-primary"
                                                v-model="form.status" id="customSwitch-13">
                                            <label class="custom-control-label" for="customSwitch-13" data-on-label="On"
                                                data-off-label="Off">
                                            </label>
                                        </div>
                                    </div>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <b-form-group id="input-group-1" label="Name:" label-for="input-1"
                            description="">
                            <b-form-input id="input-1" v-model="form.name" type="text" placeholder="Account name"
                                required></b-form-input>
                        </b-form-group>

                        <b-form-group id="input-group-4" label="Account Type:" label-for="input-4"
                            description="">
                        <select v-model="form.type" class="form-control" placeholder="kka">
                            <option value="null">Please select account type</option>
                            <option v-for="( type,type_id ) in types()" :key="type.name" :value="type_id">{{ type.name }}</option>
                        </select>
                        </b-form-group>
                        <div class="mb-4" v-show="is_taxable">
                            <div class="form-group">
                                <div class="d-flex justify-content-between">
                                    <label for="gst_tax_title">GST Tax:</label>
                                    <label for="gst_tax_value"><b-form-checkbox v-model="form.is_exempt_gst_tax" switch :checked="form.is_exempt_gst_tax" size="sm">is exempt?</b-form-checkbox></label>
                                </div>
                                <b-form-input id="input-gst-tax" v-model="form.gst_tax_num" type="text" placeholder="GST Tax"
                                        ></b-form-input>
                            </div>
                            <div class="form-group">
                                    <div class="d-flex justify-content-between">
                                        <label for="qst_tax_title">QST Tax:</label>
                                        <label for="qst_tax_value"><b-form-checkbox v-model="form.is_exempt_qst_tax" switch :checked="form.is_exempt_qst_tax" size="sm">is exempt?</b-form-checkbox></label>
                                    </div>
                                    <b-form-input id="input-qst-tax" v-model="form.qst_tax_num" type="text" placeholder="QST Tax"
                                        ></b-form-input>
                                </div>

                        </div>
                        <b-form-group id="input-group-4" label="Discounts:" label-for="input-4"
                            description="" v-if="false">
                            <discounts :_discounts="form.discounts" @onSelect="onSelectDiscounts"></discounts>
                        </b-form-group>

                        <b-row align-h="end" class="px-4">
                        <b-button variant="primary" type="submit" :disabled="loading.modal">
                            Submit
                            <b-spinner small type="grow" v-show="loading.modal"></b-spinner>
                        </b-button>
                        </b-row>
                    </b-form>
                </b-modal>
            </b-col>
        </b-row>
         <!-- END Account Modal-->

    </b-container>
</template>
<script>
import { sofbox } from '../../config/pluginInit'
import api from '@/api/RestClient';
import Balances from './widgets/Balances.vue'
import Discount from './widgets/Discount.vue'
import Subscriptions from './widgets/Subscriptions.vue'
import StatusCell from '@/components/inboxgeek/tables/StatusCell.vue'

import { ACCOUNT_TYPE } from '../../constantes';
import discounts from './children/discounts.vue';
import { mapGetters } from 'vuex';
import { BILLING_COUNTRIES } from '../../countries';
import { SERVICES } from "@/constantes";

var moment = require('moment');

export default {
    name: 'accounts',
    components: {
        Balances,
        Subscriptions,
        StatusCell,
        discounts,
        Discount
    },
     computed: {
        ...mapGetters({
            isAdmin: 'Auth/isAdmin',
            isClient: 'Auth/isClient',
            isAccounting: 'Auth/isAccounting',
            user: 'Auth/user',
            liveOpenersSubscribed: 'Auth/liveOpenersSubscribed',
            liveLeadsSubscribed: 'Auth/liveLeadsSubscribed',
        })
    },
    mounted() {

        sofbox.index()
        window.parent = this;

        this.fetchAccounts();
        this.loadFields();
    },

    data() {
        return {

            detailed_component:null,
            selected_account:null,

            loading : {
                modal: false,
                table : false,
            },

            headerTitle: 'Account list',

            notOrderable: [],
            columnsTable: null,

            filter: 7,
            account_id: null,

            add_new_account:true,
            accounts : [],

            form: {
               "name": null,
               "type": null,
               "status": false,
               "discounts": [],
               "gst_tax_num": null,
               "qst_tax_num": null,
            },
            modal: {
                title: 'Add new Account',
            },
            table: null,

             // Table data :
            fields: [
                { key: 'id', label: '#', sortable: true, sortDirection: 'desc' },
                { key: 'name', label: 'Name', sortable: true, class: 'text-left' },
                { key: 'type', label: 'Type', sortable: true, class: 'text-center' },
                { key: 'referral', label: 'Affiliate', sortable: true, class: 'text-center' },
                { key: 'billing_address_country', label: 'Country', sortable: true, class: 'text-center' },
                { key: 'user', label: 'Owner', sortable: false, class: 'text-center' },
                { key: 'created_at', label: 'Creation date', sortable: true, class: 'text-center' },
                { key: 'status', label: 'Status', sortable: true, class: 'text-center' },
                { key: 'actions', label: 'Actions' ,class: 'text-center'}
            ],
            totalRows: 1,
            currentPage: 1,
            sortBy:"",
            perPage: 25,
            pageOptions: [25, 50, { value: 999, text: "Show all"}],
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            is_taxable: false,
            billing_info_title: 'Show Billing Info',
        }
    },
    methods: {
        showDetails(component,account) {
            account.isLoading = true;
            this.detailed_component = component;
            this.selected_account   = account;


            setTimeout( () => {

                const el = this.$refs.account_details;

                if (el) {

                    el.scrollIntoView({behavior: 'smooth'});

                }

                let item = _.find(this.accounts, (o) => { return o.id == account.id})
                if(item) {
                    item.isLoading = false;

                    this.loading.table = true;
                    this.$nextTick(() => {
                        this.loading.table = false;
                    });

                }

            }, 500);



        },
        typeName(id){

            const type = ACCOUNT_TYPE[id];
            return type.name;

        },
        types(){

            return ACCOUNT_TYPE;

        },
        edit(account) {

            this.add_new_account = false;
            this.modal.title = "Edit account";
            this.account_id = account.id;

            let discounts = [];
            account.discounts.forEach(discount => {
                discounts.push({
                    code: discount.id,
                    name: discount.name
                });
            });
            this.form = {
                "name": account.name,
                "type": account.type,
                "status": account.status,
                "discounts": discounts,
                "gst_tax_num": account.gst_tax_num,
                "qst_tax_num": account.qst_tax_num,
                "is_exempt_gst_tax": account.is_exempt_gst_tax ? true : false,
                "is_exempt_qst_tax": account.is_exempt_qst_tax ? true : false
            };
            this.is_taxable = ['CA'].includes(account.billing_address_country)
            this.$refs['account'].show()

        },
        onSubmit(e) {

            e.preventDefault();

            this.loading.modal = true;

            const account_id = this.account_id;
            const data = this.form;

            if(this.add_new_account)
            {

                api.accounts.post(data).then(response => {
                    let variant = 'danger';
                    let message = 'Something went wrong'


                    if ('success' in response) {

                        variant = response.success ? 'success' : 'danger';
                        message = response.message;
                        this.toast('account', variant, message);

                        if (response.success){

                            this.fetchAccounts();

                            this.form = {
                                "name":     null,
                                "type":     null,
                                "status":   null,
                                "discounts": []
                            };

                            this.$refs['account'].hide();
                        }
                    }


                }).catch(error => {
                    console.log(error);
                    this.$bvToast.toast(error.response.data.message, {
                        title: 'Error',
                        variant: 'danger',
                    });
                }).finally(() => {
                    this.loading.modal = false;
                })


            }else{


                api.accounts.update(account_id, data).then(response => {

                    if ('success' in response) {

                        const variant = response.success ? 'success' : 'danger';
                        const message = response.message;

                        this.toast('Account', variant, message);
                        if (response.success){

                            this.fetchAccounts();

                            this.$refs['account'].hide();
                        }
                    }
                }).catch(error => {
                    console.log(error);
                    this.$bvToast.toast(error.response.data.message, {
                        title: 'Error',
                        variant: 'danger',
                    });
                }).finally(() => {
                    this.loading.modal = false;
                });


                this.add_new_account = true;
                this.modal.title = "Add new account";

            }




        },
        destroy(account) {
            this.$swal({
                customClass: {
                    confirmButton: 'btn btn-danger'
                },
                title: 'Are you sure?',
                text: 'are you sure you want to delete account ' + account.name,
                type: 'danger',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete this account',
                cancelButtonText: 'No',
                showCloseButton: true,
                confirmButtonColor: '#d33',
                showLoaderOnConfirm: true
            }).then((result) => {

                if (result.value) {

                    api.accounts.delete(account.id).then(response => {
                        let varian = response.success ? 'success' : 'danger';
                        this.toast('Delete Account', varian, response.message);
                        if (response.success) {
                          this.fetchAccounts();
                        }
                    }).catch(error => {
                        console.log(error);
                        this.$bvToast.toast(error.response.data.message, {
                            title: 'Error',
                            variant: 'danger',
                        });
                    }).finally(() => {
                    });
                }
            })
        },
        setNotOrderableColumns() {
            let n = this.columns.length
            for (let index = 0; index < n; index++) {
                if (index != this.filter) this.notOrderable.push(index)
            }
        },
        toast(title = "success", variant = "success", message = "Organization updated successfully") {
            this.$bvToast.toast(message, {
                title: title,
                variant: variant
            })
        },
        // table :
        onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length
        this.currentPage = 1
        },
        fetchAccounts(){

            this.loading.table = true;

            api.accounts.all({per_page:-1}).then((response)=>{

                this.loading.table = false;
                this.accounts =  response.data.map((account)=>{
                    account.type_name = this.typeName(account.type);
                    account._showDetails = false;

                    return account;
                });

                this.totalRows = this.accounts.length;

            }).catch((error)=>{
                this.loading.table = false;
                this.accounts = [];
                this.$bvToast.toast(error.response.data.message, {
                    title: 'Error',
                    variant: 'danger',
                });
            });

        },
        dateFormat( date ){
            return  moment(date).format('YYYY-MM-DD'); //DD MMM YYYY
        },
        onSelectDiscounts(discounts) {
            this.form.discounts = discounts;
        },
        loadFields() {
            let fields = _.map(this.fields, (item) => {
                if (this.isAdmin || this.isAccounting) {
                    return item;
                } else
                    if (!["actions"].includes(item.key)) return item;
            })
            this.fields = fields;
        },
        showBillingInfo(row) {
            // To disabled other (active script if button display for all rows)
            let activeDetailItems = _.filter( this.accounts, (o) => { return o._showDetails && o != row.item });
            activeDetailItems.forEach(item => {
                item._showDetails = false;
            });
            row.toggleDetails();
        },
        countryNameFromCode(code) {
            let country = _.find(BILLING_COUNTRIES, (o) => { return o.code == code; });
            if(country) return country.name;

            return code;
        },
        loginAs(item) {

            if(!item.user) return;

            // Get client token set store app update state and redirect to dashboard if success
            api.auth.loginAs(item.user.email)
                .then(response => {

                    this.$store.dispatch('Auth/updateLiveOpenersSubscribed', false);
                    this.$store.dispatch('Auth/updateLiveLeadsSubscribed', false);

                    let userAs = response.data;
                    let user = userAs.user;
                    let subscribed = userAs.subscribed;
                    let roles = user.roles.map(function (role) {
                        return role.slug;
                    });

                  this.$store.dispatch('Auth/initUserMetaDta',userAs.meta_data ?? [])


                  this.$store.dispatch('Auth/updateTokenAs', response.data.access_token);
                    this.$store.dispatch('Auth/updateUserAdmin', this.user);

                    let admin = false;
                    if (roles.includes('ROLE_SUPER_ADMIN')) admin = true;
                    user['role_id'] = 1;

                    this.$store.dispatch('Auth/updateisAdmin', admin);
                    this.$store.dispatch('Auth/updateisClient', !admin);
                    this.$store.dispatch('Auth/updateUser', user);


                    let liveOpensPlan, liveLeadsPlan = null;
                    let liveOpensSubscription, liveLeadsSubscription = null;

                    // Get Services LiveSubscriptions
                    liveOpensSubscription = userAs.live_services_subscriptions[SERVICES.LIVE_OPENS.id] ?? null;
                    liveLeadsSubscription = userAs.live_services_subscriptions[SERVICES.LIVE_LEADS.id] ?? null;

                    // Get Live plans
                    liveOpensPlan = userAs.live_plans[SERVICES.LIVE_OPENS.id] ?? null;
                    liveLeadsPlan = userAs.live_plans[SERVICES.LIVE_LEADS.id] ?? null;

                    // LiveSubscriptions and LivePlans
                    this.$store.dispatch('Auth/updateLivePlans', userAs.live_plans);
                    this.$store.dispatch('Auth/updateLastSubscriptions', userAs.last_services_plans);
                    this.$store.dispatch('Auth/updateUserSubscriptions', userAs.live_services_subscriptions);

                    if ((liveOpensSubscription && (liveOpensPlan && !liveOpensPlan.is_trial)) || this.$store.getters['Auth/isAccountManager']) {
                        this.$store.dispatch('Auth/updateLiveOpenersSubscribed', true);
                    }
                    if ((liveLeadsSubscription && (liveLeadsPlan && !liveLeadsPlan.is_trial)) || this.$store.getters['Auth/isAccountManager']) {
                        this.$store.dispatch('Auth/updateLiveLeadsSubscribed', true);
                    }

                    api.settings.getPlatformConfigs().then(response => {

                      this.$store.dispatch('updatePlatformConfigs', response.data);

                      window.location.assign("/");

                    }).catch((error)=>{

                      window.location.assign("/");
                    });



                })
        }
    },
}

</script>

<style>
.dataTables_filter > label {
  width: 50% !important;
  text-align: left;
}
.dataTables_filter {
  text-align: right;
}
ul.pagination{
  justify-content: end;;
}
.table-hover tbody tr:hover {
    color: #212529;
    background-color: rgb(171 216 225 / 15%);
}
</style>
