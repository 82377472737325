<template>
    <b-container fluid>
        <b-row>
            <b-form-group class="col-md-12 my-0 px-0" label-for="selectcountry">
                <multiselect ref="plan-ref" :loading="isLoading" :disabled="isLoading" tag-placeholder="Manage plan"
                    placeholder="Select plans" label="name" track-by="code" v-model="plan" :options="plans"
                    :multiple="true" :taggable="true" @input="onSelect">
                </multiselect>
            </b-form-group>
        </b-row>
    </b-container>
</template>

<script>
import Multiselect from 'vue-multiselect'

import api from '@/api/RestClient'

export default {
    name: 'PlansMultiselect',
    components: {
        Multiselect,
    },
    data() {
        return {
            isLoading: true,
            plan: null,
            plans: [],
        }
    },
    mounted() {
        this.getPlans()
    },
    methods: {
        getPlans() {
            this.isLoading = true;
            var plans = [];
            api.plans.getActive()
                .then(response => {
                    response.data.forEach((org) => {
                        let plan = {};
                        plan.name = org.name;
                        plan.code = org.id;
                        plans.push(plan);
                    });
                })
                .catch(err => {
                    console.log(err)
                })
                .finally(() => {
                    setTimeout(() => {
                        this.plans = plans;
                        this.isLoading = false;
                        this.$emit('onComplete', this.plans)
                    }, 1000)
                })
        },
        onSelect(selectedOption, id) {
            this.$emit('onSelect', selectedOption)
        }
    },
    watch: {
    },
}
</script>
