<template>
  <div class="w-100">
    <b-row class="my-2">
      <b-col md="12">
        <b-form-group class="" label="Secret Key">
          <b-input-group>
            <b-form-input type="text" v-model="platform.apikey" placeholder="Secret Key"></b-form-input>

            <b-input-group-append class="cursor-pointer">
              <b-input-group-text>
                <i class="ri-clipboard-line" @click.prevent="clickboardCopy(platform.apikey)"></i>
              </b-input-group-text>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row class="my-2">
      <b-col>
        <label for="">Domain: *</label>
        <b-input v-model="platform.account" placeholder="example.com"></b-input>
      </b-col>
    </b-row>

    <b-row>
      <b-col class="text-right mt-2">
        <b-button variant="primary" :disabled="!isAllowed" @click.prevent="createPlatform">Create Platform</b-button>
      </b-col>
    </b-row>


  </div>
</template>
  
<script>
import { helper } from '@/helpers';

export default {
  name: 'IbgEspInput',
  components: {
  },
  props: ['platform_nickname', 'account'],
  mounted() {
    console.log(this.account)

    // this.generateIntegrationAccount();
    this.generateSecretKey();
  },

  data() {
    return {
      platform: {
        account: null,
        apikey: null
      }
    }
  },
  methods: {
    createPlatform() {


      this.$emit('createPlatform', this.platform);
    },
    generateIntegrationAccount() {
      if (!this.platform.account) {
        this.platform.account = 'IBG_WEBHOOK_#' + this.account;
      }
    },
    generateSecretKey() {
      if (!this.platform.apikey) {
        this.platform.apikey = helper.create_UUID();
      }
    },
    clickboardCopy(text) {
      navigator.clipboard.writeText(text)
      this.$bvToast.toast('Platform Secret Key Copied', {
        title: 'Copy Platform Secret Key',
        variant: 'info'
      })
    }
  },
  computed: {
    isAllowed() {
      return this.platform_nickname != null && this.platform_nickname.trim() != '';
    }
  }

}

</script>