<template>
      <!--  MODAL Remove Balances  -->
      <b-row class="p-4">

  

        <b-col lg="12" md="12">
            <b-modal  id="modal-log-balance"  ref="balance" size="xl" scrollable title="Events Activity History" @hide="updateIsBusyToTrue">

              <!-- START FILTER OPTIONS ROW  -->
              <b-row class="my-2" align-h="between">

                <!-- START Sow Option Input -->
                <b-col md="2" >
                  <b-form-group
                    label-for="per-page-select"
                    label-align-sm="left"

                    class="mb-0"
                  >
                    <b-form-select
                      id="per-page-select"
                      v-model="perPage"
                      :options="pageOptions"
                    
                    ></b-form-select>
                  </b-form-group>
                </b-col>
                <!-- END Sow Option Input -->


                <!-- START Filter Input -->
                <b-col md="4">
                  <b-form-group

                    
                    class="mb-0"
                  >
                    <b-input-group >
                      <b-form-input
                        id="filter-input"
                        v-model="filter"
                        type="search"
                        placeholder="Type to Search"
                      ></b-form-input>

                    </b-input-group>
                  </b-form-group>
                </b-col>
                <!-- END Filter Input -->

              </b-row>
              <!-- END FILTER OPTIONS ROW  -->

                
              <b-table
                borderless
                hover
                fixed
                :items="logs"
                :fields="fields"
                :current-page="currentPage"
                :per-page="perPage"
                :filter="filter"
                :filter-included-fields="filterOn"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                :busy="isBusy"
                stacked="md"
                show-empty
                @filtered="onFiltered" >

                <template #table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Loading...</strong>
                  </div>
                </template>
                <template #cell(action_type)="row">

                  <b-badge v-if="['Added', 'Updated'].includes(row.item.action_type)"  pill variant="success" class="iq-bg-success" size="sm">Added</b-badge>
                  <b-badge v-else  pill variant="danger" class="iq-bg-danger" size="sm">{{row.item.action_type}} </b-badge>

                </template>



              </b-table>

               
              <template #modal-footer="{ hide }">
  
              <b-button size="sm" variant="secondary" @click="hide()">
                Cancel
              </b-button>
        
            </template>

            </b-modal>
        </b-col>
      </b-row>
      <!-- END MODAL Balances  -->

</template>

<script>
import api from '@/api/RestClient';

export default {

  name:'add-balance-modal',
  props:['account','updateLogs', 'balance'],
  mounted() {
  },
  data(){
    return {
      isBusy : true,
      logs:[],
      fields: [
          { key: 'date'                 , label: 'Date', sortDirection: 'desc' },
          { key: 'event_amount'         , label: 'Event Amount', class: 'text-center' },
          { key: 'total_event_remaining', label: 'Total Event Remaining', class: 'text-center total-event-remaining-column-width' },
          { key: 'action_type'          , label: 'Action Type', class: 'text-center' },
          { key: 'notes'                , label: 'Notes', class: 'text-left notes-column-width' },
          { key: 'plan', label: 'Plan', class: 'text-center', formatter: (value, key, item) => { return item.plan } },
          { key: 'completed_by', label: 'Completed by', class: 'text-center' },      
      ],    
      totalRows: 1,
      currentPage: 1,
      perPage: -1,
      pageOptions: [{ value : -1, text:'All' }],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],

    }
  },
  methods : {
        
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    fetchLogs(account, service_id)
    {
      api.balances.logs(account, service_id).then(response => {
        
        this.logs           = response.data.logs;
        this.totalRows      = response.data.logs.length;

      }).catch(error =>{
        this.logs           = [];
      }).finally(() => {
        this.isBusy = false;
      });
    },
    updateIsBusyToTrue() {
      this.isBusy = true;
    }
  },
  watch : {

    account(newAccount, oldAccount) {

      this.fetchLogs(newAccount.id);

    },
    updateLogs(newUpdate,oldUpdate)
    {
      this.fetchLogs(this.account.id, newUpdate.service_id);
    },
    balance(newUpdate, oldUpdate) {
      if (newUpdate) {
        this.fetchLogs(this.account.id, newUpdate.service_id);
      }
    }

  }
  
}
</script>


<style>
.notes-column-width {
  width:310px !important;
}

.total-event-remaining-column-width {
  width: 190px !important;

}
</style>