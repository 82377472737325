<template>
  <div class="row">
            <div class="col-lg-12">
              <h3>
                Hello there,
                <span class="text-primary">
                  {{ (profile ? profile.first_name : '') | filterCapitalize(true) }}
                </span>
              </h3>
            </div>
  </div>
</template>

<script>


export default {
  name: 'WelcomeWidget',
  mounted() {
   
  },
  props: ['service_id'],
  data() {
    return {
      profile : 'Test'
    }
  }
}

</script>