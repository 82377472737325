<template>
    <div >
        <multiselect 
            v-model="value" 
            tag-placeholder="Empty" 
            deselect-label="Can't remove this value" 
            :placeholder="placeholder" label="name" 
            :custom-label="({ name, code }) => `${name} - [${code}]`"
            track-by="code" :options="options" :multiple="false" :disabled="false" :taggable="true" @input="emitInput">
        </multiselect>
        <!-- <multiselect tag-placeholder="Empty" deselect-label="Can't remove this value" :placeholder="placeholder"
            label="name" :custom-label="({ name, code }) => `${name} - [${code}]`" track-by="code" :options="[]"
            :multiple="false" :disabled="false" :taggable="true" @select="selectPlatformConfig($event)">
        </multiselect> -->
    </div>
</template>
<script>
import Multiselect from 'vue-multiselect'

export default {
    name: 'OauthAccountId',
    components: {
        Multiselect
    },
    props: {
        label: {
            type: [String],
            default: 'Account ID:',
        },
        placeholder: {
            type: [String],
            default: 'Account ID',
        },
        accountId: {
            type: [String],
            default: '',
        },
        options: {
            type: [Array],
            default: [],
        },
        disabled: {
            type: [Boolean],
            default: false,
        },
    },
    data() {
        return {
            isLoading: true,
            value: null,
        }
    },
    mounted() {
    },
    methods: {
        init() {
            if (typeof this.accountId !== 'undefined') {
                this.value = this.accountId
            }
        },
        emitInput(value) {
            this.$emit('input', value)
        },
        setValue(value = null) {
            this.value = value
        }
    },
    computed: {
    },
    watch: {
        "p_records": {
            handler(newVal, oldVal) {
                this.value = null
            },
            deep: true
        },
    },
}
</script>