<template>
<iq-card body-class="p-0">
    <template v-slot:headerTitle>
        <h4 class="card-title">{{ title }}</h4>
    </template>
    <template v-slot:headerAction>
        <ul class="nav nav-pills">
            <li v-if="component_use_day.includes(keyy)" class="nav-item">
                <a href="#" :v-model="week_or_month" :class="class_day" @click="getStatsOnDay">Days</a>
            </li>
            <li v-if="component_use_week.includes(keyy)" class="nav-item">
                <a href="#" :v-model="week_or_month" :class="class_week" @click="getStatsOnWeek">Weeks</a>
            </li>
            <li v-if="component_use_month.includes(keyy)" class="nav-item">
                <a href="#" :v-model="week_or_month" :class="class_month" @click="getStatsOnMonth">Months</a>
            </li>
        </ul>
    </template>
    <template v-slot:body>
        <div class="libolo">
            <multiselect v-if="showOrganization" v-model="organization" deselect-label="Can't remove this value" track-by="name" label="name" placeholder="Select organization" :options="organizations" :searchable="true" :allow-empty="true">
            </multiselect>
        </div>
        <div class="dashboard">
            <b-spinner small type="grow" v-if="loader"></b-spinner>
            <div class="d-flex justify-content-around mt-4" v-if="!loader">

                <div v-for="(item, index) in indicators" :key="index" class="price-week-box mr-5">
                    <span>{{ item.title }}</span>
                    <h2><span class="counter">{{ item.indicator }}</span>
                    </h2>
                </div>
            </div>
            <div v-if="!loader">
                <ApexChart :element="slug" :chartOption="chart5" :key="keyy" />
            </div>
        </div>
    </template>
</iq-card>
</template>

<script>
import api from '@/api/RestClient'
import Multiselect from "vue-multiselect"
const week = 1
const month = 2
export default {
    name: 'DashCard',
    props: {
        options: {
            type: Object,
            default: null
        }
    },
    components: {
        Multiselect
    },
    mounted() {
        this.setOption();
        this.getStats();
        this.setLink(this.option.period);
        this.setOrganization();
        this.setActiveOganization();
    },
    data() {
        return {
            chart5: {
                chart: {
                    height: 200,
                    type: 'line',
                    sparkline: {
                        enabled: true
                    },
                    stacked: false
                },
                stroke: {
                    width: [2, 3],
                    curve: 'smooth',
                    dashArray: [0, 5]
                },
                plotOptions: {
                    bar: {
                        columnWidth: '50%'
                    }
                },
                colors: ['#00ca00', '#007bff'],
                series: [],
                fill: {
                    opacity: [0.2, 1],
                    gradient: {
                        inverseColors: false,
                        shade: 'light',
                        type: 'vertical',
                        opacityFrom: 1,
                        opacityTo: 1,
                        stops: [0, 100, 100, 100]
                    }
                },
                markers: {
                    size: 0
                },
                xaxis: {
                    type: this.xaxis_type
                },
                yaxis: {
                    min: 0
                },
                tooltip: {
                    shared: true,
                    intersect: false,
                    y: {
                        formatter: function (y) {
                            if (typeof y !== 'undefined') {
                                return y.toFixed(0)
                            }
                            return y
                        }
                    }
                },
                legend: {
                    labels: {
                        useSeriesColors: true
                    },
                    markers: {
                        customHTML: [
                            function () {
                                return ''
                            },
                            function () {
                                return ''
                            },
                            function () {
                                return ''
                            }
                        ]
                    }
                }
            },
            showOrganization: false,
            component_use_month: [1, 2, 3, 4, 5],
            component_use_week: [1, 2, 3, 4, 5],
            component_use_day: [5],
            indicators: [],
            title: '***',
            slug: null,
            keyy: null,
            loader: true,
            labels: [],
            class_week: 'nav-link',
            class_month: 'nav-link',
            class_day: 'nav-link',
            week_or_month: 1,
            option: null,
            statsData: null,
            xaxis_type: 'datetime',
            organization: null,
            organizations: []
        }
    },
    methods: {
        loadSeries() {
            if (this.statsData) {
                this.statsData.series.forEach(serie => {
                    this.chart5.series.push(serie)
                });
            }
        },
        loadIndicators() {
            if (this.statsData) {
                this.indicators = this.statsData.indicators;
            }
        },
        setTitle() {
            if (this.option) {
                this.title = this.option.title;
            }
        },
        setXaxisType() {
            if (this.option) {
                this.xaxis_type = this.option.axis_type;
            }
        },
        chartElement() {
            if (this.option) {
                this.slug = this.option.element;
            }
        },
        chartKey() {
            if (this.option) {
                this.keyy = this.option.key;
            }
        },
        setOrganization() {
            if ([5].includes(this.option.key)) this.showOrganization = true;
        },
        setLoader() {
            if (this.statsData) {
                this.loader = this.statsData.loading;
            }
        },
        setLabel() {
            if (this.statsData) {
                this.chart5.labels = this.statsData.labels;
            }
        },
        setOption() {
            if (this.options) {
                this.option = this.options
            }
        },
        setWeekOrMonth(val = 0) {
            this.week_or_month = val;
        },
        getStatsOnWeek(event) {
            event.preventDefault()
            const period = 1;
            this.setWeekOrMonth(period)
        },
        getStatsOnDay(event) {
            event.preventDefault()
            const period = 0;
            this.setWeekOrMonth(period)
        },
        getStatsOnMonth(event) {
            event.preventDefault();
            const period = 2;
            this.setWeekOrMonth(period)
        },
        getStats() {
            const url = this.option.url;
            const period = this.option.period;
            const component = this.option.key;
            const account = this.organization;
            this.request(url, period, component, account).then(response => {
                const data = response.data;
                this.statsData = data.stats;
            }).catch(err => {
                console.log(err)
            });
        },
        request(url, interval, index, account = null) {
            const stats = new Promise((resolve, reject) => {
                const account_id = account ? '&account_id=' + account.value : '';
                const link = url + '/' + interval + '?c=' + index + account_id
                api.dashboard.statistics(link).then(response => {
                    resolve(response);
                }).catch(err => {
                    reject(err)
                });
            });
            return stats;
        },
        resetData() {
            this.chart5.series = [];
            this.chart5.labels = [];
        },
        setLink($period) {
            console.log($period);
            if ($period == 1) {
                this.class_month = 'nav-link'
                this.class_day = 'nav-link'
                this.class_week = 'nav-link active'
            } else if ($period == 2) {
                this.class_week = 'nav-link'
                this.class_day = 'nav-link'
                this.class_month = 'nav-link active'
            } else if ($period == 0) {
                this.class_week = 'nav-link'
                this.class_month = 'nav-link'
                this.class_day = 'nav-link active'
            }
        },
        setActiveOganization() {
            api.dashboard.accounts().then(response => {

                const organizations = response.map(item => {
                    const container = {};

                    container.name = item.name;
                    container.value = item.value;

                    return container;
                })
                this.organizations = organizations;
            });
        },
    },
    watch: {
        'statsData': {
            handler(newValue, oldValue) {
                this.loadSeries();
                this.loadIndicators();
                this.setTitle();
                this.setXaxisType();
                this.chartElement();
                this.chartKey();
                this.setLoader();
                this.setLabel();
            }
        },
        'week_or_month': {
            handler(newValue, oldValue) {
                this.setLink(newValue);
                this.loader = true;
                this.resetData();
                this.option.period = newValue;
                this.getStats();
            }
        },
        'organization': {
            handler(newValue, oldValue) {
                this.loader = true;
                this.resetData();
                this.getStats();
            }
        }
    }

}
</script>

<style>
.form-row {
    display: block !important;
}

.dashboard {
    text-align: center;
    margin-bottom: 5px;
}
</style>
