import store from "../../store";

export default function (axios) {

  return {

    uploadList: (data, progress,) => {

      if (process.env.NODE_ENV === 'production') {
        let axiosInstance = axios.create({
          baseURL: 'https://services.inboxgeek.com/api',
          timeout: 120000
        });

        
        axiosInstance.interceptors.request.use((config) => {
          if (config.noAuth) {
            return config;
          };
          const token = store.getters['Auth/tokenAs'] ?? store.getters['Auth/token'];
          config.headers.Authorization = "Bearer " + token;
          return config
        });

        return axiosInstance.post(`/platforms/webhook/lists`, data, progress) .then(response => response.data);

      }
      return axios.post(`/platforms/webhook/lists`, data, progress) .then(response => response.data);

    },
    uploadNewList: (id, data, progress) => {


      if (process.env.NODE_ENV === 'production') {
        let axiosInstance = axios.create({
          baseURL:'https://services.inboxgeek.com/api',
          timeout: 120000
        });

        return axiosInstance.post(`/platforms/webhook/lists/${id}`, data, progress)

      }
      return axios.post(`/platforms/webhook/lists/${id}`, data, progress)

    },
    removeList: (id, data, progress) => {

      if (process.env.NODE_ENV === 'production') {
        let axiosInstance = axios.create({
          baseURL: 'https://services.inboxgeek.com/api',
          timeout: 120000
        });

        return axiosInstance.post(`/platforms/webhook/lists/${id}/delete`, data, progress)

      }
      return axios.post(`/platforms/webhook/lists/${id}/delete`, data, progress)

    },
    fetchLists : (params) =>{

      return axios.get(`/platforms/webhook/lists`,  { params:params })  .then(response => response.data);
    },
    updateList: (data) => {

      return axios.put(`/platforms/webhook/lists`, data) .then(response => response.data);

    },
  }
}
