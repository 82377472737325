<template>
  <div>

  <multiselect ref="account-ref" :loading="loading" :disabled="loading" tag-placeholder="Manage account"
               placeholder="Select Suppression List" label="name" track-by="id" v-model="selected_lists" :options="suppression_lists"
               :multiple="true" :taggable="true" @input="onSelect">
  </multiselect>
  </div>
</template>




<script>
import multiselect from "vue-multiselect";

import api from "@/api";
  export default {
    name: 'SuppressionListSelect',
    props: ['existed_suppression_lists'],
    components : {
      multiselect
  },
    mounted() {
      this.fetchSuppressionLists();
    },
    data() {
      return {
        loading:true,
        selected_lists:[],
        suppression_lists:[],

      }
    },
    methods: {
      onSelect(){
        console.log('selected lists',this.selected_lists)
        this.$emit('selected',this.selected_lists);
      },
      fetchSuppressionLists() {

        api.suppressionList.index().then(response=>{
          this.loading=false;
          this.suppression_lists = response.data;

        })
      }
    }
  }
</script>