<template>
    <button @click="onClick($event, row.item, row.index)" type="button" :class="btnClass" data-toggle="tooltip"
        data-placement="top" :title="btnTitle">
        <i v-if="!isLoading" :class="iconClass" :title="iconTitle"></i>
        <b-spinner v-else small label="Small Spinner" type="grow"></b-spinner>
    </button>
</template>
<script>

export default {
    name: 'IgBtnDefault',
    props: ['row', 'btnClass', 'btnTitle', 'iconClass', 'iconTitle'],
    data() {
        return {
            isLoading: false,
        }
    },
    mounted() {
    },
    methods: {
        onClick(event, row, index) {
            // this.setState(true);
            this.$emit('onClick', { row: this.row, event })
        },
        setState(value = true) {
            if (typeof value === 'boolean') {
                this.isLoading = value
            }
        }
    }
}

</script>