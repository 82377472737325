<template>
<div>
    <h1 class="mb-0">Recover Password</h1>
    <p>Enter your email address and we'll send you an email with instructions to reset your password.</p>
    <form class="mt-4">

        <div class="form-group">
            <label for="email">Email address</label>
            <input type="email" class="form-control mb-0" v-model='email' id="email" aria-describedby="emailHelp" placeholder="Enter email">
            <div class="text-danger" id="password">{{errors.email}}</div>
        </div>

        <div class="d-inline-block w-100">

            <button type="submit" class="btn btn-primary float-right" @click.prevent="recoverPassword">
                <span class="spinner-grow spinner-grow-sm" v-show="spinner" role="status" aria-hidden="true"></span>
                Recover Password
            </button>
        </div>

    </form>
</div>
</template>

<script>
import api from '@/api/RestClient';
import {
    helper
} from '@/helpers'
export default {
    name: 'RecoverPassword1',
    data() {
        return {
            email: '',
            errors: [],
            spinner: false,
            fails: false,
        }
    },
    methods: {
        recoverPassword() {
            this.checkPassWordForm();
            if (helper.isEmpty(this.errors)) {
                this.spinner = true;
                api.auth.resetPassword(this.email).then(response => {
                        if (response.success) {
                            this.errors = [];
                            this.fails = false;
                            this.toast('Success!', 'success', response.message)
                            setTimeout(() => {
                                this.$router.push({
                                    name: 'auth1.confirm-mail1',
                                    params: {
                                        email: this.email
                                    }
                                });
                            }, 2500);

                        } else {
                            this.errors = response.data;
                            this.errors.map((error) => {
                                this.toast('warning!', 'danger', error)
                            })
                            this.spinner = false;
                        }
                    }).catch(err => {
                        this.toast('Oh!', 'danger', 'Something went wrong')
                    })
                    .finally(() => {
                        this.spinner = false;
                    })
            }
        },
        resetErrors() {
            this.errors = {}
        },
        checkPassWordForm() {
            this.resetErrors();
            if (!this.email) {
                this.email = 'Email required.';
            }
            if (!helper.validMail(this.email)) {
                this.errors.email = 'Email format did not matched.';
            }
        },
        toast(title = "success", variant = "success", message = "Account chargerd") {
            this.$bvToast.toast(message, {
                title: title,
                variant: variant
            })
        }
    }
}
</script>
