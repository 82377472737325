<template>
    <b-row>
        <b-col cols="7">
            <h3 class="text-left mb-4">{{ title }}</h3>
        </b-col>
        <b-col cols="5">
            <h2 class="steps">{{ steps }}</h2>
        </b-col>
    </b-row>
</template>
<script>
export default {
    name: 'StepTitle',
    components: {
    },
    props: {
        title: {
            type: [String],
            default: () => '--',
        },
        steps: {
            type: [String],
            default: () => 'Step __ - __',
        },
    },
}
</script>