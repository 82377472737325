export default function (axios) {
  return {
    list: (accountId) => {
      return axios.get(`/accounts/${accountId}/subscriptions`)
        .then(response => response.data);
    },
    getActive: (accountId) => {
      return axios.get(`/accounts/${accountId}/subscriptions/active`)
        .then(response => response.data);
    },
    post: (accountId, subscriptionData) => {
      return axios.post(`/accounts/${accountId}/subscriptions`, subscriptionData)
        .then(response => response.data);
    },
    update: (accountId, subscriptionData) => {
      return axios.put(`/accounts/${accountId}/subscriptions`, subscriptionData)
        .then(response => response.data)
    },
    changeSubscription: (accountId, subscriptionData) => {
      return axios.put(`/accounts/${accountId}/change-subscription`, subscriptionData)
        .then(response => response.data)
    },
    changeSubscriptionBeta: (accountId, subscriptionData) => {
      return axios.put(`/accounts/${accountId}/change-subscription/beta`, subscriptionData)
        .then(response => response.data)
    },
    subscribeSubscription: (accountId, data) => {
      return axios.put(`/accounts/${accountId}/subscribe-subscription`, data)
        .then(response => response.data)
    },
    cancelSubscription: (accountId, subscriptionData) => {
      return axios.put(`/accounts/${accountId}/cancel-subscription`, subscriptionData)
        .then(response => response.data)
    },
    reSubscription: (subscriptionId, subscriptionData) => {
      return axios.put(`/accounts/${subscriptionId}/re-subscription`, subscriptionData)
        .then(response => response.data)
    },
    createSubsPeriod: (data) => {
      return axios.post(`/subscription-periods`, data)
        .then(response => response.data);
    },

    updateSubsPeriod: (id, data) => {
      return axios.put(`/subscription-periods/${id}`, data)
        .then(response => response.data);
    },
    subsPeriodsLists: (subscription_id) => {
      return axios.get(`/subscriptions/${subscription_id}/subscription-periods`)
        .then(response => response.data);
    },
    updateSubscription: (id, subscriptionData) => {
      return axios.put(`/subscriptions/${id}`, subscriptionData)
        .then(response => response.data)
    },
    updateOption:(subscription_id,data) => {
      return axios.put(`/subscriptions/${subscription_id}/options`,data)
      .then(response => response.data);
    },
    fetchOptionVariables : () =>{
      return axios.get(`/services/subscriptions/options`)
      .then(response => response.data);
    },
    getOptions:(user_params,subscription_id = false )=>{

      if(subscription_id){


        let params = { 'subscription_id' : subscription_id ,'service_id' : user_params.service_id , 'account_id' :user_params.account_id };
        return axios.get(`/subscriptions/options`,{  params: params }).then(response => response.data);

      }

      return axios.get(`/subscriptions/options`,{  params: { 'service_id' : user_params.service_id , 'account_id' :user_params.account_id } }).then(response => response.data);

    },
    freeSubscription: (accountID, serviceID) => {
      return axios.post(`/accounts/${accountID}/free-subscription`, {service: serviceID})
        .then(response => response.data);
    },
    accountAlreadyUseService: (accountID, serviceID) => {
      return axios.get(`/accounts/${accountID}/use-service/${serviceID}`)
        .then(response => response.data);
    },

  }
}
