<template>
  <!-- loader Start -->
  <div v-show="isLoad">
    <div id="loading-center">
      <div class="loader">
        <div class="cube">
          <div class="sides">
            <div class="top"></div>
            <div class="right"></div>
            <div class="bottom"></div>
            <div class="left"></div>
            <div class="front"></div>
            <div class="back"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- loader END -->
</template>
<script>
export default {
  name: 'Loader',
  props: {
    isLoad: {
      type: Boolean,
      required: true,
      default: true,
    },
  }
}
</script>