<template>

  <div class="w-100">

    <b-row class="my-2">
      <b-col>
        <label for="">API Key: </label>
        <b-input @keyup="fetchProjects" v-model="platform.apikey" placeholder="API Key"></b-input>
      </b-col>
    </b-row>

    <b-row class="my-2" v-show="platform.apikey != null">
      <b-col>
        <label for="">Projects: </label>
        <multiselect
            v-model="selectedProject"
            :allow-empty="false"
            placeholder="Select project"
            label="name"
            track-by="id"
            :options="projects"
            :multiple="false"
            :taggable="true"
            :loading="isLoadingProjects" return="id" @select="selectPublication">
        </multiselect>

      </b-col>
    </b-row>
  
    <b-row>
      <b-col class="text-right mt-2">
        <button :disabled="!isAllowed" class="btn btn-primary" @click.prevent="createPlatform"> <b-spinner v-if="isLoading" small type="grow"></b-spinner>  Create Platform</button>
      </b-col>
    </b-row>
  
  
  
  </div>
  
  
  </template>
  
  <script>
  
  import api from '@/api/RestClient'
  import Multiselect from "vue-multiselect";


    export default {
      name: 'MailSendInput',
      components: {
        Multiselect,
      },
      props: ['platform_nickname'],
      mounted(){
        
      },
      
      data(){
        return {
          isDisabled:false,
          isLoading:false,
          isLoadingProjects:false,
          custom : {
            checkbox : false,
          },  
          platform: {
            account:null,
            apikey:null
          },
          projects:[],
          selectedProject:null
        }
      },
      methods: {
        selectPublication(project){
          this.platform.account = project.id;
        },
        fetchProjects(){
          this.isLoadingProjects = true;
          api.default.get(`platforms/mailsend/projects?token=${this.platform.apikey}`).then(response => {
              this.projects = response.data;
              this.isLoadingProjects = false;
          }).catch(error => {
            let message = error.response.data.errors.message;
            this.$swal('Error!', message, 'error');
            this.isLoadingProjects=false;
          });
        },
        createPlatform(){

          this.isLoading = true;
          this.isDisabled = true;

            api.platforms
          .verifyToken('mailsend',this.platform.account,this.platform.apikey)
          .then(()=>{
           
            this.$emit('createPlatform',this.platform);
            this.isLoading = false;
          }).catch((error)=>{
            this.isLoading = false;
            this.isDisabled = false;
            let swal = {
              icon: 'error',
              title: 'Failed',
              text: 'Failed to authenticate, please check your credentials and try again',
              type: 'error',
              confirmButtonText: 'OK',
              showCloseButton: true,
            }

            this.$swal(swal)

          });
        }
      },
        computed : {
            isAllowed(){
              return ( this.platform_nickname != null && this.platform_nickname.trim() != '' ) && ! this.isDisabled && this.platform.account != null && this.platform.apikey != null;
            }
        }
  
    }
  
  </script>