import getters from './getters'
import actions from './actions'
import mutations from './mutations'

const state = {
  loggedIn: false,
  isSubscribed: false,
  isClient: false,
  isAdmin: false,

  isAccounting: false,
  isManager: false,
  isAccountManager: false,

  token: null,
  tokenAs: null,
  user: [],
  userAdmin: null,
  liveOpenersSubscribed: false,
  liveLeadsSubscribed: false,
  livePlans:null,
  lastSubscriptions:null,
  UserSubscriptions:null,
  AccountServicePlans:{},
  CurrentAccountOptions: {},
  supportChatRoomID: null
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
