<template>

  <div class="w-100">

    <b-row class="my-2">
      <b-col>
        <label for="name">Account code:</label>
        <b-input v-model="fields.code" placeholder="login" autocomplete="off"> </b-input>
      </b-col>
    </b-row>

    <b-row class="my-2">
      <b-col>
        <label for="name">Username:</label>
        <b-input v-model="fields.username" placeholder="login" autocomplete="off"> </b-input>
      </b-col>
    </b-row>
      <b-row class="my-2">
      <b-col>
        <label for="">Password</label>
        <b-input type="password" v-model="fields.password" placeholder="password" autocomplete="off"></b-input>
      </b-col>
    </b-row>
  

    <b-row>
      <b-col class="text-right mt-2">
        <button :disabled="!isAllowed || loading" class="btn btn-primary"  @click.prevent="createPlatform">  <b-spinner v-if="loading" small type="grow"></b-spinner> Create Platform</button>
      </b-col>
    </b-row>

  
  </div>
  
  
  </template>
  
  <script>

import api from '@/api/RestClient'
export default {
      name: 'OngageInput',
      components: {
      },
      props: ['platform_nickname'],
      mounted(){
  
      },
      
      data(){
        return {
          loading:false,
          fields: {
            code:null,
            username:null,
            password:null
          },
          platform: {
            account:null,
            apikey:null
          }
        }
      },
      methods: {
        createPlatform(){

          this.isLoading  = true;
          this.isDisabled = true;
          
          this.platform.apikey = JSON.stringify(this.fields);

          api.platforms
          .verifyToken('ongage',this.platform.account,this.platform.apikey)
          .then(()=>{
           
            this.$emit('createPlatform',this.platform);
            this.isLoading = false;
            
          }).catch((error)=>{
            this.isLoading = false;
            this.isDisabled = false;
            let swal = {
              icon: 'error',
              title: 'Failed',
              text: 'Failed to authenticate, please check your credentials and try again',
              type: 'error',
              confirmButtonText: 'OK',
              showCloseButton: true,
            }

            this.$swal(swal)

          });


        }
      },
        computed : {
            isAllowed(){
                return this.platform_nickname != null && this.platform_nickname.trim() != ''; 
            }
        }
}
</script>