<template>
    <b-container fluid>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">Automations</h4>
                    </template>

                    <template v-slot:headerAction class="mt-3">

                        <b-form v-if="is_manager" class="mx-3">
                            <AccountsMultiselect ref="organization-select" @onSelect="onSelectAccount" />
                        </b-form>

                        <b-button variant="primary" :disabled="btnAddIsDisabled" @click="showModalAddAutomation">Add
                            New</b-button>

                    </template>

                    <template v-slot:body>

                        <!-- Custom Filters row -->
                        <b-row>
                            <b-col lg="12">
                                <div class="container-fluid">
                                    <b-row class="d-flex justify-content-end p-3">
                                        <b-button class="mb-2" v-b-tooltip.hover title="Filter" variant="primary"
                                            @click="isFilter = !isFilter"> <i class="fa fa-filter mr-0"></i> </b-button>
                                        <Transition name="fade">
                                            <b-col lg="12" md="12" v-show="isFilter" class="alert-secondary">

                                                <AutomationsFilters ref="dt-filters" @onFilter="onFilter"
                                                    service="ll_service" :platforms_array="platforms_co">
                                                </AutomationsFilters>
                                            </b-col>
                                        </Transition>
                                    </b-row>
                                </div>
                            </b-col>
                        </b-row>
                        <!-- End Custom Filters row -->

                        <DataTable v-if="renderDT" ref="automations-table" :url="'/ge-automations'"
                            v-bind="{ columns: columns, customCells: dtCells, organization: organization, filters: filters, selectable: true, rowSelect: [] }"
                            @onSwitch="handleSwitchAttr" @onClick="handleBtn" @sortChanged="sortChanged"
                            @onSelectItems="handleSelectItems" @bulkAction="bulk" @contextChanged="showChart = false" />

                    </template>
                </iq-card>
            </b-col>
        </b-row>

        <!-- Chart -->
        <b-row ref="records-chart" class="adminCharts">
            <b-col md="12" v-if="showChart">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4>{{ liveLeadAutomation.name }} Automation Records</h4>
                    </template>
                    <template v-slot:body>
                        <b-row class="justify-content-center my-4">
                            <b-form class="my-auto" inline>
                                <b-form-group label="" class="" label-cols-sm="2" label-for="from">
                                    <b-form-input class="px-1" type="date" v-model="options.dates.from"
                                        :max="options.dates.to" :value="options.dates.from"
                                        @input="graph(liveLeadAutomation)"></b-form-input>
                                </b-form-group>
                                <label class="my-auto px-2">To</label>
                                <b-form-group label="" class="" label-cols-sm="2" label-for="to">
                                    <b-form-input class="px-1" type="date" v-model="options.dates.to"
                                        :min="options.dates.from" :max="options.dates.max" :value="options.dates.to"
                                        @input="graph(liveLeadAutomation)"></b-form-input>
                                </b-form-group>
                            </b-form>
                        </b-row>
                        <b-row class="d-flex justify-content-center text-center">
                            <b-button-toolbar v-if="statsData" v-for="(item, index) in statsData.indicators"
                                :key="index" aria-label="">
                                <b-button-group size="sm" class="mr-0">
                                    <b-button @click="toggleChartSerie($event, item, index)"
                                        :style="{ 'background-color': recordsChart.bodyData.colors[index], 'border-color': recordsChart.bodyData.colors[index] }"
                                        :class="isHide(item.title) ? 'indicator-disabled' : ''">{{ item.title
                                        }}</b-button>

                                </b-button-group>
                                <b-input-group size="sm" :append="formatDecimal(item.indicator)" class="mr-3">
                                </b-input-group>
                            </b-button-toolbar>
                        </b-row>
                        <b-col v-if="chartLoading" md="12" class="text-center">
                            <b-spinner small type="grow"></b-spinner>
                        </b-col>
                        <ApexChart v-else-if="!chartLoading && showChart" :element="'slug'"
                            :chartOption="recordsChart.bodyData" :columnsHide="recordsChart.columnsHide" />
                    </template>
                </iq-card>
            </b-col>
        </b-row>
        <!-- Modal Add GE Sources -->
        <ModalAddGeAutomation :id="addModal" :organization="organization" @add="finishAdded"
            @addIntegration="createAutomation" :ref="addModal" />

        <!-- Modal Edit LiveLeads Source -->
        <ModalEditGeAutomation v-if="liveLeadAutomation" v-bind="{
            id: editModal,
            ref: editModal,
            automation: liveLeadAutomation,
            organization: liveLeadAutomation.account_id
        }" @add="finishAdded" @showAutomationModal="createAutomationForEdit" />


        <!-- Modals SendFlow -->
        <b-modal :id="sendFlowModal.id" size="md" title="Automation Test Flow" :ref="sendFlowModal.id" hide-footer>
            <TestFlow :automation_id="sendFlowModal.item.id" col=12></TestFlow>
        </b-modal>

        <!-- Modals Add Integration -->
        <ModalAddIntegration style="background-color: #f3f7fd" :id="modalIntegration" :ref="modalIntegration"
            :organizationId="organization" @hide="forceRerenderModalIntegration" />

        <!-- Modals SendFlow -->
        <b-modal id="time_logs" size="lg" title="Automation Histories" ref="time_logs" hide-footer>
            <AutomationLogs :sourceLogs="logs" :name="automationName"></AutomationLogs>
        </b-modal>

        <!-- Modals SendFlow -->
        <b-modal id="resend_form_modal" size="lg" title="Resend Automation Records" ref="resend_form_modal" hide-footer>
            <ResendAutomationForm :loader="automation_resend_records.finished" @onSubmit="handleResendForm" />
            <ResendAutomationList :items="automation_resend_records.resends"
                :is_loading="automation_resend_records.is_loading" @onEdit="editResendAutomation"
                @onTrash="trashResendAutomation" ref="resend_automation_list" />
        </b-modal>

    </b-container>
</template>

<script>
import { sofbox } from '@/config/pluginInit'
import { mapGetters } from 'vuex'
import store from '@/store/index'
import api from '@/api/RestClient'
import ModalAddGeAutomation from "./modals/ModalAddGeAutomation.vue";
import SourceModalEdit from "./modals/SourceModalEdit.vue";
import AutomationLogs from "./children/timeLineAutomations.vue";
import ResendAutomationForm from "./children/ResendAutomationForm.vue";
import ResendAutomationFormEdit from "./children/ResendAutomationFormEdit.vue";
import ResendAutomationList from "./children/ResendAutomationList.vue";
import GeScriptModal from "./modals/GeScriptModal.vue";
import DataTable from '@/components/inboxgeek/tables/DataTable.vue'
import AccountsMultiselect from "@/views/children/AccountsMultiselect.vue";
import IBGSwitchCell from '@/components/inboxgeek/tables/IBGSwitchCell.vue'
import AutomationsFilters from "./children/AutomationsFilters.vue";
import LLAutomationActions from '@/views/GetEmails/children/LLAutomationActions.vue'
import ApexChart from "@/components/sofbox/charts/ApexChart.vue"
import Integration from './children/IntegrationLiveLead.vue'
import { excelParser } from "@/helpers/excel-parser";
import moment from 'moment'
import { helper } from '@/helpers'
import _ from 'lodash'
import TestFlow from '@/components/inboxgeek/GeSendflow'
import AutomationListsAndPlatform from './children/AutomationListsAndPlatform.vue';
import AutomationReceptionLists from './children/AutomationReceptionLists.vue';
import AutomationTotalContacts from './children/AutomationTotalContacts.vue';
import DtAutomationPlatform from './children/DtAutomationPlatform.vue';
import ModalEditGeAutomation from "./modals/ModalEditGeAutomation.vue";
import ModalAddIntegration from "../Integration/modals/ModalAddIntegration.vue";
import {SERVICES} from "@/constantes";
import DtCheckboxItem from './children/DtCheckboxItem';

const PERIOD_DAY = 0;
const PERIOD_WEEK = 1;
const PERIOD_MONTH = 2;
const PERIOD_YEAR = 3;
const SERVICE_ID = 2;

export default {
    name: "AutomationList",
    components: {
        ModalAddGeAutomation,
        SourceModalEdit,
        GeScriptModal,
        AccountsMultiselect,
        DataTable,
        ApexChart,
        AutomationsFilters,
        TestFlow,
        Integration,
        ModalEditGeAutomation,
        ModalAddIntegration,
        AutomationTotalContacts,
        AutomationLogs,
        ResendAutomationForm,
        ResendAutomationFormEdit,
        ResendAutomationList
    },
    data() {
        return {
            admin: {
                account: { code: null, name: '' }
            },
            loading: true,
            renderDT: true,
            addModal: 'modal-add',
            editModal: 'modal-edit',
            modalIntegration: 'modal-add-integration',
            renderModalIntegration: true,
            organization: null,
            btnAddIsDisabled: !store.getters['Auth/isAdmin'] ? false : true,
            isFilter: false,
            dtCells: [
                {
                    key: 'select',
                    component: DtCheckboxItem,
                    event: 'onSelectItem',
                },
                {
                    key: 'platform',
                    component: DtAutomationPlatform,
                    event: null,
                },
                {
                    key: 'lists',
                    component: AutomationReceptionLists,
                    event: null,
                },
                {
                    key: 'total_contacts',
                    component: AutomationTotalContacts,
                    event: null,
                },
                {
                    key: 'status',
                    component: IBGSwitchCell,
                    event: 'onSwitch',
                    props: {
                        attribute: 'status',
                    }
                },
                {
                    key: 'actions',
                    component: LLAutomationActions,
                    event: 'onClick',
                }
            ],
            liveLeadAutomation: {},
            automation_code: null,
            yaxis: 5,
            periodCode: 0,
            showDates: true,
            loadAdminStats: false,
            chartLoading: false,
            showChart: false,
            options: { label: 'event_id', period: PERIOD_DAY, dates: {} },
            statsData: null,
            chartData: [],
            recordsChart: {
                title: '***',
                type: 'line-area',
                dot: 1,
                bodyData: {
                    chart: {
                        height: 350,
                        type: 'area',
                        stacked: false,
                        events: {
                            click: (event, chartContext, config) => {
                                let date = config.globals.categoryLabels[config.dataPointIndex];
                                if (date) {
                                    this.detailsRedirect(date)
                                }
                            },
                            legendClick: (chartContext, seriesIndex, config) => {
                                this.clearHideColumns(chartContext, seriesIndex)
                            }
                        },
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        width: [3, 3],
                        curve: 'smooth'
                    },
                    plotOptions: {
                        bar: {
                            columnWidth: '50%'
                        }
                    },
                    colors: ['#00ca00', '#0084ff', '#FEB019', '#5A2A27', '#FD6A6A', '#662E9B', '#D7263D', '#C5D86D', '#4CAF50'],
                    series: [],
                    fill: {
                        opacity: [0.5, 0.5],
                        gradient: {
                            inverseColors: false,
                            shade: 'light',
                            type: 'vertical',
                            opacityFrom: 0.85,
                            opacityTo: 0.55,
                            stops: [0, 100, 100, 100]
                        }
                    },
                    labels: [],
                    markers: {
                        size: 0
                    },
                    xaxis: {
                        type: 'category',
                        interval: 4,
                        tickAmount: 10
                    },
                    yaxis: {
                        min: 0,
                        max: () => this.yaxis,
                        tickAmount: 9
                    },
                    tooltip: {
                        shared: true,
                        intersect: false,
                        y: {
                            formatter: function (y) {
                                if (typeof y !== 'undefined') {
                                    return y.toFixed(0)
                                }
                                return y
                            }
                        },
                        x: {},
                    },
                    legend: {
                        labels: {
                            useSeriesColors: true
                        },
                        markers: {
                            customHTML: [
                                function () {
                                    return ''
                                },
                                function () {
                                    return ''
                                },
                                function () {
                                    return ''
                                }
                            ]
                        }
                    }
                },
                columnsHide: [],

            },
            tabsPeriod: [
                { code: PERIOD_DAY, name: 'Days', format: 'YYYY-MM-DD', offset: 30 },
                { code: PERIOD_WEEK, name: 'Weeks', format: 'YYYY-MM-DD', offset: 10 },
                { code: PERIOD_MONTH, name: 'Months', format: 'YYYY-MM', offset: 6 },
                { code: PERIOD_YEAR, name: 'Years', format: 'YYYY', offset: 1 },
            ],

            filters: {},
            sendFlowModal: {
                id: 'send-flow-modal',
                title: '',
                item: {}
            },
            form: {},
            is_manager: false,
            automationName: '***',
            automation_id: null,
            logs: [],
            automation_resend_records: {
                finished: false,
                is_loading: false,
                resends: [],
                resend: null
            }
        }
    },
    computed: {
        ...mapGetters({
            isAdmin: 'Auth/isAdmin',
            isClient: 'Auth/isClient',
            user: 'Auth/user',
            UserSubscriptions: 'Auth/UserSubscriptions',
            platforms_co: 'platformConfigs'
        }),
        columns() {
            let columns = [
                { label: '', key: 'select', class: 'text-center', sortable: false },
                { label: 'Name', key: 'name', class: 'text-left', sortable: true, sortDirection: 'desc' },
                { label: 'Source', key: 'source.name', class: 'text-center', sortable: true, sortDirection: 'desc' },
                { label: 'Platform', key: 'platform', class: 'text-center', sortable: false, sortDirection: 'desc' },
                { label: 'Reception List', key: 'lists', class: 'text-center', sortable: false, sortDirection: 'desc' },
                { label: 'Organization', key: 'account.name', class: 'text-center', sortable: false, sortDirection: 'desc' },
                { label: '#records', key: 'total_contacts', class: 'text-center', sortable: false, sortDirection: 'desc' },
                {
                    label: 'Created at', key: 'created_at', class: 'text-center', sortable: true, sortDirection: 'desc',
                    formatter: (value, key, item) => {
                        let dt = new Date(value);
                        return helper.formatDate(dt)
                    }
                },
                { label: 'Status', key: 'status', class: 'text-center', sortable: true, sortDirection: 'desc' },
                { label: 'Actions', key: 'actions', class: 'text-center', sortable: false, sortDirection: 'desc' },
            ];

            if (!this.isAdmin) {
                // Remove Organization
                _.remove(columns, {
                    key: 'account.name'
                });
            }

            return columns;
        }
    },
    mounted() {
        sofbox.index();
        this.initIntervalDates();
        this.isManager();
    },
    methods: {
        isManager() {
            this.is_manager = helper.hasRoles(['ROLE_ADMIN', 'ROLE_SUPER_ADMIN', 'ROLE_ACCOUNTS_MANAGER']);
        },
        handleResendForm(data) {
            this.automation_resend_records.finished = false;
            api.geAutomations.resend_records(this.automation_id, data).then((response) => {
                this.automation_resend_records.is_loading = true;
                this.automation_resend_records.resends = response.data;
                this.$root.$bvToast.toast(response.message, {title: 'Good!',variant: 'success'})
            }).catch((error)=>{
                this.$root.$bvToast.toast(error.response.data.message, {title: 'Error!',variant: 'danger'})
            }).finally(() => {
                this.automation_resend_records.finished = true;
                
            });
        },
        onSelectAccount(accountSelected) {
            this.chartLoading = false;
            this.showChart = false;

            if (this.is_manager) {
                if (accountSelected) {
                    this.btnAddIsDisabled = false
                    this.admin.account = accountSelected
                    this.organization = this.admin.account.code

                    api.subscriptions.getOptions({
                      service_id : SERVICES.LIVE_LEADS.id,
                      account_id :  this.organization
                    }).then((response)=>{
                      //response : {"time_spent": false, "validation": true, "suppression": true, "event_action": false}
                      this.$store.dispatch('Auth/updateCurrentAccountOptions', { service_id :SERVICES.LIVE_LEADS.id , options :  response.data });
                    });
                } else {
                    delete this.admin.account
                    this.btnAddIsDisabled = true
                    accountSelected = null
                    this.organization = null
                }
            }


            this.forceRenderTable();
        },
        forceRenderTable() {
            this.renderDT = false;
            this.showChart = false

            this.$nextTick(() => {
                this.renderDT = true;
            });
        },
        finishAdded($event) {
            this.showChart = false;

            this.updateItem($event);
            // this.$root.$emit('bv::refresh::table', this.$refs['automations-table'].id)
        },
        handleSwitchAttr(data) {

            let showPreAlert = null;
            let checkShowAlert = null;

            if (!data.input || !data.row || !data.key) {
                return
            }

            checkShowAlert  = !this.isClient ? {state: null} : helper.showAlertOutOfEventOrEmptyBalance(SERVICE_ID);
            if (checkShowAlert.state && checkShowAlert.alert && data.input.checked) {
                showPreAlert = true;
            }

            const updateRowState = (data) => {
                let itemCode = data.row.id
                let form = {}
                form[data.key] = data.input.checked ? 1 : 0

                let toast = { message: 'Something went wrong!', title: 'Error', variant: 'danger' }

                return api.geAutomations.update_status(itemCode, form)
                    .then(result => {
                        toast = { message: result?.message, title: 'Success', variant: 'success' }
                    })
                    .catch((error) => {
                        data.input.checked = !data.input.checked;
                        let errors = [];
                        toast.message = error.response.data.message;

                    })
                    .finally(() => {
                        this.$root.$bvToast.toast(toast.message, toast);
                    });
            }


            if(showPreAlert && checkShowAlert?.state && checkShowAlert?.alert) {

                return (this.$swal(checkShowAlert.alert))
                    .then((result) => {
                        updateRowState(data)
                    })
                    
            } else {

                updateRowState(data)

            }


        },
        handleBtn(data) {

            this.showChart = false;
            let showPreAlert = null;
            let checkShowAlert = null;

            if (!data.event || !data.row) {
                return
            }

            // Check Inactive Account
            if(this.user && this.user.account && !this.user.account.status) {
                helper.alertAccountInactive(this.$swal);
                return;
            }

            let action = data.event.title.toLowerCase();
            this.liveLeadAutomation = data.row;

            checkShowAlert  = !this.isClient ? {state: null} : helper.showAlertOutOfEventOrEmptyBalance(SERVICE_ID);
            if (checkShowAlert.state && checkShowAlert.alert) {
                showPreAlert = true;
            }

            if (typeof data.row.id !== "undefined") {
                if (action == 'detail') {
                    this.detailsRedirect()
                } else if (action == 'delete') {
                    this.removeItem(data.row)
                } else if (action == 'test') {

                    // ! TODO - Create script to test flow for automation
                    this.sendFlowModal.item = data.row;
                    this.$root.$emit('bv::show::modal', this.sendFlowModal.id);

                    // this.testFlow(data.row);

                } else if (action == 'edit') {

                    if(showPreAlert && checkShowAlert?.state && checkShowAlert?.alert) {

                        return (this.$swal(checkShowAlert.alert))
                            .then((result) => {
                                if (data.ref && typeof data.ref.setState == 'function') {
                                    data.ref.setState(false);
                                }
                                this.$root.$emit('bv::show::modal', this.editModal);
                            })
                    } else {
                        helper.delay(1000).then(() => {
                            this.$root.$emit('bv::show::modal', this.editModal);
                        });
                    }

                } else if (action == 'restore') {

                    if(showPreAlert && checkShowAlert?.state && checkShowAlert?.alert) {

                        return (this.$swal(checkShowAlert.alert))
                            .then((result) => {
                                if (data.ref && typeof data.ref.setState == 'function') {
                                    data.ref.setState(false);
                                }
                                this.restoreItem(data.row)
                            })
                    } else {
                        this.restoreItem(data.row)
                    }

                } else if (action == 'chart') {
                    this.graph(this.liveLeadAutomation);
                }else if (action == 'logs') {
                    this.logs = data.row.logs;
                    this.automationName = data.row.name;
                    this.$refs['time_logs'].show();
                }else if (action == 'resend') {
                    this.automation_id = data.row.id;//eric
                    this.automation_resend_records.resends = data.row.resends;
                    this.$refs['resend_form_modal'].show();
                }
            }
            helper.delay(2000).then(() => {
                if (data.ref && typeof data.ref.setState == 'function') {
                    data.ref.setState(false);
                }
            });

        },
        removeItem(item) {

            if (!item.id) {
                return
            }

            this.$swal({
                title: 'Are you sure?',
                html: `<p>Once you delete this automation.</p>`,
                icon: 'question',
                showCancelButton: true,
                confirmButtonText: 'Yes Delete it!',
                cancelButtonText: 'No, Keep it!',
                showCloseButton: true,
                showLoaderOnConfirm: true
            }).then((result) => {
                if (result.value) {
                    let swalObj = {
                        icon: 'success',
                        confirmButtonText: 'OK',
                        showCloseButton: true,
                    }

                    api.geAutomations.delete(item.id)
                        .then(result => {
                            swalObj.title = 'Success!';
                            swalObj.text = `LiveLeads Automation ${item.name} Deleted Successfully.`;
                        })
                        .catch(function (error) {
                            swalObj.icon = 'warning';
                            swalObj.title = 'Warning!';
                            swalObj.text = error.response.data.message;
                        })
                        .finally(() => {
                            this.$swal(swalObj)
                            this.$root.$emit('bv::refresh::table', this.$refs['automations-table'].id)
                        });
                }
            })
        },
        restoreItem(item) {

            if (!item.id) {
                return
            }

            this.$swal({
                title: 'Are you sure restore Item?',
                html: `<p>Restore automation ${item.name}.</p>`,
                icon: 'question',
                showCancelButton: true,
                confirmButtonText: 'Yes Delete it!',
                cancelButtonText: 'No, Keep it!',
                showCloseButton: true,
                showLoaderOnConfirm: true
            }).then((result) => {
                if (result.value) {
                    let swalObj = {
                        icon: 'success',
                        confirmButtonText: 'OK',
                        showCloseButton: true,
                    }

                    api.geAutomations.restore(item.id)
                        .then(result => {

                            if (result.success) {
                                swalObj.title = 'Success!';
                                swalObj.text = result.message ?? `LiveLeads Automation ${item.name} Restore Successfully.`;
                                this.$root.$emit('bv::refresh::table', this.$refs['automations-table'].id)
                            }


                        })
                        .catch(function (error) {
                            swalObj.icon = 'warning';
                            swalObj.title = 'Warning!';
                            swalObj.text = error.response.data.message;
                        })
                        .finally(() => {
                            this.$swal(swalObj)
                        });
                }


            })


        },
        testFlow(item) {
            let alert = {
                title: 'Test Flow',
                text: 'Error occurred, Please try later!'
            }

            const comp = this
            this.$swal.fire({
                title: 'Automation Testing Flow',
                input: 'text',
                inputAttributes: {
                    placeholder: 'Email',
                    autocapitalize: 'off',
                },
                showCancelButton: true,
                confirmButtonText: 'Send Test Flow',
                showLoaderOnConfirm: true,
                preConfirm: (email) => {
                    return api.geAutomations.sendTestFlow(item.platform.code, email)
                        .then(response => {
                            alert.text = response.message
                        })
                        .catch(error => {
                            console.log(error)
                            this.$bvToast.toast(error.response.data.message, {
                                title: 'Error',
                                variant: 'danger',
                            })
                        })
                },
                allowOutsideClick: () => !comp.$swal.isLoading()
            }).then((result) => {
                if (result.isConfirmed) {
                    comp.$swal.fire(alert)
                }
            })
        },
        exportData(data, fileName = null, type = null) {
            excelParser().exportDataFromJSON(data, fileName, type);
        },
        sortChanged(ctx) {
            this.showChart = false
            this.chartLoading = false
        },
        initIntervalDates() {
            let date = moment();
            let minDate = date.clone();

            const period = this.options.period;
            let currentPeriod = _.find(this.tabsPeriod, function (o) { return period == o.code; });

            minDate.subtract(currentPeriod.offset, currentPeriod.name.toLowerCase())

            this.options.dates.to = date.format('YYYY-MM-DD');
            this.options.dates.from = minDate.format('YYYY-MM-DD');
            this.options.dates.max = date.format('YYYY-MM-DD');
        },
        setYAxis() {
            const max = this.getMaxSerie(this.recordsChart.bodyData.series);

            let addMarge = max ? (max + (max * 10) / 100) : 10;

            this.yaxis = max + Math.ceil(addMarge); // set Chart bodyData.yaxis.max dynamically

            this.recordsChart.bodyData.yaxis = {
                min: 0,
                max: addMarge,
                tickAmount: 5,
            }
        },
        arrayMax(arr) {
            let len = arr.length,
                max = -Infinity;
            while (len--) {
                if (Number(arr[len]) > max) {
                    max = Number(arr[len]);
                }
            }
            return max;
        },
        getMaxSerie(series = []) {
            if (!series) return 0;
            let elts = [];
            series.forEach(serie => {
                elts.push(this.arrayMax(serie.data))
            });
            return this.arrayMax(elts);
        },
        getChartDataFormatted(response, format = 'YYYY-MM-DD', comparator = 'day') {
            // this.recordsChart.columnsHide = [];

            let chartData = {
                indicators: [],
                series: [],
                labels: [],
            }

            if (typeof response.data === "undefined") {
                return chartData
            }

            const that = this
            const data = response.data;

            let startDate = moment(data.period.from, format);
            let endDate = moment(data.period.to, format);

            chartData.labels = this.getLabelsFromRangeDates(startDate, endDate, format, `${comparator}s`);

            data.items.forEach(item => {
                let graphItems = _.filter(data.data, function (o) {

                    let label = null;
                    if (typeof that.options.label !== "undefined") {
                        label = (o.hasOwnProperty(that.options.label))
                            ? that.options.label : null
                    }

                    return item.id == o[label];
                });

                let indicatorPlan = {
                    title: item.name,
                    indicator: `${this.getSumItemsByAttr(graphItems)}`
                }
                if ((typeof item.disabled !== 'undefined' && item.disabled)) {
                    let alreadyHide = _.find(that.recordsChart.columnsHide, function (o) { return indicatorPlan.title == o; });

                    if (!alreadyHide) {
                        this.recordsChart.columnsHide.push(indicatorPlan.title)
                    }
                }
                chartData.indicators.push(indicatorPlan)

                let seriePlan = {
                    name: item.name,
                    type: `area`,
                    data: this.getDataChartByItemsAndLabels(chartData.labels, graphItems, comparator)
                }
                chartData.series.push(seriePlan)
            });

            // Update X tooltip
            this.recordsChart.bodyData.tooltip.x = {
                formatter: function (x, series, labels = chartData.labels) {
                    return labels[series.dataPointIndex]
                }
            }

            if (comparator == 'week') {
                chartData.labels = this.getLabelsFromRangeDates(startDate, endDate, '[Week ]WW-YYYY', `${comparator}s`);
            }
            return chartData
        },
        getLabelsFromRangeDates(startDate, endDate, format = 'YYYY-MM-DD', interval = 'days') {

            let now = startDate.clone(), dates = [];

            while (now.isSameOrBefore(endDate)) {
                dates.push(now.format(format));
                now.add(1, interval);
            }

            return dates;
        },
        getDataChartByItemsAndLabels(labels, items = [], comparator = 'day') {
            let data = [];
            labels.forEach(label => {
                let graphItems = _.filter(items, function (o) { return moment(o.date).isSame(label, comparator); });
                data.push(this.getSumItemsByAttr(graphItems))
            });
            return data;
        },
        getSumItemsByAttr(items, attr = 'total') {
            const sum = items.reduce((accumulator, object) => {
                if (!Object.hasOwn(object, attr) || object.event_id == 'all_time') {
                    return accumulator + 0;
                }
                return accumulator + parseInt(object[attr]);
            }, 0);
            return sum.toFixed(0);
        },
        loadGeSource(sourceID) {
            api.api.geAutomations.get(sourceID)
                .then(response => {
                    if (response.success) {
                        this.liveLeadAutomation = response.data
                    }
                })
                .catch(error => {
                    this.automation_code = null
                    this.liveLeadAutomation = {}
                    this.$bvToast.toast(error.response.data.message, {
                        title: 'Error',
                        variant: 'danger',
                    })
                })
        },
        detailsRedirect(dateFrom = null, dateTo = null, serie = null) {
            return this.redirectToRecordsTable(dateFrom, dateTo, serie)
        },
        redirectToRecordsTable(dateFrom = null, dateTo = null, serie = null) {
            let message = `You will be redirect to LiveLeads Records page`;
            if (dateFrom && dateTo) {
                message += ` from ${dateFrom} to ${dateTo}`;
            } else if (!dateTo && dateFrom) {
                message += ` ${dateFrom}`;
            } else if (!dateFrom && dateTo) {
                message += ` ${dateTo}`;
            }

            return this.$swal({
                title: 'Are you sure?',
                text: message,
                type: 'info',
                confirmButtonText: 'Yes',
                showCancelButton: true,
                showCloseButton: true,
                showLoaderOnConfirm: true
            }).then((result) => {
                if (result.value) {
                    let params = {};
                    let query = {};

                    if (typeof this.liveLeadAutomation !== 'undefined') {
                        params.id = this.liveLeadAutomation.source_id
                    }

                    if (this.showChart) {
                        query.from = this.options.dates.from;
                        query.to = this.options.dates.to;
                    }

                    if (dateFrom) {
                        query.from = dateFrom
                    }
                    if (dateTo) {
                        query.to = dateTo
                    }
                    if (serie) {
                        query.serie = serie
                    }

                    this.$router.push({ name: 'liveleads.records', params, query })
                }
            })
        },
        resetChartData() {
            this.recordsChart.bodyData.series = [];
            this.recordsChart.bodyData.labels = [];
        },
        onFilter(data) {
            this.showChart = false;
            this.filters = this.filterTable(data);

            this.renderDT = false;
            this.$nextTick(() => {
                this.renderDT = true;
            });

            helper.delay(2000).then(() => {
                if (typeof this.$refs['dt-filters'].finishFilter === 'function') {
                    this.$refs['dt-filters'].finishFilter()
                }
            })
        },
        filterTable(params) {
            let filters = {
                active: params.status?.code ?? '',
                clean: params.clean?.code ?? '',
                platforms: params.platforms ? _.map(params.platforms, 'name') : [],
                date_to: params.date_to ?? '',
                date_from: params.date_from ?? '',
                platform_account: params.platform_account ?? ''
            };

            return filters;
        },
        createAutomation(event) {
            // this.form = event;
            console.log('Show Integration Modal Add', this.form, event)
            this.$refs[this.addModal].hideModal();
            this.$root.$emit('bv::show::modal', this.modalIntegration)
            console.log('Modal Integration Must be showing')
        },
        createAutomationForEdit(event) {
            this.form = event;
            this.$refs[this.editModal].hideModal();
        },
        clearHideColumns(chartContext, index) {
            let currIndicator = this.statsData.indicators[index];

            const that = this;
            if (_.includes(this.recordsChart.columnsHide, currIndicator.title)) {
                this.recordsChart.columnsHide = _.remove(this.recordsChart.columnsHide, function (popItem) {
                    return popItem !== currIndicator.title;
                });
            } else {
                let alreadyHide = _.find(that.recordsChart.columnsHide, function (o) { return currIndicator.title == o; });

                if (!alreadyHide) {
                    this.recordsChart.columnsHide.push(currIndicator.title)
                }
            }
        },
        graph(item) {

            this.chartLoading = true
            let options = {
                "automation_id": item.id,
                "from": this.options.dates.from,
                "to": this.options.dates.to,
            }
            api.geAutomations.automation_chart_records(item.id, this.options.dates.from, this.options.dates.to)
                .then(response => {

                    if (response.success) {
                        this.statsData = this.getChartDataFormatted(response);
                        this.recordsChart.bodyData.series = this.statsData.series;
                        this.recordsChart.bodyData.labels = this.statsData.labels;

                        this.chartData = response.data.data

                        this.showChart = false;
                        this.$nextTick(() => {
                            this.showChart = true;
                        });
                    }

                })
                .catch(err => {
                    console.log(err)
                    this.$bvToast.toast(err.response.data.message, {
                        title: 'Error',
                        variant: 'danger',
                    })
                })
                .finally(() => {
                    helper.delay(2000).then(() => {
                        this.chartLoading = false;
                    });

                    this.showChart = true
                    $([document.documentElement, document.body]).animate({
                        scrollTop: $(".adminCharts").offset().top
                    }, 2000);

                })

        },
        toggleChartSerie($event, item) {
            $event.preventDefault();
            this.detailsRedirect(this.options.dates.from, this.options.dates.to, item.title);
        },
        isHide(name) {
            return _.includes(this.recordsChart.columnsHide, name)
        },
        formatDecimal(nStr) {
            nStr += '';
            var x = nStr.split('.');
            var x1 = x[0];
            var x2 = x.length > 1 ? '.' + x[1] : '';
            var rgx = /(\d+)(\d{3})/;
            while (rgx.test(x1)) {
                x1 = x1.replace(rgx, '$1' + ',' + '$2');
            }
            return x1 + x2;
        },
        forceRerenderModalIntegration() {
            this.renderModalIntegration = false;
            this.$nextTick(() => {
                this.renderModalIntegration = true
            })
            if (this.isAdmin) {
                setTimeout(() => {
                    this.onSelectAccount(this.admin.account)
                }, 500)
            }

        },
        handleSelectItems($event) {
            console.log('handleSelectItems', $event )
        },
        bulk(data) {
            let showAlert = null;
            let checkShowAlert = null;

            // Check Inactive Account
            if(this.user && this.user.account && !this.user.account.status) {
                helper.alertAccountInactive(this.$swal);
                return;
            }

            if (typeof data.action == 'undefined' || typeof data.items == 'undefined' || (data.items && !data.items.length)) {
                return
            }

            const that = this.$refs['automations-table'];

            let bulkData = { active: -1 }

            let alertObject = {
                title: 'Are you sure?',
                text: `This action will permenantly delete ` + (this.$refs['automations-table'].itemsSelected.length ? ` ${(this.$refs['automations-table'].itemsSelected.length)} Automation${(this.$refs['automations-table'].itemsSelected.length > 1 ? 's' : '')}` : ''),
                type: 'danger',
                showCancelButton: true,
                confirmButtonText: 'Delete',
                showCloseButton: true,
                showLoaderOnConfirm: true,
                confirmButtonColor: '#e64141',
                showLoaderOnConfirm: true,
                allowOutsideClick: () => !this.$swal.isLoading()
            }

            switch (data.action) {
                case 'turn-on':

                    checkShowAlert  = !this.isClient ? {state: null} : helper.showAlertOutOfEventOrEmptyBalance(SERVICE_ID);

                    if (checkShowAlert.state && checkShowAlert.alert) {
                        showAlert = true;
                    }
                    
                    alertObject.type = 'success'
                    alertObject.text = `If your ` + `${(this.$refs['automations-table'].itemsSelected.length ?? '')}` + ` Automation${(this.$refs['automations-table'].itemsSelected.length > 1 ? 's' : 'Automation')}` + ` shares contacts with any other synced list on the InboxGeek platform, events will begin sending as soon as today.`
                    alertObject.confirmButtonText = 'Turn on'
                    alertObject.confirmButtonColor = '#00ca00'
                    bulkData = { active: 1 }
                    break

                case 'turn-off':
                    alertObject.type = 'question'
                    alertObject.text = `If an Automation is turned off for longer than 1:00a EST the following day, the contacts synced to that list will need 24 hours to re-sync once the Automation is turned on again.`
                    alertObject.confirmButtonText = 'Turn off'
                    alertObject.confirmButtonColor = '#0084ff'
                    bulkData = { active: 0 }
                    break

                case 'delete':
                    bulkData = null
                    break

                default:
                    break
            }


            let swalObj = {
                type: 'success',
                confirmButtonText: 'OK',
                showCloseButton: true,
            }

            alertObject.preConfirm = () => {

                that.$swal.update({
                    title: 'In Progress', text: 'Please wait, action in progress ...', showConfirmButton: false, allowOutsideClick: false, showCloseButton: false,
                })

                if (data.action == 'delete') {

                    return api.geAutomations.bulkDelete(data.items)
                        .then(result => {
                            swalObj.title = 'Delete!';
                            swalObj.text = result.message;
                            this.indeterminate = false
                        })
                        .catch(function (error) {
                            swalObj.type = 'warning';
                            swalObj.title = 'Warning!';
                            swalObj.text = error.response.data.message;

                    }).finally(() => {
                        this.$bvToast.toast(swalObj.text, {
                            title: swalObj.title,
                            variant: swalObj.type,
                        })
                        })
                }

                return api.geAutomations.bulk(data.items, bulkData)
                    .then(result => {
                        swalObj.title = 'Success!';
                        swalObj.text = result.message;
                        this.indeterminate = false
                    })
                    .catch(function (error) {
                        swalObj.type = 'warning';
                        swalObj.title = 'Warning!';
                        swalObj.text = error.response.data.message;
                }).finally(() => {
                    this.$bvToast.toast(swalObj.text, {
                        title: swalObj.title,
                        variant: swalObj.type,
                    })
                    })

            };

            const bulkConfirmAction = (obj) => {

                that.$swal(alertObject)
                    .then((result) => {
                        this.$refs['automations-table'].itemInOperate = false;

                        if (result.isConfirmed) {
                            this.$refs['automations-table'].selected = [];
                            this.$refs['automations-table'].selectAll = false;
                            that.$swal(swalObj)
                            this.$refs['automations-table'].$root.$emit('bv::refresh::table', this.$refs['automations-table'].id);
                        }
                    })

            }

            if(showAlert && checkShowAlert?.state && checkShowAlert?.alert) {

                return (this.$swal(checkShowAlert.alert))
                    .then((result) => {
                        bulkConfirmAction(alertObject)
                    })
            } else {
                bulkConfirmAction(alertObject)
            }
                

        },
        showModalAddAutomation(e) {
            e.preventDefault();

            // Check Inactive Account
            if(this.user && this.user.account && !this.user.account.status) {
                helper.alertAccountInactive(this.$swal);
                return;
            }

            let checkShowAlert  = !this.isClient ? {state: null} : helper.showAlertOutOfEventOrEmptyBalance(SERVICE_ID);


            if (checkShowAlert.state && checkShowAlert.alert) {
                this.$swal(checkShowAlert.alert);
                return;
            } else {
                this.$root.$emit('bv::show::modal', this.addModal)
            }
        },
        showAlertRemaining() {
            let subscription = this.UserSubscriptions[2];
            console.log( subscription )

            let date = subscription ? subscription.ends_at : '--';

            let alertMessage = `It\'s appears you are out of records until [${date}]. If you\'d like to activate your overconsumption feature to continue sending events until your next billing cycle, please visit your dashboard`;

            this.$swal({
                title: 'Uh, oh...',
                text: alertMessage,
                type: 'info',
                confirmButtonText: 'Got it!',
                showCloseButton: true,
            })
        },
        updateItem(resultData) {

            const DT = this.$refs['automations-table'].$refs[this.$refs['automations-table'].id];
            const index = DT.$data.localItems.findIndex((item) => item.id === resultData.id);

            if (index !== -1) {

                const itemUpdated = { ...this.liveLeadAutomation, ...resultData }
                this.$set(DT.$data.localItems, index, itemUpdated);
            } else {

                this.$root.$emit('bv::refresh::table', this.$refs['automations-table'].id)

            }
        },
        trashResendAutomation(item) { 
            let status = item.status;
            this.$swal({
                title: 'Are you sure?',
                text: 'The pause will be permanent',
                type: 'info',
                confirmButtonText: 'Yes',
                showCancelButton: true,
                showCloseButton: true,
                showLoaderOnConfirm: true
            }).then((result) => {
                
                if (result.value) {
                    api.geAutomations.stop_resend(item.id)
                        .then(result => {
                            swalObj.title = 'Success!';
                            swalObj.text = result.message;
                            this.indeterminate = false
                            status = -2;
                        })
                        .catch(function (error) {
                            swalObj.type = 'warning';
                            swalObj.title = 'Warning!';
                            swalObj.text = error.response.data.message;
                        }).finally(() => {
                            this.$bvToast.toast(swalObj.text, {
                                title: swalObj.title,
                                variant: swalObj.type,
                            })
                        })
                }

                api.geAutomations.fetch_resend_records(item.automation_id).then((response) => {
                    this.automation_resend_records.resends = response.data;
                })
               
            })//eric
        },
        editResendAutomation(item) {
            console.log(item);
            this.automation_resend_records.resend = item;
            this.$refs['resend_form_modal'].hide();
            this.$refs['resend_form_modal_edit'].show();
        },
    },
    watch: {
        'periodCode': {
            handler(newValue, oldValue) {
                this.options.period = newValue;

                this.resetChartData();
                this.initIntervalDates();
                // if (this.liveLeadAutomation) {
                //     this.graph(this.liveLeadAutomation)
                // }
            },
            deep: true
        },
        'statsData': {
            handler(newValue, oldValue) {
                this.setYAxis();
            }
        },
    },
};
</script>
<style>
.indicator-disabled {
    opacity: 0.65;
}
</style>
