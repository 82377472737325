<template>
  <div class="container-fluid p-0">
    <div class="row no-gutters">
      <div class="col-sm-12 text-center">
        <div class="iq-error" style="    display: flex;flex-direction: column;justify-content: center;">
          <h2>Maintenance Mode</h2>
          <h4 class="mb-0">We adding some new features, we will be back in a few moments, please check again after few secondes</h4>
          <p>The requested page dose not exist.</p>
          <div class="text-center">
            <a class="btn btn-primary mt-3" href="/"><i class="ri-home-4-line"></i>Back to Home</a>

          </div>
        </div>
        <img :src="require('../../assets/images/error/01.png')" class="img-fluid iq-error-img" alt="error">

      </div>
    </div>
  </div>
</template>
<script>
import { sofbox } from '../../config/pluginInit'

export default {
  name: 'ErrorPage',
  mounted () {
    sofbox.index()
  }
}
</script>
