import { render, staticRenderFns } from "./OngageListInput.vue?vue&type=template&id=6b7794fc&scoped=true"
import script from "./OngageListInput.vue?vue&type=script&lang=js"
export * from "./OngageListInput.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b7794fc",
  null
  
)

export default component.exports