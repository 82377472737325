<template>
<b-container fluid>

    <!-- Table title -->
    <b-row class="mb-3">
        <b-col lg="6" class="my-1">
            <h4 class="card-title">Invoices</h4>
        </b-col>
        <b-col v-show="isAdmin" lg="6" class="my-1">
            <b-button v-b-modal.modal-new-account variant="primary" @click="new_invoice" class="text-right mr-1 pull-right">New invoice <i class="fa fa-plus ml-2"></i></b-button>
        </b-col>
    </b-row>

    <!-- User Interface controls -->
    <b-row class="mb-3">

        <b-col lg="4" class="my-1">
            <b-input-group size="md">
                <b-form-input id="filter-input" v-model="filter" type="search" placeholder="Search"></b-form-input>
            </b-input-group>
        </b-col>

    </b-row>

    <!-- Main table element -->
    <b-table ref="table" :id="id" striped bordered :responsive="$store.getters['Auth/isAdmin']" :busy="isBusy" :items="getData" :fields="fields" :current-page="currentPage" :per-page="perPage" :filter="filter" :filter-included-fields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" stacked="md" show-empty small @filtered="onFiltered" @sort-changed="sortingChanged">

        <template #table-busy>
            <div class="text-center d-inline-block my-2">
                <span class='fa-stack fa-lg'>
                    <i class='fa fa-spinner fa-spin fa-stack-2x fa-fw'></i>
                </span>&emsp;Processing ...
            </div>
        </template>

        <template #empty="scope">
            <p class="mb-1">No matching records found</p>
        </template>

        <!-- Cell status -->
        <template #cell(status)="row">
            <span :class="statusClass(row.item.state)">
                {{ statusTitle(row.item.state) }}
                <b class="cursor-pointer-ek ml-1" data-toggle="tooltip" title="Recharge" v-if="[-1, 101].includes(row.item.state)" @click="recharge(row.item)">
                    <i class="fa fa-refresh" v-show="row.item.state == -1"></i>
                    <b-spinner small label="Small Spinner" type="grow" v-show="row.item.state != -1"></b-spinner>
                </b>
            </span>

        </template>
        <template #cell(total_refund)="row">
            <span>{{ moneyDefaultFormat(row.item.total_refund) }} <i class="fa fa-info text-info cursor-pointer-ek" v-if="row.item.refunds.length > 0" v-b-tooltip.html data-toggle="tooltip" :title="refund_note(row.item.refunds)"></i></span>
        </template>
        <template #cell(actions)="row">
            <div class="text-center">
                <button type="button" class="btn iq-bg-primary mr-1 btn-sm" v-b-tooltip.hover data-toggle="tooltip" title="Histories" @click="histories(row.item)">
                    <i class="fa fa-history m-0"></i>
                </button>
                <button v-if="isAdmin"  :disabled="[-1, -3].includes(row.item.state)" type="button" class="btn iq-bg-danger mr-1 btn-sm" v-b-tooltip.hover data-toggle="tooltip" title="Refund" @click="refund(row.item)">
                    <i class="fa fa-undo m-0"></i>
                    <b-spinner small label="Small Spinner" type="grow" v-show="row.item.state == -201"></b-spinner>
                </button>
                <button v-if="isAdmin"  :disabled="row.item.state == 1" type="button" class="btn iq-bg-success mr-1 btn-sm" v-b-tooltip.hover data-toggle="tooltip" title="Edit" @click="update(row.item)">
                    <i class="ion-edit m-0"></i>
                </button>
                <button type="button" class="btn iq-bg-primary mr-1 btn-sm" v-b-tooltip.hover data-toggle="tooltip" title="Show">
                    <router-link :to="{ name: 'invoices.show', params: { id: row.item.id } }"><i class="fa fa-eye m-0"></i></router-link>
                </button>
                <button v-if="row.item.status !== -1 && isAdmin" type="button" class="btn iq-bg-danger mr-1 px-2 btn-sm" v-b-tooltip.hover data-toggle="tooltip" title="Delete" @click="remove(row.item)">
                    <i class="fa fa-trash mx-0" v-show="true"></i>
                </button>

            </div>
        </template>
    </b-table>

    <!-- Datatble pagination -->
    <b-row class="justify-content-between mt-3">
        <b-col md="9" class="my-1">
            <div class="dataTables_info">
                <b-row class="dataTables_info justify-content-between" id="lists_info" role="status" aria-live="polite">
                    <b-col md="10" class="">
                        Showing {{ ((currentPage - 1) * perPage) + 1 }}
                        to {{
                                items.length < perPage ? (((currentPage - 1) * perPage) + items.length) : (1 * currentPage)
                                    * perPage
                        }} of {{ totalRows }} entries </b-col>

                    <b-col md="2" class="">
                        <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm">
                        </b-form-select>
                    </b-col>
                </b-row>
            </div>
        </b-col>
        <b-col md="3" class="my-1">
            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm" class="my-0"></b-pagination>
        </b-col>
    </b-row>

    <b-modal id="modal-prevent-closing" ref="modal" title="Update invoice status" :hide-footer=true>
        <form ref="form" @submit.stop.prevent="handleSubmit">
            <b-form-group label="Name" label-for="name-input" invalid-feedback="Name is required">
                <b-form-select v-model="selected" :options="options" class="mb-3" value-field="item" text-field="name" disabled-field="notEnabled"></b-form-select>
            </b-form-group>
            <b-button type="submit" variant="primary" :disabled="onSubmit">
                <b-spinner small label="Small Spinner" type="grow" v-show="onSubmit"></b-spinner> Submit
            </b-button>
        </form>
    </b-modal>

</b-container>
</template>

<script>
import { mapGetters } from 'vuex'
import Spinner1 from '@/components/loaders/spinner1'
import store from '@/store/index'
import api from '@/api/RestClient'
import {
    INVOICE_STATES
} from "../../../constantes";
import {
    INVOICE_STATUS
} from "../../../constantes";

import { helper } from '@/helpers'

export default {
    name: 'DtInvoice',
    components: {
        Spinner1,
    },
    created() {
    },
    data() {
        return {
            id: 'dt-invoices',
            isBusy: false,
            items: [],
            fields: [{
                    key: 'id',
                    label: '#ID',
                    sortable: true,
                    sortDirection: 'desc',
                    class: 'text-center'
                },
                {
                    key: 'description',
                    label: 'Description',
                    sortable: true,
                    sortDirection: 'desc',
                    class: 'text-center',
                    formatter: (value, key, item) => {
                        return item.description ? item.description : '--';
                    }
                },
                {
                    key: 'amount',
                    label: 'Amount',
                    sortable: true,
                    sortDirection: 'desc',
                    class: 'text-center',
                    formatter: (value, key, item) => {
                        return helper.moneyDefaultFormat(item.amount);
                    }
                },
                {
                    key: 'total_refund',
                    label: 'Total Refund',
                    sortable: true,
                    sortDirection: 'desc',
                    class: 'text-center',
                },
                {
                    key: 'date',
                    label: 'Date',
                    sortable: true,
                    sortDirection: 'desc',
                    class: 'text-center'
                },
                {
                    key: 'due_date',
                    label: 'Due date',
                    sortable: true,
                    sortDirection: 'desc',
                    class: 'text-center'
                },
                {
                    key: 'account',
                    label: 'Organization',
                    sortable: false,
                    sortDirection: 'desc',
                    class: 'text-center'
                },
                {
                    key: 'status',
                    label: 'Status',
                    sortable: false,
                    class: 'text-center'
                },
                {
                    key: 'actions',
                    label: 'Action',
                    sortable: false,
                    class: 'text-center'
                },

            ],
            totalRows: 1,
            currentPage: 1,
            perPage: 25,
            pageOptions: [25, 50, 100, {
                value: 100,
                text: "Show a lot"
            }],
            sortBy: 'created_at',
            sortDesc: true,
            sortDirection: 'desc',
            filter: null,
            filterOn: ["description"],
            account: -1,
            dates: null,
            $search: '',
            selected: null,
            invoice_id: null,
            options: INVOICE_STATES,
            onSubmit: false,
            refundTitle: '***'
        }
    },
    computed: {
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return {
                        text: f.label,
                        value: f.key
                    }
                })
        },
        ...mapGetters({
            isAdmin: 'Auth/isAdmin',
            isAccounting: 'Auth/isAccounting',
            isManager: 'Auth/isManager',
            isClient: 'Auth/isClient',
        })
    },
    mounted() {
        this.loadFields()
    },
    methods: {
        async getData(ctx) {
            await this.delay();
            return await this.fetchData(ctx);
        },
        async fetchData(ctx) {
            var items = []
            this.items = [];
            this.isBusy = true;
            let queryParams = {}
            if (typeof ctx !== "undefined") {
                queryParams = {
                    search: this.filter,
                    page: ctx.currentPage,
                    per_page: ctx.perPage,
                    sort_by: ctx.sortBy,
                    sort_desc: ctx.sortDesc,
                    filter_on: JSON.stringify(this.filterOn),
                }
            }

            return api.invoices.all(queryParams)
                .then((response) => {
                    items = response.data.data;
                    this.totalRows = response.data.total
                    this.currentPage = response.data.current_page
                    this.perPage = response.data.per_page
                    this.items = items
                    return items
                })
                .catch((err) => {
                    this.$bvToast.toast(err.response.data.message, {
                        title: 'Error',
                        variant: 'danger',
                    });
                })
                .finally(() => {
                    setTimeout(() => {
                        this.isBusy = false;
                        this.$root.$emit('updatedTable');
                    }, 400);
                })

        },
        delay: () => {
            return new Promise(resolve => setTimeout(resolve, 2000));
        },
        formatDate(date) {
            var d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2)
                month = '0' + month;
            if (day.length < 2)
                day = '0' + day;

            return [year, month, day].join('-');
        },
        // Filter
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        sortingChanged(ctx) {
            this.currentPage = 1
        },
        rechargeInvoice(item) {
            item.state = 101;
            let data = {
                amount: item.amount,
            }
            api.invoices.pay(item.id, data).then((response) => {
                let title = 'Error';
                let variant = 'error';
                if (response.success) {
                    item.state = 1;
                    title = 'Success';
                    variant = 'success';
                } else item.state = -1;
                this.$bvToast.toast(response.message, {
                    title: title,
                    variant: variant
                });
                this.$root.$emit('bv::refresh::table', this.id)
            }).catch((err) => {
                console.log(err)
                item.state = -1;
                this.$bvToast.toast('Something went wrong!', {
                    title: 'Error',
                    variant: 'danger'
                });
            }).finally(() => {})
        },
        recharge(item) {
            this.$swal({
                title: 'Are You Sure?',
                type: 'warning',
                text: 'This action will charge the current card on file for the amount listed on this invoice.',
                showCancelButton: true,
                confirmButtonText: 'Yes, Recharge',
                cancelButtonText: 'Cancel',
                showCloseButton: true,
                showLoaderOnConfirm: true
            }).then((result) => {
                if (result.value) {
                    this.rechargeInvoice(item)
                }
            })

        },
        update(item) {
            this.selected = item.state;
            this.invoice_id = item.id;
            this.$emit('onEditInvoice', item);
        },
        handleSubmit() {
            this.onSubmit = true;
            const data = {
                status: this.selected
            }
            const id = this.invoice_id;
            api.invoices.update(id, data).then((response) => {
                this.$bvToast.toast(response.message, {
                    title: 'Success',
                    variant: 'success'
                });
                this.$root.$emit('bv::refresh::table', this.id)
            }).catch((error) => {
                console.log(error);
            }).finally(() => {
                this.onSubmit = false;
            })
        },
        statusClass(status) {
            let statusData = INVOICE_STATUS.find(x => x.code == status);
            return statusData ? statusData.class : "";
        },
        statusTitle(status) {
            let statusData = INVOICE_STATUS.find(x => x.code == status);
            return statusData ? statusData.name : "";
        },
        onHoverTable() {
            $('[data-toggle="tooltip"]').tooltip({
                html: true
            })
        },
        refund(item) {
            this.$emit('onRefund', item);
        },
         moneyDefaultFormat(amount) {
            return amount.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        },
        histories(item) {
            this.$emit('onShowInvoiceHistories', item);
        },
        new_invoice() {
            this.$emit('onAddInvoice');
        },
        remove(item) {
            this.$swal({
                title: 'Are you sure?',
                text: 'You can\'t revert your action',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes Delete it!',
                cancelButtonText: 'No, Keep it!',
                showCloseButton: true,
                showLoaderOnConfirm: true
            }).then((result) => {
                if (result.value) {
                    api.invoices.delete(item.id).then(response => {
                        let varian = response.success ? 'success' : 'danger';
                        this.toast('response.message', varian, response.message);
                        if (response.success) this.$root.$emit('bv::refresh::table', this.id);
                    }).catch(err => {
                        this.$bvToast.toast(err.response.data.message, {
                            title: 'Error',
                            variant: 'danger',
                        });
                    })
                } else {
                    this.$swal('Cancelled', 'Your file is still intact', 'info')
                }
            })
        },
        toast(title = "success", variant = "success", message = "Invoice added") {
            this.$bvToast.toast(message, {
                title: title,
                variant: variant
            })
        },
        refund_note(refunds) {
            if (refunds.length <= 0) return '';
            let ul = '<ol>'
            refunds.forEach(refund => {
                let date = this.formatDate(refund.created_at);
                let note = refund.note + `($${refund.total}, ${date})`
                ul += `<li>${note}</li>`
            });

            ul += '</ol>'

            return ul;
        },
        loadFields() {
            let fields = _.map(this.fields, (item) => {
                if (this.isAdmin || this.isAccounting) {
                    return item;
                } else {
                    if (!["actions"].includes(item.key)) return item;
                }

            })
            this.fields = fields;
        },
    },
    watch: {}
}
</script>

<style>
td.lists>div,
td.integration>div {
    width: 10em;
}

td.tag>div,
td.account>div {
    width: 8em;
}

.cursor-pointer-ek {
    cursor: pointer;
}

.pull-right {
    float: right;
}

.dropdown-menu.show {
    display: inherit;
}

ul[role=menu] {
    transform: translate3d(-136px, 37px, 0px);
}

.dropdown-menu {
    padding: 0.5rem 0.5rem;
}
.tooltip-inner {
    max-width: 500px !important;
}
</style>
