<template>
    <div>
        <Loader />
        <section class="sign-in-page bg-white">
            <div class="container-fluid p-0">
                <div class="row no-gutters">
                    <div class="col-sm-6 align-self-center">
                        <div class="d-flex justify-content-center">
                            <a class="mb-5" href="#">
                                <img :src="logo" class="img-fluid" alt="logo" style=" height: 4rem;">
                            </a>
                        </div>
                        <div class="sign-in-from">
                            <router-view></router-view>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="sign-in-detail text-white "
                            :style="'background: url(' + bgImageURL + ') no-repeat 0 0; background-size: cover;'"
                            id="rcorners_3">
                            <div class="text-center" v-if="partner">
                                <h2 v-if="false" class="text-white">A</h2>
                                <img :src="partnerLogo" class="img-fluid mb-3 mt-3 img-partner" alt="chronos">
                                <div v-if="false" class="mb-5">
                                    <h2 class="text-white">Preferred</h2>
                                    <h2 class="text-white">Partner</h2>
                                </div>
                            </div>
                            <div class="text-white text-center info-link" v-if="partner">
                                <p>Click
                                    <a class='text-white' :href="sheduleUrl" target="_blank">here</a> to schedule a
                                    call with the InboxGeek team
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import Loader from '../../components/sofbox/loader/Loader'
import logo from '../../assets/images/logo-white.png'
import { sofbox } from '../../config/pluginInit'
import bgImage from '../../assets/images/login/2.jpg'
import * as bot from '@/chatBots'
export default {
    name: 'AuthLayout1',
    components: {
        Loader
    },
    mounted() {
        sofbox.index()
        const param = this.$route.params.via;
        const query = this.$route.query.via;
        this.partner = param ? param : query ? query : null;

        switch (this.partner) {
            case 'chronos':
                this.partnerLogo = 'https://chronos.agency/wp-content/uploads/2020/08/chronos-logo-white.svg'
                break;
            case 'dogtooth':
                this.partnerLogo = 'https://www.dogtoothinc.com/wp-content/uploads/2022/06/Dogtoothinc-Logo-Black-copy-1024x276.png'
                break;
            default:
                this.partner = null;
                break;
        }

       bot.liveChat();
    },
    data() {
        return {
            logo: logo,
            bgImageURL: bgImage,
            partnerLogo: null,
            partner: null,
            sheduleUrl: 'https://calendly.com/inboxgeek/support-call'
        }
    }
}
</script>

<style>
.sign-in-detail {
    padding: 100px;
    height: 100vh;
}

.sign-in-logo {
    display: inline;
    width: 100%;
}

.img-partner {
    width: 20%;
}

.info-link {
    position: relative;
    top: 25%;
}

a[target] {
    text-decoration: underline;
    font-weight: bold;
}

@media (max-width: 600px) {
    .img-partner {
        width: 90%;
    }
}

#rcorners3 {
    border-radius: 25px;
    background-position: left top;
    background-repeat: repeat;
    margin: 10px;
}
</style>