<template>
    <b-modal :id="id" :ref="id" ok-title="Update Daily Limits" cancel-title="Cancel" size="md" @show="initModal" hide-footer
        @hidden="form = resetForm()">

        <template #modal-title>
            <div
                class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color float-right mr-0">
                <div class="custom-switch-inner">
                    <label class="mr-2">Daily limit</label>
                    <input type="checkbox" class="custom-control-input bg-primary" v-model="form.dailyLimit"
                        id="dailyLimitSwitch">
                    <label class="custom-control-label" for="dailyLimitSwitch" data-on-label="On" data-off-label="Off">
                    </label>
                </div>
            </div>
        </template>

        <b-col>
            <form action="#" @submit="submit" method="post" id="sendflow">
                <div class="form-row" id="daily-limit-inputs">
                    <div class="col-md-12 mb-3">

                        <b-form-group v-if="form.dailyLimit" class="col-md-12" v-slot="{ ariaDescribedby }">

                            <b-form-radio-group id="radio-group-daily-limit" v-model="form.dailyLimitSelected"
                                :options="dailyLimitOptions" :aria-describedby="ariaDescribedby"
                                name="radio-dailyLimitOptions" @input="dailyLimitChange"></b-form-radio-group>

                            <b-form-input v-if="['unlimited', 'fixed'].includes(form.dailyLimitSelected)" type="number"
                                id="limit" v-model="form.limitPerDay" name="limit" placeholder="limit per day"
                                :disabled="form.dailyLimitSelected == 'unlimited'"></b-form-input>

                            <multiselect v-else-if="form.dailyLimitSelected == 'auto'" v-model="form.loDailyLimitAutoUpdate"
                                deselect-label="Can't remove this value" track-by="code" label="name"
                                placeholder="Select Auto Update Logic" :loading="isLoading"
                                :options="loDailyLimitAutoUpdates" :searchable="true" :allow-empty="false"></multiselect>

                        </b-form-group>

                        <b-form-group v-else class="col-md-12 mb-1 px-0">

                            <b-alert :show="true" class="py-0 mb-0" variant="secondary">
                                <label class="mb-1">Unlimited</label>
                            </b-alert>

                        </b-form-group>

                        <small v-if="errors.noType" class="text-danger">Select a Type of Daily Limit!</small>
                        <small v-if="errors.limitPerDay" class="text-danger">Define Limit per day to update</small>
                        <small v-if="errors.loDailyLimitAutoUpdate" class="text-danger">Select a Logic to update</small>

                    </div>
                </div>
                <div class="form-group">
                    <div class="form-group">
                        <button class="btn btn-primary pull-right" type="submit" variant="primary" :disabled="send">
                            <b-spinner small type="grow" v-if="send"></b-spinner>
                            {{ sendText }}
                        </button>
                    </div>
                </div>
            </form>
        </b-col>

    </b-modal>
</template>
<script>
import Multiselect from 'vue-multiselect'

import api from '@/api/RestClient'
const BTN_TEXT = "Update"

export default {
    name: 'DailyLimitsModal',
    props: {
        id: String,
        title: String,
        items: Array
    },
    components: {
        Multiselect
    },
    mounted() {

    },
    data: () => ({
        isLoading: false,
        sendText: BTN_TEXT,
        send: false,
        form: {},
        loDailyLimitAutoUpdates: [],
        isSameAccount: false,
        errors:{
            noType:false,
            limitPerDay:false,
            loDailyLimitAutoUpdate: false,
        },
    }),
    methods: {
        submit(e) {
            e.preventDefault();

            this.errors = { noType: false, limitPerDay: false, loDailyLimitAutoUpdate: false };


            this.send = true;
            this.sendText = 'In Progress...';

            if(this.isValid()) {
                let data = {ids: []};

                if(!this.form.dailyLimit) {
                    data.limit = -1;
                }

                if(this.form.dailyLimit && this.form.dailyLimitSelected == 'fixed' && this.form.limitPerDay) {
                    data.limit = this.form.limitPerDay;
                }

                if(this.form.dailyLimit && this.form.dailyLimitSelected == 'auto' && this.form.loDailyLimitAutoUpdate) {
                    data.lo_daily_limit_auto_update_id = this.form.loDailyLimitAutoUpdate.code;
                }

                for (let i = 0; i < this.items.length; i++) {
                    data.ids.push(this.items[i].id)
                }


                api.integrations.bulkDailyLimit(data)
                    .then((response) => {
                        this.send = false;
                        this.sendText = BTN_TEXT;
                        this.$swal('Success', response.message, 'success')
                        this.$emit("modalHide", response.data);
                    })
                    .catch((error) => {
                        if (error.response.status == 500) {
                            this.$bvToast.toast('Something went wrong!', {
                                title: `Error Bulk Update Integrations Daily Limit`,
                                variant: 'danger'
                            });
                            return;
                        }

                        this.$bvToast.toast(error.response.data.errors.message, {
                            title: `Error Bulk Update Integrations Daily Limit`,
                            variant: 'danger'
                        })
                    })
            } else {
                this.send = false;
                this.sendText = BTN_TEXT;
            }

        },
        initModal() {

            this.send = false;
            this.sendText = BTN_TEXT;

            if(this.isSameAccountId()) {
                setTimeout(() => {
                    this.getLoDailyLimitAutoUpdates();
                }, 400)
            }

        },
        resetForm() {
            return {}
        },
        dailyLimitChange($event) {
        },
        getLoDailyLimitAutoUpdates() {

            this.isLoading = true;

            let params = { account: this.items[0].account_id };

            api.loDailyLimitAutoUpdates.getNames(params)
                .then((response) => {

                    this.loDailyLimitAutoUpdates = response.data.map((dailyLimit) => {
                        let item = {
                            'code': dailyLimit.id,
                            'name': dailyLimit.name
                        };

                        return item
                    });

                })
                .finally(() => {
                    this.isLoading = false;
                })
        },
        isSameAccountId() {

            const propertyToCheck = 'account_id';

            const allObjectsHaveSameAccount = _.every(this.items, obj => obj[propertyToCheck] === this.items[0][propertyToCheck]);

            this.isSameAccount = allObjectsHaveSameAccount;

            return allObjectsHaveSameAccount;
        },
        isValid() {

            if(this.form.dailyLimit && !this.form.dailyLimitSelected) {
                this.errors.noType = true;
                return false;
            }

            if(this.form.dailyLimit && this.form.dailyLimitSelected == 'fixed' && !this.form.limitPerDay) {
                this.errors.limitPerDay = true;
                return false;
            }

            if(this.form.dailyLimit && this.form.dailyLimitSelected == 'auto' && !this.form.loDailyLimitAutoUpdate) {
                this.errors.loDailyLimitAutoUpdate = true;
                return false;
            }

            return true;

        }
    },
    computed: {
        dailyLimitOptions() {
            return [
                { text: 'Custom value', value: 'fixed' },
                { text: 'Automated Daily Limit', value: 'auto', disabled: !this.isSameAccountId() },
            ]
        }
    }
}
</script>

<style>
.form-row {
    display: block !important;
}
</style>
