<template>

  <b-container fluid>
    <b-modal id="edit-suppression-list-modal" ref="editSuppressionListModal" title="Edit Suppression List" size="lg" centered hide-footer>

      <label for="">name</label>
    <b-input v-model="form.name"></b-input>

      <div class="alert alert-danger" v-show="error">Something went wrong</div>
      <hr>
      <div class="text-right">
        <a class="btn btn-light" @click="$refs['editSuppressionListModal'].hide()">Cancel</a>
        <a class="btn btn-primary" @click="update">Update</a>
      </div>
    </b-modal>

  </b-container>



</template>

<script>
import api from "@/api";

export default {
  name: "EditSuppressionList",
  props:['suppression_list'],
  data(){
    return {
      error:false,
      form:{
        name:null
      }
    }
  },
  methods:{
    update(){

      api.suppressionList.update(this.suppression_list.id,this.form).then(response=>{
        this.$refs['editSuppressionListModal'].hide();
      }).catch((error)=>{
        this.error = true;
      });

    }
  },
  watch:{
    suppression_list(newValue,oldValue){
      this.form.name = newValue.name;
    }
  }
}
</script>

<style scoped>

</style>