<template>
    <div>
        <b-form @submit="onSubmit">
           

            <b-form-group id="input-group-2" label="Referral Name:" label-for="input-2">
                <b-form-input id="input-2" v-model="form.name" placeholder="Enter referral name" required></b-form-input>
            </b-form-group>

            <b-form-group id="input-group-3" label="Discount:" label-for="input-3">
                <b-form-select id="input-3" v-model="form.discount_id" :options="discounts" required></b-form-select>
            </b-form-group>

            <b-form-checkbox v-model="checked" name="check-button" switch @change="handleSwitch">
                Status
            </b-form-checkbox>
            <br>
            <b-button type="submit" variant="primary">
                Submit
                <b-spinner small label="Small Spinner" type="grow" v-show="submitLoader"></b-spinner>
            </b-button>
        </b-form>
        <b-card class="mt-3 d-none" header="Form Data Result">
            <pre class="m-0">{{ form }}</pre>
        </b-card>
    </div>
</template>

<script>
import api from '@/api/RestClient'
export default {
    props: ['options', 'referral', 'referralId'],
    created() {
        this.addOptionsSelect();
        this.setForm();
    },
    data() {
        return {
            form: {
                name: '',
                discount_id: null,
                status: 0
            },
            discounts: [
                { value: null, text: 'Please select some item' },
            ],
            show: true,
            checked: false,
            submitLoader: false
        }
    },
    methods: {
        onSubmit(event) {
            event.preventDefault()
            this.submitLoader = true;
            if (this.referralId != null) this.put();
            else if (this.referralId == null) this.post();
        },
        post() {
            let status = 0;
            api.referrals.post(this.form)
                .then((response) => {
                    status = 1;
                    this.$emit('onSaved', status)
                })
                .catch((err) => {
                    console.log(err)
                    this.$emit('onSaved', status)
                })
                .finally(() => {
                    this.submitLoader = false;
                })
        },
        put() {
            let status = 0;
            api.referrals.put(this.referralId, this.form)
                .then((response) => {
                    status = 1;
                    this.$emit('onSaved', status)
                })
                .catch((err) => {
                    console.log(err)
                    this.$emit('onSaved', status)
                })
                .finally(() => {
                    this.submitLoader = false;
                })
        },

        handleSwitch(event) {
            if (this.checked) this.form.status = 1
            else this.form.status = 0
        }, 
        addOptionsSelect() {
            this.options.forEach(option => {
                this.discounts.push(option)
            });
        },
        setForm() {
            this.form = this.referral;
            if (this.referral.status == 1) this.checked = true
            else this.checked = false
        }
    }
}
</script>