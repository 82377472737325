<template>
    <div>
        <!-- START Subscriptions Card  -->
        <iq-card class="p-4">



            <div class="p-4">

                <!-- START FILTER OPTIONS ROW  -->
                <b-row class="my-2" align-h="between">

                    <!-- START Sow Option Input -->
                    <b-col md="2">
                        <b-form-group label-for="per-page-select" label-align-sm="left" class="mb-0">
                            <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions"></b-form-select>
                        </b-form-group>
                    </b-col>
                    <!-- END Sow Option Input -->


                    <!-- START Filter Input -->
                    <b-col md="4">
                        <b-form-group class="mb-0">
                            <b-input-group>
                                <b-form-input id="filter-input" v-model="filter" type="search"
                                    placeholder="Type to Search"></b-form-input>

                            </b-input-group>
                        </b-form-group>
                    </b-col>
                    <!-- END Filter Input -->

                </b-row>
                <!-- END FILTER OPTIONS ROW  -->


                <b-table borderless hover :items="subscriptions" :fields="fields" :current-page="currentPage"
                    :per-page="perPage" :filter="filter" :filter-included-fields="filterOn" :busy="loading.table"
                    stacked="md" show-empty @filtered="onFiltered">

                    <template #table-busy>
                        <div class="text-center p-4 d-flex justify-content-center align-items-center">
                            <b-spinner variant="primary"> </b-spinner>
                            <span class="text-primary p-2"><small> Fetching Subscriptions, Please wait...</small></span>
                        </div>
                    </template>

                    <!-- Cell status -->
                    <template #cell(status)="row">
                        
                        <StatusCell :row="row" />

                    </template>

                    <template #cell(payment_status)="row">

                        <span v-if="row.value == 1 || row.value == -1" class="badge cursor-pointer badge-secondary badge-pill"
                            :class="row.value == 1 ? 'iq-bg-success' : 'iq-bg-danger'">
                            {{ row.value == 1 ? 'Paid' : 'failed' }}
                        </span>
                        <span v-else-if="row.value == 0"
                            class="badge cursor-pointer badge-secondary badge-pill iq-bg-warning">
                            initial
                        </span>
                    </template>

                    <template #cell(actions)="row">

                        <b-button variant=" iq-bg-info mr-1 mb-1" @click="affectPlan(row.item)" size="sm">Change
                            Plan</b-button>

                    </template>


                </b-table>

                <!-- START PAGINATION TABLE-->
                <b-row align-h="end">

                    <b-col md="3" class="my-1">
                        <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill"
                            size="sm" class="my-0"></b-pagination>
                    </b-col>
                </b-row>
                <!-- END PAGINATION TABLE-->
            </div>

        </iq-card>
        <!-- END Subscriptions Card  -->


        <!-- START Account Modal-->
        <b-row>
            <b-col lg="12" md="12">
                <b-modal id="modal-affect-plan" ref="planModal" hide-footer title="Affect Plan">
                    <b-form @submit="onSubmitPlanChanges">


                        <b-form-group id="input-group-4" label="Account Plan:" label-for="input-4" description="">

                            <Multiselect v-model="form.plan" :options="plans" label="name" value="id" />

                        </b-form-group>

                        <b-row align-h="end" class="px-4">
                            <b-button variant="primary" type="submit" :disabled="loading.modal">
                                Submit
                                <b-spinner small type="grow" v-show="loading.modal"></b-spinner>
                            </b-button>
                        </b-row>
                    </b-form>
                </b-modal>
            </b-col>
        </b-row>
        <!-- END Account Modal-->

    </div>
</template>
  
<script>
import { sofbox } from '../../config/pluginInit'
import Spinner1 from '../../components/loaders/spinner1'
import api from '@/api/RestClient';
import Multiselect from 'vue-multiselect';
import StatusCell from '@/components/inboxgeek/tables/StatusCell.vue'

var moment = require('moment');

export default {
    name: 'account-subscriptions',
    components: {
        Spinner1,
        Multiselect,
        StatusCell
    },
    data() {
        return {
            id: 'dt-account-subscriptions',
            isBusy: false,
            loading: { modal: false, table: false },
            subscriptions: [],
            plans: [],
            form: {
                plan: null,
                account_id: null,
            },
            fields: [
                {
                    key: 'id',
                    label: '#ID',
                    sortable: true,
                    sortDirection: 'desc',
                    class: 'text-center',
                    formatter: (value, key, item) => {
                        const id = `#${item.id}`
                        return id;
                    }
                },
                {
                    key: 'name',
                    label: 'Account',
                    sortable: true,
                    sortDirection: 'desc',
                    class: 'text-left account',
                    formatter: (value, key, item) => {
                        const name = `${item.name}`
                        return name;
                    }

                },
                {
                    key: 'referral',
                    label: 'Referral',
                    sortable: true,
                    sortDirection: 'desc',
                    class: 'text-center',
                    formatter: (value, key, item) => {
                        return item.referral ? item.referral : '--';
                    }

                },
                {
                    key: 'current_plan',
                    label: 'Plan',
                    sortable: true,
                    thStyle: {
                        width: "6em"
                    },
                    class: 'text-center',
                }, {
                    key: 'next_plan',
                    label: 'Next Plan',
                    sortable: true,
                    thStyle: {
                        width: "6em"
                    },
                    class: 'text-center'
                },
                {
                    key: 'remaining',
                    label: 'Remaining',
                    sortable: false,
                    thStyle: {
                        width: "10em"
                    },
                    class: 'text-center'

                },
                {
                    key: 'limit',
                    label: 'Overconsumption Limit',
                    sortable: false,
                    thStyle: {
                        width: "10em"
                    },
                    class: 'text-center'

                },
                {
                    key: 'consumption',
                    label: 'Consumption',
                    sortable: false,
                    sortDirection: 'desc',
                    class: 'text-center'
                },
                {
                    key: 'next_billing_date',
                    label: 'Next billing date',
                    sortable: false,
                    sortDirection: 'desc',
                    class: 'text-center'
                },
                {
                    key: 'payment_status',
                    label: 'Payment status',
                    sortable: false,
                    class: 'text-center'
                }, {
                    key: 'ends_at',
                    label: 'Ends At',
                    sortable: false,
                    class: 'text-center',
                    formatter: (value, key, item) => {
                        if (item.ends_at) {
                            return moment(item.ends_at).format('YYYY-MM-DD');
                        }
                        return item.ends_at;
                    }
                }, {
                    key: 'price',
                    label: 'Price',
                    sortable: false,
                    class: 'text-center',
                    formatter: (value, key, item) => {
                        if (item.price)
                            return '$' + item.price;

                    }
                },
                {
                    key: 'status',
                    label: 'Status',
                    sortable: true,
                    class: 'text-center'
                },
                {
                    key: 'creation_date',
                    label: 'Creation Date',
                    sortable: true,
                    sortDirection: 'desc',
                    class: 'text-center',
                    formatter: (value, key, item) => {
                        return moment(item.creation_date).format('YYYY-MM-DD');
                    }
                },
                {
                    key: 'actions',
                    label: 'Actions',
                    sortable: false,
                    class: 'text-center'

                },

            ],
            totalRows: 1,
            currentPage: 1,
            perPage: 25,
            pageOptions: [25, 50, 100, {
                value: 100,
                text: "Show a lot"
            }],
            sortBy: 'created_at',
            sortDesc: true,
            sortDirection: 'desc',
            filter: null,
            filterOn: ["name", "current_plan", "next_plan"],
            dates: null,
            $search: ''

        }
    },
    async mounted() {

        sofbox.index();

        this.fetchPlans();
        this.fetchSubscriptions();

    },
    methods: {

        toast(title = "success", variant = "success", message = "Organization updated successfully") {
            this.$bvToast.toast(message, {
                title: title,
                variant: variant
            })
        },
        fetchSubscriptions() {

            this.loading.table = true;


            api.accounts.subscriptions().then((response) => {
                this.totalRows = response.data.length
                this.subscriptions = response.data;

                this.loading.table = false;

            }).catch((error) => {
                console.log(error);
                console.log('something went wrong');
            });

        },
        fetchPlans() {
            api.plans.list().then((response) => {
                this.plans = response.data.data;
            }).catch(error => {
                console.log('failed to fetch plans.')
            });
        },
        affectPlan(account) {

            this.form.account_id = account.id;
            this.$refs['planModal'].show()
        },
        onSubmitPlanChanges(e) {

            e.preventDefault();
            this.loading.modal = true;

            api.plans.changePlan(this.form.plan.id, this.form.account_id).then(response => {

                this.loading.modal = false;

                let variant = 'danger';
                let message = 'Something went wrong'

                if ('success' in response) {
                    variant = response.success ? 'success' : 'danger';
                    message = response.message;

                    this.$bvToast.toast(message, {
                        title: "Plan changed",
                        variant: "success"
                    });



                    this.fetchSubscriptions();
                    this.$refs['planModal'].hide();

                }

            });

        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        }
    }

}
</script>
  
  
<style scoped>
.table-hover tbody tr:hover {
    color: #212529;
    background-color: rgb(171 216 225 / 15%);
}
</style>