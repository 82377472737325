<template>
    <b-modal :id="id" :ref="id" :title="automation ? 'Edit Automation - ' + automation.name : 'Add New Automation'"
        ok-title="Next" cancel-title="Previous" size="xl" @show="initModal" @ok="handleNext" @cancel="handleBack"
        @hidden="hideModal">

        <b-row>
            <b-col lg="12" sm="12">
                <iq-card v-if="renderModal">
                    <template v-slot:body>
                        <b-col md="12">
                            <b-form id="ll-edit-form-wizard2" class="mt-4">

                                <div v-if="renderStep">
                                    <component :is="'StepSource'" ref="step-automation" v-bind="{ form, errors }">
                                    </component>

                                    <fieldset v-if="!editPlatform && (automation.platform || form.platform)">
                                        <iq-card class="px-0">

                                            <div v-if="showPlatform" class="card-body iq-card-body pt-0 pb-1 px-0">

                                                <div v-if="dataIsLoading" class="text-center d-inline-block my-2">
                                                    <span class="fa-stack fa-lg">
                                                        <i class="fa fa-spinner fa-spin"></i>
                                                        <!-- fa-stack-2x fa-fw -->
                                                    </span>&emsp;Processing ...
                                                </div>
                                                <b-row v-else md="12">

                                                    <b-form-group class="col-md-6" label="" label-for="platform"
                                                        :disabled="true">
                                                        <label for="">
                                                            Platform
                                                        </label>
                                                        <PlatformsMultiselect class="p-0"
                                                            :platform="form.platform ? form.platform.name : null"
                                                            :disabledProp="true">
                                                        </PlatformsMultiselect>

                                                        <small v-if="errors.platform" ref="errors_platform" class="text-danger" :class="{ 'd-none': !errors.platform }">{{ errors.platform }}</small>
                                                    </b-form-group>

                                                    <b-form-group class="col-md-6" :label="reception_lists"
                                                        label-for="reception-lists" :disabled="true">
                                                        <multiselect v-model="form.list" tag-placeholder="Empty"
                                                            :placeholder="'listPlaceholder'" label="name" track-by="code"
                                                            :options="formatLists" :multiple="false" return="code"
                                                            :allow-empty="true" :taggable="false" :disabled="true">


                                                            <template slot="option" slot-scope="props">
                                                                <div class="option__desc">

                                                                    <span class="option__title">

                                                                        <span v-if="props.option.type">

                                                                            <i v-if="props.option.type == 'segment'"
                                                                                class="ri-flashlight-line"
                                                                                style="color:#5ad"></i>
                                                                            <i v-else class="ri-list-check color-primary"
                                                                                style="color:#5ad"></i>

                                                                        </span>

                                                                        <span v-else><i class="ri-list-check color-primary"
                                                                                style="color:#5ad"></i></span>

                                                                        <span style="font-wight:bold;"> {{ props.option.name
                                                                        }}
                                                                        </span>

                                                                        <span style="font-size:11px; color:#888"> {{
                                                                            props.option.code }}</span>


                                                                    </span>

                                                                </div>
                                                            </template>


                                                        </multiselect>

                                                        <small v-if="errors.list" ref="errors_list" class="text-danger" :class="{ 'd-none': !errors.list }">{{ errors.list }}</small>
                                                    </b-form-group>

                                                </b-row>

                                            </div>

                                        </iq-card>
                                    </fieldset>

                                    <b-button v-show="!editPlatform" block variant="info" class="mb-3 mr-1"
                                        @click.prevent="editAutomationPlatform">
                                        <i class="ri-pencil-line"></i> Edit Platform & Reception List
                                    </b-button>

                                    <fieldset v-show="editPlatform">

                                        <component :is="'StepPlatform'" ref="step-platform"
                                            v-bind="{ form: { ...form, organization: isAdmin ? organization : null }, errors, btnAdd: false, isConnected: isConnected, automation: automation }"
                                            @platformSelected="handlePlatformSelect">
                                        </component>

                                        <b-form-group class="col-md-12" :label="reception_lists" >
                                            <multiselect v-model="form.list" tag-placeholder="Empty"
                                                :placeholder="'listPlaceholder'" label="name" track-by="code"
                                                :options="form.lists" :multiple="false" return="code" :allow-empty="true"
                                                :taggable="false" :disabled="false">

                                                <template slot="option" slot-scope="props">
                                                    <div class="option__desc">

                                                        <span class="option__title">

                                                            <span v-if="props.option.type">

                                                                <i v-if="props.option.type == 'segment'"
                                                                    class="ri-flashlight-line" style="color:#5ad"></i>
                                                                <i v-else class="ri-list-check color-primary"
                                                                    style="color:#5ad"></i>

                                                            </span>

                                                            <span v-else><i class="ri-list-check color-primary"
                                                                    style="color:#5ad"></i></span>

                                                            <span style="font-wight:bold;"> {{ props.option.name
                                                            }}
                                                            </span>

                                                            <span style="font-size:11px; color:#888"> {{
                                                                props.option.code }}</span>


                                                        </span>

                                                    </div>
                                                </template>

                                            </multiselect>
                                        </b-form-group>

                                        <b-button block variant="success" class="mb-3 mr-1" @click="editPlatform = false">
                                            <i class="ri-check-double-line"></i>Done
                                        </b-button>
                                    </fieldset>



                                </div>
                                <!-- End Dynamic call Current step component -->

                                <!-- END Add Setting Modal-->

                            </b-form>
                        </b-col>

                    </template>
                </iq-card>
            </b-col>
        </b-row>

        <template #modal-footer="{ ok, cancel, hidden }">

            <button class="btn mx-2 btn-secondary" v-if="btnBackChoise" :disabled="isLoading">
                Choose an existing platform <i class="ri-arrow-go-back-line"></i>
            </button>

            
            <button v-if="!liveLeadSource.id" class="btn btn-primary" @click="ok"
                :disabled="isLoading || editPlatform">

                <b-spinner small type="grow" v-show="isLoading"></b-spinner>
                Save Change
            </button>
            <button v-else class="btn mx-2 btn-secondary" @click="hideModal()">
                Done
            </button>
        </template>
    </b-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import store from '@/store/index'
import api from '@/api/RestClient'
import Multiselect from 'vue-multiselect'
import StepSource from './automation/StepSource.vue'
import StepPlatform from './automation/StepPlatform.vue'
import StepIntegration from './automation/StepIntegration.vue'
import StepRecap from './automation/StepRecap.vue'
import PartialIntegrationLiveLead from '../children/IntegrationLiveLead.vue'
import _ from 'lodash';
import { services, helper } from '@/helpers';
import { PLATFORMS_CODE, OAUTH_ESP } from "@/constantes";
import PlatformsMultiselect from '@/components/inboxgeek/inputs/PlatformsMultiselect'
import { GE_RECORD_STATUS_STATES, GE_RECORD_CLEAN_STATES } from "@/constantes";
import moment from 'moment'


export default {
    name: 'GeModalEditAutomation',
    components: {
        Multiselect,
        StepSource,
        StepPlatform,
        StepIntegration,
        StepRecap,
        PartialIntegrationLiveLead,
        PlatformsMultiselect
    },
    props: ['id', 'organization', 'automation'],
    computed: {
        ...mapGetters({
            isAdmin: 'Auth/isAdmin',
            isClient: 'Auth/isClient',
            user: 'Auth/user',
        }),
        showPlatform() {
            return this.form.platform && this.form.lists;
        },
        showPlatformList() {
            return this.form.platform && this.form.lists && this.form.lists.length;
        },
        formatLists() {
            return helper.formatLists(this.form);
        }
    },
    created() {
    },
    mounted() {
    },
    data() {
        return {
            isLoading: true,
            renderModal: true,
            renderStep: true,
            btnBackChoise: false,
            // hideNextBtn: false,
            currStep: 0,
            currStepComponent: null,
            oautESP: OAUTH_ESP,
            form: {},
            steps: [
                { name: 'website-tab', title: 'Source', component: 'StepSource', active: true, done: false, fields: ['name', 'website'], icon: 'ri-pages-fill' },
                { name: 'platform-tab', title: 'Platform', component: 'StepPlatform', active: true, done: false, fields: ['platform_code', 'account_id', 'api_key'], icon: 'ri-apps-fill' },
                { name: 'integration-tab', title: 'Reception List', component: 'StepIntegration', active: true, done: false, fields: ['list'], icon: 'ri-play-list-add-fill' },
                { name: 'finish-tab', title: 'Finish', component: 'StepRecap', active: false, done: false, fields: [], icon: 'ri-check-double-line' },
            ],
            errors: {},
            p_records: [],
            showSkip: true,
            liveLeadSource: {},
            isConnected: false,
            showScript: false,
            editPlatform: false,
            dataIsLoading: false,
            reception_lists: '***'
        }
    },

    methods: {
        initModal() {

            // this.hideNextBtn = false;
            this.isLoading = false;
            this.currStep = 0;
            this.currStepComponent = this.steps[this.currStep].component;
            this.liveLeadSource = {};
            this.editPlatform = false;

            this.isConnected = false;
            this.resetErrors();

            this.steps.forEach(element => {
                element.active = false
                element.done = false
            });

            this.steps[0].active = true
            this.form = this.resetForm()

            this.resetStepComp();
            this.setPlatform();
            this.resetModal();
            this.getSources();
        },
        getSources() {
            this.isLoading = true;

            api.geSources.getSourcesNames({ account: this.isAdmin ? this.organization : null })
                .then(response => {
                    if (response.success) {

                        let sources = _.map(response.data, (o) => {
                            return {
                                uuid: o.id,
                                code: o.code,
                                name: o.name ?? '--',
                                // platform: o.platform,
                                // platform_id: o.platform_id,
                                website: o.website,
                            }
                        });

                        this.form.sourceOptions = sources;

                        if (this.automation.source_id) {
                            let source = _.find(this.form.sourceOptions, (o) => { return o.uuid == this.automation.source_id; });
                            this.form.source = source;
                        }

                    }
                    this.isLoading = false;
                })
                .catch(error => {
                    this.isLoading = false;
                })
        },
        resetForm() {
            return !this.automation ? {

                name: '',
                source: '',
                sourceOptions: [],
                platform: null,
                platforms: [],
                list: null,
                lists: [],
                isFiltered: true,
                recordState: null,
                timeSpent: null,
                cleanOptions: GE_RECORD_CLEAN_STATES,
                actions: [
                    { code: 2, name: 'Add to Card' },
                    { code: 3, name: 'Checkout' },
                ],
                status: true,
                // Time spent
                timeFromH: '00',
                timeFromM: '00',
                timeFromS: '00',
                timeToH: '00',
                timeToM: '15',
                timeToS: '00',
                timeSpentFrom: null,
                timeSpentTo: null,

                tag: null,
                CustomEventName: null,
                CustomEventId: null,
                sendin_blue_event: null,
                customTag: null,
                custom: { isVisible: null, checkbox: null, link: null },
                isListOfTags: false
            } : this.getAutomation()
        },
        resetModal() {
            this.renderModal = false;

            this.$nextTick(() => {
                this.renderModal = true;
            })
        },
        async handleNext(e) {
            e.preventDefault();
            this.isLoading = true;
            this.showSkip = false;

            if (!this.formIsValid()) {
                await helper.delay(300).then(() => {

                    if (!this.form.platform) {
                        this.$swal('Please Select or Create Platform', 'Please select a Platform or Add New to Continue. You can Skip this step!', 'warning')
                    }

                    this.$refs['step-automation'].showErrors();
                    this.$refs['step-platform'].showErrors();
                    // this.$refs['step-integration'].showErrors();
                    this.isLoading = false;
                })
                return
            } else {
                this.handleSubmit();
            }

        },
        handleBack(e) {
            e.preventDefault()
            this.showSkip = true;

            const STEP = this.steps[this.currStep]

            if (this.currStep > 0) {
                this.currStep--
                this.currStepComponent = this.steps[this.currStep].component

                STEP.active = false
                this.steps[this.currStep].done = false
            }

        },
        showStepIntegration() {
            this.$emit('showIntegrationModal', this.form);
        },
        handlePlatformSelect(data) {
            this.resetErrors();

            this.isLoading = true;
            this.showSkip = false;

            const CURR_COMP = this.$refs['step-platform'];

            let esp_platform = _.find(CURR_COMP.platforms, (o) => {
                return o.name == data.name;
            });


            if (typeof esp_platform === 'object' && typeof esp_platform.code !== 'undefined') {


                let platform = {
                    id: data.id,
                    name: data.name,
                    esp: esp_platform,
                    account_id: this.isAdmin ? this.organization : (data.account_id ?? null),
                    account: data.account,
                };

                this.form.platform = platform

                let _data = { ...data, code: platform.esp.code, img: platform.esp.img }

                if (esp_platform.code == PLATFORMS_CODE.INBOXSUITE) {
                    this.isLoading = false;
                    return;
                }

                if ([PLATFORMS_CODE.WEBHOOK].includes(esp_platform.code)) {
                    this.isLoading = false;
                    this.form.lists = [];
                    // this.showSkip = true;
                    return;
                }



                return this.fetchLists(_data)
                    .then((response) => {


                        if (response.success) {
                            // this.form.list = null;
                            this.lists = [];

                            if (esp_platform.code == PLATFORMS_CODE.CONVERKIT) response.data = response.data.tags;
                            if (esp_platform.code == PLATFORMS_CODE.BLASTABLE) response.data = response.data.data.records;

                            this.lists = services.default.formatLists(response.data, esp_platform)
                            this.selectFromAdd(data)

                            if(platform.esp && (this.automation.platform && this.automation.platform.id == platform.id)) {
                                let automationLists = this.automation.lists[0];
                                let list = _.find(this.lists, (o) => { return o.code == automationLists.list_id && o.name == automationLists.name; });

                                if (list) {
                                    let receptionList = {code: list.list_id, name: list.name};
                                    if(typeof list.type !== 'undefined') this.form.list.type = list.type;

                                    this.form.list = receptionList;
                                }
                            } else {
                                this.form.list = null;
                            }


                            this.form.lists = this.lists;
                            this.isLoading = false;
                            let platformName = this.form.platform.name.toLowerCase();
                            let isTagPlatForm = ['ontraport', 'convertkit', 'keap', 'gohighlevel'].includes(platformName);
                            this.reception_lists = isTagPlatForm ? 'Reception Tags' : 'Reception Lists';
                            //eric
                        }

                    })
                    .catch((err) => {
                        this.$swal('Checking Api Failed', 'Error or this Api is not valid', 'error')
                        this.isLoading = false
                        this.form.lists = []
                        this.showSkip = true;
                        return []
                    })

            }

        },
        selectFromAdd(value) {
            let esp_platform = value;
            const CURR_COMP = this.$refs['step-platform'];

            let _esp_platform = _.find(CURR_COMP.platforms, function (o) {
                return o.name == value.name;
            });

            if (_esp_platform) {
                this.form.platform_code = _esp_platform.code
            }

        },
        platformName(name) {
            return helper.platformFormatName(name);
        },
        updateAccount(data) {

        },
        handleCustomLink(custom) {
            this.form.custom = custom
        },
        handlePlatformAdd(value) {
        },
        handleApiKey(value) {
        },
        async handleCheckForm() {


            let result = { valid: true, data: [] };

            let platform = this.form.platform ?? null

            if (!this.formIsValid()) {
                result.false = false
                this.$swal('Select Platform or Add New', 'Please Add Platform or Select once to continue!', 'warning')
                return new Promise((resolve, reject) => reject(result));
            }

            if (platform) {
                let fetchLists = async () => {

                    return await this.loadPlatformLists(platform)
                        .then((response) => {

                            result.valid = true
                            if (Array.isArray(response)) {
                                this.form.lists = response
                                result.data = response
                            }

                        })
                        .catch((error) => {
                            this.$swal('Checking Api Failed', 'Error or this Api is not valid', 'error')
                            result.valid = false
                            this.isLoading = false
                            return new Promise((resolve, reject) => reject(result));
                        })

                }

                await fetchLists()
            }


            return new Promise(resolve => resolve(result));
        },
        resetErrors() {
            this.errors = {}
        },
        formIsValid() {
            // let fields = this.steps[this.currStep].fields
            this.resetErrors();

            let fieldInputs = [];
            const currentStepComponent = this.$refs['current-step'];

            fieldInputs = [
                { name: 'name', type: String, require: true },
                { name: 'source', type: Object, require: true },
                { name: 'triggers', type: Array, require: true },
                { name: 'recordState', type: Array, require: false, label: 'record state' },
                { name: 'action', type: Object, require: false, label: 'time spent' },
                { name: 'timeSpent', type: Object, require: false, label: 'time spent' },

                { name: 'platform', type: Object, require: true },
                { name: 'list', type: Object, require: true },
                { name: 'lists', type: Array, require: false },
            ];


            _.forEach(fieldInputs, (field) => {
                let fieldValue = this.form[field.name];

                let label = helper.capitalizeFirstLetter(field.label ?? field.name)

                if (field.require || this.isFiltered) {

                    if (field.type.name == 'String') {
                        if (!fieldValue || fieldValue && fieldValue.trim() == '') {
                            this.errors[field.name] = label + ' is required.';
                        }
                    }

                    if (field.type.name == 'Object') {
                        if (!fieldValue) {
                            this.errors[field.name] = label + ' is required.';
                        }
                    }

                    if (field.name == 'triggers' && field.type.name == 'Array') {

                        this.filtersValidations();

                    } else if (field.type.name == 'Array') {
                        if (!fieldValue || (fieldValue && !fieldValue.length)) {
                            this.errors[field.name] = 'Please select at least one item from ' + label;
                        }
                    }
                }

            });

            fieldInputs = [
                { name: 'name', type: String, require: true },
                { name: 'source', type: Object, require: true },
                { name: 'triggers', type: Array, require: true },
                { name: 'recordState', type: Array, require: false, label: 'record state' },
                { name: 'action', type: Object, require: false, label: 'time spent' },
                { name: 'timeSpent', type: Object, require: false, label: 'time spent' },

                { name: 'platform', type: Object, require: true },
                { name: 'list', type: Object, require: true },
                { name: 'lists', type: Array, require: false },
            ];

            _.forEach(fieldInputs, (field) => {
                let fieldValue = this.form[field.name];

                let label = helper.capitalizeFirstLetter(field.label ?? field.name)

                if (field.require || this.isFiltered) {

                    if (field.type.name == 'String') {
                        if (!fieldValue || fieldValue && fieldValue.trim() == '') {
                            this.errors[field.name] = label + ' is required.';
                        }
                    }

                    if (field.type.name == 'Object') {
                        if (!fieldValue) {
                            this.errors[field.name] = label + ' is required.';
                        }
                    }

                    if (field.name == 'triggers' && field.type.name == 'Array') {

                        this.filtersValidations();

                    } else if (field.type.name == 'Array') {
                        if (!fieldValue || (fieldValue && !fieldValue.length)) {
                            this.errors[field.name] = 'Please select at least one item from ' + label;
                        }
                    }
                }

            });
            return helper.isEmpty(this.errors);
        },
        filtersValidations() {
            let triggers = this.getTriggers();
            const someTriggersTruthy = Object.values(triggers).some(val => val.value === true);

            _.forEach(triggers, (trigger) => {

                if (`${trigger.type}` == 'clean' && this.form.triggers.cleaning && !trigger.value) {
                    this.errors.recordState = 'Please select at least one item from ' + helper.capitalizeFirstLetter(`${trigger.type}`);
                }

                if (`${trigger.type}` == 'actions' && this.form.triggers.actions && !trigger.value) {
                    this.errors.actions = 'Please select at least one item from ' + helper.capitalizeFirstLetter(`${trigger.type}`);
                }

                if (`${trigger.type}` == 'time_spent' && this.form.triggers.timeSpent) {
                    let timeFromIsValid = helper.validateTime(this.form.timeSpentFrom);
                    let timeToIsValid = helper.validateTime(this.form.timeSpentTo);

                    if (timeFromIsValid && timeToIsValid) {

                        let timeBetweenIsValid = this.isValidTime(this.form.timeSpentFrom) && this.isValidTime(this.form.timeSpentFrom, '00:00:00', this.form.timeSpentTo) && this.isValidTime(this.form.timeSpentTo) && this.isValidTime(this.form.timeSpentTo, this.form.timeSpentFrom, '00:15:00');

                        if (!timeBetweenIsValid) {
                            this.errors.timeSpent = 'Please set Time Spent between 00:00 and 15:00 minutes';
                        }

                    } else if (!timeFromIsValid || !timeToIsValid) {

                        this.errors.timeSpent = 'Please enter a valid ' + helper.capitalizeFirstLetter(`${trigger.type}`) + '.';

                    }

                }
            });
        },
        isValidTime(time, minTime = '00:00:00', maxTime = '00:15:00') {

            let format = 'HH:mm:ss';
            const TIME = moment(time, format);
            const MIN_TIME = moment(minTime, format);
            const MAX_TIME = moment(maxTime, format);

            let state = TIME.isBetween(MIN_TIME, MAX_TIME, undefined, '[]');

            return state;

        },
        callbackCheckForm() {

            // Trigger next
            this.goToNextStep()

        },
        async goToNextStep() {
            await helper.delay(1500)

            const STEP = this.steps[this.currStep]

            this.currStep++
            this.currStepComponent = this.steps[this.currStep].component

            STEP.done = true
            this.steps[this.currStep].active = true
            this.isLoading = false;

        },
        getPostPlatform() {
            return {
                name: this.form.platform.name,
                account: this.form.account_id,
                key: this.form.api_key,
                organization: this.isAdmin ? this.organization : null
            }
        },
        isValidJson(value) {

            if (typeof value == "undefined") {
                return null
            }

            if (/^[\],:{}\s]*$/.test(value.replace(/\\["\\\/bfnrtu]/g, '@').
                replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']').
                replace(/(?:^|:|,)(?:\s*\[)+/g, ''))) {
                let token = JSON.parse(value)

                if (typeof token.accessToken !== 'undefined') token = token.accessToken
                else if (typeof token.access_token !== 'undefined') token = token.access_token

                if (typeof token.errors !== 'undefined' || typeof token.error !== 'undefined') {
                    return null
                }

                return token
            }

            return value
        },

        // Fetch and Post
        async fetchLists(platform) {


            let esp_code = platform.code

            let customUrl = null


            this.form.custom.link = null


            if (this.form.custom && (typeof this.form.custom.checkbox !== 'undefined')) {
                if (this.form.custom.checkbox) customUrl = this.form.custom.link
            }


            let options = {
                entity: [PLATFORMS_CODE.ONTRAPORT, PLATFORMS_CODE.KEAP, PLATFORMS_CODE.CONVERKIT].includes(platform.code) ? 'tags' : 'lists',
                account: platform.account,
                organization: platform.account_id,
                platform_id: platform.id,
                customUrl: customUrl
            }


            let _params = services.default.getListParamsByEspAndPlatform(platform, this.form.platform, options)

            return api.platforms.fetchServices(_params)
        },
        loadPlatformLists(data) {
            const currComponent = this.$refs['step-platform']

            let esp_platform = this.form.platform.esp;

            if (typeof esp_platform === 'object' && typeof esp_platform.code !== 'undefined') {

                let _data = { ...data, code: esp_platform.code, img: esp_platform.img }

                return this.fetchLists(_data)

                    .then((response) => {

                        if (response.success) {


                            if (this.form.platform.esp.code == PLATFORMS_CODE.CONVERKIT) response.data = response.data.tags;
                            if (this.form.platform.esp.code == PLATFORMS_CODE.BLASTABLE) response.data = response.data.data.records;

                            this.lists = services.default.formatLists(response.data, esp_platform)
                            this.selectFromAdd(data)

                            return response.data
                        }
                    })
                    .catch((err) => { return [] })
            }
        },
        handleSubmit() {

            this.isLoading = true;

            let message = 'Something went wrong!', title = 'Error!', success = 'danger', icon = 'error';


            // Check form Validation
            if (this.formIsValid()) {


                if (this.isAdmin && this.organization) {
                    this.form.organization = this.organization
                }

                const data = this.formData();

                let apiAction = null;
                if (typeof this.automation !== 'undefined' && this.automation.id) {
                    let code = this.automation.id;
                    apiAction = api.geAutomations.put(code, data);
                } else {
                    apiAction = api.geAutomations.post(data);
                }

                // send ajax query and interpret result for trigger next step
                apiAction.then((response) => {
                    if (response.success) {
                        this.liveLeadSource = response.data;

                        icon = 'success'
                        title = 'Success'
                        message = response.message
                        success = 'success'

                    }

                })
                    .catch((error) => {
                        console.log('Errors')
                    })
                    .finally(() => {
                        this.$swal({
                            title: title,
                            icon: icon,
                            text: message,
                            type: success,
                            showCancelButton: false,
                            showCloseButton: true,
                        }).then((result) => {
                            helper.delay(3000).then(() => {
                                this.isLoading = false;
                                // this.hideNextBtn = false;
                                if (this.liveLeadSource) {
                                    this.$emit('add', this.liveLeadSource)
                                    // this.$bvModal.hide(this.id)
                                }
                            });
                        })
                    })

            } else {

                helper.delay(300).then(() => {
                    this.$refs['step-automation'].showErrors();
                    this.$refs['step-platform'].showErrors();
                })

                this.isLoading = false;
            }

        },
        formData() {

            let triggers = this.getTriggers();
            let list = (this.form.list && typeof this.form.list.code !== 'undefined') ? [this.form.list] : []

            return {
                name: this.form.name,
                source_id: this.form.source.uuid,
                account_id: this.isAdmin ? this.form.organization : this.user.account_id,
                platform_id: this.form.platform ? this.form.platform.id ?? null : null,
                lists: list,
                triggers: triggers,
                status: this.form.status ? 1 : 0
            }
        },
        getTriggers() {
            let cleans = [];
            let actions = [];

            let timeSpent = this.form.triggers.timeSpent ? {
                from: `${this.form.timeFromH}:${this.form.timeFromM}:${this.form.timeFromS}`,
                to: `${this.form.timeToH}:${this.form.timeToM}:${this.form.timeToS}`,
            } : null;

            if (timeSpent) {
                this.form.timeSpentFrom = this.getTimeFrom();
                this.form.timeSpentTo = this.getTimeTo();
            }

            if (this.form.triggers.cleaning) {
                _.forEach(this.form.recordState, (state) => {
                    cleans.push(state.code);
                });
            }

            if (this.form.triggers.actions) {
                actions = this.form.action ? [this.form.action] : null
            }

            let triggers = [
                { type: 'clean', value: Array.isArray(cleans) && cleans.length ? JSON.stringify(cleans) : null },
                { type: 'actions', value: Array.isArray(actions) && actions.length ? JSON.stringify(actions) : null },
                { type: 'time_spent', value: timeSpent ? JSON.stringify(timeSpent) : null },
            ];

            return triggers;
        },
        getTimeFrom() {
            return `${this.form.timeFromH}:${this.form.timeFromM}:${this.form.timeFromS}`;
        },
        getTimeTo() {
            return `${this.form.timeToH}:${this.form.timeToM}:${this.form.timeToS}`
        },
        hideModal() {
            this.$bvModal.hide(this.id)
        },
        getAutomation() {
            let form = {};
            let triggersChecks = {
                cleaning: false,
                timeSpent: false,
                actions: false,
            };

            if (typeof this.automation !== 'undefined' && this.automation) {
                let list = null;
                form = {
                    id: this.automation.id,
                    name: this.automation.name,
                    source: '',
                    sourceOptions: [],
                    platform: this.automation.lists && this.automation.lists.length ? this.automation.lists[0].platform ?? null : null,
                    account_id: this.automation.account_id,
                    platforms: [],
                    list: this.automation.lists[0],
                    lists: this.automation.lists,
                    isFiltered: true,
                    recordState: null,
                    timeSpent: null,
                    cleanOptions: GE_RECORD_CLEAN_STATES,
                    actions: [
                        { code: 2, name: 'Add to Card' },
                        { code: 3, name: 'Checkout' },
                    ],
                    timeSpentFrom: null,
                    timeSpentTo: null,
                    timeFromH: '00',
                    timeFromM: '00',
                    timeFromS: '00',
                    timeToH: '00',
                    timeToM: '15',
                    timeToS: '00',
                    status: this.automation.status,

                    // For ESP with customs Events no yet used
                    tag: null,
                    CustomEventName: null,
                    CustomEventId: null,
                    sendin_blue_event: null,
                    customTag: null,
                    custom: { isVisible: null, checkbox: null, link: null },
                    isListOfTags: false
                };

                // Fix platform of automation
                if (form.platform) {
                    this.automation.platform = form.platform
                }

                if (this.automation.lists && this.automation.lists.length) {
                    list = { code: this.automation.lists[0].id, name: this.automation.lists[0].list_name };

                    // WEBHOOK Only
                    if (this.automation.platform && this.automation.platform.name.toLowerCase() == 'webhook') {
                        list.code = this.automation.lists[0].p_list_id ?? null;
                        list.link = JSON.parse(this.automation.lists[0].custom_columns).link;
                    }
                }

                // Formatted Time Spent
                let clean = _.find(this.automation.triggers, (o) => { return o.type == 'clean'; });
                if (clean) {
                    let cleaningArr = clean.value ? JSON.parse(clean.value) : [];
                    form.recordState = _.filter(form.cleanOptions, (cleanState) => { return cleaningArr.includes(cleanState.code) });
                    triggersChecks.cleaning = form.recordState.length ? true : false;
                }
                // End Formatted Time Spent

                // Formatted Time Spent
                let actions = _.find(this.automation.triggers, (o) => { return o.type == 'actions'; });
                if (actions) {
                    let actionsArr = JSON.parse(actions.value);
                    form.action = _.filter(form.actions, (o) => { return _.find(actionsArr, (item) => { return o.code == item.code; }); });
                    triggersChecks.actions = form.action && form.action.length ? true : false;
                }
                // End Formatted Time Spent


                // Formatted Time Spent
                let timeSpent = _.find(this.automation.triggers, (o) => { return o.type == 'time_spent'; });
                form.timeSpent = timeSpent && timeSpent.value ? JSON.parse(timeSpent.value) : null;
                if (form.timeSpent) {
                      form.timeSpentFrom = form.timeSpent.from ?? '00:00:00';
                      form.timeSpentTo = form.timeSpent.to ?? '00:00:00';
                      triggersChecks.timeSpent = true;

                      // Formatting Time Spent
                      let timeSpentFromArr = form.timeSpentFrom.split(':');
                      let timeSpentToArr = form.timeSpentTo.split(':');
                      form.timeFromH = timeSpentFromArr[0],
                          form.timeFromM = timeSpentFromArr[1],
                          form.timeFromS = timeSpentFromArr[2],
                          form.timeToH = timeSpentToArr[0],
                          form.timeToM = timeSpentToArr[1],
                          form.timeToS = timeSpentToArr[2];
                  }
                // End Formatted Time Spent

                if (this.automation.triggers && this.automation.triggers.length) {
                    form.isFiltered = true;
                }

                // Enable triggers if define
                form.triggers = triggersChecks;


                if (this.isAdmin && this.organization) {
                    this.form.organization = this.organization;
                    this.resetStepComp();
                }
            }

            return form
        },
        resetStepComp() {
            this.renderStep = false;

            this.$nextTick(() => {
                this.renderStep = true;
            })
        },
        setPlatform() {
            if (this.automation.platform) {
                this.dataIsLoading = true;


                if (typeof this.$refs['step-platform'] !== 'undefined' && typeof this.$refs['step-platform'].setPlatform == 'function') {
                    helper.delay(600).then(() => {
                        const data = { ...this.automation.platform, code: this.automation.platform.id }
                        this.handlePlatformSelect(data, true);

                        let existComponent = this.$refs['step-platform'].$refs['exist-platform']
                        existComponent.setPlatform({ ...this.automation.platform, code: this.automation.platform.id })

                        this.isLoading = false;
                        this.loadindPlatform = false;
                        this.toggleSpinnerPlatform();
                        return;
                    });
                }

                this.toggleSpinnerPlatform();

            }

        },
        handleDone() {
            this.editPlatform = false;
        },
        toggleSpinnerPlatform(value = true) {
            helper.delay(2000).then(() => {
                this.dataIsLoading = !value;
            });
        },
        cleanPlatform() {
            if (typeof this.$refs['step-platform'].$refs['exist-platform'] !== 'undefined' && typeof this.$refs['step-platform'].$refs['exist-platform'].setPlatform == 'function') {
                const CURR_STEP_COMP = _.find(this.steps, function (o) { return o.name == 'platform-tab'; });
                if (!CURR_STEP_COMP.fields.includes('platform_code')) CURR_STEP_COMP.fields.push('platform_code')

                let existComponent = this.$refs['step-platform'].$refs['exist-platform']
                existComponent.setPlatform(null)
                this.isLoading = false;
            }
        },
        cleanReceptionList() {
            this.form.list = null
            this.form.lists = []
            if (typeof this.$refs['step-integration'].setReceptionList == 'function') {
                let existComponent = this.$refs['step-integration']
                existComponent.setReceptionList()
            }
        },
        editAutomationPlatform() {
            this.editPlatform = true;

            if (this.form.platform) {
                this.handlePlatformSelect(this.form.platform);
            }

        },
        updateReceptionList(list) {
            console.log( 'updateReceptionList', list )
        }
    }
}
</script>

<style>
select.form-control[size],
select.form-control[multiple] {
    height: 45px !important;
}

.pt-0,
.py-0 {
    padding-top: 6px !important;
}

.select2-container {
    width: 100% !important;
}

.select2-search--dropdown .select2-search__field {
    width: 98%;
}

h3 {
    font-size: 1.5em;
}

#top-tab-list {
    margin: 0 -10px 45px;
    overflow: hidden;
    color: #777D74;
}

.spinnerAddTag {
    position: relative;
    top: -3.5rem;
    left: 95.9%;
}

.fit-image {
    width: 50% !important;
}

.iq-card {
    background: #ffffff;
    border-radius: 5px;
    margin-bottom: 30px;
    border: none;
    box-shadow: none !important;
}

.iq-card .iq-card-header {
    padding: 0 20px;
    min-height: 60px;
    border-bottom: 0px solid #f2edff;
    align-items: center !important;
}

.modal-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: .8rem 1rem;
    border-bottom: 0px solid #dee2e6;
    border-top-left-radius: calc(.3rem - 1px);
    border-top-right-radius: calc(.3rem - 1px);
}

div.swal2-html-container,
div#swal2-html-container {
    overflow-y: hidden;
}

tbody td[data-label="Reception List"] {
    max-width: 180px;
}

@media only screen and (max-width: 768px) {
    #top-tab-list li a {
        background: #e5f2ff;
        color: #0084ff;
        display: block;
        padding: 15px;
        margin: 0 1px;
        border-radius: 5px;
        -webkit-border-radius: 5px;
        text-decoration: none;
        font-size: 12px;
    }

    .justify-content-center .col-7 {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .spinnerAddTag {
        position: relative;
        top: -3rem;
        left: 85.9%;
    }
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
