<template>
<b-container fluid>
    <b-row>
        <b-col md="12">
            <iq-card>
                <template v-slot:headerTitle>
                    <h4 class="card-title">Affiliates</h4>
                </template>

                <template v-slot:headerAction>
                    <b-button v-b-modal variant="primary" @click="handleAdd($event)" class="text-right mr-1">Add
                        Affiliate</b-button>
                </template>

                <template v-slot:body>
                    <b-row>
                        <b-col lg="12">
                            <DtReferrals ref="dt-referrals" @onUpdatedTable="tableReady" @onShowCommissions="showCommissions" @handleEdit="handleEdit" @handleCommission="handleCommission" />
                        </b-col>
                    </b-row>
                </template>
            </iq-card>
        </b-col>
    </b-row>

    <!-- Start Add Referral Modal-->
    <b-modal :id="modal.id" :ref="modal.id" :title="modal.title" size="md" hide-footer class="bg-secondary py-0">
        <AddOrEditReferral :options="discounts" :referral="item" :referralId="id" @onSaved="savedReferral"></AddOrEditReferral>
    </b-modal>
    <!-- END Edit Referral Modal-->

    <!-- Start Add Referral Modal-->
    <b-modal id="commission" ref="modal-add-commission" :title="commissionTitle" size="md" hide-footer class="bg-secondary py-0">
        <Commission :referral="referral" @onSaved="SaveCommission"></Commission>
    </b-modal>
    <!-- END Edit Referral Modal-->

     <b-modal id="modal-time-line" ref="modal-timeLine" title="" :hide-footer=true>
        <timeLine :commissions="commissions" :affiliate="referral_name"></timeLine>
    </b-modal>
    <!-- Commissions table-->
    <div id="commissions">
        <iq-card>
            <b-row v-show="show_commissions" class="pb-5">
                <b-col md="8" offset-md="2" class="mt-5">
                    <h3 class="mb-3">Commissions for {{ referral_name }}</h3>
                    <DtCommissions :commissions="commissions"></DtCommissions>
                </b-col>
            </b-row>
        </iq-card>
    </div>
    <!-- End commissions table-->

</b-container>
</template>

<script>
import {
    sofbox
} from "@/config/pluginInit";
import {
    mapGetters
} from 'vuex'
import DtReferrals from './children/DtReferrals';
import AddOrEditReferral from './children/forms';
import Commission from './children/Commission';
import DtCommissions from './children/DtCommissions';
import Spinner1 from '@/components/loaders/spinner1'
import _ from 'lodash'
import api from '@/api/RestClient'
import timeLine from "./children/timeLine.vue";

export default {
    name: "ReferralList",
    components: {
        timeLine,
        Spinner1,
        Commission,
        DtReferrals,
        DtCommissions,
        AddOrEditReferral
    },
    created() {
        this.isAdmin = this.admin;
        this.modal = this.addModal;
    },
    mounted() {
        sofbox.index();
        this.discount();
    },
    data() {
        return {
            isAdmin: false,
            addModal: {
                id: 'modal-add-referrals',
                title: 'Add Referral',
                item: {}
            },
            editModal: {
                id: 'modal-edit-referrals',
                title: 'Edit Referral',
                item: {}
            },
            modal: {},
            discounts: [],
            item: {
                name: '',
                status: 0,
                discount_id: ''
            },
            id: null,
            referral: null,
            commissionTitle: '',
            commissions: [],
            show_commissions: false,
            referral_name: '***',
        };
    },
    computed: {
        ...mapGetters({
            admin: 'Auth/isAdmin',
            client: 'Auth/isClient',
        })
    },
    methods: {
        closeModal(item) {
            this.$root.$emit('bv::refresh::table', this.$refs['dt-referrals'].id)
        },
        handleAdd(event) {
            this.addModal.title = `Add New Referral`
            this.modal = this.addModal;
            this.item = {
                name: '',
                status: 0,
                commission: 0,
                discount_id: null,
            }
            this.$root.$emit('bv::show::modal', this.addModal.id)
        },
        handleEdit(item) {
            if (!item) {
                return
            }
            this.editModal.title = `Edit Referral [${item?.name}]`
            this.editModal.item = item
            this.modal = this.editModal;
            this.item = {
                name: item.name,
                status: item.status,
                commission: item.commission,
                discount_id: item.discount ? item.discount.id : null
            }
            this.id = item.id;
            this.$root.$emit('bv::show::modal', this.modal.id)
        },
        discount() {
            api.discounts.list({
                per_page: 100
            }).then(response => {
                let discounts = response.data.data;
                discounts = _.map(discounts, function (discount) {
                    return {
                        value: discount.id,
                        text: discount.name
                    }
                });

                this.discounts = discounts;
            })
        },
        savedReferral(status) {
            console.log(status);
            let variant = status ? 'success' : 'error';
            let message = status ? 'Referral created successfully' : 'An error occurred';
            let title = status ? 'Success' : 'Error';
            this.$bvToast.toast(message, {
                title: title,
                variant: variant
            });
            this.$root.$emit('bv::refresh::table', this.$refs['dt-referrals'].id)
            if (this.$refs['modal-add-referrals']) this.$refs['modal-add-referrals'].hide();
            if (this.$refs['modal-edit-referrals']) this.$refs['modal-edit-referrals'].hide();
        },
        SaveCommission(status) {
            let variant = status ? 'success' : 'error';
            let message = status ? 'Commission created successfully' : 'An error occurred';
            let title = status ? 'Success' : 'Error';
            this.$bvToast.toast(message, {
                title: title,
                variant: variant
            });
            this.$root.$emit('bv::refresh::table', this.$refs['dt-referrals'].id)
            if (this.$refs['modal-add-commission']) this.$refs['modal-add-commission'].hide();
        },
        handleCommission(item) {
            let total = this.total(item.accounts);
            let total_due = Math.round((total * item.commission) / 100, 2)
            let total_paid = this.calculateSum(item.commissions, 'total');
            item.unpaid = total_due - total_paid;

            this.referral = item;
            this.commissionTitle = `Commissions [Referral: ${item.name}]`
            this.$refs['modal-add-commission'].show()
        },
        calculateSum(array, property) {
            const total = array.reduce((accumulator, object) => {
                return accumulator + object[property];
            }, 0);

            return total;
        },
        total(data) {
            let total = 0;
            if (data.length > 0) {
                let accounts = data;
                accounts.forEach(account => {
                    if (account.revenue_stats.length > 0) {
                        let revenue_stats = account.revenue_stats;
                        revenue_stats.forEach(revenue_stat => {
                            total += revenue_stat.revenue;
                        });
                    }
                });
            }

            return `${total}`
        },
         showCommissions(item) {
            this.referral_name = item.name;
            this.commissions = item.commissions;
            this.$refs['modal-timeLine'].show();
        },
        showCommissions_old(item) {
            this.referral_name = item.name;
            this.commissions = item.commissions;
            this.show_commissions = true;
            $([document.documentElement, document.body]).animate({
                scrollTop: $("#commissions").offset().top
            }, 2000);
        },
        tableReady() {
            this.show_commissions = false;
        }
    },
    watch: {},
};
</script>

<style>
.actionClass {
    width: 18%;
}

.timeClass,
.createdatClass,
.accountClass {
    width: 10%;
}

textarea.form-control {
    line-height: 25px;
}

.cursor-default {
    cursor: default;
}

.table th,
.table td {
    padding: 0.55rem;
    vertical-align: top;
}

.action {
    width: 100%;
    height: 43.5px;
}
</style>
