<template>
<div>
    <b-table striped hover :busy="isBusy" :items="items" :fields="fields" show-empty>
        <template #table-busy>
            <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
            </div>
        </template>
        <template #empty="scope">
            <h6>No commissions found</h6>
        </template>
        <template #cell(created_at)="data">
            <span>{{ formatDateYMD(data.item.created_at) }}</span>
        </template>
        <!-- A custom formatted column -->
        <template #cell(action)="data">

            <div v-if="data.item.can_download == 1" class="cursor-pointer" @click="downloadFile(data.item)" v-show="true">
                <i class="ri-download-line"></i> Attachment
                <b-spinner small label="Small Spinner" type="grow" v-if="data.item.on_downloading == 1"></b-spinner>
            </div>
            <div v-else>
                <i class="fa fa-ban iq-bg-danger"></i>
            </div>
        </template>
    </b-table>
</div>
</template>

<script>
import api from '@/api/RestClient'
export default {
    name: 'dtCommissions',
    props: {
        commissions: {
            type: Array,
            default () {
                return [];
            }
        }
    },
    data() {
        return {
            isBusy: true,
            fields: [{
                    key: 'id',
                    label: '#',
                    sortable: true
                },
                {
                    key: 'total',
                    label: 'Total',
                    sortable: false
                },
                {
                    key: 'note',
                    label: 'Note',
                    sortable: true,
                },
                {
                    key: 'created_at',
                    label: 'Date',
                    sortable: true,
                },
                {
                    key: 'action',
                    label: 'Action',
                    sortable: true,
                }
            ],
            items: []
        }
    },
    methods: {
        downloadFile(item) {
            item.on_downloading = 1;
            api.commissions.download(item.id).then(response => {
                var fileURL = window.URL.createObjectURL(new Blob([response]));
                var fileLink = document.createElement('a');
                var fileName = 'commission-' + Date.now() + '.pdf';

                fileLink.href = fileURL;
                fileLink.setAttribute('download', fileName);
                document.body.appendChild(fileLink);

                fileLink.click();
            }).catch(err => {
                console.log(err);
                this.$bvToast.toast('Something went wrong', {
                    title: 'Warning',
                    variant: 'warning'
                });
            }).finally(() => {
                item.on_downloading = 0;
            })
        },
        formatDateYMD(date) {
            var d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2)
                month = '0' + month;
            if (day.length < 2)
                day = '0' + day;

            return [year, month, day].join('-');
        }
    },
    watch: {
        "commissions": {
            handler(newCommissions, oldCommissions) {
                let items = [];
                let item = {};
                this.isBusy = true;
                newCommissions.map(function (value, key) {
                    item = {
                        isActive: true,
                        id: value.id,
                        total: `${value.total}`,
                        note: value.note,
                        created_at: value.created_at,
                        file: value.file_link,
                        on_downloading: 0,
                        can_download: value.file_link ? 1 : 0,
                        action: '',
                    };
                    items.push(item);
                })
                this.items = items;
            },
        },
        "items": {
            handler(newItems, oldItems) {

                setTimeout(() => {
                    this.isBusy = false;
                }, "1000")

            }
        }
    }
}
</script>
