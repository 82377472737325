export default function (axios) {
  return {
    list: (params = {}) => {
      return axios.get(`/ge_sources`, { params })
        .then(response => response.data);
    },
    getBySource: (geSourceID = null) => {
      let url = geSourceID ? `/ge_sources/${geSourceID}` : `/ge_sources`
      return axios.get(url)
        .then(response => response.data);
    },
    get: (geSourceID = null) => {
      let url = geSourceID ? `/ge_sources/${geSourceID}` : `/ge_sources`
      return axios.get(url)
        .then(response => response.data);
    },
    post: (geSourceData = {}) => {
      return axios.post('/ge_sources', geSourceData)
        .then(response => response.data);
    },
    put: (geSourceID, geSourceData) => {
      return axios.put(`/ge_sources/${geSourceID}`, geSourceData)
        .then(response => response.data)
    },
    delete: async (geSourceID, data) => {
      return axios.delete(`/ge_sources/${geSourceID}`, { data: data })
        .then(response => response.data)
    },
    restore: (id) => {
      return axios.post(`/ge_sources/${id}/restore`, {})
        .then(response => response.data);
    },
    bulk: (items, data) => {
      return axios.put(`/ge_sources/bulk`, {items, data})
        .then(response => response.data)
    },
    bulkDelete: (items) => {
      return axios.put(`/ge_sources/bulk/delete`, {items})
        .then(response => response.data)
    },
    script: () => {
      return axios.get(`/ge_sources/script`)
        .then(response => response.data);
    },
    getSourcesNames: (params = {}) => {
      return axios.get(`/ge_sources/get-source-names`, { params })
        .then(response => response.data);
    },
    sendTestFlow: (source, email) => {
      return axios.get('ge_sources/sendflows/test?email=' + email + '&source=' + source)
        .then(response => response.data);
    },
    checkScriptInstallation: (source, params = {}) => {
      return axios.get('ge_sources/check-script/' + source, { params })
        .then(response => response.data);
    },
    fetchSuppressionLists:(source,params={})=>{
      return axios.get(`ge_sources/${source}/suppression-lists`, { params })
          .then(response => response.data);
    }
  }
}
