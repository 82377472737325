<template>
  <div>

    <div v-if="!choice" class="my-4">

      <div class="d-flex w-100 m-auto justify-content-center"  style="gap: 30px;">

        <div class="col text-center text-primary border-primary p-5 border rounded shadow-sm choice"
             @click="applyChoice('upload')">
          <div>
            <i class="ri-upload-cloud-2-fill font-size-32"></i>
          </div>

          <div>  Upload my own list </div>

        </div>

        <div class="col text-center text-primary border-primary p-5 border rounded shadow-sm choice"
             @click="applyChoice('platform')">
          <div>
          <i class="ri-git-branch-line font-size-32"></i>
          </div>

          <div>
          Fetch from Platform
          </div>
        </div>

      </div>

    </div>

    <div v-if="platform_choice">

      <b-form-group class="col-md-12">

      <b-row>
        <b-col>
          <label for="">Platform: *</label>
          <exist-platforms @platformSelected="handelPlatformSelectedEvent" :selectedPlatform="platform" :account="{accountid:account}"></exist-platforms>
          <small v-if="errors?.platform" class="text-danger">A platform should be selected</small>
        </b-col>
      </b-row>
    </b-form-group>

    </div>

    <!-- Navigation buttons -->
    <hr>

    <div class="d-flex justify-content-end" style="gap:5px">
      <button class="btn btn-light" @click="$emit('cancel')">Cancel</button>
      <button class="btn btn-primary d-flex align-items-center " :disabled="!valid" @click="next">Next  <i class="ri-arrow-right-line"></i> </button>
    </div>

  </div>


</template>

<script>

import ExistPlatforms from "@/components/inboxgeek/ExistPlatforms";
import UploadFileFiled from "@/components/inboxgeek/fileds/UploadFileFiled";
import SuppressionListField from "@/components/inboxgeek/fileds/SuppressionListField";

export default {
  name: "NameSuppressionListWizardModal",
  props:['account','form'],
  components: {UploadFileFiled, ExistPlatforms,SuppressionListField},
  mounted() {
    if(this.form.initial){
      this.name     = this.form.initial.name;
      this.platform = this.form.initial.platform;
    }
  },
  data(){
    return {
      choice:false,
      platform_choice:false,
      uploadFile:false,
      name,
      errors:{
        name:false,
        platform:false
      },
      platform:null

    }
  },
  methods:{

    next(){

      this.errors = {name:false,platform:false};



      if(!this.platform && this.uploadFile){
        this.errors.platform = true;
        return;
      }


      this.$emit('next',{
        platform:this.platform,
        uploadFile:false,
        name:this.name
      });

    },
    handelPlatformSelectedEvent(platform){
      if(platform){
        this.platform = platform;
        this.$emit('preloadLists',platform);
      }

    },
    applyChoice(choice){

      if(choice === 'upload'){

        this.errors = {name:false,platform:false};

        this.$emit('next',{
          uploadFile:true,
          name:this.name
        });

      }else if (choice === 'platform'){
        this.choice = true;
        this.platform_choice = true;
      }
    }
  },
  computed:{
    valid(){

      return ! (!this.platform);

    }
  }

}

</script>

<style scoped>
.choice {
  cursor:pointer;
  border: 2px dashed #ff739a !important;
}
.choice:hover {
  box-shadow: 0px 0px 3px #f6386bdb, 1px 1px 13px #f6386b69 !important;
  border: 2px solid #ff739a !important;
}
</style>