<template>
    <fieldset>
        <legend></legend>

        <div class="form-card text-left">
            <b-row>
                <b-col class="my-auto" md="12" v-show="!toggleIntegration">
                    <b-alert class="bg-white w-100" :show="true" :variant="isConnected ? 'success' : 'secondary'">
                        <div class="iq-alert-text w-100">
                            <h3 v-if="title" class="alert-heading">
                                <i class="ri-information-line fa-lg"></i> {{ title }}
                            </h3>
                            <p>
                                Your script has been successfully generated.
                                <br>
                                Click on icon <span class="text-primary"><i class="ri-file-copy-2-line fa-lg"></i></span> to
                                copy the Script Generated.
                            </p>
                            <hr>
                            <div class="mb-0">
                                <b-form-group label-for="docs">
                                    <label for="docs" class="d-block">
                                        <a href="https://help.inboxgeek.com/liveleads/" target="_blank" variant="link"
                                            class="btn btn-light mb-1 mr-1 px-2">
                                            <i class="ri-article-line fa-lg text-primary mx-0" v-b-tooltip.top
                                                title="Documentation"></i>
                                            Documentation
                                        </a>
                                    </label>
                                </b-form-group>
                            </div>

                            <div class="mb-0">
                                <b-form-group label-for="script">
                                    <label for="script" class="d-block">
                                        Script Generated
                                        <span v-if="isConnected" class="badge badge-success">Connected</span>

                                        <i class="ri-file-copy-2-line fa-lg text-primary pull-right" v-b-tooltip.top
                                            title="Click to Copy Script" role="button" @click="copyScript($event)"></i>
                                    </label>

                                    <b-input-group v-if="!isConnected" class="col-md-12 my-1 px-0" label="URL Script Intalled:"
                                        label-for="urlSuppScript">
                                        <b-form-input name="urlScript" size="sm" :placeholder="form.website"
                                            v-model="urlScript"></b-form-input>


                                        <template #append>
                                            <button class="btn btn-info btn-sm float-right"
                                                @click="checkInstallation($event)" :disabled="isLoading || checkIsLoading">
                                                <b-spinner small type="grow" v-show="checkIsLoading"></b-spinner>
                                                Check Script Installation
                                            </button>
                                        </template>
                                    </b-input-group>

                                    <p v-if="scriptErrors.scriptWebsite" class="text-danger">{{ scriptErrors.scriptWebsite }}</p>

                                    <b-form-textarea rows="3" v-model="script" :readonly="true"></b-form-textarea>
                                </b-form-group>
                            </div>

                            <hr v-if="hasOption(current_service,'suppression')">

                            <div class="mb-0" v-if="hasOption(current_service,'suppression')">
                                <b-form-group>
                                    <b-form-checkbox name="check-button" v-model="useSuppression" switch
                                        class="d-inline pull-right">
                                        Use Suppression
                                    </b-form-checkbox>
                                </b-form-group>
                            </div>


                            <div class="mb-0">
                                <b-form-group label-for="script-supp">

                                    <span v-if="useSuppression">
                                        <label for="script-supp" class="d-block">
                                            Script Generated for Suppression
                                            <span v-if="suppIsConnected" class="badge badge-success">Connected</span>

                                            <i class="ri-file-copy-2-line fa-lg text-primary pull-right" v-b-tooltip.top
                                                title="Click to Copy Suppression Script" role="button"
                                                @click="copySuppScript($event)"></i>
                                        </label>

                                        <b-input-group v-if="!suppIsConnected" class="col-md-12 my-1 px-0" label="URL Suppression Script Intalled:"
                                            label-for="suppUrlScript">
                                            <b-form-input name="suppUrlScript" size="sm" :placeholder="form.website"
                                                v-model="suppUrlScript"></b-form-input>


                                            <template #append>
                                                <button class="btn btn-primary btn-sm float-right"
                                                    @click="checkInstallation($event, true)"
                                                    :disabled="isLoading || checkSuppIsLoading">
                                                    <b-spinner small type="grow" v-show="checkSuppIsLoading"></b-spinner>
                                                    Check Suppression Script Installation
                                                </button>
                                            </template>
                                        </b-input-group>

                                        <p v-if="scriptErrors.suppScriptWebsite" class="text-danger">{{ scriptErrors.suppScriptWebsite }}</p>

                                        <b-form-textarea rows="3" v-model="suppScript" :readonly="true"></b-form-textarea>
                                    </span>

                                </b-form-group>
                            </div>
                        </div>
                    </b-alert>
                </b-col>
            </b-row>

        </div>

    </fieldset>
</template>
<script>
import { helper } from '@/helpers';
import api from '@/api/RestClient'
import {SERVICES} from "@/constantes";
import {mapGetters} from "vuex";

export default {
    name: "PartialFinish",
    props: ['form', 'errors', 'title'],
    data() {
        return {
            current_service : SERVICES.LIVE_LEADS.id,
            script: null,
            suppScript: null,
            toggleIntegration: false,
            useSuppression: false,
            isLoading: false,
            isConnected: false,
            suppIsConnected: false,
            urlScript: '',
            suppUrlScript: '',
            checkIsLoading: false,
            checkSuppIsLoading: false,
            scriptErrors: {}
        }
    },
    mounted() {
        let script = helper.generateScript(this.form.code);
        this.script = script.innerHTML;
        this.$emit('onFinish', this.form);
        let suppScript = helper.generateSuppressionScript(this.form.code);
        this.suppScript = suppScript.innerHTML;
    },
    methods: {
        copyScript(e) {
            e.preventDefault();
            let script = helper.generateScript(this.form.code);
            this.script = script.innerHTML;

            if (this.script) {
                navigator.clipboard.writeText(this.script)
                this.$bvToast.toast('Script Copied', {
                    title: 'Copy Script',
                    variant: 'info'
                })
            }
        },
        copySuppScript(e) {
            e.preventDefault();
            let script = helper.generateSuppressionScript(this.form.code);
            this.suppScript = script.innerHTML;

            if (this.suppScript) {
                navigator.clipboard.writeText(this.suppScript)
                this.$bvToast.toast('Suppression Script Copied', {
                    title: 'Copy Suppression Script',
                    variant: 'info'
                })
            }
        },
        checkInstallation($event, isSupp = false) {
            $event.preventDefault();
            this.scriptErrors = {};

            if (this.form.id) {
                this.checkIsLoading = true;
                let params = {};
                let url = this.setCheckingType(isSupp);

                // Check url is valid
                if (url) {

                    if (!helper.isValidURL(url.trim())) {

                        let message = 'Please insert valid URL Website.'
                        if (!isSupp) {
                            this.scriptErrors.scriptWebsite = message;
                        } else {
                            this.scriptErrors.suppScriptWebsite = message;
                        }

                        this.checkIsLoading = false;
                        this.checkSuppIsLoading = false;
                        return;
                    }

                    params.url = url;

                }

                let liveLeadSourceId = this.form.id;

                // Condition for Suppression Script
                if (isSupp) {
                    params.supp = isSupp;
                }

                api.geSources.checkScriptInstallation(liveLeadSourceId, params)
                    .then((response) => {

                        if (response.success) {
                            if (!isSupp) {
                                this.isConnected = true;
                            } else {
                                this.suppIsConnected = true;
                            }

                            this.$swal('Pixel Script Installation', response.message, 'success')
                        }

                    })
                    .catch((error) => {
                        let message = error.response.data.message;

                        if (typeof error.response.data.message !== 'undefined') {
                            message = error.response.data.message;
                        }

                        this.$swal('Pixel Script Installation Error!', message, 'warning')
                    })
                    .finally(() => {
                        helper.delay(2000).then(() => {
                            this.checkIsLoading = false;
                            this.checkSuppIsLoading = false;
                        })
                    })
            }
        },
        setCheckingType(isSupp) {
            let url = null;

            if (!isSupp) {
                this.isConnected = false;
                this.checkIsLoading = true;

                if (this.urlScript) {
                    url = this.urlScript;
                }
            } else {
                this.suppIsConnected = false;
                this.checkSuppIsLoading = true;

                if (this.suppUrlScript) {
                    url = this.suppUrlScript;
                }
            }

            return url;
        }
    },
    computed : {
      ...mapGetters({
        hasOption : 'Auth/hasOption'
      }),
    },

};
</script>
