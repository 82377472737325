import { update } from 'lodash';
import { Main } from '../Integrations/';
import { RESPONSE_STATUS } from "../../../constantes";
import { COLUMNS_GRID } from "../../../constantes";
import { PLATFORMS_CODE, PLATFORMS_ITEMS } from "../../../constantes";
import api from '@/api/RestClient';
import { services } from '@/helpers';


export const Add = {
  addIntegration(comp) {
    // empty for this time
  },
  addIntegrationFormColumns(COMP) {

    let platform = COMP.form.platform
    let platformId = platform.code;
    let platformName = platform.name.toLowerCase();
    COMP.showCols.groupCol = false;

    COMP.showCols.auth2Btn = false;
    COMP.showCols.secretKey = false;
    COMP.showCols.tags = true;
    COMP.showCols.event = false;
    COMP.showCols.sendin_blue_event = false;
    COMP.showCols.event_link = false;
    COMP.$refs["step-platform"].$refs.nextStep1.classList.remove('d-none')
    COMP.$refs["step-platform"].$refs.auth2AccountsGroup.classList.add('d-none')
    COMP.$refs["step-platform"].accountIsVisible = true;

    COMP.form.accountid = ''
    COMP.tagLabel = 'Tag: *'

    COMP.auth2.addNew = true // New line
    COMP.custom.isVisible = false;
    COMP.auth2.platforms = [];
    COMP.auth2.platform = null;


    if (platformId == PLATFORMS_CODE.BLASTABLE) COMP.custom.isVisible = true;



    if (COMP.$data.form.platform && ([PLATFORMS_CODE.MAROPOST, PLATFORMS_CODE.ACTIVE_CAMPAIGN, PLATFORMS_CODE.CONVERKIT].includes(platformId))) {

      COMP.accountLabel = 'Account ID';
      COMP.accountPlaceholder = 'Account ID';

      COMP.apiTokenLabel = 'API key/Token: *';
      COMP.apiTokenPlaceholder = 'API key';

      COMP.cols.platformCol = COLUMNS_GRID.COL12;
      COMP.cols.accoundIdCol = COLUMNS_GRID.COL12;
      if(typeof COMP.cols.apiKeyCol !== 'undefined')  COMP.cols.apiKeyCol = COLUMNS_GRID.COL12;

      if(typeof COMP.showCols.accountId!== 'undefined')  COMP.showCols.accountId = true;
      // COMP.accountIsVisible = true;
      COMP.$refs["step-platform"].accountIsVisible = true;
      if(typeof COMP.showCols.apiKey !== 'undefined')  COMP.showCols.apiKey = true;


      if (platformId == PLATFORMS_CODE.ACTIVE_CAMPAIGN) {
        COMP.accountLabel = 'Account ID';
        COMP.accountPlaceholder = 'https://<youraccountname>.api-us1.com';
      } else if (platformId == PLATFORMS_CODE.CONVERKIT) {
        COMP.accountLabel = 'API Secret';
        COMP.accountPlaceholder = 'Api secret';
      }

    } else if ([PLATFORMS_CODE.EXPERTSENDER].includes(platformId)) {


      COMP.apiTokenLabel = 'API Key:';
      COMP.apiTokenPlaceholder = 'API key';
      if(typeof COMP.cols.apiKeyCol !== 'undefined')  COMP.cols.apiKeyCol = COLUMNS_GRID.COL12;
      if(typeof COMP.showCols.apiKey !== 'undefined')  COMP.showCols.apiKey = true;
      COMP.showCols.tags = false;
      COMP.showCols.event = true;

    }else if ([PLATFORMS_CODE.SENDINBLUE].includes(platformId)) {


      COMP.apiTokenLabel = 'API Key:';
      COMP.apiTokenPlaceholder = 'API key';
      if(typeof COMP.cols.apiKeyCol !== 'undefined')  COMP.cols.apiKeyCol = COLUMNS_GRID.COL12;
      if(typeof COMP.showCols.apiKey !== 'undefined')  COMP.showCols.apiKey = true;
      COMP.showCols.tags = false;
      COMP.showCols.sendin_blue_event = true;

    }else if ([PLATFORMS_CODE.WEBHOOK,PLATFORMS_CODE.INBOXSUITE].includes(platformId) ) {

      if(typeof COMP.showCols.accountId!== 'undefined')  COMP.showCols.accountId = false;
      COMP.apiTokenLabel = '';
      COMP.apiTokenPlaceholder = '';
      if(typeof COMP.cols.apiKeyCol !== 'undefined')  COMP.cols.apiKeyCol = COLUMNS_GRID.COL12;

      if(platformId != PLATFORMS_CODE.INBOXSUITE){
        COMP.showCols.apiKey      = false;
        COMP.tagLabel             = "Event: *";
        COMP.selectTagPlaceholder = "Select an event";
        COMP.tagPlaceholder       = "Create a new event";
      }


      COMP.showCols.tags = false;
      COMP.showCols.event = false;
      COMP.showCols.event_link = true;
      COMP.form.tagSelected = '';
      COMP.form.platform.key ='';

    }
    else if([PLATFORMS_CODE.BEEHIIV].includes(platformId)) {
      COMP.tagLabel             = "Automations: *";
      COMP.selectTagPlaceholder = "Select an automation";
      COMP.tagPlaceholder       = "Create a new automation";
    } else {

      COMP.cols.platformCol = COLUMNS_GRID.COL6;
      if(typeof COMP.cols.apiKeyCol !== 'undefined')  COMP.cols.apiKeyCol = COLUMNS_GRID.COL12;
      if(typeof COMP.showCols.accountId!== 'undefined')  COMP.showCols.accountId = false;
      if(typeof COMP.showCols.apiKey !== 'undefined')  COMP.showCols.apiKey = true;

      if (platformId == PLATFORMS_CODE.ONTRAPORT) {

        if(typeof COMP.showCols.accountId!== 'undefined')  COMP.showCols.accountId = true;
        if(typeof COMP.cols.apiKeyCol !== 'undefined')  COMP.cols.apiKeyCol = COLUMNS_GRID.COL12;

        COMP.accountLabel = 'APP ID';
        COMP.accountPlaceholder = 'App Id';
      } else if (COMP.oauthPlatforms.includes(platformId)) {

        COMP.$refs["step-platform"].accountIsVisible = false;
        COMP.showCols.auth2Btn = true;
        if(typeof COMP.showCols.accountId!== 'undefined')  COMP.showCols.accountId = true;
        if(typeof COMP.showCols.apiKey !== 'undefined')  COMP.showCols.apiKey = false;
        let action = COMP.$route.query.action;


        COMP.$refs["step-platform"].$refs.nextStep1.classList.add('d-none')
        COMP.$refs["step-platform"].$refs.auth2AccountsGroup.classList.remove('d-none')

        // [New Update Oauth]
        if (COMP.oauthPlatforms.includes(platformId)) {
          if ((typeof services[platformName] !== "undefined")) services[platformName].updateLabels(COMP)
        }

        if (platformId == PLATFORMS_CODE.AWEBER) {
          COMP.accountLabel = 'Account ID';
          COMP.accountPlaceholder = 'Account ID';
          COMP.auth2.btnLabel = 'Add New Aweber';
          if (typeof action === "undefined" || action == '') {
            COMP.accountLabel = 'Platforms Added';
            COMP.accountPlaceholder = 'Select settings to use';

            // check and set already platform added for organisation
            COMP.auth2.btnAuthorize = true;
            COMP.auth2.inProgress = true;

            let auth2ServiceApi = api.aweberPlatforms.getPlatformsAlreadyAdded({ organizationId: COMP.organization.accountid });

            auth2ServiceApi.then(function (result) {
              Add.auth2LoadPlatforms(COMP, result);
            })
              .catch(function (error) {
                COMP.auth2.addNew = true
              })
              .finally(function () {
                COMP.auth2.btnAuthorize = false;
                COMP.auth2.inProgress = false;
              })
          }
        } else if (platformId == PLATFORMS_CODE.KEAP) {
          COMP.auth2.addNew = true;
          // COMP.$refs.auth2AccountsGroup.classList.remove('d-none');

          COMP.accountLabel = 'Account ID Keap';
          COMP.accountPlaceholder = 'Account ID Keap';
          COMP.auth2.btnLabel = 'Add New Keap';

          if (typeof action === "undefined" || action == '') {
            COMP.accountLabel = 'Platforms Added';
            COMP.accountPlaceholder = 'Select settings to use';

            // check and set already platform added for organisation
            COMP.auth2.btnAuthorize = true;
            COMP.auth2.inProgress = true;

            let auth2ServiceApi = api.keapPlatforms.getPlatformsAlreadyAdded({ organizationId: COMP.organization.accountid });

            auth2ServiceApi.then(function (result) {
              Add.auth2LoadPlatforms(COMP, result);
            })
              .catch(function (error) {
                COMP.auth2.addNew = true
              })
              .finally(function () {
                COMP.auth2.btnAuthorize = false;
                COMP.auth2.inProgress = false;
              })
          }
        } else if (platformId == PLATFORMS_CODE.MAILCHIMP) {
          COMP.auth2.addNew = true;
          // COMP.$refs.auth2AccountsGroup.classList.remove('d-none');

          COMP.accountLabel = 'Account ID Mailchimp';
          COMP.accountPlaceholder = 'Account ID Mailchimp';
          COMP.auth2.btnLabel = 'Add New Mailchimp';

          if (typeof action === "undefined" || action == '') {
            COMP.accountLabel = 'Platforms Added';
            COMP.accountPlaceholder = 'Select settings to use';

            // check and set already platform added for organisation
            COMP.auth2.btnAuthorize = true;
            COMP.auth2.inProgress = true;

            let auth2ServiceApi = api.mailchimpPlatforms.getPlatformsAlreadyAdded({ organizationId: COMP.organization.accountid });

            auth2ServiceApi.then(function (result) {
              Add.auth2LoadPlatforms(COMP, result);
            })
              .catch(function (error) {
                COMP.auth2.addNew = true
              })
              .finally(function () {
                COMP.auth2.btnAuthorize = false;
                COMP.auth2.inProgress = false;
              })
          }
        }

        if (COMP.auth2.listAccounts.length) {
          COMP.$refs["step-platform"].$refs.nextStep1.classList.remove('d-none')
        }
      }
    }

    if (platformId == PLATFORMS_CODE.KLAVIYO){
      COMP.tagLabel = "Event: *";
      COMP.selectTagPlaceholder = "Select an event";
      COMP.tagPlaceholder = "Create a new event";
    }

    if (platformId == PLATFORMS_CODE.BEEHIIV){
      COMP.tagLabel = "Automations: *";
      COMP.selectTagPlaceholder = "Select an automation";
      COMP.tagPlaceholder = "Create a new automation";
    }
    // console.log( COMP.showCols, COMP.$refs["step-platform"].showCols )
  },
  reinitializeOauth(COMP) {
    COMP.form.oAccountID = null;
    COMP.auth2.platform = null;
    COMP.auth2.platforms = [];
    COMP.auth2.listAccounts = [];
  },
  getOAuthPlatform(COMP, platform_id) {

    COMP.auth2.btnAuthorize = true;
    COMP.auth2.inProgress = true;

    // To reinstall auth2
    Add.reinitializeOauth(COMP);

    let platformName = COMP.form.platform.name.toLowerCase();
    var servicePlatform = (typeof services[platformName] !== "undefined")
      ? services[platformName] : null;

    var auth2Service = null;
    if (servicePlatform)
      auth2Service = servicePlatform.oauthGetAccountInfo({ platform_id: platform_id }) // To add

    // Replace by new oauth - add require service.[platformName].oauthGetAccountInfo
    // if (PLATFORMS_CODE.AWEBER == COMP.form.platform.code) auth2Service = api.aweberPlatforms.getAweberAccounts({ platform_id: platform_id })
    if (PLATFORMS_CODE.KEAP == COMP.form.platform.code) auth2Service = api.keapPlatforms.getAccountInfo({ platform_id: platform_id })
    if (PLATFORMS_CODE.MAILCHIMP == COMP.form.platform.code) auth2Service = api.mailchimpPlatforms.getAccountInfo({ platform_id: platform_id })
    // End Replace by new oauth


    auth2Service.then(function (result) {
      if (servicePlatform) Add.oauthLoadData(COMP, result, platformName)

      // Replace by new oauth - add require servicePlatform
      if (PLATFORMS_CODE.AWEBER == COMP.form.platform.code) Add.oauthLoadData(COMP, result?.data, 'aweber');
      if (PLATFORMS_CODE.KEAP == COMP.form.platform.code) Add.oauthLoadData(COMP, result, 'keap');
      if (PLATFORMS_CODE.MAILCHIMP == COMP.form.platform.code) Add.oauthLoadData(COMP, result, 'mailchimp');
      // End Replace by new oauth

    })
      .catch(function (error) {
        console.log(error)
      })
      .finally(function () {
        COMP.auth2.btnAuthorize = false;
        COMP.auth2.inProgress = false;
      })
  },
  oauthLoadData(COMP, result, _name = null) {
    if (result.success && (typeof result?.data?.platform_id !== "undefined" && result?.data?.platform_id)) {
      // Test and use store state
      localStorage.setItem(`${_name}.platform_id`, result.data.platform_id);

      var mapFunc = () => { };
      var account = {};
      var dataArray = [];

      // NEW UPDATE Load Oauth data with access token query
      let platform = COMP.form.platform
      let platformName = platform.name.toLowerCase();

      if ((typeof services[platformName] !== "undefined")) {
        dataArray = services[platformName].oauthGetUsers(result?.data)
        account = services[platformName].oauthDataLabel()
        mapFunc = (obj) => services[platformName].oauthDataMap(obj)
      }
      // END

      /**
       * Replace by new oauth - add require service.[platformName].oauthGetAccountInfo
       * methods to add [oauthGetUsers, oauthDataLabel, oauthDataMap]
       */
      switch (_name) {
        case 'aweber':
          dataArray = result?.data?.data
          mapFunc = (obj) => {
            let item = {}
            item.code = obj.id
            item.name = obj.uuid
            return item
          }
          account = { label: 'Account ID', placeholder: 'Account ID' }
          break;
        case 'keap':
          dataArray = result?.data?.users
          mapFunc = (obj) => {
            let item = {}
            item.code = obj?.global_user_id
            item.name = obj?.preferred_name
            return item
          }
          account = { label: 'Keap Users', placeholder: 'Selected User' }
          break;
        case 'mailchimp':
          dataArray = []; dataArray.push(result?.data?.account)
          mapFunc = (obj) => {
            let item = {}
            item.code = obj.user_id
            item.name = obj.accountname
            return item
          }
          account = { label: 'Mailchimp User', placeholder: 'Selected User' }
          break;
        default:
      }
      // End Replace by new oauth

      if (dataArray.length) {
        const _data = _.map(dataArray, (item) => mapFunc(item))
        COMP.auth2.listAccounts = _data;
        COMP.auth2.addNew = true // New line
        COMP.$refs["step-platform"].accountLabel = account.label;
        COMP.$refs["step-platform"].accountPlaceholder = account.placeholder;
      } else Add.reinitializeOauth(COMP);
      COMP.nextStep1 = false;
      $('.next').attr('disabled', false);

      COMP.showCols.auth2Btn = false;
      COMP.$refs["step-platform"].$refs.nextStep1.classList.remove('d-none')
    }
  },
  auth2LoadPlatforms(COMP, result) {
    if (result.success && (typeof result?.data?.platforms !== "undefined")) {
      const _old_platforms = _.map(result.data.platforms, (obj, key) => {
        let item = {}
        item.code = obj.id
        item.name = `${obj.name} [${obj.account}]`
        return item
      })
      COMP.auth2.platforms = _old_platforms;
      COMP.auth2.addNew = false;
    } else {
      COMP.auth2.addNew = true
    }
  },
  auth2Done(btn, COMP) {
    setTimeout(() => {
      COMP.form.oAccountID = null;
      COMP.auth2.btnAuthorize = false;
      COMP.auth2.inProgress = false;
      $(btn).attr('disabled', false);
    }, 1500);
  },
  refreshKeyAuth2(that, click, code = null, platform_id) { },
  authorizeData(result, that, code) {
    if (result.success && (typeof result?.data?.url !== "undefined")) {
      that.$store.dispatch('updateOAuth2Organization', that.organizationId);
      if (result?.data?.url)
        that.$store.dispatch('updateOAuth2AuthUrl', result.data.url);
      if (result?.data?.params)
        that.$store.dispatch('updateOAuth2AuthParams', result.data.params);

      // if (result?.data?.platform_id) that.$store.dispatch('updateOAuth2AuthParams', result.data.params);
      localStorage.setItem('oauth.install', params);
    }

    if (result.success && (typeof result?.data?.authorizationUrl !== "undefined")) {

      var authUrl = null;
      var params = {};

      if (result?.data?.authorizationUrl) if (typeof result?.data?.authorizationParams !== "undefined") authUrl = result?.data?.authorizationUrl;
      if (result?.data?.authorizationParams) if (typeof result?.data?.authorizationParams !== "undefined") params = JSON.parse(result.data.authorizationParams);

      if (result?.data?.params) if (typeof result?.data?.authorizationParams !== "undefined") params = JSON.parse(result.data.params);

      that.$store.dispatch('updateOAuth2AuthUrl', authUrl);
      that.$store.dispatch('updateOAuth2AuthParams', params);
      that.$store.dispatch('updateOAuth2Organization', that.organizationId);

      if (PLATFORMS_CODE.AWEBER == code) {
        if (result?.data?.platform_id) localStorage.setItem('aweber.platform_id', result.data.platform_id);
      } else if (PLATFORMS_CODE.KEAP == code) {
        if (result?.data?.platform_id) localStorage.setItem('keap.platform_id', result.data.platform_id);
      } else if (PLATFORMS_CODE.MAILCHIMP == code) {
        if (result?.data?.platform_id) localStorage.setItem('mailchimp.platform_id', result.data.platform_id);
      }
    }
  }
}
