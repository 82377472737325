<template>
    <ul class="navbar-list">
        <li>
            <a href="#" class="search-toggle iq-waves-effect bg-primary text-dark profile">
                <!-- <img :src="userProfile" class="img-fluid rounded" alt="user"> -->
                <i class="fas fa-user-circle fa-2x text-white"></i>
            </a>
            <div class="iq-sub-dropdown iq-user-dropdown">
                <div class="iq-card shadow-none m-0">
                    <div class="iq-card-body p-0 ">
                        <div class="bg-primary p-3">
                            <h5 class="mb-0 text-white line-height" style="font-size: 1.25rem;">Hello there, &nbsp;{{
                                user.name | uppercase
                            }}</h5>
                            <span class="text-white font-size-12">{{ role }}</span>
                        </div>
                        <a href="#" v-show="false" class="iq-sub-card iq-bg-primary-hover">
                            <div class="media align-items-center">
                                <div class="rounded iq-card-icon iq-bg-primary">
                                    <i class="ri-file-user-line"></i>
                                </div>
                                <div class="media-body ml-3">
                                    <h6 class="mb-0 ">My Profile</h6>
                                    <p class="mb-0 font-size-12">View personal profile details.</p>
                                </div>
                            </div>
                        </a>
                        <router-link :to="{ name: 'user.edit' }" class="iq-sub-card iq-bg-primary-success-hover">
                            <div class="media align-items-center">
                                <div class="rounded iq-card-icon iq-bg-success">
                                    <i class="ri-user-settings-line"></i>
                                </div>
                                <div class="media-body ml-3">
                                    <h6 class="mb-0">Profile</h6>
                                    <p class="mb-0 font-size-12 text-secondary">Your Personal Details.</p>
                                </div>
                            </div>
                        </router-link>
                        <router-link v-show="!isAdmin || !isAccountManager" :to="{ name: 'user.billing' }"
                            class="iq-sub-card iq-bg-primary-danger-hover">
                            <div class="media align-items-center">
                                <div class="rounded iq-card-icon iq-bg-danger">
                                    <i class="ri-account-box-line"></i>
                                </div>
                                <div class="media-body ml-3">
                                    <h6 class="mb-0 ">Subscriptions</h6>
                                    <p class="mb-0 font-size-12 text-secondary">Manage Your Subscriptions.</p>
                                </div>
                            </div>
                        </router-link>
                        <router-link :to="{ name: 'user.settings' }" class="iq-sub-card iq-bg-primary-secondary-hover">
                            <div class="media align-items-center">
                                <div class="rounded iq-card-icon iq-bg-secondary">
                                    <i class="ri-profile-line"></i>
                                </div>
                                <div class="media-body ml-3">
                                    <h6 class="mb-0">Settings</h6>
                                    <p class="mb-0 font-size-12 text-secondary">General Settings.</p>
                                </div>
                            </div>
                        </router-link>
                        <a href="#" v-show="false" class="iq-sub-card iq-bg-primary-secondary-hover">
                            <div class="media align-items-center">
                                <div class="rounded iq-card-icon iq-bg-secondary">
                                    <i class="ri-lock-line"></i>
                                </div>
                                <div class="media-body ml-3">
                                    <h6 class="mb-0">Privacy Settings</h6>
                                    <p class="mb-0 font-size-12 text-secondary">Control your privacy parameters.</p>
                                </div>
                            </div>
                        </a>
                        <div class="d-inline-block w-100 text-center p-3">
                            <a class="iq-bg-danger iq-sign-btn" href="#" @click.prevent="logout" role="button">Sign
                                out<i class="ri-login-box-line ml-2"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </li>
    </ul>
</template>

<script>
import api from '@/api/RestClient'
import {
    mapGetters
} from 'vuex'
export default {
    name: 'ProfileIcon',
    props: {
        user: {
            type: [Object],
            default: () => null,
        },
    },
    data() {
        return {
            isAdmin: false,
            isClient: false,
            role: 'Client',
        }
    },
    mounted() {
        this.setRole();
      //  console.log(this.isAccountManager);
    },
    computed: {
        ...mapGetters({
            admin: 'Auth/isAdmin',
            isLogged: 'Auth/loggedIn',
            isAccountManager: 'Auth/isAccountManager'
        })
    },
    methods: {
        setRole() {
            this.role = this.admin ? 'Admin' : 'client';
            this.isAdmin = this.admin;
            this.isClient = !this.admin;
        },
        logout() {
            api.auth.logout().then(response => {

                this.$bvToast.toast(response.message, {
                    title: response.error ? 'Warning' : 'Success',
                    variant: response.error ? 'warning' : 'success'
                });
                this.$store.dispatch('Auth/updateLoggedIn', false);
                this.$store.dispatch('Auth/updateSupportChatRoomID', null);

                setTimeout(() => {
                    window.location.href = '/';
                }, 2500, this);
            }).catch(err => {
                this.$bvToast.toast('Something went wrong', {
                    title: 'Error!',
                    variant: 'danger'
                });
             
            }).finally(() => { })

        },
    },
    filters: {
        uppercase(value) {
            return value ? value.toUpperCase() : '--'
        }
    }
}
</script>

<style>
a.profile {
    display: flex !important;
    align-items: center;
    justify-content: center;
    height: 100%;
    background: #f6386b !important;
}
</style>
