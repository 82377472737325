<template>

<b-alert :variant="type"  class="d-flex justify-content-center" style="margin-bottom:0;border-radius: 0;" :show="show_announcement">
  <div id="announces-nav" class="px-4 d-flex text-center" ref="IBGAnnounceNav">
    <div class=" text-white w-100 announce-body"   style="font-size:1rem;margin-left: 260px"  v-html="message">

    </div>

  </div>

  <span style="cursor: pointer;" @click="closeAnnouncement()">x</span>
</b-alert>
</template>

<script>


import api from '@/api/RestClient'

export default {
  name: "AnnouncementNav",
  mounted() {

    api.settings.fetchAnnouncement()
      .then((response)=>{


        if(response.success){


          this.message = response.data.message;
          this.show_announcement = (response.data.status == '1');
          this.type = response.data.type;

        }


      }).then(()=>{

      const divElement = document.querySelector(".announce-body");
      const height = divElement?.clientHeight;

       if(!isNaN(parseFloat(height)) && isFinite(height)) {
         this.paddingTop = 100 + height;
       }

      this.updatePagePadding();

    })

        .catch((error)=>{

        console.log('could not fetch announcement status',error);

      })



  },
  data() {
        return {
          message : "",
          show_announcement:false,
          type : 'info',
          paddingTop : 160
        }
  },
  methods :{

    updatePagePadding(){

      if(this.show_announcement){

        this.$root.$el.querySelector('.content-page').style.paddingTop =  this.paddingTop  + 24 + 'px';

      }

    },
    closeAnnouncement(){
      this.show_announcement = false;
      this.$root.$el.querySelector('.content-page').style.paddingTop =  "100px";
    }
  }
}


</script>


<style>
.announce-body p {
  margin : 0 !important;
  color:white !important;
}
.announce-body a {
  color:white !important;
}
.alert-warning  .announce-body  a {
  color:#212529 !important;
}
.alert-warning  .announce-body p {
  color:#212529 !important;
}
</style>

<style scoped>

.alert-warning {
    color: #212529;
    border-color: #ffd400;
    background-color: #ffd400;
}

.alert-info {
    color: #ffffff;
    border-color: #5ad;
    background-color: #5ad;
}
</style>
