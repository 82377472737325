export default function (axios) {

  return {

    uploadList: (data, progress) => {

      if (process.env.NODE_ENV === 'production') {
        let axiosInstance = axios.create({
          baseURL: process.env.VUE_SERVICE_BASE_URL_API,
          timeout: 120000
        });

        return axiosInstance.post(`/platforms/ibgesp/lists`, data, progress)

      }
      return axios.post(`/platforms/ibgesp/lists`, data, progress)

    }

  }
}
