<template>
   <!--  MODAL Add Balances  -->
   <b-row>
          <b-col lg="12" md="12">
              <b-modal  id="modal-add-balance"  ref="add-balance" hide-footer title="Add Balance">
                  
                <b-form @submit="submit" class="p-4">
                  
                    <b-form-group  id="input-group-1" label=""  description="">
                      <label for="input-1" >Add balance :</label>                        
                      <b-form-input  id="input-balance" v-model="formData.event_amount" type="number" placeholder="Event amount to be added" required></b-form-input>
                    </b-form-group>

                    <b-form-group id="input-group-1" label="Notes :" label-for="input-1" description="">
                      <b-form-textarea rows="2" max-rows="2" id="input-description" v-model="formData.description" placeholder="Describe why the balance is updated" class="mb-2" required></b-form-textarea>
                        
                    </b-form-group>


                    <div class="text-right">
                        <b-button variant="success" type="submit" :disabled="loading.modal"> Add Balance <b-spinner small type="grow" v-show="loading.modal"></b-spinner></b-button>
                    </div>
                  </b-form>

              </b-modal>
          </b-col>
      </b-row>
      <!-- END MODAL Balances  -->

</template>

<script>
import api from '@/api/RestClient';

export default {

  name:'add-balance-modal',
  props:['account','balance'],
  mounted(){
    
  },
  data(){
    return {
      loading: {
        modal:false
      },
      formData : {
        event_amount  : null,
        description   : null,
        service_id    : null
      },

    }
  },
  methods : {

    submit( event ) {

      event.preventDefault();

      this.loading.modal = true;

      const account_id = this.account.id;
      this.formData.service_id = this.balance.service_id;
      const data       = this.formData;

      api.balances.add(account_id,this.balance.id,data).then(response => {

          
          let variant = 'danger';
          let message = 'Something went wrong'

          if ('success' in response) {

              variant = response.success ? 'success' : 'danger';
              message = response.message;
              this.toast('account', variant, message);

              if (response.success){

                  this.$refs['add-balance'].hide();

                  this.loading.table   = true;

                  this.formData =  { event_amount : null, description: null };

                  this.$emit('balanceAdded');
                  
              }
          }

          this.loading.modal = false;


      }).catch( (error) => {

          this.toast('Balance', 'error', error.response.data.message);
          this.loading.modal = false;

      });


    },

    toast(title = "success", variant = "success", message = "Organization updated successfully") {

      this.$bvToast.toast(message, {

          title   : title,
          variant : variant
      })

    },

  }
  
}
</script>