<template>
    <fieldset>
        <h3 class="text-left mb-4">Platform Information:</h3>
        <!-- <p class="text-left">Create Reception List or Select an already added.</p> -->


        <!-- Platform already added -->
        <div v-if="!btnAddNew" class="my-2">
            <ExistPlatforms ref="exist-platform" :account="{ accountid: form.organization ?? organization ?? null }" @addNewPlatform="onAddPlatform"
                @platformSelected="selectExistingPlatform" :selectedPlatform="form.platform" service="ll_service"></ExistPlatforms>
        </div>
        <b-row v-else class="text-left">

            <!-- platforms multiselect component -->
            <b-col md="12">
                <b-form-group class="col-md-12" label="Platform" label-for="platform">

                    <component ref="platforms-select" is="PlatformsSelect" v-bind="{ platform, disabled: false }"
                        @input="updatePlatform" />

                    <small v-if="errors.platform_code" ref="errors_platform" class="text-danger"
                        :class="{ 'd-none': !errors.platform_code }">
                        {{ errors.platform_code }}
                    </small>

                </b-form-group>

            </b-col>


            <!-- account -->
            <b-col v-if="showCols.accountId && !showCols.oauth" md="12">
                <b-form-group class="col-md-12" :label="accountLabel" label-for="account">

                    <component is="AccountIdInput" v-bind="getAccountIDProps()" @input="updateAccount" />

                    <small v-if="errors.account_id" ref="errors_account_id" class="text-danger"
                        :class="{ 'd-none': !errors.account_id }">{{ errors.account_id
                        }}</small>
                </b-form-group>

            </b-col>

            <!-- Oauth account -->
            <b-col v-if="showCols.oauth" md="12">
                <b-form-group class="col-md-12" :label="accountLabel" label-for="account">

                    <component ref="oauth-account" is="OauthAccountId"
                        v-bind="{ label: accountLabel, placeholder: accountPlaceholder, accountId: '', options: p_records }"
                        @input="updateAccount" />

                    <small v-if="errors.account_id" ref="errors_account_id" class="text-danger"
                        :class="{ 'd-none': !errors.account_id }">{{ errors.account_id
                        }}</small>
                </b-form-group>

            </b-col>



            <!-- api_key/token -->
            <b-col v-if="showCols.apiKey" md="12">
                <b-form-group class="col-md-12" :label="apiKeyLabel" label-for="api_key">

                    <component is="KeyInput" v-bind="getKeyProps()" @input="updateApiKey" />

                    <small v-if="errors.api_key" ref="errors_api_key" class="text-danger"
                        :class="{ 'd-none': !errors.api_key }">{{ errors.api_key
                        }}</small>

                </b-form-group>

            </b-col>

            <b-col v-show="custom.isVisible" md="12">

                <b-form-group class="col-md-12" :label="''">
                    <b-form-checkbox name="check-button" v-model="custom.checkbox" switch size="sm">
                        Sending domain
                        <label data-on-label="On" data-off-label="Off"></label>
                    </b-form-checkbox>
                    <b-form-input v-show="custom.checkbox" v-model="custom.link" type="text" name="blastable-link"
                        placeholder="example.com" :disabled="!custom.checkbox">
                    </b-form-input>

                    <small v-if="errors.custom" ref="errors_api_key" class="text-danger"
                        :class="{ 'd-none': !errors.custom }">
                        {{ errors.custom }}
                    </small>
                </b-form-group>

            </b-col>
        </b-row>


    </fieldset>
</template>
<script>
import ExistPlatforms from '@/components/inboxgeek/ExistPlatforms'
import PlatformsSelect from '@/components/inboxgeek/inputs/PlatformsSelect'
import AccountIdInput from '@/components/inboxgeek/inputs/platforms/KeyInput'
import OauthAccountId from '@/components/inboxgeek/inputs/platforms/OauthAccountId'
import KeyInput from '@/components/inboxgeek/inputs/platforms/KeyInput'
import PlatformsMultiselect from '@/components/inboxgeek/inputs/PlatformsMultiselect'
import Multiselect from 'vue-multiselect'
import { PLATFORMS_CODE, PLATFORMS_ITEMS, OAUTH_ESP } from "@/constantes";
import { services, helper } from '@/helpers';


export default {
    name: "StepPlatform",
    props: ['form', 'errors', 'btnAdd', 'organization'],
    components: {
        KeyInput,
        AccountIdInput,
        OauthAccountId,
        Multiselect,
        ExistPlatforms,
        PlatformsSelect,
        PlatformsMultiselect
    },
    data() {
        return {
            btnAddNew: false,
            logoWidth: 28,
            platform: null,
            platforms: [],
            oautESP: OAUTH_ESP,

            // key
            apiKeyLabel: 'API key/Token: *',
            apiKeyPlaceholder: 'API key',

            // organization
            accountLabel: 'Account ID:',
            accountPlaceholder: 'Account ID',

            p_records: [],

            auth2: {},
            cols: {},

            custom: {
                isVisible: false,
                checkbox: false,
                link: ''
            },
            showCols: {
                oauth: false,
                apiKey: true,
                accountId: true,
                secretKey: true,
            }
        }
    },
    created() {
        this.platforms = this.platformsSorted
    },
    mounted() {
        this.platform = this.form.platform
      
        if (typeof this.form.platform_recorded !== 'undefined') {
            this.platform = this.form.platform_recorded
        }

        if (typeof this.btnAdd !== 'undefined') {
            this.btnAddNew = this.btnAdd
        }

        if (Object.keys(this.errors).length > 0) {
           // console.log('error',this.errors);
            //this.btnAddNew = true
        }

        this.showErrors()
    },
    methods: {
        showErrors() {
            const that = this
            _.forOwn(that.errors, function (error, key) {
                const ref = `errors_${key}`
                if (typeof that.$refs[ref] !== 'undefined') that.$refs[ref].classList.remove('d-none')
            });
        },
        resetErrors() {
            const that = this
            _.forOwn(this.errors, function (error, key) {
                const ref = `errors_${key}`
                if (typeof that.$refs[ref] !== 'undefined') that.$refs[ref].classList.add('d-none')
            });
        },
        onAddPlatform($event) {
            this.btnAddNew = true
            this.$emit('onAddPlatform', this.btnAddNew)
        },
        selectExistingPlatform(platform) {
            let platformSelected = null

            if (platform) {
                this.platform = platform
                platformSelected = platform
            }
            this.$emit('platformSelected', platformSelected)
        },
        emitInput(platformSelected) {
            this.$emit('input', platformSelected)
        },
        getImageURL(img) {
            try {
                return require(`@/assets/images/platforms/${img}`)
            } catch (e) {
                return require(`@/assets/images/platforms/default.png`)
            }
        },
        onChangeAccount(value) {
        },
        showAnotherCols(data) {

            if (!data || typeof data === 'undefined') return

            let platform_code = null
            let platform_name = null

            if (typeof data.code !== 'undefined') platform_code = data.code
            if (typeof data.name !== 'undefined') platform_name = data.name.toLowerCase()

            // Custom fields
            if (platform_code == PLATFORMS_CODE.BLASTABLE) this.custom = { ...this.custom, isVisible: true }
            else this.custom = { ...this.custom, isVisible: false }
            // End Custom fields


            let service = null
            if ((typeof services[platform_name] !== "undefined")) service = services[platform_name]

            this.updateFormDisplay()

            if (platform_code && ([PLATFORMS_CODE.MAROPOST, PLATFORMS_CODE.ACTIVE_CAMPAIGN, PLATFORMS_CODE.CONVERKIT].includes(platform_code))) {
                this.accountIsRequire()
            }

            if (typeof service?.customShowAnotherCols === 'function') service.customShowAnotherCols(this)

            // COMP.oautESP
            if (this.oautESP.includes(platform_code)) {
                this.showCols.apiKey = true;
                this.showCols.accountId = false;

                if (typeof services.default.initializeOauth === 'function') services.default.initializeOauth(this)

                if (typeof service?.updateLabels === 'function') service.updateLabels(this)
                if (typeof service?.oauthLoadPlatforms === 'function') service.oauthLoadPlatforms(this, this.form.organization)

            }

        },
        accountIsRequire() {
            this.accountLabel = 'Account ID: *';
            this.accountPlaceholder = 'Account ID';
            this.apiKeyLabel = 'API key/Token: *';
            this.apiKeyPlaceholder = 'API key';
        },
        updateFormDisplay() {
            this.showCols.apiKey = true;
            this.showCols.accountId = true;
            this.showCols.secretKey = false;
            this.showCols.oauth = false;
            this.custom.isVisible = false;

            this.apiKeyLabel = 'API key/Token: *';
            this.apiKeyPlaceholder = 'API key';

            this.accountLabel = 'Account ID:';
            this.accountPlaceholder = 'Account ID';

        },
        updatePlatform(value) {
            this.resetErrors()
            this.showAnotherCols(value)
            this.$emit('updatePlatform', value)
        },
        updateApiKey(value) {
            this.$emit('updateApiKey', value)
        },
        updateAccount(value) {
            this.$emit('updateAccount', value)
        },
        getAccountIDProps() {
            let config = {
                label: this.accountLabel,
                placeholder: this.accountPlaceholder
            }
            return config
        },
        getKeyProps() {
            let config = {
                label: this.apiKeyLabel,
                placeholder: this.apiKeyPlaceholder
            }
            return config
        },
        setAddNew(value) {
            if (typeof variable == "boolean") this.btnAddNew = value
        }
    },
    watch: {
        "errors": {
            handler(newVal, oldVal) {
                this.showErrors()
            },
            deep: true
        },
        "btnAddNew": {
            handler(newVal, oldVal) {
                if (newVal) this.platform = null
                this.showErrors()
                this.$emit('onNewPlatform', newVal)
            },
            deep: true
        },
        "custom": {
            handler(newVal, oldVal) {
                this.$emit('onCustomLink', newVal)
            },
            deep: true
        },
    },
    computed: {
        platformsSorted() {
            return _.sortBy(PLATFORMS_ITEMS, ['name', 'code']);
        }
    },
};
</script>