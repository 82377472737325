<template>
    <b-container fluid>

        <b-row>
            <b-col v-for="(card, index) in cards" :key="index" md="6" lg="3">
                
                <ChatStatsCard 
                    :period="card.period" 
                    :label="card.label" 
                    :itemClass="card.itemClass" 
                    :iconClass="card.iconClass">
                </ChatStatsCard>

            </b-col>
        </b-row>

    </b-container>
</template>

<script>

import ChatStatsCard from './ChatStatsCard'

export default {
    name: 'ChatStatsGroup',
    components: {
        ChatStatsCard
    },
    data() {
        return {
            cards: [
                {
                    label: 'Today',
                    itemClass: 'iq-bg-success',
                    iconClass: 'ri-message-3-line',
                    period: "today"
                },
                {
                    label: 'Yesterday',
                    itemClass: 'iq-bg-danger',
                    iconClass: 'ri-message-3-line',
                    period: "yesterday"
                },
                {
                    label: 'This Week',
                    itemClass: 'iq-bg-warning',
                    iconClass: 'ri-message-3-line',
                    period: "week"
                },
                {
                    label: 'This Month',
                    itemClass: 'iq-bg-primary',
                    iconClass: 'ri-message-3-line',
                    period: "month"
                },
            ],
        }
    }
}

</script>