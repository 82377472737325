<template>
<div class="sign-up1" style="margin-top:0;">
    <h1 class="mb-0">You’re One Step Closer to:</h1>
    <ul style="font-size: 1rem;padding-inline-start:20px" class="advantages_list">
        <li>Higher open rates</li>
        <li>Improved Email Deliverability</li>
        <li>Up to a 20% lift in email revenue</li>
    </ul>
    <p v-if="false">Enter your email address and password to access admin portal.</p>
    <form id="register" class="mt-4" @submit="submit">
        <b-row>
            <b-col sm="12" lg="6">
                <div class="form-group">
                    <label for="name">Full Name</label>
                    <input required type="name" class="form-control mb-0" id="name" aria-describedby="emailHelp" placeholder="Enter Full Name" v-model="form.name">
                    <div class="text-danger" id="full_name">{{errors.full_name}}</div>
                </div>
            </b-col>
            <b-col sm="12" lg="6">
                <div class="form-group">
                    <label for="email">Email address</label>
                    <input required type="email" @change="sendEmail" class="form-control mb-0" id="email" aria-describedby="emailHelp" placeholder="Enter email" v-model="form.email">
                    <div class="text-danger" id="email_address">{{errors.email_address}}</div>
                </div>
            </b-col>
            <b-col sm="12" lg="6">
                <div class="form-group">
                    <label for="password">Password</label>
                    <input required type="password" class="form-control mb-0" id="password" placeholder="Password" v-model="form.password">
                    <div class="text-danger" id="password">{{errors.password}}</div>
                </div>
            </b-col>
            <b-col sm="12" lg="6">
                <div class="form-group">
                    <label for="password_confirmation">Repeat password</label>
                    <input required type="password" class="form-control mb-0" id="password_confirmation" placeholder="Repeat password" v-model="form.password_confirmation">
                    <div class="text-danger" id="repeat_password">{{errors.repeat_password}}</div>
                </div>

            </b-col>

            <b-col sm="12" lg="12">
                <div class="form-group">
                    <label for="account">Company Name</label>
                    <input required type="text" class="form-control mb-0" id="account" placeholder="Enter Account" v-model="form.account">
                    <div class="text-danger" id="organization">{{errors.organization}}</div>
                </div>

            </b-col>

            <b-col sm="12" lg="12" v-show="false">
                <div class="form-group">
                    <label for="address">Address</label>
                    <b-form-textarea id="address" placeholder="Enter Address" rows="2" v-model="form.address"></b-form-textarea>
                </div>
            </b-col>
            <b-col lg="12">
                <div class="d-inline-block w-100">
                    <div class="custom-control custom-checkbox d-block mt-2 pt-1">
                        <input type="checkbox" class="custom-control-input" id="termsAndConditions" v-model="form.terms" @change='acceptTerms'>
                        <label class="custom-control-label" for="termsAndConditions" v-show="true">By signing up I am accepting InboxGeek's
                          <a :href="termsUrl" target="_blank">Terms and Conditions</a>
                        </label>
                    </div>
                    <button type="submit" class="btn btn-primary float-right" :disabled="submitDisabled">
                        <b-spinner small type="grow" v-show="onsubmit"></b-spinner>
                        Sign Up
                    </button>

                  <div class="text-white text-center info-link" v-if="true" style="margin-top:1em; position:relative; top:2em">
                    <p>Questions? Click
                      <a class='text-white' :href="scheduleUrl" target="_blank">here</a> to schedule a
                      call with the InboxGeek team</p>
                  </div>
                </div>
            </b-col>
        </b-row>
        <div v-if="false" class="sign-info">

            <span class="dark-color d-inline-block line-height-2" v-if="false">Already Have Account ? <router-link :to="{ name: 'auth1.sign-in1' }">Sign in</router-link></span>
            <ul class="iq-social-media" v-show="false">
                <li><a href="#"><i class="ri-facebook-box-line"></i></a></li>
                <li><a href="#"><i class="ri-twitter-line"></i></a></li>
                <li><a href="#"><i class="ri-instagram-line"></i></a></li>
            </ul>
            <h3 class="dark-color d-inline-block line-height-2" v-if="true">Clients who love InboxGeek</h3>
            <div class="partners_logos d-flex mt-3">
                <b-link href="#" class="iq-media partner">
                    <b-img class="avatar-400" fluid :src="require('@/assets/images/logo/partners/Dogtooth-White2.png')" alt="" />
                </b-link>
                <b-link href="#" class="iq-media partner">
                    <b-img class="avatar-400" fluid :src="require('@/assets/images/logo/partners/logo-vitality.png')" alt="" />
                </b-link>
                <b-link href="#" class="iq-media partner">
                    <b-img class="avatar-400" fluid :src="require('@/assets/images/logo/partners/loop7-1.png')" alt="" />
                </b-link>
                <b-link href="#" class="iq-media partner">
                    <b-img class="avatar-400" fluid :src="require('@/assets/images/logo/partners/upsender-logo.png')" alt="" />
                </b-link>
            </div>

        </div>
    </form>
</div>
</template>

<script>
import api from '@/api/RestClient'
import {
    helper
} from '@/helpers'
export default {
    name: 'SignUp1',
    created() {
        this.initReferrer()
        this.referrerJoshuaToChronos();
        this.affiliates();
    },
    mounted(){
        this.saveEmail();
        this.sendEmail();
    },
    data() {
        return {
            form: {
                name: '',
                email: '',
                password: '',
                password_confirmation: '',
                account: '',
                address: '',
                referer: '',
                terms: false,
            },
            scheduleUrl: 'https://calendly.com/inboxgeek/support-call',
            termsUrl: 'https://inboxgeek.com/terms-of-use/',
            submitDisabled: true,
            onsubmit: false,
            partner: null,
            errors: {},
            referer: null,
            sliderOption: {
                centerMode: true,
                centerPadding: '30px',
                slidesToShow: 5,
                slidesToScroll: 1,
                focusOnSelect: true,
                responsive: [{
                    breakpoint: 992,
                    settings: {
                        arrows: false,
                        centerMode: true,
                        centerPadding: '30',
                        slidesToShow: 5
                    }
                }, {
                    breakpoint: 480,
                    settings: {
                        arrows: false,
                        centerMode: true,
                        centerPadding: '15',
                        slidesToShow: 1
                    }
                }],
                nextArrow: '<a href="#" class="ri-arrow-left-s-line left"></a>',
                prevArrow: '<a href="#" class="ri-arrow-right-s-line right"></a>'
            },
            clients: [{
                    image: require('@/assets/images/logo/partners_/loop7.png')
                },
                {
                    image: require('@/assets/images/logo/partners_/vitaly_now.jpg')
                },
                {
                    image: require('@/assets/images/logo/partners_/upsender.png')
                },
                {
                    image: require('@/assets/images/logo/partners_/green_med.jpg')
                },
                {
                    image: require('@/assets/images/logo/partners_/dogtooth.jpg')
                },
            ],
        }
    },
    methods: {
        saveEmail() {

            if (this.$route.query.email) {

                this.form.email = this.$route.query.email;
            }
        },
        sendEmail() {

            let validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
            if(this.form.email.match(validRegex))
            {
                api.auth.registerEmail(this.form.email).then(response => { });

            }

        },
        submit(event) {
            event.preventDefault();
            this.onsubmit = true;
            this.checkForm();
            if (helper.isEmpty(this.errors)) {
                api.auth.register(this.form).then(data => {
                        let title = 'Error';
                        let type = 'danger';
                        if (data.success) {
                            title = 'Success';
                            type = 'success';
                            setTimeout(() => {
                                let via = this.$route.params.via
                                if (via == undefined) via = this.$route.query.via;
                                const link = via != undefined ? '&via=' + via : '';
                                window.location.assign("/thank-you?email=" + this.form.email + link)
                            }, 1000);
                        }
                        this.toast(title, type, data.message)
                    })
                    .catch(err => {
                       var data = err.response.data;
                        for (let name in data.errors) {
                            for (let key in data.errors[name]) {
                                this.toast('Error: ' + name, 'danger', data.errors[name][key]);
                            }
                        }
                    })
                    .finally(() => {
                        this.onsubmit = false;
                    })
            } else this.onsubmit = false;

        },
        resetErrors() {
            this.errors = {}
        },
        checkForm() {
            this.resetErrors();
            if (!this.form.name) {
                this.errors.full_name = 'Name required.';
            }
            if (!this.form.email) {
                this.errors.email_address = 'Email required.';
            }
            if (!this.form.password) {
                this.errors.password = 'Password required.';
            }
            if (!this.form.password_confirmation) {
                this.errors.repeat_password = 'Repeat password required.';
            }
            if (!this.form.account) {
                this.errors.organization = 'Organization required.';
            }
            if (!helper.equal(this.form.password, this.form.password_confirmation)) {
                this.errors.repeat_password = 'Password did not matched';
            }
        },
        acceptTerms() {
            this.submitDisabled = true;
            const termChecked = this.form.terms;
            if (termChecked) this.submitDisabled = false;
        },
        toast(title = "success", variant = "success", message = "Account charged") {
            this.$bvToast.toast(message, {
                title: title,
                variant: variant
            })
        },
        affiliates() {
            if (typeof this.form.referer !== 'undefined') {
                let data = {
                    'name': this.form.referer
                }
                api.referrals.post(data).then(response => {}).catch(error => {
                    console.log(error);
                }).finally(() => {

                })
            }
        },
        referrerJoshuaToChronos() {
            if (this.form.referer == 'joshua') this.form.referer = 'chronos';
        },
        initReferrer() {
            this.form.referer = this.$route.params.via ?
                this.$route.params.via :
                this.$route.query.via;
        }
    }
}
</script>

<style>
label[for=termsAndConditions]>a[target] {
    text-decoration: none;
    font-weight: normal;
}

ul.advantages_list li {
    list-style-type: disc;
}

.iq-additional_slider img {
    height: 70px;
    width: auto;
}

.slick-list {
    margin-top: 20px;
}

.avatar-400 {
    height: auto;
    width: 100%;
}


.client-box {
  margin-top: 2em;
}

.partners_logos {
    border: 1px solid #FFF;
    border-radius: 5px;
    background: #FFF;
    padding: 5px;
    max-width: 60em;
    text-align: center;
    margin: auto;
}

.sign-up1 {
   margin-top: 3em;
 }

</style>
