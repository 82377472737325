import { Add } from '@/actions/modules/Integrations/AddIntegrations';
import api from '@/api/RestClient';

export default function platform(axios) {
    return {
        labels: {
            listID: "list_uid",
            tagID: "tag_id",
            tagName: "tag_name",

        },
        maps: {
            listsMap: (obj) => {
                let labels = platform(axios).labels
                let item = {}
                if(typeof obj.general !== 'undefined') {
                    item.code = obj.general[labels.listID]
                    item.name = obj.general.name   
                } else {
                    return obj
                }
                return item
            },
            tagsMap: (obj) => {
                let labels = platform(axios).labels
                let item = {}
                item.code = obj.general[labels.listID]
                item.name = obj.general.name
                return item
            }
        },
        customShowAnotherCols(COMP) {
            if (typeof COMP.showCols.apiKey !== 'undefined') COMP.showCols.apiKey = true;
            if (typeof COMP.showCols.accountId !== 'undefined') COMP.showCols.accountId = false;
            COMP.custom.isVisible = true;
        },
        getTagAdded(result) {
            let labels = platform(axios).labels
            result = 'data' in result ? result.data : result;
            return { code: result?.general?.list_uid, name: result?.general?.display_name }
        }
    }
}