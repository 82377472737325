<template>
    <b-container fluid>
        <b-row>
            <b-col md="12">
                <iq-card class="">
                    <template v-slot:headerTitle>
                        <b-row class="mt-4">
                            <b-col md="4">
                                <b-form-group label="From" label-for="from">
                                    <b-form-input id="from" type="month" :max="dates.to" v-model="dates.from"></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col md="4">
                                <b-form-group label="To" label-for="to">
                                    <b-form-input id="to" type="month" :min="dates.from" v-model="dates.to"></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col md="4">
                                <b-form-group label="To" label-for="submit">
                                    <b-button class="action" variant="primary" :disabled="submitLoad" @click="onSubmit">
                                        <b-spinner small type="grow" v-if="submitLoad"></b-spinner>
                                        Submit
                                    </b-button>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col ld="12">
                                <b-form-checkbox v-model="isGroupByProduct" name="check-button" switch>
                                    Group by Product?
                                </b-form-checkbox>
                            </b-col>
                        </b-row>
                    </template>
                   <template  v-if="this.is_manager" v-slot:headerAction>
                     <b-form class="mx-3 mt-3">
                        <PlansMultiselect @onSelect="onSelectPlans" />
                    </b-form>
                    <b-form class="mx-3 mt-3">
                        <AccountsMultiselect @onSelect="onSelectAccounts" />
                    </b-form>
                   </template>
                </iq-card>
                <iq-card>
                    <template v-slot:headerAction>
                        <h4 class="float-end">Total Revenue: <b-spinner small label="Small Spinner" type="grow" v-if="revenue_loader"></b-spinner> <span class="text-primary" v-if="!revenue_loader">{{ moneyDefaultFormat(totalRevenue) }}</span></h4>
                    </template>

                    <template v-slot:body>
                        <b-row>
                            <b-col lg="12">
                                <DtRevenues ref="dt-reports" :dates="dates" @onFetchData="onFetchData"/>
                            </b-col>
                        </b-row>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import {
    sofbox
} from "@/config/pluginInit";

import AccountsMultiselect from "./children/AccountsMultiselect.vue";
import PlansMultiselect from "./children/PlansMultiselect.vue";
import DtRevenues from "./children/DtRevenues.vue";

import Spinner1 from '@/components/loaders/spinner1'
import TestFlow from '@/components/inboxgeek/sendflow'
import _ from 'lodash'

import {
    mapGetters
} from 'vuex'
import api from '@/api/RestClient'
import Ibgmorris from '../../components/inboxgeek/IbgMorris'
import ApexCharts from "../LiveReports/children/ApexCharts.vue"

import {
    helper
} from '@/helpers'
import store from '@/store/index'
var moment = require('moment');

export default {
    name: "UiDataTable",
    components: {
        DtRevenues,
        Spinner1,
        TestFlow,
        Ibgmorris,
        ApexCharts,
        PlansMultiselect,
        AccountsMultiselect,
    },

    created() {
        this.isAdmin = this.admin;
    },
    mounted() {
        sofbox.index();
        this.initDates();
        this.isManager();
        this.$root.$on('showChart', (item) => {
            this.item = item;
            if (this.isAdmin) this.adminGraph(item);
            else this.graph(item);
        })
        this.$root.$on('updatedTable', (item) => {
            this.submitLoad = false;
        });
    },
    computed: {
        ...mapGetters({
            admin: 'Auth/isAdmin',
            client: 'Auth/isClient',
            isAccountManager: 'Auth/isAccountManager',
            user: 'Auth/user'
        })
    },
    methods: {
        isManager() {
            this.is_manager = helper.hasRoles(['ROLE_ADMIN', 'ROLE_SUPER_ADMIN', 'ROLE_ACCOUNTS_MANAGER']);
        },
        onFetchData(items = []) {
            this.showChart = false;
            this.loadAdminStats = false
            this.totalRevenue = 0;
            if (items && items.length > 0) {
                let sum = this.sumArrayObject(items);
                this.totalRevenue = sum;
            }
            this.revenue_loader = false
        },
        moneyDefaultFormat(amount) {
            return amount.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        },
        sumArrayObjectByKey(arr, key) {
            return arr.reduce((accumulator, object) => {
                return accumulator + object[key];
            }, 0);
        },
        sumArrayObject(items) {
            let total = 0;
            items.forEach(item => {
                let revenue = item['revenue'];
                let debit = item['refund_value'] + item['tax_gst'] + item['tax_qst'];
                total += revenue - debit;
            });

            return total;
        },
        onSelectAccounts(accountSelected) {
            let accounts = [];

            this.showChart = false;
            this.loadAdminStats = false;
            this.revenue_loader = true;

            this.btnAddIsDisabled = false
             if (accountSelected) {
                accounts = _.map(accountSelected, function (k) {
                    return k.code;
                })
            }
            this.$refs['dt-reports'].setAccounts(accounts)

        },
        onSelectPlans(plansSelected) {
            let plans = [];

            this.showChart = false;
            this.loadAdminStats = false;
            this.revenue_loader = true;

            this.btnAddIsDisabled = false
             if (plansSelected) {
                plans = _.map(plansSelected, function (k) {
                    return k.code;
                });
            }
            this.$refs['dt-reports'].setPlans(plans)
        },
        onSubmit() {
            this.submitLoad = true;
            this.revenue_loader  = true;
            this.showChart = false;
            this.loadAdminStats = false;
            let date = {
                from: this.dates.from,
                to: this.dates.to
            }
            this.$refs['dt-reports'].setGroupByProduct(this.isGroupByProduct)
            this.$refs['dt-reports'].setDates(date)
        },
        graph(item) {
            this.showChart = false;
            let options = {
                "integration_id": item.id,
                "from": this.dates.from,
                "to": this.dates.to,
            }
            api.stats.getOpenersStats(options)
                .then(response => {
                    const data = response.data;
                    var responseData = data.chart;
                    const ONE_DAY = 1;
                    var chars = {
                        title: item.tag_name + ' (Events Sent ' + options['from'] + ' to ' + options['to'] + ')',
                        type: 'line',
                        colors: ['#36A2EB'],
                        xKeys: 'period',
                        yKeys: ['a'],
                        labels: ['Events'],
                    }
                    //this.$data.charts = {};
                    let stat = false;
                    let dates = data.dates;
                    if (dates.length == ONE_DAY) {
                        let _dates = dates;
                        this.xLabels = "hour";
                        stat = true;
                        dates = [];
                        for (let i = 0; i < 24; i++) {
                            let date = _dates[0];
                            dates.push(this.getDateAndTime(date, i));

                        }
                    } else {
                        this.xLabels = "day";
                    }
                    chars.bodyData = this.chartData(dates, responseData, stat);
                    this.$root.$emit('chartDone', item);
                    setTimeout(() => {
                        chars.xLabels = this.xLabels;
                        this.charts = chars;
                        this.showChart = true;
                    }, item, this, 1000);

                    setTimeout(() => {
                        item.editable = false;
                        $([document.documentElement, document.body]).animate({
                            scrollTop: $(".charts").offset().top
                        }, 2000)
                    }, item, 1000);
                })
                .catch(err => {
                    console.log(err)
                })
                .finally(() => {
                    this.progress = false;
                })

        },
        adminChartEventBuildSeries(keys = [], labels = [], statistics = []) {

            let series = [];
            let serie = {};
            let data = [];

            for (let index = 0; index < labels.length; index++) {
                serie.name = labels[index];
                if (statistics.length > 0) {
                    for (let i = 0; i < statistics.length; i++) {
                        data.push(statistics[i][keys[index]]);

                    }
                }
                serie.data = data;
                series.push(serie);
                serie = {};
                data = [];
            }

            return series;
        },
        adminGraph(item) {

            this.showChart = false;
            this.loadAdminStats = false;
            this.apexChartTitle = item.name;
            this.params = {
                title: item.name,
                from: this.dates.from,
                to: this.dates.to,
                integration_id: item.id,
                account_id: item.account_id
            }
            api.stats.getOpenersStatsForAdminByDates(this.params)
                .then(response => {
                    let keys = response.data.keys;
                    let labels = response.data.labels;
                    let statistics = response.data.chart;
                    let dates = _.map(statistics, function (o) {
                        return o.date
                    });
                    this.adminEventsChart.categories = dates;
                    this.adminEventsChart.series = this.adminChartEventBuildSeries(keys, labels, statistics);
                })
                .catch(error => {
                    console.log(error);
                }).finally(() => {
                    this.loadAdminStats = true;
                    this.$root.$emit('chartDone', this.item);
                    setTimeout(() => {
                        $([document.documentElement, document.body]).animate({
                            scrollTop: $(".adminCharts").offset().top
                        }, 2000)
                    }, 1000);
                })
        },
        chartData(dates, responseData, ONE_DAY) {
            var bodies = [];
            //let responseDatas = ONE_DAY ? responseData : responseData.data;
            let responseDatas = responseData;
            for (let index = 0; index < dates.length; index++) {
                var date = {
                    a: 0,
                    period: dates[index]
                }
                if (responseDatas.length > 0) {
                    responseDatas.forEach(data => {
                        if (ONE_DAY) {
                            if (data.hour == index) {
                                date.period = dates[index]
                                date.a = data.events
                            }
                        } else {
                            if (data.period == dates[index]) {
                                date.period = data.period
                                date.a = data.a
                            }
                        }
                    });

                }

                bodies.push(date)
            }

            return bodies;
        },
        getDateAndTime(date, i) {
            return date + ' ' + (i.toString().length > 1 ? i.toString() : '0' + i.toString()) + ':00:00';
        },
        initDates() {

            let date = helper.dateToYMD(new Date());
            date = moment(date).format('YYYY-MM');
            this.dates.to = date;
            this.dates.from = date;
            //this.dates.max = date;
        },
    },
    watch: {
        "organizations": {
            handler(newVal, oldVal) { },
            deep: true
        },
    },
    data() {
        return {
            isAdmin: false,
            columns: [],
            rows: [],
            dates: {
                to: null,
                from: null,
            },
            accountId: null,
            submitLoad: false,
            charts: {},
            loadAdminStats: false,
            apexChartTitle: '***',
            item: {},
            adminEventsChart: {
                series: [],
                categories:[]
            },
            totalRevenue: 0,
            isGroupByProduct: false,
            showChart: false,
            revenue_loader:true,
            apexColumnsHide: ['Others', 'Contact Clean', 'Time Limit Reached', 'Daily Limit Reached', 'Contact Already Tagged', 'Events Already Sent', 'Insufficient Balance'],
            is_manager: false
        };
    },
};
</script>

<style>
#myTabContent-5 {
    padding-bottom: 2em !important;
    /* margin: 0 5px; */
}

.actionClass {
    width: 18%;
}

.timeClass,
.createdatClass,
.accountClass {
    width: 10%;
}

textarea.form-control {
    line-height: 25px;
}

.cursor-default {
    cursor: default;
}

.table th,
.table td {
    padding: 0.55rem;
    vertical-align: top;
}

.action {
    width: 100%;
    height: 43.5px;
}

label[for=submit] {
    visibility: hidden;
}
</style>
