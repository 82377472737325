<template>
    <b-form @submit="handleSubmit">
        <b-row class="mb-3">

            <b-form-group class="col-md-12 mb-2 px-1" label="" label-for="discount_name">
                <b-row align-h="between" class="px-3">
                    <label for="discount_status">Name: *</label>
                    <b-form-checkbox switch v-model="form.status" :disabled="form.status == -1">{{
                        form.status ? 'Active' : 'Inactive'
                    }}</b-form-checkbox>
                </b-row>

                <b-form-input id="discount_name" name="discount_name" placeholder="Enter Name" v-model="form.name"
                    required></b-form-input>
                <div class="text-danger" id="error_discount_name">{{ errors.discount_name }}</div>
            </b-form-group>

            <b-form-group class="col-md-12 mt-2 mb-0 px-1" label="" label-for="discount_type">
                <b-row align-h="between" class="px-3">
                    <label for="discount_type">Type: *</label>
                    <b-form-radio-group id="discount_type" name="discount_type" placeholder="Select type"
                        v-model="form.type" :options="['amount', 'percentage']" align-self="end"></b-form-radio-group>
                </b-row>
                <div class="text-danger" id="error_discount_type">{{ errors.discount_type }}</div>
            </b-form-group>

            <b-form-group class="col-md-12 mt-1 mb-2 px-1" label="" label-for="discount_value">
                <b-input-group :prepend="form.type == 'amount' ? '$' : ''"
                    :append="form.type == 'amount' ? '' : `${form.value} %`">
                    <b-form-input :type="form.type == 'amount' ? 'number' : 'range'" id="discount_value"
                        name="discount_value" placeholder="Enter value" v-model="form.value" min="0"
                        :max="form.type == 'amount' ? '' : 100" :step="form.type == 'amount' ? 0.01 : 1" required>
                    </b-form-input>
                </b-input-group>
                <div class="text-danger" id="error_discount_value">{{ errors.discount_value }}</div>
            </b-form-group>

            <b-form-group class="col-md-12 my-2 px-1" label="Discount Code:" label-for="discount_code">
                <b-input-group class="col-md-12 my-1 px-1" label="Discount Code:" label-for="discount_code">
                    <b-form-input id="discount_code" name="discount_code" placeholder="Enter Code"
                        v-model="form.discount_code" required readonly></b-form-input>

                    <template #append>
                        <b-button variant="primary" type="button" @click="generateFormDiscountCode(form)"
                            :disabled="onsubmit">
                            Generate
                        </b-button>
                    </template>
                </b-input-group>

                <div class="text-danger" id="error_discount_code">{{ errors.discount_code }}</div>
            </b-form-group>


            <b-form-group class="col-md-12 my-2 px-1" label="" label-for="discount_usage_limit">

                <b-row align-h="between" class="px-3">
                    <label for="discount_limit_check">Number of use:</label>
                    <b-form-checkbox switch v-model="form.unlimited">
                        {{ form.unlimited ? 'Unlimited' : 'Limited' }}
                    </b-form-checkbox>
                </b-row>

                <b-form-input type="number" 
                    id="discount_usage_limit" 
                    name="discount_usage_limit"
                    v-model="form.usage_limit" 
                    :placeholder="form.unlimited ? 'Unlimited' : 'Usage limit'"
                    ref="input-limit" 
                    :readonly="form.unlimited"></b-form-input>

                <div class="text-danger" id="error_discount_usage_limit">{{ errors.discount_usage_limit }}</div>
            </b-form-group>

            <b-form-group class="col-md-6 py-0 my-2 px-1" label="Start at:" label-for="discount_start_date">
                <b-form-input id="discount_start_date" class="mb-0 px-1" type="date" v-model="form.start_date"
                    :max="form.end_date" :value="form.start_date"></b-form-input>
                <div class="text-danger" id="error_discount_start_date">{{ errors.discount_start_date }}</div>
            </b-form-group>


            <b-form-group class="col-md-6 py-0 my-2 px-1" label="End at:" label-for="discount_end_date" description="">
                <b-form-input id="discount_end_date" class="px-1" type="date" v-model="form.end_date"
                    :min="form.start_date" :value="form.end_date"></b-form-input>
                <div class="text-danger" id="error_discount_end_date">{{ errors.discount_end_date }}</div>
            </b-form-group>
        </b-row>

        <b-row class="">
            <b-button ref="btn-submit" variant="primary" type="submit" :disabled="onsubmit">
                <b-spinner small type="grow" v-show="onsubmit"></b-spinner>
                {{ submitLabel }}
            </b-button>
        </b-row>

    </b-form>
</template>

<script>
import { helper } from '@/helpers'
import api from '@/api/RestClient'
import _ from 'lodash'
import Vue from 'vue'
import moment from 'moment'


export default {
    name: 'FormDiscount',
    props: {
        item: {
            type: [Object],
            default: null
        }
    },
    components: {

    },
    mounted() {
        this.setForm()
        this.setSubmitLabel()
    },
    data() {
        return {
            submitLabel: '',
            onsubmit: false,
            form: {
                code: null,
                name: '',
                discount_code: null,
                type: 'percentage',
                value: 0,
                status: true,
                unlimited: false,
                usage_limit: 1,
                start_date: null,
                end_date: null,
            },
            formInit: {},
            errors: {},
            codePrefix: 'IGDIS',
            codeLength: 8,
        }
    },
    methods: {
        setForm() {
            let form = this.getForm()
            if (form) {
                this.form = form
            }
            this.formInit = Vue.util.extend({}, this.form);
        },
        getForm() {
            if (!this.item || typeof this.item == "undefined") {
                // this.form.discount_code = this.generateDiscountCode(this.codeLength)
                this.form = this.generateFormDiscountCode(this.form)

                return null
            }

            let form = {
                code: this.item.id,
                name: this.item.name,
                type: this.item.type,
                value: this.item.value,
                discount_code: this.item.discount_code,
                status: this.item.status == 1 ? true : false,
                unlimited: this.item.usage_limit == -1 ? true : false,
                usage_limit: this.item.usage_limit == -1 ? null : this.item.usage_limit,
                start_date: this.item.start_date ? (moment(this.item.start_date)).format('YYYY-MM-DD') : null,
                end_date: this.item.end_date ? (moment(this.item.end_date)).format('YYYY-MM-DD') : null,
            }

            if (!form.discount_code || form.discount_code == '') {
                form = this.generateFormDiscountCode(form)
            }

            return form
        },
        generateFormDiscountCode(form) {
            form.discount_code = this.generateDiscountCode(this.codeLength)
            return form
        },
        setSubmitLabel() {
            this.submitLabel = 'Add New Discount'
            if (this.item && (typeof this?.item !== 'undefined') && this.item?.id) {
                this.submitLabel = `Update Discount`
            }
        },
        emitHandleSubmit(data, isNew = true) {
            this.$emit('handleSubmit', { ...data, new: true })
        },
        addNewItem() {
            let isValidForm = helper.isEmpty(this.errors)
            let data = _.omit(this.form, ['code'])
            const that = this

            if (!isValidForm) {
                this.onsubmit = false;
                return;
            }

            if (this.form.unlimited) {
                data.usage_limit = -1
            }

            api.discounts.post(data)
                .then(data => {
                    if ('success' in data) {
                        this.emitHandleSubmit(data.data, true)
                    } else {
                        that.handleError(data)
                    }
                })
                .catch(errors => {
                    that.handleError(errors)
                })
                .finally(() => {
                    this.onsubmit = false;
                })
        },
        updateItem() {
            let isValidForm = helper.isEmpty(this.errors)
            const id = this.form.code
            let data = this.form
            const that = this

            if (!isValidForm) {
                this.onsubmit = false;
                return;
            }

            if (this.form.unlimited) {
                data.usage_limit = -1
            }

            api.discounts.put(id, data)
                .then(data => {
                    if ('success' in data) {
                        this.emitHandleSubmit(data.data, false)
                    } else {
                        that.handleError(data)
                    }
                })
                .catch(errors => {
                    that.handleError(errors)
                })
                .finally(() => {
                    this.onsubmit = false;
                })
        },
        resetErrors() {
            const discount_code_error = this.errors.discount_code
            this.errors = {}
            if (discount_code_error) {
                this.errors.discount_code = discount_code_error
            }
        },
        checkForm() {
            this.resetErrors();

            if (!this.form.name.trim()) {
                this.errors.discount_name = 'Name is required.';
            }
            if (!this.form.type) {
                this.errors.discount_type = 'Type is required.';
            }
            if (this.form.type == 'percentage' && parseInt(this.form.value) > 100) {
                this.errors.discount_type = 'Value max for type selected is [100 %].';
            }
            if (!this.form.value) {
                this.errors.discount_value = 'Value is required.';
            }
            if (isNaN(this.form.value) || parseInt(this.form.value) <= 0) {
                this.errors.discount_value = 'Value must be number value.';
            }
        },
        formatErrorResponse(errors) {
            if (errors.response) {
                errors = errors.response.data.errors
            } else if (errors.request) {
                errors = errors.request
            } else {
                errors = errors.message;
            }
            return errors
        },
        handleSubmit(e) {
            e.preventDefault()

            this.onsubmit = true;
            this.checkForm();

            if (helper.isEmpty(this.errors)) {
                if (this.form.code) {
                    this.updateItem()
                } else {
                    this.addNewItem()
                }
            } else {
                // Can emit error
                this.onsubmit = false;
            }
        },
        handleError(errors) {
            this.resetErrors()
            const that = this

            errors = this.formatErrorResponse(errors)
            // Make loop
            _.forOwn(errors, function (value, key) {
                that.errors[`discount_${key}`] = errors[key][0]
            });
        },
        generateDiscountCode(length) {
            let result = '';
            const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            const charactersLength = characters.length;
            let time = (new Date().getTime()).toString()
            let counter = 0;
            let codeCore = '';
            while (counter < length / 2) {
                codeCore += characters.charAt(Math.floor(Math.random() * charactersLength));
                counter += 1;
            }

            result += `${this.codePrefix}${time[0]}${time[2]}${codeCore}${time[time.length - 2]}${time[time.length - 1]}`
            return result;
        },
        checkDiscountCode() {
            this.onsubmit = true;
            this.errors.discount_code = null

            const id = this.form.code ?? null
            const value = this.form.discount_code ?? null

            api.discounts.checkCodeAvailable(id, value)
                .catch(errors => {
                    this.errors.discount_code = 'Discount code is already taken'
                })
                .finally(() => {
                    this.onsubmit = false;
                })
        }
    },
    computed: {
        isSameInit() {
            return _.isEqual(this.form, this.formInit);
        }
    },
    watch: {
        "form": {
            handler(newVal, oldVal) {
                if (!this.isSameInit) {
                    this.$refs['btn-submit'].disabled = false
                } else {
                    this.$refs['btn-submit'].disabled = true
                }
                if (newVal.unlimited) {
                    this.form.usage_limit = null
                } else if (!this.form.usage_limit) {
                    this.form.usage_limit = this?.item?.usage_limit ?? 1
                } else if (!newVal.unlimited && this.form.usage_limit == -1) {
                    this.form.usage_limit = 1
                }

            },
            deep: true
        },
        "form.discount_code": {
            handler(newValue, oldValue) {
                this.checkDiscountCode();
            }
        }
    },
}
</script>

<style lang="scss" scoped>
#discount_type {
    div.custom-control {
        padding-right: 20px !important
    }
}

label.d-block {
    margin-right: 0 !important
}
</style>></style>