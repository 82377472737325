<template>
    <b-container fluid>

        <b-col sm="12">
            <iq-card class="p-2">

                <template v-slot:headerTitle>
                    <h4 class="card-title">Platforms</h4>

                </template>
                <template v-slot:headerAction>
                    <b-form v-if="isAdmin || isAccountManager" class="mx-3 my-2">
                        <AccountsMultiselect @onSelect="SelectAccount" />
                    </b-form>
                    <b-button v-b-modal.add-platform-modal variant="primary" class="text-right_ mr-1"
                        :disabled="!AllowToCreatePlatform"><i class="fa fa-plus"></i> Add New Platform</b-button>
                </template>
                <template v-slot:body>



                    <b-input v-model="search" placeholder="search" class="mt-0 mb-4"></b-input>



                    <b-row v-if="loading.platforms == true">

                        <div class="text-center p-4 d-flex justify-content-center align-items-center w-100">
                            <b-spinner variant="primary"> </b-spinner>
                            <span class="text-primary p-2"><small> Fetching platforms, please wait...</small></span>

                        </div>
                    </b-row>
                    <b-row v-else>

                        <b-col v-if="isAdmin && !account.accountid" class="w-100 m-4 text-center">No Account is selected ,
                            please select an account to fetch platforms</b-col>

                        <b-col v-else-if="filtered_platforms.length == 0" class="w-100 m-4 text-center">Oh It seems You
                            don't have any connected platform yet, create one by clicking on create new platform
                            button</b-col>

                        <b-col v-for="platform in filtered_platforms" :key="platform.id" cols="4">
                            <div class="bg-white rounded my-2 p-4 ig-p-card"
                                :class="{ 'inactive-platform': (isConnected(platform) == false) }">

                                <NetWorkState :platform="platform"></NetWorkState>

                                <span class="action-icon action-info" v-b-modal.update-platform-details
                                    @click="editPlatform(platform)"><i class="ri-pencil-line"></i></span>
                                <span class="action-icon action-danger" @click="deletePlatform(platform)"
                                    style="top:80px"><i class="ri-delete-bin-7-line"></i></span>

                                <b-row class="row mt-3" align-v="center">
                                    <b-col align-self="center" class="text-center" cols="3" style="height:7rem;">
                                        <img style="width: 70%;margin-top: 1rem;" :src="getImageURL(platform.name)">
                                    </b-col>
                                    <b-col cols="8">
                                        <h4>{{ platform.nickname }}</h4>
                                        <h6 :class="[isConnected(platform) ? 'text-primary' : 'secondary']"> {{
                                            platformName(platform.name) }}</h6>
                                        <small>{{ platform.key }}</small><br>
                                        <small>{{ platform.account }}</small>
                                    </b-col>
                                </b-row>

                            </div>
                        </b-col>
                    </b-row>



                </template>

            </iq-card>

        </b-col>

        <b-modal v-if="showModal" id="update-platform-details" ref="updatePlatformsModal" hide-footer
            title="Update your platform details" @hidden="hideModalEdit">

            <b-form @submit="onUpdate" class="p-4 pb-0">

                <b-form-group id="input-platform" label-for="m-platform" label="Platform" description="">
                    <Multiselect :options="platformsSorted" :customLabel="() => { }" v-model="form.name" :searchable="true"
                        :disabled="true">

                        <template slot="option" slot-scope="props">

                            {{ platformName(props.option) }}

                        </template>

                        <template slot="singleLabel" slot-scope="props">

                            {{ platformName(props.option) }}
                        </template>

                    </Multiselect>


                </b-form-group>

                <b-form-group id="input-nickname" label-for="m-nickname" label="Nickname" description="">
                    <b-form-input id="m-nickname" v-model="form.nickname" placeholder="Nickname"></b-form-input>
                </b-form-group>

                <b-form-group id="input-account" label-for="m-account" label="Account" description=""
                    v-if="form.account && form.account != ''">
                    <b-form-input id="m-account" v-model="form.account" placeholder="" :disabled="true"></b-form-input>
                </b-form-group>

                <b-form-group id="input-api-key" label-for="m-api-key" label="Api Key" description="">
                    <b-input-group>
                        <b-form-input id="m-api-key" v-model="form.key" placeholder="Api key" :disabled="!showApiKey || disableApiKey"></b-form-input>
                        <template #append>
                            <b-input-group-text class="cursor-pointer p-0 m-0">
                                <b-button variant="light" class="h-100" @click.prevent="toggleShowApiKey">
                                    <i v-if="showApiKey" class="ri-eye-line"></i>
                                    <i v-else class="ri-eye-off-line"></i>
                                </b-button>

                            </b-input-group-text>
                        </template>
                    </b-input-group>
                </b-form-group>

                <hr>

                <b-row class="text-right px-3 mb-0 pb-0" style="justify-content:end;">

                    <b-button 
                        v-if="isOAuthPlatform"
                        id="oauthReconnect" 
                        name="oauthReconnect" 
                        variant="info" 
                        ref="oauthReconnect" 
                        :disabled="!isAllowedReconnection" 
                        class="auth2 action-button float-right mr-2"
                        :data-action="oauth.state"
                        @click="handleOauthReconnection($event)">
                        <b-spinner small type="grow" v-if="oauth.inProgress"></b-spinner>
                        Reconnect {{ currEditPlatform.name }} Platform
                    </b-button>

                    <b-button variant="primary" type="submit" class="text-right"> Update <b-spinner small type="grow"
                            v-show="loading.modal"></b-spinner> </b-button>
                    
                </b-row>

            </b-form>
        </b-modal>

        <b-modal id="add-platform-modal" ref="createPlatformModal" hide-footer title="Create new platform"
            style="padding-bottom: 10px;">

            <createNewPlatform :account="account" :oPlatforms="platforms" @platformCreated="platformCreated"
                @cancelCreated="$refs['createPlatformModal'].hide()"></createNewPlatform>


        </b-modal>


    </b-container>
</template>

<script>
import {
    sofbox
} from '../../config/pluginInit'
import Spinner1 from '../../components/loaders/spinner1'
import Multiselect from 'vue-multiselect'
import api from '@/api/RestClient';
import createNewPlatform from '@/components/inboxgeek/createNewPlatform';
import AccountsMultiselect from "@/views/children/AccountsMultiselect.vue";
import store from '@/store/index'
import { PLATFORMS_CODE, COLUMNS_GRID, PLATFORMS_ITEMS } from "@/constantes";
import NetWorkState from "./children/NetWorkState.vue";
import { mapGetters } from 'vuex'

import {
    helper
} from '@/helpers';
import { services } from '@/helpers';

export default {
    name: 'platformList',
    components: {
        Spinner1,
        Multiselect,
        createNewPlatform,
        AccountsMultiselect,
        NetWorkState
    },
    async mounted() {

        sofbox.index()
        if (!this.isAdmin || !this.isAccountManager) {
            this.fetchExistingPlatforms();
        }
    },
    data() {
        return {
            loading: {
                modal: false,
                platforms: false,
            },
            showApiKey: false,
            disableApiKey: false,
            platforms: [],
            loader: false,
            headerTitle: 'Platforms',
            filter: 7,

            form: {
                id: null,
                key: null,
                name: null,
                account: null,
                nickname: null,
            },
            platformEdit: null,

            // Table data :
            fields: [{
                key: 'id',
                label: '#',
                sortable: true,
                sortDirection: 'desc'
            },
            {
                key: 'service',
                label: 'Service',
                sortable: true,
                class: 'text-center'
            },
            {
                key: 'name',
                label: 'Name',
                sortable: true,
                class: 'text-left'
            },
            {
                key: 'parent_plan',
                label: 'Parent Plan',
                sortable: true,
                class: 'text-center'
            },
            {
                key: 'interval',
                label: 'Interval',
                sortable: true,
                class: 'text-center'
            },
            {
                key: 'balance',
                label: 'Balance',
                sortable: true,
                class: 'text-center'
            },
            {
                key: 'price',
                label: 'Price',
                sortable: true,
                class: 'text-center'
            },
            {
                key: 'unit_price',
                label: 'Price/Unit',
                sortable: true,
                class: 'text-center'
            },
            {
                key: 'status',
                label: 'Status',
                sortable: true,
                class: 'text-center'
            },
            {
                key: 'actions',
                label: 'Actions',
                class: 'text-center'
            }
            ],

            pageOptions: [10, 15, {
                value: 100,
                text: "Show a lot"
            }],
            platformNames: [
                'Brevo',
                'Klaviyo',
                'ExpertSender',
                'Blastable',
                'Ontraport',
                'Sendlane',
                'Mailchimp',
                'HubSpot',
                'Campaigner',
                'ConvertKit',
                'AWeber',
                'INBOX',
                'Keap',
                'Sendgrid',
                'GoHighLevel',
                'Inbox Suite'

            ],
            platformWithAccountColumn: [
                'ExpertSender',
                'INBOX',
                'ConvertKit',
                'SendinBlue',

            ],
            account: {
                accountid: null
            },
            search: '',
            showModal: false,
            currEditPlatform: null,
            currPlatformApiKey: null,

            // Reconnection btn methods
            oauth: {
                // listAccounts: [],
                // listAccounts: [],
                // platforms: [],
                state: '',
                inProgress: false,
            },
        }

    },
    methods: {
        
        SelectAccount(account) {

            if (!account) {

                this.account.accountid = null;
                this.platforms = null;

                return;
            }

            this.account.accountid = account.code;
            this.fetchExistingPlatforms(account.code);


        },

        onUpdate(e) {

            e.preventDefault();

            let form = this.form;

            if (!form.account) {
                delete form.account;
            }

            if(!this.showApiKey || this.disableApiKey || form.key.includes('***')) {
                delete form.key;
            }

            if(form.name == 'Inbox Suite') { form.name = 'Inboxsuite'; }
            console.log('data to send', form, this.showApiKey, this.disableApiKey)

            api.platforms.update(this.form.id, form)
                .then((response) => {

                    if (response.success) {

                        this.$swal({
                            title: 'Platform Updated',
                            text: response.message,
                            icon: 'success',
                            type: 'success',
                            confirmButtonText: 'Ok',
                            showCloseButton: true,
                        }).then((result) => {
                            if (!this.isAdmin || !this.isAccountManager) {
                                this.fetchExistingPlatforms();
                            } else {
                                this.fetchExistingPlatforms(this.account.accountid);
                            }

                            this.showModal = false;
                            // this.$refs['updatePlatformsModal'].$forceUpdate();
                            // this.$refs['updatePlatformsModal'].hide();
                        })

                    }

                })
                .catch((error)=>{
                    this.$swal('Invalid Credentials', error.response.data.message, 'error')
                })

        },
        platformCreated(platform) {
            this.fetchExistingPlatforms(this.account.accountid);
            this.$refs['createPlatformModal'].hide();
            this.$forceUpdate();

        },
        editPlatform(platform) {

            this.form.name = this.platformName(platform.name);
            this.form.id = platform.id;
            this.form.account = platform.account ?? null;
            this.form.key = platform.key;
            this.form.nickname = platform.nickname;
            this.platformEdit = platform;
            this.showModal = true;

            this.fetchPlatform(this.form.id);

        },
        fetchPlatform(platformID) {
            if (!platformID) return;

            api.platforms.fetch(platformID)
                .then((response) => {

                    if (response.success) {
                        this.currEditPlatform = response.data
                        this.currPlatformApiKey = this.isJSON(response.data.key) ? this.getPlatformApiAccess(response.data.key) : response.data.key
                    }

                })
                .finally(() => {
                    this.disableApiKey = this.currEditPlatform && this.isJSON(this.currEditPlatform.key);
                });
        },
        isJSON(text) {
            if (typeof text !== "string") {
                return false;
            }
            try {
                JSON.parse(text);
                return true;
            } catch (error) {
                return false;
            }
        },
        getPlatformApiAccess(ApiKey) {
            let key = JSON.parse(ApiKey);

            return typeof key.accessToken != 'undefined' ? key.accessToken : key.access_token;
        },
        hideModalEdit() {
            this.showApiKey = false;
            this.currEditPlatform = null;
            this.currPlatformApiKey = null;
        },
        fetchExistingPlatforms(account) {

            this.loading.platforms = true;


            return api.platforms.index(account).then((response) => {


                this.platforms = response.data;
                this.loading.platforms = false;

            }).catch((error) => {


                this.loading.platforms = false;

            });

        },
        onFiltered(filteredItems) {

            this.totalRows = filteredItems.length
            this.currentPage = 1

        },
        toast(title = "success", variant = "success", message = "Organization updated successfully") {

            this.$bvToast.toast(message, {
                title: title,
                variant: variant
            });

        },
        onServiceSelected() {

            this.form.service_id = this.service.code;
        },
        getImageURL(platform) {

            try {

                return require(`@/assets/images/platforms/${platform.toLowerCase()}.png`);

            } catch (e) {

                return require(`@/assets/images/platforms/default.png`);

            }
        },
        deletePlatform(platform) {

            this.$swal({
                title: 'Are you sure?',
                text: 'If there are any active integrations associated with this platform, those integrations will be turned off.',
                type: 'info',
                showCancelButton: true,
                confirmButtonText: 'Yes Delete it!',
                cancelButtonText: 'No, Keep it!',
                showCloseButton: true,
                showLoaderOnConfirm: true
            })
                .then((result) => {

                    if (result && result.isConfirmed) {


                        api.platforms.delete(platform.id).then((response) => {

                            if (this.account?.accountid) {
                                this.fetchExistingPlatforms(this.account.accountid);

                            } else {
                                this.fetchExistingPlatforms();
                            }
                        });


                    }
                })

        },
        isConnected(platform) {
            return (platform.validation_code != 113 || platform.validation_code == null)
        },
        platformName(name) {

            return helper.platformFormatName(name);
        },
        toggleShowApiKey() {
            this.showApiKey = !this.showApiKey

            if(this.showApiKey) {
                this.form.key = this.currPlatformApiKey;
            } else {
                this.form.key = this.platformEdit.key;
            }
        },

        // Reconnection btn methods
        handleOauthReconnection() {
            console.log('handleOauthReconnection', this.currEditPlatform);

            if (this.isOAuthPlatform) {
                this.oauth.inProgress = true;

                let oauthServiceApi = null;
                let platform_id = this.currEditPlatform.id;
                let platformName = this.currEditPlatform.name.toLowerCase();
                var swalObj = {
                    text: 'You will be redirected to the authentication page. Click "Ok" to continue',
                    type: 'info',
                    showCancelButton: true,
                    confirmButtonText: 'Ok',
                    cancelButtonText: 'Cancel!',
                    showCloseButton: true,
                    showLoaderOnConfirm: true
                };

                this.oauth.state = '';

                localStorage.removeItem('oauth.refresh');
                localStorage.removeItem('oauth.params');
                localStorage.removeItem('oauth.install');

                swalObj.title = `InboxGeek ${platformName} Integration`

                oauthServiceApi = api.default
                    .get(`/platforms/${platformName}/oauth/authorize${(platform_id ? '?platform_id=' + platform_id : '')}`)
                    .then(response => response.data);



                oauthServiceApi.then((result) => {


                    this.auth_url = result.url;
                    this.auth_params = result.params;

                    localStorage.setItem('oauth.install', this.auth_params);

                })
                    .catch((error) => {

                        this.$swal({
                            title: swalObj.title,
                            text: 'An error has occurred. Please try again',
                            type: 'warning',
                            confirmButtonText: 'Ok'
                        })

                        this.oauth.inProgress = false;
                    })
                    .finally(() => {

                        this.$swal(swalObj).then((result) => {

                            if (result.value) {
                                this.showWindowOauth(result)
                            } else {
                                this.oauth.inProgress = false;
                            }

                        });
                    })
            }

        },
        showWindowOauth(result) {

            if (this.isOAuthPlatform) {
                if (!result.value) {

                    this.$bvToast.toast('Your Integration process has been cancel', {
                        title: 'Warning',
                        variant: 'warning'
                    });

                    this.oauth.inProgress = false;

                }

                var oauthRefresh, _params = null;

                var w = 800,
                    h = 600;

                // Fixes dual-screen position                             Most browsers      Firefox
                const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
                const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

                const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
                const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

                const systemZoom = width / window.screen.availWidth;
                const left = (width - w) / 2 / systemZoom + dualScreenLeft
                const top = (height - h) / 2 / systemZoom + dualScreenTop

                let platform_id = this.currEditPlatform.id;
                let platformName = this.currEditPlatform.name.toLowerCase();
                let params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=${w / systemZoom},height=${h / systemZoom},left=${left},top=${top}`;

                var _oauthWindow = window.open(this.auth_url, 'oauth', params);

                var intervalID = setInterval(() => {

                    oauthRefresh = localStorage.getItem('oauth.refresh');
                    _params = localStorage.getItem('oauth.params') ? JSON.parse(localStorage.getItem('oauth.params')) : {};
                    _params.account = this.account


                    if (oauthRefresh) {

                        api.default.post(`/platforms/${platformName}/oauth/access_token`, _params)
                            .then((result) => {

                                // TODO Update Visual Item ApiKey  
                                if(result.data && typeof result.data.token != 'undefined') {
                                    
                                    console.log(result, this.currEditPlatform)
                                    this.currEditPlatform.key = JSON.stringify(result.data.token);
                                    this.currPlatformApiKey = this.isJSON(this.currEditPlatform.key) ? this.getPlatformApiAccess(this.currEditPlatform.key) : response.data.key

                                //     console.log( this.currEditPlatform, response.data )
                                    this.$swal({
                                        title: `InboxGeek ${this.currEditPlatform.name} Integration`,
                                        text: 'The platform has been successfully connected',
                                        type: 'success',
                                        confirmButtonText: 'Ok'
                                    })
                                }

                                this.oauth.inProgress = false;
                            })
                            .catch((error) => {
                                this.$swal({
                                    title: `InboxGeek ${this.currEditPlatform.name} Integration`,
                                    text: 'An error has occurred. Please try again',
                                    type: 'warning',
                                    confirmButtonText: 'Ok'
                                })

                                this.oauth.inProgress = false;
                            });

                        clearInterval(intervalID)
                    }

                    else if (_oauthWindow.closed && !oauthRefresh) {
                        this.$bvToast.toast('Please authenticate to complete your integration', {
                            title: 'Warning', variant: 'warning'
                        });

                        this.oauth.inProgress = false;

                        clearInterval(intervalID)
                    }

                    if (_oauthWindow.closed || !_oauthWindow) {
                        this.oauth.btnAuthorize = false
                    }
                }, 2000);
            }

           
        },
    },
    computed: {
        ...mapGetters({
            isAdmin: 'Auth/isAdmin',
            isClient: 'Auth/isClient',
            isAccountManager: 'Auth/isAccountManager',
        }),
        filtered_platforms() {

          if(this.isAdmin && !this.account.accountid) return [];

            if (this.search != '') {

                return this.platforms.filter((platform) => {

                    if (
                        platform.name.toLowerCase().includes(this.search.toLowerCase()) ||
                        platform.nickname?.toLowerCase().includes(this.search.toLowerCase())  ||
                        platform.account?.toLowerCase().includes(this.search.toLowerCase())
                    ) {
                        return platform;
                    }
                })
            }
            return this.platforms;
        },
        AllowToCreatePlatform() {
            if (this.isAdmin || this.isAccountManager) {
                return (this.account?.accountid != null);
            }
            return true;
        },
        platformsSorted() {
            return _.sortBy(PLATFORMS_ITEMS, 'name').map((item) => {


                return item.name;
            });
        },

        // Reconnection btn computed
        isOAuthPlatform() {
            let state = this.currEditPlatform 
                && this.currEditPlatform.name != null 
                && this.currEditPlatform.name.trim() != '' 
                && ['aweber', 'hubspot', 'keap', 'mailchimp'].includes(this.currEditPlatform.name.toLowerCase());
                console.log( 'isOAuthPlatform', this.currEditPlatform, state );
            return state;
        },
        isAllowedReconnection(){
            let state = this.isOAuthPlatform
                && !this.oauth.inProgress;

            console.log( 'isAllowedReconnection:this.currEditPlatform', this.currEditPlatform, state );

            return state;
            // return !this.oauth.inProgress ; 
        }
    }
}
</script>

<style>
.ig-p-card:hover {
    box-shadow: 0 0 14px #f6386b51;
    transition: box-shadow .3s ease-in-out;
}

.ig-p-card {
    box-shadow: 0 0.2rem 0.5rem rgb(227 231 239);
    transition: box-shadow .8s ease-in-out;
}

.inactive-platform {
    background-color: #f4f7fd !important;
    box-shadow: inset 0 0 20px #dde2ed !important;
}

.active-spot {
    background: #1add47;
}

.inactive-platform img {
    filter: grayscale(100%);
    opacity: 0.5;
}

.inactive-spot {
    background: #575757;
}

.spot-light {

    width: 15px;
    height: 14px;
    border-radius: 40px;
    position: absolute;
    left: 30px;
    top: 25px;

}

.text-status {
    position: absolute;
    left: 50px;
    top: 20px;
}

.action-info {
    color: #5ad;
}

.action-info:hover {
    color: #5ad;
    background-color: #00c9ff3b !important;
}

.action-danger {
    color: #ff0f0f;
    z-index: 50;
}

.action-danger:hover {
    color: #ff0f0f;
    background-color: #ff000021;
}

.action-icon {
    cursor: pointer;
    font-size: 20px;
    position: absolute;
    right: 40px;
    top: 30px;
    border-radius: 25px;
    padding: 0px 10px;
}
</style>
