<template>
    <div>
        <b-button variant="primary" class="mb-2">
            Total Paid
            <b-badge variant="light">${{ total }}</b-badge>
        </b-button>
        <b-form @submit="onSubmit" v-if="show">
            <b-form-group id="input-group-1" label="" label-for="input-1"
                description="Please specify the refund amount">
                <b-form-input :disabled="checked" id="input-1" v-model="form.total" type="number" step="0.01" placeholder="Enter amount"
                    min="0" :max="max_can_pay" required></b-form-input>
            </b-form-group>
            <b-form-group
              label="Note"
              label-for="textarea-refund"
              description="Add a note that explains the reason for this action"
              class="mb-0"
            >
              <b-form-textarea
                id="textarea-refund"
                v-model="form.note"
                placeholder="Enter your note"
                required></b-form-textarea>
            </b-form-group>
            <b-form-group
                  label="File"
                  class="mb-0"
            >
            <b-form-file v-model="form.file" ref="file-input" class="mb-2"  accept="application/pdf"></b-form-file>
             </b-form-group>
             <div class="commission_actions">
                <b-button type="submit" variant="primary" class="mt-3">
                    Submit
                    <b-spinner small label="Small Spinner" type="grow" v-if="onSubmitSpinner"></b-spinner>
                </b-button>
             </div>

        </b-form>
    </div>
</template>

<script>
import api from '@/api/RestClient'
export default {
    name: 'Commission',
    props: ['referral'],
    mounted() {
        this.total = parseFloat(this.calculateSum(this.referral.commissions, 'total')).toFixed(2);
        this.form.referral_id = this.referral.id;
    },
    data() {
        return {
            form: {
                total: '',
                note: '',
                file: '',
                referral_id: null
            },
            checked: false,
            show: true,
            total: 0,
            max_can_pay: '',
            onSubmitSpinner: false,
            downloadSpinner: false,
            can_download_zip: false
        }
    },
    methods: {
        calculateSum(array, property) {
            const total = array.reduce((accumulator, object) => {
                return accumulator + object[property];
            }, 0);

            return total;
        },
        onSubmit(event) {
            event.preventDefault()

            const formData = new FormData()
            formData.append('total', this.form.total)
            formData.append('note', this.form.note)
            formData.append('file', this.form.file)
            formData.append('referral_id', this.form.referral_id)

            let resp = {
                status: 1,
                message: ''
            };
            this.onSubmitSpinner = true;
            api.commissions.post(formData).then(response => {
                resp.message = response.message
                this.$emit('onSaved', resp.status);
            }).catch(err => {
                resp.status = 0;
                response.message = 'An error occurred'
                this.$emit('onSaved', resp.status);
            }).finally(() => {
                this.onSubmitSpinner = false
            })
        },
        toast(title = "success", variant = "success", message = "Organization updated successfully") {
            this.$bvToast.toast(message, {
                title: title,
                variant: variant
            })
        },
    }
}
</script>
<style>
    .commission_actions {
        display: flex;
        justify-content: space-between;
    }
    .cursor-pointer {
        cursor: "pointer";
    }
</style>