import store from "../../store";

export default function (axios) {

  return {

    create: (data, progress) => {

      if (process.env.NODE_ENV === 'production') {
        let axiosInstance = axios.create({
          baseURL: 'https://services.inboxgeek.com/api',
          timeout: 120000
        });


        axiosInstance.interceptors.request.use((config) => {
          if (config.noAuth) {
            return config;
          };
          const token = store.getters['Auth/tokenAs'] ?? store.getters['Auth/token'];
          config.headers.Authorization = "Bearer " + token;
          return config
        });

        return axiosInstance.post(`/suppression-lists`, data, progress) .then(response => response.data);

      }
      return axios.post(`/suppression-lists`, data, progress) .then(response => response.data);

    },
    update: (id, data, progress) => {

      if (process.env.NODE_ENV === 'production') {
        let axiosInstance = axios.create({
          baseURL: process.env.VUE_SERVICE_BASE_URL_API,
          timeout: 120000
        });


        axiosInstance.interceptors.request.use((config) => {
          if (config.noAuth) {
            return config;
          }

          const token = store.getters['Auth/tokenAs'] ?? store.getters['Auth/token'];
          config.headers.Authorization = "Bearer " + token;
          return config
        });

        return axiosInstance.put(`/suppression-lists/${id}`, data, progress)

      }
      return axios.put(`/suppression-lists/${id}`, data, progress)

    },
    index: (account_id, data, progress) => {

      if (process.env.NODE_ENV === 'production') {
        let axiosInstance = axios.create({
          baseURL: process.env.VUE_SERVICE_BASE_URL_API,
          timeout: 120000
        });

        axiosInstance.interceptors.request.use((config) => {
          if (config.noAuth) {
            return config;
          }

          const token = store.getters['Auth/tokenAs'] ?? store.getters['Auth/token'];
          config.headers.Authorization = "Bearer " + token;
          return config
        });

        return axiosInstance.get(`/suppression-lists`,{params :{account_id:account_id}}) .then(response => response.data);

      }
      return axios.get(`/suppression-lists`,{params :{account_id:account_id}}) .then(response => response.data);

    },
    delete:(list_id)=>{
      return axios.delete(`/suppression-lists/${list_id}`) .then(response => response.data);
    },
    apply: (data,progress) => {
      if (process.env.NODE_ENV === 'production') {
        let axiosInstance = axios.create({
          baseURL: process.env.VUE_SERVICE_BASE_URL_API,
          timeout: 120000
        });

        axiosInstance.interceptors.request.use((config) => {
          if (config.noAuth) {
            return config;
          }

          const token = store.getters['Auth/tokenAs'] ?? store.getters['Auth/token'];
          config.headers.Authorization = "Bearer " + token;
          return config
        });

        axiosInstance.interceptors.request.use((config) => {
          if (config.noAuth) {
            return config;
          };
          const token = store.getters['Auth/tokenAs'] ?? store.getters['Auth/token'];
          config.headers.Authorization = "Bearer " + token;
          return config
        });

        return axiosInstance.post(`/suppression-lists/apply`, data, progress)

      }
      return axios.post(`/suppression-lists/apply`, data, progress)
    },
    detach: (data,progress) => {
      if (process.env.NODE_ENV === 'production') {
        let axiosInstance = axios.create({
          baseURL: process.env.VUE_SERVICE_BASE_URL_API,
          timeout: 120000
        });


        axiosInstance.interceptors.request.use((config) => {
          if (config.noAuth) {
            return config;
          }

          const token = store.getters['Auth/tokenAs'] ?? store.getters['Auth/token'];
          config.headers.Authorization = "Bearer " + token;
          return config
        });

        return axiosInstance.post(`/suppression-lists/detach`, data, progress)

      }
      return axios.post(`/suppression-lists/detach`, data, progress)
    },
    postPlatform:(data,progress) => {

      return axios.post(`/suppression-lists/platform`, data, progress).then(response => response.data);
    },
    attach:(data,progress)=>{
      return axios.post(`/suppression-lists/attach`, data, progress).then(response => response.data);
    }
  }
}
