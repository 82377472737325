<template>

  <div class="w-100">
    <b-row class="my-2">
      <b-col>
        <label for="name">Login:</label>
        <b-input v-model="fields.account" placeholder="login" autocomplete="off"> </b-input>
      </b-col>
      <b-col>
        <label for="">Password</label>
        <b-input type="password" v-model="fields.apikey" placeholder="password" autocomplete="off"></b-input>
      </b-col>
    </b-row>
  

    <b-row>
      <b-col class="text-right mt-2">
        <button :disabled="!isAllowed || loading" class="btn btn-primary"  @click.prevent="createPlatform">  <b-spinner v-if="loading" small type="grow"></b-spinner> Create Platform</button>
      </b-col>
    </b-row>

  
  </div>
  
  
  </template>
  
  <script>

import api from '@/api/RestClient'

    export default {
      name: 'InboxInput',
      components: {
      },
      props: ['platform_nickname'],
      mounted(){
  
      },
      
      data(){
        return {
          loading:false,
          fields: {
            account:null,
            apikey:null
          },
          platform: {
            account:null,
            apikey:null
          }
        }
      },
      methods: {
        createPlatform(){
          this.loading = true;
          api.default.post('platforms/inbox/generate-Token',{
            'account' : this.fields.account,
            'key' : this.fields.apikey
          }).then((response)=>{

            this.fields.account = this.fields.apikey = '';
            this.platform.apikey = response.data.key;
            this.platform.account = response.data.account_id;
           
            this.$emit('createPlatform',this.platform);
            this.loading = false;
          }).catch(()=>{

            let swal = {
              icon: 'error',
              title: 'Failed',
              text: 'Failed to authenticate, please check your credentials and try again',
              type: 'error',
              confirmButtonText: 'OK',
              showCloseButton: true,
            }

            this.$swal(swal)


            this.loading = false;
          })


          
        }
      },
        computed : {
            isAllowed(){
                return this.platform_nickname != null && this.platform_nickname.trim() != ''; 
            }
        }
  
    }
  
  </script>