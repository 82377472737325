<template>
  <iq-card class="mt-4">
    <template v-slot:body>
      <b-row>
        <b-col md="2">
          <tab-nav :pills="true" :vertical="true" class="text-left" id="v-pills-tab">
            <tab-nav-items :active="true" id="v-pills-notification-type-tab" href="#v-pills-notification-type"
              ariaControls="v-pills-notification-type" role="tab" :ariaSelected="true" title="Notification Types"
              class="text-left" />
            <tab-nav-items :active="false" id="v-pills-notification-tab" href="#v-pills-notification"
              ariaControls="v-pills-notification" role="tab" :ariaSelected="false" title="Notifications"
              class="text-left" />
          </tab-nav>
        </b-col>
        <b-col md="">
          <tab-content id="v-pills-tabContent" class="mt-0">
            <tab-content-item :active="true" id="v-pills-notification-type"
              aria-labelled-by="v-pills-notification-type-tab">
              <DtNotificationTypes></DtNotificationTypes>
            </tab-content-item>
            <tab-content-item :active="false" id="v-pills-notification" aria-labelled-by="v-pills-notification-tab">
              <DtNotifications :isClient="true"></DtNotifications>
            </tab-content-item>

          </tab-content>
        </b-col>
      </b-row>


    </template>
  </iq-card>
</template>

<script>
import Vue from 'vue'
import Multiselect from "vue-multiselect"
import Spinner1 from '@/components/loaders/spinner1'
import DtNotificationTypes from '@/components/inboxgeek/settings/DtNotificationTypes'
import DtNotificationTypeEvents from '@/components/inboxgeek/settings/DtNotificationTypeEvents'
import DtNotifications from '@/components/inboxgeek/settings/DtNotifications'
import api from '@/api/RestClient'

export default {

  name: "NotificationSettings",
  components: {
    Spinner1,
    Multiselect,
    DtNotificationTypes,
    DtNotificationTypeEvents,
    DtNotifications
  },
  mounted() {
    this.fetchSettings()
  },
  data() {
    return {
      loading: true,
      app_settings: [],
      settingType: null,
      settings: [
        {
          name: 'General',
          value: 'general',
          icon: 'ri-function-line',
          data: []
        },
        {
          name: 'Emails',
          value: 'emails',
          icon: 'ri-mail-settings-line',
          data: []
        },
        {
          name: 'Credentials',
          value: 'credentials',
          icon: 'ri-key-fill',
          data: []
        }
      ],
    }
  },
  methods: {
    fetchSettings() {
      this.loading = true

      const that = this
      api.settings.list({ page: 1, per_page: 50 })
        .then(response => {

          return;

          if ('success' in response) {
            that.app_settings = response.data.data
          }
        })
        .catch(errors => {
          // that.handleError(errors)
        })
        .finally(() => {

          _(that.settings).forEach(function (group) {
            group.data = [];
          });

          _(that.app_settings).forEach(function (setting) {

            let currentSetting = _.find(that.settings, (o) => { return o.value === setting.type })

            if (currentSetting) {
              currentSetting.data.push(setting)
            }

          });

          this.formatTeamEmails();

          setTimeout(() => { this.loading = false }, 1500)

        })
    },
    formatTeamEmails() {
      const that = this

      let emailSetting = _.find(that.settings, function (o) { return o.value == 'emails'; });

      let teamEmails = [];
      let types = {};
      if (emailSetting) {
        _(emailSetting.data).forEach(function (setting) {
          let alreadyAdd = _.find(teamEmails, function (o) { return o == setting.value; });
          if (!alreadyAdd) {
            teamEmails.push(setting.value)
            types[setting.value] = []
          }

          let alreadyAddType = _.find(types[setting.value], function (o) { return o == setting.name; });
          if (!alreadyAddType) {
            types[setting.value].push(setting.name)
          }
        });
      }
      return types
    },
  }
}

</script>