<template>
    <div class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color mr-0">
        <div class="custom-switch-inner">
            <input type="checkbox" data-toggle="tooltip" data-placement="top" title="Active Status"
                :id="`${customLabel}-${row.item.id}_${attribute}${row.index}`"
                :name="`${customLabel}-${row.item.id}_${attribute}${row.index}`"
                :data-id="`${customLabel}-${attribute}${row.index}_${row.item.id}`"
                :data-name="`${customLabel}-${row.item.id}_${attribute}${row.index}`" v-model="row.item[attribute]"
                @input="onSwitchActive(row.item, row.index, $event.target)" class="custom-control-input"
                :class="bg_class(row)" :disabled="row.item.deleted_at">

            <label :for="`${customLabel}-${row.item.id}_${attribute}${row.index}`" data-on-label="ON"
                data-off-label="OFF" class="custom-control-label" />
        </div>
    </div>
</template>

<script>

export default {
    name: 'IBGSwitchCell',
    props: ['row', 'attribute', 'labelClass', 'label'],
    data() {
        return {
            customLabel: Date.now()
        }
    },
    methods: {
        
        onSwitchActive(row, index, input) {
            this.$emit('onSwitch', {row, input, key: this.attribute})
        },
        bg_class(row) {
            return row.item.deleted_at ? "bg-secondary" : "bg-primary";
        },
        custom_class(row) {
            return row.item.deleted_at ? "custom-switch-grey" : "custom-switch";
        }
    }
}

</script>

<style>
.custom-switch .custom-control-input:disabled:checked~.custom-control-label::before {
    background-color: rgba(78, 79, 81, 0.5);
}
</style>
