<template>
    <div class="d-flex justify-content-center">

        <b-dropdown>
            <template #button-content variant="light">
                <!-- For Dropdown -->
                <i v-if="!isLoading" class="ri-more-2-fill fa-lg mx-auto btn btn-light"></i>

                <b-spinner v-else small label="Small Spinner" type="grow" class="m-2 text-dark"></b-spinner>
                <!-- End Dropdown -->
            </template>
            <b-dropdown-item v-if="row.item.active >= 0" href="#" ref="btn-edit" @click="onClick({ ...$refs['btn-edit'], title: 'edit' }, row.item, 'btn-edit')">
                <IgBtn v-if="row.item.active >= 0" :row="row" title="Edit Source"
                    :btnClass="'btn iq-bg-success mx-1 btn-sm'" :btnTitle="'Edit Source'" :icon-class="'ri-edit-box-line mx-auto'"
                    @onClick="onClick({ ...$event.target, title: 'edit' }, row.item, 'btn-edit')"></IgBtn>
                Edit
            </b-dropdown-item>

            <b-dropdown-item v-show="row.item.platform && row.item.lists.length" href="#" :ref="'btn-test'" @click="onClick({ ...$refs['btn-test'], title: 'test' }, row.item, 'btn-test')">
                <IgBtn v-show="row.item.platform" :row="row" title="Test Send Flow"
                    :btnClass="'btn iq-bg-primary mx-1 btn-sm'" :btnTitle="'Test Flow'"
                    :icon-class="'ri-mail-send-line text-info fa-lg mx-auto'"
                    @onClick="onClick({ ...$event.target, title: 'test' }, row.item, 'btn-test')"></IgBtn>
                Test Flow
            </b-dropdown-item>
            <b-dropdown-item href="#" :ref="'btn-logs'" @click="onClick({ ...$refs['btn-logs'], title: 'logs' }, row.item, 'btn-logs')">
                <IgBtn :row="row" title="logs"
                    :btnClass="'btn iq-bg-primary mx-1 btn-sm'" :btnTitle="'Histories'"
                    :icon-class="'fa fa-history m-0 text-info fa-lg mx-auto'"
                    @onClick="onClick({ ...$event.target, title: 'logs' }, row.item, 'btn-logs')"></IgBtn>
                Logs
            </b-dropdown-item>

          <b-dropdown-item href="#" :ref="'btn-detail'" @click="onClick({ ...$refs['btn-detail'], title: 'detail' }, row.item, 'btn-detail')">
            <IgBtn :row="row" title="Go to details" :btnClass="'btn iq-bg-success mx-1 btn-sm'"
                   :btnTitle="'Detail'" :icon-class="'ri-link pull-right fa-lg mx-auto'"
                   @onClick="onClick({ ...$event.target, title: 'detail' }, row.item, 'btn-detail')"></IgBtn>
            Go to details
          </b-dropdown-item>

          <b-dropdown-item href="#" :ref="'btn-suppression'" @click="onClick({ ...$refs['btn-suppression'], title: 'applySuppressionList' }, row.item, 'btn-suppression')">
            <IgBtn :row="row" title="Suppression lists" :btnClass="'btn iq-bg-danger mx-1 btn-sm'"
                   :btnTitle="'Detail'" :icon-class="'ri-file-forbid-line pull-right fa-lg mx-auto'"
                   @onClick="onClick({ ...$event.target, title: 'applySuppressionList' }, row.item, 'btn-suppression')"></IgBtn>
           Suppression Lists
          </b-dropdown-item>

            <b-dropdown-item href="#" :ref="'btn-copy'" @click="onClick({ ...$refs['btn-copy'], title: 'copy' }, row.item, 'btn-copy')">
                <IgBtn :row="row" title="Copy Script" :btnClass="'btn iq-bg-secondary mx-1 btn-sm'"
                    :btnTitle="'Copy'" :icon-class="'ri-file-copy-2-line pull-right fa-lg text-info mx-auto'"
                    @onClick="onClick({ ...$event.target, title: 'copy' }, row.item, 'btn-copy')"></IgBtn>
                Copy Script
            </b-dropdown-item>


            <b-dropdown-item href="#" :ref="'btn-chart'" @click="onClick({ ...$refs['btn-chart'], title: 'chart' }, row.item, 'btn-chart')">
                <IgBtn :row="row" title="Show Chart" :btnClass="'btn iq-bg-primary mx-1 btn-sm'"
                    :btnTitle="'Show Chart'" :icon-class="'fa fa-eye mx-auto'"
                    @onClick="onClick({ ...$event.target, title: 'chart' }, row.item, 'btn-chart')"></IgBtn>
                Show Chart
            </b-dropdown-item>


            <b-dropdown-item v-if="row.item.active >= 0" href="#" ref="btn-delete" @click="onClick({ ...$refs['btn-delete'], title: 'Delete' }, row.item, 'btn-delete')">
                <IgBtn v-if="row.item.active >= 0" :row="row" title="Delete"
                    :btnClass="'btn iq-bg-danger mx-1 btn-sm'" :btnTitle="'Delete'" :icon-class="'fa fa-trash mx-auto'"
                    @onClick="onClick({ ...$event.target, title: 'Delete' }, row.item, 'btn-delete')"></IgBtn>
                Delete
            </b-dropdown-item>


        </b-dropdown>

    </div>
</template>

<script>
import IgBtn from '@/components/inboxgeek/btn/Default.vue'
import { helper } from '@/helpers'

export default {
    name: 'LLSourceActions',
    props: ['row'],
    components: {
        IgBtn
    },
    data() {
        return {
            isLoading: false,
        }
    },
    mounted() {
    },
    methods: {
        onClick(event, row, ref) {
            if (typeof this.$refs[ref] !== 'undefined') {
                this.setState();
            }

            this.$emit('onClick', { row, event, ref: this })
        },
        setState(value = true) {
            if (typeof value === 'boolean') {
                this.isLoading = value
            }
        }
    }
}

</script>
<style>
button.btn.dropdown-toggle {
    background-color: #E9EDF4 !important;
    border-color: #E9EDF4 !important;
    padding: 0;
}

ul.dropdown-menu li,
ul.dropdown-menu li>a.dropdown-item {
    /* width: 2.5rem !important; */
}
</style>