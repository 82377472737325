import { Add } from '@/actions/modules/Integrations/AddIntegrations';
import api from '@/api/RestClient';
import { services } from '..';

export default function platform(axios) {
    return {
        labels: {
            listID: "listId",
            tagID: "listId",
            // listName: "",
            // tagName: "listId",
        },
        maps: {
            listsMap: (obj) => {
                let item = {}
                item.code = obj.listId
                item.name = obj.name
                item.type = obj['listType']
                return item
            },
        },
        updateLabels: (COMP) => {
            COMP.auth2.btnLabel = 'Add New Hubspot';

            if (typeof COMP.$refs["step-platform"] !== 'undefined') {
                COMP.$refs["step-platform"].accountLabel = 'Account Hubspot';
                COMP.$refs["step-platform"].accountPlaceholder = 'Account Hubspot';
            }

            if (typeof COMP.accountLabel !== 'undefined') COMP.accountLabel = 'Account Hubspot';
            if (typeof COMP.accountPlaceholder !== 'undefined') COMP.accountPlaceholder = 'Account Hubspot';


        },
        getTagAdded(result) {
            result = 'data' in result ? result.data : result;
            return { code: result.listId, name: result.name }
        },


        oauthDataLabel: () => {
            return { label: 'User Hubspot', placeholder: 'Selected User Hubspot' }
        },
        oauthDataMap: (obj) => {
            let item = { code: obj.user_id, name: obj.user }
            return item
        },
        oauthGetUsers: (data) => {
            return [data?.data]
        },

        oauthLoadPlatforms(COMP, organization = null) {

            let organizationId = (typeof COMP.organization?.accountid !== 'undefined') ? COMP.organization.accountid : organization ?? null

            let auth2ServiceApi = platform(axios).oauthGetAlreadyAdded({ organizationId: organizationId });

            auth2ServiceApi.then(function (result) {
                if (typeof services.default.oauthLoadOlds === 'function') services.default.oauthLoadOlds(COMP, result)
            })
                .catch(function (error) {
                    COMP.auth2.addNew = true
                })
                .finally(function () {
                    COMP.auth2.btnAuthorize = false;
                    COMP.auth2.inProgress = false;
                })
        },


        oauthGetAuthURL: (platform_id = null) => {
            return axios.get(`/platforms/hubspot/oauth/authorize${(platform_id ? '?platform_id=' + platform_id : '')}`)
                .then(response => response.data);
        },
        oauthGetAccessToken: (params) => {
            return axios.post(`/platforms/hubspot/oauth/access_token`, params)
                .then(response => response.data);
        },
        oauthGetAccountInfo: (params) => {
            return axios.post(`/platforms/hubspot/account_infos`, params)
                .then(response => response.data);
        },
        oauthGetAlreadyAdded: (params) => {
            return axios.post(`/platforms/hubspot/previous_configs`, params)
                .then(response => response.data);
        },
        oauthGetRefreshToken: (params) => {
            return axios.post(`/platforms/hubspot/oauth/refresh`, params)
                .then(response => response.data)
        },
    }
}
