export default function (axios) {
  return {
    list: (params = {}) => {
      return axios.get(`/support_tickets`, { params })
        .then(response => response.data);
    },
    get: (id) => {
      return axios.get(`/support_tickets/${id}`)
        .then(response => response.data);
    },
    post: (data) => {
      return axios.post(`/support_tickets`, data)
        .then(response => response.data)
    },
    put: (id, data) => {
      return axios.put(`/support_tickets/${id}`, data)
        .then(response => response.data)
    },
    delete: (id) => {
      return axios.delete(`/support_tickets/${id}`)
        .then(response => response.data);
    },
    restore: (id) => {
      return axios.post(`/support_tickets/${id}/restore`, {})
        .then(response => response.data);
    },
    addMessage: (id, data) => {
      return axios.post(`/support_tickets/${id}/message`, data)
        .then(response => response.data)
    },
    bulkUpdate: (items, data) => {
      return axios.put(`/support_tickets/bulk`, {items, data})
        .then(response => response.data)
    },
    bulkDelete: (items) => {
      return axios.put(`/support_tickets/bulk/delete`, {items})
        .then(response => response.data)
    },
    uploadFile: (data) => {
      return axios.post(`/support_tickets/upload_file`, data)
        .then(response => response.data)
    },
  }
}
