<template>
    <b-col :cols="column">
        <form action="#" @submit="submit" method="post" id="sendflow">
            <div class="form-row" id="email-inputs">
                <div class="col-md-12 mb-3">
                    <label for="validationDefaultUsername">Enter an email address below to test your source.</label>
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="inputGroupPrepend2">@</span>
                        </div>
                        <input id="testEmailFlow" type="email" class="form-control" v-model="email" placeholder="Email"
                            aria-describedby="inputGroupPrepend2" required>

                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="form-group">
                    <button class="btn btn-primary" type="submit" variant="primary" :disabled="send">
                        <b-spinner small type="grow" v-if="send"></b-spinner>
                        {{ sendText }}
                    </button>
                </div>
            </div>
        </form>
    </b-col>
</template>
<script>
import api from '@/api/RestClient'
const BTN_TEXT = "Send Test LiveLeads"
export default {
    name: 'GeSendflow',
    props: {
        source: Number,
        col: String,
        automation_id: Number
    },
    mounted() {
        window.component = this;
        this.column = this.col;
    },
    data: () => ({
        column: 12,
        sendText: BTN_TEXT,
        send: false,
        email: "",
        platform_response_status: [200, 201, 202, 203, 204]
    }),
    methods: {
        submit(e) {
            e.preventDefault();
            this.send = true;
            this.sendText = 'Sending...';
            this.sendTestFlow();
        },  
        sendTestFlow() {
            return api.geAutomations.test_flow(this.automation_id, this.email)
                .then(result => {
                    let title = result.success ? 'Success!' : 'Oops ...';
                    let type = result.success ? 'success' : 'error';
                    this.$swal(title, result.data.message, type);
                })
                .catch(function (error) {
                    // handle error
                    const message = error.response.data.message;
                    const title = 'Warning';
                    const type = 'error';
                    component.$swal(title, message, type);
                })
                .finally(() => {
                  this.send = false;
                  this.sendText = BTN_TEXT;
                });
        }
    }
}
</script>

<style>
.form-row {
    display: block !important;
}
</style>
