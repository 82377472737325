<template>
<div class="row mb-4">

  <div class="col">

    <label for="">Custom Field:</label>

    <multiselect
        class="mb-2"
        :options="customFields"
        v-model="selectedCustomField"
        label="name"
        @select="updateValue"
        track-by="code"
        :multiple="false"
        :close-on-select="true"
        :taggable="true"
        tag-placeholder="Press Enter to create a new custom field."
        @tag="handleAddCustomField"></multiselect>

    <label for="">Custom field value:</label>
    <b-input v-model="customField.code" @keyup="updateValue"></b-input>
    <div class="alert alert-info my-2" style="color: #22768a;background-color: #e5faff9e;padding: 1rem;">
     <p class="m-0"> Retrieve only contacts with the given specified value in the given custom field attribute. </p>
    </div>
  </div>
</div>
</template>

<script>

import api from "@/api";
import multiselect from "vue-multiselect";

export default {

  name: "BeehiivCustomFieldsInput",
  props: ['list', 'customFields','targetCustomField','platform'],
  components:{ multiselect},

  data(){
    return {

      customField:{
        code:null,
        name:null
      },
      selectedCustomField:null,

    }
  },
  mounted() {
    if(this.list){
      this.selectedCustomField = this.list;
    }
    this.customField.code = this.name;
  },
  methods:{
    updateValue(){
      this.customField.name = this.selectedCustomField?.name;
      this.$emit('customFieldSelected',this.customField);
    },
    handleAddCustomField(customField){
      api.platforms.addTag({
        platform_name : 'beehiiv',
        entities      : 'lists',
        platform_id   : this.platform.p_id,
        name          : customField
      }).then((response)=>{

        this.$bvToast.toast('Custom field is successfully added', {
          title   : 'Success',
          variant : 'success'
        });

        this.selectedCustomField = { name : customField , code:'' };

      }).catch((response)=>{

        this.$bvToast.toast('failed to add the custom field', {
          title   : 'failed',
          variant : 'danger'
        });

      })
    }
  },
  watch:{
    list: function(newValue,oldValue){
      if(newValue.value){
        this.selectedCustomField = { code: newValue.id , name:newValue.name} ;
        this.customField.code = newValue.value;
      }

    }
  }

}
</script>
