<template>
    <fieldset>
        <h3 class="text-left mb-4">Source Information:</h3>

        <div class="form-card text-left">

            <b-row>
                <b-form-group class="col-md-6" label="Name: *" label-for="Platform">
                    <b-form-input type="text" placeholder="Name" v-model="form.name"></b-form-input>
                    <small v-if="errors.name" ref="errors_name" class="text-danger d-none">{{ errors.name }}</small>
                </b-form-group>

                <b-form-group class="col-md-6" label="Website: *" label-for="Platform">
                    <b-form-input type="text" placeholder="Website" v-model="form.website"></b-form-input>
                    <small v-if="errors.website" ref="errors_website" class="text-danger d-none">{{ errors.website
                    }}</small>
                </b-form-group>

            </b-row>

            <b-row>
                
                <b-form-group class="col-md-12" label-for="daily-limit">
                    <b-form-checkbox v-model="form.enableLimit" name="check-button" button-variant="primary" size="md" switch @input="onSwitchLimitPerDay">
                        Daily limit
                    </b-form-checkbox>

                    <b-form-input v-show="form.enableLimit" v-model="form.limit" type="number" name="limit" placeholder="Daily Limit" :disabled="!form.enableLimit"></b-form-input>

                </b-form-group>

            </b-row>

            <b-row>
                
                <b-form-group class="col-md-8 my-auto" label-for="duplicate-by-account" v-slot="{ ariaDescribedby }">

                    <b-form-radio-group 
                        class=""
                        id="duplicate-type-group"
                        v-model="form.duplicateByType"
                        :options="duplicateByOpions"
                        :aria-describedby="ariaDescribedby"
                        name="radio-duplicateByOpions"
                        @input="duplicateByTypeChange"
                    ></b-form-radio-group>
                    
                </b-form-group>

                <b-form-group v-show="showRecurrentInput" class="col-md-4 my-auto" label-for="recurrent">
                    <b-form-input type="number" placeholder="Recurrent Record Every: (X days)" v-model="form.recurrent"></b-form-input>
                    <small v-if="errors.recurrent" ref="errors_recurrent" class="text-danger d-none">{{ errors.recurrent
                    }}</small>
                </b-form-group>

            </b-row>

        </div>

    </fieldset>
</template>

<script>
import SuppressionListField from "@/components/inboxgeek/fileds/SuppressionListField";

export default {
    name: "PartialName",
    components: {
      SuppressionListField
    },
    props: ['form', 'errors'],
    data() {
        return {
          checked: false,
          duplicateByOpions: [
            { text: 'No Duplicate by Account', value: 'no_by_account' },
            { text: 'No Duplicate by Source', value: 'no_by_source' },
            { text: 'Recurrent Record Every: (X days)', value: 'recurrent' },
          ],
          showRecurrentInput: false,
        }
    },
    mounted() {
        this.showErrors();
        
        if(!this.form.duplicateByType) {
            this.form.duplicateByType = this.duplicateByOpions[2].value;
        }

    },
    methods: {
        showErrors() {
            const that = this

            _.forOwn(this.errors, function (error, key) {
                const ref = `errors_${key}`
                if (typeof that.$refs[ref] !== 'undefined') that.$refs[ref].classList.remove('d-none')
            });
        },
        resetErrors() {
            const that = this
            _.forOwn(this.errors, function (error, key) {
                const ref = `errors_${key}`
                that.$refs[ref].classList.add('d-none')
            });
        },
        onSwitchLimitPerDay($event) {
            if (!$event.checked) {
                this.form.limitPerDay = null
                this.form.limit = null
            } else this.form.limit = 0
        },
      suppressionListUploaded(list){

        if(!this.form.suppression_lists) {

          this.form.suppression_lists = [];
        }

        this.form.suppression_lists.push(list);
      },
        duplicateByTypeChange($event) {
            console.log( $event, this.form.duplicateByType )

            if(this.form.duplicateByType == 'recurrent') {
                this.showRecurrentInput = true;
            } else {
                this.showRecurrentInput = false;
            }
        }
    }
};
</script>