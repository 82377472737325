import { Add } from '@/actions/modules/Integrations/AddIntegrations';
import { PLATFORMS_CODE, COLUMNS_GRID } from "@/constantes";
import api from '@/api/RestClient';

export default function platform(axios) {
    return {
        labels: {

        },
        customShowAnotherCols(COMP) {
            
            COMP.showCols.fields = true;
            COMP.tagLabel  = 'Custom field *';
            COMP.tagPlaceholder = 'Custom field';
            COMP.selectTagPlaceholder = 'Custom field';
        },

    }
}