<template>
    <b-modal :id="id"
        :ref="id"
        title="LiveOpens Automation"
        ok-title="Save" cancel-title="Cancel" size="xl"
        @show="initModal" hide-footer @hidden="form = resetForm()">
        <b-col :cols="column">
            <form v-if="renderModal" action="#" @submit.prevent="submit" method="post" id="link-automation">
                <div class="form-row" id="lo-automation-inputs">

                    <b-row>
                        <b-col lg="12" sm="12">

                        <iq-card>
                            <template v-slot:body>
                                <b-row>
                                    <div class="col-md-2 my-auto">
                                        Integration Name: *
                                    </div>
                                    <b-form-group class="col-md-10" label-for="integration-name">
                                        <b-form-input type="text" placeholder="Integration Name" v-model="form.integration_name"></b-form-input>
                                        <small ref="errors_integration_name" class="text-danger d-none">
                                            {{ errors.integration_name }}
                                        </small>
                                    </b-form-group>
                                </b-row>

                                <!-- <b-row>
                                    <div class="col-md-3 my-auto">
                                        Automation: *
                                    </div>
                                    <b-form-group class="col-md-9" label-for="Platform">
                                        <b-form-input type="text" placeholder="Automation Name" v-model="form.automation_name" :disabled="true"></b-form-input>
                                        <small ref="errors_automation_pid" class="text-danger d-none">
                                            {{ errors.automation_pid }}
                                        </small>
                                    </b-form-group>
                                </b-row> -->

                                <b-row>
                                    <div class="col-md-2 my-auto">
                                      Platform Automations: *
                                    </div>
                                    <div v-if="isLoading" class="col-md-10">
                                        <div class="text-center p-4 d-flex justify-content-center align-items-center">
                                            <b-spinner variant="primary"> </b-spinner>
                                            <span class="text-primary p-2"><small> Fetching platform automations, please wait...</small></span>
                                        </div>
                                    </div>
                                    <b-form-group v-else class="col-md-10" label-for="Platform">

                                        <div v-if="!automations.length" class="text-center">
                                            <div class="w-100 mb-2" style="color:#f6386b">No Automation found</div>

                                            <div @click="showCreateNewPlatform = true" class="rounded btn btn-primary text-white m-auto p-2 px-4 mt-4 d-none"
                                            @mouseover="isHovering = true" @mouseout="isHovering = false" :disabled="true">
                                                <h5 class="text-white">Create new Automation</h5>
                                            </div>
                                        </div>

                                        <div v-if="automations.length">
                                            <multiselect v-model="automation" tag-placeholder="Empty" :allow-empty="false"
                                                deselect-label="Can't remove this value" placeholder="Select an Automation" label="name" track-by="code"
                                                :options="automations" :multiple="false" :taggable="true">
                                                <template slot="singleLabel" slot-scope="props">
                                                    <img v-if="item.platform" class="option_image img-responsive mx-2" :src="getImageURL(item.platform.name)" alt="Platform image"
                                                        width="28">
                                                    <span class="option__desc">
                                                        <span class="option__title">{{ platformName(props.option.name) }}</span>
                                                    </span>
                                                </template>

                                                <template slot="option" slot-scope="props">
                                                    <div class="option__desc">
                                                        <img v-if="item.platform" class="option__image img-responsive mx-2" :src="getImageURL(item.platform.name)"
                                                        alt="Platform label image" width="28">
                                                        <span v-if="item.platform" class="option__title">{{ platformName(props.option.name) }}</span>
                                                    </div>
                                                </template>
                                            </multiselect>
                                        </div>
                                        <small v-if="automations.length" ref="errors_automation" class="text-danger d-none">
                                            {{ errors.automation }}
                                        </small>

                                    </b-form-group>
                                </b-row>

                            </template>
                        </iq-card>

                        </b-col>
                    </b-row>

                </div>
                <div class="form-group">
                    <div class="form-group">

                        <button class="btn btn-primary pull-right mx-2" type="submit" variant="primary" :disabled="saving || !automations.length">
                            <b-spinner small type="grow" v-if="saving"></b-spinner>
                            {{ saveText }}
                        </button>

                        <button v-if="done" class="btn btn-secondary pull-right" type="button" variant="secondary" @click="$root.$emit('bv::hide::modal', id)">
                            <b-spinner small type="grow" v-if="saving"></b-spinner>
                            Done
                        </button>

                    </div>
                </div>
            </form>
        </b-col>
    </b-modal>
</template>
<script>
import api from '@/api/RestClient'
import { helper } from '@/helpers';
import _ from 'lodash';
import Multiselect from 'vue-multiselect'

const BTN_TEXT = "Save Link Automation"
export default {
    name: 'ModalLinkAutomation',
    props: ['id', 'item'],
    components: {
        Multiselect,
    },
    mounted() {
        this.column = this.col;
    },
    data() {
        return {
            isLoading: false,
            renderModal: true,
            saving: false,
            form: {},
            errors: {},
            search: '',
            automations: [],
            automation: null,
            column: 12,
            saveText: BTN_TEXT,
            done: false
        }
    },
    methods: {
        initModal() {
            this.automation = null;
            this.form = this.resetForm();

            this.resetStepComp();
            this.fetchPlatformAutomation();
        },
        resetForm() {
            return !this.item ? {
                integration_id: null,
                account_id: null,
                platform_id: null,
                integration_name: '',
                automation: null,
                automation_name: '',
                automation_pid: '',
            } : this.getFormAutomation()
        },
        getFormAutomation() {

            if (typeof this.item !== 'undefined' && this.item) {
                return {
                    integration_id: this.item.id,
                    account_id: this.item && this.item.account_id ? this.item.account_id : null,
                    platform_id: this.item.platform_id,
                    integration_name: this.item.name,
                    automation_name: '',
                    automation_pid: '',
                }
            }

            return {}
        },
        resetStepComp() {
            this.done = false;
            this.saving = false;
            this.renderStep = false;

            this.$nextTick(() => {
                this.renderStep = true;
                this.$forceUpdate();
            })
        },
        fetchPlatformAutomation() {
            this.isLoading = true;

            const platformId = this.item.platform_id;
            const platformName = this.item.platform.name;
            const listID = this.item.lists.length ? this.item.lists[0].list_id : null;

            return api.platforms.automations(platformId, platformName, listID)
                .then((response) => {

                    if(response.success) {
                        this.automations = response.data;
                        this.setCurrentLoAutomation();
                    }

                    this.isLoading = false;
                })
        },
        selectAutomation(automation) {

            if (automation.code) {
                this.form.automation_name = automation.name;
                this.form.automation_pid = automation.code;
            }

        },
        isLinked(automation) {
            return automation.code == this.form.automation_pid
        },
        platformName(name) {
            return helper.platformFormatName(name);
        },
        getImageURL(platform) {
            try {
                return require(`@/assets/images/platforms/${platform.toLowerCase()}.png`)
            } catch (e) {
                return require(`@/assets/images/platforms/default.png`)
            }
        },
        onChange($event) {
            if($event) {
                this.form.automation = $event
            }

            this.$refs['input-automation-selected'].$forceUpdate();
        },
        submit(e) {
            e.preventDefault();
            this.saving = true;
            this.saveText = 'Saving...';
            this.saveLinkAutomation();
        },
        resetErrors() {

            _.forOwn(this.errors, (error, key) => {
                const ref = `errors_${key}`

                if (typeof this.$refs[ref] !== 'undefined') {
                    this.$refs[ref].classList.add('d-none');
                }
            });

            this.errors = {}

        },
        formIsValid() {
            this.resetErrors();

            if (!this.form.integration_name || !this.form.integration_name.trim()) {
                this.errors.integration_name = 'Integration name is required.';
            }
            // if (!this.form.automation_name || (this.form.automation_name && !this.form.automation_name.trim())) {
            //     this.errors.automation_name = 'Automation name is required.';
            // }
            // if (!this.form.automation_pid || (this.form.automation_pid && typeof this.form.automation_pid == 'string' && !this.form.automation_pid.trim())) {
            //     this.errors.automation_pid = 'Please select a Platform Automation.';
            // }

            if (!this.form.automation || (this.form.automation && (!this.form.automation.code || !this.form.automation.name))) {
                this.errors.automation = 'Please select an Automation.';
            }

            return helper.isEmpty(this.errors);

        },
        getFormData() {
            return {
                integration_id: this.form.integration_id,
                account_id: this.form.account_id,
                platform_id: this.form.platform_id,
                integration_name: this.form.integration_name,
                automation_pid: this.form.automation ? this.form.automation.code : null,
                automation_name: this.form.automation ? this.form.automation.name : null,
            }
        },
        saveLinkAutomation() {
            this.saving = true;
            const data = this.getFormData();

            // Add form Validation Check
            if(this.formIsValid()) {

                return api.loAutomations.store(data)
                    .then((response) => {

                        if (response.success) {
                            let title = response.success ? 'Success!' : 'Oops ...';
                            let type = response.success ? 'success' : 'error';
                            this.$swal(title, response.message, type);
                            this.done = true;
                            this.fetchPlatformAutomation();

                            // TODO emit refresh datatable when automation added
                            this.$emit('hide', {})
                        }

                    })
                    .catch((error) => {
                        // handle error
                        if (error.response.data.errors) {
                            let title = 'Oops ...';
                            this.$swal(title, error.response.data.errors.message, "error");
                        }
                    })
                    .finally(() => {
                        this.saving = false;
                        this.saveText = BTN_TEXT;
                    });

            } else {

                this.showErrors();
                this.saving = false;
                this.saveText = BTN_TEXT;

            }

        },
        showErrors() {

            _.forOwn(this.errors, (error, key) => {
                const ref = `errors_${key}`;

                if (typeof this.$refs[ref] !== 'undefined') {
                    this.$refs[ref].classList.remove('d-none');
                }
            });

        },
        setCurrentLoAutomation() {
            this.isLoading = true;

            let loAutomation = null;

            if(this.item.lo_automation) {
                loAutomation = _.find(this.automations, (o) => { return o.name == this.item.lo_automation.automation_name })


                if (loAutomation) {
                    this.automation = loAutomation
                    this.form.automation = loAutomation
                }
            }
            this.isLoading = false;
        }
    },
    watch: {
        // whenever question changes, this function will run
        automation: function (newAutomation, oldAutomation) {
            this.form.automation = newAutomation;
        }
    }

}
</script>

<style>
.form-row {
    display: block !important;
}
.automation-card.selected {
    border: 2px solid #39adfa !important;
    box-shadow: 0px 2px 9px 0px #1f75fe61 !important;
    outline-offset: -12px;
}
</style>
