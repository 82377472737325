
      <template>
      <div>
      <label for="">Groups:</label>
      <multiselect v-model="list"
                        :options="lists"
                        tag-placeholder="Empty"
                        label="name"
                        track-by="id"
                        :multiple="false"
                        return="code"
                        :allow-empty="false"
                        :taggable="true"
                        @select="handelSelectEvent"
                        @remove="handelSelectEvent">

    <template slot="option" slot-scope="props">
      <div class="option__desc">

            <span class="option__title">

              <span v-if="props.option.type">

                <i v-if="props.option.type === 'segment'" class="ri-flashlight-line" style="color:#5ad"></i>
                <i v-else class="ri-list-check color-primary" style="color:#5ad"></i>

              </span>

              <span v-else><i class="ri-list-check color-primary" style="color:#5ad"></i></span>
              <span style="font-weight:bold;"> {{ props.option.name }} </span>
              <span style="font-size:11px; color:#888"> {{ props.option.code }}</span>


            </span>

      </div>

    </template>

</multiselect>
<small v-if="error.show">{{error.message}}</small>
</div>

</template>

<script>
  import Multiselect from 'vue-multiselect'
  import api from "@/api";
  export default {
  name: "OntraportListInput",
  props:['form','listsProp'],
  components:{
  Multiselect
},
  mounted() {
  if(this.listsProp.length === 0){
  this.fetchLists();
}else{
  this.lists = this.listsProp;
}

},
  data(){
  return{
  list:null,
  lists:[],
  error:{
  show:false,
  message:''
}
}
},methods:{
  fetchLists(){
  api.platforms.fetchServices({
  platform_name : this.form['initial'].platform.name.toLowerCase(),
  entities      : 'lists',
  platform_id   : this.form['initial'].platform.id
}).then((response)=>{
  this.lists = response.data;
});
},
  handelSelectEvent(){
  this.$emit('ListSelected',{id : this.list.id , name : this.list.name });
}
}
}
</script>

<style scoped>

</style>
