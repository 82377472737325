export default function (axios) {
  return {
    list: (params = {}) => {
      return axios.get(`/ge_records`, { params })
        .then(response => response.data);
    },
    get: (geRecordID = null) => {
      let url = geRecordID ? `/ge_records/${geRecordID}` : `/ge_records`
      return axios.get(url)
        .then(response => response.data);
    },
    downloadBySource: (params = {}) => {
      let url = `/ge_records/download?` + (new URLSearchParams(params).toString());

      return axios.get(url, {params: params})
        .then(response => response.data);
    },
  }
}
