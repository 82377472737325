<template>
<div>
    <div>
        <label for="">Lists:</label>
        <multiselect v-model="list" :options="lists" tag-placeholder="Empty" label="name" track-by="id" :multiple="false" return="code" :allow-empty="false" :taggable="true" @select="handelSelectEvent" @remove="handelSelectEvent">

            <template slot="option" slot-scope="props">
                <div class="option__desc">

                    <span class="option__title">

                        <span v-if="props.option.type">

                            <i v-if="props.option.type === 'segment'" class="ri-flashlight-line" style="color:#5ad"></i>
                            <i v-else class="ri-list-check color-primary" style="color:#5ad"></i>

                        </span>

                        <span v-else><i class="ri-list-check color-primary" style="color:#5ad"></i></span>
                        <span style="font-weight:bold;"> {{ props.option.name }} </span>
                        <span style="font-size:11px; color:#888"> {{ props.option.code }}</span>

                    </span>

                </div>

            </template>

        </multiselect>
        <small v-if="error.show">{{ error.message }}</small>
    </div>

    <div v-if="list_selected" class="mt-3">
        <label for="segment" style="display:flex;justify-content: space-between;">
            <span>Segment</span>
        </label>

        <multiselect v-model="selected_segments" multiple taggable tag-placeholder="Segments" placeholder="select segments" label="name" track-by="code" :options="segments" :loading="isLoading" @select="handelSelectSegments" return="code" :allow-empty="true">

            <template slot="option" slot-scope="props">
                <div class="option__desc">

                    <span class="option__title">

                        <span v-if="props.option.type">

                            <i class="ri-flashlight-line" style="color:#5ad"></i>

                        </span>

                        <span v-else><i class="ri-list-check color-primary" style="color:#5ad"></i></span>

                        <span style="font-wight:bold;"> {{ props.option.name }} </span>

                        <span style="font-size:11px; color:#888"> {{ props.option.id }}</span>

                    </span>

                </div>

            </template>

        </multiselect>
    </div>
    <div v-show="showWarning" class="p-3">
        <div class="alert danger" style="    color: #da1e1e;border-color: #f7c6c6;background-color: #fcecec4d;">At least one segment must be selected </div>
    </div>
</div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import api from "@/api";
export default {
    name: "OngageListInput",
    props: ['form', 'listsProp'],
    components: {
        Multiselect
    },
    mounted() {
        if (this.listsProp.length === 0) {
            this.fetchLists();
        } else {
            this.lists = this.listsProp;
        }

    },
    data() {
        return {
            list: null,
            lists: [],
            segments: [],
            error: {
                show: false,
                message: ''
            },
            showWarning: false,
            list_selected: false,
            selected_segments: [],
        }
    },
    methods: {
        fetchSegments() {

            this.isLoading = true;

            api.platforms.fetchServices({

                "platform_name": 'ongage',
                "entities": "segments",
                "platform_id": this.form['initial'].platform.id,
                "platform": -300,
                "list_id": this.list.id

            }).then((response) => {

                this.segments = response.data.map((segment) => {
                    return {
                        'code': segment.id,
                        'name': segment.name
                    };
                });

                this.isLoading = false;

            }).catch((error) => {
                this.isLoading = false;
                console.log('failed to fetch segments');
            }).finally(() => {

                this.list_selected = true;
                this.showWarning = true;
            });
        },
        fetchLists() {
            api.platforms.fetchServices({
                platform_name: this.form['initial'].platform.name.toLowerCase(),
                entities: 'lists',
                platform_id: this.form['initial'].platform.id
            }).then((response) => {
                this.lists = response.data;
            });
        },
        handelSelectEvent() {
            this.fetchSegments();
        },
        handelSelectSegments() {
            this.list.id = `${this.selected_segments[0].code}-${this.list.id}`
            this.$emit('ListSelected',{id: this.list.id , name:this.list.name});
        }

    },
}
</script>

<style scoped>

</style>
