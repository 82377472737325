<template>

  <b-container fluid>
  <b-modal id="apply-suppression-source-modal" ref="applySuppressionSourcedModal" title="Apply suppression list to source" size="xl" centered hide-footer>
<div class="p-4">
  <div class="alert alert-light p-2" style="border:0;background-color: #d6d8d536">
    <div class="row align-items-center p-2 ml-2 w-100">
      <div class="">
        <img :src="fetchPlatformImage(list.platform)"  width="40px" alt="">
      </div>
      <div class="col">
        <h4>{{list.id}} | {{ list.name }}</h4>
        <small class="text-muted">{{ list.type }} {{ list.list_id }}</small>
      </div>
    </div>

  </div>
  <label for="">Sources:</label>
  <multiselect  ref="source-ref"
                class="mb-3"
                :loading="loading.source"
                :disabled="loading.source"
                tag-placeholder="Source"
                placeholder="Select Source"
                v-model="selected_sources"
                label="name"
                track-by="id" return="id"
                :multiple="true"
                :options="sources" >
    <template slot="option" slot-scope="props">
      <div class="option__desc">

              <span class="option__title">

                  <span><i class="ri-list-check color-primary" style="color:#5ad"></i></span>
                  <span> {{ props.option.name }} </span>

              </span>

      </div>
    </template>
  </multiselect>

</div>
  <!-- Navigation buttons -->
  <hr>

  <div class="d-flex justify-content-end" style="gap:5px">
    <button class="btn btn-light" @click="hideModal">cancel</button>
    <button class="btn btn-dark d-flex align-items-center " :disabled="loading.apply" @click="apply(true)">
      <b-spinner v-if="loading.apply" small type="grow"></b-spinner> Apply to all Sources <span v-if="loading.apply">...</span>
    </button>
    <button class="btn btn-primary d-flex align-items-center " :disabled="loading.apply" @click="apply(false)">
      <b-spinner v-if="loading.apply" small type="grow"></b-spinner> Apply to selected sources only <span v-if="loading.apply">...</span>
    </button>
  </div>
  </b-modal>
  </b-container>
</template>

<script>
import multiselect from "vue-multiselect";
import api from "@/api";
export default {
  name: "ApplySuppressionListToSourceModal",
  props:['list','account'],
  components:{
    multiselect
  },
  mounted() {

    this.fetchSources();

  },
  data(){
    return {
      sources:[],
      selected_sources:[],
      loading:{ source:false,apply:false}
    }
  },
  methods:{
    fetchSources(){
      api.geSources.getSourcesNames({params:{account:this.account}}).then((response)=>{
        this.sources = response.data;
      });
    },
    apply(apply_all_sources){


      api.suppressionList.attach({

        'id' : this.list.id,
        'sources' : this.selected_sources,
        'apply_all_sources' : apply_all_sources ?? false,
        'account_id' : this.account

      }).then((response)=>{

        this.hideModal();

      });
    },
    hideModal(){
      this.$refs['applySuppressionSourcedModal'].hide()
    },
    fetchPlatformImage(platform_name){
      try {
        return require(`@/assets/images/platforms/${platform_name.toLowerCase()}.png`)
      } catch (e) {
        return require(`@/assets/images/platforms/file-upload.svg`)
      }
    },
  },
}
</script>

<style scoped>

</style>