<template>
    <b-form @submit="handleSubmit">
        <b-row class="mb-3 px-3">

            <b-form-group class="col-md-12 my-2 px-1" label-for="name">
                <b-row align-h="between" class="px-3">
                    <label for="discount_status">Name: *</label>
                    <b-form-checkbox switch v-model="form.active" :disabled="form.active == -1">{{
                        form.active ? 'Active' : 'Inactive'
                    }}</b-form-checkbox>
                </b-row>
                <b-form-input placeholder="Enter Name" v-model="form.name" required></b-form-input>
                <div class="text-danger" id="error_name">{{ errors.name }}</div>
            </b-form-group>

            <b-form-group class="col-sm-12 my-2 px-1" label-for="roles" label="Role:">
                <multiselect v-model="form.roles" tag-placeholder="Add this as new role" placeholder="Select platform"
                    label="name" track-by="code" :options="roles" :multiple="true" :taggable="true"
                    :disabled="disabledRoles">
                </multiselect>
                <div class="text-danger" id="roles">{{ errors.roles }}</div>
            </b-form-group>

            <b-form-group class="col-md-12 my-2 px-1" label="Description: *" label-for="description">
                <b-form-textarea name="description" v-model="form.description" rows="3"></b-form-textarea>
                <div class="text-danger" id="error_name">{{ errors.description }}</div>
            </b-form-group>
        </b-row>

        <b-row class="pull-right px-0 mr-1">
            <b-button ref="btn-submit" variant="primary" type="submit" :disabled="onsubmit">
                <b-spinner small type="grow" v-show="onsubmit"></b-spinner>
                {{ submitLabel }}
            </b-button>
        </b-row>

    </b-form>
</template>

<script>
import { helper } from '@/helpers'
import api from '@/api/RestClient'
import _ from 'lodash'
import Vue from 'vue'
import moment from 'moment'
import Multiselect from "vue-multiselect"


export default {
    name: 'FormNotifType',
    props: {
        item: {
            type: [Object],
            default: null
        }
    },
    components: {
        Multiselect
    },
    mounted() {
        this.setForm();
        this.getRoles();
        this.setSubmitLabel();
        this.setItemRoles(this.item);
    },
    data() {
        return {
            submitLabel: '',
            onsubmit: false,
            roles: [],
            form: {
                active: true,
                code: null,
                name: null,
                description: null,
                roles: null,
            },
            param: { name: null, value: null },
            params: [],
            emailsOptions: [
                { name: 'Support', value: 'support' },
                { name: 'Tech', value: 'tech' },
                { name: 'Administration', value: 'administration' },
                { name: 'Reports', value: 'reports' },
            ],
            formInit: {},
            errors: {},
            disabledRoles: false
        }
    },
    methods: {
        setForm() {
            let form = this.getForm()
            if (form) {
                this.form = form
            }

            const that = this;

            this.formInit = Vue.util.extend({}, this.form);
        },
        getForm() {
            if (!this.item || typeof this.item == "undefined") {
                return null
            }

            let form = {
                code: this.item.id,
                name: this.item.name,
                description: this.item.description,
                active: this.item.active == 1 ? true : false,
            }
            let roles = this.item.roles.map((role) => {
                return {
                    code: role.id,
                    name: role.name,
                }
            });

            form.roles = roles;

            return form
        },
        setSubmitLabel() {
            this.submitLabel = 'Add Notification Type'
            if (this.item && (typeof this?.item !== 'undefined') && this.item?.id) {
                this.submitLabel = `Update Notification Type`
            }
        },
        getRoles() {
            api.roles.all().then(response => {
                this.roles = response.data;
            }).catch(err => {
                console.log(err);
            }).finally(() => {
                // here code ...
            })
        },
        emitHandleSubmit(data, isNew = true) {
            this.resetForm()
            this.$emit('handleSubmit', { ...data, new: isNew })
        },
        addNewItem() {
            let isValidForm = helper.isEmpty(this.errors);
            let data = this.form;

            const that = this;

            if (!isValidForm) {
                this.onsubmit = false;
                return;
            }

            // TODO
            api.notificationTypes.post(data)
                .then(response => {

                    if (response.success) {
                        this.emitHandleSubmit(response.data, true)
                    } else {
                        that.handleError(data)
                    }
                })
                .catch(errors => {
                    that.handleError(errors)
                })
                .finally(() => {
                    this.onsubmit = false;
                })
        },
        updateItem() {
            let isValidForm = helper.isEmpty(this.errors)
            const id = this.form.code
            let data = this.form
            const that = this

            if (!isValidForm) {
                this.onsubmit = false;
                return;
            }

            api.notificationTypes.put(id, data)
                .then(data => {
                    if ('success' in data) {
                        this.emitHandleSubmit(data.data, false)
                    } else {
                        that.handleError(data)
                    }
                })
                .catch(errors => {
                    that.handleError(errors)
                })
                .finally(() => {
                    this.onsubmit = false;
                })
        },
        resetErrors() {
            this.errors = {}
        },
        checkForm() {
            this.resetErrors();

            let nameLabel = 'Name';

            if (typeof this.form.name === 'string' && (!this.form.name || !this.form.name.trim())) {
                this.errors.name = nameLabel + ' is required.';
            }

            if (typeof this.form.name === 'object' && (!this.form.name.length)) {
                this.errors.name = nameLabel + ' is required.';
            }

        },
        handleSubmit(e) {
            e.preventDefault()

            this.onsubmit = true;
            this.checkForm();

            if (helper.isEmpty(this.errors)) {
                if (this.form.code) {
                    this.updateItem()
                } else {
                    this.addNewItem()
                }
            } else {
                // Can emit error
                this.onsubmit = false;
            }
        },
        handleError(errors) {
            this.resetErrors()
            const that = this

            errors = this.formatErrorResponse(errors)
            // Make loop
            _.forOwn(errors, function (value, key) {
                that.errors[`setting_${key}`] = errors[key][0]
            });

            this.$emit('handleSubmit', null)
        },
        formatErrorResponse(errors) {
            if (errors.response) {
                errors = errors.response.data.errors
            } else if (errors.request) {
                errors = errors.request
            } else {
                errors = errors.message;
            }
            return errors
        },
        resetForm() {
            this.form = {
                code: null,
                name: null,
                description: null,
                roles: null,
            }
        },
        setItemRoles(item) {
            if (item !== null && Array.isArray(item.roles)) {

                this.form.roles = item.roles.map((role) => {
                    return {
                        code: role.id,
                        name: role.name,
                    }
                })

                this.disabledRoles = true;
            }
        },
        deleteParam(e, ref) {
            document.getElementById(`param-${ref}`).remove()
            _.remove(this.params, function (param) {
                return param.name === ref;
            });
        }
    },
    computed: {
        isSameInit() {
            return _.isEqual(this.form, this.formInit);
        },
        emailTypeOptions() {
            let options = _.map(this.emailsOptions, (option) => {
                return option.value
            })
            return options
        }
    },
    watch: {
        "form": {
            handler(newVal, oldVal) {
                if (!this.isSameInit) {
                    this.$refs['btn-submit'].disabled = false
                } else {
                    this.$refs['btn-submit'].disabled = true
                }
            },
            deep: true
        },
    },
}
</script>