<template>
<div class="charts">
    <iq-card v-if="loading" class="d-flex justify-content-center py-5">
        <b-spinner small label="Small Spinner" type="grow"></b-spinner>
    </iq-card>
    <div v-else class="" id="events-chart" v-show="true">
        <iq-card>
            <template v-slot:headerTitle>
                <h4 class="header_title">{{ _title }}</h4>

            </template>
            <template v-slot:headerAction>
                <div class="range_picker">
                    <b-row class="mt-4">
                        <b-col md="4">
                            <b-form-group label="From" label-for="from">
                                <b-form-input id="from" type="date" v-model="form_dates.from" :max="form_dates.to" :value="form_dates.from"></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col md="4">
                            <b-form-group label="To" label-for="to">
                                <b-form-input id="to" type="date" v-model="form_dates.to" :min="form_dates.from" :max="form_dates.max" :value="form_dates.to"></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col md="4">
                            <b-form-group label="To" label-for="submit">
                                <b-button class="action" variant="primary" :disabled="submitload" @click="onSubmit">
                                    <b-spinner small type="grow" v-if="submitload"></b-spinner>
                                    Submit
                                </b-button>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </div>
            </template>
            <template v-slot:body>
                <div class="text-center">
                    <h3>Total Events</h3>
                    <h3 class="text-primary font-size-2em" v-show="!submitload"> {{ formatDecimal(statistic) }} </h3>
                    <b-spinner small type="grow" v-show="submitload"></b-spinner>
                </div>
                <div v-if="true">
                    <MorrisChart :element="type + 1" :type="type" :xKeys="xKeys" :data="bodyData" :colors="colors" :yKeys="yKeys" :labels="labels" :xLabels="xLabels" @onFinished="onFinished"/>
                </div>
                
            </template>
        </iq-card>
    </div>
</div>
</template>

<script>
import api from '@/api/RestClient'
import {
    helper
} from '@/helpers'

export default {
    name: 'events-chart',
    props: {
        'title': {
            type: String,
            default: "Total Events "
        },
        'options': {
            type: Object,
            default: () => ({
                "integration_id": -1,
                "from": 0,
                "to": 0
            })
        },
        'dates': {
            type: Object,
            default: () => ({
                "from": 0,
                "to": 0
            })
        }
    },
    data: () => ({
        loading: true,
        xLabels: 'day',
        progress: true,
        type: 'line',
        _title: '',
        bodyData: [],
        colors: ['#36A2EB'],
        xKeys: 'period',
        yKeys: ['a'],
        labels: ['Events'],
        form_dates: {
            from: null,
            to: null
        },
        submitload: false,
        statistic: 0,
        plage: '--',
        params: null
    }),
    watch: {
        begin() {
            this.loadStats(false)
        }
    },
    components: {},
    mounted() {
        this.loadStats(true);
        this.initDates();
        this.getOpenersStatsForAccountByDates();
        this.params = this.options;
    },
    created() {},
    methods: {
        loadStats(progress) {
            this.loading = true
            this.progress = progress;
            api.stats.getOpenersStats(this.options)
                .then(response => {

                    this.progress = false;
                    this.bodyData = [];

                    var dates = response.data.dates;

                    var bodies = [];

                    for (let index = 0; index < dates.length; index++) {
                        var date = {
                            a: 0,
                            period: dates[index]
                        }
                        if (response.data.chart.length > 0) {
                            response.data.chart.forEach(data => {
                                if (data.period == dates[index]) {
                                    date.period = data.period
                                    date.a = data.events
                                }
                            });

                        }

                        bodies.push(date)
                    }
                    this._title = 'Total Events' + ' from ' + dates[0] + ' to ' + dates[dates.length - 1];
                    this.bodyData = bodies;
                })
                .catch(err => {
                    this.progress = false;
                    console.log(err);
                })
                .finally(() => {
                    this.progress = false;
                    this.loading = false;
                })
        },
        getOpenersStatsForAccountByDates() {
            api.stats.getOpenersStatsForAccountByDates(this.form_dates).then(response => {
                this.statistic = response.data.total ? response.data.total : 0;
            }).catch(err => {
                console.log(err)
            }).finally(() => {
               
            })
        },
        onSubmit() {
            this.submitload = true;
            this.loading = true;
            this.options.from = this.form_dates.from
            this.options.to = this.form_dates.to
            this.plage = this.form_dates.from + ' to ' + this.form_dates.to
            this.loadStats(true);
            this.getOpenersStatsForAccountByDates();
        },
        initDates() {

            var date = helper.dateToYMD(new Date());
            var minDate = helper.dateToYMD(helper.AddOrSubractDays(new Date(), 6, false));

            this.form_dates.to = date;
            this.form_dates.from = minDate;
            this.form_dates.max = date;
        },
        formatDecimal(nStr) {
            nStr += '';
            var x = nStr.split('.');
            var x1 = x[0];
            var x2 = x.length > 1 ? '.' + x[1] : '';
            var rgx = /(\d+)(\d{3})/;
            while (rgx.test(x1)) {
                x1 = x1.replace(rgx, '$1' + ',' + '$2');
            }
            return x1 + x2;
        },
        onFinished() {
            this.submitload = false;
            this.loading = false;
        }
    }
}
</script>

<style>
.font-size-2em {
    font-size: 1.5em;
}
</style>
