<template>
    <div>
      <span :show="false" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
      <span class="sr-only">Loading...</span>
    </div>
</template>
<script>
export default {
  name: 'iq-spinner',
  props: {
  },
  mounted () {
  },
  computed: {
  }
}
</script>
