<template>
    <b-container fluid>

        <!-- Table title -->
        <b-row class="mb-3 mt-3">
            <b-col lg="6">
                <h4 class="card-title float-start">Live validation Lists</h4>
            </b-col>
        </b-row>

        <!-- User Interface controls -->
        <b-row class="mb-3">

            <b-col lg="4" class="my-1">
                <b-input-group size="md">
                    <b-form-input id="filter-input" v-model="filter" type="search" placeholder="Search"></b-form-input>
                </b-input-group>
            </b-col>

        </b-row>

        <!-- Main table element -->
        <b-table class="ig-text-wrap" ref="table" :id="id" striped bordered :responsive="$store.getters['Auth/isAdmin']"
            :busy="isBusy" :items="getData" :fields="fields" :current-page="currentPage" :per-page="perPage"
            :filter="filter" :filter-included-fields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection" stacked="md" show-empty small @filtered="onFiltered"
            @sort-changed="sortingChanged">

            <template #table-busy>
                <div class="text-center d-inline-block my-2">
                    <span class='fa-stack fa-lg'>
                        <i class='fa fa-spinner fa-spin fa-stack-2x fa-fw'></i>
                    </span>&emsp;Processing ...
                </div>
            </template>

            <template #empty="scope">
                <p class="mb-1">No matching records found</p>
            </template>

            <!-- Cell status -->
            <template #cell(status)="row">
                <span><i class="fa" v-b-tooltip.hover :title="statusClassText(row.item.status)" :class="statusClass(row.item.status)" aria-hidden="true"></i></span> 
            </template>

            <!-- Cell action -->
            <template #cell(action)="row">
                <span @click="download(row.item)" class="cursor-pointer" :disabled="isDisabledDownload(row.item.status)">
                    <i class="fa fa-download text-primary" v-b-tooltip.hover title="Download" aria-hidden="true" v-show="row.item.status != DOWNLOADING"></i>
                    <b-spinner small label="Small Spinner" type="grow" v-show="row.item.status == DOWNLOADING"></b-spinner>
                </span> 
            </template>

        </b-table>

        <!-- Data table pagination -->
        <b-row class="justify-content-between mt-3">
            <b-col md="9" class="my-1">
                <div class="dataTables_info">
                    <b-row class="dataTables_info justify-content-between" id="lists_info" role="status" aria-live="polite">
                        <b-col md="10" class="">
                            Showing {{ from }}
                            to {{ to }} of {{ totalRows }} entries
                        </b-col>
                        <b-col md="2" class="">
                            <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm">
                            </b-form-select>
                        </b-col>
                    </b-row>
                </div>
            </b-col>
            <b-col md="3" class="my-1">
                <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm"
                    class="my-0"></b-pagination>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import Spinner1 from '@/components/loaders/spinner1'
import store from '@/store/index'
import api from '@/api/RestClient'
import { helper } from '@/helpers'
import {
    mapGetters
} from "vuex";

export default {
    name: 'DtImport',
    components: {
        Spinner1,
    },
    props: {
        dates: {
            type: Object,
            default: () => {
                return { from: null, to: null }
            }
        },
        organization: {
            type: Object,
            default: () => {
                return {}
            }
        },
    },
    data() {
        return {
            id: 'dt-contacts',
            isBusy: false,
            items: [],
            fields: [
                {
                    key: 'list_name',
                    label: 'List Name',
                    sortable: true,
                    sortDirection: 'desc',
                    formatter: (value, key, item) => {
                        return value;
                    },
                },
                {
                    key: 'account',
                    label: 'Organization',
                    sortable: true,
                    sortDirection: 'desc',
                    class: 'text-center',
                    formatter: (value, key, item) => {
                        return value.name;
                    },
                },
                {
                    key: 'total_contacts',
                    label: 'Total contacts',
                    sortable: false,
                    class: 'text-center',
                    formatter: (value, key, item) => {
                        return this.formatDecimal(value);
                    },
                },
                {
                    key: 'total_verified',
                    label: 'Total verified',
                    sortable: false,
                    class: 'text-center',
                    formatter: (value, key, item) => {
                        return this.formatDecimal(value);
                    },
                },
                {
                    key: 'status',
                    label: 'Status',
                    sortable: true,
                    class: 'text-center',
                },
                {
                    key: 'created_at',
                    label: 'Date',
                    sortable: true,
                    class: 'text-center',
                    formatter: (value, key, item) => {
                        return this.formatDate(value);
                    }
                },
                {
                    key: 'action',
                    label: 'Actions',
                    sortable: true,
                    class: 'text-center'
                },
                
            ],
            totalRows: 1,
            currentPage: 1,
            perPage: 25,
            pageOptions: [25, 50, 100, 200, 500, {
                value: 10000,
                text: "Show a lot"
            }],
            sortBy: 'id',
            sortDesc: true,
            sortDirection: 'asc',
            filter: null,
            filterOn: ["list_name"],
            accounts: [],
            account: null,
            plans: [],
            from: null,
            to: null,
            $search: '',
            reportDays: null,
            groupByProduct: false,
            DOWNLOADING: 101
        }
    },
    computed: {
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return {
                        text: f.label,
                        value: f.key
                    }
                })
        },
        ...mapGetters('Auth', ['user']),
        ...mapGetters('Auth', ['isAdmin'])
    },
    mounted() {
        this.loadFields()
        if (!this.isAdmin) this.account = this.user.account_id;
    },
    methods: {
        formatDecimal(nStr) {
            nStr += '';
            var x = nStr.split('.');
            var x1 = x[0];
            var x2 = x.length > 1 ? '.' + x[1] : '';
            var rgx = /(\d+)(\d{3})/;
            while (rgx.test(x1)) {
                x1 = x1.replace(rgx, '$1' + ',' + '$2');
            }
            return x1 + x2;
        },
        moneyDefaultFormat(amount) {
            return amount.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        },
        setGroupByProduct(status) {
            this.groupByProduct = status;
        },
        sumInArrayByKey(items = [], key = 'events') {
            let sum = 0;
            items.forEach(item => {
                sum += item[key]
            });
            return sum;
        },
        eventCalculStats(array, key) {
            return this.sumInArrayByKey(array, key);
        },
        stringInArrayByKey(items = [], key = 'list_name') {
            let name = '';
            let i = 0;
            const length = items.length;
            items.forEach(item => {
                i++;
                name += i != length ? item[key] + ', ' : item[key];

            });
            return name;
        },
        formatDate(date) {
            var d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2)
                month = '0' + month;
            if (day.length < 2)
                day = '0' + day;

            return [year, month, day].join('-');
        },
        async getData(ctx) {
            await this.delay();
            return await this.fetchData(ctx);
        },
        async fetchData(ctx) {
            var items = []
            this.items = [];
            this.isBusy = true;
            let queryParams = {}
            if (typeof ctx !== "undefined") {
                queryParams = {
                    search: this.filter,
                    page: ctx.currentPage,
                    per_page: ctx.perPage,
                    sort_by: ctx.sortBy,
                    sort_desc: ctx.sortDesc,
                    filter_on: JSON.stringify(this.filterOn),
                    from: this.dates.from,
                    to: this.dates.to,
                    account: this.account ?  this.account : null,
                    plans: this.plans.length ? JSON.stringify(this.plans) : -1,
                    $search: this.search,
                    groupByProduct: this.groupByProduct
                }
            }
            return api.emailValidations.lists(queryParams)
                .then((response) => {

                    items = response.data.data;
                    const reportDays = response.data.days;
                    this.totalRows = response.data.total
                    this.currentPage = response.data.current_page
                    this.perPage = response.data.per_page
                    this.items = items
                    this.reportDays = reportDays != 0 ? reportDays : 1;
                    this.from = response.data.from
                    this.to = response.data.to
                    this.$emit('onFetchData', items);
                    
                    return items
                })
                .catch((err) => {
                    console.log(err)
                    return []
                })
                .finally(() => {
                    setTimeout(() => {
                        this.isBusy = false;
                        this.$root.$emit('updatedTable');
                    }, 400);
                })

        },
        delay: () => {
            return new Promise(resolve => setTimeout(resolve, 2000));
        },
        loadFields() {
            let fields = _.map(this.fields, (item) => {
                if (store.getters['Auth/isAdmin']) {
                    return item;
                } else
                    if (![
                        "account_id",
                        "billing_date",
                        "events_cycle",
                        "current_plan",
                        "time_restrictions",
                        "created_by",
                        "monthly_events",
                        "list_quality"
                    ].includes(item.key)) return item;
            })
            this.fields = fields;
        },
        setAccounts(account) {
            this.currentPage = 1
            this.account = account.code
            this.$root.$emit('bv::refresh::table', this.id)
        },
        setDates(dates) {
            this.dates = dates
            this.currentPage = 1
            this.$root.$emit('bv::refresh::table', this.id)
        },
        isDisabledDownload(status) {
            const FINISHED = 1;
            return ![FINISHED].includes(status)
        },
        // Filter
        onFiltered(filteredItems) {
        },
        sortingChanged(ctx) {
            this.currentPage = 1
        },
        statusClass(status) {
            let classe = "fa-spinner text-primary"
            switch (status) {
                case -2:
                    classe = "fa-times-circle-o text-danger"
                    break;
                 case -1:
                    classe = "fa-times-circle-o text-danger"
                    break;
                case 2: case 1:
                    classe = "fa-check text-success"
                    break;
            
                default:
                    break;
            }
            return classe;
        },
        statusClassText(status) {
            let label = "inprogress..."
            switch (status) {
                case -2:
                    label = "Error"
                    break;
                case -1:
                    label = "Cancelled"
                    break;
                 case 2: case 1:
                    label = "Finished"
                    break;

                default:
                    break;
            }
            return label;
        },
        download(item) {
            let currentStatus =item.status; 
            item.status  = this.DOWNLOADING;
            let listId = item.id;
            return api.emailValidations.generateCsv(listId)
                .then((response) => {
                    this.$bvToast.toast('Csv Downloaded successfully', {
                        title: 'Success',
                        variant: 'success'
                     });

                    var fileURL = window.URL.createObjectURL(new Blob([response]));
                    var fileLink = document.createElement('a');
                    var fileName = 'emails-validate-' + Date.now() + '.csv';

                    fileLink.href = fileURL;
                    fileLink.setAttribute('download', fileName);
                    document.body.appendChild(fileLink);

                    fileLink.click();
                 })
                .catch((error) => { 
                    this.$bvToast.toast(error.response.data.message, {
                        title: 'Create csv file',
                        variant: 'danger'
                    });
                    
                })
                .finally(() => { 
                    item.status = currentStatus;
                })
        }
    },
     watch: {
        "organization": {
            handler(newVal, oldVal) {
                this.setAccounts(newVal);
            }
        }
    }
}
</script>
