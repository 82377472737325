<style>
.organisation-name {
    color: red;
}
</style>
<template>
    <b-container fluid>
        <b-row>
            <b-col lg="12">
                <iq-card body-class="p-0">
                    <template v-slot:body>
                        <div class="iq-edit-list" v-on:click='switch_tab'>
                            <tab-nav :pills="true" class="iq-edit-profile d-flex">
                                <tab-nav-items class="col-md-6 p-0" :active="true" href="#personal-information" title="Personal Information" />
                                <tab-nav-items class="col-md-6 p-0" :active="false" href="#chang-pwd" title="Change Password" />
                            </tab-nav>
                        </div>
                    </template>
                </iq-card>
            </b-col>
            <b-col lg="12">
                <div class="iq-edit-list-data">
                    <tab-content id="pills-tabContent-2">
                        <tab-content-item :active="true" id="personal-information">
                            <b-row>
                                <b-col>
                                    <iq-card>
                                        <template v-slot:headerTitle>
                                            <h4 class="card-title">Personal Information</h4>
                                        </template>
                                        <template v-slot:body>
                                            <div class="view pb-4" style="text-align: justify;" v-if="!edit">
                                                <b-row class="billing-detail">
                                                    <div class="col-lg-6">
                                                        <p class="billing-detail-plan">First Name</p>
                                                        <span class="h5">{{ profile.first_name }}</span>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <p class="billing-detail-plan">Last Name</p>
                                                        <span class="h5">{{ profile.last_name }}</span>
                                                    </div>
                                                </b-row>
                                                <b-row class="billing-detail">
                                                    <div class="col-lg-6">
                                                        <p class="billing-detail-plan">Email Address</p>
                                                        <span class="h5">{{ profile.email }}</span>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <p class="billing-detail-plan">Address</p>
                                                        <span class="h5">{{ profile.address }}</span>
                                                    </div>
                                                </b-row>
                                                <b-button variant="primary" class="float-right"
                                                    @click='edit = true'>Edit</b-button>
                                            </div>
                                            <div class="edit" v-else>
                                                <b-form @submit.prevent="updateProfile" class="pb-4">
                                                    <b-row align-v="center">
                                                        <b-form-group class="col-sm-6" label="First Name:"
                                                            label-for="fname">
                                                            <b-form-input id="fname"
                                                                v-model='profile.first_name'></b-form-input>
                                                            <div class="text-danger" id="first_name">{{ errors.first_name }}
                                                            </div>
                                                        </b-form-group>
                                                        <b-form-group class="col-sm-6" label="Last Name:" label-for="lname">
                                                            <b-form-input id="lname"
                                                                v-model='profile.last_name'></b-form-input>
                                                            <div class="text-danger" id="last_name">{{ errors.last_name }}
                                                            </div>
                                                        </b-form-group>
                                                        <b-form-group class="col-sm-12" label="Email Address:"
                                                            label-for="lemail">
                                                            <b-form-input id="lemail"
                                                                v-model='profile.email'></b-form-input>
                                                            <div class="text-danger" id="email_address">{{
                                                                errors.email_address }}
                                                            </div>
                                                        </b-form-group>
                                                        <b-form-group class="col-sm-12" label="Address:">
                                                            <b-form-textarea name="address" style="line-height: 22px;"
                                                                rows="5" v-model='profile.address'>
                                                            </b-form-textarea>
                                                            <div class="text-danger" id="email_address">{{
                                                                errors.address }}
                                                            </div>
                                                        </b-form-group>
                                                    </b-row>
                                                    <b-button type="submit" variant="primary" class="float-right">Submit
                                                        <b-spinner small type="grow" v-show="update_spinner"></b-spinner>
                                                    </b-button>
                                                    <b-button variant="dark" class="float-right mr-2"
                                                        @click='edit = false'>Close</b-button>
                                                </b-form>
                                            </div>
                                        </template>
                                    </iq-card>
                                </b-col>
                            </b-row>


                        </tab-content-item>
                        <tab-content-item :active="false" id="chang-pwd">
                            <iq-card>
                                <template v-slot:headerTitle>
                                    <h4 class="card-title">Change Password</h4>
                                </template>
                                <template v-slot:body>
                                    <b-form @submit.prevent="resetPassword">
                                        <b-form-group>
                                            <label for="cpass">Current Password:</label>
                                            <b-form-input type="password" v-model="password.current_password" id="cpass"
                                                required>
                                            </b-form-input>
                                            <div class="text-danger" id="password">{{ errors.current_password }}</div>
                                        </b-form-group>
                                        <b-form-group>
                                            <label for="npass">New Password:</label>
                                            <b-form-input type="password" v-model="password.new_password" id="npass"
                                                required></b-form-input>
                                            <div class="text-danger" id="new_password">{{ errors.new_password }}</div>
                                        </b-form-group>
                                        <b-form-group>
                                            <label for="vpass">Verify Password:</label>
                                            <b-form-input type="password" v-model="password.verify_password" id="vpass"
                                                required>
                                            </b-form-input>
                                            <div class="text-danger" id="verify_password">{{ errors.verify_password }}</div>
                                        </b-form-group>
                                        <b-button type="submit" variant="primary" class="mr-2">Submit <b-spinner small
                                                type="grow" v-show="pass_spinner"></b-spinner>
                                        </b-button>
                                    </b-form>
                                </template>
                            </iq-card>
                        </tab-content-item>

                    </tab-content>
                </div>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import {
    sofbox
} from '../../config/pluginInit'
import api from '@/api/RestClient'
import {
    helper
} from '@/helpers'
export default {
    name: 'ProfileEdit',
    beforeCreate() {
        sofbox.openLoader();
    },
    mounted() {
        sofbox.index()
        this.profil();
    },
    updated() {
        this.errors = [];
    },

    data() {
        return {
            account: null,
            is_admin: null,
            profile_id: null,
            profile: {
                first_name: '--',
                last_name: '--',
                email: '--',
                address: '--',
            },
            errors: [],
            fails: false,
            success: false,
            edit: false,
            password: {
                current_password: '',
                new_password: '',
                verify_password: '',
            },
            update_spinner: false,
            pass_spinner: false,
            isAdmin: false
        }
    },
    methods: {
        switch_tab(e) {
            this.errors = [];
            this.success = false;
        },
        profil() {
            const user = this.$store.getters['Auth/user'];
            this.isAdmin = this.$store.getters['Auth/isAdmin'];
            this.account = '--';
            if (!this.isAdmin) {
                this.profile_id = user.profile_id;
                this.profile.first_name = user.profile.first_name;
                this.profile.last_name = user.profile.last_name;
                this.profile.email = user.email;
                this.profile.address = user.profile.address;
            }

        },
        update(id, data) {
            if (!this.isAdmin) {
                api.profiles.put(id, data).then(response => {
                    if (!response.success) {
                        response.data.forEach(error => {
                            this.toast(response.message, 'danger', error);
                        });
                        return 1;
                    }
                    // update user in store
                    this.$store.dispatch('Auth/updateUser', response.data)
                    this.toast('Updated!', 'success', response.message);
                }).catch(err => {
                    let errors = err.response.data.errors;
                    errors.forEach(error => {
                        this.toast('Oh!', 'danger', error);
                    });
                })
                    .finally(() => {
                        this.update_spinner = false;
                        this.delete_spinner = false;
                    })
            } else this.toast('Oh!', 'danger', 'Your are admin')
        },
        updateProfile() {
            this.checkUpdateForm();
            if (helper.isEmpty(this.errors)) {
                this.update_spinner = true;
                const profileId = this.profile_id;
                this.update(profileId, this.profile);
            }
        },
        resetPassword() {
            this.checkPassWordForm();
            if (helper.isEmpty(this.errors)) {
                this.pass_spinner = true;
                const data = this.password;
                api.profiles.reset_password(this.profile_id, data).then(response => {
                    if (response.success) {
                        // update user in store
                        this.toast('Success!', 'success', response.message);
                    } else {
                        response.data.map((error) => {
                            this.toast('oops ..', 'danger', error);
                        });
                    }
                }).catch(err => {
                    this.toast('Oh!', 'danger', 'Something went wrong')
                })
                    .finally(() => {
                        this.pass_spinner = false;
                    })
            }
        },
        resetErrors() {
            this.errors = {}
        },
        checkUpdateForm() {
            this.resetErrors();
            if (!this.profile.first_name) {
                this.errors.first_name = 'First name required.';
            }
            if (!this.profile.last_name) {
                this.errors.last_name = 'Last name required.';
            }
            if (!this.profile.email) {
                this.errors.email_address = 'Email required.';
            }
            if (!this.profile.address) {
                this.errors.address = 'Address field required.';
            }

            if (!helper.validMail(this.profile.email)) {
                this.errors.email_address = 'Email format did not matched.';
            }

        },
        checkPassWordForm() {
            this.resetErrors();
            if (!this.password.current_password) {
                this.errors.current_password = 'Current password required.';
            }
            if (!this.password.new_password) {
                this.errors.new_password = 'New password required.';
            }
            if (!this.password.verify_password) {
                this.errors.verify_password = 'Repeat password required.';
            }
            if (helper.checkStringLegth(this.password.new_password, 6)) {
                this.errors.new_password = 'Password must be at least 6 characters';
            }
            if (!helper.equal(this.password.new_password, this.password.verify_password)) {
                this.errors.verify_password = 'Password did not matched';
            }
        },
        toast(title = "success", variant = "success", message = "Account chargerd") {
            this.$bvToast.toast(message, {
                title: title,
                variant: variant
            })
        }
    },
    computed: {
        verifypassword() {
            return this.password.new_password != this.password.verify_password;
        }
    }
}
</script>
