<template>
<div>

  <beehiiv-custom-fields-input
      :customFields="platformLists"
      :list="selectedList"
      @customFieldSelected="updateCustomField"
  ></beehiiv-custom-fields-input>
</div>
</template>
<script>

import BeehiivCustomFieldsInput from "@/components/inboxgeek/fileds/BeehiivCustomFieldsInput";
import Multiselect from "vue-multiselect";
import {services} from "@/helpers";
import api from "@/api";
import _ from "lodash";
import {PLATFORMS_ITEMS} from "@/constantes";
export default {
  name:'BeehiivUpdateListField',
  props:['lists','platform_id'],
  components: {Multiselect, BeehiivCustomFieldsInput},

  mounted() {
    this.fetchItems();
  },
  data(){
    return {
      isLoading:false,
      selectedList:null,
      platformLists:[]
    }
  },
  methods:{
    fetchItems() {
      this.listdisabled = true;
      this.isLoading = true;

      api.platforms.fetchServices({
        "platform_name" : "beehiiv",
        "entities"      : 'lists',
        "platform_id"   : this.platform_id
      })
          .then((result) => {
            if (result.success && result.data) {
              this.platformLists = result.data.map((customField)=>{
                return {
                  code:customField.id,
                  name:customField.name
                }
              });

              let list = this.lists[0].list_id;

              list = list.split('[');

              result.data.map((customField)=>{

                if( customField.name === list[0] ){
                  this.selectedList = customField;
                  this.selectedList['value'] = list[1]?.replace(']','');
                }
              })

            }
          })
         .catch(function (error) {

          console.log(error);

        }).finally(() => {
          this.listdisabled = false;
          this.isLoading = false;
        })
    },
    updateCustomField(customField){


      this.$emit('listSelected', [{
        code: customField['name'] + '[' + customField['code'] + ']',
        name:customField['name'],
        'type': 'list'
      }] );

    }

  },
  computed:{

  },


}
</script>