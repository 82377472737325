<template>
  <div class="text-center">

      

      <h1 class="mt-3 mb-3"> <img :src="icon.src" :width="icon.width" class="px-1 mx-2" alt=""/>{{ result }}</h1>
      <p>{{ message }}</p>
      <div class="d-inline-block w-100">
  
          <a class="btn btn-primary mt-3" href="/"><i class="ri-home-4-line"></i>Go To Dashboard</a>
      </div>
  </div>
  </template>
  
    
  <script>
  export default {
      name: 'PaymentResponse',
      data() {
          return {
              result  : 'failed',
              message : '',
              icon    : {src : "",width : 60 },
              invoice_id : '',
          }
      },
      mounted() {
       
      

        switch (this.$route.params.status) {
          case "failed":

            this.result       = "failed";
            this.message      = "The payment did not processed correctly please try again, or contact support for help."  
            this.icon.src     = "/img/img-failed.png";
            this.icon.width   = 50;
            break;

          case "success":
            
            this.result     = "success";
            this.message    = "The payment has successfully processed, Thank you!."
            this.icon.src   = "/img/img-success.c151c48f.png";
            break;
        
        
          default:
            this.result = "";
            this.message = ""
            break;
        }

       
      }
  }
  </script>
  