<template>
    <fieldset v-if="platform" class="text-right list-fieldset">

        <div v-if="platform.code == platform_codes.WEBHOOK || platform.code == platform_codes.INBOXSUITE || platform.code == platform_codes.IBG_ESP "
            class="form-card text-left">
            <StepTitle :title="'List informations:'" :steps="'Step 2 - 4'" />

            <UploadFileFiled :list="list" :platform="platform" @uploadedList="uploadedList"></UploadFileFiled>


        </div>

        <div v-else-if="platform.code == platform_codes.BEEHIIV" class="form-card text-left">
            <StepTitle :title="'List informations:'" :steps="'Step 2 - 4'" />

            <BeehiivCustomFieldsInput :list="list" :customFields="lists" :platform="platform"
                @customFieldSelected="customFieldSelected"></BeehiivCustomFieldsInput>


        </div>

        <div v-else-if="platform.code == platform_codes.ONGAGE">
            <StepTitle :title="'List informations:'" :steps="'Step 2 - 4'" />
            <ListStepOngage :lists="lists" :oAccountId="oAccountId" :auth2="auth2" :platform="platform"
                @listSelected="listSelected" @segmentSelected="segmentSelected"></ListStepOngage>
        </div>

        <div v-else-if="platform.code == platform_codes.MAILCHIMP">
            <StepTitle :title="'List informations:'" :steps="'Step 2 - 4'" />
            <ListStepMailchimp :lists="lists" :oAccountId="oAccountId" :auth2="auth2" :platform="platform"
                @listSelected="listSelected" @segmentSelected="segmentSelected"></ListStepMailchimp>
        </div>

        <div v-else-if="platform.code == platform_codes.BLASTABLE">
            <StepTitle :title="'List informations:'" :steps="'Step 2 - 4'" />

            <ListStepBlastable :lists="lists" :oAccountId="oAccountId" :platform="platform" :platform_id="platform.p_id"
                @listSelected="listSelected" @segmentSelected="segmentSelected"></ListStepBlastable>
        </div>

        <div v-else-if="platform.code == platform_codes.OMNISEND">
            <StepTitle :title="'Tag informations:'" :steps="'Step 2 - 4'" />
            <div class="mb-3">
                <multiselect id="range-1" v-model="list" tag-placeholder="Add this as new tag" placeholder="Add Tags"
                    label="name" track-by="code" :options="lists" :multiple="true" :taggable="true" :allow-empty="false"
                    :loading="omnisend_isLoading" @tag="tagInput"></multiselect>
                <p class="text-dark alert alert-warning my-2" style="padding: 1rem;">
                    Please type into the field the name of your InboxGeek tag that already exists in Omnisend, then press 'ENTER'
                </p>
            </div>
        </div>

        <div v-else class="form-card text-left">
            <StepTitle :title="'List informations:'" :steps="'Step 2 - 4'" />
            <b-row>
                <b-form-group :label="listLabel" label-for="list" class="col-md-12">

                    <multiselect v-model="list" tag-placeholder="Empty" :placeholder="listPlaceholder" label="name"
                        track-by="code" :options="lists" :multiple="true" return="code" :allow-empty="true"
                        :taggable="true" :disabled="listdisabled" @select="handleSelectListOption"
                        @remove="handleSelectListOption">

                        <template slot="option" slot-scope="props">
                            <div class="option__desc">

                                <span class="option__title">

                                    <span v-if="props.option.type">

                                        <i v-if="props.option.type == 'segment'" class="ri-flashlight-line"
                                            style="color:#5ad"></i>
                                        <i v-else class="ri-list-check color-primary" style="color:#5ad"></i>

                                    </span>

                                    <span v-else><i class="ri-list-check color-primary" style="color:#5ad"></i></span>

                                    <span style="font-wight:bold;"> {{ props.option.name }} </span>

                                    <span style="font-size:11px; color:#888"> {{ props.option.code }}</span>


                                </span>

                            </div>

                        </template>

                    </multiselect>
                    <div v-if="platform.code == platform_codes.ONTRAPORT" class="alert alert-warning my-2"
                        style="padding: 1rem;">
                        <p class="m-0 text-dark">InboxGeek cannot recognize contact statuses of 'transactional only' and
                            'hard
                            bounced'. In your
                            Ontraport account, add a condition to your group to exclude all Bulk Email Statuses that
                            contain
                            'transactional only' and 'hard bounced'. Without this condition, InboxGeek will send an
                            event
                            for that contact but an email will not be triggered by Ontraport. </p>
                    </div>
                    <small v-else id="alertLists" class="text-danger"></small>
                </b-form-group>
            </b-row>
        </div>

        <b-button name="next" variant="primary" class="next action-button float-right" value="Next"
            @click="handleNextStep($event)">
            <b-spinner small type="grow" v-show="nextSpinner"></b-spinner>
            Next
        </b-button>
        <b-button ref="previous" name="previous" variant="dark" class="previous action-button-previous float-right mr-3"
            value="Previous" @click="handlePreviousStep($event)">Previous</b-button>
    </fieldset>
</template>
<script>
import api from '@/api/RestClient';
import Multiselect from 'vue-multiselect'
import UploadFile from '@/components/inboxgeek/UploadFile'
import ListStepMailchimp from '@/components/inboxgeek/modals/IntgrationModal/ListStepMailchimp.vue'
import ListStepOngage from '@/components/inboxgeek/modals/IntgrationModal/ListStepOngage.vue'
import ListStepBlastable from '@/components/inboxgeek/modals/IntgrationModal/ListStepBlastable.vue'
import BeehiivCustomFieldsInput from '@/components/inboxgeek/fileds/BeehiivCustomFieldsInput';
import { PLATFORMS_CODE } from "@/constantes";
import StepTitle from "@/views/Integration/modals/children/StepTitle.vue";
import UploadFileFiled from "@/components/inboxgeek/fileds/UploadFileFiled";
import _ from 'lodash';

export default {
    name: 'StepList',
    components: {
        StepTitle,
        Multiselect,
        ListStepMailchimp,
        ListStepOngage,
        ListStepBlastable,
        UploadFile,
      UploadFileFiled,
      BeehiivCustomFieldsInput
    },
    props: {
        platform: {
            default: () => null,
        },
        listProp: {
            type: [Array],
            default: () => null,
        },
        listsProp: {
            type: [Array],
            default: () => null,
        },
        oAccountId:{
            default: () => null,
        },
        auth2:{
            default: () =>null
        },
    },
    data() {
        return {
            platform_codes: PLATFORMS_CODE,
            step: 1,
            listLabel: 'List: *',
            listPlaceholder: 'Select Lists',
            listdisabled: true,

            list: null,
            lists: [],
            omnisend_tag: [],
            omnisend_tags: [],
            omnisend_isLoading:false,
            uploadFile: "",
            nextSpinner: false,
            prevSpinner: false,
        }
    },
    methods: {
      customFieldSelected(custom_field){
        if(custom_field){
          this.list = [ {
            code : custom_field.code,
            name : custom_field.name
          }];
        }
      },
        uploadedList(lists) {

            if(lists[0]){

                if(lists[0].code){
                  this.list = lists.map(list=>{  return {'code' : list.code , 'name' : list.name};} );
                }

            }

        },
        customLabel({ name, code, type }) {

            if (type) {
                return `${name} - [${code}] `;
            } else {
                return `${name} - [${code}]`;
            }

        },
        initStep() {

            if (typeof this.listProp !== "undefined") this.list = this.listProp
            if (typeof this.listsProp !== "undefined") this.lists = this.listsProp
        },
        cleanForm() {
            this.list = []
            this.lists = []
        },
        handleNextStep($event) {
            this.nextSpinner = true;
            this.$emit('onNext', { btn: $event.target, step: this.step, compStep: this })
        },
        handlePreviousStep($event) {
            this.$emit('onPrevious', { btn: $event.target, step: this.step, compStep: this })
        },
        handleSelectListOption(itemSelected) {
            this.$emit('onSelectedListOption', this.list)
        },

        setLists(items) {

            if (Array.isArray(items)) this.lists = items
        },
        listSelected(list){

            if(list){
                this.list = [{
                'code' : list.code,
                'name' : list.name,
                'type'  : list.type ?? 'list'
                }];
            }else{
                this.list = [];
            }
        },
        tagInput(tag) {

             if (tag) {
                this.omnisend_isLoading = true
                let code = tag;
                let platform_id = this.platform.p_id
                api.integrations.checkOmniTag(code, platform_id).then(response => {
                    let total = response.data.length;
                    if (total == 0) {
                        this.$swal('Error tag', `${tag ?? 'undefined'} tag doesn't exist`, 'error')
                    } else {
                        this.omnisend_tag.push({
                            'code': tag,
                            'name': tag,
                            'type': 'list'
                        });
                        this.list = this.omnisend_tag;
                    }
                    this.omnisend_isLoading = false;
                }).catch((error) => {
                    console.log(error);
                    this.$bvToast.toast(`Something want wrong`, {
                        title: 'Error',
                        variant: 'danger'
                    });
                    this.omnisend_isLoading = false;
                }).finally(() => {
                    //this.omnisend_isLoading = false;
                })
            } else {
                this.list = [];
            }
        },
        segmentSelected(segments,list){

            if(list){

                this.list = segments.map((segment)=>{
                    console.log(segment.code +'-'+list.code);
                    let list_or_segment_id = segment.code;
                    if(this.platform.code == this.platform_codes.MAILCHIMP || this.platform.code == this.platform_codes.ONGAGE) {
                        list_or_segment_id = segment.code +'-'+list.code;
                    }

                    return {
                        'code' : list_or_segment_id,
                        'name' : segment.name,
                        'type' : 'segment'
                    };
                })

            }else{
                this.list = [];
            }


        },
    },
    watch: {
        "form.platform": {
            handler(newValue, oldValue) {
                console.log(oldValue, newValue)
            }
        },
    },
    mounted() {
        this.initStep()
    },
}
</script>
