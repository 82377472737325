<template>
<b-container fluid>
    <b-row>
        <b-col lg="10" offset-md="1">
            <iq-card>
                <template v-slot:body>
                     <b-row>
                            <b-col cols="12" align-self="center">
                                <div class="mb-0 float-left">
                                    <b-button variant="link" @click="$router.go(-1)"><i class="fa fa-arrow-left" aria-hidden="true"></i>{{ back.title }}</b-button>
                                </div>
                            </b-col>
                        </b-row>
                    <b-row>
                        <b-col cols="12" align-self="center">
                            <h4 class="mb-0 text-center">{{ title }}</h4>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col v-show="!isLoad" lg="12" @mouseover="onHover">
                            <div class="table-responsive-sm">
                                <b-table :items="invoice.order">
                                     <template v-slot:cell(invoiceDate)="data">
                                        <p>{{data.value}}</p>
                                    </template>
                                    <template v-slot:cell(invoiceStatus)="data">
                                        <span class="span" :class="statusClass(data.value)">{{ statusTitle(data.value) }}</span>
                                        <span class="span" :class="statusClass(1)" v-if="INVOICES_CAN_PAID.includes(data.value)">
                                            <b data-toggle="tooltip" data-placement="top" title="" data-original-title="Generate checkout link" class="cursor-pointer-ek ml-1">
                                                <i class="fa fa-link" v-show="!checkout" @click="checkoutSessionLink(data)"></i>
                                                <span role="status" class="spinner-grow spinner-grow-sm" v-show="checkout">
                                                    <span class="sr-only">Small Spinner</span>
                                                </span>
                                            </b>
                                        </span>
                                        <span class="span" :class="statusClass(data.value)" v-if="INVOICES_CAN_PAID.includes(data.value)">
                                            <b data-toggle="tooltip" data-placement="top" title="" data-original-title="Recharge"
                                                class="cursor-pointer-ek ml-1">
                                                <i class="fa fa-refresh" v-show="!forcePaySpinner" @click="payInvoice(data)"></i>
                                                <span role="status" class="spinner-grow spinner-grow-sm" v-show="forcePaySpinner">
                                                    <span class="sr-only">Small Spinner</span>
                                                </span>
                                            </b>
                                        </span>
                                    </template>
                                    <template v-slot:cell(client)="data">
                                        <p v-html="data.value"></p>
                                    </template>
                                    <template v-slot:cell(inboxgeek)="data">
                                        <p v-html="data.value"></p>
                                    </template>
                                </b-table>
                            </div>
                        </b-col>
                    </b-row>
                    <b-row v-show="!isLoad">
                        <b-col lg="12">
                            <h5>{{ invoice.summary }}</h5>
                            <div class="table-responsive-sm">
                                <b-table-simple striped class="text-center">
                                    <thead>
                                        <tr>
                                            <th v-for="(item,index) in invoice.orderSummaryFields" :key="index" :class="item.key === 'item' ? 'text-left' : ''">{{ item.label }}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(body, bodyKey) in invoice.orderSummary" :key="bodyKey">
                                            <template v-for="(item,index) in invoice.orderSummaryFields">
                                                <th v-if="item.key === 'id'" :key="item.key+index">{{ body[item.key] }}</th>
                                                <td v-else-if="item.key === 'item'" :key="item.key+index" class="text-left">
                                                    <h6 class="mb-0">{{ body[item.key].title }}</h6>
                                                    <p class="mb-0">{{ body[item.key].description }}</p>
                                                </td>
                                                <td v-else-if="item.key === 'action' && INVOICES_CAN_UPDATE.includes(body.status) && body.id != '--'" :key="item.key+index" class="text-center">
                                                    <span class="span" :class="statusClass(COLOR_FAILED)">
                                                        <b data-toggle="tooltip" data-placement="top" title="" data-original-title="Remove item"
                                                            class="cursor-pointer-ek ml-1" @click="removeItem(body)">
                                                            <i class="fa fa-minus i-class"></i>
                                                        </b>
                                                    </span>
                                                    <span class="span" :class="statusClass(COLOR_SUCCESS)">
                                                        <b data-toggle="tooltip" data-placement="top" title="" data-original-title="Edit item"
                                                            class="cursor-pointer-ek ml-1" @click="editItem(body)">
                                                            <i class="fa fa-edit i-class"></i>
                                                        </b>
                                                    </span>
                                                </td>
                                                <td v-else :key="item.key+index" :class="item.key === 'total' ? 'font-weight-bold' : ''">
                                                    {{ body[item.key] }}
                                                </td>
                                            </template>
                                        </tr>
                                    </tbody>
                                </b-table-simple>
                                <span class="span mt-2" :class="statusClass(1)" v-if="isAdmin">

                                    <b data-toggle="tooltip" data-placement="top" title="" data-original-title="Add new item"
                                        class="cursor-pointer-ek ml-1" @click="manageItem" v-if="INVOICES_CAN_UPDATE.includes(invoice.order[0].invoiceStatus)">
                                        <i class="fa fa-plus i-class"></i>
                                    </b>
                                </span>
                                <span>
                                    <b-spinner small label="Small Spinner" type="grow" v-show="onDelete"></b-spinner>
                                </span>
                            </div>
                            <h5>{{ invoice.detail }}</h5>
                            <div class="table-responsive-sm">
                                <b-table-simple striped>
                                    <thead>
                                        <tr>
                                            <th v-for="(item,index) in invoice.InvoiceDetailField" :key="index" :class="item.key === 'bank' ? 'text-left' : ''">{{ item.label }}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(body, bodyKey) in invoice.InvoiceDetails" :key="bodyKey">
                                            <template v-for="(item,index) in invoice.InvoiceDetailField">
                                                <th v-if="item.key === 'bank'" :key="item.key+index">{{ body[item.key] }}</th>
                                                <td v-else :key="item.key+index" :class="item.key === 'total' ? 'font-weight-bold' : ''">
                                                    {{ body[item.key] }}
                                                </td>
                                            </template>
                                        </tr>
                                    </tbody>
                                </b-table-simple>
                            </div>
                        </b-col>
                        <b-col offset="6" cols="6" class="text-right">
                            <b-button variant="link mr-3" @click="downloadInvoice">
                                <i class="ri-download-line"></i>
                                Download <b-spinner v-show="downloadSpinner" small type="grow"></b-spinner>
                            </b-button>
                            <b-button variant="primary" v-show="false">
                                Submit
                            </b-button>
                        </b-col>
                        <b-col class="mt-3">
                            <b class="text-danger">Note</b>
                            <p v-if="!isNoteEdit">{{ invoice.note }} <span class="span badge cursor-pointer badge-secondary badge-pill iq-bg-success text-center ml-1" v-if="isAdmin && INVOICES_CAN_UPDATE.includes(invoice.order[0].invoiceStatus)" @click="isNoteEdit=true"><i style="display:block; margin:auto" class="fa fa-pencil i-class"></i></span> </p>
                            <p v-else><Note :note="invoice.note == '--' ? '' : invoice.note" @onSaveNote="saveNote" ref="invoice-note"/></p>

                        </b-col>
                    </b-row>
                    <b-modal id="modal-add-item" ref="manageItem" :title="item.title" :hide-footer=true @hide="beforeClose(event)">
                        <Item :title="item.title" :invoiceId="invoiceId" :itemId="item.id" :item="item.detail" @onSavedItem="invoiceUpdated" @onFinishUpdate="finishUpdateItem" ref="item"></Item>
                    </b-modal>
                </template>
            </iq-card>
        </b-col>
    </b-row>
</b-container>
</template>

<script>
import {
    sofbox
} from '../../config/pluginInit'
import api from '@/api/RestClient'
import Loader from '@/components/inboxgeek/Loader'
import Item from './children/item.vue'
import Note from './children/note.vue'
import { INVOICE_STATUS } from "../../constantes";
import { helper } from '@/helpers'
import { BILLING_COUNTRIES } from '@/countries';

export default {
    name: 'Invoice',
    mounted() {
        sofbox.index()
        if (this.$store.getters['Auth/isAdmin']) {
            this.isAdmin = true;
            this.back.title = 'Return to invoices list';
        };
    },
    created() {
        this.getInvoice();
    },
    components: {
        Item,
        Note,
        Loader,
    },
    data() {
        return {
            title: 'Invoice ...',
            invoice: {
                paymentStatusLabel: 'Paid',
                summary: 'Invoice Summary',
                detail: 'Invoice Detail',
                description: 'This invoice has paid.',
                order: [{
                    invoiceDate: '--',
                    invoiceStatus: 1,
                    invoiceID: '--',
                    client: '',
                    inboxgeek: '',
                }],
                InvoiceDetailField: [{
                        key: 'dueDate',
                        label: 'Due Date',
                        class: 'text-center'
                    },
                    {
                        key: 'subTotal',
                        label: 'Sub-total',
                        class: 'text-center'
                    },
                     {
                        key: 'total_tax_gst',
                        label: 'Total Tax GST',
                        class: 'text-center'
                    },
                    {
                        key: 'total_tax_qst',
                        label: 'Total Tax QST',
                        class: 'text-center'
                    },
                    {
                        key: 'refund',
                        label: 'Total refund',
                        class: 'text-center'
                    },
                    {
                        key: 'total',
                        label: 'Total',
                        class: 'text-center'
                    },
                ],
                InvoiceDetails: [{
                    dueDate:    '--',
                    subTotal:   '$0',
                    total_tax_gst: '$0',
                    total_tax_qst: '$0',
                    subTotal:   '$0',
                    discount:   '0%',
                    refund:     '$0',
                    total:      '$0'
                }],
                orderSummaryFields: [{
                        key: 'id',
                        label: '#'
                    },
                    {
                        key: 'item',
                        label: 'Item'
                    },
                    {
                        key: 'quantity',
                        label: 'Quantity'
                    },
                    {
                        key: 'price',
                        label: 'Price'
                    },
                    {
                        key: 'discount',
                        label: 'Discount'
                    },
                    {
                        key: 'tax_qst',
                        label: 'Tax QST',
                    },
                    {
                        key: 'tax_gst',
                        label: 'Tax GST',
                    },
                    {
                        key: 'total',
                        label: 'Total'
                    },
                ],
                orderSummary: [{
                    id: '',
                    item: {
                        title: '',
                        description: ''
                    },
                    quantity: 1,
                    price: 0,
                    discount: 0,
                    tax_qst: 0,
                    tax_gst: 0,
                    total: 0,
                    status: 0
                }],
                note: '--',
                sub_total: 0,
                total: 0,
            },
            invoiceId: this.$route.params.id,
            downloadSpinner: false,
            isLoad: true,
            checkout: false,
            forcePaySpinner: false,
            onDelete: false,
            item: {
                title: 'New item',
                detail:null,
                id: null
            },
            back: {
                title: 'Return to billing dashboard',
            },
            isAdmin: false,
            INVOICES_CAN_UPDATE: [-1, -3, 0],
            INVOICES_CAN_PAID: [-1, -3, 0],
            COLOR_FAILED: -1,
            COLOR_SUCCESS: 1,
            isNoteEdit: false,
            total_tax_gst: 0,
            total_tax_qst: 0,
            is_taxable: true
        }
    },
    methods: {
        onHover() {
            $("[data-toggle=tooltip]").tooltip();
         },
        sumInArrayGivenKey($array, key) {
            if(!key || !$array) return 0;
        },
        getInvoice() {
            const id = this.invoiceId;
            this.invoice.total = 0;
            this.invoice.sub_total = 0;

            api.invoices.get(id).then(response => {
                let invoice = response.data
                invoice.forEach(data => {
                    let compagny = data.business;
                    let client = data.account;
                    let items = data.items
                    let invoiceRow = {};
                    this.is_taxable = ['CA'].includes(client.country) ? true : false
                    /**
                     * invoice sumary
                     */
                    if(items.length != 0) this.invoice.orderSummary = [];
                    items.forEach(item => {
                        invoiceRow = {
                            id: data.id,
                            item: {
                                title: item.name,
                                description: item.description,
                                item_id: item.item_id
                            },
                            quantity: item.quantity,
                            price: helper.moneyDefaultFormat(item.price),
                            discount: helper.moneyDefaultFormat(item.discount),
                            tax_gst: helper.moneyDefaultFormat(item.tax_gst),
                            tax_qst: helper.moneyDefaultFormat(item.tax_qst),
                            total: helper.moneyDefaultFormat(parseFloat(item.total_numeric)),
                            status: data.status
                        };
                        this.total_tax_gst += item.tax_gst_value;
                        this.total_tax_qst += item.tax_qst_value;

                        this.invoice.sub_total += item.sub_total_numeric;
                        this.invoice.total += item.total_numeric;
                        this.invoice.orderSummary.push(invoiceRow);
                    });
                    this.invoice.order[0].invoiceID = data.id
                    this.invoice.order[0].invoiceDate = data.date
                    this.invoice.order[0].invoiceStatus = data.status

                    let address = client.address ?? '';
                    let country = client.country ? ', ' + this.getBillingCountries(client.country) : '';
                    let state = client.state ? ', ' + client.state : '';
                    let city = client.city ? ', ' + client.city : ''
                    let postal_code = client.postal_code ? ', ' + client.postal_code : ''

                    this.invoice.order[0].client = client.name + ' <br>' + client.principal_user + '<br> Email: ' + client.email + '<br> Address: ' + address + state + city + country;
                    this.invoice.order[0].inboxgeek = compagny.name + '<br> Email: ' + compagny.email + ' <br> Address: ' + compagny.address_line1 + ' <br>' + compagny.address_line2;

                    /**
                     * invoice total
                     */
                    this.invoice.InvoiceDetails[0].dueDate = data.due_date
                    this.invoice.InvoiceDetails[0].subTotal = helper.moneyDefaultFormat(this.invoice.sub_total)
                    this.invoice.InvoiceDetails[0].refund = '-' + helper.moneyDefaultFormat(data.total_refund)

                    this.invoice.InvoiceDetails[0].total_tax_gst = helper.moneyDefaultFormat(this.total_tax_gst)
                    this.invoice.InvoiceDetails[0].total_tax_qst = helper.moneyDefaultFormat(this.total_tax_qst)
                      
                    this.invoice.InvoiceDetails[0].total = helper.moneyDefaultFormat(data.amount)

                    /**
                     * note
                     */
                    this.invoice.note = data.note ?? '--'
                    /**
                     * title
                     */
                    this.title = 'Invoice ' + data.id
                });

            }).catch(err => {
                this.$bvToast.toast(err.response.data.message, {
                    title: 'Error',
                    variant: 'danger',
                });
            }).finally(() => {
                this.isLoad = false;
                this.loadFields();
            })
        },
        loadFields() {
           const actionColumn =  {
                key: 'action',
                label: 'Action'
            }
            var action = _.filter(this.invoice.orderSummaryFields, function (o) {
                if (o.key == "action") return o;
            });
            if (action.length == 0
                && [0,-1].includes(this.invoice.order[0].invoiceStatus)
                && this.isAdmin) {
                this.invoice.orderSummaryFields.push(actionColumn);
            }
        },
        downloadInvoice() {
            this.downloadSpinner = true;
            api.invoices.download(44, this.invoiceId).then(response => {
                var fileURL = window.URL.createObjectURL(new Blob([response]));
                var fileLink = document.createElement('a');
                var fileName = 'invoice-' + Date.now() + '.pdf';

                fileLink.href = fileURL;
                fileLink.setAttribute('download', fileName);
                document.body.appendChild(fileLink);

                fileLink.click();
            }).catch(err => {
                const message = err.response.data.message ?? 'Something went wrong';
                this.$bvToast.toast(message, {
                    title: 'Warning',
                    variant: 'warning'
                });
            }).finally(() => {
                this.downloadSpinner = false;
            })
        },
        statusClass(status) {
            return INVOICE_STATUS.find(x => x.code == status).class;
        },
        statusTitle(status) {
            return INVOICE_STATUS.find(x => x.code == status).name;
        },
        checkoutSessionLink(item) {
            this.$swal({
                title: 'Generate a checkout session?',
                type: 'warning',
                text: 'This action will generate a checkout session to allow you to pay your bill',
                showCancelButton: true,
                confirmButtonText: 'Credit Card',
                cancelButtonText: 'PayPal',
                showCloseButton: true,
                showLoaderOnConfirm: true,
                closeOnClickOutside: false
            }).then((result) => {
                if (result.value) {
                    this.payByStripeCheckout(this.invoiceId)
                } else {
                    if(result.dismiss == 'cancel') this.payByPayPalCheckout(this.invoiceId)
                }
            })
        },
        payInvoice(item) {
            this.$swal({
                title: 'Are You Sure?',
                type: 'warning',
                text: 'This action will charge the current card on file for the amount listed on this invoice.',
                showCancelButton: true,
                confirmButtonText: 'Yes, Recharge',
                cancelButtonText: 'Cancel',
                showCloseButton: true,
                showLoaderOnConfirm: true
            }).then((result) => {
                if (result.value) {
                    item.value = 102;
                    this.rechargeInvoice(item)
                }
            })
        },
        payByStripeCheckout(invoiceId) {
            this.checkout = true;
            api.invoices.payByClient(this.invoiceId).then((response) => {
                window.open(
                    response.data.link,
                    '_blank' // <- This is what makes it open in a new window.
                );
            }).catch((err) => {
                console.log(err)
                this.$bvToast.toast(err.response.data.message, {
                    title: 'Error',
                    variant: 'danger',
                });
            }).finally(() => {
                this.checkout = false;
             })
        },
        payByPayPalCheckout(invoiceId) {
            this.checkout = true;
            api.invoices.payByPayPalCheckout(this.invoiceId).then((response) => {
                window.location.href = response.data.link;
            }).catch((err) => {
                this.$bvToast.toast(err.response.data.message, {
                    title: 'Error',
                    variant: 'danger',
                });
            }).finally(() => {
                this.checkout = false;
            })
        },
        rechargeInvoice(data) {
            this.forcePaySpinner = true;
            let amount = {
            }
            api.invoices.pay(this.invoiceId, amount).then((response) => {
                let title = 'Error';
                let variant = 'error';
                if (response.success) {
                    title = 'Success';
                    variant = 'success';
                    this.$bvToast.toast(response.message, {
                        title: title,
                        variant: variant
                    });
                    this.getInvoice();
                }
            }).catch((err) => {
                console.log(err)
                this.$bvToast.toast(err.response.data.message, {
                    title: 'Error',
                    variant: 'danger'
                });
            }).finally(() => {
                this.forcePaySpinner = false;
            })
        },
        resetItem() {
            return {
                name: '',
                unit_price: '',
                quantity: '',
                discount: '',
                description: ''
            }
        },
        manageItem() {
            this.item.detail = this.resetItem();
            this.item.id = null;
            this.item.title = 'New item';
            this.$refs['manageItem'].show();
        },
        removeItem(data) {
            this.$swal({
                customClass: {
                    confirmButton: 'btn btn-danger'
                },
                title: 'Are you sure?',
                text: 'are you sure you want to delete item ' + data.item.title + '?',
                type: 'danger',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete this item',
                cancelButtonText: 'No',
                showCloseButton: true,
                confirmButtonColor: '#d33',
                showLoaderOnConfirm: true
            }).then((result) => {
                if (result.value) {
                    this.onDelete = true;
                    const itemId = data.item.item_id;
                    api.invoices.deleteItem(itemId).then(response => {
                        let varian = response.success ? 'success' : 'danger';
                        this.$bvToast.toast(response.message, {
                            title: 'Deleted',
                            variant: varian
                        });
                        if (response.success) {
                            this.getInvoice();
                        }
                    }).catch(err => {
                        this.$bvToast.toast(err.response.data.message, {
                            title: 'Error',
                            variant: 'danger',
                        });
                    }).finally(() => {
                        this.onDelete = false;
                    })
                }
            })
        },
        invoiceUpdated(response = []) {
            this.getInvoice();
        },
        editItem(data) {
            let price = String(data.price);
            let discount = String(data.discount);
            let item = {
                name: data.item.title,
                unit_price: price.includes("$") ?data.price.split('$')[1] : price,
                quantity: data.quantity,
                discount: discount.includes("$") ? data.discount.split('$')[1] : discount,
                description: data.description
            };
            this.item.detail = item;
            this.item.id = data.item.item_id;
            this.item.title = 'Update item';

            this.$refs['manageItem'].show();
        },
        beforeClose(event) {
            //console.log(this.$refs.item.resetForm())
        },
        formatDate(date) {
            var d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2)
                month = '0' + month;
            if (day.length < 2)
                day = '0' + day;

            return [year, month, day].join('-');
        },
        hideModal(ref = null) {
            this.$refs['my-modal'].hide()
        },
        finishUpdateItem() {
            setTimeout(() => {
                 this.$refs['manageItem'].hide();
            }, 500);

        },
        saveNote(data) {
            let message = 'Invoice note updated successfully';
            let title   = 'Success';
            let variant = 'success';
            api.invoices.update(this.invoiceId, data)
                .then(response => {
                }).catch(error => {
                    console.log(err);
                    message = 'Something went wrong';
                    title = 'Warning';
                    variant = 'danger';
                 }).finally(() => {
                    this.getInvoice();
                     this.$bvToast.toast(message, {
                        title: title,
                        variant: variant
                    });
                    this.isNoteEdit = false
                    this.$refs['invoice-note'].isSubmit = false;
                })
        },
        getBillingCountries(countryCode = null) {
            let countryName = '--'; console.log( countryCode )

            if (countryCode) {
                let country = _.find(BILLING_COUNTRIES, (o) => { return o.code == countryCode })
                if (country) countryName = country.name;
            }

            return countryName;
        }
    },
    watch: {
        'is_taxable': function (newVal, oldVal) {
            if (!newVal) {
                let item_columns_head_table = this.invoice.orderSummaryFields; 
                let item_columns_footer_table = this.invoice.InvoiceDetailField;
                this.invoice.orderSummaryFields = _.filter(item_columns_head_table, function (o) { return !['tax_gst', 'tax_qst'].includes(o.key); });
                this.invoice.InvoiceDetailField = _.filter(item_columns_footer_table, function (o) { return !['total_tax_gst', 'total_tax_qst'].includes(o.key); });
            }
        }
    }
}
</script>
<style>
.span {
    display: inline-block;
    margin-right: 5px;
 }
 .i-class {
    margin-left: -5px;
    margin-top: 3px;
 }
 tbody>tr>td {
    text-align: center;
 }
 .d-none {
    display: none;
 }
</style>
