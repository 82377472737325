<template>
    <span>
        <span v-if="isConnected(platform)" class="spot-light active-spot"></span>
        <span v-else-if="reconnected.loading && reconnected.platform.id == platform.id" class="spot-light">
            <b-spinner small type="grow" v-show="reconnected.loading && reconnected.platform.id == platform.id"></b-spinner>
        </span>
        <span v-else class="spot-light inactive-spot"></span>

        <span class="text-status text-success" v-if="isConnected(platform)">connected</span>
        <span v-else-if="reconnected.loading && reconnected.platform.id == platform.id" class="text-status text-muted outline-info">Connecting...</span>
        <span class="text-status text-muted outline-info" v-else v-b-hover="handleHoverReconnected">
            <span v-if="reconnected.isHover" class="cursor-pointer text-primary" @click.prevent="platformCheckFetchList(platform)"
                :disabled="reconnected.loading">
                <i class="ri-send-plane-fill"></i>
                Reconnect
            </span>
            <span v-else>disconnected</span>
        </span>
    </span>
</template>

<script>
import {
    sofbox
} from '../../../config/pluginInit'
import Spinner1 from '../../../components/loaders/spinner1'
import Multiselect from 'vue-multiselect'
import api from '@/api/RestClient';
import createNewPlatform from '@/components/inboxgeek/createNewPlatform';
import AccountsMultiselect from "@/views/children/AccountsMultiselect.vue";
import store from '@/store/index'
import { PLATFORMS_CODE, COLUMNS_GRID, PLATFORMS_ITEMS } from "@/constantes";

import {
    helper
} from '@/helpers';
import { services } from '@/helpers';

export default {
    name: 'NetWorkState',
    props: ['platform'],
    components: {
        Spinner1,
        Multiselect,
        createNewPlatform,
        AccountsMultiselect
    },
    data() {
        return {
            reconnected: { loading: false, platform: null, isHover: false },
        }
    },
    methods: {
        isConnected(platform) {
            return (platform.validation_code != 113 || platform.validation_code == null)
        },
        platformCheckFetchList(platform) {
            this.reconnected.loading = true;
            this.reconnected.platform = platform;

            return api.platforms.fetch(platform.id)
                .then((response) => {
                    return response.data
                })
                .then((data) => {
                    let service = 'lists';
                    if (data.name.toLowerCase() == 'converkit') service = 'tags';

                    if (data) {
                        return api.platforms.fetchServices({
                            "platform_name": data.name.toLowerCase(),
                            "entities": service,
                            "account": data.account_id,
                            "platform_id": data.id,
                        }).then((result) => {

                            if (result.success) {
                                platform.validation_code = null;

                                this.$swal({
                                    title: 'Reconnection',
                                    text: 'Platform Reconnected successfully.',
                                    type: 'success',
                                    icon: 'success',
                                }).then((result) => {

                                    // TODO
                                    // Can Emit event to refresh platform lists return platform props
                                    console.log('emit event and return platform');
                                    this.$emit('updateLists', result.data)
                                })

                            }
                        })
                    }

                })
                .catch((err) => {
                    this.$swal({
                        title: 'Error Reconnection',
                        text: 'Impossible to reconnect Platform. Update or Verify Api Key Permissions and Try Again',
                        type: 'error',
                        icon: 'error',
                    })
                })
                .finally(() => {
                    this.reconnected.loading = false;
                });

        },
        handleHoverReconnected(hovered) {
            this.reconnected.isHover = hovered
        }
    },
}
</script>
