<template>
  <div>
    <router-view/>
  </div>
</template>
<script>
import { sofbox } from './config/pluginInit'

export default {
  name: 'App',
  components: {
  },
  mounted() {
     sofbox.mainIndex()
  },
  data: ()=> ({ })
}
</script>
<style lang="scss">
  @import "./src/assets/scss/style.scss";
</style>
