<template>
  <div>
    <!-- START Subscriptions Card  -->
    <iq-card class="p-4">

      <template v-slot:headerTitle>
        <h4 class="card-title">Subscriptions Periods</h4>
        <p>Subscription Name : {{ plan.name }}</p>

      </template>


      <template v-slot:headerAction>
        <button class="btn btn-primary" @click="showSubsPeriodModal">Add Subscription Period</button>
      </template>

      <div class="p-4">

        <!-- START FILTER OPTIONS ROW  -->
        <b-row class="my-2" align-h="between">

          <!-- START Sow Option Input -->
          <b-col md="2">
            <b-form-group label-for="per-page-select" label-align-sm="left" class="mb-0">
              <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions"></b-form-select>
            </b-form-group>
          </b-col>
          <!-- END Sow Option Input -->


          <!-- START Filter Input -->
          <b-col md="4">
            <b-form-group class="mb-0">
              <b-input-group>
                <b-form-input id="filter-input" v-model="filter" type="search"
                  placeholder="Type to Search"></b-form-input>

              </b-input-group>
            </b-form-group>
          </b-col>
          <!-- END Filter Input -->

        </b-row>
        <!-- END FILTER OPTIONS ROW  -->


        <b-table borderless hover :items="subscriptionsPeriods" :fields="fields" :current-page="currentPage"
          :per-page="perPage" :filter="filter" :filter-included-fields="filterOn" :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc" :sort-direction="sortDirection" :busy="loading.table" stacked="md" show-empty
          @filtered="onFiltered">

          <template #table-busy>
            <div class="text-center p-4 d-flex justify-content-center align-items-center">
              <b-spinner variant="primary"> </b-spinner>
              <span class="text-primary p-2"><small> Fetching subscriptions periods, Please wait...</small></span>
            </div>
          </template>

          <template #cell(start_date)="row">
            {{ formatDateYMD(row.item.start_date) }}

          </template>

          <template #cell(end_date)="row">
            {{ formatDateYMD(row.item.end_date) }}

          </template>

            <template #cell(invoice_id)="row">
              {{ row.item.invoice_id }}

            </template>

            <template #cell(consumption)="row">
              {{ row.item.consumption }}

            </template>

          <template #cell(status)="row">

            <span class="badge badge-pill" :class="row.item.status == 1 ? 'iq-bg-success' : 'iq-bg-danger'">{{ row.item.status == 1 ? 'Active' : 'Inactive' }}</span>
          </template>

          <template #cell(actions)="row">

            <b-button title="Apply Discount" data-toggle="tooltip" v-b-tooltip.hover top
              variant=" iq-bg-success mr-1 mb-1" size="sm" @click="showSubsPeriodModal(row.item)">
              <i class="fa fa-pencil m-0"></i>
            </b-button>

          </template>


        </b-table>

        <!-- START PAGINATION TABLE-->
        <b-row align-h="end">

          <b-col md="3" class="my-1">
            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm" class="my-0"></b-pagination>
          </b-col>
        </b-row>
        <!-- END PAGINATION TABLE-->
      </div>

    </iq-card>
    <!-- END Subscriptions Card  -->


    <!-- START MODAL Subscription  -->
    <b-row>
      <b-col lg="12" md="12">
        <b-modal id="modal-new-subscription" @hide="onHide" :label="modal_title" ref="addSubscriptionPeriod" hide-footer
          :title="modal_title">

          <b-form @submit="formSubmit">

            <b-form-group id="input-group-1" label="Start date:" label-for="input-1" description="">
              <b-form-input id="input-1" v-model="form.start_date" type="date" placeholder="Interval"
                required></b-form-input>
            </b-form-group>

            <b-form-group id="input-group-1" label="End date:" label-for="input-1" description="">
              <b-form-input id="input-1" v-model="form.end_date" type="date" placeholder="Interval"
                required></b-form-input>
            </b-form-group>

            <!-- START Plans select -->
            <b-form-group id="input-group-4" label="Status : " label-for="input-4" description="">
              <b-form-select style="background-color: #e9edf4;border: 0;" v-model="form.status" :options="options_status"
                value-field="id" text-field="name"></b-form-select>
            </b-form-group>

            <b-form-group v-if="form.associate_invoice && action == 'update'" content-cols-xl="" id="input-group-21" label="Invoice Id:" label-for="input-21" description="">
                <b-form-input id="input-21" v-model="form.invoice_id" type="number" placeholder="#Invoice"
                  ></b-form-input>
            </b-form-group>
            <!-- END Plans select -->
              <b-form-group>
                <b-form-checkbox v-model="form.associate_invoice" name="check-button" switch>
                  Associate an invoice?
                </b-form-checkbox>
              </b-form-group>
            <b-button variant="primary" type="submit" :disabled="false"> Submit
              <b-spinner small type="grow" v-show="loading.modal"></b-spinner>
            </b-button>

          </b-form>

        </b-modal>
      </b-col>
    </b-row>
    <!-- END MODAL Subscription  -->


  </div>
</template>

<script>
import { sofbox } from '../../../config/pluginInit'
import Spinner1 from '../../../components/loaders/spinner1'
import api from '@/api/RestClient';
import { object } from '@amcharts/amcharts4/core';

var moment = require('moment');

export default {
  name: 'subscriptions-period',
  props: ['plan', 'subscriptionsPeriods', 'subscription_id', 'account_id', 'isFreePlan'],
  components: {
    Spinner1
  },
  data() {
    return {
      plans: [],
      options_status: [
        { id: 1, name: 'Active' },
        { id: 0, name: 'Inactive' },
      ],
      services: [],
      subscription_period_id: null,
      subscriptions_periods: [],
      today_date: moment().format("YYYY-MM-DD"),
      loading: {
        modal: false,
        table: false
      },
      modal_title: 'Add Subscription Period',
      action: 'create',
      formData: {
        "plan_id": null,
        "interval": null,
        "next_billing_date": null,
        "active": false,
      },
      form: {
        "subscription_id": this.subscription_id,
        "start_date": null,
        "end_date": null,
        "status": 1,
        "invoice_id": null,
        "associate_invoice": false
      },
      // Table data :
      fields: [
        { key: 'id', label: '#ID', sortable: true, class: 'text-center' },
        { key: 'start_date', label: 'Start Date', sortable: true, class: 'text-center' },
        { key: 'end_date', label: 'End Date', sortable: true, class: 'text-center' },
        { key: 'consumption', label: 'Consumption', sortable: true, class: 'text-center' },
        { key: 'invoice_id', label: '#Invoice', sortable: true, class: 'text-center' },
        { key: 'status', label: 'Status', sortable: true, sortDirection: 'desc' },
        { key: 'actions', label: 'Actions', class: 'text-center' }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],

    }
  },
  async mounted() {

    sofbox.index();
    this.$root.$on('bv::hide::modal', (item) => {
     //
    })
    //this.fetchSubscriptions();
    this.totalRows = this.subscriptionsPeriods.length ?? 1;

  },
  methods: {

    update(subscription) {

      this.action = 'update';

      this.modal_title = 'Edit Subscription';

      this.subscription_id = subscription.id;

      this.formData = {

        "account_id": this.account.id,
        "plan_id": subscription.plan_id,
        "interval": subscription.interval,
        "next_billing_date": subscription.next_billing_date,
        "active": subscription.active

      };

      this.$refs['subscription'].show();
    },
    destroy(key) {

      // delete subscription ..

    },
    formSubmit(e) {

      e.preventDefault();

      const data = this.form;
      if (this.action == "create") {
        this.createSubscriptionPeriod(data);

      } else {
        this.updateSubscriptionPeriod(this.subscription_period_id, data);

      } // end if

    },
    createSubscriptionPeriod(data) {
      this.loading.modal = true;
      data.subscription_id = this.subscription_id;
      api.subscriptions.createSubsPeriod(data).then(response => {

        let variant = 'danger';
        let message = 'Something went wrong'

        if ('success' in response) {
          variant = response.success ? 'success' : 'danger';
          message = response.message;
          this.toast('Subscriptions period', variant, message);
          this.getSubscriptionPeriods();
        }

        this.loading.modal = false;


      }).catch((error) => {
        this.toast('Subscriptions period', 'error', error.message);
        this.loading.modal = false;
      });
    },
    updateSubscriptionPeriod(id, data) {
      data.subscription_id = this.subscription_id;
      this.loading.modal = true;

      api.subscriptions.updateSubsPeriod(id, data).then(response => {
        let variant = 'danger';
        let message = 'Something went wrong'

        if ('success' in response) {
          variant = response.success ? 'success' : 'danger';
          message = response.message;
          this.toast('Account', variant, message);
          this.getSubscriptionPeriods();
        }
        this.action = 'create';
      }).catch((error) => {
        this.toast('Subscriptions period', 'error', error.message);
        this.loading.modal = false;
      });

    },
    toast(title = "success", variant = "success", message = "Organization updated successfully") {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant
      })
    },
    fetchSubscriptions() {

      this.loading.table = true;

      api.subscriptions.list(this.account.id).then((response) => {

        this.loading.table = false;
        this.subscriptions = response.data;

        /*  this.subscriptions = this.subscriptions.map((subscription) => {

            // TODO :
            // ! will be replaced with the real values

            if (subscription.next_billing_date) {
              subscription.payment_status = moment().diff(moment(subscription.next_billing_date)) >= 0 ? 1 : 0;
            } else {
              subscription.payment_status = null;
            }




            return subscription;
          });*/

        this.totalRows = response.data.length;

      }).catch((error) => {

        this.loading.table = false;
        console.log("failed to fetch subscriptions");

        this.subscriptions_periods = [];
      });

    },
    getSubscriptionPeriods() {
      this.loading.table = true;
      api.subscriptions.subsPeriodsLists(this.subscription_id).then((response) => {
        let subsPeriods = _.map(response.data, function (o) {
          return {
            id: o.id,
            start_date: o.start_date,
            end_date: o.end_date,
            status: o.status,
            consumption: o.consumption,
            invoice_id: o.invoice_id
          }
        });
        this.$emit('oneDoneSubsPeriod', subsPeriods);
      }).catch((error) => {

        this.loading.table = false;
        console.log(error);
        this.loading.table = true;
        this.subscriptions_periods = [];
      }).finally(() => {
        this.reinitializeForm();
      })
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    formatDateYMD(date) {
      var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;

      return [year, month, day].join('-');
    },
    setLoading(val) {
      this.loading.table = val;
    },
    showSubsPeriodModal(elt) {
      if ('start_date' in elt) {
        this.action = 'update';
        this.modal_title = `Update subscription Period #${elt.id}`;
        this.subscription_period_id = elt.id;
        this.form.start_date = this.formatDateYMD(elt.start_date);
        this.form.end_date = this.formatDateYMD(elt.start_date);
        this.form.status = elt.status;
        this.form.invoice_id = elt.invoice_id;
        this.form.associate_invoice = elt.invoice_id ? true : false
      }
      this.$refs['addSubscriptionPeriod'].show();
    },
    onHide() {
      this.reinitializeForm();
    },
    reinitializeForm() {
      this.modal_title = 'Add Subscription Period';
      this.form = {
        "subscription_id": null,
        "start_date": null,
        "end_date": null,
        "status": 1,
        "invoice_id": null,
        "associate_invoice": false
      };
      this.loading.modal = false;
    }
  },
  computed: {
    filteredPalns() {


      return this.plans.filter((plan) => {

        return plan.service_id == this.formData.service_id

      });
    }
  },
  watch: {
    account: function (newVal, oldVal) {
      this.fetchSubscriptions();
    },
    subscriptionsPeriods: function (newVal, oldVal) {
      setTimeout(() => {
        this.setLoading(false);
        this.totalRows = newVal.length;
      }, 3000);

    },
    "form.associate_invoice":  function (newVal, oldVal) {
      if (newVal == false) this.form.invoice_id = null;
    }
  }

}
</script>


<style scoped>
.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgb(171 216 225 / 15%);
}</style>
