export default function (axios) {
  return {
    stats: (params = {}) => {
      return axios.get(`/chat/stats`)
        .then(response => response.data);
    },
    statsByPeriod: (params = {}) => {
      return axios.get(`/chat/stats-by-period`, { params: params })
        .then(response => response.data);
    },
  }
}
