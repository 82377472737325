import Qs from 'qs';

export default function (axios) {
  return {
    last: (params = {}) => {
      return axios.get(`/notification_events/last`, { params })
        .then(response => response.data);
    },
    list: (params = {}) => {
      return axios.get(`/notification_events`, { params })
        .then(response => response.data);
    },
    get: (id) => {
      return axios.get(`/notification_events/${id}`)
        .then(response => response.data);
    },
    put: (id, data) => {
      return axios.put(`/notification_events/${id}`, data)
        .then(response => response.data)
    },
    delete: (id)=>{
      return axios.delete(`notification_events/${id}`).then(response => response.data);
    },
  }
}
