<template>
    <b-form @submit="onFilter">
        <b-row class="mt-2">
            <b-col md="12" lg="12">
                <h4>Filters</h4>
                <b-row class="px-2">

                    <b-col md="4">
                        <b-form-group label="Choose source:">
                            <multiselect :loading="isLoading" :disabled="isLoading" tag-placeholder="Manage Source"
                                placeholder="Choose source" label="name" track-by="code" v-model="form.source"
                                deselect-label="Can't remove this value" :allow-empty="true" :options="sources"
                                :multiple="true" :taggable="false" @input="searchSources">

                                <template slot="option" slot-scope="props">
                                    <div class="option__desc">

                                        <span class="option__title">
                                             {{ props.option.name }}
                                        </span>

                                    </div>

                                </template>

                            </multiselect>
                        </b-form-group>
                    </b-col>

                    <b-col md="4">
                        <b-form-group label="Page Title:">
                            <b-form-input type="text" class="mb-2 mr-sm-2 mb-sm-0" v-model="form.title"
                                placeholder="Search by title"></b-form-input>
                        </b-form-group>
                    </b-col>

                    <b-col md="4">
                        <b-form-group label="Website:">
                            <b-form-input type="text" class="mb-2 mr-sm-2 mb-sm-0" v-model="form.website"
                                placeholder="Search by website"></b-form-input>
                        </b-form-group>
                    </b-col>

                    <b-col md="3">
                        <b-form-group label="Date from:">
                            <b-form-input :max="form.date_to" v-model="form.date_from" type="date"
                                class="mb-2 mr-sm-2 mb-sm-0" placeholder="Created at date from"></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col md="3">
                        <b-form-group label="Date to:">
                            <b-form-input :min="form.date_from" v-model="form.date_to" type="date"
                                class="mb-2 mr-sm-2 mb-sm-0" placeholder="Created at date to"></b-form-input>
                        </b-form-group>
                    </b-col>

                    <b-col md="3" class="text-left">
                        <b-form-group id="label" label="Status:">
                            <multiselect tag-placeholder="Manage status" placeholder="Choose status" label="name"
                                track-by="code" v-model="form.status" deselect-label="Can't remove this value"
                                :allow-empty="true" :options="status" :multiple="false" :taggable="false">
                            </multiselect>
                        </b-form-group>
                    </b-col>

                    <b-col md="3" class="text-left">
                        <b-form-group id="label" label="Clean:">
                            <multiselect tag-placeholder="Manage clean" placeholder="Choose state" label="name"
                                track-by="code" v-model="form.clean" deselect-label="Can't remove this value"
                                :allow-empty="true" :options="cleans" :multiple="false" :taggable="false">
                            </multiselect>
                        </b-form-group>
                    </b-col>

                </b-row>

                <b-row class="px-4 mb-2">
                    <b-button class="ml-auto mt-0 px-3" type="submit" :disabled="onFilterDisable" variant="primary"
                        style="height: 3.3em; margin-top: 2.3em">
                        <b-spinner small label="Small Spinner" type="grow" v-show="onFilterDisable"></b-spinner> <i
                            class="fa fa-paper-plane m-0" v-show="!onFilterDisable"></i>
                    </b-button>
                </b-row>


            </b-col>

        </b-row>
    </b-form>
</template>

<script>
import api from '@/api/RestClient'
import {
    sofbox
} from '@/config/pluginInit'
import Multiselect from 'vue-multiselect'
import { helper } from '@/helpers'
import _ from 'lodash'
import { GE_RECORD_STATUS_STATES, GE_RECORD_CLEAN_STATES } from "@/constantes";

export default {
    name: 'BlankPage',
    props: {
        source: {
            type: Object,
            default: () => null
        },
        onFilterTable: {
            type: Boolean,
            default: false
        },
        from: {
            type: String,
            default: null
        },
        to: {
            type: String,
            default: null
        },
        serie: {
            type: String,
            default: null
        }
    },
    components: {
        Multiselect
    },
    mounted() {
        sofbox.index();
        this.setDates();
        this.setStatus();
        this.fetchSources();
    },
    data() {
        return {
            onFilterDisable: false,
            isLoading: true,
            form: {
                source: [],
                website: null,
                title: null,
                status: false,
                clean: false,
                daily_cap: null,
                date_to: null,
                date_from: null,
            },
            sources: [],
            status: GE_RECORD_STATUS_STATES,
            cleans: GE_RECORD_CLEAN_STATES,
        }
    },
    methods: {
        onFilter(e) {
            e.preventDefault();
            this.onFilterDisable = true
            this.$emit('onFilter', this.form)
        },
        fetchSources() {
            let accountId = this.isClient ? this.user.account_id : null;

            api.geSources.getSourcesNames({})
                .then((response) => {

                    if (response.success) {

                        response.data.forEach((item) => {
                            let source = {};
                            source.name = item.name;
                            source.code = item.id;
                            source.website = item.website;
                            this.sources.push(source);
                        });

                    }

                }).catch(error => {
                    console.log(error)
                }).finally(() => {

                    const that = this;
                    that.form.source = [];

                    helper.delay(2000).then(() => {

                        if (that.source) {
                            let source = _.find(that.sources, function (o) { return o.code == that.source.id; });
                            this.form.source.push(source);
                        }

                        this.$emit('onFilter', this.form)
                    })

                    this.isLoading = false;
                })
        },
        setDates() {
            if (typeof this.$route.query.from !== 'undefined') {
                this.form.date_from = this.$route.query.from;
            }

            if (typeof this.$route.query.to !== 'undefined') {
                this.form.date_to = this.$route.query.to;
            }
        },
        setStatus() {
            if (typeof this.$route.query.serie !== 'undefined') {
                let serie = this.$route.query.serie.toLowerCase();
                let status = null;

                if (serie == 'total sent') { // Total Sent
                    status = 1;
                } else if (['total verified', 'verified'].includes(serie)) { // Total verified
                    // Set Clean filtered
                    let itemClean = _.find(this.cleans, function (o) { return o.code == 1; });
                    if (itemClean) {
                        this.form.clean = itemClean;
                    }

                }

                // Find status filtered
                let itemStatus = _.find(this.status, function (o) { return o.code == status; });

                // Set item status
                if (itemStatus) {
                    this.form.status = itemStatus;
                }
                console.log( this.form, itemStatus )
            }

            if (typeof this.$route.query.to !== 'undefined') {
                this.form.date_to = this.$route.query.to;
            }
        },
        finishFilter() {
            this.onFilterDisable = false
        },
        searchSources() {
            // console.log('Here on searchSources.')
        }
    },
}
</script>
<style>
.form-border {
    border: 5px solid;
    border-radius: 5px;
    margin-bottom: 30px;
    padding: 10px;
}
</style>
