<template>
<b-form @submit="onSubmit">
    <b-form-group label="Name" label-for="name">
        <b-form-input v-model="name" id="name" type="text" placeholder="List Name"></b-form-input>
    </b-form-group>
    <div class="form-group text-center dash" @dragover="dragover" @dragleave="dragleave" @drop="drop">
        <h1 style="color: #0084ff;"><i class="fa fa-upload"></i></h1>
        <h4 v-if="!uploading">Drag & Drop file Here or <label class="text-sm" style="font-size:0.85rem;cursor: pointer;padding: 5px 15px;background: #e5f2ff;border-radius: 5px;color: #19f;" for="file" v-if="!isDragging"> Choose a file </label></h4>
        <h5 v-else>Uploading...</h5>
        <input id="file" type="file" class="inputfile" @change="handelFileUpload" ref="file" accept=".csv">

        <div class="p-2">
            <span v-if="file"><i class="fas fa-file"></i> {{ file.name }}</span>
        </div>
    </div>

    <b-button class="mt-3" type="submit" variant="primary" :disabled="disable">Submit <b-spinner small label="Small Spinner" type="grow" v-show="loader"></b-spinner>
    </b-button>
</b-form>
</template>

<script>
export default {
    props: {
        imported: {
            type: Boolean,
            default: false
        },
        account_id: {
            type: Number,
            default: -1
        }
    },
    data() {
        return {
            file: null,
            name: '',
            disable: false,
            loader: false,
            isDragging: false,
            uploading: false,
        }
    },
    methods: {
        onSubmit(e) {
            e.preventDefault();
            this.disable = true;
            this.loader = true
            let formData = new FormData();
            formData.append('file', this.file);
            formData.append('list_name', this.name);
            formData.append('account_id', this.account_id);
            this.$emit('onSubmit', formData)
        },
        dragover(e) {
            e.preventDefault();
            this.isDragging = true;
        },
        dragleave() {
            this.isDragging = false;
        },
        drop(e) {

            e.preventDefault();
            //this.$refs.file.files = e.dataTransfer.files;
            //this.onChange();
            //this.isDragging = false;
        },

        handelFileUpload(event) {
            this.file = event.target.files[0];
            //this.submit();
        },
        reset() {
            this.disable = false
            this.loader = false
            this.imported = false
            this.isDragging = false
            this.uploading = false
            this.file = null
            this.name = null
        }
    },
    watch: {
        "imported": {
            handler(newVal, oldVal) {
                this.reset()
            }
        }
    }
}
</script>

<style scoped>
.dash {

    background: #cfe1ff0f;
    border-radius: 10px;
    padding: 1rem;
    border: 1px dashed #0084ff;

}

.inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.inputfile+label {
    font-size: 1.25em;
    font-weight: 700;
    color: white;
    background-color: black;
    display: inline-block;
}

.inputfile:focus+label,
.inputfile+label:hover {
    background-color: red;
}

.inputfile+label {
    cursor: pointer;
    /* "hand" cursor */
}
</style>
