<template>
   <b-row v-if="show_video">
    <div class="col-md-12 col-lg-12 consumption-bar">
      <div class="iq-card iq-card-block iq-card-stretch">
        <div class="iq-card-body p-4">
          <div class="row p-2">


            <div class="col-6 p-5  ">
              <div class="my-auto">

              <h2 class="mb-4">{{ video_details.title }}</h2>
              <p class="mb-4 " >
                {{ video_details.description }}
              </p>
              <p class="text-right"><a class="my-3" style="cursor: pointer;" @click="hideVideoCard">Don't show me this video walkthrough again</a></p>


              </div>
            </div>

           <div class="col-6 text-center">
            <i @click="playVideo=true" class="ri-play-circle-fill play-button"></i>
            <img  :src="getThumbnail()" alt="" class="m-auto" width="700" height="440">

            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="video-modal" v-if="playVideo" >

      <iframe class="video-content" :src="video_details.link"></iframe>
      <i class="ri-close-line text-white close-video" @click="playVideo=false"></i>
    </div>
  </b-row>

</template>

<script>


import api from '@/api/RestClient'

export default {
  name: "WelcomeVideoCard",
  components: {

  },
  mounted() {


      api.settings.getVideowalkthroughStatus().then(response=>{

        if(response.success){

          if(response.data == 1){

            api.userMetadata.fetchUserMetadata("hide_video_walk_through") .then(response => {


              if( response.data != 1 ){

                this.fetchVideoDetails();
              }

            });

          }
        }
      })


  },
  data: () => ({
      video_details:{
        title: "",
        description: "",
        link : '',

      },
      show_video: false,
      playVideo:false
   }),
   methods : {
    fetchVideoDetails (){
      api.settings.fetchVideoDetails().then(response=>{
        if(response.success){
          this.video_details = response.data;

          this.show_video = true;
        }
      })
    },
    getThumbnail () {
      

      return require(`@/assets/images/background.png`) 
    },
    hideVideoCard () {


        api.userMetadata.updateUserMetadata('hide_video_walk_through', 1) .then(response => {

          if( response.success ){

            this.show_video = false;

          }

        })


    }

   }

}
</script>
//
<style>
.video-modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background: #00000096;
  z-index: 1000;
  display: flex;
}
.video-content{
  margin: auto;
  width: 70%;
  height: 80vh;
}
.close-video{
  position: absolute;
  right: 8vw;
  top: 10vh;
  cursor: pointer;
  font-size: 2rem;
}
.play-button {
    font-size: 8em;
    position: absolute;
    color: #0084ff;
    top: 28%;
    margin: auto;
    right: 44%;
    cursor: pointer;
}
</style>
