<template>
  <div>
    <Loader />
    <section class="sign-in-page bg-white">
      <div class="container-fluid p-0">
        <div class="row no-gutters pt-3">
          <div class="col-sm-6 align-self-center mt-2">
            <a class="sign-in-logo mb-5 ml-5" href="#"><img :src="logo" class="img-fluid" alt="logo"></a>
          </div>
          <div class="col-sm-6 align-self-center mt-2">
            <p class="d-inline-block line-height-2 float-right mr-2"><span class="mr-2">Already Have Account ?</span>
              <router-link :to="{ name: 'auth1.sign-in1' }">
                <b-button variant="primary" style="padding:5px 2rem" class="text-right mr-1 pull-right">Sign in
                </b-button>
              </router-link>
            </p>
          </div>
        </div>
        <div class="row no-gutters">
          <div class="col-sm-6 align-self-center">
            <div class="sign-in-from">
              <router-view></router-view>

            </div>
          </div>
          <div class="col-sm-6">
            <div class="sign-in-detail text-white"
              style="background:   linear-gradient(0deg, #172945, #dd406c); padding: 60px 100px ; min-height: 713px;"
              id="rcorners3">
              <a v-if="false" class="sign-in-logo mb-5" href="#"><img :src="logo" class="img-fluid" alt="logo"></a>
              <div class="content_detail_old" v-if="true">
                <div class="text-center mb-2 mt-1" v-if="partner" style="margin: -2em 0">
                  <h3 class="text-white" v-if="false">A</h3>
                  <img :src="partnerLogo" class="img-fluid mb-3 mt-3 img-partner" style="width:30%" alt="chronos">
                  <div class="mb-5" v-if="false">
                    <h3 class="text-white">Preferred Partner</h3>
                  </div>
                </div>
                <div class="containt">
                  <h5 class="text-white header_content" style="font-size: 2rem;">
                    With your free account today, you’re getting:
                  </h5>
                  <ul class="mt-3 account_advantages" style="font-size: 0.9rem;padding-inline-start: 0px;">
                    <li>A 30 day trial with 6000 events you can use to see what results you get from InboxGeek… all
                      without paying a dime</li>
                    <li>Top tier customer support to help you integrate your ESP</li>
                    <li>No credit Card Required</li>
                  </ul>
                  <div class="question">
                    <h4 style="font-size: 2rem; color:white">What’s an event?</h4>
                    <p>An Event triggers your email to send in the exact moment your subscriber is active in their
                      inbox.</p>
                  </div>

                  <div id="account-free-card">
                    <b-card class="iq-mb-3 dark-color">
                      <b-card-title>Your Account Is Free Forever</b-card-title>
                      <blockquote class="blockquote mb-0">
                        <p class="font-size-14">Use your 6000 events anytime. Don’t wait too long though: the sooner you
                          use
                          them, the sooner you’ll see incredible results.</p>
                        <p class="font-size-14">You can upgrade your free account to a paid subscription anytime.</p>
                      </blockquote>
                    </b-card>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
        <div class="row signup-footer-row dark-color" style="padding:3em 0;">
          <div class="signup-footer-container">
            <b-media style="margin-bottom: 1em;">
              <template v-slot:aside>
                <b-img :src="require('../../assets/images/logo/partners/reverb-media.png')" class="client-logo"
                  style="width: 100px;" alt="#"></b-img>
              </template>
              <b-media-body>
                <p>"We had tens of thousands of email leads that were worth nothing to us... Our email team successfully
                  revived a good percentage of these leads and re-engaged them in a very short time, generating an extra
                  $42,000 in revenue in only the first 3 months, thanks to how they used InboxGeek"</p>
              </b-media-body>
            </b-media>
            <div>

              <h3 class="dark-color d-inline-block line-height-2" v-if="true">Clients who love InboxGeek</h3>
              <div class="partners_logos d-flex mt-3">
                <b-link href="#" class="iq-media partner">
                  <b-img class="avatar-400" fluid :src="require('@/assets/images/logo/partners/Dogtooth-White2.png')"
                    alt="" />
                </b-link>
                <b-link href="#" class="iq-media partner">
                  <b-img class="avatar-400" fluid :src="require('@/assets/images/logo/partners/logo-vitality.png')"
                    alt="" />
                </b-link>
                <b-link href="#" class="iq-media partner">
                  <b-img class="avatar-400" fluid :src="require('@/assets/images/logo/partners/loop7-1.png')" alt="" />
                </b-link>
                <b-link href="#" class="iq-media partner">
                  <b-img class="avatar-400" fluid :src="require('@/assets/images/logo/partners/upsender-logo.png')"
                    alt="" />
                </b-link>
                <b-link href="#" class="iq-media partner">
                  <b-img class="avatar-400" fluid
                    :src="require('@/assets/images/logo/partners/publisher_in_a_box_logo.png')" alt="" />
                </b-link>
              </div>

            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Loader from '../../components/sofbox/loader/Loader'
import logo from '../../assets/images/logo-white.png'
import {
  sofbox
} from '../../config/pluginInit'
import bgImage from '../../assets/images/login/2.jpg'
import * as chat from '@/chatBots.js'

export default {
  name: 'AuthLayout1', 
  components: {
    Loader
  },
  mounted() {
    sofbox.index()
    const param = this.$route.params.via;
    const query = this.$route.query.via;
    this.partner = param ? param : query ? query : null;

    switch (this.partner) {
      case 'chronos': case 'joshua':
        this.partnerLogo = 'https://chronos.agency/wp-content/uploads/2020/08/chronos-logo-white.svg'
        break;
      case 'dogtooth':
        this.partnerLogo = 'https://www.dogtoothinc.com/wp-content/uploads/2022/09/Dogtooth-White2.png'
        break;
      default:
        this.partner = null;
        break;
    }
    chat.liveChat();

  },
  data() {
    return {
      logo: logo,
      bgImageURL: bgImage,
      partnerLogo: null,
      partner: null,
      sheduleUrl: 'https://calendly.com/inboxgeek/support-call'
    }
  }
}
</script>

<style>
.client-logo {
  width: 100%;
}

.media-aside {
  flex: 1 !important;
}

.media-body {
  flex: 6 !important;
}

.sign-in-page {
  background: #0a2946 !important;
  color: #FFF;
}

.sign-in-page h1,
.sign-in-page h2,
.sign-in-page h3,
.sign-in-page label {
  color: #FFF;
}

.sign-in-page .btn {
  background: #f6386b;
  border-color: #f6386b;
}
.dark-color {
  color: #374948 !important;
}
.signup-footer-row {
  background: white;
  padding: 5em 0;
  margin: auto;
}

.content_detail_old {
  position: relative;
}

.header_content {
  font-size: 2em !important;
}


.sign-in-logo {
  display: inline;
  width: 100%;
}

.img-partner {
  width: 20%;
}

.info-link {
  position: relative;
  top: 25%;
}

.content_detail {
  width: 45em;
  position: relative;
  top: -5rem
}

a[target] {
  text-decoration: underline;
  font-weight: bold;
}

.header_content {
  font-size: 2.2em;
}

#rcorners3 {
  border-radius: 5px;
  background-position: left top;
  background-repeat: repeat;
  margin: 10px;
  height: 95%;
}
#account-free-card {
  margin-top: 3em;
}
.signup-footer-container {
  max-width: 80%;
  width: 1400px;
  margin: auto;
}
.testimony {
  background-color: transparent !important;
  color: #FFF !important;
}

ul.account_advantages li {
  list-style-type: disc;
}

@media (max-width: 767px) {
  .header_content {
    font-size: 1.5em !important;
  }

}
</style>