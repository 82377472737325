export default function (axios) {
  return {
    list: (params = {}) => {
      return axios.get('lo-automations', { params }).then(response => response.data);
    },
    get: (id = null, params = {}) => {
      let url = id ? `/lo-automations/${id}` : `/lo-automations`
      return axios.get(url, { params })
        .then(response => response.data);
    },
    fetch: (loAutomationId) => {
      return axios.get(`lo-automations/${loAutomationId}`).then(response => response.data);
    },
    store: (data) => {
      return axios.post('lo-automations', data).then(response => response.data);
    },
    update: (id, data) => {
      return axios.put(`lo-automations/${id}`, data).then(response => response.data);
    },
    unlink: (integrationId) => {
      return axios.post(`lo-automations/unlink/${integrationId}`).then(response => response.data);
    },
    // delete: (id) => {
    //   return axios.delete(`lo-automations/${id}`).then(response => response.data);
    // },
    // restore: (id) => {
    //   return axios.post(`lo-automations/${id}/restore`, {})
    //     .then(response => response.data);
    // },
  }
}
