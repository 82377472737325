<template>
  <div>
    <div>
      <b-form-group class="col-md-12">
        <b-row>
          <b-col>
            <label for="">Nickname: *</label>
            <input type="text" class="form-control" v-model="platform_name">
            <small v-if="errors.platform_name" class="text-danger">Platform name is required</small>
          </b-col>
        </b-row>
      </b-form-group>

      <b-form-group class="col-md-12" label="Platform: *" label-for="Platform">

        <div>
          <multiselect v-model="selected_platform" tag-placeholder="Empty" :allow-empty="false"
            deselect-label="Can't remove this value" placeholder="Select platform" label="name" track-by="code"
            :options="platforms" :multiple="false" :taggable="true">
            <template slot="singleLabel" slot-scope="props">
              <img class="option__image img-responsive mx-2" :src="getImageBasicURL(props.option)" alt="Platform image"
                width="28">
              <span class="option__desc">
                <span class="option__title">{{ platformName(props.option.name) }}</span>
              </span>
            </template>

            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <img class="option__image img-responsive mx-2" :src="getImageBasicURL(props.option)"
                  alt="Platform label image" width="28">
                <span class="option__title">{{ platformName(props.option.name) }}</span>
              </div>
            </template>
          </multiselect>
        </div>

        <small id="alertPlatform" class="text-danger d-none"></small>



      </b-form-group>
    </div>

    <b-form-group class="col-md-12">
      <component v-bind:is="SelectedPlatformComponent" @createPlatform="createPlatform" :account="account.accountid"
        :platform_nickname="platform_name" @OauthPlatformAdded="OauthPlatformAdded" :nickname="platform_name"
        :disabled_button="disable_button"></component>
    </b-form-group>

  </div>
</template>

<script>

import api from '@/api/RestClient'
import Multiselect from 'vue-multiselect'
import { PLATFORMS_CODE, COLUMNS_GRID, PLATFORMS_ITEMS } from "@/constantes";


import ActiveCampaignInput from './platforms/inputs/ActiveCampaignInput.vue';
import AweberInput from './platforms/inputs/AweberInput.vue';
import BlastableInput from './platforms/inputs/BlastableInput.vue';
import CampaignerInput from './platforms/inputs/CampaignerInput.vue';
import SendGridInput from './platforms/inputs/SendGridInput.vue';
import GoHighLevelInput from './platforms/inputs/GoHighLevelInput.vue';
import ConvertkitInput from './platforms/inputs/ConvertkitInput.vue';
import EmailOctopusInput from './platforms/inputs/EmailOctopusInput.vue';
import ExpertSenderInput from './platforms/inputs/ExpertsenderInput.vue';
import GetResponseInput from './platforms/inputs/GetResponseInput.vue';
import HubspotInput from './platforms/inputs/HubspotInput.vue';
import InboxsuiteInput from './platforms/inputs/InboxsuiteInput.vue';
import KeapInput from './platforms/inputs/KeapInput.vue';
import KlaviyoInput from './platforms/inputs/KlaviyoInput.vue';
import MailchimpInput from './platforms/inputs/MailchimpInput.vue';
import MaropostInput from './platforms/inputs/MaropostInput.vue';
import OntraportInput from './platforms/inputs/OntraportInput.vue';
import BrevoInput from './platforms/inputs/BrevoInput.vue';
import InboxInput from './platforms/inputs/InboxInput.vue';
import SendlaneInput from './platforms/inputs/SendlaneInput.vue';
import WebhookInput from './platforms/inputs/WebhookInput.vue';
import MumaraInput from './platforms/inputs/MumaraInput.vue';
import ListrakInput from './platforms/inputs/ListrakInput.vue';
import InboxgeekInput from './platforms/inputs/InboxgeekInput.vue';
import OngageInput from './platforms/inputs/OngageInput.vue';
import OmniSendInput from './platforms/inputs/OmniSendInput.vue';
import SendrAppInput from './platforms/inputs/SendrAppInput.vue';
import BeehiivInput from './platforms/inputs/BeehiivInput.vue';
import MailSendInput from './platforms/inputs/MailSendInput.vue';
import { helper } from '@/helpers';
import {mapGetters} from "vuex";

export default {
  name: "createNewPlatform_108_",
  props: ['account', 'oPlatforms'],
  components: {
    Multiselect,
    MumaraInput,
    OngageInput,
    ActiveCampaignInput,
    BlastableInput,
    AweberInput,
    CampaignerInput,
    ConvertkitInput,
    EmailOctopusInput,
    ExpertSenderInput,
    GetResponseInput,
    HubspotInput,
    InboxsuiteInput,
    KeapInput,
    KlaviyoInput,
    SendlaneInput,
    MailchimpInput,
    MaropostInput,
    OntraportInput,
    BrevoInput,
    InboxInput,
    WebhookInput,
    SendGridInput,
    GoHighLevelInput,
    ListrakInput,
    InboxgeekInput,
    OmniSendInput,
    BeehiivInput,
    MailSendInput,
    SendrAppInput
  },
  mounted() {

  },
  created() {
    this.platforms = this.platformsSorted
  },
  data() {
    return {
      disable_button: false,
      platform_name: '',
      platforms: [],
      selected_platform: null,
      form: {

      },
      errors: {
        platform_name: false
      }
    }
  },
  methods: {
    platformName(name) {

      return helper.platformFormatName(name);
    },
    getImageBasicURL(option) {
      try {

        return require(`@/assets/images/platforms/${option.img}`)
      } catch (e) {
        return require(`@/assets/images/platforms/default.png`)
      }
    },
    createPlatform(platform) {
      this.disable_button = true;

      if (this.platform_name.trim() != '') {

        let platform_details = {
          'nickname': this.platform_name,
          'name': this.selected_platform.name,
          'account': platform.account,
          'key': platform.apikey,
          'organization': this.account.accountid,
          'options' : platform?.options
        };

        // TODO Check if platform key already existing and ask to user if continue
        api.platforms.match(platform_details)
          .then((response) => {
            if (response.success) {
              this.storePlatform(platform_details);
            } else {
              // TODO Display Pop up here
              this.alertPlatformAlreadyExists(platform_details);
            }

          })
          .catch((err) => {
            let message = 'Error!';

            if (err.response.data.message) {
              message = err.response.data.message;
            }

            this.disabled_button = false;

            if (err.response.status == 406) {
              this.alertPlatformAlreadyExists(platform_details);
            } else {
              this.$swal({
                icon: 'error',
                title: 'Something Went Wrong!',
                text: message,
                confirmButtonText: 'Ok',
              })
            }

          });

      } else {
        this.disable_button = false;
        this.errors.platform_name = true;
      }
    },
    alertPlatformAlreadyExists(platformData) {
      this.$swal({
        icon: 'warning',
        title: 'Oh?',
        text: 'This platform account already exists within your InboxGeek account. Creating a duplicate may cause delayed list synchronization. Do you wish to continue?',
        type: 'warning',
        confirmButtonText: 'Continue',
        cancelButtonText: 'Go Back',
        showCloseButton: true,
        showCancelButton: true,
        showLoaderOnConfirm: true
      }).then((result) => {

        if (result.value) {
          // overwrite store platform
          this.storePlatform(platformData);
        } else {
          this.disabled_button = false;
          this.$emit('cancelCreated', null);
        }

      })
    },
    storePlatform(platformData) {
      api.platforms.store(platformData)
        .then((response) => {

          platformData.id = response.data.id;
          platformData.nickname = response.data.nickname;

          this.$swal({
            icon: 'success',
            title: 'Platform Added',
            text: response.message,
            type: 'success',
            confirmButtonText: 'OK',
            showCloseButton: true,
          })

          this.$emit('platformCreated', platformData);

        }).catch((error) => {


          let swal = {
            icon: 'error',
            title: 'Failed',
            text: 'Failed to create a new platform,' + error,
            type: 'error',
            confirmButtonText: 'OK',
            showCloseButton: true,
          }


          if (error.response.data.message) {
            swal.text = error.response.data.message;
          }

          this.$swal(swal)

          this.disable_button = false;
        });
    },
    OauthPlatformAdded(platform) {
      const platform_id = platform.id ?? platform.platform_id;
      api.platforms.fetch(platform_id).then((response) => {

        if (response.success) {
          let platform_details = {
            'id': response.data.id,
            'name': response.data.name,
            'account': response.data.account,
            'key': response.data.apikey,
            'organization': this.account.accountid
          };

          this.$emit('platformCreated', platform_details);
        }

      })
        .catch((err) => {
          let swal = {
            icon: 'error',
            title: 'Failed',
            text: 'Failed to create a new OAuth platform!',
            type: 'error',
            confirmButtonText: 'OK',
            showCloseButton: true,
          }

          if (error.response.data.message) {
            swal.text = error.response.data.message;
          }
          this.$swal(swal)
          this.disabled_button = false;

        });
    },
    fetchPlatforms() {
      api.settings.getPlatformConfigs().then(response => {

        this.$store.dispatch('updatePlatformConfigs', response.data);

      }).catch((error)=>{
        console.log(error);
      });
    }

  },
  computed: {
    ...mapGetters({activePlatforms : 'activePlatforms'}),
    platformsSorted() {

      if(this.activePlatforms.length === 0) this.fetchPlatforms();

      return _.sortBy(this.activePlatforms, ['name', 'code']);
    },
    SelectedPlatformComponent() {
      return this.selected_platform ? this.selected_platform.name + 'Input' : null;
    }
  }
}
</script>


<style>
.btn-primary.disabled,
.btn-primary:disabled {
  color: #686767 !important;
  background-color: #e1e0e0 !important;
  border-color: #e1e0e0 !important;
}
</style>
<style scoped>
.error {
  border: 1px solid red;

}


.ibg-new-platform {
  text-align: center;
  background-color: rgb(255 208 221 / 34%) !important;
  padding: 2rem;
  border: unset;
  cursor: pointer;
}

.ibg-hovering {
  color: white !important;
}

.ibg-new-platform h5 {
  color: #f6386b;
  font-weight: 500;
}

.ibg-new-platform:hover {
  background-color: #f6386b !important;
  color: white !important;
}

.ibg-new-platform h5:hover {
  color: inherit;
}

.selected {
  border: 2px solid #ff8bac !important;
  box-shadow: 0px 2px 9px 0px #dd406c61 !important;
  outline-offset: -12px;
}
</style>
