export default function (axios) {
  return {
    all: () => {
      return axios.get(`/roles`)
        .then(response => response.data);
    },
    roleUsers: (id) => {
      return axios.get(`/roles/${id}/users`)
        .then(response => response.data);
    },
  }
}
