<template>
    <b-container fluid>
        <b-row>
            <b-col lg="10" offset="1">
                <iq-card>
                    <template v-slot:body>
                        <b-row>
                            <b-col lg="12" class="mb-4">
                                <h3 class="text-center">
                                    Invoice Log Detail
                                </h3>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col lg="12">
                                <h5 class="text-danger">{{ invoice.summary }}</h5>
                                <div class="table-responsive-sm">
                                    <b-table-simple striped class="text-center">
                                        <thead>
                                            <tr>
                                                <th v-for="(item, index) in invoice.orderSummaryFields" :key="index"
                                                    :class="item.key === 'item' ? 'text-left' : ''">{{ item.label }}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(body, bodyKey) in invoice.orderSummary" :key="bodyKey">
                                                <template v-for="(item, index) in invoice.orderSummaryFields">
                                                    <th v-if="item.key === 'id'" :key="item.key + index">{{ body[item.key] }}
                                                    </th>
                                                    <td v-else-if="item.key === 'item'" :key="item.key + index"
                                                        class="text-left">
                                                        <h6 class="mb-0">{{ body[item.key].title }}</h6>
                                                        <p class="mb-0">{{ body[item.key].description }}</p>
                                                    </td>
                                                    <td v-else :key="item.key + index"
                                                        :class="item.key === 'total' ? 'font-weight-bold' : ''">
                                                        {{ body[item.key] }}
                                                    </td>
                                                </template>
                                            </tr>
                                        </tbody>
                                    </b-table-simple>
                                </div>
                                <h5 class="text-success">{{ invoice.detail }}</h5>
                                <div class="table-responsive-sm">
                                    <b-table-simple striped>
                                        <thead>
                                            <tr>
                                                <th v-for="(item, index) in invoice.OrderDetailField" :key="index"
                                                    :class="item.key === 'bank' ? 'text-left' : ''">{{ item.label }}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(body, bodyKey) in invoice.OrderDetails" :key="bodyKey">
                                                <template v-for="(item, index) in invoice.OrderDetailField">
                                                    <th v-if="item.key === 'bank'" :key="item.key + index">{{ body[item.key]
                                                    }}</th>
                                                    <td v-else :key="item.key + index"
                                                        :class="item.key === 'total' ? 'font-weight-bold' : ''">
                                                        {{ body[item.key] }}
                                                    </td>
                                                </template>
                                            </tr>
                                        </tbody>
                                    </b-table-simple>
                                </div>
                            </b-col>
                        </b-row>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
import { sofbox } from '../../config/pluginInit'
import api from '@/api/RestClient'

export default {
    name: 'Invoice',
    mounted() {
        sofbox.index()
        this.init();
    },
    data() {
        return {
            image: require('../../assets/images/logo.png'),
            title: 'Invoice',
            invoice: {
                name: 'Hello, Nik Jones',
                summary: 'Old Entree',
                detail: 'New Entree',
                description: 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using \'Content here, content here\', making it look like readable English.',
                order: [
                    {
                        orderDate: 'Jan 17, 2016',
                        orderStatus: 2,
                        orderID: '250028',
                        billingAddress: 'PO Box 16122 Collins Street West <br> Victoria 8007 Australia <br> Phone: +123 456 7890 <br> Email: demo@sofbox.com <br> Web: www.sofbox.com',
                        shippingAddress: 'PO Box 16122 Collins Street West <br> Victoria 8007 Australia <br> Phone: +123 456 7890 <br> Email: demo@sofbox.com <br> Web: www.sofbox.com'
                    }
                ],
                orderSummaryFields: [
                    {
                        key: 'id',
                        label: '#'
                    },
                    {
                        key: 'item',
                        label: 'Description'
                    },
                    {
                        key: 'quantity',
                        label: 'Quantity'
                    },
                    {
                        key: 'price',
                        label: 'Price'
                    },
                    {
                        key: 'discount',
                        label: 'Discount'
                    },
                    {
                        key: 'total',
                        label: 'Total'
                    }
                ],
                orderSummary: [
                    {
                        id: 1,
                        item: {
                            title: '--',
                            description: '--'
                        },
                        quantity: '0',
                        price: '$0',
                        discount: '$0',
                        total: '$0'
                    },
                ],
                OrderDetailField: [
                    {
                        key: 'id',
                        label: '#'
                    },
                    {
                        key: 'item',
                        label: 'Description'
                    },
                    {
                        key: 'quantity',
                        label: 'Quantity'
                    },
                    {
                        key: 'price',
                        label: 'Price'
                    },
                    {
                        key: 'discount',
                        label: 'Discount'
                    },
                    {
                        key: 'total',
                        label: 'total'
                    }
                ],
                OrderDetails: [
                    {
                        id: '--',
                        item: '--',
                        quantity: 0,
                        price: '$0',
                        discount: '$0',
                        total: '$0'
                    }
                ],
                note: 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using \'Content here, content here\', making it look like readable English.'
            }
        }
    }, 
    methods: {
        init() {
            let params = this.$route.params;
            let id = params.invoice_log_id;
            this.invoiceLogs(id)
        },
        invoiceLogs(id) {
            api.invoiceLogs.get(id)
                .then(response => { 
                    let old_resource = JSON.parse(response.data.old_resource);
                    let resource = JSON.parse(response.data.update_resource);
                    let via = this.$route.query.via;
                    let data = {};
                    if (via == 'invoice') {
                         data = this.dataInvoice(old_resource);
                        this.invoiceSummery(data);
                        data = this.dataInvoice(resource);
                        this.orderDetails(data);
                    }

                    if (via == 'item') {
                        data = this.dataItem(old_resource);
                        this.invoiceSummery(data);
                        data = this.dataItem(resource);
                        this.orderDetails(data);

                    }
                })
                .catch(error => { })
                .finally(() => { })
        },
        invoiceLogData($data) {
            this.invoiceSummery($data)
            this.OrderDetails($data)
            
        },
        invoiceSummery(data) {
            this.invoice.orderSummary[0].id = data['id'];
            this.invoice.orderSummary[0].item.title = data['name'];
            this.invoice.orderSummary[0].item.description = data['description'] ? data['description'] : '--';
            this.invoice.orderSummary[0].quantity = data.quantity;
            this.invoice.orderSummary[0].price = `$${data['amount']}`;
            this.invoice.orderSummary[0].total = `$${data['amount']}`;
        },
        orderDetails(data) {
            this.invoice.OrderDetails[0].id = data['id'];
            this.invoice.OrderDetails[0].item = data['name'] ? data['name'] : '--';
            this.invoice.OrderDetails[0].quantity = data.quantity ? data.quantity : 1;
            this.invoice.OrderDetails[0].price = `$${data['amount']}`;
            this.invoice.OrderDetails[0].total = `$${data['amount']}`;
        },
        dataItem(data) {
             let resp = {
                id: data.id,
                quantity: data.quantity,
                name: data.name,
                discount: data.discount,
                description: data.description,
                price: data.unit_price,
                amount: data.quantity * data.unit_price
            }
            return resp;
        },
        dataInvoice(data) {
            
            let resp = {
                id: data.id,
                quantity: 1,
                name: data.description,
                description: data.description,
                discount: data.discount,
                price: data.amount,
                amount:data.amount
            }
            return resp;
        }
    }
}
</script>
