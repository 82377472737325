<template>
    <b-container fluid>


        <!-- User Interface controls -->
        <b-row class="mb-3">

            <b-col lg="4" class="my-1">
                <b-input-group size="md">
                    <b-form-input id="filter-input" v-model="filter" type="search" placeholder="Search"></b-form-input>
                </b-input-group>
            </b-col>

        </b-row>

        <!-- Main table element -->
        <b-table ref="table" :id="id" striped bordered :responsive="$store.getters['Auth/isAdmin']" :busy="isBusy"
            :items="getData" :fields="fields" :current-page="currentPage" :per-page="perPage" :filter="filter"
            :filter-included-fields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection" stacked="md" show-empty small @filtered="onFiltered"
            @sort-changed="sortingChanged">

            <template #table-busy>
                <div class="text-center d-inline-block my-2">
                    <span class='fa-stack fa-lg'>
                        <i class='fa fa-spinner fa-spin fa-stack-2x fa-fw'></i>
                    </span>&emsp;Processing ...
                </div>
            </template>

            <template #empty="scope">
                <p class="mb-1">No matching records found</p>
            </template>

            <!-- Cell status -->
            <template #cell(name)="row">
                <!-- @onSwitchStatus="onUpdateItem" -->
                {{ row.item.name }}
                <span v-if="row.item.id == row.item.account_owner" class="float-right">
                    <i class="ri-community-fill"></i>
                    <span class="badge badge-primary mx-1">
                        Organization Owner
                    </span>
                </span>
            </template>

            <!-- Cell status -->
            <template #cell(status)="row">
                <!-- @onSwitchStatus="onUpdateItem" -->

                <StatusCell :row="row" />
            </template>

            <!-- Cell status -->
            <template #cell(is_owner)="row">
                <div v-if="checkIsClient(row.item.roles)" class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color mr-0">
                    <div class="custom-switch-inner" :id="row.item.id">
                        <input type="checkbox" data-toggle="tooltip" data-placement="top" title="Active Status"
                            :id="'custom-switch-cell-dt-' + row.item.id" :data-id="row.item.id" :data-name="row.item.id"
                            :checked="row.item.account_owner"
                            @input="onSwitchActive($event, row.item)" :key="row.item.id" :disabled="row.item.account_owner" class="custom-control-input bg-primary"
                            >

                        <label :for="'custom-switch-cell-dt-' + row.item.id" data-on-label="ON" data-off-label="OFF"
                            class="custom-control-label" />
                    </div>
                </div>
                <div v-else>
                    <p>--</p>
                </div>
            </template>
            

            <template #cell(action)="row">
                <div class="text-center d-flex user-flex">
                    <button v-if="(row.item.id != user.id) && row.item.status" type="button"
                        class="btn iq-bg-success mr-1 btn-sm" data-toggle="tooltip" :title="'Login as:' + row.item.name"
                        @click="loginAs(row.item)">
                        <i class="ri-login-box-line" v-show="true"></i>
                    </button>
                    <button type="button" class="btn iq-bg-primary mr-1 btn-sm" data-toggle="tooltip" data-placement="top"
                        title="" data-original-title="Test" @click="edit(row.item)">
                        <!-- <router-link :to="{ name: 'profile-edit', query: { id: row.item.id } }">
                        </router-link> -->
                        <i class="fa fa-edit" v-show="true"></i>
                    </button>
                    <button v-if="row.item.id != user.id" type="button" class="btn iq-bg-danger mr-1 btn-sm"
                        data-toggle="tooltip" data-placement="top" title="" data-original-title="Test"
                        @click="remove(row.item)">
                        <i class="fa fa-trash" v-show="true"></i>
                    </button>
                </div>
            </template>

        </b-table>

        <!-- Datatble pagination -->
        <b-row class="justify-content-between mt-3">
            <b-col md="9" class="my-1">
                <div class="dataTables_info">
                    <b-row class="dataTables_info justify-content-between" id="lists_info" role="status" aria-live="polite">
                        <b-col md="10" class="">
                            Showing {{ from }}
                            to {{ to }} of {{ totalRows }} entries
                        </b-col>
                        <b-col md="2" class="">
                            <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm">
                            </b-form-select>
                        </b-col>
                    </b-row>
                </div>
            </b-col>
            <b-col md="3" class="my-1">
                <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm"
                    class="my-0"></b-pagination>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import { mapGetters } from 'vuex'
import Spinner1 from '@/components/loaders/spinner1'
import store from '@/store/index'
import api from '@/api/RestClient'
import _ from 'lodash'
import StatusCell from '@/components/inboxgeek/tables/StatusCell.vue'
import moment from 'moment'
import { SERVICES } from "@/constantes";

export default {
    name: 'DtLists',
    components: {
        Spinner1,
        StatusCell
    },
    data() {
        return {
            id: 'dt-users',
            isBusy: false,
            items: [],
            fields: [{
                key: 'id',
                label: '#ID',
                sortable: true,
                formatter: (value, key, item) => {
                    return "#" + item.id
                },
                sortDirection: 'desc',
                class: 'text-center'
            },
            {
                key: 'name',
                label: 'Name',
                sortable: true,
                sortDirection: 'desc',
                class: 'text-left'
            },
            {
                key: 'email',
                label: 'Email',
                sortable: true,
                thStyle: {
                    width: "20em"
                },
                sortDirection: 'desc',
                class: 'text-center'
            },
            {
                key: 'status',
                label: 'Status',
                sortable: false,
                sortDirection: 'desc',
                class: 'text-center'
            },
            {
                key: 'account',
                label: 'Organization',
                sortable: false,
                sortDirection: 'desc',
                class: 'text-center'
            },
            {
                key: 'role',
                label: 'Roles',
                sortable: false,
                class: 'text-center'
            },
            {
                key: 'is_owner',
                label: 'Is owner?',
                sortable: false,
                class: 'text-center'
            },
            {
                key: 'created_at',
                label: 'Date',
                sortable: true,
                sortDirection: 'desc',
                class: 'text-center'
            },
            {
                key: 'action',
                label: 'Action',
                sortable: false,
                class: 'text-center'
            },

            ],
            totalRows: 1,
            currentPage: 1,
            perPage: 25,
            pageOptions: [25, 50, 100, {
                value: 100,
                text: "Show a lot"
            }],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: ["name", "email"],
            account: -1,
            dates: null,
            $search: '',
            from: null,
            to: null,
            active: 0
        }
    },
    computed: {
        ...mapGetters({
            user: 'Auth/user',
            tokenAs: 'Auth/tokenAs',
            userAdmin: 'Auth/userAdmin'
        }),
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return {
                        text: f.label,
                        value: f.key
                    }
                })
        }
    },
    mounted() {
        this.loadFields()
    },
    methods: {
        async getData(ctx) {
            await this.delay();
            return await this.fetchData(ctx);
        },
        async fetchData(ctx) {

            var items = []
            this.items = [];
            this.isBusy = true;
            let queryParams = {}
            if (typeof ctx !== "undefined") {
                queryParams = {
                    search: this.filter,
                    page: ctx.currentPage,
                    per_page: ctx.perPage,
                    sort_by: ctx.sortBy,
                    sort_desc: ctx.sortDesc,
                    filter_on: JSON.stringify(this.filterOn),
                }
            }

            return api.users.list(queryParams)
                .then((response) => {
                    items = response.data.data;
                    this.totalRows = response.data.total
                    this.currentPage = response.data.current_page
                    this.perPage = response.data.per_page
                    this.items = items
                    this.from = response.data.from
                    this.to = response.data.to
                    return items
                })
                .catch((err) => {
                    console.log(err)
                    return []
                })
                .finally(() => {
                    setTimeout(() => {
                        this.isBusy = false;
                        this.$root.$emit('updatedTable');
                    }, 400);
                })
        },
        delay: () => {
            return new Promise(resolve => setTimeout(resolve, 2000));
        },
        onSwitchActive(a, user) {
            console.log(user); return
            return api.accounts.update(user.account_id, {user_id: user.id})
                .then((response) => { 
                    this.toast('Success', 'success', response.data.message);
                }).catch((error) => {
                    console.log(error);
                }).finally(() => {
                   //this.$root.$emit('bv::refresh::table', this.id);
                })
        },
        loadFields() {
            let fields = _.map(this.fields, (item) => {
                if (store.getters['Auth/isAdmin']) {
                    return item;
                } else if(store.getters['Auth/isAccountManager']) {
                    if (!["action"].includes(item.key)) return item;
                } else {
                    if (!["account"].includes(item.key)) return item;
                }
                    
            })
            this.fields = fields;
        },

        // Filter
        onFiltered(filteredItems) {
        },
        sortingChanged(ctx) {
            this.currentPage = 1
        },
        edit(item) {
            this.$emit('handleEdit', item)
        },
        remove(item) {
            this.$swal({
                title: 'Are you sure?',
                text: 'You can\'t revert your action',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes Delete it!',
                cancelButtonText: 'No, Keep it!',
                showCloseButton: true,
                showLoaderOnConfirm: true
            }).then((result) => {
                if (result.value) {
                    api.users.delete(item.id).then(response => {
                        let varian = response.success ? 'success' : 'danger';
                        this.toast('Delete user', varian, response.message);
                        if (response.success) this.$root.$emit('bv::refresh::table', this.id);
                    })
                } else {
                    this.$swal('Cancelled', 'Your file is still intact', 'info')
                }
            })
        },
        loginAs(item) {

            // Get client token set store app update state and redirect to dashboard if success
            api.auth.loginAs(item.email)
                .then(response => {
                    this.$store.dispatch('Auth/updateLiveOpenersSubscribed', false);
                    this.$store.dispatch('Auth/updateLiveLeadsSubscribed', false);

                    let userAs = response.data;
                    let user = userAs.user;
                    let subscribed = userAs.subscribed;
                    let roles = user.roles.map(function (role) {
                        return role.slug;
                    });

                    this.$store.dispatch('Auth/updateTokenAs', response.data.access_token);
                    this.$store.dispatch('Auth/updateUserAdmin', this.user);
                  this.$store.dispatch('Auth/initUserMetaDta',response.data.meta_data ?? [])
                    let admin = false;
                    if (roles.includes('ROLE_SUPER_ADMIN')) admin = true;
                    user['role_id'] = 1;

                    this.$store.dispatch('Auth/updateisAdmin', admin);
                    this.$store.dispatch('Auth/updateisClient', !admin);
                    this.$store.dispatch('Auth/updateUser', user);


                    let liveOpensPlan, liveLeadsPlan = null;
                    let liveOpensSubscription, liveLeadsSubscription = null;

                    // Get Services LiveSubscriptions
                    liveOpensSubscription = userAs.live_services_subscriptions[SERVICES.LIVE_OPENS.id] ?? null;
                    liveLeadsSubscription = userAs.live_services_subscriptions[SERVICES.LIVE_LEADS.id] ?? null;

                    // Get Live plans
                    liveOpensPlan = userAs.live_plans[SERVICES.LIVE_OPENS.id] ?? null;
                    liveLeadsPlan = userAs.live_plans[SERVICES.LIVE_LEADS.id] ?? null;


                    // LiveSubscriptions and LivePlans
                    this.$store.dispatch('Auth/updateLivePlans', userAs.live_plans);
                    this.$store.dispatch('Auth/updateLastSubscriptions', userAs.last_services_plans);
                    this.$store.dispatch('Auth/updateUserSubscriptions', userAs.live_services_subscriptions);

                    // if (liveOpensSubscription || this.$store.getters['Auth/isAccountManager']) {
                    if ((liveOpensSubscription && (liveOpensPlan && !liveOpensPlan.is_trial)) || this.$store.getters['Auth/isAccountManager']) {
                        this.$store.dispatch('Auth/updateLiveOpenersSubscribed', true);
                    }
                    // if (liveLeadsSubscription || this.$store.getters['Auth/isAccountManager']) {
                    if ((liveLeadsSubscription && (liveLeadsPlan && !liveLeadsPlan.is_trial)) || this.$store.getters['Auth/isAccountManager']) {
                        this.$store.dispatch('Auth/updateLiveLeadsSubscribed', true);
                    }

                    api.settings.getPlatformConfigs().then(response => {

                      this.$store.dispatch('updatePlatformConfigs', response.data);

                      window.location.assign("/");

                    }).catch((error)=>{

                      window.location.assign("/");
                    });
                })
        },
        toast(title = "success", variant = "success", message = "Account chargerd") {
            this.$bvToast.toast(message, {
                title: title,
                variant: variant
            })
        },
        checkIsClient(roles = []) {
            let role = _.filter(
                roles, function (o) {
                    return o.slug == "ROLE_CLIENT";
                }
            );
            return !_.isEmpty(role);
        }  
        },
}
</script>

<style>
td.lists>div,
td.integration>div {
    width: 10em;
}

td.tag>div,
td.account>div {
    width: 8em;
}

.user-flex {
    justify-content: center;
}
</style>
