<template>
    <div class="d-flex justify-content-center">

        <IgBtn v-show="row.item.platform" :ref="'btn-test'" :row="row" title="Test Send Flow" :btnClass="'btn iq-bg-primary mx-1 btn-sm'"
            :btnTitle="'Test Flow'" :icon-class="'ri-mail-send-line text-info fa-lg mx-auto'"
            @onClick="onClick({ ...$event.target, title: 'test' }, row.item, 'btn-test')"></IgBtn>

        <IgBtn v-show="row.item.platform" :ref="'btn-logs'" :row="row" title="logs" :btnClass="'btn iq-bg-primary mx-1 btn-sm'"
                :btnTitle="'Histories'" :icon-class="'fa fa-history m-0 text-info fa-lg mx-auto'"
                @onClick="onClick({ ...$event.target, title: 'logs' }, row.item, 'btn-logs')"></IgBtn>

        <IgBtn :ref="'btn-detail'" :row="row" title="Go to details" :btnClass="'btn iq-bg-success mx-1 btn-sm'"
            :btnTitle="'Detail'" :icon-class="'ri-link pull-right fa-lg mx-auto'"
            @onClick="onClick({ ...$event.target, title: 'detail' }, row.item, 'btn-detail')"></IgBtn>

        <IgBtn :ref="'btn-copy'" :row="row" title="Copy Script" :btnClass="'btn iq-bg-secondary mx-1 btn-sm'"
            :btnTitle="'Copy'" :icon-class="'ri-file-copy-2-line pull-right fa-lg text-info mx-auto'"
            @onClick="onClick({ ...$event.target, title: 'copy' }, row.item, 'btn-copy')"></IgBtn>

        <IgBtn :ref="'btn-chart'" :row="row" title="Show Chart" :btnClass="'btn iq-bg-primary mx-1 btn-sm'"
            :btnTitle="'Show Chart'" :icon-class="'fa fa-eye mx-auto'"
            @onClick="onClick({ ...$event.target, title: 'chart' }, row.item, 'btn-chart')"></IgBtn>

        <IgBtn ref="btn-delete" v-if="row.item.active >= 0" :row="row" title="Delete"
            :btnClass="'btn iq-bg-danger mx-1 btn-sm'" :btnTitle="'Delete'" :icon-class="'fa fa-trash mx-auto'"
            @onClick="onClick({ ...$event.target, title: 'Delete' }, row.item, 'btn-delete')"></IgBtn>

    </div>
</template>

<script>
import IgBtn from '@/components/inboxgeek/btn/Default.vue'
import { helper } from '@/helpers'

export default {
    name: 'GeSourceActions',
    props: ['row'],
    components: {
        IgBtn
    },
    data() {
        return {
        }
    },
    mounted() {
    },
    methods: {
        onClick(event, row, ref) {

            if( typeof this.$refs[ref] !== 'undefined' ) {
                this.$refs[ref].setState();
            }

            this.$emit('onClick', { row, event, ref: this.$refs[ref] })
        },
    }
}

</script>
