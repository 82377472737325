<template>
	<div>

		<iq-card>
			<template v-slot:headerTitle>
				<h4 class="card-title"><i class="ri-notification-line"></i> Notifications {{ isClient ? 'Client' : 'Team' }}
				</h4>
			</template>
			<template v-slot:headerAction>
				<b-row class="text-right" style="justify-content: end;padding: 15px;">
					<b-button type="submit" variant="primary" @click="handleAdd($event)">
						Add Notification
					</b-button>
				</b-row>
			</template>
			<template v-slot:body>
				<!-- <b-form-group class="text-right" label="Select Notifications to Display" v-slot="{ ariaDescribedby }">
					<b-form-checkbox-group v-model="typesDisplayed" :options="types" :aria-describedby="ariaDescribedby"
						name="buttons-1" buttons button-variant="primary"></b-form-checkbox-group>
				</b-form-group> -->

				<DataTable v-if="renderDT" :ref="`notifications-table`" :url="`/notifications`"
					v-bind="{ columns: dtColumns, customCells: dtCells, sort: 'subject', isClient: isClient }" :per="5"
					@onClick="handleBtn" @onSwitch="handleSwitchAttr" />

			</template>
		</iq-card>


		<!-- Start Add Setting Modal-->
		<b-modal :id="notificationModal.id" :ref="notificationModal.id" :title="notificationModal.title" size="lg" centered
			hide-footer class="bg-secondary py-0">
			<FormNotification col=12 :modal="notificationModal" :item="notificationModal.item"
				@modalHide="closeModal(notificationModal)" @handleSubmit="handleSubmit" />
		</b-modal>
		<!-- END Add Setting Modal-->

		<!-- Start Add Setting Modal-->
		<b-modal :id="notifContentModal.id" :ref="notifContentModal.id" :title="notifContentModal.title" size="lg" centered
			hide-footer class="bg-secondary py-0">
			<div v-if="notifContentModal.item"><span v-html="notifContentModal.item.message"></span></div>
		</b-modal>
		<!-- END Add Setting Modal-->

	</div>
</template>

<script>
import Multiselect from "vue-multiselect";
import Spinner1 from '@/components/loaders/spinner1';
import api from '@/api/RestClient';
import DataTable from '@/components/inboxgeek/tables/DataTable.vue';
import DtNotifRoles from '@/views/Apps/children/DtNotifRoles.vue';
import DtNotifActions from '@/views/Apps/children/DtNotifActions.vue';
import DtNotifMessage from '@/views/Apps/children/DtNotifMessage.vue';
import FormNotification from '@/views/Apps/children/FormNotification';
import { helper } from '@/helpers'
import MailSwitchCell from '@/components/inboxgeek/tables/IBGSwitchCell.vue'
import StatusSwitchCell from '@/components/inboxgeek/tables/IBGSwitchCell.vue'

export default {

	name: "DtNotifications",
	props: {
		isClient: {
			type: [Boolean],
			default: false
		}
	},
	components: {
		Spinner1,
		Multiselect,
		DataTable,
		DtNotifRoles,
		DtNotifActions,
		FormNotification
	},
	mounted() {
		console.log('this.isClient', this.isClient)
	},
	data() {
		return {
			loading: true,
			isFilter: false,
			renderDT: true,
			filters: {},
			dtColumns: [
				{ label: 'Name', key: 'name', class: 'text-left', sortable: true, sortDirection: 'desc' },
				{ label: 'Subject', key: 'subject', class: 'text-left', sortable: true, sortDirection: 'desc' },
				{
					label: 'Created at', key: 'created_at', class: 'text-center', sortable: true, sortDirection: 'desc',
					formatter: (value, key, item) => {
						let dt = new Date(value);
						return helper.formatDate(dt)
					}
				},
				{ label: 'Active', key: 'active', class: 'text-center', sortable: true, sortDirection: 'desc' },
				{ label: 'Is Mail', key: 'is_mail', class: 'text-center', sortable: true, sortDirection: 'desc' },
				{ label: 'Action', key: 'actions', class: 'text-center', sortable: true, sortDirection: 'desc' },
			],
			dtCells: [
				{
					key: 'message',
					component: DtNotifMessage,
					event: null,
				},
				{
					key: 'active',
					component: StatusSwitchCell,
					event: 'onSwitch',
					props: {
						attribute: 'active',
					}
				},
				{
					key: 'is_mail',
					component: MailSwitchCell,
					event: 'onSwitch',
					props: {
						attribute: 'is_mail',
					}
				},
				{
					key: 'actions',
					component: DtNotifActions,
					event: 'onClick',
				}
			],
			notificationModal: {
				id: 'modal-add-notif',
				title: 'Add Notification',
				item: {}
			},
			notifContentModal: {
				id: 'modal-notif-content',
				title: 'Show Notification Content',
				item: {}
			},

			typesDisplayed: [], // Must be an array reference!
			types: [
				{ text: 'Client', value: 'client' },
				{ text: 'Team', value: 'team' },
			]
		}
	}
	,
	methods: {
		handleAdd(event) {
			this.notificationModal.item = null;
			this.notificationModal.title = `Add Notification`;
			this.$root.$emit('bv::show::modal', this.notificationModal.id)
		},
		closeModal(modal) {
			this.$root.$emit('bv::refresh::table', modal.id)
		},
		handleSubmit(data) {

			const that = this

			let swal = {
				icon: 'success',
				title: 'Notification Added',
				text: 'Notification Added Successfully',
				type: 'success',
				confirmButtonText: 'OK',
				showCloseButton: true,
			}

			if (!data || !data.id) {
				swal.text = 'Something went wrong'
				swal.type = 'danger'
				swal.icon = 'warning'

				this.$swal(swal)
				return
			}

			if (data.id && !data.new) {
				swal.title = 'Notification Updated'
				swal.text = 'Notification updated successfully'
			}

			this.$swal(swal).then((result) => {
				that.$root.$emit('bv::hide::modal', this.notificationModal.id)
				this.$root.$emit('bv::refresh::table', this.$refs[`notifications-table`].id)
			})
		},
		handleBtn(data) {

			if (!data.event || !data.row) {
				return
			}

			let action = data.event.title.toLowerCase();

			// this.ge_source = data.row;


			if (action == 'edit') {
				this.handleEdit(data.row)
			} else if (action == 'show') {
				this.handleShowMessage(data.row)
			}

			helper.delay(2000).then(() => {
				if (data.ref) {
					data.ref.setState(false);
				}
			});
		},
		handleEdit(item) {
			if (!item || !item.id) {
				return
			}
			this.notificationModal.title = `Edit Notification - ${item.subject}`
			this.notificationModal.item = item
			console.log("item", item)
			this.$root.$emit('bv::show::modal', this.notificationModal.id)
		},
		handleShowMessage(item) {
			if (!item || !item.id) {
				return
			}
			this.notifContentModal.subject = `${item.subject}`
			this.notifContentModal.item = item
			this.$root.$emit('bv::show::modal', this.notifContentModal.id)
		},
		handleSwitchAttr(data) {

			if (!data.input || !data.row || !data.key) {
				return
			}

			let itemCode = data.row.id
			let form = {}
			form[data.key] = data.input.checked ? true : false

			let toast = { message: 'Something went wrong!', title: 'Error', variant: 'danger' }


			return api.notifications.put(itemCode, form)
				.then(result => {
					toast = { message: result?.message, title: 'Success', variant: 'success' }
				})
				.catch((error) => {
					data.input.checked = !data.input.checked;
					let errors = [];
					toast.message = '';

				})
				.finally(() => {
					this.$root.$bvToast.toast(toast.message, toast);
				});
		},
		removeItem(item) {

			if (!item.id) {
				return
			}

			this.$swal({
				title: 'Are you sure?',
				html: `<p>Once you delete this Notification Event</p>`,
				type: 'danger',
				showCancelButton: true,
				confirmButtonText: 'Yes Delete it!',
				cancelButtonText: 'No, Keep it!',
				showCloseButton: true,
				showLoaderOnConfirm: true
			}).then((result) => {
				if (result.value) {
					let swalObj = {
						type: 'success',
						confirmButtonText: 'OK',
						showCloseButton: true,
					}

					api.notificationTypes.delete(item.id)
						.then(result => {
							swalObj.title = 'Success!';
							swalObj.text = result.message;
						})
						.catch(function (error) {
							swalObj.type = 'warning';
							swalObj.title = 'Warning!';
							swalObj.text = 'Something went wrong!';
						})
						.finally(() => {
							this.$swal(swalObj)
							this.$root.$emit('bv::refresh::table', this.$refs[`notifications-table`].id)
						});
				}
			})
		},
	}
}

</script>