<template>
    <div class="d-flex justify-content-center">

        <IgBtn :ref="'btn-edit'" :row="row" title="Edit Question" :btnClass="'btn iq-bg-success mx-1 btn-sm pb-0'"
            :btnTitle="'Edit Question'" :icon-class="'ri-edit-box-line text-success fa-lg mx-auto'"
            @onClick="onClick({ ...$event.target, title: 'edit' }, row.item, 'btn-edit')"></IgBtn>

        <IgBtn :ref="'btn-delete'" :row="row" title="Delete Question" :btnClass="'btn iq-bg-danger mx-1 btn-sm'"
            :btnTitle="'Delete Question'" :icon-class="'fa fa-trash text-danger fa-lg mx-auto'"
            @onClick="onClick({ ...$event.target, title: 'delete' }, row.item, 'btn-delete')"></IgBtn>

    </div>
</template>

<script>
import IgBtn from '@/components/inboxgeek/btn/Default.vue'
import { helper } from '@/helpers'

export default {
    name: 'DtLoDailyLimitAutoUpdateActions',
    props: ['row'],
    components: {
        IgBtn
    },
    methods: {
        onClick(event, row, ref) {

            if (typeof this.$refs[ref] !== 'undefined') {
                this.$refs[ref].setState();
            }

            this.$emit('onClick', { row, event, ref: this.$refs[ref] })
        },
    }
}

</script>
