export default function (axios) {
  return {
    list:() => {
      return axios.get(`/plans`)
        .then(response => response.data);
    },
    changePlan: (planId,accountId) => {

      return axios.put(`accounts/${accountId}/plans/change`,{"plan_id" : planId})
      .then(response => response.data);

    },
    get: (planId) => {
      return axios.get(`/plans/${planId}`)
        .then(response => response.data);
    },
    planByAccount: (accountId,service_id = 1) => {
      return axios.get(`accounts/${accountId}/current_plan`,{ params: { service_id: service_id } })
        .then(response => response.data);
    },
    getActive: (params = { service : 1}) => {
      return axios.get(`/plans/active`, { params })
        .then(response => response.data);
    },
    post: (planData) => {
      return axios.post(`/plans`, planData)
        .then(response => response.data)
    },
    put: (planId, planData) => {
      return axios.put(`/plans/${planId}`, planData)
        .then(response => response.data)
    },
    delete: (planId) => {
      return axios.delete(`/plans/${planId}`)
        .then(response => response.data);
    },
  }
}
