import Vue from 'vue'
import Vuex from 'vuex'
import Auth from './Auth/index'
import createPersistedState from "vuex-persistedstate";
import {PLATFORMS_ITEMS} from "@/constantes";
Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  state: {
    auth2Service: {
      authUrl: null,
      params: null,
      organizationID: null,
    },
    platformConfigs:[],
    subscription : null,
    freeAccount:false,
    loader: false,
    title: 'Dashboard'
  },
  plugins: [createPersistedState()],
  actions: {
    updatePageTitle(context, payload) {
      context.commit('commitTitle', payload)
    },
    updateLoggedIn (context, payload) {
      context.commit('commitLoggedIn', payload)
    },
    updateLoader (context, payload) {
      context.commit('commitLoader', payload)
    },
    updateFreeAccount(context, payload) {
      context.commit('commitFreeAccount', payload)
    },
    updateOAuth2AuthUrl(context, payload) {
      context.commit('commitOAuth2AuthUrl', payload)
    },
    updateOAuth2AuthParams(context, payload) {
      context.commit('commitOAuth2AuthParams', payload)
    },
    updateOAuth2Organization(context, payload) {
      context.commit('commitOAuth2Organization', payload)
    },
    updateSubscriptionOptions(context, payload) {
      context.commit('commitSubscriptionOptions', payload)
    },
    updatePlatformConfigs (context,payload) {
      context.commit('commitPlatformConfigs', payload)
    }
  },
  getters: {
    auth2Service: state => state.auth2Service,
    freeAccount:  state => state.freeAccount,
    loader:       state => state.loader,
    title:        state => state.title,
    subscription: state => state.subscription,
    platformConfigs : state => state.platformConfigs,
    activePlatforms : state =>{

      const activePlatformNames = state.platformConfigs
          .filter(config => config.active === 1)
          .map(config => config.name.toLowerCase());

      return  PLATFORMS_ITEMS.filter(platform => {
           return  activePlatformNames.includes(platform.name.toLowerCase());
      });
    }
  },
  mutations: {
    commitTitle (state, data) {
      state.title = data
    },
    commitLoader (state, data) {
      state.loader = data
    },
    commitFreeAccount (state, data) {
      state.freeAccount = data
    },
    commitOAuth2AuthUrl (state, data) {
      state.auth2Service.authUrl = data
    },
    commitOAuth2AuthParams (state, data) {
      state.auth2Service.params = data
    },
    commitOAuth2Organization (state, data) {
      state.auth2Service.organizationID = data
    },
    updateSubscriptionOptions (state,data) {
      state.subscription.options = data;
    },
    commitPlatformConfigs (state,data) {
      state.platformConfigs = data;
    }
  },
  modules: {
    Auth
  },
  strict: debug
})
