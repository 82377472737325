<template>
<b-container fluid>
    <b-row>
        <b-col lg="12" v-for="(item, index) in charts" :key="index">
            <iq-card>
                <template v-slot:headerTitle>
                    <h4 class="mt-2">{{ item.title }}</h4>
                    <p class="information-border-*" v-if="!isEmpty(entity)">
                        <span> <small class="font-weight-bold">Platform:</small> <small>{{ entity.platform.name ?? '--' }}</small>, </span>
                        <span> <small class="font-weight-bold">Lists:</small> <small>{{ getIntegrationsLists(entity.lists) ?? '--' }}</small>, </span>

                        <span> <small class="font-weight-bold">Total Contact:</small> <small>{{ sumByKey(entity.lists, 'total_contacts') }}</small>, </span>
                        <span> <small class="font-weight-bold">Total Events:</small> <small>{{ sumByKey(entity.events, 'events_sent') }}</small>, </span>

                        <span> <small class="font-weight-bold">Daily Cap:</small> <small>{{ entity.limit != -1 ? entity.limit : 'Unlimited' }}</small>, </span>
                        <span> <small class="font-weight-bold">Created at:</small> <small>{{ formatDate(entity.created_at) }}</small></span>
                    </p>
                </template>
                <template v-slot:body>
                    <ApexChart :element="item.type" :chartOption="item.bodyData" :columnsHide="columnsHide" />
                </template>
            </iq-card>
        </b-col>
    </b-row>
</b-container>
</template>

<script>
import {
    sofbox
} from '../../../config/pluginInit'
import api from '@/api/RestClient'

export default {
    name: 'ApexCharts',
    mounted() {
        sofbox.index();
        this.charts.push(this.body());
    },
    props: {
        params: {
            type: Object,
            default: {}
        },
        categories: {
            type: Array,
            default: () => []
        },
        series: {
            type: Array,
            default: () => []
        },
        columnsHide: {
            type: Array,
            default: () => []
        },
        entity: {
            type: Object,
            default: () => {}
        }

    },
    methods: {

        body() {
            return {
                title: this.params.title,
                type: 'line-area',
                bodyData: {
                    chart: {
                        height: 350,
                        type: 'area'
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        curve: 'smooth'
                    },
                    colors: ['#0084ff', '#00ca00', '#008000', '#008080', '#FF00FF', '#800080', '#FFFF00', '#00FFFF', '#800000'],
                    series: this.series,

                    xaxis: {
                        type: 'category',
                        categories: this.categories
                    },
                    tooltip: {
                        x: {
                            format: 'yy-MM-dd'
                        }
                    }
                },
            }
        },
        formatDate(date) {
            var d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2)
                month = '0' + month;
            if (day.length < 2)
                day = '0' + day;

            return [year, month, day].join('-');
        },
        getIntegrationsLists(integration_lists = []) {
            let lists = null;
            lists = _.map(integration_lists, function (list) {
                return list.list_name
            });
            lists = _.join(lists, '|');
            return lists;
        },
        sumByKey(array = [], key = '') {
            let total = 0;
            array.forEach(element => {
                total += key ? element[key] : 0;
            });
            return total;
        },
        isEmpty(obj) {
            for (var prop in obj) {
                if (Object.prototype.hasOwnProperty.call(obj, prop)) {
                    return false;
                }
            }

            return JSON.stringify(obj) === JSON.stringify({});
        }
    },
    data() {
        return {
            charts: [],
        }
    }
}
</script>

<style>
.information-border {
    border-bottom: 2px solid green;
    border-top: 2px solid green;
    margin-top: 10px;
}
</style>
