<template>
  <b-container fluid>
    <b-modal id="add-suppression-wizard-modal" ref="addSuppressionWizardModal" title="New Suppression List" size="xl" centered hide-footer>

      <AddSuppressionWizardModalNav :steps="steps" :current_step_index="current_step_index"></AddSuppressionWizardModalNav>

      <b-row>
        <b-col lg="12" sm="12" class="p-4">
            <div v-if="currentStep">
              <component
                  :account="account"
                  :is="currentStep.component"
                  :form="form"
                  :lists="lists"
                  :loadingProp="loading"
                  @back="handelBackEvent"
                  @cancel="handelCancelEvent"
                  @next="nextStep"
                  @finish="finishWizard"
                  @preloadLists="handelPreloadListsEvent"
                  @listCreated="handelListCreatedEvent"

              />
            </div>

        </b-col>
      </b-row>
    </b-modal>
  </b-container>
</template>


<script>

import ApplySuppressionListWizardModal from '@/components/inboxgeek/modals/Wizards/SuppressionList/steps/ApplySuppressionListWizardModal.vue';
import NameSuppressionListWizardModal from '@/components/inboxgeek/modals/Wizards/SuppressionList/steps/NameSuppressionListWizardModal.vue';
import PlatformListSuppressionListWizardModal from '@/components/inboxgeek/modals/Wizards/SuppressionList/steps/PlatformListSuppressionListWizardModal.vue';
import AddSuppressionWizardModalNav from '@/components/inboxgeek/modals/Wizards/SuppressionList/AddSuppressionWizardModalNav.vue';
import api from "@/api";

export default {
  name:'CreateSuppressionListWizardModal',
  props:['account'],
  components:{
    ApplySuppressionListWizardModal,
    NameSuppressionListWizardModal,
    PlatformListSuppressionListWizardModal,
    AddSuppressionWizardModalNav
  },
  data(){
    return{
      current_step_index:0,
      form:{},
      lists:[],
      loading:false,
      steps:[{
        index:0,
        title:'Initial Step',
        name:'initial',
          component:NameSuppressionListWizardModal.name,
        icon:'ri-profile-line'
      },{
        index:1,
        title:'List Step',
        name:'list',
        component:PlatformListSuppressionListWizardModal.name,
        icon:'ri-file-list-line'
      },{
        index:2,
        title:'Apply Step',
        name:'finish-step',
        component:ApplySuppressionListWizardModal.name,
        icon:'ri-links-line'
      }
      ]

    }
  },
  methods:{

    nextStep(data){

      // save emitted data into the form

      if(data){
        this.form[this.steps[this.current_step_index].name] = data;

      }

      this.current_step_index++;

    },

    handelBackEvent(){

      // remove current step data if exist:

      if(this.form[this.steps[this.current_step_index].name]){
        delete this.form[this.steps[this.current_step_index].name];
      }

      this.current_step_index--;
    },

    handelPreloadListsEvent(platform){

      this.loading=true;
      // fetch lists directly after selecting platform ( saves time to user in the upcoming steps )
      this.lists = [];

      let params = {
        platform_name : platform.name.toLowerCase(),
        entities      : 'lists',
        platform_id   : platform.id
      };

      api
          .platforms
          .fetchServices(params)
          .then((response)=>{

            if(response.data.data){
              this.lists = response.data.data.map((list)=>{
                return {
                  'id' : list.id ?? list.list_id ?? list.listId,
                  'name' : list.name ?? list.listName
                }
              });
            }else{
              this.lists = response.data.map((list)=>{
                return {
                  'id' : list.id ?? list.list_id ?? list.listId,
                  'name' : list.name ?? list.listName
                }
              });
            }

            this.loading=false;

          }).catch(error=>{
        console.log('error',error);
            this.loading = false;
      });
    },

    handelListCreatedEvent(list){
      this.$emit('ListCreated',list);
    },
    finishWizard(){



      // after user confirms data form , send create request, returns response and close the modal.
      this.loading=false;
      this.$swal('success', 'Suppression list successfully created', 'success');
      this.$emit('SuppressionListCreated');
      this.handelCancelEvent();
    },

    handelCancelEvent(){
      this.form = {};
      this.$refs['addSuppressionWizardModal'].hide();
      this.current_step_index=0;

    }

  },
  computed:{
    currentStep(){

      // update current steps when the index is changed

      let current_step = null;

      this.steps
          .forEach((step)=>{
            if(this.current_step_index === step.index){
              current_step = step;
            }
          });

      return current_step;
    }
  }
}

</script>