<template>
  <div>

    <b-row class="my-2">

      <b-form-group class="col-md-3" label-for="option-type">
          <!-- LoDailyLimitAutoUpdate value Section -->
          <label for="">Daily Limit: *</label>

          <label class="float-right">
            <b-form-checkbox v-model="isDefault" name="check-button" button-variant="primary" size="md" switch>
                Default
            </b-form-checkbox>
          </label>

          <input type="number" class="form-control" v-model="value">
          <small v-if="errors.value" class="text-danger">Define a value to add conditions</small>
      </b-form-group>

      <b-form-group v-if="!isDefault" class="col-md-3" label-for="option-type">
        <!-- LoDailyLimitAutoUpdate name Section -->
        <label for="">Condition Type: *</label>
        <b-input-group>
          <multiselect tag-placeholder="Auto-update Parameter" placeholder="Select Parameter"
            label="name" track-by="code" v-model="option" :options="options"
            :multiple="false" :taggable="true">
          </multiselect>
        </b-input-group>

        <small v-if="errors.option" class="text-danger">Select option of Daily Limit Auto Update to add Option</small>

      </b-form-group>


        <b-form-group v-if="!isDefault" class="col-md-2">
            <!-- LoDailyLimitAutoUpdate Option min Section -->
            <label for="">Min: *
              <span v-if="isRate">
                <b-input-group append="%">
                  <b-form-input type="number" min="0" :max="max" step="0.001" v-model="min"></b-form-input>
                </b-input-group>
              </span>
            </label>

            <input v-if="!isRate" type="number" class="form-control" v-model="min">

            <!-- <b-form-input v-else v-model="min" type="range" min="0" :max="max" step="0.001"></b-form-input> -->

            <small v-if="errors.min" class="text-danger">Define min of Daily Limit Auto Update to add Option</small>

        </b-form-group>

      <b-form-group v-if="!isDefault" class="col-md-2">
            <!-- LoDailyLimitAutoUpdate Option max Section -->
            <label for="">Max: *
              <span v-if="isRate">
                <b-input-group append="%">
                  <b-form-input type="number" min="0" :min="min" step="0.001" v-model="max"></b-form-input>
                </b-input-group>
              </span>
            </label>

            <input v-if="!isRate" type="number" class="form-control" v-model="max">

            <!-- <b-form-input v-else v-model="max" type="range" :min="min" max="100" step="0.001"></b-form-input> -->

            <small v-if="errors.max" class="text-danger">Define max of Daily Limit Auto Update to add Option</small>

      </b-form-group>

      <b-col v-if="isDefault" md="7"></b-col>

      <b-form-group class="col-md-2 my-auto">
        <label for="" class="text-light mt-4">--</label>
        <a class="btn btn-primary" @click="addCondition">Add Condition</a>
      </b-form-group>

    </b-row>

    <b-row v-if="!checkIfHasDefault">
      <b-col md="12">
          <b-alert :show="true" variant="danger">
            <div class="iq-alert-text">Add Default value to continue</div>
          </b-alert>
      </b-col>
    </b-row>

   <!-- Option Added -->
    <hr>
    <b-row v-show="valuesOptions.length">
      <b-col md="12">
        <small v-if="errors.valuesOptions" class="text-danger">Add Option to continue</small>

        <b-table class="mt-2" borderless hover :items="valuesOptions" :fields="fields">

          <!-- Cell status -->
          <template #cell(options)="row">

            <span v-if="row.item.isDefault" class="badge badge-success mx-2">Default</span>
            <span v-else-if="!row.value.length" class="badge badge-light mx-2">Not Defined</span>

            <span v-for="(option, i) in row.value" :key="i" class="mx-1">
              <span class="badge badge-info mx-2 position-relative my-auto">
                  {{ option.option_name }}
                  <span v-if="option.min">Min: {{ option.min }} - </span>
                  <span v-if="option.max">Max: {{ option.max }}</span>

                  <!-- style="position: absolute; top: -10px;" -->
                  <span><i class="fas fa-times text-danger fa-lg cursor-pointer pl-2" @click="onDeleteOption($event, row, i)"></i></span>
              </span>
            </span>
          </template>

          <!-- Cell actions -->
          <template #cell(actions)="row">
            <div class="d-flex justify-content-center">
              <button @click="onDeleteValue($event, row)" type="button" class="btn iq-bg-danger mx-1 btn-sm" data-toggle="tooltip"
                  data-placement="top" title="Edit Option">
                  <i v-if="!DtActionLoading" class="ri-subtract-fill text-danger fa-lg mx-auto" title="Edit"></i>
                  <b-spinner v-else small label="Small Spinner" type="grow"></b-spinner>
              </button>
            </div>
          </template>

        </b-table>
      </b-col>
    </b-row>
   <!-- End Option Added -->


    <!-- Navigation buttons -->
    <hr>

    <div class="d-flex justify-content-end" style="gap:5px">
      <button class="btn btn-light" @click="$emit('cancel')">Cancel</button>
      <button class="btn btn-secondary d-flex align-items-center" @click="back">  <i class="ri-arrow-left-line"></i> Back</button>
      <button class="btn btn-primary d-flex align-items-center " :disabled="!valid" @click="next">
        Next <i class="ri-arrow-right-line"></i>
      </button>
    </div>

  </div>
</template>

<script>
import { PREFERENCES_AUTO_UPDATE_LIST } from "@/constantes";
import Multiselect from 'vue-multiselect'

export default {
  name: "OptionsLoDailyLimitAutoUpdateWizardModal",
  props:['form'],
  components: {
    Multiselect
  },
  mounted() {

    if(this.form.initial){
      this.valuesOptions      = this.form.initial.valuesOptions;
    }

    if(this.form.options) {
      this.valuesOptions      = this.form.options.options;
    }

    this.sortValuesOptions();

  },
  data(){
    return {
      options: PREFERENCES_AUTO_UPDATE_LIST,
      isDefault: false,
      value: null,
      option: null,
      min:null,
      max:null,
      valuesOptions: [],
      errors:{
        value:false,
        option: false,
        min: false,
        max: false,
        valuesOptions:false,
      },
      fields: [
        {
          key: 'value',
          label: 'Daily Limit',
          sortable: true,
          sortDirection: 'desc',
          class: 'text-left'
        },
        {
          key: 'options',
          label: 'Conditions',
          sortable: true,
          sortDirection: 'desc',
          class: 'text-left'
        },
        {
          key: 'actions',
          label: 'Actions',
          sortable: false,
          class: 'text-center'
        },

      ],
      DtActionLoading: false,
      valuesDeleted: [],
      optionsDeleted: [],
    }
  },
  methods:{

    next(){

      this.errors = {value: false, option:false, min: false, max: false, valuesOptions: false};

      if(!this.valuesOptions.length){
        this.errors.valuesOptions = true;
        return;
      }

      this.$emit('next',{
        options: this.valuesOptions,
        valuesDeleted: this.valuesDeleted,
        optionsDeleted: this.optionsDeleted
      });

    },
    sortValuesOptions() {

      this.valuesOptions.sort((a, b) => {
        // Compare by value
        if (a.value !== b.value) {
          return a.value - b.value;
        }


      });

    },
    addCondition() {
      this.errors = {value: false, option:false, min: false, max: false, valuesOptions: false};

      if(this.isDefault) {
        this.addDefault();
      } else {
        this.addClassicValue();
      }


      this.sortValuesOptions();
      this.resetConditionForm();

      if(this.isDefault) {
        this.isDefault = !this.isDefault;
      }
    },
    addDefault() {
      if(!this.value){
        this.errors.value = true;
        return false;
      }

      const index = this.valuesOptions.findIndex((o) => o.isDefault == true);

      let valueDefault = {value: this.value, isDefault: true};

      if (index !== -1) {

        this.valuesOptions[index] = valueDefault;

      } else {

        this.valuesOptions.push(valueDefault);

      }

    },
    addClassicValue() {
      if(!this.value){
        this.errors.value = true;
        return false;
      }

      if(!this.option){
        this.errors.option = true;
        return false;
      }

      if(this.min < 0){
        this.errors.min = true;
        return false;
      }

      if(this.max < 0){
        this.errors.max = true;
        return false;
      }

      const index = this.valuesOptions.findIndex((o) => o.value == this.value && !o.isDefault);

      let option = {option_name: this.option.code, min: this.min, max: this.max};
      let valueOptions = {value: this.value, options: []};

      if (index !== -1) {

        const valueItem = this.valuesOptions[index];
        const i = valueItem.options.findIndex((o) => o.option_name.toLowerCase() == option.option_name.toLowerCase());

        if (i !== -1) {
          const oldOption = valueItem.options[i];

          // Add option replace in optionsToDeleted if code defined
          if(typeof oldOption.code !== 'undefined') {

            const j = this.optionsDeleted.findIndex((o) => o == oldOption.code);

            if (j == -1) {
              this.optionsDeleted.push(oldOption.code)
            }

          }

          valueItem.options.splice(i, 1, option);
        } else {
          valueItem.options.push(option);
        }

      } else {

        valueOptions.options = [option];
        this.valuesOptions.push(valueOptions);

      }
    },
    resetConditionForm() {
      this.value =  null;
      this.option =  null;
      this.min = null;
      this.max = null;
    },
    back(){
      this.$emit('back', this.form);
    },
    onDeleteValue($event, row) {

      if(typeof row.item.code !== 'undefined') {

        const index = this.valuesDeleted.findIndex((o) => o == row.item.code);
        if (index == -1) {
          this.valuesDeleted.push(row.item.code)
        }

      }

      if (typeof this.valuesOptions[row.index] !== 'undefined') {

        this.valuesOptions.splice(row.index, 1);

      }

    },
    onDeleteOption($event, row, optionId) {

      if(typeof this.valuesOptions[row.index].options[optionId].code !== 'undefined') {
        const optionCode = this.valuesOptions[row.index].options[optionId].code;

        const i = this.optionsDeleted.findIndex((o) => o == optionCode);
        if (i == -1) {
          this.optionsDeleted.push(optionCode)
        }

      }

      if (typeof this.valuesOptions[row.index] !== 'undefined' && typeof this.valuesOptions[row.index].options[optionId] !== 'undefined') {

        const option = this.valuesOptions[row.index].options[optionId];

        const index = this.valuesOptions[row.index].options.findIndex((o) => o.option_name.toLowerCase() == option.option_name.toLowerCase());


        if(index !== -1) {
          this.valuesOptions[row.index].options.splice(index, 1);
        }

      }

      if(!this.valuesOptions[row.index].options.length) {
        this.onDeleteValue($event, row);
      }
    },
  },
  computed:{
    valid(){

      if(!this.valuesOptions.length && !this.isDefault){
        return false;
      }

      if(!this.checkIfHasDefault) {
        return false;
      }

      return true;
    },
    checkIfHasDefault() {
      let hasDefault = _.find(this.valuesOptions, (o) => { return o.isDefault == true; });
      if(!hasDefault) {
        return false;
      }

      return true;
    },
    isRate() {
      let state = this.option && ['opens_rate', 'complaints_rate', 'clicks_rate', 'bounces_rate'].includes(this.option.code)
      if(state) {
        this.min = 0.000;
        this.max = 50;
      }
      return state;
    }
  },
  watch: {
      'option': {
          handler(newValue, oldValue) {
              if(!this.isRate) {
                this.min = parseInt(this.min);
                this.max = parseInt(this.max);
              }
          },
          deep: true
      },
  },
}
</script>

<style scoped>
  div.input-group-prepend .input-group-text {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }
</style>
