<template>
<iq-card>
    <template v-slot:headerTitle>
        <h4 class="card-title">{{ chart.title }}</h4>
    </template>
    <template v-slot:headerAction>
        <ul class="nav nav-pills">
            <li v-for="(item, index) in menus" class="nav-item">
                <a href="#" :v-model="week_or_month" v-if="menuItem(item)" class="nav-link" :class="nav_class(item)" @click.prevent="getStatsByPeriod(item)">{{ item.name }}</a>
            </li>
        </ul>
    </template>
    <template v-slot:body>
        <div class="libolo">
            <b-row class="mt-2" v-if="showOrganization">
                <b-col md="7" v-if="[-1, 0].includes(week_or_month)">
                    <b-row>
                        <b-col md="5" class="col-md-10px">
                            <b-form-group label="From" label-for="from">
                                <b-form-input id="from" type="date" v-model="form_dates.from" :max="form_dates.to" :value="form_dates.from"></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col md="5" class="col-md-10px">
                            <b-form-group label="To" label-for="to">
                                <b-form-input id="to" type="date" v-model="form_dates.to" :min="form_dates.from" :max="form_dates.max" :value="form_dates.to"></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col md="2" class="col-md-10px">
                            <b-form-group label="To" label-for="submit">
                                <b-button class="action" variant="primary" :disabled="submitload" @click="onSubmit">
                                    <b-spinner small type="grow" v-if="submitload"></b-spinner>
                                    <i v-if="!submitload" title="Submit" class="fa fa-paper-plane m-0"></i>
                                </b-button>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col :md="organizationSelectBox">
                    <b-form-group label="Select Organization">
                        <multiselect v-model="organization" deselect-label="Can't remove this value" track-by="name" label="name" placeholder="Select organization" :options="organizations" :searchable="true" :allow-empty="true">
                        </multiselect>
                    </b-form-group>
                </b-col>

            </b-row>
        </div>
        <div class="dashboard">
            <b-spinner small type="grow" v-if="loader"></b-spinner>
            <div class="d-flex justify-content-around mt-4" v-if="!loader">
                <div v-for="(item, index) in indicators" class="price-week-box mr-5">
                    <span>{{ item.title }}</span>
                    <h2><span class="counter">{{ item.indicator }}</span>
                    </h2>
                </div>
            </div>
            <div v-if="!loader">
                <ApexChart v-if="!loader" :element="slug" :chartOption="chart.bodyData" />
            </div>
        </div>
    </template>
</iq-card>
</template>

<script>
const TOTAL_ORGANIZATIONS = 1;
const ACTIVE_AND_INACTIVE_ORGANIZATIONS = 2;
const ORGANIZATION_PER_SUBSCRIPTION = 3;
const CANCELED_ORGANIZATION = 4;
const EVENTS = 5;
const REVENUE_STATS = 6;
function arrayMax(arr) {
    var len = arr.length,
        max = -Infinity;
    while (len--) {
        if (Number(arr[len]) > max) {
            max = Number(arr[len]);
        }
    }
    return max;
};

function tooltipLabelFormatter(val, categories) {
    let day = new Date().toJSON().slice(0, 10).replace(/-/g, '/');
    let explodeToDay = day.split('/');
    let today = formatDateYMD(explodeToDay[1] + '/' + explodeToDay[2] + '/' + explodeToDay[0]);
    if (categories.includes('Jan') || categories.includes(today) || categories.includes(parseInt(explodeToDay[0]))) return categories[val - 1];
    return 'Week ' + categories[val - 1] + ' (' + getDateRangeOfWeek(categories[val - 1]) + ')';
}
Date.prototype.getWeek = function () {
    var onejan = new Date(this.getFullYear(), 0, 1);
    var today = new Date(this.getFullYear(), this.getMonth(), this.getDate());
    var dayOfYear = ((today - onejan + 86400000) / 86400000);
    return Math.ceil(dayOfYear / 7)
};

function getDateRangeOfWeek(weekNo) {
    var d1 = new Date();
    let numOfdaysPastSinceLastMonday = eval(d1.getDay() - 1);
    d1.setDate(d1.getDate() - numOfdaysPastSinceLastMonday);
    var weekNoToday = d1.getWeek();
    var weeksInTheFuture = eval(weekNo - weekNoToday);
    d1.setDate(d1.getDate() + eval(7 * weeksInTheFuture));
    var rangeIsFrom = eval(d1.getMonth() + 1) + "/" + d1.getDate() + "/" + d1.getFullYear();
    d1.setDate(d1.getDate() + 6);
    var rangeIsTo = eval(d1.getMonth() + 1) + "/" + d1.getDate() + "/" + d1.getFullYear();
    return formatDateYMD(rangeIsFrom) + " to " + formatDateYMD(rangeIsTo);
};

function formatDateYMD(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}
import api from '@/api/RestClient'
import Multiselect from "vue-multiselect"
import AccountsMultiselect from "@/views/children/AccountsMultiselect.vue";
import { helper } from '@/helpers'
export default {
    name: 'ApexDashCharts',
    props: {
        options: {
            type: Object,
            default: null
        }
    },
    components: {
        Multiselect,
        AccountsMultiselect
    },
    mounted() {
        this.setOption();
        this.chartKey();
        this.getStats();

        this.setActiveOganization();
        this.setOrganization();
        window.component = this;
    },
    created() {
        this.initDates();
    },
    methods: {
        loadSeries() {

            if (this.statsData) {
                this.statsData.series.forEach(serie => {
                    this.chart.bodyData.series.push(serie)
                });
            }
        },
        loadIndicators() {
            if (this.statsData) {
                this.indicators = this.statsData.indicators;
            }
        },
        setTitle() {
            if (this.option) {
                this.chart.title = this.option.title;
            }
        },
        setXaxisType() {
            if (this.option) {
                this.chart.bodyData.xaxis = {
                    type: this.option.axis_type
                }
            }
        },
        chartElement() {
            if (this.option) {
                this.slug = this.option.element;
                this.chart.bodyData.yaxis = {
                    min: 0,
                    max: this.getMaxSerie(this.chart.bodyData.series),
                    tickAmount: 5,
                }
            }
        },
        chartKey() {
            if (this.option) {
                this.keyy = this.option.key;
                this.day_week_month = this.option.period;
            }
        },
        setOrganization() {
            if ([EVENTS, REVENUE_STATS].includes(this.option.key)) this.showOrganization = true;
        },
        setLoader() {
            if (this.statsData) {
                this.loader = this.statsData.loading;
            }
        },
        setLabel() {
            if (this.statsData) {
                this.chart.bodyData.labels = this.statsData.labels;
            }
        },
        setOption() {
            if (this.options) {
                this.option = this.options
            }
        },
        setWeekOrMonth(val = 0) {
            this.week_or_month = val;
        },
        getStats() {
            const url = this.option.url;
            const period = this.option.period;
            const component = this.option.key;
            const account = this.organization;

            this.request(url, period, component, account).then(response => {
                const data = response.data;
                this.statsData = data.stats;
            }).catch(err => {
                console.log(err)
            });
        },
        request(url, interval, index, account = null) {
            const stats = new Promise((resolve, reject) => {
                const account_id = account ? '&account_id=' + account.value : '';
                let link = url + '/' + interval + '?c=' + index + account_id
                if (index == 5 && [-1, 0].includes(this.week_or_month)) link += '&from=' + this.form_dates.from + '&to=' + this.form_dates.to
                api.dashboard.statistics(link).then(response => {
                    resolve(response);
                }).catch(err => {
                    reject(err)
                }).finally(() => {
                    this.submitload = false;
                });
            });
            return stats;
        },
        resetData() {
            this.chart.bodyData.series = [];
            this.chart.bodyData.labels = [];
        },
        setActiveOganization() {
            if ([EVENTS, REVENUE_STATS].includes(this.option.key)) {
                api.dashboard.accounts().then(response => {
                    const accountData = response.data;
                    const accounts = response.data;
                    const organizations = accounts.map(item => {
                        const container = {};

                        container.name = item.name;
                        container.value = item.id;

                        return container;
                    })
                    this.organizations = organizations;
                });
            }
        },
        getMaxSerie(series = []) {
            if (!series) return 0;
            let elts = [];
            series.forEach(serie => {
                elts.push(arrayMax(serie.data))
            });
            return arrayMax(elts);
        },

        onSelectAccount(accountSelected) {
            if (store.getters['Auth/isAdmin']) {
                if (accountSelected) {} else {}
            }
        },
        initDates() {

            var date = helper.dateToYMD(new Date());
            var minDate = helper.dateToYMD(helper.AddOrSubractDays(new Date(), 19, false));

            this.form_dates.to = date;
            this.form_dates.from = minDate;
            this.form_dates.max = date;
        },
        onSubmit() {
            this.submitload = true;
            this.loader = true;
            this.resetData();
            this.getStats();
        },
        menuItem(item) {
            return item.components.includes(this.options.key);
        },
        nav_class(item, index) {
            return item.code == this.options.period ? 'active' : '';
        },
        getStatsByPeriod(item) {
            const period = item.code;
            this.setWeekOrMonth(period)
        }
    },
    watch: {
        'statsData': {
            handler(newValue, oldValue) {
                this.setXaxisType();
                this.loadSeries();
                this.loadIndicators();
                this.setTitle();
                this.chartElement();
                this.chartKey();
                this.setLoader();
                this.setLabel();
            }
        },
        'week_or_month': {
            handler(newValue, oldValue) {
                switch (newValue) {
                    case -1:
                    case 0:
                        this.organizationSelectBox = 4
                        break;

                    default:
                        this.organizationSelectBox = 12
                        break;
                }
                this.loader = true;
                this.resetData();
                this.option.period = newValue;
                this.getStats();
            }
        },
        'organization': {
            handler(newValue, oldValue) {
                this.loader = true;
                this.resetData();
                this.getStats();
            }
        }
    },
    data() {
        return {
            chart: {
                title: '***',
                type: 'mixes',
                dot: 1,
                bodyData: {
                    chart: {
                        height: 350,
                        type: 'line',
                        stacked: false
                    },
                    stroke: {
                        width: [3, 3, 3, 3, 3, 3, 3, 3, 3],
                        curve: 'smooth'
                    },
                    plotOptions: {
                        bar: {
                            columnWidth: '50%'
                        }
                    },
                    colors: ['#00ca00', '#0084ff', '#FEB019', '#5A2A27', '#FD6A6A', '#662E9B', '#D7263D', '#C5D86D', '#4CAF50'],
                    series: [],
                    fill: {
                        opacity: [0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5],
                        gradient: {
                            inverseColors: false,
                            shade: 'light',
                            type: 'vertical',
                            opacityFrom: 0.85,
                            opacityTo: 0.55,
                            stops: [0, 100, 100, 100]
                        }
                    },
                    labels: [],
                    markers: {
                        size: 0
                    },
                    xaxis: {
                        type: 'category',
                        interval: 4,
                        tickAmount: 10
                    },
                    yaxis: {
                        min: 0,
                        max: 25,
                        tickAmount: 9
                    },
                    tooltip: {
                        shared: true,
                        intersect: false,
                        y: {
                            formatter: function (y) {
                                if (typeof y !== 'undefined') {
                                    return y.toFixed(0)
                                }
                                return y
                            }
                        },
                        x: {
                            formatter: function (x) {
                                if (typeof x !== 'undefined') {
                                    return tooltipLabelFormatter(x.toFixed(0), this.categoryLabels)
                                }
                                return tooltipLabelFormatter(x, this.categoryLabels)
                            }
                        },
                    },
                    legend: {
                        labels: {
                            useSeriesColors: true
                        },
                        markers: {
                            customHTML: [
                                function () {
                                    return ''
                                },
                                function () {
                                    return ''
                                },
                                function () {
                                    return ''
                                }
                            ]
                        }
                    }
                },
            },
            showOrganization: false,
            indicators: [],
            title: '***',
            slug: null,
            keyy: null,
            loader: true,
            labels: [],
            week_or_month: -1,
            option: null,
            statsData: null,
            organization: null,
            organizations: [],
            y_week_month: null,
            form_dates: {
                from: null,
                to: null
            },
            submitload: false,
            organizationSelectBox: 5,
            menus: [
                {
                    code: 0,
                    name: 'Days',
                    components: [5,6] // components see definition on top
                },
                {
                    code: 1,
                    name: 'Weeks',
                    components: [1,2,3,4,5] // components see definition on top
                },
                {
                    code: 2,
                    name: 'Months',
                    components: [1,2,3,4,5,6]// components see definition on top
                },
                {
                    code: 3,
                    name: 'Years',
                    components: [6] // components see definition on top
                },
            ]
        }
    }
}
</script>

<style>
.dashboard {
    text-align: center;
    margin-bottom: 5px;
}

.libolo {
    width: 50%;
    margin: auto;
}

.col-md-10px {
    position: relative;
    width: 100%;
    padding-right: 10px;
    padding-left: 10px;
}

@media screen and (max-width: 600px) {
    .price-week-box {
        margin-right: 0.5rem !important;
    }

    .libolo {
        width: 90% !important;
    }
}
</style>
