<template>

  <div>
    <h3>Summary:</h3>
    <div class="row p-4" style="gap:10px">

      <div class="col p-4 shadow-sm border rounded">
        <div class="col-12">
          Daily Limit Auto Update
        </div>
        <div class="col">
          <h5>Logic Name: {{ form.logic.name }}</h5>
        </div>
      </div>

      <div class="col p-4 shadow-sm border rounded">
        <div class="col-12">
          Conditions Selected
        </div>
        <div class="col" v-for="(item, index) in form.options.options" :key="index">
          <h5>
            Daily Limit: {{item.value}}
            <span v-if="item.isDefault" class="badge badge-pill badge-success">Default</span>
            <span v-for="(option, k) in item.options" :key="k">
              <span class="badge badge-pill badge-light">{{option.option_name}} - min: {{option.min}} - max: {{option.max}}</span>
            </span>

          </h5>

        </div>

      </div>
    </div>

    <!-- Navigation buttons -->
    <hr>

    <div class="d-flex justify-content-end" style="gap:5px">
      <button class="btn btn-light" @click="$emit('cancel')">Cancel</button>
      <button class="btn btn-secondary d-flex align-items-center" @click="back">  <i class="ri-arrow-left-line"></i> Back</button>

      <button class="btn btn-success d-flex align-items-center " :disabled="loadingProp" @click="finish">
        <b-spinner v-if="loadingProp" small type="grow"></b-spinner> {{ code ? 'Update' : 'Create' }}  <span v-if="loadingProp">...</span>
      </button>
    </div>
  </div>


</template>

<script>
import {PLATFORMS_ITEMS} from "@/constantes";


export default {
  name: "FinishLoDailyLimitAutoUpdateWizardModal",
  props:['form','loadingProp'],
  mounted() {
    if(this.form.initial && typeof this.form.initial.code !== 'undefined'){
      this.code = this.form.initial.code;
    }
  },
  data(){
    return {
      code: null
    }
  },
  methods:{
    finish(){
      this.$emit('finish');
    },
    back(){
      this.$emit('back', this.form);
    }
  },
  computed : {
  }
}
</script>

<style scoped>

</style>
