<template>
    <div>
        <b-form @submit="onSubmit" @reset="onReset" v-if="show">
            <b-row>
                <b-col md="6">
                    <b-form-group id="from_date" label="From:" label-for="from">
                        <b-form-input id="from" v-model="form.from" placeholder="From" type="date"
                            required></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col md="6">
                    <b-form-group id="to_date" label="To:" label-for="to">
                        <b-form-input id="to" v-model="form.to" placeholder="to" type="date" required></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col md="12" class="#">
                    <b-form-group id="input-group-3" label="Status:" label-for="input-3">
                        <b-form-select id="input-3" v-model="form.status" :options="status_resends"
                            required></b-form-select>
                    </b-form-group>
                </b-col>

                <b-col md="12" class="mt-3">
                    <div class="text-center">
                        <b-button type="submit" variant="primary" class="mr-2">
                            Submit
                            <b-spinner small label="Small Spinner" type="grow"
                                v-show="submit_load"></b-spinner></b-button>
                    </div>
                </b-col>
            </b-row>
        </b-form>
    </div>
</template>

<script>
const moment = require('moment');

export default {
    name: 'ResendAutomationForm',
    props: {
        loader: {
            type: Boolean,
            default: false
        },
        item: {
            type:Object,
            default(rawProps) {
                return {}
            }
        }
    },
    data() {
        return {
            form: {
                from: '',
                to: '',
                status: 1,
            },
            status_resends: [{ text: 'Resend all', value: -1 }, { text: 'Resend only unsent records', value: 1 },],
            show: true,
            submit_load: false,
            
        }
    },
    methods: {
        onSubmit(event) {
            event.preventDefault()
            this.submit_load = true;
            this.$emit('onSubmit', this.form)
        },
        onReset(event) {
            event.preventDefault()
            // Reset our form values
            this.form.from = ''
            this.form.to = ''
            this.form.status = 1
        }
    },
    watch: {
        'loader':{
            handler(newValue, oldValue) { 
                this.submit_load = !newValue
            }
        },
        'item':{
            handler(newValue, oldValue) { 
                if (newValue) {
                    this.form = {
                        to: moment(item.to).format('MM/DD/YYYY'),
                        from: moment(item.from).format('MM/DD/YYYY'),
                        status: -1,
                    }
                }
            }
        }
    }
}
</script>