
export default function (axios) {
  return {
    register(data) {
      return axios.post(`/auth/register`, data, {
        noAuth: true
      }).then(response => response.data);
    },
    registerEmail(data) {
      return axios.post(`/auth/register/email`, {"email":data}).then(response => response.data);
    },

    login(email, password, remember = false) {
      if (!email || !password) {
        return Promise.reject(new Error('Both `email` and `password` should be set'));
      }

      return axios.post(`/auth/login`, {
        'email': email,
        'password': password,
        'remember': remember
      }, {
        noAuth: true
      }).then(response => response.data);
    },
    loginAs(email) {
      localStorage.clear();

      if (!email) {
        return Promise.reject(new Error('`email` should be set'));
      }

      return axios.post(`/auth/login-as`, {
        'email': email
      }, {
        // noAuth: true
      }).then(response => response.data);
    },

    logout: () => {
      return axios.post(`/auth/logout`)
        .then(response => response.data);
    },

    refreshToken(refreshToken) {
      return axios.post(`/auth/token/refresh`, {
        'refresh_token': refreshToken
      }, {
        noAuth: true
      }).then(response => response.data);
    },
    adminResetPass: (userid, data) => {
      return axios.post(`/auth/admin-password/${userid}`, data)
        .then(response => response.data);
    },
    resetPassword(email) {
      if (!email) {
        return Promise.reject(new Error('`Email`should be set'));
      }

      return axios.post('/auth/recover-password', {
        email: email
      }, {
        noAuth: true
      }).then(response => response.data);
    },

    resetPasswordConfirm(token) {
      if (!token) {
        return Promise.reject(new Error('`token`should be set'));
      }

      return axios.get(`/auth/password/reset/confirm?token=${token}`, {
        noAuth: true
      })
        .then(response => response.data);
    },
    passwordResetUpdate(token, email = '', firstPassword, secondPassword) {
      if (!token) {
        return Promise.reject(new Error('`token`should be set'));
      }
      return axios.post(`/auth/reset-password`, {
        token: token,
        email: email,
        password: firstPassword,
        password_confirmation: secondPassword,
      }, {
        noAuth: true
      })
        .then(response => response.data);
    },



    generateToken(){

      return axios.post('/auth/api-token')
        .then(response => response.data);
    }


  }
}
