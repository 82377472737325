
export default function platform(axios) {
    return {
        labels: {
            listID: "id",
            listName: "name",
            tagID: "id",
            tagName: "name",
        },
        customShowAnotherCols(COMP) {
            COMP.tagLabel               = 'List *';
            COMP.tagPlaceholder         = 'List';
            COMP.selectTagPlaceholder   = 'List';
        },
        getTagAdded(result) {
            result = 'data' in result ? result.data : result;
            return { code: result.ListID, name: result.Name }
        },
    }
}