<template>
    <b-container fluid>

        <ChatStatsGroup></ChatStatsGroup>

        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title"><i class="ri-mail-line"></i> Chat</h4>
                    </template>
                    <template v-slot:headerAction class="mt-3">
                        <button class="btn btn-primary" @click="$router.push({ name: 'reports.chat' })">Chat Report</button>
                    </template>
                    <template v-slot:body>

                        <div id="chat-page" class="iq-card-body chat-page p-0">
                            <div class="chat-data-block">

                                <div class="row">
                                    <div class="col-lg-3 chat-data-left scroller">
                                        <div class="chat-search pt-3 pl-3">
                                            <div class="d-flex align-items-center">

                                                <div class="chat-profile mr-3">
                                                    <i class="ri-account-circle-fill fa-4x"></i>
                                                    <!-- <img src="images/user/1.jpg" alt="chat-user" class="avatar-60 "> -->
                                                </div>
                                                <div class="chat-caption">
                                                    <h5 class="mb-0">{{ user.name }}</h5>
                                                    <p>{{ user.roles[0].name }}</p>
                                                </div>

                                                <button type="submit" class="close-btn-res p-3"><i
                                                        class="ri-close-fill"></i></button>


                                            </div>

                                        </div>

                                        <div class="chat-sidebar-channel scroller mt-4 pl-3">
                                            <ul class="iq-chat-ui nav flex-column nav-pills">
                                                <h5 class="mt-3">Direct Message</h5>

                                                <spinner1 v-if="roomsIsLoading"></spinner1>

                                                <li v-else v-for="(room, index) in latestRooms" :key="index" @click="getRoomMessages(room)">
                                                    <a role="tab" data-toggle="pill" :href="'#chatbox'+room.id"
                                                        aria-selected="true">
                                                        <div class="d-flex align-items-center">
                                                            <div class="avatar mr-3">
                                                                <i class="ri-account-circle-fill fa-3x"></i>
                                                                <span class="avatar-status"><i class="ri-checkbox-blank-circle-fill text-success"></i></span>
                                                            </div>
                                                            <div class="chat-sidebar-name position position-relative">
                                                                <h6 class="mb-0">{{ room.client ? room.client.name : '--' }}</h6>
                                                                <span v-if="room.new_messages"
                                                                    class="badge badge-pill badge-primary badge-up text-white position position-absolute px-2"
                                                                    style="top: -10px; right: 0px;">{{ room.new_messages }}</span>
                                                                <span>{{ room.name ?? '--' }}</span>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div class="col-lg-9 chat-data p-0 chat-data-right">
                                        <div id="ibg-chat-box" class="tab-content">
                                            <div class="tab-pane fade active show" id="default-block" role="tabpanel">
                                                <div class="chat-start">
                                                    <span class="iq-start-icon text-primary">
                                                        <i class="ri-message-3-line"></i>
                                                    </span>
                                                    <button id="chat-start" class="btn bg-white mt-3">
                                                        Start Conversation!
                                                    </button>
                                                </div>
                                            </div>
                                            <div v-for="(room, index) in chatRooms" :key="index" class="tab-pane fade" :id="`chatbox${room.id}`" role="tabpanel">
                                                <div class="chat-head">
                                                    <header
                                                        class="d-flex justify-content-between align-items-center bg-white pt-3 pl-3 pr-3 pb-3">
                                                        <div class="d-flex align-items-center">
                                                            <div id="sidebar-toggle" class="sidebar-toggle">
                                                                <i class="ri-menu-3-line"></i>
                                                            </div>
                                                            <div class="avatar chat-user-profile m-0 mr-3">
                                                                <i class="ri-account-circle-fill fa-3x"></i>
                                                                <span class="avatar-status"><i
                                                                        class="ri-checkbox-blank-circle-fill text-success"></i></span>
                                                            </div>
                                                            <h5 class="mb-0">{{room.client ? room.client.name : ''}}</h5>
                                                        </div>
                                                        <div id="chat-user-detail-popup" class="scroller">
                                                            <div class="user-profile text-center">
                                                                <button type="submit" class="close-popup p-3"><i
                                                                        class="ri-close-fill"></i></button>
                                                                <div class="user mb-4">
                                                                    <a class="avatar m-0">
                                                                        <i class="ri-account-circle-fill fa-2x"></i>
                                                                    </a>
                                                                    <div class="user-name mt-4">
                                                                        <h4>Monty Carlo</h4>
                                                                    </div>
                                                                    <div class="user-desc">
                                                                        <p>Cape Town, RSA</p>
                                                                    </div>
                                                                </div>
                                                                <hr>
                                                                <div class="chatuser-detail text-left mt-4">
                                                                    <div class="row">
                                                                        <div class="col-6 col-md-6 title">Nik Name:
                                                                        </div>
                                                                        <div class="col-6 col-md-6 text-right">Babe
                                                                        </div>
                                                                    </div>
                                                                    <hr>
                                                                    <div class="row">
                                                                        <div class="col-6 col-md-6 title">Tel:</div>
                                                                        <div class="col-6 col-md-6 text-right">072 143
                                                                            9920</div>
                                                                    </div>
                                                                    <hr>
                                                                    <div class="row">
                                                                        <div class="col-6 col-md-6 title">Date Of Birth:
                                                                        </div>
                                                                        <div class="col-6 col-md-6 text-right">July 12,
                                                                            1989</div>
                                                                    </div>
                                                                    <hr>
                                                                    <div class="row">
                                                                        <div class="col-6 col-md-6 title">Gender:</div>
                                                                        <div class="col-6 col-md-6 text-right">Female
                                                                        </div>
                                                                    </div>
                                                                    <hr>
                                                                    <div class="row">
                                                                        <div class="col-6 col-md-6 title">Language:
                                                                        </div>
                                                                        <div class="col-6 col-md-6 text-right">English
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="chat-header-icons d-flex">

                                                            <a href="#" class="chat-icon-delete" @click.prevent="closeChat(room)">
                                                                <i class="ri-delete-bin-line"></i>
                                                            </a>



                                                        </div>
                                                    </header>
                                                </div>
                                                <div v-if="messagesIsLoading" class="chat-content scroller">
                                                    <spinner1></spinner1>
                                                </div>
                                                <div :ref="'room-messages-'+room?.id" v-else class="chat-content scroller">
                                                    <div v-for="(message, i) in chatRoomMessages" :key="i" class="chat" :class="{ 'chat-left': message.client_id}">
                                                        <div class="chat-user">
                                                            <a class="avatar m-0" :class="{ 'd-none': !message.client_id}">
                                                                <i class="ri-account-circle-fill fa-2x"></i>
                                                            </a>
                                                            <span class="chat-time mt-1">{{ getDateTimeMessage(message) }}</span>
                                                        </div>
                                                        <div class="chat-detail">
                                                            <div class="chat-message">
                                                                <p v-if="message?.message">{{ message.message }}</p>
                                                                <p v-else-if="message.url">
                                                                    <img v-if="isImageFile(message.filename)" class="img-fluid" :src="filePath(message)" :alt="message.name">
                                                                    <a v-else href="#" @click.prevent="downloadFile(message)">{{message.filename}}</a>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="chat-footer p-3 bg-white" style="position: relative; bottom: -23px;">
                                                    <form class="d-flex align-items-center"
                                                        action="javascript:void(0);">

                                                        <input id="admin-chat-file" type="file" @change="handelFileUpload" ref="admin-chat-file" accept="image/*,.pdf,.csv" hidden>

                                                        <div class="chat-attagement d-flex">
                                                            <label class="cursor-pointer" for="admin-chat-file">
                                                                <!-- <i aria-hidden="true" class="fa fa-paperclip px-3 fa-2x"></i> -->
                                                                <i class="fa fa-paperclip pr-3" aria-hidden="true"></i>
                                                            </label>
                                                        </div>

                                                        <input type="text" class="form-control mr-3" placeholder="Type your message" v-model="responseMessage">
                                                        <button type="submit"
                                                            class="btn btn-primary d-flex align-items-center p-2" @click="sendAdminMessage">
                                                            <b-spinner v-if="onsubmit" small variant="white" class> </b-spinner>
                                                            <i v-else class="fa fa-paper-plane-o" aria-hidden="true"></i>
                                                            <span class="d-none d-lg-block ml-1">Send</span>
                                                        </button>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </template>
                </iq-card>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import { sofbox } from "@/config/pluginInit";
import Spinner1 from '@/components/loaders/spinner1'
import _ from 'lodash'
import { mapGetters } from 'vuex'
import api from '@/api/RestClient'
import moment from 'moment'
import Pusher from 'pusher-js';
import ChatStatsGroup from '@/views/Reports/children/ChatStatsGroup'


export default {
    name: "ChatAdmin",
    components: {
        Spinner1,
        ChatStatsGroup
    },
    mounted() {
        sofbox.index();
        this.getChatRooms();
        this.listenNewRooms();
    },
    computed: {
        ...mapGetters({
            isAdmin: 'Auth/isAdmin',
            isClient: 'Auth/isClient',
            user: 'Auth/user',
        }),
      latestRooms(){

          return  this.chatRooms.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));
      }
    },
    methods: {
      listenNewRooms(){
        const pusher  = new Pusher(process.env.VUE_APP_PUSHER_APP_KEY, {cluster: 'eu',});
        const channel = pusher.subscribe('support.chat.room');
        channel.bind('room', (data)=> { this.newChatRoom(data) });

      },
      playNotificationSound(){

        let audio = new Audio(require('@/assets/sounds/notification-sound.mp3'));
        audio.play();
        document.title = document.title.replace(' ( new message )','') + ' ( new message )';
      },
        getChatRooms() {

            this.roomsIsLoading = true;

            api.default.get('/rooms/admin/chats')
                .then(response => {

                    this.chatRooms = response.data

                    const pusher  = new Pusher(process.env.VUE_APP_PUSHER_APP_KEY, {cluster: 'eu',});

                    this.chatRooms.forEach( (room) => {

                        const channel = pusher.subscribe('support.chat.' + room.id);
                        channel.bind('message',  (data)=> {

                          this.messageArrived(data);
                        });

                    });

                })
                .finally(() => {
                    this.roomsIsLoading = false;
                })
        },
        getRoomMessages(room) {
            this.messagesIsLoading = true;
            this.chatRoom = null;

            api.default.get('/rooms/'+room.id+'/messages/admin')
                .then(response => {

                    this.chatRoomMessages = response.length ? response : [];

                    // Count of new message from client
                    if(room) room.new_messages = (_.filter(this.chatRoomMessages, o => !o.read_at && o.client_id)).length;

                })
                .finally(() => {

                    this.messagesIsLoading = false;
                    this.chatRoom = room;
                    // this.scrollToBottomMessagesList();

                    if (room.id == this.$route.query.id) {
                        $('a[href="#chatbox' + room.id + '"]').click()
                    }else{
                        this.$router.replace({ 'query': null });
                    }
                })
        },
        getDateTimeMessage(message) {
            const time = moment(message.created_at);
            const formattedTime = time.format('HH:mm');
            return formattedTime;
        },
        sendAdminMessage() {

            if(!this.responseMessage) {
                return;
            }

            this.onsubmit = true;

            api.default.post('/rooms/' + this.chatRoom.id + '/reply', {
                message: this.responseMessage
            }).then(response => { })
            .finally(() => {
                this.onsubmit = false;
                // this.scrollToBottomMessagesList();
            })

          this.responseMessage = '';
        },
        handleFocusResponse($event) {

            if(!this.chatRoom.hasSeen) {

                api.default.put('/rooms/seen/' + this.chatRoom.id)
                    .then(response => {

                        this.chatRoom.hasSeen = true;

                        // TODO update new message state
                    })

            }


        },
        newChatRoom(room) {

            let chatRoom = _.find(this.chatRooms, { 'id': room.id });

            if(!room.new_messages) {
                room.new_messages = 0;
            }

            if (!chatRoom) {
                this.chatRooms.push(room);

                const pusher = new Pusher(process.env.VUE_APP_PUSHER_APP_KEY, { cluster: 'eu', });

                const channel = pusher.subscribe('support.chat.' + room.id);
                channel.bind('message', (message) => { this.messageArrived(message) });
            }

        },
        messageArrived(data) {

            let chatRoom = _.find(this.chatRooms, { 'id': data.chat_room_id });

            if (chatRoom && data.client_id) {
                chatRoom.new_messages++;
            }

            if(chatRoom && (this.chatRoom.id == chatRoom.id)) {
                this.chatRoomMessages.push(data);
            }

          //  if(data.client_id != )
        },
        filePath(msg) {
            return  msg && msg.url ? `${process.env.VUE_APP_BASE_URL}/${msg.url}` : null
        },
        isImageFile(fileName) {
            const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp", "svg"];
            const fileExtension = fileName.slice((fileName.lastIndexOf(".") - 1 >>> 0) + 2);
            return imageExtensions.includes(fileExtension.toLowerCase());
        },
        handelFileUpload(event) {
            const filePath = event.target.files[0];

            var formData = new FormData();
            formData.append("image", filePath);

            api.default.post(
                `/rooms/${this.chatRoom.id}/upload/admin`,
                formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }).then((result) => {

                })
                .catch((err) => {
                    console.log(err);
                });
          this.resetFileInput();
        },
        resetFileInput() {
            // Reset the value of the input file
            this.$refs['admin-chat-file'].value = '';
        },
        downloadFile(msg) {
            const url = `${process.env.VUE_APP_BASE_URL}/${msg.url}`;

            window.open(url, '_blank');
        },
        scrollToBottomMessagesList() {

            // Get the reference to the scrollable div
            const scrollableDiv = this.$refs['room-messages-'+this.chatRoom?.id];

            console.log( scrollableDiv, scrollableDiv.$el )

            if(scrollableDiv && scrollableDiv.$el) {
                // Scroll the div to its bottom
                scrollableDiv.$el.scrollTo({
                    top: scrollableDiv.$el.scrollHeight,
                    behavior: 'smooth' // You can use 'auto' instead for immediate scrolling
                });
            }
},
        closeChat(room) {

            api.default
                .put(`/rooms/${room.id}/end`)
                .then(response => {
                    this.chatRooms = _.remove(this.chatRooms, obj => obj.id !== room.id);

                    this.chatRoom = null;
                    this.chatRoomMessages = [];

                    this.$swal('Chat Room Close', 'Chat has been closed successfully', 'success')
                })
        },
        updateRoom(id, data) {
            api.default
                .put(`/rooms/${id}`, data)
                .then(response => {
                    console.log(response.message);
                })
                .catch((error) => {
                    console.error(error);
                })
        }
    },
    watch: {
        chatRooms(newValue, oldValue) {
            const chatId = this.$route.query.id;
            if (chatId) {
                const room = {
                    id: chatId
                }
                this.getRoomMessages(room);
            }
            console.log(newValue);
        }
    },
    data() {
        return {
            onsubmit: false,
            roomsIsLoading: false,
            messagesIsLoading: false,
            chatRooms: [],
            chatRoom: null,
            chatRoomMessages: [],
            responseMessage: null,
        };
    },
};
</script>

<style>

.chat-data .tab-content {
    position: relative;
}

#ibg-chat-box .chat-start {
    background: none !important;
    height: calc(100vh - 10rem);
}

span.avatar-status {
    position: absolute;
    bottom: 8px;
    top: auto;
    left: auto;
    right: 0;
}

#ibg-chat-box .chat-message::after {
    border-color: transparent transparent transparent #f6386b;
}

#chat-page img {
    border-radius: 20px;
}

</style>
