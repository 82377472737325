<template>
  <div>
    <Loader />
    <div class="wrapper">

      <div class="iq-top-navbar mb-4">
        <div class="iq-navbar-custom" style="background: rgb(22, 30, 50); color: white !important; box-shadow: rgba(109, 130, 169, 0.64) 0px 0px 29px 11px;">
          <div class="iq-sidebar-logo px-2 my-auto py-0" style="width: 260px;">

            <img :src="logo" class="img-fluid" alt="logo">
          </div>
          <div class="navbar-breadcrumb" style=" display: flex; justify-content: space-between; justify-items: center; align-items: center; height: 72px;margin:0 !important"><h5 class="mb-0 text-white">Cookie Modal Generator</h5></div>
        </div>
      </div>

      <!--  -->

      <div class="container m-auto p-4" style="margin-top: 72px !important;">
        <router-view/>

      </div>
    </div>
  </div>
</template>
<script>
import Loader from '../components/sofbox/loader/Loader'
import logo from '../assets/images/logo.png'



export default {
  name: 'BlankLayout',
  components: {
    Loader
  },
  data () {
    return {
      logo: logo
    }
  },
  mounted () {
  },
  methods:{
  },
}
</script>
<style>
@import url("../assets/css/custom.css");
.modal-backdrop {
  opacity: .5;
}
</style>
