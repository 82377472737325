<template>
    <b-container fluid>
      <b-modal id="addSupportTicketMessageModal" ref="addSupportTicketMessageModal" :title="'Add Ticket Message - '+(ticket ? ticket.subject : '')" size="xl" centered
        hide-footer @hide="content = null">
  
        <b-form-group class="col-md-12">
  
          <b-row class="my-2">

            <b-col md="12" class="mt-3">
              <label for="">Content: *</label>
  
              <VueEditor v-model="content" class="w-100" />
  
              <small v-if="errors?.content" class="text-danger">Support Content is required</small>
            </b-col>
  
          </b-row>
        </b-form-group>
  
  
        <!-- Navigation buttons -->
        <hr>
  
        <div class="d-flex justify-content-end" style="gap:5px">
            <!-- <button class="btn btn-light" @click="$emit('cancel')">Cancel</button> -->
            <button class="btn btn-primary d-flex align-items-center " :disabled="!valid" @click="submit">
                <b-spinner small type="grow" v-show="onsubmit"></b-spinner> 
                Send Ticket Message
            </button>
        </div>
  
      </b-modal>
    </b-container>
  </template>
  
  
  <script>
  
  import api from "@/api";
  import { mapGetters } from "vuex";
  import { VueEditor } from "vue2-editor";
  
  export default {
    name:'CreateSupportTicketMessageModal',
    props:['ticket'],
    components:{
      VueEditor
  
    },
    data(){
      return{
        content: null,
        errors:{
          content:false,
        },
        onsubmit: false,
      }
    },
    methods:{
      submit(e) {
        e.preventDefault();
        this.onsubmit = true;
  
        if(!this.valid) {
          return;
        }
  
        let data = { content:this.content };
  
        api.supportTickets.addMessage(this.ticket.id, data)
          .then(response => {
  
                console.log(response.data, this.ticket);
              
                this.$root.$emit('bv::hide::modal', 'addSupportTicketMessageModal')
                this.$swal('Ticket Message Created', response.message, 'success')
                this.$root.$emit('ibg:create-ticket-message', response.data);
                
          }).catch(err => {
              console.log(err);
          })
          .finally(() => {
              this.onsubmit = false;
          })
      },
  
    },
    computed:{
      ...mapGetters('Auth', ['user', 'isAdmin', 'isClient']),
      currentStep(){
  
        // update current steps when the index is changed
  
        let current_step = null;
  
        this.steps
            .forEach((step)=>{
              if(this.current_step_index === step.index){
                current_step = step;
              }
            });
  
        return current_step;
      },
      valid(){
  
        return this.content;
  
      }
    }
  }
  
  </script>  