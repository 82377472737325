<template>
    <b-form @submit="onFilter">
        <b-row class="mt-2">
            <b-col md="12" lg="12">
                <h4>Search Filters</h4>
                <b-row class="px-2">

                    <b-col md="4" class="text-left">
                        <b-form-group id="label" label="Platforms:" label-for="input-3">
                            <multiselect tag-placeholder="Manage Platform" placeholder="Choose platform" label="name"
                                track-by="code" v-model="form.platforms" deselect-label="Can't remove this value"
                                :allow-empty="true" :options="platforms" :multiple="true" :taggable="false">
                            </multiselect>
                        </b-form-group>
                    </b-col>
                    <b-col md="4">
                        <b-form-group id="input-group-platform_account" label="Platform account:" label-for="input-group-platform_account">
                            <b-form-input v-model="form.platform_account" type="text" class="mb-2 mr-sm-2 mb-sm-0" placeholder="Platform Account"></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col md="4" class="text-left">
                        <b-form-group id="label" label="Status:" label-for="input-3">
                            <multiselect :loading="isLoading" tag-placeholder="Manage status" placeholder="Choose status" label="name"
                                track-by="code" v-model="form.status" deselect-label="Can't remove this value"
                                :allow-empty="true" :options="status" :multiple="false" :taggable="false">
                            </multiselect>
                        </b-form-group>
                    </b-col>
                    <b-col md="4">
                        <b-form-group label="Date from:" label-for="input-3">
                            <b-form-input :max="form.date_to" v-model="form.date_from" type="date"
                                class="mb-2 mr-sm-2 mb-sm-0" placeholder="Created at date from"></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col md="4">
                        <b-form-group label="Date to:" label-for="input-3">
                            <b-form-input :min="form.date_from" v-model="form.date_to" type="date"
                                class="mb-2 mr-sm-2 mb-sm-0" placeholder="Created at date to"></b-form-input>
                        </b-form-group>
                    </b-col>

                    <b-col md="3" class="text-left d-none">
                        <b-form-group id="label" label="Clean:" label-for="input-3">
                            <multiselect tag-placeholder="Manage Clean" placeholder="Choose Clean State" label="name"
                                track-by="code" v-model="form.clean" deselect-label="Can't remove this value"
                                :allow-empty="true" :options="clean" :multiple="false" :taggable="false">
                            </multiselect>
                        </b-form-group>
                    </b-col>


                    <b-button class="ml-auto px-3" type="submit" :disabled="onFilterDisable" variant="primary"
                        style="height: 3.3em; margin-top: 2.3em">
                        <b-spinner small label="Small Spinner" type="grow" v-show="onFilterDisable"></b-spinner> <i
                            class="fa fa-paper-plane m-0" v-show="!onFilterDisable"></i>
                    </b-button>

                </b-row>
            </b-col>

        </b-row>
    </b-form>
</template>

<script>
import {
    helper
} from '@/helpers';
import api from '@/api/RestClient'
import {
    sofbox
} from '@/config/pluginInit'
import Multiselect from 'vue-multiselect'
import {SERVICES} from "@/constantes";
import { mapGetters } from 'vuex'

export default {
    name: 'BlankPage',
    props: {
        onFilterTable: {
            type: Boolean,
            default: false
        },
        account: {
            type: Object,
            default: null,
        },
      service:{
        type: String,
        default: 'lo_service'
        },
        platforms_array: {
            type: Array,
            default: () => []
        }
    },
    components: {
        Multiselect
    },
    mounted() {
        sofbox.index()
        this.fetchPlatforms();
    },
    data() {
        return {
            onFilterDisable: false,
            isLoading: true,
            form: {
                status: false,
                clean: false,
                daily_cap: null,
                date_to: null,
                date_from: null,
                platforms: null,
                platform_account: null
            },
             platforms: [],
            status: [
                {
                    code: 1,
                    name: 'Active'
                },
                {
                    code: 0,
                    name: 'Inactive'
                }
            ],
            clean: [
                {
                    code: 1,
                    name: 'Active'
                },
                {
                    code: 0,
                    name: 'Inactive'
                }
            ],
        }
    },
    methods: {
        onFilter(e) {
            e.preventDefault();
            this.onFilterDisable = true
            this.$emit('onFilter', this.form)
        },
        finishFilter() {
            this.onFilterDisable = false
        },
        platformName(name) {
            return helper.platformFormatName(name);
        },
        fetchPlatforms() {
            var data = JSON.parse(JSON.stringify(this.platforms_array))
            data.forEach((plat) => {
                let platform = {};
                platform.name = this.platformName(plat.name);
                platform.code = plat.id;
                this.platforms.push(platform);
            });
            this.isLoading = false;
        },
    },
    computed: {
        ...mapGetters({
            isAdmin: 'Auth/isAdmin',
            isAccountManager: 'Auth/isAccountManager',
        }),
    }
}
</script>
<style>
.form-border {
    border: 5px solid;
    border-radius: 5px;
    margin-bottom: 30px;
    padding: 10px;
}
</style>
