<template>

    <iq-card>
        <div v-if="loading" class="d-flex justify-content-center py-3">
            <b-spinner small label="Small Spinner" type="grow"></b-spinner>
        </div>
        <div v-else class="p-3 overflow-hidden" style="position: relative;">
            <div class="rounded-circle iq-card-icon" :class="itemClass">
                <i :class="iconClass"></i>
            </div>
            <span class="float-right line-height-6">{{ label }}</span>
            <div class="clearfix"></div>
            <div class="text-center mt-2">
                <h3 class="mb-0 d-flex justify-content-between py-1">
                    <span class="counter-unit my-auto"> Visitor{{ stats.visitors > 1 ? 's' : '' }}</span>
                    <span class="counter badge badge-info">{{ stats.visitors ?? 0 }}</span>
                </h3>

                <h3 class="mb-0 d-flex justify-content-between py-1">
                    <span class="counter-unit my-auto"> Chat start{{ stats.chat_starts > 1 ? 's' : '' }}</span>
                    <span class="counter badge badge-success">{{ stats.chat_starts ?? 0 }}</span>
                </h3>
            </div>
        </div>
    </iq-card>

</template>

<script>

import api from '@/api/RestClient'
import moment from 'moment'
import { helper } from '@/helpers'

export default {
    name: 'ChatStatsCard',
    components: {},
    props: {
        'label': String,
        'itemClass': String,
        'iconClass': String,
        'period': {
            type: String,
            default: ""
        },
    },
    computed: {
    },
    data() {
        return {
            loading: true,
            loadingRevenue: true,
            options: {
                from: 0,
                to: 0
            },
            prevOptions: {
                from: 0,
                to: 0
            },
            value: 0,
            revenue: 0,
            prevRevenue: 0,
            stats: {}
        }
    },
    mounted() {
        this.loadCardStats();
    },
    methods: {
        loadCardStats() {
            this.loading = true;

            this.options = this.getDatesByName(this.period);
            this.listenRoomsChat(this.options);
        },
        listenRoomsChat(options = {}) {
            this.loading = true;

            api.supportChats.statsByPeriod(options)
                .then((response) => {
                    this.stats.visitors = response.data?.visits ?? 0;
                    this.stats.chat_starts = response.data?.chat_starts ?? 0;
                })
                .catch((error) => {})
                .finally(() => { this.loading = false });
        },
        getDatesByName(label) {
            let dates = {
                from: 0,
                to: 0
            }
            switch (label) {
                case 'today':
                    dates.from = moment().format('YYYY-MM-DD')
                    dates.to = moment().format('YYYY-MM-DD')
                    break;
                case 'yesterday':
                    dates.from = moment().subtract(1, 'days').format('YYYY-MM-DD')
                    dates.to = moment().subtract(1, 'days').format('YYYY-MM-DD')
                    break;
                case 'week':
                    dates.from = moment().startOf('isoWeek').format('YYYY-MM-DD')
                    dates.to = moment().format('YYYY-MM-DD')
                    break;
                case 'month':
                    dates.from = moment().format("YYYY-MM-01");
                    dates.to = moment().format('YYYY-MM-DD')
                    break;
            }
            return dates;
        },
        getPreviousDatesByName(label) {
            let dates = {
                from: 0,
                to: 0,
                service: 1
            }
            switch (label) {
                case 'today':
                    dates.from = moment().subtract(1, 'days').format('YYYY-MM-DD')
                    dates.to = moment().subtract(1, 'days').format('YYYY-MM-DD')
                    break;
                case 'yesterday':
                    dates.from = moment().subtract(2, 'days').format('YYYY-MM-DD')
                    dates.to = moment().subtract(2, 'days').format('YYYY-MM-DD')
                    break;
                case 'week':
                    dates.from = moment().startOf('isoWeek').subtract(1, 'weeks').format('YYYY-MM-DD')
                    dates.to = moment().subtract(1, 'weeks').format('YYYY-MM-DD')
                    break;
                case 'month':
                    dates.from = moment().startOf('month').subtract(1, 'months').format('YYYY-MM-DD')
                    dates.to = moment().subtract(1, 'months').format('YYYY-MM-DD')
                    break;
            }
            return dates;
        },
        getTotalEventsStats(options) {
            api.stats.getEventsTotalStats(options)
                .then((chartData) => {
                    this.value = helper.formatDecimal(chartData?.data?.total ?? 0)
                })
                .catch(err => {
                })
                .finally(() => {
                    this.loading = false
                })
        },
    },
}
</script>

<style scoped>
    .counter-unit {
        font-size: 12px;
        color: #6c757d;
    }
</style>