<template>
    <div class="d-flex justify-content-center">

        <span v-for="(role, i) in roles" :key="i" class="badge mx-1"
            :class="{ 'badge-primary': role == 'Owner', 'badge-info': role == 'Client' }">
            {{ role }}
        </span>

    </div>
</template>

<script>
import { helper } from '@/helpers'
import { mapGetters } from 'vuex'

export default {
    name: 'DtUserRoles',
    props: ['row'],
    components: {
    },
    data() {
        return {
            roles: []
        }
    },
    mounted() {
        this.loadRowRoles()
    },
    methods: {
        loadRowRoles() {
            let roles = this.row.item.role.split(',');

            if (this.row.item.id == this.user.account.user_id) {
                this.roles.push('Owner');
            }
            roles.forEach((role) => {
                if (role && (role.trim() !== '')) {
                    this.roles.push(role);
                }
            });
        }
    },
    computed: {
        ...mapGetters({
            user: 'Auth/user',
            isOwnerAccount: 'Auth/isOwnerAccount',
        }),
    }
}

</script>
