<template>
    <b-modal :id="id" :ref="id" :title="source ? 'Edit Source - ' + source.name : 'Add New Source'" ok-title="Next"
        cancel-title="Previous" size="xl" @show="initModal" @ok="handleNext" @cancel="handleBack">

        <b-row>
            <b-col lg="12" sm="12">
                <iq-card v-if="renderModal">
                    <template v-slot:body>
                        <b-form id="ll-edit-form-wizard2" class="mt-4">
                            <ul id="top-tab-list" class="d-flex justify-content-center p-0">

                                <li v-for="(item, index) in steps" :key="index" :id="`ll-edit-${item.name}`" class=""
                                    :class="{ 'active': item.active, 'done': item.done }">
                                    <b-link href="javascript:void();">
                                        <i :class="item.icon"></i><span>{{ item.title }}</span>
                                    </b-link>
                                </li>

                            </ul>

                            <!-- Dynamic call Current step component -->
                            <div>

                                <component v-if="renderStep" :is="currStepComponent" ref="edit-current-step"
                                    v-bind="{ form, errors, btnAdd: false, title: 'Well done!', isConnected: isConnected }"
                                    @onAddPlatform="cleanPlatformStep" @platformSelected="handlePlatformSelect"
                                    @updatePlatform="selectFromAdd" @updateAccount="updateAccount"
                                    @onCustomLink="handleCustomLink" @onNewPlatform="handlePlatformAdd"
                                    @updateApiKey="handleApiKey" @updateList="form.list = $event">
                                </component>

                            </div>
                            <!-- End Dynamic call Current step component -->

                            <!-- END Add Setting Modal-->

                        </b-form>
                    </template>
                </iq-card>
            </b-col>
        </b-row>

        <template #modal-footer="{ ok, cancel }">

            <button class="btn mx-2 btn-secondary" v-if="btnBackChoise" :disabled="isLoading">
                Choose an existing platform <i class="ri-arrow-go-back-line"></i>
            </button>

            <button v-if="currStep > 0 && currStep !== steps.length - 1" v-b-modal.modal-close_visit class="btn btn-dark"
                @click="cancel" :disabled="isLoading">
                Previous
            </button>

            <button v-if="currStepComponent == 'PartialPlatform' && showSkip && !form.platform_code" v-b-modal.modal-close_visit
                class="btn btn-light" @click="skipStep()" :disabled="isLoading">
                <b-spinner small type="grow" v-show="isLoading"></b-spinner>
                Skip
            </button>

            <b-button v-if="currStep === steps.length - 1 && showIntegrationLiveLeads" variant="primary"
                @click="showStepIntegration">Create liveOpens integration</b-button>

            <button v-if="!hideNextBtn && currStep !== steps.length - 1" class="btn btn-primary"
                :class="{ 'btn-primary': (currStep < steps.length - 1), 'btn-dark': currStep == steps.length - 1 }"
                @click="ok" :disabled="isLoading">

                <b-spinner small type="grow" v-show="isLoading"></b-spinner>
                {{ 'Next' }}
            </button>

            <button class="btn mx-2 btn-secondary" v-if="currStep == steps.length - 1" @click="hideModal">
                Done
            </button>

        </template>
    </b-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import store from '@/store/index'
import api from '@/api/RestClient'
import Multiselect from 'vue-multiselect'
import PartialName from './partials/Name.vue'
import PartialFinish from './partials/Finish.vue'
import PartialPlatform from './partials/Platform.vue'
import PartialIntegration from './partials/Integration.vue'
import PartialIntegrationLiveLead from '../children/IntegrationLiveLead.vue'
import _ from 'lodash';
import { services, helper } from '@/helpers';
import { PLATFORMS_CODE, OAUTH_ESP } from "@/constantes";
import { customRef } from 'vue'


export default {
    name: 'SourceModalEdit',
    components: {
        Multiselect,
        PartialName,
        PartialFinish,
        PartialPlatform,
        PartialIntegration,
        PartialIntegrationLiveLead,
    },
    props: ['id', 'organization', 'source'],
    computed: {
        ...mapGetters({
            isAdmin: 'Auth/isAdmin',
            isClient: 'Auth/isClient',
            user: 'Auth/user',
        })
    },
    mounted() {
        this.form = this.resetForm();
    },
    data() {
        return {
            isLoading: true,
            renderModal: true,
            renderStep: true,
            btnBackChoise: false,
            hideNextBtn: false,
            currStep: 0,
            currStepComponent: null,
            oautESP: OAUTH_ESP,
            form: {},
            steps: [
                { name: 'website-tab', title: 'Source', component: 'PartialName', active: true, done: false, fields: ['name', 'website'], icon: 'ri-pages-fill' },
                { name: 'platform-tab', title: 'Platform', component: 'PartialPlatform', active: true, done: false, fields: ['platform_code', 'account_id', 'api_key'], icon: 'ri-apps-fill' },
                { name: 'integration-tab', title: 'Reception List', component: 'PartialIntegration', active: true, done: false, fields: ['list'], icon: 'ri-play-list-add-fill' },
                { name: 'finish-tab', title: 'Finish', component: 'PartialFinish', active: false, done: false, fields: [], icon: 'ri-check-double-line' },
            ],
            errors: {},
            p_records: [],
            showSkip: true,
            showIntegrationLiveLeads: true,
            liveLeadSource: {},
            isConnected: false
        }
    },

    methods: {
        initModal() {

            this.hideNextBtn = false;
            this.isLoading = false;
            this.currStep = 0;
            this.currStepComponent = this.steps[this.currStep].component;
            this.liveLeadSource = {};

            this.isConnected = false;

            this.steps.forEach(element => {
                element.active = false
                element.done = false
            });

            this.steps[0].active = true
            this.form = this.resetForm()

            this.resetStepComp();
        },
        resetForm() {
            return !this.source ? {
                name: '',
                website: '',
                source: '',
                platform_code: null,
                platform: {},
                account_id: '',
                api_key: null,
                list: null,
                lists: [],
                custom: { isVisible: null, checkbox: null, link: null },
                enableLimit: false,
                limit: -1,
                duplicateByAccount: true,
            } : this.getSource()
        },
        resetModal() {
            this.renderModal = false;

            this.$nextTick(() => {
                this.renderModal = true;
            })
        },
        updateFieldsRequirePlatform() {
            this.isLoading = true;
            const CURR_STEP_COMP = _.find(this.steps, function (o) { return o.name == 'platform-tab'; });

            if (this.currStepComponent == 'PartialPlatform' && !this.$refs['edit-current-step'].showCols.accountId) {
                if (CURR_STEP_COMP.fields.includes('account_id')) CURR_STEP_COMP.fields.pop('account_id')
            } else {
                if (!CURR_STEP_COMP.fields.includes('account_id')) CURR_STEP_COMP.fields.push('account_id')
            }

            if (
                (typeof this.source.platform !== 'undefined' && this.source.platform)
                && CURR_STEP_COMP.fields.includes('api_key')
                && this.form.platform_code) {

                CURR_STEP_COMP.fields = _.remove(CURR_STEP_COMP.fields, function (field) {
                    return field !== 'api_key';
                });
            }

            if (
                (typeof this.source.platform !== 'undefined' && this.source.platform)
                && CURR_STEP_COMP.fields.includes('platform_code')
                && this.form.platform_code) {

                CURR_STEP_COMP.fields = _.remove(CURR_STEP_COMP.fields, function (field) {
                    return field !== 'platform_code';
                });
            }
        },
        async handleNext(e) {
            e.preventDefault()
            this.isLoading = true;
            this.showSkip = false;

            if (this.isAdmin && this.organization) {
                this.form.organization = this.organization
            }

            if (this.currStepComponent == 'PartialName' || this.currStepComponent == 'PartialPlatform') {
                this.showSkip = true;
            }

            if (this.currStepComponent == 'PartialPlatform') {
                this.updateFieldsRequirePlatform()
            }


            if (!this.formIsValid()) {
                this.isLoading = false;
                await helper.delay(300).then(() => this.$refs['edit-current-step'].showErrors())

                if (this.currStepComponent == 'PartialPlatform') {
                    if (!this.form.platform_code) {
                        this.$swal('Please Select or Create Platform', 'Please select a Platform or Add New to Continue. You can Skip this step!', 'warning')
                    }
                }
                return
            }


            if ((this.currStep + 1) < this.steps.length - 1) {

                this.handleCheckForm()
                    .then((result) => {

                        if (Array.isArray(result.data)) {
                            this.form.lists = result.data
                        }

                        this.callbackCheckForm(result)

                    })
                    .catch((error) => {
                        this.isLoading = false
                    })

            } else if ((this.currStep + 1) === this.steps.length - 1) {

                this.handleSubmit()

            } else {
                this.initModal()
            }

        },
        handleBack(e) {
            e.preventDefault()
            this.showSkip = true;

            const STEP = this.steps[this.currStep]

            if (this.currStep > 0) {
                this.currStep--
                this.currStepComponent = this.steps[this.currStep].component

                STEP.active = false
                this.steps[this.currStep].done = false
            }

        },
        skipStep() {
            this.hideNextBtn = true;

            if (this.currStepComponent == 'PartialPlatform') {
                this.form.platform_code = null;
                this.form.platform_recorded = null;
                this.handleSubmit(true)
            }
        },
        cleanPlatformStep() {


        },
        showStepIntegration() {
            this.$emit('showIntegrationModal', this.form);
        },
        handlePlatformSelect(data, goToNext = true) {

            this.isLoading = true;
            this.showSkip = true;

            const CURR_COMP = this.$refs['edit-current-step'];


            if (this.form.platform_code && this.form.platform_recorded && (this.form.platform_recorded.id == data.id)) {
                this.form.platform_code = null;
                this.form.platform_recorded = null;

                helper.delay(1000).then(() => {
                    if (typeof this.$refs['edit-current-step'].$refs['exist-platform'] !== 'undefined' && typeof this.$refs['edit-current-step'].$refs['exist-platform'].setPlatform == 'function') {
                        const CURR_STEP_COMP = _.find(this.steps, function (o) { return o.name == 'platform-tab'; });
                        if (!CURR_STEP_COMP.fields.includes('platform_code')) CURR_STEP_COMP.fields.push('platform_code')

                        let existComponent = this.$refs['edit-current-step'].$refs['exist-platform']
                        existComponent.setPlatform(null)
                        this.isLoading = false;
                    }

                });
                return
            }

            let esp_platform = _.find(CURR_COMP.platforms, function (o) {
                return o.name == data.name;
            });


            if (esp_platform && esp_platform.code) {
                this.form.platform_code = esp_platform.code
            }

            if (data && data.account) {
                this.form.account_id = data.account
            }
            this.form.api_key = data.key ?? null;
            this.form.platform = esp_platform;

            if (!goToNext && esp_platform) {

                this.form.platform = data
                this.form.platform_code = esp_platform.code
            }

            if (typeof esp_platform === 'object' && typeof esp_platform.code !== 'undefined') {

                this.form.platform_recorded = data

                let _data = { ...data, code: esp_platform.code, img: esp_platform.img }
                if (!data.api_key) _data.api_key = this.form.api_key

                if (esp_platform.code == PLATFORMS_CODE.INBOXSUITE) {
                    if (goToNext) this.goToNextStep();
                    return;
                }

                return this.fetchLists(_data)
                    .then((response) => {

                        if (response.success) {

                            if (esp_platform.code == PLATFORMS_CODE.CONVERKIT) response.data = response.data.tags;
                            if (esp_platform.code == PLATFORMS_CODE.BLASTABLE) response.data = response.data.data.records;

                            this.lists = services.default.formatLists(response.data, esp_platform)
                            this.selectFromAdd(data)
                            this.form.lists = response.data

                            // if (goToNext) this.goToNextStep()

                            this.isLoading = false;
                        }

                    })
                    .catch((err) => {
                        this.$swal('Checking Api Failed', 'Error or this Api is not valid', 'error')
                        this.isLoading = false
                        this.form.lists = []
                        this.showSkip = true;
                        return []
                    })

            }

        },
        selectFromAdd(value) {
            let esp_platform = value;
            const CURR_COMP = this.$refs['edit-current-step'];

            let _esp_platform = _.find(CURR_COMP.platforms, function (o) {
                return o.name == value.name;
            });

            if (_esp_platform) {
                this.form.platform_code = _esp_platform.code
            }

        },
        updateAccount(data) {

        },
        handleCustomLink(custom) {
            this.form.custom = custom
        },
        handlePlatformAdd(value) {
        },
        handleApiKey(value) {
        },
        async handleCheckForm() {


            let result = { valid: true, data: [] };

            /* Step list check validity */

            if (this.currStepComponent == 'PartialPlatform') {

                /* Get list for next step */

                let platform_recorded = this.form.platform_recorded ?? null

                if (!this.formIsValid()) {
                    result.false = false
                    this.$swal('Select Platform or Add New', 'Please Add Platform or Select once to continue!', 'warning')
                    return new Promise((resolve, reject) => reject(result));
                }

                if (platform_recorded) {
                    let fetchLists = async () => {

                        return await this.loadPlatformLists(platform_recorded)
                            .then((response) => {

                                result.valid = true
                                if (Array.isArray(response)) {
                                    this.form.lists = response
                                    result.data = response
                                }

                            })
                            .catch((error) => {
                                this.$swal('Checking Api Failed', 'Error or this Api is not valid', 'error')
                                result.valid = false
                                this.isLoading = false
                                return new Promise((resolve, reject) => reject(result));
                            })

                    }

                    await fetchLists()
                }

            }

            return new Promise(resolve => resolve(result));
        },
        resetErrors() {
            this.errors = {}
        },
        checkStepSource() {
            if (!this.form.name || !this.form.name.trim()) {
                this.errors.name = 'Name is required.';
            }
            if (!this.form.website || !this.form.website.trim()) {
                this.errors.website = 'Website is required.';
            }
            if (!helper.isValidURL(this.form.website.trim())) {
                this.errors.website = 'Please insert valid URL Website.';
            }
        },
        checkStepPlatform() {
            const CURR_COMP = this.$refs['edit-current-step']
            let fields = this.steps[this.currStep].fields

            if (CURR_COMP.showCols.accountId && (!this.form.account_id || typeof this.form.account_id == 'undefined')) {
                this.errors.account_id = 'Account is required.';
            }
            if (!this.form.platform_code && (fields.includes('platform_code'))) {
                this.errors.platform_code = 'Platform is required.';
            }
            if ((!this.form.api_key || !this.form.api_key.trim()) && (fields.includes('api_key'))) {
                this.errors.api_key = 'Api is required.';
            }
        },
        checkStepIntegration() {
            if (!this.form.list) {
                this.errors.name = 'List is required.';
            }
        },
        formIsValid() {
            this.resetErrors();

            if (this.currStepComponent == 'PartialName') {
                this.checkStepSource();
            }

            if (this.currStepComponent == 'PartialPlatform') {
                this.checkStepPlatform();
            }

            if (this.currStepComponent == 'PartialIntegration') {
                this.checkStepIntegration();

            }

            return helper.isEmpty(this.errors);
        },
        callbackCheckForm() {

            // Update by plaform selection selection
            if (this.currStepComponent == 'PartialPlatform') {

                const POST_PLATFORM = this.getPostPlatform()


                // manage oauth case with POST_PLATFORM const

            }

            // Trigger next
            this.goToNextStep()

        },
        async goToNextStep() {
            await helper.delay(1500)

            const STEP = this.steps[this.currStep]

            this.currStep++
            this.currStepComponent = this.steps[this.currStep].component

            STEP.done = true
            this.steps[this.currStep].active = true
            this.isLoading = false;

            if (this.currStepComponent == 'PartialPlatform') {
                this.setPlatform();
            }
            if (this.currStepComponent == 'PartialIntegration') {
                this.setReceptionList();
            }
        },
        getPostPlatform() {
            return {
                name: this.form.platform.name,
                account: this.form.account_id,
                key: this.form.api_key,
                organization: this.isAdmin ? this.organization : null
            }
        },
        isValidJson(value) {

            if (typeof value == "undefined") {
                return null
            }

            if (/^[\],:{}\s]*$/.test(value.replace(/\\["\\\/bfnrtu]/g, '@').
                replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']').
                replace(/(?:^|:|,)(?:\s*\[)+/g, ''))) {
                let token = JSON.parse(value)

                if (typeof token.accessToken !== 'undefined') token = token.accessToken
                else if (typeof token.access_token !== 'undefined') token = token.access_token

                if (typeof token.errors !== 'undefined' || typeof token.error !== 'undefined') {
                    return null
                }

                return token
            }

            return value
        },

        // Fetch and Post
        async fetchLists(platform) {


            let esp_code = platform.code

            let customUrl = null


            this.form.custom.link = null


            if (this.form.custom && (typeof this.form.custom.checkbox !== 'undefined')) {
                if (this.form.custom.checkbox) customUrl = this.form.custom.link
            }

            // const token = this.isValidJson(platform.key) ?? platform.key;

            let options = {
                entity: 'lists',
                // token: token,
                account: platform.account,
                organization: platform.account_id,
                platform_id: platform.id,
                customUrl: customUrl
            }


            let _params = services.default.getListParamsByEspAndPlatform(platform, this.form.platform_recorded, options)

            return api.platforms.fetchServices(_params)
        },
        loadPlatformLists(data) {
            const currComponent = this.$refs['edit-current-step']


            let esp_platform = _.find(currComponent.platforms, function (o) { return o.name == data.name; });
            this.form.platform = esp_platform


            if (typeof esp_platform === 'object' && typeof esp_platform.code !== 'undefined') {
                this.form.platform_recorded = data
                this.form.platform = esp_platform.code
                this.form.account_id = data.account
                this.form.api_key = data.api_key

                let _data = { ...data, code: esp_platform.code, img: esp_platform.img }
                if (!data.api_key) _data.api_key = this.form.api_key

                return this.fetchLists(_data)

                    .then((response) => {

                        if (response.success) {


                            if (this.form.platform.code == PLATFORMS_CODE.CONVERKIT) response.data = response.data.tags;
                            if (this.form.platform.code == PLATFORMS_CODE.BLASTABLE) response.data = response.data.data.records;

                            this.lists = services.default.formatLists(response.data, esp_platform)
                            this.selectFromAdd(data)

                            return response.data
                        }
                    })
                    .catch((err) => { return [] })
            }
        },
        handleSubmit(skipSteps = false) {

            this.isLoading = true;

            let message = 'Something went wrong!', title = 'Error!', success = 'danger', icon = 'error';


            // Check form Validation
            if (this.formIsValid() || this.hideNextBtn) {


                if (this.isAdmin && this.organization) {
                    this.form.organization = this.organization
                }

                const data = this.formData()
                let apiAction = null;

                if (typeof this.source !== 'undefined' && this.source.id) {
                    let code = this.source.id;
                    apiAction = api.geSources.put(code, data);
                } else {
                    apiAction = api.geSources.post(data);
                }

                // send ajax query and interpret result for trigger next step
                apiAction.then((response) => {
                    if (response.success) {
                        this.liveLeadSource = response.data.resource;
                        this.form.code = this.liveLeadSource.code
                        this.goToNextStep()

                        icon = 'success'
                        title = 'Success'
                        message = response.message
                        success = 'success'

                        if(!this.liveLeadSource.platform_id) {
                            this.showIntegrationLiveLeads = false;
                        }

                        if (skipSteps) {
                            this.goToNextStep()
                        }
                    }

                })
                    .catch((error) => {
                        console.log('Errors')
                    })
                    .finally(() => {
                        this.$swal({
                            title: title,
                            icon: icon,
                            text: message,
                            type: success,
                            showCancelButton: false,
                            showCloseButton: true,
                        })

                        this.isLoading = false;
                        this.hideNextBtn = false;
                        this.$emit('add', this.liveLeadSource)
                    })


            } else {
                console.log('Form not valid', this.errors)
            }


        },
        formData() {
            return {
                name: this.form.name,
                website: this.form.website,
                source: this.form.source,
                platform: this.form.platform_recorded ? this.form.platform_recorded.id : null,
                lists: this.form.list,
                organization: this.isAdmin ? this.organization : null,
                limit: this.form.limit,
                duplicate_by_account: this.form.duplicateByAccount ?? false,
            }
        },
        hideModal() {
            this.currStep = 0;
            this.$refs[this.id].hide();
        },
        getSource() {
            if (typeof this.source !== 'undefined' && this.source) {
                return {
                    name: this.source.name,
                    website: this.source.website,
                    platform_code: this.source.platform_id,
                    platform: this.source.platform,
                    account_id: this.source.account_id,
                    api_key: null,
                    list: this.source.lists,
                    lists: [],
                    custom: { isVisible: null, checkbox: null, link: null },
                    enableLimit: this.source.limit !== -1 ? true : false,
                    limit: this.source.limit,
                    duplicateByAccount: this.source.duplicate_by_account ? true : false
                }
            }

            return {}
        },
        resetStepComp() {
            this.renderStep = false;

            this.$nextTick(() => {
                this.renderStep = true;
            })
        },
        setPlatform() {
            if (this.currStepComponent == 'PartialPlatform') {

                if (this.source.platform) {

                    if (typeof this.form.platform == 'number' && this.form.platform.platform_recorded) {
                        this.form.platform = this.form.platform_recorded;
                    }

                    helper.delay(1000).then(() => {
                        if (typeof this.$refs['edit-current-step'].$refs['exist-platform'] !== 'undefined' && typeof this.$refs['edit-current-step'].$refs['exist-platform'].setPlatform == 'function') {
                            const data = { ...this.source.platform, code: this.source.platform.id }
                            this.handlePlatformSelect(data, false);

                            let existComponent = this.$refs['edit-current-step'].$refs['exist-platform']
                            existComponent.setPlatform({ ...this.source.platform, code: this.source.platform.id })

                            this.isLoading = false;
                        }
                    });
                }

            }
        },
        setReceptionList() {

            if (this.currStepComponent == 'PartialIntegration') {

                if (this.source.lists && this.source.lists.length) {
                    helper.delay(1000).then(() => {
                        if (typeof this.$refs['edit-current-step'].setReceptionList == 'function') {
                            let existComponent = this.$refs['edit-current-step']
                            
                            existComponent.setReceptionList(this.source.lists[0])
                        }
                    });
                }


            }
        }
    }
}
</script>

<style>
select.form-control[size],
select.form-control[multiple] {
    height: 45px !important;
}

.pt-0,
.py-0 {
    padding-top: 6px !important;
}

.select2-container {
    width: 100% !important;
}

.select2-search--dropdown .select2-search__field {
    width: 98%;
}

h3 {
    font-size: 1.5em;
}

#top-tab-list {
    margin: 0 -10px 45px;
    overflow: hidden;
    color: #777D74;
}

.spinnerAddTag {
    position: relative;
    top: -3.5rem;
    left: 95.9%;
}

.fit-image {
    width: 50% !important;
}

.iq-card {
    background: #ffffff;
    border-radius: 5px;
    margin-bottom: 30px;
    border: none;
    box-shadow: none !important;
}

.iq-card .iq-card-header {
    padding: 0 20px;
    min-height: 60px;
    border-bottom: 0px solid #f2edff;
    align-items: center !important;
}

.modal-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: .8rem 1rem;
    border-bottom: 0px solid #dee2e6;
    border-top-left-radius: calc(.3rem - 1px);
    border-top-right-radius: calc(.3rem - 1px);
}

div.swal2-html-container,
div#swal2-html-container {
    overflow-y: hidden;
}

@media only screen and (max-width: 768px) {
    #top-tab-list li a {
        background: #e5f2ff;
        color: #0084ff;
        display: block;
        padding: 15px;
        margin: 0 1px;
        border-radius: 5px;
        -webkit-border-radius: 5px;
        text-decoration: none;
        font-size: 12px;
    }

    .justify-content-center .col-7 {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .spinnerAddTag {
        position: relative;
        top: -3rem;
        left: 85.9%;
    }
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
