<template>
    <b-container fluid>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title"><i class="ri-notification-line"></i> Notifications</h4>
                    </template>
                    <template v-slot:headerAction class="mt-3">
                        <b-form v-if="isAdmin" class="mx-3">
                            <AccountsMultiselect @onSelect="onSelectAccount" />
                        </b-form>
                    </template>
                    <template v-slot:body>
                        <b-row>
                            <b-col lg="12">
                                <DtNotification ref="dt-notifications" />
                            </b-col>
                        </b-row>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import {
    sofbox
} from "@/config/pluginInit";

import DtNotification from "./children/DtNotification.vue";

import Spinner1 from '@/components/loaders/spinner1'
import _ from 'lodash'

import {
    mapGetters
} from 'vuex'
import AccountsMultiselect from "@/views/children/AccountsMultiselect.vue";
export default {
    name: "UiDataTable",
    components: {
        DtNotification,
        AccountsMultiselect,
        Spinner1,
    },
    created() {
        let _url = window.location.href.replace('127.0.0.1', 'localhost')
        if (window.location.host.split(":")[0] == '127.0.0.1') window.location.href = _url
        this.isAdmin = this.admin;
    },
    mounted() {
        sofbox.index();
    },
    computed: {
        ...mapGetters({
            admin: 'Auth/isAdmin',
            client: 'Auth/isClient',
        })
    },
    methods: {
        onSelectAccount(event) {
            const account = event ? event.code : -1;
            this.$refs['dt-notifications'].setAccount(account)
            this.$root.$emit('bv::refresh::table', this.$refs['dt-notifications']?.id)
        }
    },
    watch: {
    },
    data() {
        return {
            isAdmin: false,
            columns: [],
            rows: [],
            dates: {
                to: null,
                from: null,
            },
            accountId: 3,
            submitload: false,
            charts: {}
        };
    },
};
</script>

<style>
#myTabContent-5 {
    padding-bottom: 2em !important;
    /* margin: 0 5px; */
}

.actionClass {
    width: 18%;
}

.timeClass,
.createdatClass,
.accountClass {
    width: 10%;
}

textarea.form-control {
    line-height: 25px;
}

.cursor-default {
    cursor: default;
}

.table th,
.table td {
    padding: 0.55rem;
    vertical-align: top;
}

.action {
    width: 100%;
    height: 43.5px;
}
</style>
