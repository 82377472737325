import Qs from 'qs';

export default function (axios) {
  return {
    list: (params = {}) => {
      return axios.get(`/revenues`, { params })
        .then(response => response.data);
    },
  }
}
