<template>
<b-container fluid>
    <b-row>
        <b-col sm="12" @mouseover="onHover">
            <iq-card>
                <template v-slot:headerTitle>
                    <h4 class="card-title">{{ title }}</h4>
                </template>
                <template v-slot:body>
                    <div class="#">
                        <div class="text-center" v-show="table_loader">
                            <b-spinner type="grow" label="Loading..."></b-spinner>
                        </div>
                        <div v-show="!table_loader">
                            <b-table :id="table_name" class="mt-4" striped bordered :fields="columns" :items="rows">
                                <template v-slot:cell(profile)="data">
                                    <b-img rounded="circle" fluid class="avatar-40" :src="data.value" />
                                </template>
                                <template v-slot:cell(status)="data">
                                    <div>
                                        <span class="badge cursor-pointer badge-secondary badge-pill" :class="statusClass(data.item.state)">
                                            {{ statusName(data.item.state) }} 
                                        </span>
                                    </div>
                                </template>
                                
                                 <template v-slot:cell(description)="data">
                                        <span>{{ data.item.description ? data.item.description : '--' }}</span>
                                </template>
                                <template v-slot:cell(amount)="data">
                                            <span>{{ moneyDefaultFormat(data.item.amount) }}</span>
                                    </template>
                                <template v-slot:cell(action)="data1">
                                    <a data-toggle="tooltip" data-placement="top" title="" data-original-title="Add" href="#" @click.prevent="download(data1.item)" v-show="false"><i v-show="!data1.item.download" class="ri-download-2-fill"></i>
                                        <b-spinner small label="Small Spinner" type="grow" v-show="data1.item.download"></b-spinner>
                                    </a>
                                    <button type="button" class="btn iq-bg-primary mr-1 btn-sm" data-toggle="tooltip" data-placement="top" title=""
                                        data-original-title="Show">
                                        <router-link :to="{ name: 'invoices.show', params: { id: data1.item.id } }"><i
                                                class="fa fa-eye m-0"></i></router-link>
                                    </button>
                                    <b data-toggle="tooltip" data-placement="top" title="" data-original-title="Generate checkout link"
                                        class="btn iq-bg-danger mr-1 btn-sm cursor-pointer-ek ml-1" v-if="[-1, -3, 101, 102].includes(data1.item.state)">
                                        <i class="fa fa-link" v-show="[-1, -3, 102].includes(data1.item.state)" @click="checkoutSessionLink(data1.item)"></i>
                                        <span role="status" class="spinner-grow spinner-grow-sm" v-if="data1.item.state == 101">
                                            <span class="sr-only">Small Spinner</span>
                                        </span>
                                    </b>
                                    <b data-toggle="tooltip" data-placement="top" title="" data-original-title="Recharge" class="btn iq-bg-success mr-1 btn-s cursor-pointer-ek ml-1" v-if="[-1, -3, 101, 102].includes(data1.item.state)">
                                        <i class="fa fa-refresh" @click="payInvoice(data1.item)"  v-show="[-1, -3, 101].includes(data1.item.state)"></i>
                                        <span role="status" class="spinner-grow spinner-grow-sm" v-show="data1.item.state == 102">
                                            <span class="sr-only">Small Spinner</span>
                                        </span>
                                    </b>
                                </template>
                            </b-table>
                        </div>

                    </div>
                </template>
            </iq-card>
        </b-col>
    </b-row>
</b-container>
</template>

<script>
import {
    sofbox
} from '@/config/pluginInit'
import $ from 'jquery'
import store from '@/store/index'
import api from '@/api/RestClient'
import { INVOICE_STATES } from "@/constantes";
export default {
    name: 'ibg_simple_table',
    props: {
        title: {
            type: String,
            default: 'title-table'
        },
        account: {
            type: Number,
            default: null
        },
        table: {
            type: String,
            default: 'user-table'
        },
        headers: {
            type: Array,
        },
    },
    mounted() {
        sofbox.index();

        this.columns = this.headers;
        this.table_name = this.table;
        this.getInvoicesForAccount(this.account);
    },
    data() {
        return {
            table_name: 'simple-table',
            columns: [],
            rows: [],
            table_loader: true,
            payInvoiceSpinner: false,
            checkout: false
        }
    },
    methods: {
        moneyDefaultFormat(amount) {
            return amount.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        },
        initTable(idOfTable) {
            setTimeout(() => {
                $(idOfTable).DataTable({
                    dom: "<'row justify-content-between' <'col-md-6 col-sm-12' f><'col-md-6 sm-12' <'user-list-files d-flex float-right' B>>> <'row' t><'row justify-content-between mt-3'<'col-md-6' i><'col-md-6' p>>",
                    buttons: {
                        dom: {
                            button: {
                                tag: 'a',
                                className: ''
                            }
                        }
                    },
                    drawCallback: function () {
                        $('.dataTables_paginate > .pagination').addClass('justify-content-end mb-0')
                        let ancestro = $('.dataTables_wrapper').find('div.row').eq(0).find('div')
                        ancestro.eq(2).remove().ready(function () {
                            ancestro.eq(1).parent().addClass('col-md-12')
                        })
                    },
                    language: {
                        search: '',
                        searchPlaceholder: 'Search'
                    }
                })
            }, 1000);
        },
        getInvoicesForAccount($id) {
            return api.invoices.invoicesByAccount($id).then(response => {
                this.rows = response.data;
            }).catch(err => {
                console.log(err);
            }).finally(() => {
                const table = `#${this.table}`;
                this.initTable(table);
                this.table_loader = false;
            })
        },
        download(item) {
            item.download = true;
            api.invoices.download(item.account_id, item.id).then(response => {
                var fileURL = window.URL.createObjectURL(new Blob([response]));
                var fileLink = document.createElement('a');
                var fileName = 'invoice-' + Date.now() + '.pdf';

                fileLink.href = fileURL;
                fileLink.setAttribute('download', fileName);
                document.body.appendChild(fileLink);

                fileLink.click();
            }).catch(err => {
                console.log(err);
                this.$bvToast.toast('Something went wrong', {
                    title: 'Warning',
                    variant: 'warning'
                });
            }).finally(() => {
                item.download = false;
            })
        },
        statusClass(state) {
            let status = 'iq-bg-danger';
            switch (state) {
                case -2:
                    status = 'iq-bg-danger';
                    break;
                case -1:
                    status = 'iq-bg-danger';
                    break;
                case 0:
                    status = 'iq-bg-primary';
                    break;
                case 1:
                    status = 'iq-bg-success';
                    break;
                default:
                    status = 'iq-bg-warning'
                    break;
            }
            return status;
        },
        statusName(state) {
            let name = 'Initial';
            switch (state) {
                case -3:
                    name = 'Unpaid';
                    break;
                case -2:
                    name = 'Canceled';
                    break;
                case -1:
                    name = 'Failed';
                    break;
                case 0:
                    name = 'Initial';
                    break;
                case 1:
                    name = 'Paid';
                    break;
                case 2:
                    name = 'Partial refund';
                    break;
                case 3:
                    name = 'Refunded';
                    break;
                default:
                    break;
            }
            return name;
        },
        
        rechargeInvoice(item) {
            item.state = 102;
            let data = {
                amount: item.amount,
            }
            api.invoices.pay(item.id, data).then((response) => {
                let title = 'Error';
                let variant = 'error';
                if (response.success) {
                    item.state = 1;
                    title = 'Success';
                    variant = 'success';
                } else item.state = -1;
                this.$bvToast.toast(response.message, {
                    title: title,
                    variant: variant
                });
            }).catch((err) => {
                console.log(err)
                item.state = -1;
                this.$bvToast.toast('Something went wrong!', {
                    title: 'Error',
                    variant: 'danger'
                });
            }).finally(() => { })
        },
        payInvoice(item) {
            this.$swal({
                title: 'Are you sure?',
                type: 'warning',
                text: 'This action will charge the current card on file for the amount listed on this invoice.',
                showCancelButton: true,
                confirmButtonText: 'Yes, Recharge',
                cancelButtonText: 'Cancel',
                showCloseButton: true,
                showLoaderOnConfirm: true
            }).then((result) => {
                if (result.value) {
                    item.state = 102;
                    this.rechargeInvoice(item)
                }
            })
        },
        pay(invoiceId) {
            api.invoices.payByClient(invoiceId).then((response) => {
                window.location.href = response.data.link;
            }).catch((err) => {
                console.log(err)
                item.state = -2;
                this.$bvToast.toast('Something went wrong!', {
                    title: 'Error',
                    variant: 'danger'
                });
            }).finally(() => {
                this.checkout = false;
            })
        },
        checkoutSessionLink(item) {
            
            this.$swal({
                title: 'Generate a checkout session?',
                type: 'warning',
                text: 'This action will generate a stripe checkout session to allow you to pay your bill',
                showCancelButton: true,
                confirmButtonText: 'Yes, Generate',
                cancelButtonText: 'Cancel',
                showCloseButton: true,
                showLoaderOnConfirm: true,
                closeOnClickOutside: false
            }).then((result) => {
                if (result.value) {
                    item.state = 101;
                    this.pay(item.id)
                }
            })
        },
        onHover() {
            $("[data-toggle=tooltip]").tooltip();
        },
    }
}
</script>
