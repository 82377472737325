<template>
    <div class="d-flex justify-content-center">

        <IgBtn v-if="isOwnerAccount" :ref="'btn-edit'" :row="row" title="Edit Notification Type" :btnClass="'btn iq-bg-success mx-1 btn-sm'"
            :btnTitle="'Edit Notification Type'" :icon-class="'ri-edit-box-line text-success fa-lg mx-auto'"
            @onClick="onClick({ ...$event.target, title: 'edit' }, row.item, 'btn-edit')"></IgBtn>

    </div>
</template>

<script>
import IgBtn from '@/components/inboxgeek/btn/Default.vue'
import { helper } from '@/helpers'
import { mapGetters } from 'vuex'

export default {
    name: 'DtUserActions',
    props: ['row'],
    components: {
        IgBtn
    },
    computed: {
        ...mapGetters({
            isOwnerAccount: 'Auth/isOwnerAccount',
        }),
    },
    methods: {
        onClick(event, row, ref) {

            if( typeof this.$refs[ref] !== 'undefined' ) {
                this.$refs[ref].setState();
            }

            this.$emit('onClick', { row, event, ref: this.$refs[ref] })
        },
    }
}

</script>
