<template>
    <span v-if="!state" class="badge cursor-pointer badge-pill"></span>
    <span v-else class="badge cursor-pointer badge-pill" 
        v-b-tooltip.top="state.description ?? ''"
        :class="{
            'iq-bg-muted': !state.code,
            'iq-bg-primary': state.code == 0, 
            'iq-bg-success': state.code == 1, 
            'iq-bg-secondary': state.code == 2,
            'iq-bg-info': state.code == 3
            }">{{ state.name }}</span>
</template>

<script>

export default {
    name: 'RecordStateCell',
    props: ['row', 'states', 'attribute'],
    data() {
        return {
            state: {code: null, name: 'Undefined', description: ''},
        }
    },
    mounted() {
        this.getState();
    },
    methods: {
        getState() {
            const that = this
            let currCode = that.row.item[that.attribute]
            let state = _.find(that.states, function (o) { return currCode == o.code; });
            if (state) {
                this.state = state
            }
        }
    }
}
</script>
