export default function (axios) {

  return {

    uploadList: (data,progress) => {

      if (process.env.NODE_ENV === 'production') {
          let axiosInstance = axios.create({
            baseURL: process.env.VUE_SERVICE_BASE_URL_API,
            timeout: 120000
          });
  
        return axiosInstance.post(`/platforms/inboxsuite/lists`, data, progress)
  
      }

      return axios.post(`/platforms/inboxsuite/lists`,data,progress)

    },
    uploadNewList: (id, data, progress) => {

      if (process.env.NODE_ENV === 'production') {
        let axiosInstance = axios.create({
          baseURL: process.env.VUE_SERVICE_BASE_URL_API,
          timeout: 120000
        });

        return axiosInstance.post(`/platforms/inboxsuite/lists/${id}`, data, progress)

      }
      return axios.post(`/platforms/inboxsuite/lists/${id}`, data, progress)

    },
    removeList: (id, data, progress) => {

      if (process.env.NODE_ENV === 'production') {
        let axiosInstance = axios.create({
          baseURL: process.env.VUE_SERVICE_BASE_URL_API,
          timeout: 120000
        });

        return axiosInstance.post(`/platforms/inboxsuite/lists/${id}/delete`, data, progress)

      }
      return axios.post(`/platforms/inboxsuite/lists/${id}/delete`, data, progress)

    }

  }
}
