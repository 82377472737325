<template>
  
  <v-tour name="myTour" :steps="steps" :callbacks="myCallbacks">
        <template slot-scope="tour">

    <transition name="fade">


    <v-step
        v-if="tour.steps[tour.currentStep]"
        :key="tour.currentStep"
        :step="tour.steps[tour.currentStep]"
        :previous-step="tour.previousStep"
        :next-step="tour.nextStep"
        :stop="tour.stop"
        :skip="tour.skip"
        :is-first="tour.isFirst"
        :is-last="tour.isLast"
        :labels="tour.labels"
        :class=" 'testing ' + classes[tour.currentStep]"
        :style="stepStyles[tour.currentStep]"
      >
          <div slot="header">
            <div  v-if="tour.steps[tour.currentStep].header">
            <h3 v-if="tour.steps[tour.currentStep].header.title" style="font-size: x-large;" class="mb-4"><i :class="tour.steps[tour.currentStep].header.icon || 'ri-share-line'" style="color:#0084ff"></i>  {{ tour.steps[tour.currentStep].header.title }}</h3>
            </div>
          </div>

          <div slot="content">
            <div class="v-step__content" style="padding:1rem;color:rgb(58 59 60);">
              <div v-if="tour.steps[tour.currentStep].content" class="step-content" v-html="tour.steps[tour.currentStep].content"></div>
            </div>
          </div>

          <div slot="actions" style="display: flex;justify-content: space-between;" class="mt-2 p-2">
            <div class="text-left">
              <button v-if="tour.currentStep < tour.steps.length-1" @click="tour.skip" class="btn btn-sm btn-light ">Skip the tour</button>

            </div>
            <div class="text-right">
              <button v-if="tour.currentStep != 0" @click="tour.previousStep" class="btn btn-sm mx-1 iq-bg-primary" style="    padding: 0.4rem !important;"> Previous </button>
            <button v-if="tour.currentStep < tour.steps.length-1" @click="tour.nextStep" class="btn btn-sm mx-1 btn-primary">Next <i class="ri-arrow-right-s-line"></i></button>
            <button v-else @click="tour.finish" class="btn btn-sm mx-1 btn-primary">Finish</button>
            </div>


            
          </div>
    

      </v-step>
    </transition>
  </template></v-tour>
</template>

<script>


import api from '@/api/RestClient'

export default {
  name: "AppTourGuidePopUp",
  components: {
    
  },
  mounted() {

    if(this.$route.name == 'dashboard1.home'){

      api.settings.getAppTourStatus() .then(response => { 

        if( response.data == 1 ){

          api.userMetadata.fetchUserMetadata("app_tour_guid_state")
          
          .then((response)=>{

            if( response.data == 0 ){

              api.userMetadata
              .appTour({ page: 1, per_page: 50 })
              .then((response)=>{ this.steps = response.data; })
              .then(()=>{
                
                  this.$root.$on('bv::collapse::state', (collapseId, isJustShown) => {
                
                    if(!isJustShown && collapseId=='sidebar.Integration'){
                      
                      this.$root.$emit('bv::toggle::collapse', 'sidebar.Integration');
                    }

                    if(!isJustShown && collapseId=='help.section'){
                      
                      this.$root.$emit('bv::toggle::collapse', 'help.section');
                    }

                  })

                  this.$tours['myTour'].start(); 
              
              });

            }

          });
            }
          });
          
        }
     
        
    },  
    data() {
        return {
            steps: [],
            myCallbacks: {
              onStart : this.onStart,
              onNextStep: this.myCustomNextStepCallback,
              onSkip:     this.skipTourGuid,
              onFinish:   this.FinishTourGuid
            },
            classes : [
              '',
              'testing help-step',
              ''
            ],
            stepStyles : [
              {},
              {
                   
         
              }
            ],
            arrowStyles : [
              {
                transform : "transform: translate(0px, 398px);"

              }
            ]
        }
    },
    methods : {
      onStart(){
      
        if (this.steps[0].target === '[aria-controls="sidebar.Integration"]' ||this.steps[0].target === '[aria-controls="integration.list"]') {
          this.$root.$emit('bv::toggle::collapse', 'sidebar.Integration');
          
        }

        if (this.steps[0].target === '[aria-controls="help.section"]') {



          this.$root.$emit('bv::toggle::collapse', 'help.section');
        }
      },
      myCustomNextStepCallback(currentStep){
        
        if (this.steps[currentStep].target === '[aria-controls="sidebar.Integration"]' ||this.steps[currentStep].target === '[aria-controls="integration.list"]') {
          this.$root.$emit('bv::toggle::collapse', 'sidebar.Integration');
        }
    
        if (this.steps[currentStep+1].target === '[aria-controls="help.section"]') {
          this.$root.$emit('bv::toggle::collapse', 'help.section');
        }

       
      },
      skipTourGuid(currentStep){
        api.userMetadata
        .updateUserMetadata('app_tour_guid_state',1);
      },
      FinishTourGuid(currentStep){
        api.userMetadata
            .updateUserMetadata('app_tour_guid_state',2);
      }
    }
  
}
</script>

<style>
.help-step{
  position: fixed !important;
  left: 261px !important;
  bottom: 0px;
  top:unset !important;
}

.testing{
    background: #ffffff !important;
    color: #a3a3a3 !important;
    text-align: left !important;
    padding: 1.5rem !important;
    margin: 1rem !important;
    box-shadow: 0 0 0 0 transparent, 0 0 0 0 transparent, 0 4px 6px -1px rgba(0,0,0,.1), 0 2px 4px 9999px rgb(85 102 122 / 51%) !important;
    max-width: 380px !important;
    z-index: 500 !important;
}

.v-tour__target--highlighted {
    box-shadow: 0 0 40px 5px rgb(14 179 255 / 82%) !important;
    pointer-events: auto;
    z-index: 9999 !important;
}
.v-step__arrow--dark::before {
  background: white !important;
}
.v-step__header{
  background-color: #ffffff !important;
    color: black;
    padding: 1rem !important;
    font-weight: bold;
    font-size: large;
}
.v-step__arrow--dark{
    background: #0084ff !important;
}

.v-step__button-next,.v-step__button-stop,.v-step__button-skip,.v-step__button-previous{
    background: #0084ff !important;
    color: white !important;

}

.v-step__arrow:before{
  margin-left: 0px !important;
  margin-top: -10px !important;
}

.v-step__buttons{
    text-align: right !important;
}
.step-content{
  font-size: larger;
  }
</style>
