<template>
    <div class="text-center">
        <input type="checkbox" v-model="select" :checked="isChecked(row)" @input="hasChangeRowSelect($event, row)" :disabled="is_deleted(row)"/>
    </div>
</template>

<script>
import { helper } from '@/helpers'
import { mapGetters } from 'vuex'

export default {
    name: 'DtCheckboxItem',
    props: ['row', 'indeterminate'],
    components: {
    },
    data() {
        return {
            select: false,
            selected: []
        }
    },
    mounted() {
    },
    methods: {
        is_deleted(row) {
            return row.item.active == -1;
        },
        isChecked(item) {
            
            let value = '';

            if(item.rowSelected) {
                this.select = true;
                value = 'checked';
            } else {
                this.select = false;
            }

            return value;
        },
        hasChangeRowSelect($event, row) {

            let event = null;

            if (!row.rowSelected) {
                event = 'selectRow'
                row.rowSelected = true;
                row.selectRow();
            } else {
                event = 'unselectRow'
                row.rowSelected = false;
                row.unselectRow();
            }

            this.$emit(event, row.index)

        },
    },
    computed: {
        ...mapGetters({
            user: 'Auth/user',
            isOwnerAccount: 'Auth/isOwnerAccount',
        }),
    }
}

</script>
