import Qs from 'qs';

export default function (axios) {
  return {
    getAccountConsumption: () => {
      return axios.get(`payments/get-account-consumption`)
             .then(response => response.data);
    },
    getPaymentMethods: () => {
      return axios.get(`payments/payment-methods`)
             .then(response => response);
    },
    getInvoices: () => {
      return axios.get(`payments/invoices`)
             .then(response => response);
    },
    getBilling: () => {
      return axios.get(`payments/invoices`)
             .then(response => response);
    },
    savePaymentMethod: (planData) => {
      return axios.post(`payments/payment-methods`, planData)
             .then(response => response);
    },
    changeDefaultMethod: (planData) => {
      return axios.post(`payments/change-default-method`, planData)
             .then(response => response);
    },
    deletePaymentMethod: (id) => {
      return axios.delete(`payments/payment-methods/${id}`)
             .then(response => response);
    },
    updateBillingDetails: (id, data) => {
      return axios.put(`/payments/billing-address/${id}`, data)
        .then(response => response.data)
    },
    getCountries: () => {
      return axios.get(`payments/s-countries`).then(response => response);
    },
  }
}
