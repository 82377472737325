<template>
    <div>
        <b-row>
    <b-col lg="12">
                <iq-card>
                  <template v-slot:body>
                    <TimeLine :items="timelineItems" v-show="haveHistories"/>
                    <p class="text-center" v-show="!haveHistories">No data founded</p>
                  </template>
                   <p class="text-primary text-center cursor-pointer-ek" @click="all">Show More <b-spinner small label="Small Spinner" type="grow" v-show="spinner"></b-spinner> </p>
                </iq-card>
            </b-col>
        </b-row>
        
    </div>
</template>

<script>

export default {
  props: {
    invoiceLogs: {
      type: Array,
      default: () => []
    },
    paymentLogs: {
      type: Array,
      default: () => []
    }
    },
    data() {
        return {
            form: {
                email: '',
                name: '',
                food: null,
                checked: []
            },
            foods: [{ text: 'Select One', value: null }, 'Carrots', 'Beans', 'Tomatoes', 'Corn'],
            show: true,
            timelineItems: [],
          COLORS: [
            'primary',
            'secondary',
            'success',
            'danger',
            'warning',
            'info',
            'dark '
          ],
          LOGS_LIMIT: 10,
          spinner: false,
          haveHistories: false,
          dataLogs: [],
          logs: []
        }
  },
  mounted() {
    this.mergePaymentWithInvoiceLogs();
    this.addTimeLineItems();
    this.addOverFlowClass()
  },
  created() { 
    
  },
  methods: {
    colorsByActions(action) {
      let color = 'info'
      switch (action) {
        case 'created':
          color = 'primary'
          break
        case 'updated':
          color = 'warning'
          break
        case 'deleted':
          color = 'danger'
          break
        case 'paid':
          color = 'success'
          break
        case 'not paid':
          color = 'danger'
          break
      }
      return color;
    },
    subArray(array = [], start = 0, end = 6) {
      if (!end) { end = -1; }
      return array.slice(start, end);
    },
    addOverFlowClass() {
      if (this.logs.length > this.LOGS_LIMIT) {
        $('.iq-timeline').parent().addClass('iq-overflow');
      }
    },
    addTimeLineItems() {
      var moment = require('moment');
      let i = 0;
      this.spinner = false;
      this.haveHistories = false;
      if (this.dataLogs.length > 0) this.haveHistories = true;
      
      this.logs.forEach(item => {
        let status = item.status == 1 ? item.action + ' successfully' : ' not ' + item.action;
        let resource = ['create','update', 'delete'].includes(item.action) ? ' <a href="' + item.resource_id + '/logs/' + item.id + '?via=' + item.type + '" target="_blank">' + item.type + '</a>' : ` ${item.type}`
        let refund_amount = item.action == 'Refunded' ? ' with $'+ item.refund.total : ''
        let line =  {
          color: this.colorsByActions(item.action),
          title: item.type + ' ' + item.action,
          right: moment(item.created_at).format('MMM Do YYYY HH:mm') + `, by ${item.completed_by}`,
          description: 'The ' + resource  + ' was ' + status,
          child: {
            type: 'img',
            items: [
            ]
          }
        }
        this.timelineItems.push(line);
        i++;
      });
      
    },
    formatDateYMD(date) {
      var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;

      return [year, month, day].join('-');
    },
    all() {
      this.spinner = true;
      setTimeout(() => {
        this.timelineItems = []
        this.logs = [...this.dataLogs];
        this.addTimeLineItems();
        this.addOverFlowClass();
        this.spinner = false;
      }, 2000);
    },
    mergePaymentWithInvoiceLogs() {
      let payment = {
        action: 'paid',
        status: 1,
        completed_by: '',
        type: 'invoice',
        created_at: '',
        id: ''
      };
      this.dataLogs = [];
      this.dataLogs = [...this.invoiceLogs];
      this.paymentLogs.forEach(log => {
        payment.completed_by = log.completed_by;
        payment.created_at = log.created_at;
        payment.status = log.status;
        payment.resource_id = log.invoice_id;
        payment.id = log.id;
        payment.action = log.status != 1 ? 'not paid' : 'paid';
        let pyment = JSON.parse(JSON.stringify(payment)); 
        this.dataLogs.push(pyment)
      });
      this.logs = this.subArray(this.dataLogs, 0, this.LOGS_LIMIT);
    }
  },
  destroyed() {
    this.dataLogs = []
    this.timelineItems = [];
  }
}
</script>

<style>
.cursor-pointer-ek {
  cursor:pointer;
}
.iq-overflow {
  overflow-y: scroll;
  height: 50em;
}
</style>