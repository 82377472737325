var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.state)?_c('span',{staticClass:"badge cursor-pointer badge-pill"}):_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.top",value:(_vm.state.description ?? ''),expression:"state.description ?? ''",modifiers:{"top":true}}],staticClass:"badge cursor-pointer badge-pill",class:{
        'iq-bg-muted': !_vm.state.code,
        'iq-bg-primary': _vm.state.code == 0, 
        'iq-bg-success': _vm.state.code == 1, 
        'iq-bg-secondary': _vm.state.code == 2,
        'iq-bg-info': _vm.state.code == 3
        }},[_vm._v(_vm._s(_vm.state.name))])
}
var staticRenderFns = []

export { render, staticRenderFns }