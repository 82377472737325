export default {
  commitResetUser(state, data) {

    state.loggedIn = data;
    state.isSubscribed = data;
    state.isClient = data;
    state.token = null;
    state.isAdmin = data;
    state.freeAccount = null;
    state.user = {}
    state.tokenAs = null;
    state.userAdmin = null;
    state.liveOpenersSubscribed = null;
    state.liveLeadsSubscribed = null;
    state.api_token = null;
  },
  commitToken(state, data) {
    state.token = data
  },
  commitisAdmin(state, data) {
    state.isAdmin = data
  },
  commitisClient(state, data) {
    state.isClient = data
  },
  commitUser(state, data) {
    state.user = {};
    state.user = data
  },
  commitIsSubscribed(state, data) {
    state.isSubscribed = data
  },
  commitLoggedIn(state, data) {
    state.loggedIn = data
  },
  commitIsAccounting(state, data) {
    state.isAccounting = data
  },
  commitIsAccountManager(state, data) {
    state.isAccountManager = data
  },
  commitIsManager(state, data) {
    state.isManager = data
  },
  commitTokenAs(state, data) {
    state.tokenAs = data
  },
  commitUserAdmin(state, data) {
    state.userAdmin = data
  },
  commitLiveOpenersSubscribed(state, data) {
    state.liveOpenersSubscribed = data
  },
  commitLiveLeadsSubscribed(state, data) {
    state.liveLeadsSubscribed = data
  },
  commitLivePlans(state, data) {
    state.livePlans = data
  }, 
  commitLastSubscriptions(state, data) {
    state.lastSubscriptions = data
  },
  commitUserSubscriptions(state, data) {
    state.UserSubscriptions = data
  },
  commitCurrentAccountOptions(state, data) {
    state.CurrentAccountOptions[data.service_id] = data['options']
  },
  commitUserApiToken(state, data) {
    state.api_token = data
  },
  commitAccountServicePlans(state, data) {
    state.AccountServicePlans = data
  },
  commitUserMetaData(state, data) {

    state.UserMetaData = data
  },
  commitAddUserMetaData(state, data) {
    let add_new = true;
    state.UserMetaData.forEach((meta_data,index)=>{
      if(meta_data['key'] === data['key']){
        state.UserMetaData[index] =data;
        add_new = false;
      }
    });

    if(add_new){
      state.UserMetaData.push(data);
    }
  },
  commitSupportChatRoomID(state, data) {
    state.supportChatRoomID = data
  },
}
