<template>

  <div>

    <div class="alert alert-success" style="background-color: #fbfffb">

      <div class="w-100">
        <h3> <i class="ri-checkbox-circle-line"></i> Suppression list created</h3>
        <hr>
        <h4>{{ form.initial.name }}</h4>
        <div class="row p-2 align-items-center">
          <div class="col text-center">
            <img :src="PlatformImage"  width="35px">
          </div>
          <div class="col-11">

            <h4 v-if="form.initial.platform">{{form.initial.platform.name}} -  {{form.initial.platform.nickname}}  </h4>
            <h4 v-else> Uploaded file </h4>
            <p class="text-muted">
              {{form.list.list.name}}

              <span class="badge badge-info mx-2">{{form.list.list.id}}</span>
              <span class="badge badge-pill badge-light">{{form.list.list.type}}</span>
            </p>
          </div>

        </div>

      </div>
    </div>
    <h4 class="my-3 mt-4 ">Apply this suppression list to an existing source</h4>

    <multiselect  ref="source-ref"
                  class="mb-3"
                  :loading="isLoading"
                  :disabled="isLoading"
                  tag-placeholder="Source"
                  placeholder="Select Source"
                  v-model="selected_sources"
                  label="name"
                  track-by="id" return="id"
                  :multiple="true"
                  :options="sources"
                  :class="errors.source.show ? 'border-error' : ''"
                  @select="errors.source.show=false"
    >


      <template slot="option" slot-scope="props">
        <div class="option__desc">

              <span class="option__title">

                  <span><i class="ri-list-check color-primary" style="color:#5ad"></i></span>
                  <span style="font-wight:bold;"> {{ props.option.name }} </span>

              </span>

        </div>
      </template>
    </multiselect>

    <p class="text-danger"  v-if="errors.source.show"><small>{{errors.source.message}}</small></p>
    <!-- Navigation buttons -->
    <hr>

    <div class="d-flex justify-content-end" style="gap:5px">
      <button class="btn btn-light" @click="$emit('cancel')">I will do that later</button>
      <button class="btn btn-dark d-flex align-items-center " :disabled="loadingProp|| loading_apply_all" @click="finish(true)">
        <b-spinner v-if="loadingProp || loading_apply_all" small type="grow"></b-spinner> Apply to all Sources <span v-if="loadingProp || loading_apply_all">...</span>
      </button>
      <button class="btn btn-primary d-flex align-items-center " :disabled="loadingProp|| loading_apply_only" @click="finish(false)">
        <b-spinner v-if="loadingProp || loading_apply_only" small type="grow"></b-spinner> Apply to selected sources only <span v-if="loadingProp || loading_apply_only">...</span>
      </button>
    </div>
  </div>


</template>

<script>
import {PLATFORMS_ITEMS} from "@/constantes";
import multiselect from "vue-multiselect";
import api from "@/api";

export default {
  name: "ApplySuppressionListWizardModal",
  props:['form','account','loadingProp'],
  components:{
    multiselect
  },
  data(){
    return {
      errors:{
        source : {
          show:false,
          message:'',
        }
      },
      apply_all_sources:false,
      isLoading:false,
      selected_sources:[],
      sources:[],
      loading_apply_all:false,
      loading_apply_only :false
    }
  },
  mounted() {
    this.fetchSources();
  },
  methods:{
    finish(apply_all_sources){

      this.errors.source.show=false;

      if(apply_all_sources === true){
        this.loading_apply_all = true;
      }

      if(! apply_all_sources ) {

        if(this.selected_sources.length === 0){
          this.errors.source.show = true;
          this.errors.source.message = 'please select at least one source to apply the created list.';
          return;
        }

        this.loading_apply_only = true;
      }

      api.suppressionList.attach({

        'id' : this.form.list.created_list.id,
        'sources' : this.selected_sources,
        'apply_all_sources' : apply_all_sources ?? false,
        'account_id' : this.account

      }).then((response)=>{

        if(apply_all_sources === true){
          this.loading_apply_all = false;
        }
        if(! apply_all_sources ) {
          this.loading_apply_only = false;
        }


        this.$emit('finish');

      });

    },
    back(){
      this.$emit('back');
    },
    fetchSources(){

      api.geSources.getSourcesNames({params:{account:this.account}}).then((response)=>{
          this.sources = response.data;
      });
    }
  },
  computed : {
    PlatformImage(){
      let image = null;

      PLATFORMS_ITEMS.forEach((platform)=>{
        if(this.form.initial.platform){

          if( platform.name === this.form.initial.platform.name){
            image = platform.img;
          }

        }

      });

      try {
        return require(`@/assets/images/platforms/${image}`)
      } catch (e) {
        return require(`@/assets/images/platforms/file-upload.svg`)
      }

    }
  }
}
</script>

<style scoped>
.border-error {
  border: 1px dashed #ff2727 !important;
  border-radius: 5px;
}
</style>