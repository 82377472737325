<template>
  <div>


    <iq-card>
        <template v-slot:headerTitle >
          <div style="display: flex; justify-content: space-between;">
            <h4 class="card-title"><i class="ri-play-circle-line"></i> Announce Card Settings </h4>
          </div>
          </template>
          <template v-slot:headerAction>
            <b-form-checkbox v-model="announce_status" @change="updateAnnounceCardStatus" class="mx-2" name="check-button" switch> Active </b-form-checkbox>

          </template>
          <template v-slot:body>
            <b-form @submit="onSubmit">
             <b-row class="mb-2"> 
              <b-col>
                <label for="">Title</label>
                <b-form-input name="title" type="text" v-model="form.title"  placeholder="title"></b-form-input>
              </b-col>
            </b-row>
             <b-row class="mb-2">
              <b-col>
                <label for="">Description</label>
              <VueEditor  v-model="form.description"  class="w-100"/>
              </b-col>
            
            </b-row>

            <b-row class="text-right" style="justify-content: end;padding: 15px;">
              <b-button type="submit" variant="primary">update</b-button>

            </b-row>

            </b-form>
          </template>
    </iq-card>


  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";
import Vue from 'vue'
import Spinner1 from '@/components/loaders/spinner1'
import api from '@/api/RestClient'

export default {

  name: "AnnounceCardSettings",
  props:['announce-status'],
  components: {
    Spinner1,
    VueEditor
  },
  mounted() {
    this.announce_status = this.announceStatus;

    api.settings.getAnnounceCardStatus()
    
    .then((response)=>{

      if(response.success){
        
        this.announce_status = (response.data== 1) ? true: false;
      }else{
        this.announce_status =false;
      }
    }).catch((response)=>{
     
      this.announce_status = false;
    });


    api.settings.fetchAnnounceDetails().then(response =>{
      this.form.title = response.data.title;
      this.form.description = response.data.description;
    })
  },
  data() {
    return {
      loading: true,
      announce_status:false,
      steps:[],
      form : {
        title:null,
        description:null
      },
      current_step_index : null,
      onsubmit:false,
      loading : false
    }
    }
    ,
    methods : {
      
      updateAnnounceCardStatus(){
        api.settings.updateAnnouncementCardStatus(this.announce_status).then((response)=>{
        if(response.success == true) {


          this.$bvToast.toast(response.message, {
                    title: 'Success',
                    variant: 'success'
                });
        }else{
          this.$bvToast.toast(response.message, {
                    title: 'failed',
                    variant: 'error'
                });
        }
      });
      },
      onSubmit(event) {
        event.preventDefault()
        api.settings.updateAnnouncementCard(this.form).then(response=>{
          if(response.success){

            let swal = {
              icon: 'success',
              title: 'video walkthrough details updated successfully',
              text: 'details updated successfully',
              type: 'success',
              confirmButtonText: 'OK',
              showCloseButton: true,
            }

            api.settings.fetchAnnounceDetails().then(response =>{
              this.form.title = response.data.title;
              this.form.description = response.data.description;
            })
            
            this.$swal(swal)
          }
        })
      },

    }
}

</script>