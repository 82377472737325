export default function (axios) {
  return {
    list: (params = {}) => {
      return axios.get(`/settings`, { params })
        .then(response => response.data);
    },
    get: (id) => {
      return axios.get(`/settings/${id}`)
        .then(response => response.data);
    },
    post: (data = {}) => {
      return axios.post('/settings', data)
        .then(response => response.data);
    },
    put: (id, data) => {
      return axios.put(`/settings/${id}`, data)
        .then(response => response.data)
    },
    delete: (id) => {
      return axios.delete(`/settings/${id}`)
        .then(response => response.data)
    },
    restore: (id) => {
      return axios.post(`/settings/${id}/restore`, {})
        .then(response => response.data);
    },
    appTour : () =>{
      return axios.get('app/tour').then(response => response.data);
    },
    getAppTourStatus : ()=>{
      return axios.get('app-tour/status').then(response=>response.data);
    },
    updateAppTourStatus : (status)=>{
      return axios.put('app-tour/status',{"status":status}).then(response=>response.data);
    },
    getVideowalkthroughStatus : () => {
      return axios.get('/settings/video-walk-through/status').then(response=>response.data);
    },
    updateVideoStatus : (status) => {
      return axios.put('/settings/video-walk-through/status',{"status":status}).then(response=>response.data);
    },
    fetchVideoDetails : ()=>{
      return axios.get('/settings/video-walk-through/details').then(response => response.data);

    },
    updateVideoDetails : (form) => {
      return axios.put('/settings/video-walk-through/details',form).then(response=>response.data);
    },
    getPlatformConfigs : () =>{
      return axios.get('/settings/platform-configs').then(response=>response.data);
    },
    updatePlatformConfigs : (id,data) =>{
      return axios.put(`/settings/platform-configs/${id}`,data).then(response=>response.data);
    },
    fetchAnnouncement : () =>{
      return axios.get('/settings/announcements').then(response=> response.data);
    },
    fetchActiveCampaign : ()=> {
      return axios.get('/settings/active-campaign/details').then(response => response.data);
    },
    updateActiveCampagin : (form) => {
      return axios.put('/settings/active-campaign/details',form).then (response => response);
    },
    updateAnnouncement : (form) =>{
      return axios.put('/settings/announcements',form).then(response=> response.data);
    },
    updateAnnouncementStatus : (status) =>{
      return axios.put('/settings/announcements/status',{"status":status}).then(response=> response.data);
    },

    updateAnnouncementCard : (form) =>{
      return axios.put('/settings/announce-card',form).then(response=> response.data);
    },
    updateAnnouncementCardStatus : (status) =>{
      return axios.put('/settings/announce-card/status',{"status":status}).then(response=> response.data);
    },


    getAnnounceCardStatus : () => {
      return axios.get('/settings/announce-card/status').then(response=> response.data);
    },
    getSettingStatus : (setting) => {
      return axios.get(`/settings/${setting}/status`).then(response=> response.data);
    },
    updateSettingStatus : (setting,value) => {
      return axios.put(`/settings/${setting}/status`,{value:value}).then(response=> response.data);
    },
    fetchAnnounceDetails : ()=>{
      return axios.get('/settings/announce-card').then(response=> response.data);
    },
    updateAppSettingStatus : (setting, status) => {
      return axios.put(`/settings/${setting}/status`,{status: status}).then(response=> response.data);
    },
  }
}
