<template>
    <div class="d-flex justify-content-center">

        <span v-for="(role, i) in row.item.roles" :key="i" class="badge badge-info">
            {{ role.name }}
        </span>

    </div>
</template>

<script>
import IgBtn from '@/components/inboxgeek/btn/Default.vue'
import { helper } from '@/helpers'

export default {
    name: 'DtNotifRoles',
    props: ['row'],
    components: {
        IgBtn
    },
    data() {
        return {
        }
    },
    mounted() {
    },
    methods: {
        
    }
}

</script>
