<template>
    <div class="d-flex justify-content-center my-auto" v-b-tooltip v-b-tooltip.hover :title="`${platform && platform.name ? platform.name : ''}${(platform && platform.account ? ' • ' + platform.account : '')}`">

        <span v-if="!platform" class="badge badge-light mx-2">Not Defined</span>
        <span v-else class="badge badge-light mx-1 my-auto">
            <span v-if="getPlatformImage(platform)" class="mx-2">
                <img style="width: 1.3em;" :src="getPlatformImage(platform)">
            </span>

            <span class="mx-2">{{ platform.nickname }}</span>
            <span v-if="!platform.nickname && platform.name" class="mx-2">{{ platformName(platform.name) }}</span>

        </span>

    </div>
</template>

<script>
import { helper } from '@/helpers'
import { mapGetters } from 'vuex'

export default {
    name: 'DtAutomationPlatform',
    props: ['row'],
    components: {
    },
    data() {
        return {
            platform: null
        }
    },
    mounted() {
        this.loadPlatform()
    },
    methods: {
        platformName(name) {
            return helper.platformFormatName(name);
        },
        loadPlatform() {
            this.platform = this.row.item.lists && this.row.item.lists.length ? this.row.item.lists[0].platform : null
        },
        getPlatformImage(platform) {
            const imgFileName = typeof platform.name !== 'undefined' ? platform.name.toLowerCase() : '';
            try {
                return require(`@/assets/images/platforms/${imgFileName}.png`);
            } catch (e) {
                return null;
            }
        },
    },
    computed: {
        ...mapGetters('Auth', ['user', 'isOwnerAccount']),
    }
}

</script>
