<template>
  <div>


    <iq-card>
        <template v-slot:headerTitle >
          <div style="display: flex; justify-content: space-between;">
            <h4 class="card-title"><i class="ri-airplay-fill"></i> Announcement Settings </h4>
          </div>
          </template>
          <template v-slot:headerAction>
            <b-form-checkbox v-model="announcement_status" @change="updateAnnounceStatus" class="mx-2" name="check-button" switch> Active </b-form-checkbox>

          </template>
          <template v-slot:body>
            <b-form @submit="onSubmit">
             <b-row class="mb-2"> 
              <b-col>
                <label for="">Type</label>
                <Multiselect  placeholder="Type" v-model="form.type" :options="types" ></Multiselect>
              </b-col>
            </b-row>
             <b-row class="mb-2"><b-col>
              <label for="">Announce</label>
              <VueEditor  v-model="form.message" class="w-100"/>
              </b-col>
            </b-row>

            <b-row class="text-right" style="justify-content: end;padding: 15px;">
              <b-button type="submit" variant="primary">update</b-button>

            </b-row>

            </b-form>
          </template>
    </iq-card>


  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";
import Vue from 'vue'
import Multiselect from "vue-multiselect"
import Spinner1 from '@/components/loaders/spinner1'
import api from '@/api/RestClient'

export default {

  name: "VideoWalkthroughSettings",
  props:['videoStatus'],
  components: {
    Spinner1,
    VueEditor,
    Multiselect
  },
  mounted() {
    this.announcement_status = this.announcement_status;

    api.settings.fetchAnnouncement()
    
    .then((response)=>{
      
      if(response.success){
        
        this.announcement_status = (response.data.status== 1) ? true: false;
        this.form.message = response.data.message;
        this.form.type = response.data.type;

      }else{
        this.announcement_status =false;
      }
    }).catch((response)=>{
     
      this.announcement_status = false;
    });

  },
  data() {
    return {
      loading: true,
      announcement_status:false,
      steps:[],
      form : {
        type:null,
        message:null,
      },
      types: [
        'info',
        'warning', 
        'danger',
      ],
      current_step_index : null,
      onsubmit:false,
      loading : false
    }
    }
    ,
    methods : {
      updateAnnounceStatus(){
        api.settings.updateAnnouncementStatus(this.announcement_status).then((response)=>{
        if(response.success == true) {


          this.$bvToast.toast(response.message, {
                    title: 'Success',
                    variant: 'success'
                });
        }else{
          this.$bvToast.toast(response.message, {
                    title: 'failed',
                    variant: 'error'
                });
        }
      });
      },
      onSubmit(event) {
        event.preventDefault()
        api.settings.updateAnnouncement(this.form).then(response=>{
          if(response.success){

            let swal = {
              icon: 'success',
              title: 'Announcement details updated successfully',
              text: 'details updated successfully',
              type: 'success',
              confirmButtonText: 'OK',
              showCloseButton: true,
            }

            api.settings.fetchAnnouncement()
            
            .then((response)=>{
              
              if(response.success){
                
                this.announcement_status = (response.data.status== 1) ? true: false;
                this.form.message = response.data.message;
                this.form.type = response.data.type;

              }else{
                this.announcement_status =false;
              }
            }).catch((response)=>{
             
              this.announcement_status = false;
            });
            
            this.$swal(swal)
          }
        })
      },

    }
}

</script>