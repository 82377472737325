<template>
  <div>

    <b-row>
      <b-col>
        <b-button name="auth2Btn" variant="primary" :disabled="!isAllowed" ref="auth2Btn" id="auth2Btn"
          class="auth2 action-button float-right" :data-action="auth2.state" @click="handleOauth($event)">
          <b-spinner small type="grow" v-if="auth2.inProgress"></b-spinner>
          Add New Hubspot Account
        </b-button>
      </b-col>
    </b-row>

  </div>
</template>
  
<script>
import Multiselect from 'vue-multiselect'
import api from '@/api/RestClient';



export default {
  name: 'HubspotInput',
  components: {
    Multiselect
  },
  props: ['account', 'platform_nickname'],
  mounted() {
  },

  data() {
    return {
      form: {

        platform: null,
        oAccountID: null
      },
      auth2: {
        listAccounts: [],
        platforms: [],
        state: '',
        inProgress: false,

      },
      platform: {
        id: null
      },
    }
  },
  methods: {

    handleOauth() {


      this.auth2.inProgress = true;

      let platform_id = null;
      let auth2ServiceApi = null;
      var swalObj = {
        text: 'You will be redirected to the authentication page. Click "Ok" to continue',
        type: 'info',
        showCancelButton: true,
        confirmButtonText: 'Ok',
        cancelButtonText: 'Cancel!',
        showCloseButton: true,
        showLoaderOnConfirm: true
      };

      this.auth2.state = '';

      localStorage.removeItem('oauth.refresh');
      localStorage.removeItem('oauth.params');
      localStorage.removeItem('oauth.install');

      // 


      // New Oauth version

      swalObj.title = `InboxGeek Hubspot Integration`


      auth2ServiceApi = api.default
        .get(`/platforms/hubspot/oauth/authorize${(platform_id ? '?platform_id=' + platform_id : '')}`)
        .then(response => response.data);



      auth2ServiceApi.then((result) => {


  
        this.auth_url = result.url;
        this.auth_params = result.params;

        localStorage.setItem('oauth.install', this.auth_params);

      })
        .catch((error) => {

          this.$swal({
            title: swalObj.title,
            text: 'An error has occurred. Please try again',
            type: 'warning',
            confirmButtonText: 'Ok'
          })
        })
        .finally(() => {

          this.$swal(swalObj).then((result) => {

            if (result.value){
              this.showWindowOauth(result)
            }else{
              this.auth2.inProgress   = false;
            }

          });
        })

    },
    showWindowOauth(result) {

      if (!result.value) {

        this.$bvToast.toast('Your Integration process has been cancel', {
          title: 'Warning',
          variant: 'warning'
        });

      }

      var oauthRefresh, _params = null;

      var w = 800,
        h = 600;

      // Fixes dual-screen position                             Most browsers      Firefox
      const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
      const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

      const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
      const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

      const systemZoom = width / window.screen.availWidth;
      const left = (width - w) / 2 / systemZoom + dualScreenLeft
      const top = (height - h) / 2 / systemZoom + dualScreenTop

      let params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=${w / systemZoom},height=${h / systemZoom},left=${left},top=${top}`;

      var _oauthWindow = window.open(this.auth_url, 'oauth', params);

      var intervalID = setInterval(() => {

        oauthRefresh = localStorage.getItem('oauth.refresh');
        _params = localStorage.getItem('oauth.params') ? JSON.parse(localStorage.getItem('oauth.params')) : {};
        _params.account = this.account


        if (oauthRefresh) {



          api.default.post(`/platforms/hubspot/oauth/access_token`, _params).then((result) => {

            localStorage.setItem(`hubspot.platform_id`, result.data.platform_id);

            this.platform.id = result.data.platform_id

            api.default.put('/platforms/apply-nickname', {
              id: result.data.platform_id,
              nickname: this.platform_nickname
            }).then((result) => {

              this.$emit('OauthPlatformAdded', this.platform);

            });

          })



            .catch((error) => {
              this.$swal({
                title: `InboxGeek Hupspot Integration`,
                text: 'An error has occurred. Please try again',
                type: 'warning',
                confirmButtonText: 'Ok'
              })
            });

            this.auth2.inProgress   = false;

          clearInterval(intervalID)
        }

        else if (_oauthWindow.closed && !oauthRefresh) {
          this.$bvToast.toast('Please authenticate to complete your integration', {
            title: 'Warning', variant: 'warning'
          });
          this.auth2.inProgress   = false;
          clearInterval(intervalID)
        }

        if (_oauthWindow.closed || !_oauthWindow) {
          this.auth2.btnAuthorize = false
        }
      }, 2000);
    },
  },
  computed: {
    isAllowed() {
      return this.platform_nickname != null && this.platform_nickname.trim() != '' && !this.auth2.inProgress;
    }
  }
}

</script>