<template>
  <!-- START Balances Card  -->
  <div>
    <iq-card class="p-4">

      <template v-if="account.name" v-slot:headerTitle>
        <h4 class="card-title">Discounts</h4>
        <p>account : {{ account.name || '' }}</p>
      </template>

      <template v-if="account.name" v-slot:headerAction>
        <b-button variant="primary" @click="edit_account" class="text-right mr-1">Add</b-button>
      </template>

      <div class="p-4">
        <!-- START FILTER OPTIONS ROW  -->
        <b-row class="my-2" align-h="between">

          <!-- START Sow Option Input -->
          <b-col md="2">
            <b-form-group label-for="per-page-select" label-align-sm="left" class="mb-0">
              <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions"></b-form-select>
            </b-form-group>
          </b-col>
          <!-- END Sow Option Input -->

          <!-- START Filter Input -->
          <b-col md="4">
            <b-form-group class="mb-0">
              <b-input-group>
                <b-form-input id="filter-input" v-model="filter" type="search"
                  placeholder="Type to Search"></b-form-input>

              </b-input-group>
            </b-form-group>
          </b-col>
          <!-- END Filter Input -->

        </b-row>
        <!-- END FILTER OPTIONS ROW  -->

        <b-table borderless hover :items="balances" :fields="fields" :current-page="currentPage" :per-page="perPage"
          :filter="filter" :filter-included-fields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection" :busy="loading.table" stacked="md" show-empty @filtered="onFiltered">

          <template #table-busy>
            <div class="text-center p-4 d-flex justify-content-center align-items-center">
              <b-spinner variant="primary"> </b-spinner>
              <span class="text-primary p-2"><small> Fetching balances, please wait...</small></span>
            </div>
          </template>

          <template #cell(limit)="row">

            <span v-if="parseInt(row.item.limit) == -1" class="text-primary p-2"><small> unlimited</small></span>

            <span v-else> {{ row.item.limit }}</span>
          </template>

          <template #cell(value)="row">

            {{ row.item.type == 'percentage' ? `${row.item.value}%` : `$${row.item.value}` }}
          </template>

          <template #cell(end_date)="row">

            <span v-if="row.item.end_date == null"> <small class="badge badge-secondary">no start date</small></span>
            <span v-else> {{ formatDate(row.item.end_date) }}</span>
          </template>

          <template #cell(start_date)="row">

            <span v-if="row.item.start_date == null"> <small class="badge badge-secondary">no end date</small></span>
            <span v-else> {{ formatDate(row.item.start_date) }}</span>
          </template>

          <template #cell(created_at)="row">

            <span v-if="row.item.created_at == null"> <small class="badge badge-secondary">no end date</small></span>
            <span v-else> {{ formatDate(row.item.created_at) }}</span>
          </template>

          <template #cell(actions)="row">
            <b-button v-b-modal.modal-remove-balance variant=" iq-bg-danger mr-1 mb-1" size="sm" v-b-tooltip.hover
              title="Detach this discount" @click="remove(row.item)"><i class="ri-delete-bin-line m-0"></i></b-button>

          </template>

        </b-table>

        <!-- START PAGINATION TABLE-->
        <b-row align-h="end">

          <b-col md="3" class="my-1">
            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm"
              class="my-0"></b-pagination>
          </b-col>
        </b-row>
        <!-- END PAGINATION TABLE-->

      </div>

      <b-modal id="modal-edit-account" ref="edit_account_discounts" hide-footer title="Edit account disounts">
        <b-form @submit="onSubmit">
          <b-form-group id="input-group-4" label="Discounts:" label-for="input-4" description="">
            <discounts :_discounts="form.discounts" @onSelect="onSelectDiscounts"></discounts>
          </b-form-group>

          <b-row align-h="end" class="px-4">
            <b-button variant="primary" type="submit" :disabled="loading.modal">
              Submit
              <b-spinner small type="grow" v-show="loading.modal"></b-spinner>
            </b-button>
          </b-row>
        </b-form>
      </b-modal>
    </iq-card>

  </div>
  <!-- START Balances Card  -->
</template>

<script>
import {
  sofbox
} from '../../../config/pluginInit'
import api from '@/api/RestClient';
import discounts from '../children/discounts.vue'
var moment = require('moment');

export default {
  name: 'account-discounts',
  props: ['account'],
  components: {
    discounts,

  },
  mounted() {
    sofbox.index();
    this.discounts();
    
  },
  data() {
    return {
      balance_id: null,
      balances: [],
      loading: {
        modal: false,
        table: false
      },
      is_unlimited: false,
      selected_balance: null,
      // Table data : 
      fields: [{
        key: 'name',
        label: 'Name',
        sortable: true,
        sortDirection: 'desc'
      },
      {
        key: 'value',
        label: 'value',
        sortable: true,
        class: 'text-center'
      },
      {
        key: 'usage_limit',
        label: 'Limit',
        sortable: true,
        class: 'text-center'
      },
      {
        key: 'start_date',
        label: 'Start date',
        sortable: true,
        class: 'text-center'
      },
      {
        key: 'end_date',
        label: 'End date',
        sortable: true,
        class: 'text-center'
      },
      {
        key: 'created_at',
        label: 'Created at',
        sortable: true,
        class: 'text-center'
      },
      {
        key: 'actions',
        label: 'Actions',
        class: 'text-center'
      }
      ],

      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15, {
        value: 100,
        text: "Show a lot"
      }],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      updateLogs: false,
      form: {
        account_id: null,
        discounts: []
      }

    }
  },
  methods: {
    discounts() {
      this.balances.splice(0, this.balances.length);
      this.balances = [...this.account.discounts]
      this.form.account_id = this.account.id
    },
    onSubmit(e) {
      e.preventDefault();
      this.loading.modal = true;
      api.accounts.attachDiscounts(this.account.id, this.form.discounts).then(response => {
        let title = 'Error';
        let variant = 'warning';
        let message = 'Something went wrong';
        if (response.success) {
          let discounts = response.data;

          this.balances = [];
          this.account.discounts.length = 0;
          discounts.forEach(elt => {
            title = 'SUccess';
            variant = 'success';
            message = response.message;
            let discount = {
              'id': elt.id,
              'type': elt.type,
              'name': elt.name,
              'value': elt.value,
              'type': elt.type,
              'usage_limit': elt.usage_limit,
              'start_date': elt.start_date,
              'end_date': elt.end_date,
              'created_at': elt.created_at,
            }
            this.balances.push(discount);
            this.account.discounts.push(discount);
          });
         
        }
        this.toast(title, variant, message);
      }).catch(error => {
        console.log(error);
      }).finally(() => {
        this.loading.modal = false;
      })
    },
    edit_account() {
      this.form.discounts = [];
      this.account.discounts.forEach(discount => {
        this.form.discounts.push({
          code: discount.id,
          name: discount.name
        });
      });

      this.$refs['edit_account_discounts'].show();
    },
    remove(item) {
      this.$swal({
        title: 'Do you really want to detach this discount from the account?',
        text: 'You can\'t revert your action',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes Delete it!',
        cancelButtonText: 'No, Keep it!',
        showCloseButton: true,
        showLoaderOnConfirm: true
      }).then((result) => {
        if (result.value) {
          api.accounts.detachDiscount(this.account.id, item.id).then(response => {
            let variant = "success";
            let title = "success";
            if (!response.success) {
              variant = "danger";
              title = "error"
            } else {
              this.balances = _.filter(this.balances, function (currentObject) {
                return currentObject.id !== item.id;
              });
              this.account.discounts = this.account.discounts.filter(function (obj) {
                return obj.id !== item.id
              });
            }

            this.toast(title, variant, response.message);
          }).catch(err => {
            console.log(err);
          }).finally(() => {

          });
        }
      })
    },
    getDiscountsForAccount() {
      api.accounts.discounts(this.account.id).then(response => { 
        this.balances.splice(0, this.balances.length);
        this.balances = [...response.data]
      })
    },
    toast(title = "success", variant = "success", message = "Organization updated successfully") {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant
      })
    },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    formatDate(date) {
      var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;

      return [year, month, day].join('-');
    },
    onSelectDiscounts(discounts) {
      this.form.discounts = discounts;
    }
  },
  watch: {
    account: function (newVal, oldVal) { // watch it
      this.discounts();
    }
  }

}
</script>

<style scoped>
.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgb(171 216 225 / 15%);
}</style>
