<template>
    <div class="ml-4">
        <a href="#" class="nav-item search-toggle iq-waves-effect">
            <span class="bg-warning dots"></span>
        </a>
    </div>
</template>

<script>

export default {
    name: 'LoadingCell',
}
</script>
