<template>
    <b-container fluid>

        <!-- DataTable pagination -->
        <b-row class="justify-content-between mt-3">
            <b-col md="9" class="my-1">
                <div class="dataTables_info">
                    <b-row class="dataTables_info justify-content-between" id="lists_info" role="status" aria-live="polite">
                        <b-col md="10" class="">
                            Showing {{ from }}
                            to {{ to }} of {{ totalRows }} entries
                        </b-col>
                        <b-col md="2" class="">
                            <b-form-select id="per-page-select" v-model="per" :options="pageOptions" size="sm">
                            </b-form-select>
                        </b-col>
                    </b-row>
                </div>
            </b-col>
            <b-col md="3" class="my-1">
                <b-pagination v-model="page" :total-rows="totalRows" :per-page="per" align="fill" size="sm"
                    class="my-0"></b-pagination>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import Spinner1 from '@/components/loaders/spinner1'


export default {
    name: 'DtPaginate',
    components: {
        Spinner1,
    },
    props: ['from', 'to', 'currentPage', 'totalRows', 'perPage', 'pageOptions'],
    data() {
        return {
            page: 1,
            per: 5
        }
    },
    mounted() {
        this.page = this.currentPage ?? 1
        this.per = this.perPage ?? 10
    },
    watch: {
        "per": {
            handler(newVal, oldVal) {
                this.$emit('handlePerPage', newVal)
            },
            deep: true
        },
        "page": {
            handler(newVal, oldVal) {
                this.$emit('handlePage', newVal)
            },
            deep: true
        },
    }
}
</script>