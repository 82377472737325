export default function (axios) {
  return {
    get: (params = {}) => {
      return axios.get(`/commissions`, { params })
        .then(response => response.data);
    },
    post: (data = {}) => {
      return axios.post('/commissions', data)
        .then(response => response.data);
    },
    put: (id, data) => {
      return axios.put(`/commissions/${id}`, data)
        .then(response => response.data)
    },
    delete: (id) => {
      return axios.delete(`/commissions/${id}`)
        .then(response => response.data)
    },
    download: (id) => {
      return axios.get(`/commissions/${id}/download`, { responseType: 'blob' })
        .then(response => response.data)
    }
  }
}
