<template>
    <b-form-input type="text" v-model="value" :placeholder="placeholder" @input="emitInput" :disabled="disabled" />
</template>

<script>

export default {
    name: 'KeyInput',
    components: {
    },
    props: {
        label: {
            type: [String],
            default: 'API key/Token: *',
        },
        placeholder: {
            type: [String],
            default: 'API key',
        },
        apiKey: {
            type: [String],
            default: '',
        },
        disabled: {
            type: [Boolean],
            default: false,
        },
    },
    data() {
        return {
            isLoading: true,
            value: null,
        }
    },
    mounted() {
    },
    methods: {
        init() {
            if (typeof this.apiKey !== 'undefined') {
                this.value = this.apiKey
            }
        },
        emitInput(value) {
            this.$emit('input', value)
        }
    },
    computed: {
    },
    watch: {
    },
}
</script>
