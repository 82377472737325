<template>
    <b-container fluid>
        
        <b-row>
            <b-col md="3" class="pb-5 px-0">
                <TicketSideMenu :groupTickets="groupTickets"></TicketSideMenu>
            </b-col>

            <b-col md="9" class="mail-box-detail">
                <TicketBoxContent :groupTickets="groupTickets"></TicketBoxContent>
            </b-col>
        </b-row>

    </b-container>
</template>
<script>
import { sofbox } from '../../config/pluginInit'
import { helper } from '@/helpers'
import { mapGetters } from 'vuex'
import DataTable from '@/components/inboxgeek/tables/DataTable.vue'
import CreateSupportTicketWizardModal from '@/components/inboxgeek/modals/Wizards/SupportTicket/CreateSupportTicketWizardModal.vue'
import TicketSideMenu from '@/views/Chat/children/TicketSideMenu';
import TicketBoxContent from '@/views/Chat/children/TicketBoxContent';
import api from '@/api/RestClient'

export default {
    data() {
        return {
            loading: true,
            renderDT: true,
            dtCells: [
            ],
            groupTickets: [
                {name: 'Open', label: 'open', typeCode: 1, icon: 'ri-mail-line', data: null},
                {name: 'Closed', label: 'closed', typeCode: -1, icon: 'ri-close-circle-fill', data: null},
            ]
        }
    },
    name: 'SupportTickets',
    components: {
        DataTable,
        CreateSupportTicketWizardModal,
        TicketSideMenu,
        TicketBoxContent
    },
    mounted() {
        sofbox.index()

        _.forEach(this.groupTickets, (groupTicket) => {
            this.getGroupTicketData(groupTicket);
        })

        this.$root.$on('ibg:refresh-group-tickets', (firstItem) => {
            
            _.forEach(this.groupTickets, (groupTicket) => {
                this.getGroupTicketData(groupTicket);
            })

        })
    },
    computed: {
        ...mapGetters({
            isAdmin: 'Auth/isAdmin',
            isClient: 'Auth/isClient',
        }),
        columns() {
            let columns = [
                { label: 'Subject', key: 'subject', class: 'text-left', sortable: true, sortDirection: 'desc' },
                { label: 'Status', key: 'active', class: 'text-center', sortable: true, sortDirection: 'desc' },
                {
                    label: 'Created at', key: 'created_at', class: 'text-center', sortable: true, sortDirection: 'desc',
                    formatter: (value, key, item) => {
                        let dt = new Date(value);
                        return helper.formatDate(dt)
                    }
                },
                {
                    label: 'Last Update', key: 'updated_at', class: 'text-center', sortable: true, sortDirection: 'desc',
                    formatter: (value, key, item) => {
                        let dt = new Date(value);
                        return helper.formatDate(dt)
                    }
                },
                { label: 'Actions', key: 'actions', class: 'text-center', sortable: false, sortDirection: 'desc' },
            ];

            if (this.isClient) {

                // // Remove
                // _.remove(columns, {
                //     key: 'account_name'
                // });

            }

            return columns;
        }
    },
    methods: {
        getGroupTicketData(groupTicket) {

            api.default.get('/support_tickets?type=' + groupTicket.typeCode)
                .then(response => {

                    groupTicket.data = response.data;


                })
        },
        onFilter(data) {

            this.filters = this.filterTable(data);

            // if (typeof this.$refs['records-table'].resetTable === 'function') {
            //     this.$refs['records-table'].resetTable()
            // }

            this.renderDT = false;
            this.$nextTick(() => {
                this.renderDT = true;
            });

            helper.delay(2000).then(() => {
                if (typeof this.$refs['dt-filters'].finishFilter === 'function') {
                    this.$refs['dt-filters'].finishFilter()
                }
            })
        },
        showModalAddTicket(e) {
            e.preventDefault();

            // // Check Inactive Account
            // if(this.user && this.user.account && !this.user.account.status) {
            //     helper.alertAccountInactive(this.$swal);
            //     return;
            // }

            // let checkShowAlert  = !this.isClient ? {state: null} : helper.showAlertOutOfEventOrEmptyBalance(SERVICE_ID);

            // if (checkShowAlert.state && checkShowAlert.alert) {
            //     this.$swal(checkShowAlert.alert);
            //     return;
            // } else {
            //     this.$root.$emit('bv::show::modal', this.addModal)
            // }
        },
    }
}
</script>