<template>
        <div class="user-list-files d-flex float-right">
            <div class="dt-buttons btn-group flex-wrap"> 
                <a tabindex="0" aria-controls="user-list-table" href="#" class="buttons-copy buttons-html5" @click="copy"><b-spinner small label="Small Spinner" v-show="copy_loader" type="grow"></b-spinner><span>Copy</span></a> 
                <a tabindex="0" aria-controls="user-list-table" href="#" class="buttons-csv buttons-html5" @click="exportToCsv"><b-spinner small label="Small Spinner" v-show="csv_loader" type="grow"></b-spinner><span>CSV</span></a> 
                <a tabindex="0" aria-controls="user-list-table" href="#" class="buttons-print" @click="exportToPdf"><b-spinner small label="Small Spinner" v-show="pdf_loader" type="grow"></b-spinner><span>PDF</span></a> 
            </div>
        </div>
</template>

<script>
import {
    sofbox
} from '@/config/pluginInit'
import api from '@/api/RestClient'

export default {
    name: 'BlankPage',
    props: {
        data: {
            type: Object,
            default: function () {
                return {}
            }
        }
    },
    mounted() {
        sofbox.index();
    },
    data() {
        return {
            csv_loader: false,
            pdf_loader: false,
            copy_loader: false,

        }
    },
    methods: {
        exportToCsv(e) {
            e.preventDefault();
            this.csv_loader = true;
            let type = 'csv';
            this.downloadReport(type);
        },
        exportToPdf(e) {
            e.preventDefault();
            this.pdf_loader = true;
            this.downloadReport();
        },
        copy(e) {
            e.preventDefault();
            this.copy_loader = true;
            setTimeout(() => {
                this.copy_loader = false;
                this.$bvToast.toast('Report copied successfully', {
                    title: 'Live Report',
                    variant: 'success'
                });
            }, 1500);
        },
        downloadReport(file = 'pdf') {
            this.downloadSpinner = true;
            this.data.file = file;
            api.stats.download(this.data).then(response => {
                var fileURL = window.URL.createObjectURL(new Blob([response]));
                var fileLink = document.createElement('a');
                var fileName = 'live-report-' + Date.now() + `.${file}`;

                fileLink.href = fileURL;
                fileLink.setAttribute('download', fileName);
                document.body.appendChild(fileLink);

                this.$bvToast.toast('Report generated successfully', {
                    title: 'Live Report',
                    variant: 'success'
                });

                fileLink.click();

            }).catch(err => {
                console.log(err);
                this.$bvToast.toast('Something went wrong', {
                    title: 'Warning',
                    variant: 'warning'
                });
            }).finally(() => {
                this.csv_loader  = false;
                this.pdf_loader  = false;
                this.copy_loader = false;
            })
        },
    },
    watch: {
        data(newVal, oldVal) {
            console.log(newVal);
        }
    }
}
</script>
