<template>
    <div class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color mr-0">
        <div class="custom-switch-inner">
            <input type="checkbox" data-toggle="tooltip" data-placement="top" title="Active Status"
                :id="'customSwitchCell-dt-' + row.item.id" 
                :data-id="row.item.id" 
                :data-name="row.item.name"
                v-model="active" 
                :checked="row.item.active == 1"
                @input="onSwitchActive(row.item, row.index, $event.target)"
                class="custom-control-input  active_integration"
                :class="classObject"
                :disabled="active === null">

            <label :for="'customSwitchCell-dt-' + row.item.id" data-on-label="ON" data-off-label="OFF" class="custom-control-label" />
        </div>
    </div>
</template>

<script>

export default {
    name: 'SwitchCell',
    props: ['row','variant'],
    data() {
        return {
            active: null
        }
    },
    mounted() {
        this.active = this.row.item.active == 1 ? 1 : (this.row.item.active == 0 ? 0 : null)
    },
    methods: {
        onSwitchActive(row, index, input) {
            this.$emit('onSwitchStatus', {row, input})
        },
    },
  computed : {
      classObject(){
        let _class = { };
        if(this.row.item.active === 1){
          _class['checked'] = true;
        }

        if(!this.variant){
          _class['bg-primary'] = true;
        }
        else{
          _class[ this.variant] = true;
        }
        return _class;
      }
  },
  watch:{
      row(newValue,oldValue){
        this.active = newValue.item.active;
      }
  }
}

</script>
