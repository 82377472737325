<template>
<div>
    <b-form @submit.prevent="onSubmit" red="form">
        <b-form-group id="input-group-1" label="Name:" label-for="input-1" description="Wording to be displayed on the invoice">
            <b-form-input id="input-1" v-model="form.name" placeholder="Enter name" required></b-form-input>
        </b-form-group>

        <b-form-group id="input-group-2" label="Quantity:" label-for="input-2">
            <b-form-input id="input-2" v-model="form.quantity" placeholder="Enter quantity" type="numeric" required></b-form-input>
        </b-form-group>

        <b-form-group id="input-group-3" label="Price:" label-for="input-3">
            <b-form-input id="input-3" v-model="form.unit_price" placeholder="Enter price" required></b-form-input>
        </b-form-group>

        <b-form-group id="input-group-4" label="Description:" label-for="input-4">
            <b-form-textarea iid="input-4" size="sm" v-model="form.description" placeholder="Small textarea"></b-form-textarea>
        </b-form-group>

        <b-form-group id="input-group-5" label="Discount:">
            <b-form-checkbox-group v-model="isChecked" id="checkboxes-4">
                <b-form-checkbox value="2">Percent</b-form-checkbox>
                <b-form-checkbox value="1">Value</b-form-checkbox>
            </b-form-checkbox-group>
            <div v-show="isChecked == 2">
                <div class="mt-2">Value: {{ discountVal }}%</div>
                <b-form-input id="range-2" v-model="discountVal" type="range" min="0" max="100" step="1" @input="onDiscount"></b-form-input>
            </div>
            <b-form-input class="mt-2" id="input-3" v-model="form.discount" placeholder="Enter price" required></b-form-input>
        </b-form-group>

        <b-button class="mt-3 mb-2" type="submit" variant="primary">
            Submit
            <b-spinner small label="Small Spinner" type="grow" v-show="onSubmitLoader"></b-spinner>
        </b-button>

    </b-form>
</div>
</template>

<script>
import api from '@/api/RestClient'
export default {
    props: ['title', 'invoiceId', 'itemId', 'item'],
    mounted() {
        if (this.itemId) {
            this.form = this.item;
        }
    },
    data() {
        return {
            form: {
                name: '',
                quantity: '',
                unit_price: null,
                description: '',
                discount: 0
            },
            discountVal: 0,
            isChecked: 1,
            show: true,
            onSubmitLoader: false,
        }
    },
    methods: {
        onSubmit(event) {
            event.preventDefault()
            this.onSubmitLoader = true;

            if (this.itemId) this.updateItem();
            else this.addItem()

        },
        addItem() {
            api.invoices.addItemToInvoice(this.invoiceId, this.form).then(response => {
                this.$bvToast.toast(response.message, {
                    title: 'Created',
                    variant: 'success'
                });
                this.$emit('onSavedItem', response);
            }).catch(error => {
                this.$bvToast.toast(error.response.data.message, {
                    title: 'Error',
                    variant: 'danger'
                });

            }).finally(() => {
                this.onSubmitLoader = false;
            })
        },
        updateItem() {
            api.invoices.updateItem(this.itemId, this.form).then(response => {
                this.$bvToast.toast(response.message, {
                    title: 'Updated',
                    variant: 'success'
                });
                
                this.resetForm();
                this.$emit('onSavedItem', response);
            }).catch(error => {
                this.$bvToast.toast(error.response.data.message, {
                    title: 'Error',
                    variant: 'danger'
                });

            }).finally(() => {
                if (this.itemId) this.$emit('onFinishUpdate');
                else  this.resetForm();
                this.onSubmitLoader = false;
            })
        },

        calculDiscount() {
            return Math.round((this.form.quantity * this.form.unit_price) * (this.discountVal / 100), 2);
        },
        onDiscount(event) {
            let response = 0;
            if (this.form.unit_price) response = this.calculDiscount();

            this.form.discount = response;
        },
        resetForm() {
            this.form = {
                name: '',
                quantity: '',
                unit_price: null,
                description: '',
                discount: 0
            }
        }
    }
}
</script>
