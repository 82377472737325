<template>
<div>
    <h1 class="mb-0">Reset Password</h1>
    <p>Enter your email address and we'll send you an email with instructions to reset your password.</p>
    <div class="alert alert-danger" v-if='fails' role="alert" v-for="error in errors">
      <div class="iq-alert-text">• {{ error }}</div> <br>
    </div>
    <div class="alert alert-success" v-if='success' role="alert">
        <div class="iq-alert-text">• Password successfully reseted</div> <br>
    </div>
    <form class="mt-4">
        <div class="form-group">
            <label for="exampleInputEmail1">Email address</label>
            <input type="email" class="form-control mb-0" v-model='reset.email' id="exampleInputEmail1"
          aria-describedby="emailHelp" placeholder="Enter email">
            <div class="text-danger" id="email">{{errors.email}}</div>
        </div>

        <div class="form-group">
            <label for="exampleInputEmail1">New Password</label>
            <input type="password" class="form-control mb-0" v-model='reset.password' id="exampleInputEmail1"
          aria-describedby="emailHelp" placeholder="New Password">
            <div class="text-danger" id="password">{{errors.password}}</div>
        </div>

        <div class="form-group">
            <label for="exampleInputEmail1">Confirm Password</label>
            <input type="password" class="form-control mb-0" v-model='reset.password_confirmation' id="exampleInputEmail1"
          aria-describedby="emailHelp" placeholder="New Password">
            <div class="text-danger" id="confirm_password">{{errors.repeat_password}}</div>
        </div>

        <div class="d-inline-block w-100">

            <button type="submit" class="btn btn-primary float-right" @click.prevent="resetPassword">Reset Password <b-spinner small type="grow" v-show="spinner"></b-spinner></button>
        </div>

    </form>
</div>
</template>

<script>
import api from '@/api/RestClient';
import {
    helper
} from '@/helpers'
export default {
    name: 'RecoverPassword1',
    data() {
        return {
            reset: {
                email: '',
                password: '',
                password_confirmation: '',
                token: ''
            },
            success: false,
            errors: [],
            spinner: false,
            fails: false,
        }
    },
    mounted() {
        this.reset.token = this.$route.params.token;
    },
    methods: {
        resetPassword() {
            this.checkPassWordForm();
            if (helper.isEmpty(this.errors)) {
                this.spinner = true;
                api.auth.passwordResetUpdate(this.reset.token, this.reset.email, this.reset.password, this.reset.password_confirmation).then(response => {
                        if (response.success) {
                            this.toast('Success!', 'success', response.message)
                            setTimeout(() => {
                              window.location.assign("/")
                            }, 1000);
                        } else {
                            response.data.map((err) => {
                                this.toast('Oh!', 'danger', err)
                            })
                        }
                    }).catch(err => {
                        this.toast('Oh!', 'danger', 'Something went wrong')
                    })
                    .finally(() => {
                        this.spinner = false;
                    })
            }

        },
        resetErrors() {
            this.errors = {}
        },
        checkPassWordForm() {
            this.resetErrors();
            if (!this.reset.email) {
                this.errors.email = 'Password required.';
            }
            if (!helper.validMail(this.reset.email)) {
                this.errors.email_address = 'Email format did not matched.';
            }
            if (!this.reset.password) {
                this.errors.password = 'Password required.';
            }
            if (!this.reset.password_confirmation) {
                this.errors.repeat_password = 'Repeat password required.';
            }
            if (!helper.equal(this.reset.password, this.reset.password_confirmation)) {
                this.errors.repeat_password = 'Password did not matched';
            }
            if (helper.checkStringLegth(this.reset.password, 6)) {
                this.errors.password = 'Password must be at least 6 characters';
            }
        },
        toast(title = "success", variant = "success", message = "Account chargerd") {
            this.$bvToast.toast(message, {
                title: title,
                variant: variant
            })
        }
    }
}
</script>
