<template>
  <div class="p-2 shadow-sm rounded border my-2">
    <div class="row align-items-center p-2">
      <div class="col">
        <i v-if="file.error.has_error" class="ri-error-warning-line" style="font-size: 20px;"></i>
        <i v-else-if="!file.completed" class="ri-file-text-line" style="font-size: 20px;"></i>
        <i v-else class="ri-check-line" style="font-size: 20px;"></i>
      </div>
      <div class="col-11">
        <div class="d-flex justify-content-between">
        <h5 class="text-left"> {{file.name }} <small> <br> {{ file.error.message }} </small></h5>
          <span @click="removeFile" style="font-size: 18px"><i class="ri-close-fill"></i></span>
        </div>
        <div class="d-flex justify-content-between align-items-center">
          <div class="col-11 px-0"> <b-progress height="5px" :variant="variantStatus" :value="file.loading"  max="100" ></b-progress></div>
          <div class="col text-center"><span>{{file.loading}}%</span></div>
        </div>

      </div>
    </div>

  </div>

</template>

<script>
export default {
  name: "uploadedFileElement",
  props:['file'],
  methods:{
    removeFile(){

    },

  },
  data(){
    return {
      isVisible:true
    }
  },
  computed:{
    variantStatus(){
      if (this.file.error.has_error){
        return 'danger';
      }

      if(this.file.completed){
        return 'success';
      }

      return null;
    },
    hideProgress(){

      if (this.file.completed){

        setTimeout( ()=>{this.isVisible=false;} , 3000);
      }

      return this.isVisible;
    }
  }
}
</script>

<style scoped>

</style>