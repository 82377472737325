import { Add } from '@/actions/modules/Integrations/AddIntegrations';
import api from '@/api/RestClient';

export default function platform(axios) {
    return {
        labels: {
            listID: "id",
            listName: "name",
            tagID: "id",
            tagName: "name",
        },
        maps: {
            listsMap: (obj) => {
                let item = {}
                item.code = obj.id
                item.name = obj.name
                item.type = obj['type']
                return item
            },
        },
        customShowAnotherCols(COMP) {
            if(typeof COMP.showCols.apiKey !== 'undefined')  COMP.showCols.apiKey = true;
            if(typeof COMP.showCols.accountId!== 'undefined')  COMP.showCols.accountId = false;
        },
        getTagAdded(result) {
            result = 'data' in result ? result.data : result;
            return { code: result.ListID, name: result.Name }
        },
    }
}