<template>
  <b-modal :id="id" :ref="id" title="Add New Automation" header-class="automation" ok-title="Next" cancel-title="Previous"
    size="xl" @show="initModal" @ok="handleNext" @cancel="handleBack">

    <b-row>
      <b-col lg="12" sm="12">

        <iq-card v-if="renderModal">

          <template v-slot:body>
            <b-form id="form-add-automation" class="mt-4">

              <!-- Start - Step Tab Title -->
              <ul id="top-tab-list" class="d-flex justify-content-center p-0">

                <li v-for="(item, index) in steps" :key="index" :id="item.name"
                  :class="{ 'active': item.active, 'done': item.done }">

                  <b-link href="javascript:void();">
                    <i :class="item.icon"></i><span>{{ item.title }}</span>
                  </b-link>

                </li>

              </ul>
              <!-- End - Step Tab Title -->

              <!-- Dynamic call Current step component -->
              <div>

                <component :is="currStepComponent" ref="current-step" v-bind="{
                  form, // used by [all_step]
                  errors, // used by [all_step]
                  btnAdd: false,
                  isConnected: isConnected,
                  showCols: showCols,
                  automation: liveLeadAutomation
                }" @onAddPlatform="cleanPlatformStep" @platformSelected="handlePlatformSelect"
                  @updatePlatform="selectFromAdd" @updateAccount="updateAccount" @onCustomLink="handleCustomLink"
                  @onNewPlatform="handlePlatformAdd" @updateApiKey="handleApiKey" @updateList="form.list = $event"
                  @updateTag="form.tag = $event" @onFinish="onFinishStep" @updateTriggers="refreshActiveFilters" :isCreateAction="true">
                </component>
                <!-- @updateList="form.list = $event" -->

              </div>
              <!-- End Dynamic call Current step component -->

            </b-form>
          </template>
        </iq-card>
      </b-col>
    </b-row>

    <template #modal-footer="{ ok, cancel }">

      <!-- BTN footer Choose existing platform -->
      <button class="btn mx-2 btn-secondary" v-if="btnBackChoise" :disabled="isLoading">
        Choose an existing platform <i class="ri-arrow-go-back-line"></i>
      </button>

      <!-- BTN footer Previous step -->
      <!-- v-if="isLastStep() && showIntegrationLiveLeads && (isSubscribeLiveOpens || isAdmin)" -->
      <b-button v-if="isLastStep() && (isAdmin || isSubscribeLiveOpens)" variant="primary"
        @click="showStepIntegration">Create liveOpens integration</b-button>


      <!-- BTN footer Previous step -->
      <button v-if="currStep > 0 && currStep !== steps.length - 1" v-b-modal.modal-close_visit class="btn btn-dark"
        @click="cancel" :disabled="isLoading">Previous</button>


      <!-- BTN footer Next step -->
      <button v-if="!hideNextBtn && currStep !== steps.length - 1" class="btn btn-primary"
        :class="{ 'btn-primary': (currStep < steps.length - 1), 'btn-dark': currStep == steps.length - 1 }" @click="ok"
        :disabled="isLoading">

        <b-spinner small type="grow" v-show="isLoading"></b-spinner>
        {{ 'Next' }}
      </button>

      <!-- BTN footer Done -->
      <button class="btn mx-2 btn-secondary" v-if="currStep == steps.length - 1" @click="hideModal">
        Done
      </button>

    </template>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import store from '@/store/index'
import api from '@/api/RestClient'
import Multiselect from 'vue-multiselect'
import StepSource from './automation/StepSource.vue'
import StepIntegration from './automation/StepIntegration.vue'
import StepRecap from './automation/StepRecap.vue'
import StepPlatform from './automation/StepPlatform.vue'
import PartialIntegrationLiveLead from '../children/IntegrationLiveLead.vue'
import _ from 'lodash';
import { services, helper } from '@/helpers';
import { PLATFORMS_CODE, OAUTH_ESP, SERVICES } from "@/constantes";
import { GE_RECORD_CLEAN_STATES } from "@/constantes";
import moment from 'moment'

const CURRENT_SUBSCRIPTION = 1;
const SUBSCRIPTION_ACTIVE = 1;

export default {
  name: 'ModalAddGeAutomation',
  components: {
    Multiselect,
    StepSource,
    StepIntegration,
    StepRecap,
    StepPlatform,
    PartialIntegrationLiveLead,
  },
  props: ['id', 'organization', 'source'],
  computed: {
    ...mapGetters({
      isAdmin: 'Auth/isAdmin',
      isClient: 'Auth/isClient',
      user: 'Auth/user',
      liveOpenersSubscribed: 'Auth/liveOpenersSubscribed',
    })
  },
  mounted() {
  },
  data() {
    return {
      isLoading: false,
      renderModal: true,
      btnBackChoise: false,
      hideNextBtn: false,
      currStep: 0,
      currStepComponent: null,
      oautESP: OAUTH_ESP,
      is_skip: false,
      form: this.resetForm(),
      steps: [
        { name: 'source-tab', title: 'Source', component: 'StepSource', active: true, done: false, fields: ['name'], icon: 'ri-pages-fill' },
        { name: 'platform-tab', title: 'Platform', component: 'StepPlatform', active: true, done: false, fields: ['platform_code', 'account_id', 'api_key'], icon: 'ri-apps-fill' },
        { name: 'integration-tab', title: 'Reception List', component: 'StepIntegration', active: true, done: false, fields: ['list'], icon: 'ri-play-list-add-fill' },
        { name: 'summary-tab', title: 'summary', component: 'StepRecap', active: false, done: false, fields: [], icon: 'ri-check-double-line' },
      ],
      errors: {},
      p_records: [],
      showSkip: true,
      hasPlatform: false,
      showIntegrationLiveLeads: true,
      liveLeadAutomation: null,
      isConnected: false,
      isSubscribeLiveOpens: false,
      showCols: {
        accountId: false,
        apiKey: true,
        groupCol: false,
        auth2Btn: false,
        tags: true,
        event: false,
        sendin_blue_event: false,
        event_link: false,
        customTag: false
      },
    }
  },

  methods: {
    initModal() {
      this.hideNextBtn = false;
      this.isLoading = false;
      this.currStep = 0;
      this.currStepComponent = this.steps[this.currStep].component;
      this.isConnected = false;

      this.steps.forEach(element => {
        element.active = false
        element.done = false
      });

      this.steps[0].active = true
      this.form = this.resetForm()
      this.resetModal()
      this.resetErrors();
      this.getSources();
    },
    getSources() {
      api.geSources.getSourcesNames({ account: this.isAdmin ? this.organization : null })
        .then(response => {
          if (response.success) {

            let sources = _.map(response.data, (o) => {
              return {
                uuid: o.id,
                code: o.code,
                name: o.name ?? '--',
                website: o.website,
              }
            });

            this.form.sourceOptions = sources;

            if (this.source) {
              let source = _.find(this.form.sourceOptions, (o) => { return o.uuid == this.source; });

              this.form.source = source;
            }
          }
        })
        .catch(error => {
        })
        .finally(() => {
          this.currentLiveOpensPlan()
        })
    },
    resetForm() {
      return {
        name: '',
        source: '',
        sourceOptions: [],
        platform: null,
        platforms: [],
        list: null,
        lists: [],
        isFiltered: true,
        recordState: null,
        timeSpent: null,
        cleanOptions: GE_RECORD_CLEAN_STATES,
        actions: [
          { code: 2, name: 'Add to Cart' },
          { code: 3, name: 'Checkout' },
        ],
        has_recurrent:false,
        recurrent_interval:0,

        // Time spent
        timeFromH: '00',
        timeFromM: '00',
        timeFromS: '00',
        timeToH: '00',
        timeToM: '15',
        timeToS: '00',
        timeSpentFrom: null,
        timeSpentTo: null,
        status: true,



        tag: null,
        CustomEventName: null,
        CustomEventId: null,
        sendin_blue_event: null,
        customTag: null,
        custom: { isVisible: null, checkbox: null, link: null },
        isListOfTags: false
      }
    },
    resetModal() {
      this.renderModal = false;

      this.$nextTick(() => {
        this.renderModal = true;
      })
    },
    updateFieldsRequirePlatform() {

      const CURR_STEP_COMP = _.find(this.steps, function (o) { return o.name == 'platform-tab'; });
      this.isLoading = false;

      if (this.currStepComponent == 'StepPlatform') {
        if (CURR_STEP_COMP.fields.includes('account_id')) CURR_STEP_COMP.fields.pop('account_id')
      } else {
        if (!CURR_STEP_COMP.fields.includes('account_id')) CURR_STEP_COMP.fields.push('account_id')
      }

    },
    async handleNext(e) {

      e.preventDefault();

      this.showSkip = true;
      this.isLoading = true;

      if (this.isAdmin && this.organization) {
        this.form.organization = this.organization;
      }

      // if (this.formIsValid() && (this.currStepComponent == 'StepPlatform' || this.currStepComponent == "StepSource")) {
      //   // this.goToNextStep()
      // }


      if (this.currStepComponent == 'StepPlatform') {
        this.form.list = null;
        this.updateFieldsRequirePlatform()
      }

      if (!this.formIsValid()) {
        this.isLoading = false;
        await helper.delay(300).then(() => this.$refs['current-step'].showErrors())
        if (this.currStepComponent == 'StepPlatform') {
          this.$swal('Please Select or Create Platform', 'Please select a Platform or Add New to Continue. You can Skip this step!', 'warning')
        }
        if (this.currStepComponent == 'StepIntegration') {
          let err_object = JSON.parse(JSON.stringify(this.errors))
          let err = Object.values(err_object);
          let message = err ? err[0] : 'Something went wrong'
          this.$swal('Warning', message, 'warning')
        }
        return
      }


      if ((this.currStep + 1) < this.steps.length - 1) {

        this.handleCheckForm()
          .then((result) => {

            if (Array.isArray(result.data)) {
              this.form.lists = result.data
            }

            this.callbackCheckForm(result)

            this.isLoading = false;
          })
          .catch((error) => {
            this.isLoading = false
          })

      } else if ((this.currStep + 1) === this.steps.length - 1) {

        this.handleSubmit()

      } else {
        this.initModal()
      }

    },
    handleBack(e) {

      e.preventDefault()

      this.showSkip = true;

      const STEP = this.steps[this.currStep]

      if (this.currStep > 0) {
        this.currStep--
        this.currStepComponent = this.steps[this.currStep].component

        STEP.active = false
        this.steps[this.currStep].done = false
      }

    },
    cleanPlatformStep() {
      // TODO check if must exec script
    },
    handlePlatformSelect(data) {

      this.isLoading = true;
      this.showSkip = false;

      const CURR_COMP = this.$refs['current-step'];

      let esp_platform = _.find(CURR_COMP.platforms, (o) => {
        return o.name == data.name;
      });


      if (typeof esp_platform === 'object' && typeof esp_platform.code !== 'undefined') {


        let platform = {
          id: data.id,
          name: data.name,
          esp: esp_platform,
          account_id: this.isAdmin ? this.organization : (data.account_id ?? null),
          account: data.account,
        };

        this.form.platform = platform


        if (esp_platform.code == PLATFORMS_CODE.INBOXSUITE) {
          this.isLoading = false;
          return;
        }

        if ([PLATFORMS_CODE.WEBHOOK].includes(esp_platform.code)) {
          this.isLoading = false;
          this.form.lists = [];
          // this.showSkip = true;
          return;
        }

        let _data = { ...data, code: platform.esp.code, img: platform.esp.img }

        return this.fetchLists(_data)
          .then((response) => {


            if (response.success) {

              if (esp_platform.code == PLATFORMS_CODE.CONVERKIT) response.data = response.data.tags;
              if (esp_platform.code == PLATFORMS_CODE.BLASTABLE) response.data = response.data.data.records;

              this.lists = services.default.formatLists(response.data, esp_platform)
              this.selectFromAdd(data)
              this.form.lists = response.data

              this.isLoading = false;
            }

          })
          .catch((err) => {
            this.$swal('Checking Api Failed', 'Error or this Api is not valid', 'error')
            this.isLoading = false
            this.form.lists = []
            this.showSkip = true;
            return []
          })

      }

    },
    selectFromAdd(value) {

      let esp_platform = value;
      const CURR_COMP = this.$refs['current-step'];
      this.form.platform_code = null;

      if (typeof esp_platform.id !== 'undefined') {
        this.form.platform_code = esp_platform.id;
      }

    },
    updateAccount(data) {
    },
    handleCustomLink(custom) {
      this.form.custom = custom
    },
    handlePlatformAdd(value) {
    },
    handleApiKey(value) {
    },
    async handleCheckForm() {


      let result = { valid: true, data: [] };

      /* Step list check validity */

      if (this.currStepComponent == 'StepPlatform') {

        /* Get list for next step */

        let platform_recorded = this.form.platform ?? null

        if (!this.formIsValid()) {
          result.false = false
          this.$swal('Select Platform or Add New', 'Please Add Platform or Select once to continue!', 'warning')
          return new Promise((resolve, reject) => reject(result));
        }

        if (platform_recorded) {
          let fetchLists = async () => {

            return await this.loadPlatformLists(platform_recorded)
              .then((response) => {

                result.valid = true
                if (Array.isArray(response)) {
                  this.form.lists = response
                  const currComponent = this.$refs['current-step']
                  let esp_platform = _.find(currComponent.platforms, function (o) { return _.lowerCase(o.name) == _.lowerCase(platform_recorded.name); });
                  this.form.isListOfTags = [PLATFORMS_CODE.ONTRAPORT, PLATFORMS_CODE.KEAP, PLATFORMS_CODE.CONVERKIT, PLATFORMS_CODE.GOHIGHLEVEL].includes(esp_platform.code) ? true : false;
                  result.data = response
                }

              })
              .catch((error) => {
                this.$swal('Checking Api Failed', 'Error or this Api is not valid', 'error')
                result.valid = false
                this.isLoading = false
                return new Promise((resolve, reject) => reject(result));
              })

          }

          await fetchLists()
        }

      }

      return new Promise(resolve => resolve(result));
    },
    resetErrors() {
      this.errors = {}
    },
    formIsValid() {
      let fields = this.steps[this.currStep].fields
      this.resetErrors();

      let fieldInputs = [];
      const currentStepComponent = this.$refs['current-step'];

      if (this.currStepComponent == 'StepSource') {

        fieldInputs = [
          { name: 'name', type: String, require: true },
          { name: 'source', type: Object, require: true },
          { name: 'triggers', type: Array, require: true },
          { name: 'recordState', type: Array, require: false, label: 'record state' },
          { name: 'action', type: Object, require: false, label: 'time spent' },
          { name: 'timeSpent', type: Object, require: false, label: 'time spent' },
        ];

      }

      if (this.currStepComponent == 'StepPlatform') {

        fieldInputs = [
          { name: 'platform', type: Object, require: true },
        ];

      }

      if (this.currStepComponent == 'StepIntegration') {
        fieldInputs = [
          { name: 'list', type: Array, require: true },
        ];
        if (['mailchimp', 'aweber'].includes(this.form.platform.name.toLowerCase())) {
          fieldInputs.push({ name: 'tag', type: Object, require: true })
        } else if ([PLATFORMS_CODE.WEBHOOK, PLATFORMS_CODE.INBOXSUITE].includes(this.form.platform.esp.code)) {
          console.log('suite & webhook checking', this.form );
          let inputLink = this.form.platform.account;
          if(!inputLink || inputLink && inputLink.trim() == '') {
            this.errors['list_link'] = 'Trigger is required.';
          }
        }
      }

      _.forEach(fieldInputs, (field) => {
        let fieldValue = this.form[field.name];
        let label = helper.capitalizeFirstLetter(field.label ?? field.name)

        if (field.require || this.isFiltered) {

          if (field.type.name == 'String') {
            if (!fieldValue || fieldValue && fieldValue.trim() == '') {
              this.errors[field.name] = label + ' is required.';
            }
          }

          if (field.type.name == 'Object') {
            if (!fieldValue) {
              this.errors[field.name] = label + ' is required.';
            }
          }

          if (field.name == 'triggers' && field.type.name == 'Array') {

            this.filtersValidations();

          } else if (field.type.name == 'Array') {
            if (_.isEmpty(fieldValue)) {
              this.errors[field.name] = 'Please select at least one item from ' + label;
            }
          }
        }

      }); 
      const data = this.formData();
      return helper.isEmpty(this.errors);
    },
    filtersValidations() {
      // Update triggers/filters validations
      let triggers = this.getTriggers();
      const someTriggersTruthy = Object.values(triggers).some(val => val.value === true);

      _.forEach(triggers, (trigger) => {

        if (`${trigger.type}` == 'clean' && this.form.triggers.cleaning && !trigger.value) {
          this.errors.recordState = 'Please select at least one item from ' + helper.capitalizeFirstLetter(`${trigger.type}`);
        }

        if (`${trigger.type}` == 'actions' && this.form.triggers.actions && !trigger.value) {
          this.errors.actions = 'Please select at least one item from ' + helper.capitalizeFirstLetter(`${trigger.type}`);
        }

        if (`${trigger.type}` == 'time_spent' && this.form.triggers.timeSpent) {
          let timeFromIsValid = helper.validateTime(this.form.timeSpentFrom);
          let timeToIsValid = helper.validateTime(this.form.timeSpentTo);

          if (timeFromIsValid && timeToIsValid) {

            let timeBetweenIsValid = this.isValidTime(this.form.timeSpentFrom) && this.isValidTime(this.form.timeSpentFrom, '00:00:00', this.form.timeSpentTo) && this.isValidTime(this.form.timeSpentTo) && this.isValidTime(this.form.timeSpentTo, this.form.timeSpentFrom, '00:15:00');

            if (!timeBetweenIsValid) {
              console.log('is not between', timeBetweenIsValid, this.errors);
              this.errors.timeSpent = 'Please set Time Spent between 00:00 and 15:00 minutes';
            }

          } else if (!timeFromIsValid || !timeToIsValid) {
            
            this.errors.timeSpent = 'Please enter a valid ' + helper.capitalizeFirstLetter(`${trigger.type}`) + '.';

          }

        }
      });
    },
    isValidTime(time, minTime = '00:00:00', maxTime = '00:15:00') {

      let format = 'HH:mm:ss';
      const TIME = moment(time, format);
      const MIN_TIME = moment(minTime, format);
      const MAX_TIME = moment(maxTime, format);

      let state = TIME.isBetween(MIN_TIME, MAX_TIME, undefined, '[]');

      return state;

    },
    callbackCheckForm() {

      // Update by plaform selection selection
      if (this.currStepComponent == 'StepPlatform') {

        const POST_PLATFORM = this.getPostPlatform()

        // manage oauth case with POST_PLATFORM const

      }

      // Trigger next
      this.goToNextStep()

    },
    async goToNextStep() {
      // await helper.delay(1500)

      const STEP = this.steps[this.currStep]

      this.currStep++
      this.currStepComponent = this.steps[this.currStep].component

      STEP.done = true
      this.steps[this.currStep].active = true


      this.isLoading = false;

    },
    getPostPlatform() {
      return {
        name: this.form.platform.name,
        account: this.form.account_id,
        key: this.form.api_key,
        organization: this.isAdmin ? this.organization : null
      }
    },
    async fetchLists(platform) {

      let customUrl = null
      // this.form.custom.link = null


      if (this.form.custom && (typeof this.form.custom.checkbox !== 'undefined')) {
        if (this.form.custom.checkbox) customUrl = this.form.custom.link
      }

      let options = {
        entity: [PLATFORMS_CODE.ONTRAPORT, PLATFORMS_CODE.KEAP, PLATFORMS_CODE.CONVERKIT].includes(platform.code) ? 'tags' : 'lists',
        account: platform.account_id,
        organization: platform.account_id,
        platform_id: platform.id,
        customUrl: customUrl
      }

      let _params = services.default.getListParamsByEspAndPlatform(platform, this.form.platform, options)
      return api.platforms.fetchServices(_params)
    },
    loadPlatformLists(data) {
      const currComponent = this.$refs['current-step']


      let esp_platform = this.form.platform.esp;

      if (typeof esp_platform === 'object' && typeof esp_platform.code !== 'undefined') {

        let _data = { ...data, code: esp_platform.code, img: esp_platform.img }

        return this.fetchLists(_data)

          .then((response) => {

            if (response.success) {


              if (this.form.platform.esp.code == PLATFORMS_CODE.CONVERKIT) response.data = response.data.tags;
              if (this.form.platform.esp.code == PLATFORMS_CODE.BLASTABLE) response.data = response.data.data.records;

              this.lists = services.default.formatLists(response.data, esp_platform)
              this.selectFromAdd(data)

              return response.data
            }
          })
          .catch((err) => { return [] })
      }
    },
    handleSubmit() {

      this.isLoading = true;

      let message = 'Something went wrong!', title = 'Error!', success = 'danger', icon = 'error';

      // Check form Validation
      if (this.formIsValid() || this.hideNextBtn) {


        if (this.isAdmin && this.organization) {
          this.form.organization = this.organization
        }

        const data = this.formData();

        // send ajax query and interpret result for trigger next step
        api.geAutomations.post(data)
          .then((response) => {

            if (this.is_skip)
              this.goToNextStep();
            if (response.success) {
              this.liveLeadAutomation = response.data;
              // this.form.code = this.liveLeadAutomation.code

              this.goToNextStep()

              icon = 'success'
              title = 'Success'
              message = response.message
              success = 'success'
            }

            this.is_skip = false;

          })
          .catch((error) => {
            console.log('Errors')
          })
          .finally(() => {
            this.$swal({
              title: title,
              icon: icon,
              text: message,
              type: success,
              showCancelButton: false,
              showCloseButton: true,
            })

            this.isLoading = false;
            this.hideNextBtn = false;
            this.$emit('add', {})
          })


      } else {
        console.log('Form not valid', this.errors)
      }


    },
    formData() {

      let triggers = this.getTriggers();

      return {
        name: this.form.name,
        source_id: this.form.source.uuid,
        platform_id: this.form.platform ? this.form.platform.id ?? null : null,
        account_id: this.isAdmin ? this.form.organization : this.user.account_id,
        lists: this.form.list ? [this.form.list] : null,
        tag: this.form.tag ? this.form.tag : null,
        triggers: triggers,
        status: this.form.status ? 1 : 0
      }
    },
    getTriggers() {

      let triggers = [];
      if (!this.form.isFiltered) {
        return [];
      }

      let cleans = [];
      let actions = [];
      let timeSpent = this.form.triggers.timeSpent ? {
        from: `${this.form.timeFromH}:${this.form.timeFromM}:${this.form.timeFromS}`,
        to: `${this.form.timeToH}:${this.form.timeToM}:${this.form.timeToS}`,
      } : null;


      if (this.form.triggers.cleaning) {
        _.forEach(this.form.recordState, (state) => {

          if(state.code){
            cleans.push(state.code);
          }

        });
      }

      if (this.form.triggers.actions) {
        actions = this.form.action ? [this.form.action] : null
      }

      triggers.push({ type: 'clean', value: Array.isArray(cleans) && cleans.length ? JSON.stringify(cleans) : null });
      triggers.push({ type: 'actions', value: Array.isArray(actions) && actions.length ? JSON.stringify(actions) : null });
      triggers.push({ type: 'time_spent', value: timeSpent ? JSON.stringify(timeSpent) : null });
      triggers.push({type: 'recurrent', value : this.form.has_recurrent ? this.form.recurrent_interval  : null });

      if (timeSpent) {
        this.form.timeSpentFrom = this.getTimeFrom();
        this.form.timeSpentTo = this.getTimeTo();
      }

      return triggers;
    },
    hideModal() {
      this.$refs[this.id].hide();
    },
    onFinishStep(form) {
      this.hasPlatform = typeof form.platform == "number";
    },
    isLastStep() {
      return this.currStep === this.steps.length - 1;
    },
    currentLiveOpensPlan() {

      // ! TODO Check Client or account subscription LiveOpens (if admin make action) before open modal add integration
      if (this.isAdmin) {
        this.getAccountLiveOpensPlan(this.organization)
          .then((response) => {
            // if (response.success) {
            //   // let source = _.find(response.data, (o) => { return o.uuid == this.source; });
            // }

          });
      } else if (!this.isAdmin && this.liveOpenersSubscribed) {
        this.isSubscribeLiveOpens = true;
      }

    },
    showStepIntegration() {
      this.$emit('addIntegration', this.form);
    },
    async getAccountLiveOpensPlan(accountID) {

      return api.subscriptions.list(accountID)
        .then((response) => {
          let liveOpensSubscription = _.filter(response.data, function (o) {
            return o.status == CURRENT_SUBSCRIPTION && o.active == SUBSCRIPTION_ACTIVE && o.service_id == SERVICES.LIVE_OPENS.id;
          })
          if (!_.isEmpty(liveOpensSubscription)) this.isSubscribeLiveOpens = true;
        })
        .catch((error) => {
          console.log('Errors')
        })
        .finally(() => {
          console.log(this.isSubscribeLiveOpens);
        })
    },
    refreshActiveFilters(activeFilters) {
      this.form.triggers = activeFilters;
    },
    getTimeFrom() {
      return `${this.form.timeFromH}:${this.form.timeFromM}:${this.form.timeFromS}`;
    },
    getTimeTo() {
      return `${this.form.timeToH}:${this.form.timeToM}:${this.form.timeToS}`
    }
  },
  watch: {
    "form.timeFromH": {
      handler(newVal, oldVal) {
        this.form.timeSpentFrom = this.getTimeFrom()
      },
      deep: true
    },
    "form.timeFromM": {
      handler(newVal, oldVal) {
        this.form.timeSpentFrom = this.getTimeFrom()
      },
      deep: true
    },
    "form.timeFromS": {
      handler(newVal, oldVal) {
        this.form.timeSpentFrom = this.getTimeFrom()
      },
      deep: true
    },
    "form.timeToH": {
      handler(newVal, oldVal) {
        this.form.timeSpentTo = this.getTimeTo()
      },
    },
    "form.timeToM": {
      handler(newVal, oldVal) {
        this.form.timeSpentTo = this.getTimeTo()
      },
    },
    "form.timeToS": {
      handler(newVal, oldVal) {
        this.form.timeSpentTo = this.getTimeTo()
      },
    },
  },
}
</script>

<style>
select.form-control[size],
select.form-control[multiple] {
  height: 45px !important;
}

.pt-0,
.py-0 {
  padding-top: 6px !important;
}

.select2-container {
  width: 100% !important;
}

.select2-search--dropdown .select2-search__field {
  width: 98%;
}

h3 {
  font-size: 1.5em;
}

#top-tab-list {
  margin: 0 -10px 45px;
  overflow: hidden;
  color: #777D74;
}

.spinnerAddTag {
  position: relative;
  top: -3.5rem;
  left: 95.9%;
}

.fit-image {
  width: 50% !important;
}

.iq-card {
  background: #ffffff;
  border-radius: 5px;
  margin-bottom: 30px;
  border: none;
  box-shadow: none !important;
}

.iq-card .iq-card-header {
  padding: 0 20px;
  min-height: 60px;
  border-bottom: 0px solid #f2edff;
  align-items: center !important;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: .8rem 1rem;
  border-bottom: 0px solid #dee2e6;
  border-top-left-radius: calc(.3rem - 1px);
  border-top-right-radius: calc(.3rem - 1px);
}

div.swal2-html-container,
div#swal2-html-container {
  overflow-y: hidden;
}

header.automation,
header.automation h5 {
  background: #00d0ff !important;
  color: white;
}

@media only screen and (max-width: 768px) {
  #top-tab-list li a {
    background: #e5f2ff;
    color: #0084ff;
    display: block;
    padding: 15px;
    margin: 0 1px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    text-decoration: none;
    font-size: 12px;
  }

  .justify-content-center .col-7 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .spinnerAddTag {
    position: relative;
    top: -3rem;
    left: 85.9%;
  }
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
