<template>
    <b-container fluid>

        <!-- Table title -->
        <b-row class="mb-3">
            <b-col lg="4" class="my-1">
                <h4 class="card-title">Lists</h4>
            </b-col>
        </b-row>


        <!-- User Interface controls -->
        <div class="row justify-content-between">
            <div class="col-md-6 col-sm-12">
                <div id="integrations_filter" class="">
                    <label>
                        <input type="search" id="filter-input" v-model="filter" class="form-control form-control-sm"
                            placeholder="Search" aria-controls="integrations">
                    </label>
                </div>
            </div>
            <div class="col-md-6 sm-12 d-none">
                <div class="user-list-files d-flex float-right">
                    <div class="dt-buttons btn-group flex-wrap">
                        <a tabindex="0" aria-controls="integrations" href="#"
                            class="buttons-copy buttons-html5"><span>Copy</span>
                        </a>
                        <a tabindex="0" aria-controls="integrations" href="#" class="buttons-csv buttons-html5">
                            <span>CSV</span>
                        </a>
                        <a tabindex="0" aria-controls="integrations" href="#" class="buttons-print">
                            <span>Print</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <!-- Main table element -->
        <b-table class="ig-text-wrap" ref="table" :id="id" striped bordered :responsive="$store.getters['Auth/isAdmin']" :busy="isBusy"
            :items="getData" :fields="fields" :current-page="currentPage" :per-page="perPage" :filter="filter"
            :filter-included-fields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection" stacked="md" show-empty small @filtered="onFiltered"
            @sort-changed="sortingChanged" @row-hovered="onRowHovered" @row-unhovered="onRowUnhovered">

            <template #table-busy>
                <div class="text-center d-inline-block my-2">
                    <span class='fa-stack fa-lg'>
                        <i class='fa fa-spinner fa-spin fa-stack-2x fa-fw'></i>
                    </span>&emsp;Processing ...
                </div>
            </template>

            <template #empty="scope">
                <p class="mb-1">No matching records found</p>
            </template>

            <!-- Cell list_name -->
            <template #cell(list_name)="row">
                    {{ row.value }}
            </template>

            <!-- Cell list_id -->
            <template #cell(list_id)="row">
                <span v-b-tooltip v-b-tooltip.hover :title="row.value.length > 20 ? row.value : ''" class="badge badge-light badge-pill">
                    {{ row.value | filterLessThan(20) }}
                </span>
            </template>

            <!-- Cell account -->
            <template #cell(account)="row">
                <div class="badge badge-primary">{{ row.item.account }}</div>
            </template>

            <!-- Cell total_contacts -->
            <template #cell(total_contacts)="row">
                <span v-if="row.item.state < 0">--</span>
                <span v-else>
                    <span>{{ row.item.total_contacts }}</span>
                </span>
            </template>

            <!-- Cell status -->
            <template #cell(state)="row">
                <a data-toggle="tooltip" data-placement="top" :data-original-title="getStateText(row.item.state)"
                    :title="getStateText(row.item.state)" href="#" target="_self" class="">
                    <i aria-hidden="true" class="fa" :class="getStateIcon(row.item.state)"></i>
                </a>
            </template>

            <template #cell(platform)="row">
                <!-- <span class="" v-b-tooltip v-b-tooltip.hover :title="row.value">
                    {{ platformName(row.value) }}
                </span> -->

                <div class="d-flex justify-content-center my-auto" v-b-tooltip v-b-tooltip.hover
                    :title="`${row.value}${(row.item.platform_account ? ' • '+row.item.platform_account : '')}`">

                    <span v-if="!row.value" class="badge badge-light mx-2">Not Defined</span>
                    <span v-else class="badge badge-light mx-1 my-auto">
                        <span v-if="getPlatformImage(row.value)" class="mx-2">
                            <img style="width: 1.3em;" :src="getPlatformImage(row.value)">
                        </span>

                        <span class="mx-2">{{ row.item.platform_nickname }}</span>
                        <span v-if="!row.value" class="mx-2">{{ row.item.platform_nickname }}</span>

                    </span>

                </div>
            </template>

            <!-- Cell refresh -->
            <template #cell(refresh)="row">
                <div class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color">
                    <div class="custom-switch-inner">
                        <input type="checkbox" data-toggle="tooltip" data-placement="top" title="Auto Refresh"
                            :id="'customSwitch-dt-' + row.item.id" :data-id="row.item.id"
                            :data-name="row.item.list_name" v-model="row.item.auto_refresh"
                            :checked="canRefreshList(row.item) && row.item.auto_refresh"
                            @input="onSwitchAutoRefresh(row.item, row.index, $event.target)"
                            class="custom-control-input bg-primary auto_refresh_list"
                            :class="row.item.auto_refresh == 1 && canRefreshList(row.item) ? 'checked' : ''"
                            :disabled="!canRefreshList(row.item)">
                        <label :for="'customSwitch-dt-' + row.item.id" data-on-label="ON" data-off-label="OFF"
                            class="custom-control-label"></label>
                    </div>
                </div>
            </template>

            <template #cell(month_opens)="row">

                <button v-if="!row.item.td_state && !row.item.td_status" @click="onEmailIntell(row.item, row.index)"
                    type="button" class="btn btn-sm text-light bg-primary mx-1 px-1"
                    v-b-tooltip.top="`Fetch last month opens`" href="#" :disabled="row.item.td_state == 301">
                    <i class="ri-play-line"></i> Launch Process
                </button>
                <button v-else-if="row.item.td_state == 301" @click="onEmailIntell(row.item, row.index)" type="button"
                    class="btn btn-sm iq-bg-info mx-1 px-1" data-toggle="tooltip" v-b-tooltip.top="`Please Wait.`"
                    href="#" :disabled="row.item.td_state == 301">
                    <b-spinner small label="Small Spinner" type="grow"></b-spinner> Loading ...
                </button>
                <button v-else-if="row.item.td_status == 1" @click="onEmailIntell(row.item, row.index)" type="button"
                    class="btn btn-sm iq-bg-success mx-1 px-1" data-toggle="tooltip"
                    v-b-tooltip.top="`Last Month Opens`" href="#" :disabled="row.item.td_state == 301">
                    <i class="ri-check-double-fill"></i> {{ row.item.td_month_last_open }}
                </button>
                <button v-else-if="row.item.td_status > 1" @click="onEmailIntell(row.item, row.index)" type="button"
                    class="btn btn-sm iq-bg-info mx-1 px-1" data-toggle="tooltip"
                    v-b-tooltip.top="`Waiting list results`" href="#" :disabled="row.item.td_state == 301">
                    <i class="ri-refresh-line"></i> In Progress
                </button>
                <button v-else @click="onEmailIntell(row.item, row.index)" type="button"
                    class="btn btn-sm iq-bg-warning mx-1 px-1 text-danger" data-toggle="tooltip"
                    v-b-tooltip.top="`Please Check List results`" href="#" :disabled="row.item.td_state == 301">
                    <i class="ri-error-warning-fill"></i> Warning
                </button>
            </template>

            <!-- Cell actions -->
            <template #cell(actions)="row">
                <div class="d-flex justify-content-center">

                    <button @click="showLogs(row.item, row.index, $event.target)" type="button"
                        class="btn iq-bg-info btn-sm mr-1" data-toggle="tooltip" data-placement="top"
                        title="List TimeLine" data-name="Inbox Geek" data-original-title="List TimeLine">
                        <i v-if="row.item.state != 601" class="fa fa-history m-0"></i>
                        <b-spinner small label="Small Spinner" v-if="row.item.state == 601" type="grow"></b-spinner>
                    </button>

                    <button v-if="['webhook', 'inboxsuite'].includes(row.item.platform.toLowerCase())"
                        v-b-modal.update-contacts-in-uploaded-list-modal @click="updateUploadContacts(row.item,'add')"
                        type="button" class="btn iq-bg-secondary mr-1 btn-sm" data-toggle="tooltip" data-placement="top"
                        title="Import new contacts" href="#">
                        <i v-if="row.item.state != 301" class="ri-contacts-book-upload-fill m-0"></i>
                        <b-spinner small label="Small Spinner" v-if="row.item.state == 301" type="grow"></b-spinner>
                    </button>

                    <button v-if="['webhook', 'inboxsuite'].includes(row.item.platform.toLowerCase())"
                        v-b-modal.update-contacts-in-uploaded-list-modal
                        @click="updateUploadContacts(row.item,'delete')" type="button"
                        class="btn iq-bg-danger mr-1 btn-sm" data-toggle="tooltip" data-placement="top"
                        title="Delete contacts" href="#">
                        <i v-if="row.item.state != 301" class="ri-file-reduce-fill m-0"></i>
                        <b-spinner small label="Small Spinner" v-if="row.item.state == 301" type="grow"></b-spinner>
                    </button>

                    <button @click="timeLogs(row.item, row.index)" type="button" class="btn iq-bg-success mr-1 btn-sm"
                        data-toggle="tooltip" data-placement="top" title="Time Line" data-original-title="Edit"
                        href="#">
                        <i v-if="row.item.state != 201" class="fa fa-history m-0"></i>
                        <b-spinner small label="Small Spinner" v-if="row.item.state == 201" type="grow"></b-spinner>
                    </button>

                    <button v-if="canRefreshList(row.item)" @click="refreshContacts(row.item, row.index, $event.target)"
                        type="button" class="btn iq-bg-success mr-1 btn-sm" data-toggle="tooltip" data-placement="top"
                        title="Refresh contacts" data-original-title="Edit" href="#"
                        :disabled="!isRefreshable(row.item.state)">
                        <i v-if="row.item.state != 101" class="ion-refresh m-0"></i>
                        <b-spinner small label="Small Spinner" v-if="row.item.state == 101" type="grow"></b-spinner>
                    </button>

                    <button @click="removeItem(row.item, row.index, $event.target)" type="button"
                        class="btn iq-bg-danger btn-sm" data-toggle="tooltip" data-placement="top" title="Delete"
                        data-name="Inbox Geek" data-original-title="delete Inbox Geek">
                        <i class="ri-delete-bin-line i-delete m-0 delete_integration_icone"></i>
                        <div class="spinner-grow spinner-grow-sm delete_integration_spiner d-none" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </button>
                </div>
            </template>

        </b-table>

        <!-- Datatble pagination -->
        <b-row class="justify-content-between mt-3">
            <b-col md="9" class="my-1">
                <div class="dataTables_info">
                    <b-row class="dataTables_info justify-content-between" id="lists_info" role="status"
                        aria-live="polite">
                        <b-col md="10" class="">
                            Showing {{ from }}
                            to {{ to }} of {{ totalRows }} entries
                        </b-col>
                        <b-col md="2" class="">
                            <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm">
                            </b-form-select>
                        </b-col>
                    </b-row>
                </div>
            </b-col>
            <b-col md="3" class="my-1">
                <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm"
                    class="my-0"></b-pagination>
            </b-col>
        </b-row>
        <b-modal ref="log-lists" size="lg" id="modal-time-log-lists" :title="time_log_title" hide-footer>
            <ListTimeLine :row="listSelected"></ListTimeLine>
        </b-modal>

        <b-modal ref="update-list" size="lg" id="modal-upload-update-list" :title="upload_file_title" hide-footer>

            <b-row>
                <b-col class="" cols="12">
                    <span class="mx-2">
                        <img style="width: 1.3em;" :src="getPlatformImage(listSelected.platform)">
                    </span>

                    <span class="mx-2">{{ listSelected.platform }}</span>

                    <span class="mx-2">{{ listSelected.list_name }} - {{ listSelected.total_contacts }} contact{{
                        listSelected.total_contacts > 1 ? 's' : '' }}</span>

                    <span class="text-info pull-right" :data-original-title="getStateText(listSelected.state)"
                        :title="getStateText(listSelected.state)">
                        {{ getStateText(listSelected.state) }} <i aria-hidden="true" class="fa"
                            :class="getStateIcon(listSelected.state)"></i>
                    </span>
                </b-col>
            </b-row>


            <h4 class="text-info mx-2"><i class="ri-file-list-3-fill"></i> Upload Lists</h4>

            <b-row v-if="listSelected && listSelected.platform && getPlatformImage(listSelected.platform)"
                class="row mt-3" align-v="center">

                <b-col class="my-2" cols="12">
                    <h5 class="text-primary mx-2"><i class="ri-file-list-3-fill"></i> Lists</h5>

                    <b-row v-if="listSelected && custom_columns && listsAdded">
                        <b-col cols="6" v-for="(list, i) in listsAdded" :key="i" class="my-1">
                            <b-input-group class="">

                                <b-form-input :id="'input-valid' + i + list.list_id" placeholder="Valid input"
                                    :value="listSelected.list_name + ' - ' + list.list_id + ' - addtional File Contacts - ' + dateFormat(list)"
                                    :disabled="true"></b-form-input>

                                <template #append>
                                    <b-input-group-text>
                                        <strong v-if="getStateText(list.state) == 'Synced'" class="text-success"><i
                                                aria-hidden="true" class="fa"
                                                :class="getStateIcon(list.state)"></i></strong>
                                        <strong v-else-if="getStateText(list.state) == 'In Progress'"
                                            class="text-info"><i aria-hidden="true" class="fa"
                                                :class="getStateIcon(list.state)"></i></strong>
                                        <strong v-else-if="getStateText(list.state) == 'Disconnected'"
                                            class="text-danger"><i aria-hidden="true" class="fa"
                                                :class="getStateIcon(list.state)"></i></strong>
                                        <strong v-else-if="getStateText(list.state) == 'Inactive/deleted'"
                                            class="text-muted"><i aria-hidden="true" class="fa"
                                                :class="getStateIcon(list.state)"></i></strong>
                                    </b-input-group-text>
                                </template>

                            </b-input-group>

                        </b-col>

                        <b-col cols="12" v-if="!listsAdded.length">
                            <span class="mx-2">No Additional contacts file found for {{ listSelected.list_name }}</span>
                        </b-col>
                    </b-row>
                </b-col>

            </b-row>

            <UploadFile v-if="listSelected" :list="listSelected" :platform="listSelected._platform"
                @uploadedList="uploadedNewList" @uploadedNewList="uploadedNewList"></UploadFile>


            <button name="close" value="Close" type="button" class="btn next action-button float-right btn-primary"
                @click="hideModal">
                Close
            </button>
        </b-modal>


        <UpdateContactsInUploadedListModal :list="selected_list" :action="update_contacts_action">
        </UpdateContactsInUploadedListModal>

        <!-- TrackedList TimeLine -->
        <TrackedListTimeLine :list="listSelected"></TrackedListTimeLine>
        
    </b-container>
</template>

<script>
import Spinner1 from '@/components/loaders/spinner1'
import store from '@/store/index'
import api from '@/api/RestClient'
import { helper } from '@/helpers';
import ListTimeLine from './timeLogList.vue';
import UploadFile from '@/components/inboxgeek/UploadFile';
import RemoveContactsFile from "@/components/inboxgeek/RemoveContactsFile";
import UpdateContactsInUploadedListModal from "@/components/inboxgeek/modals/UpdateContactsInUploadedListModal";
import { PLATFORMS_ITEMS } from '@/constantes';
import { mapGetters } from "vuex";
import TrackedListTimeLine from "@/components/inboxgeek/modals/TrackedList/TimeLine";

export default {
    name: 'DtLists',
    components: {
        Spinner1,
        ListTimeLine,
        UploadFile,
        RemoveContactsFile,
        UpdateContactsInUploadedListModal,
        TrackedListTimeLine
    },
    data() {
        return {
            id: 'dt-lists',
            isBusy: false,
            items: [],
            fields: [
                { key: 'list_name', label: 'List name', sortable: true, sortDirection: 'desc', class: 'text-left' },
                { key: 'list_id', label: 'List Id', sortable: true, sortDirection: 'desc', class: 'text-center' },
                { key: 'platform', label: 'Platform', sortable: true, sortDirection: 'desc', class: 'text-left' },
                { key: 'total_contacts', label: 'Contacts', sortable: true, sortDirection: 'desc', class: 'text-center' },
                { key: 'account', label: 'Account', sortable: true, sortDirection: 'desc', class: 'text-center' },
                { key: 'state', label: 'Status', sortable: true, sortDirection: 'desc', class: 'text-center' },
                { key: 'refresh', label: 'Auto Refresh', sortable: true, sortDirection: 'desc', class: 'text-center' },
                { key: 'month_opens', label: 'Month Opens', sortable: false, class: 'text-center' }, // Change key
                { key: 'actions', label: 'Actions', sortable: false, class: 'text-center' },
            ],
            totalRows: 1,
            currentPage: 1,
            perPage: 25,
            pageOptions: [10, 25, 50, 100, {
                value: 250,
                text: "Show a lot"
            }],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: ["list_name"],
            account: null,
            from: null,
            to: null,
            filterData: null,
            listSelected: {},
            time_log_title: '',
            upload_file_title: '',
            rowTooltip: null,
            custom_columns: null,
            selected_list:null,
            update_contacts_action:'edit',
            listsAdded: [],
            listsDeleted: [],
            delete_file_title: '',

            logsIsLoading: true
        }
    },
    computed: {
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return { text: f.label, value: f.key }
                })
        },
        ...mapGetters('Auth', ['user']),
    },
    mounted() {
        this.loadFields()
    },
    methods: {
        async getData(ctx) {
            await this.delay();
            return await this.fetchData(ctx);
        },
        async fetchData(ctx) {
            var items = []
            this.items = [];
            this.isBusy = true;
            let queryParams = {}
            if (typeof ctx !== "undefined") {
                queryParams = {
                    search: this.filter,
                    page: ctx.currentPage,
                    per_page: ctx.perPage,
                    sort_by: ctx.sortBy != '' ? ctx.sortBy : 'state',
                    sort_desc: ctx.sortDesc,
                    // filter_on: JSON.stringify(this.filterOn),
                    account: this.account ? this.account.code : null
                }
                if (this.filterData) {
                    queryParams.filters = this.filterData;
                }
            }

            return api.trackedLists.list(queryParams)
                .then((response) => {
                    items = response.data.data;
                    items = _.map(items, function (item) {
                        if (store.getters['Auth/isAdmin'] && [-3].includes(item.state)) {
                            item._rowVariant = 'danger';
                        }
                        return item;
                    });
                    this.totalRows = response.data.total
                    this.currentPage = response.data.current_page
                    this.perPage = response.data.per_page
                    this.items = items
                    this.from = response.data.from
                    this.to = response.data.to
                    return this.items
                })
                .catch((err) => {
                    console.log(err)
                    return []
                })
                .finally(() => {
                    setTimeout(() => {
                        this.isBusy = false;
                        this.$emit('onFinishedFilter');
                    }, 400);
                })
        },
        delay: () => {
            return new Promise(resolve => setTimeout(resolve, 2000));
        },
        loadFields() {
            let fields = _.map(this.fields, (item) => {
                if (store.getters['Auth/isAdmin']) {
                    return item;
                } else
                    if (!["account", "month_opens"].includes(item.key)) return item;
            })
            this.fields = fields;
        },
        setAccount(account) {
            this.account = account
            this.currentPage = 1
            this.$root.$emit('bv::refresh::table', this.id)
        },
        platformName(name) {
            return helper.platformFormatName(name);
        },
        updateItem(id, data, item = null) {
            var toast = { message: 'Something went wrong!', title: 'Error', variant: 'danger' }

            return api.trackedLists.put(id, data)
                .then(result => {
                    toast = { message: result?.message, title: 'Success', variant: 'success' }
                })
                .catch((error) => {
                    this.$root.$emit('bv::refresh::table', this.id)
                })
                .finally(() => {
                    if (item) {
                        item.state = 'state' in data ? data.state : item.state;
                    }
                    this.$root.$bvToast.toast(toast.message, toast);
                });
        },
        onFiltered(filteredItems) {
        },
        sortingChanged(ctx) {
            this.currentPage = 1
        },

        // ACTIONS
        onSwitchAutoRefresh(item, index, input) {
            var listID = item?.id
            var listData = { auto_refresh: input.checked ? 1 : 0 }
            this.updateItem(listID, listData);
        },
        refreshContacts(item, index, button) {
            item.state = 101;
            var listID = item?.id
            var listData = { state: 5 }
            this.updateItem(listID, listData, item);
        },

        removeItem(item, index, button) {
            this.$swal({
                title: 'Are you sure?',
                text: `This action will permenantly delete ${item?.list_name} List`,
                type: 'danger',
                showCancelButton: true,
                confirmButtonText: 'Yes Delete it!',
                cancelButtonText: 'No, Keep it!',
                showCloseButton: true,
                showLoaderOnConfirm: true
            }).then((result) => {
                if (result.value) {
                    let swalObj = {
                        type: 'success',
                        confirmButtonText: 'OK',
                    }
                    api.trackedLists.delete(item?.id)
                        .then(result => {
                            swalObj.title = 'Success!';
                            swalObj.text = `The ${item.list_name} list has been successfully deleted.`;
                        })
                        .catch(function (error) {
                            swalObj.type = 'warning';
                            swalObj.title = 'Warning!';
                            swalObj.text = 'Something went wrong!';
                        })
                        .finally(() => {
                            this.$swal(swalObj)
                            this.$root.$emit('bv::refresh::table', this.id);
                        });
                }
            })
        },
        getStateText(state) {
            var stateEn = '';
            switch (state) {
                case -1: case -2:
                    stateEn = 'Inactive/deleted';
                    break;
                case -3:
                    stateEn = 'Disconnected';
                    break;
                case 1: case 2:
                    stateEn = 'Synced';
                    break;
                default:
                    stateEn = 'In Progress';
                    break;
            }
            return stateEn;
        },
        getStateIcon(state) {
            var stateEn = '';
            switch (state) {
                case -1: case -2:
                    stateEn = 'fa-exclamation-triangle text-danger';
                    break;
                case -3:
                    stateEn = 'fa-ban text-danger';
                    break;
                case 1: case 2:
                    stateEn = 'fa-check';
                    break;
                default:
                    stateEn = 'fa-spinner';
                    break;
            }
            return stateEn;
        },
        showContacts(state) {
            return (state === 1 || state === 2);
        },
        isRefreshable(state) {
            return (state === 1 || state === 2 || state === -3);
        },
        filterTable(params) {
            this.filterData = JSON.stringify({
                status: params.status?.code ?? '',
                platform: params.platform ? _.map(params.platform, 'name') : [],
                daily_cap: params.daily_cap ?? '',
                date_to: params.date_to ?? '',
                date_from: params.date_from ?? '',
                auto_refresh: params.auto_refresh?.code ?? '',
                platform_account: params.platform_account ?? '',
            });
            this.$root.$emit('bv::refresh::table', this.id)
        },
        canRefreshList(item) {
            try {
                if (!item.platform || item.platform.trim() == '') return true;

                let platform = item.platform.toLowerCase();
                return !['webhook', 'inboxsuite'].includes(platform);
            } catch (e) {
                console.error(e);
                return null;
            }


        },
        timeLogs(item, index) {
            this.listSelected = item;
            this.time_log_title = `Time line list  ${item.list_name}`
            this.$root.$emit("bv::show::modal", "modal-time-log-lists");
        },
        onEmailIntell(item, index) {

            item = this.items[index]
            item.td_state = 301;
            this.$forceUpdate();

            return api.trackedLists.eiInit(item.id)
                .then((response) => {

                    item.td_state = null;
                    item.td_status = response.data.td_status;

                    this.items[index].td_status = response.data.td_status;
                    this.$forceUpdate();

                    this.$swal({
                        title: 'Email Intelligence',
                        text: response.message,
                        type: 'success',
                        showCancelButton: false,
                        confirmButtonText: 'Ok',
                        showCloseButton: true,
                        showLoaderOnConfirm: true
                    })
                })
                .catch((err) => {
                    item.td_state = null;
                })
        },
        onRowHovered(item, _, event) {
            if (store.getters['Auth/isAdmin'] && [-3].includes(item.state)) {
                let lastLogMessage = '';
                let obj = JSON.parse(JSON.stringify(item.tracked_lists_errors));
                if (obj) {
                    let message = obj[0];
                    if (message && 'errors' in message) {
                        lastLogMessage = message.errors;
                    }
                }

                let options = {
                    title: lastLogMessage,
                    trigger: 'hover',
                }
                $(event.target).tooltip(options)
            }
        },
        onRowUnhovered() {
            this.rowTooltip = null;
        },
        getPlatformImage(platform) {
            try {
                const imgFileName = platform.toLowerCase();
                return require(`@/assets/images/platforms/${imgFileName}.png`);
            } catch (e) {
                return null;
            }
        },
      updateUploadContacts(list,action){
            this.selected_list = list;
            this.update_contacts_action = action;

        },
        onUploadContacts(item, index) {
            this.custom_columns = null;
            this.listsAdded = [];
            this.listsDeleted = [];

            if (typeof item.platform !== 'undefined' && ['webhook', 'inboxsuite'].includes(item.platform.toLowerCase())) {
                this.listSelected = item;
                this.listSelected._platform = PLATFORMS_ITEMS.find(o => o.name.toLowerCase() == item.platform.toLowerCase());

                this.filteredLists(item);

                this.upload_file_title = `Upload New Contacts to list: ${item.list_name}`
                this.$root.$emit("bv::show::modal", "modal-upload-update-list");
            }

        },
        uploadedNewList(list) {

            this.refreshLists(list);

        },
        deletedList(list) {

            this.refreshLists(list);

        },
        refreshLists(list) {

            this.custom_columns = null;
            this.listsAdded = [];
            this.listsDeleted = [];

            this.custom_columns = list.custom_columns ? JSON.parse(list.custom_columns) : { list_ids: [] }

            this.$nextTick(() => {

                if (typeof this.custom_columns.list_ids !== 'undefined') {
                    this.listsAdded = _.filter(this.custom_columns.list_ids, (o) => { return o.action == 'add'; });
                    this.listsDeleted = _.filter(this.custom_columns.list_ids, (o) => { return o.action == 'delete'; });
                }

            })

        },
        hideModal() {
            this.$root.$emit("bv::hide::modal", "modal-upload-update-list", "#modal-upload-update-list");
        },
        onDeleteContacts(item, index) {

            if (typeof item.platform !== 'undefined' && ['webhook', 'inboxsuite'].includes(item.platform.toLowerCase())) {
                this.listSelected = item;
                this.listSelected._platform = PLATFORMS_ITEMS.find(o => o.name.toLowerCase() == item.platform.toLowerCase());

                this.filteredLists(item);

                this.delete_file_title = `Delete Contacts to list: ${item.list_name}`
                this.$root.$emit("bv::show::modal", "modal-upload-delete-contacts");
            }

        },
        hideModalDelete() {
            this.$root.$emit("bv::hide::modal", "modal-upload-delete-contacts", "#modal-upload-delete-contacts");
        },
        dateFormat(item) {
            if (typeof item?.date_time == 'undefined') return '--';

            var dt = new Date(item.date_time);
            return helper.formatDate(dt)
        },
        filteredLists(item) {

            if (item.custom_columns) {

                this.custom_columns = JSON.parse(item.custom_columns);

                if (typeof this.custom_columns.list_ids !== 'undefined') {
                    this.listsAdded = _.filter(this.custom_columns.list_ids, (o) => { return o.action == 'add'; });
                    this.listsDeleted = _.filter(this.custom_columns.list_ids, (o) => { return o.action == 'delete'; });
                }

            }

        },
        showLogs(item, index) {
            this.listSelected = item;
            this.$root.$emit("bv::show::modal", "modal-list-timeline");
        },
    },
    watch: {
    }
}
</script>
<style>
.table-warning > td {
    background-color: rgba(255, 245, 217, .5) !important;
}

.ig-text-wrap {
    word-wrap: break-word;
}
</style>
