<template>
  <div>
    <label for="">Lists:</label>
    <multiselect v-model="list"
                 :options="lists"
                 tag-placeholder="Empty"
                 label="name"
                 track-by="id"
                 :multiple="false"
                 return="code"

                 :allow-empty="false"
                 :taggable="true"
                 :loading="loading"
                 @select="handelSelectEvent"
                 @remove="handelSelectEvent">

      <template slot="placeholder" slot-scope="props">
        <b-spinner v-if="loadingProp" small type="grow"></b-spinner>   {{placeholder}}
      </template>
      <template slot="option" slot-scope="props">
        <div class="option__desc">

                                <span class="option__title">

                                    <span v-if="props.option.type">

                                        <i v-if="props.option.type === 'segment'" class="ri-flashlight-line"
                                           style="color:#5ad"></i>
                                        <i v-else class="ri-list-check color-primary" style="color:#5ad"></i>

                                    </span>

                                    <span v-else><i class="ri-list-check color-primary" style="color:#5ad"></i></span>

                                    <span style="font-wight:bold;"> {{ props.option.name }} </span>

                                    <span style="font-size:11px; color:#888"> {{ props.option.code }}</span>


                                </span>

        </div>

      </template>

    </multiselect>
    <small v-if="error.show">{{error.message}}</small>
  </div>

</template>

<script>
import Multiselect from 'vue-multiselect'
import api from "@/api";
export default {
  name: "KlaviyoListInput",
  props:['form','listsProp','loadingProp'],
  components:{
    Multiselect
  },
  mounted() {
    this.loading = this.loadingProp;
    if(this.listsProp.length === 0){
      this.fetchLists();
    }else{
      this.lists = this.listsProp;
    }

  },
  data(){
    return{
      list:null,
      loading:false,
      lists:[],
      error:{
        show:false,
        message:''
      }
    }
  },methods:{
    fetchLists(){
      this.loading = true;
      api.platforms.fetchServices({
        platform_name : 'klaviyo',
        entities      : 'lists',
        platform_id   : this.form['initial'].platform.id
      }).then((response)=>{
        this.loading = false;
        this.lists = response.data;
      });
    },
    handelSelectEvent(){
      this.$emit('ListSelected',this.list);
    }
  },
  computed:{
    placeholder(){
      return this.loading ? "Fetching lists from Platform..." : "Select a suppression list"
    }
  }
}
</script>

<style scoped>

</style>