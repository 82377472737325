<template>
    <div style="min-width: 250px; position: relative;">

      <span @click="cancel" style="position: absolute;top: 0px;right: 5px;cursor: pointer;"><svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style=" width: 15px;"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path fill-rule="evenodd" clip-rule="evenodd" d="M19.207 6.207a1 1 0 0 0-1.414-1.414L12 10.586 6.207 4.793a1 1 0 0 0-1.414 1.414L10.586 12l-5.793 5.793a1 1 0 1 0 1.414 1.414L12 13.414l5.793 5.793a1 1 0 0 0 1.414-1.414L13.414 12l5.793-5.793z" fill="#666666"></path></g></svg></span>




      <div class="p-2">

        <div class="row p-2">
          <div class="col-3 p-0">

            <svg fill="#55aadd" viewBox="0 0 32 32" width="60px" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;" version="1.1" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:serif="http://www.serif.com/" xmlns:xlink="http://www.w3.org/1999/xlink"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><g id="Icon"></g><path d="M16,6c-5.519,0 -10,4.481 -10,10c0,5.519 4.481,10 10,10c5.519,0 10,-4.481 10,-10c0,-5.519 -4.481,-10 -10,-10Zm0,2c4.415,0 8,3.585 8,8c0,4.415 -3.585,8 -8,8c-4.415,0 -8,-3.585 -8,-8c0,-4.415 3.585,-8 8,-8Z"></path><path d="M15.5,13l0,3c0,0.099 0.029,0.195 0.084,0.277l2,3c0.153,0.23 0.464,0.292 0.693,0.139c0.23,-0.153 0.292,-0.464 0.139,-0.693l-1.916,-2.874c0,-0 0,-2.849 0,-2.849c0,-0.276 -0.224,-0.5 -0.5,-0.5c-0.276,-0 -0.5,0.224 -0.5,0.5Z"></path><path d="M7,16.5l4,-0c0.276,0 0.5,-0.224 0.5,-0.5c0,-0.276 -0.224,-0.5 -0.5,-0.5l-4,0c-0.276,0 -0.5,0.224 -0.5,0.5c0,0.276 0.224,0.5 0.5,0.5Z"></path><path d="M15.5,7l0,4c0,0.276 0.224,0.5 0.5,0.5c0.276,-0 0.5,-0.224 0.5,-0.5l0,-4c0,-0.276 -0.224,-0.5 -0.5,-0.5c-0.276,-0 -0.5,0.224 -0.5,0.5Z"></path><path d="M25,15.5l-4,0c-0.276,0 -0.5,0.224 -0.5,0.5c0,0.276 0.224,0.5 0.5,0.5l4,-0c0.276,0 0.5,-0.224 0.5,-0.5c0,-0.276 -0.224,-0.5 -0.5,-0.5Z"></path><path d="M16.5,25l0,-4c0,-0.276 -0.224,-0.5 -0.5,-0.5c-0.276,-0 -0.5,0.224 -0.5,0.5l0,4c0,0.276 0.224,0.5 0.5,0.5c0.276,-0 0.5,-0.224 0.5,-0.5Z"></path></g></svg>

          </div>
          <div class="col-9 p-0" style="margin-top: auto; margin-bottom: auto;">
            <h6> Schedule </h6>

            <small v-if="integration?.enabled_at" class="text-muted">
              Deactivation Scheduled at :<br>{{ integration.enabled_at }} EST
            </small>
            <small v-else-if=" integration?.disabled_at" class="text-muted">
              Activation Scheduled at :<br>{{ integration.disabled_at }} EST
            </small>
          </div>

        </div>


        <div v-if="showSchedul">

          <label for="popover-schedule-date">Date:</label>

          <b-form-datepicker
              ref="popover-schedule-date"
              id="popover-schedule-date"
              v-model="schedule.date"
              type="date"
              :min="new Date()"
          ></b-form-datepicker>

          <label class="mt-2" for="popover-schedule-time">Time: (EST)</label>

          <div class="d-flex">

            <multiselect
                id="popover-schedule-time"
                v-model="schedule.time.hours"
                :options="hours"
                placeholder="HH"
                selectLabel=""
            ></multiselect>

            <span style="padding: 7px 7px;">:</span>

            <multiselect
                id="popover-schedule-time"
                v-model="schedule.time.minutes"
                :options="minutes"
                placeholder="mm"
                selectLabel=""

            ></multiselect>

          </div>



          <button

              @click="lunchSchedule"
              class="btn btn-sm mt-3"
              style="width: 100%" :class=" integration?.active === 1 ? 'btn-info' : 'btn-info'" :disabled="loading">
            <b-spinner small type="grow" v-show="loading"></b-spinner>
            Schedule  {{  integration?.active === 1 ? 'Deactivation' : 'Activation'  }}
          </button>

        </div>

        <button
            v-else
            @click="isRescheduled=true"
            class="btn btn-sm mt-3"
            style="width: 100%" :class=" integration?.active === 1 ? 'btn-light' : 'btn-light'" :disabled="loading">
          <b-spinner small type="grow" v-show="loading"></b-spinner>
          ReSchedule  {{  integration?.active === 1 ? 'Deactivation' : 'Activation'  }}
        </button>


        <hr>

        <div class="text-right">


          <button
              :disabled="loading"
              style="width: 100%"
              @click="loading=true;$emit('updateIntegrationStatus');"
              class="btn btn-sm btn-danger"
              v-if="integration?.active === 1" ><b-spinner small type="grow" v-show="loading"></b-spinner>  Deactivate now</button>

          <button
              :disabled="loading"
              style="width: 100%"
              @click="loading=true;$emit('updateIntegrationStatus');"
              class="btn btn-sm btn-success"
              v-else ><b-spinner small type="grow" v-show="loading"></b-spinner> Activate now</button>
        </div>



      </div>


    </div>
</template>

<script>

import multiselect from "vue-multiselect";
import api from "@/api";
export default {

  name: "ScheduleIntegrationModal",
  props:['integration'],
  components:{
    multiselect
  },
  data: function (){

    return {
      loading:false,
      popoverShow: false,
      isRescheduled:false,
      schedule:{
        active:false,
        date:null,
        time: {
          hours:null,
          minutes:null
        },
        action:'activate',
        integration:{
          status:1
        }
      },
      status : [
        {name:'enable',value:1},
        {name:'disable',value: 0}
      ],
      hours:[
        "00",
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
      ],
      minutes : ['00','15','30','45']

    }
  },
  methods:{
    hide(){
      this.$refs['schedule_integration'].hide()
    },
    cancel(){
      this.$emit('canceled',this.integration);
    },
    lunchSchedule(){
      this.loading = true;

      this.schedule.action = ( this.integration.active === 1 ) ? 'deactivate' : 'activate' ;

      api
          .integrations
          .schedule(this.integration.id,this.schedule )
          .then((response) => {
            console.log('response.integration',response.data.integration);
              this.loading = false;
              this.$emit('finished',response.data.integration,response.data.action);
          }).catch(error => {


              this.loading = false;
              this.$emit('Error',this.integration);
          });

    },
    callAlertToast(){

    },
    callSuccessToast(){
      this.$bvToast.toast('Schedule added successfully', {
        title: 'Success',
        variant: 'success'
      });

    }
  },
  computed :{
    showSchedul(){

      return !(this.integration.enabled_at !== null || this.integration.disabled_at !== null) || this.isRescheduled;


    }
  }


}


</script>


<style>

.b-calendar-grid {
  background-color: white !important;
}
.b-calendar-grid-body span.disabled {
 color : #bcbcbc !important;
}

.b-calendar-grid-body .bg-light {
  background-color: #f8f8f8 !important;
}

</style>