<template>
    <b-container fluid>

        <iq-card>

            <template v-slot:body>

                <div class="">
                    <div class="iq-email-list">
                        <button class="btn btn-primary btn-lg btn-block mb-3 font-size-16 p-3" v-b-modal.addSupportTicketWizardModal>
                            <i class="ri-send-plane-line mr-2"></i>New Ticket
                        </button>

                        <div class="iq-email-ui nav flex-column nav-pills">

                            <li v-for="(groupTicket, index) in groupTickets" :key="index" class="nav-link"
                                role="tab" data-toggle="pill" :href="`#mail-${groupTicket.label}`" @click="activeGroupTicket(groupTicket)">
                                <a href="#">
                                    <i :class="groupTicket.icon"></i>{{groupTicket.name}}
                                </a>
                            </li>
                        </div>

                        <h6 class="mt-4 mb-3 d-none">Labels</h6>
                        <ul class="iq-email-ui iq-email-label d-none">
                            <li><a href="#"><i class="ri-focus-fill text-primary"></i>Personal</a></li>
                            <li><a href="#"><i class="ri-focus-fill text-danger"></i>Company</a>
                            </li>
                            <li><a href="#"><i class="ri-focus-fill text-success"></i>Important</a>
                            </li>
                            <li><a href="#"><i class="ri-focus-fill text-info"></i>Private</a>
                            </li>
                            <li><a href="#"><i class="ri-focus-fill text-warning"></i>Private</a></li>
                            <li><a href="#"><i class="ri-add-circle-line"></i>Add New Labels</a>
                            </li>
                        </ul>

                    </div>
                </div>

                <!-- $root.$emit('bv::refresh::table', 'support-ticket') -->
                <CreateSupportTicketWizardModal @createTicket=""></CreateSupportTicketWizardModal>

            </template>
        </iq-card>
    </b-container>
</template>

<script>
import Spinner1 from '@/components/loaders/spinner1'
import { mapGetters } from 'vuex'
import _ from 'lodash'
import api from '@/api/RestClient'
import CreateSupportTicketWizardModal from '@/components/inboxgeek/modals/Wizards/SupportTicket/CreateSupportTicketWizardModal.vue'


export default {
    name: "TicketSideMenu",
    props: ['groupTickets'],
    components: {
        Spinner1,
        CreateSupportTicketWizardModal
    },
    mounted() {
        this.$root.$on('ibg:loading-complete-tickets', (firstItem) => {
            const links = document.querySelectorAll(`li[href="#mail-${firstItem.label}"]`);
            if(links.length) {
                $(links[0]).click()
                let ticket_id = this.$route.query.id;
                setTimeout(() => {
                    if(ticket_id) {
                        $('#ticket_content_' + ticket_id).addClass('show')
                    }
                }, 3000);
            }
        })
    },
    computed: {
        ...mapGetters({
            isAdmin: 'Auth/isAdmin',
            isClient: 'Auth/isClient',
            user: 'Auth/user',
        })
    },
    data() {
        return {
        }
    },
    methods: {
        activeGroupTicket(groupTicket) {
            if (groupTicket.name != 'Open') { this.$router.replace({ 'query': null }); }
            this.$root.$emit('ibg:active:ticket-group', groupTicket);
        },

        updateTicket(id, data) {
            api.supportTickets.put(id, data).then(response => {
                console.log(response);
            }).catch((error)=>{
                console.log(error);
            }).finally(() => {

            })
        }
    },
};
</script>