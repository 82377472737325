<template>
  <b-container fluid>
    <b-modal id="modal-add-integration" ref="add_integration" size="xl" centered hide-footer @hide="resetAddModal"
      :no-close-on-backdrop="true">
      <template #modal-header="{ close }">
        <!-- Emulate built in modal header close button action -->
        <div class="d-flex">
          <h5>New integration</h5>
          <span class="ml-1" v-b-tooltip.hover title="Integration help guide"><a :href="help.link" target="_blank"><i
                class="fa fa-info-circle text-info fa-lg"></i></a></span>
        </div>
        <div @click="hiddenModalIntegration">
          <i class="fa fa-close text-danger cursor-pointer fa-lg"></i>
        </div>
      </template>
      <b-row>
        <b-col lg="12" sm="12" class="p-4">

          <b-form id="form-wizard1" class="text-center mt-4">
            <ul id="top-tab-list" class="p-0">
              <li class="active" id="top-step-platform">
                <b-link href="javascript:void();">
                  <i class="ri-lock-unlock-line"></i><span>Platform</span>
                </b-link>
              </li>
              <li id="top-step-list">
                <b-link href="javascript:void();">
                  <i class="ri-user-fill"></i><span>List</span>
                </b-link>
              </li>
              <li id="top-step-integration">
                <b-link href="javascript:void();">
                  <i class="ri-camera-fill"></i><span>Integration</span>
                </b-link>
              </li>
              <li id="top-step-finish">
                <b-link href="javascript:void();">
                  <i class="ri-check-fill"></i><span>Finish</span>
                </b-link>
              </li>
            </ul>

            <!-- Add int. step 1 content -->
            <StepPlatform ref="step-platform" :formProp="form" :colsProp="cols" :showColsProp="showCols"
              :customProp="custom" :organizationProp="organization" :auth2Prop="auth2"
              @onPlatformSelected="showAnotherCols" @onOauthSelectPlatform="selectPlatformConfig" @onNext="onNext"
              @onOauth="onOauth" />

            <StepList ref="step-list" :listProp="list" :oAccountId="form.oAccountID" :auth2="auth2"
              :platform="form.platform" :listsProp="lists" @onNext="onNext" @onPrevious="onPrevious"
              @onSelectedListOption="selectedListOption" :isExistList="existList" />

            <StepIntegration ref="step-integration" :tagLabelProp="tagLabel"
              :selectTagPlaceholderProp="selectTagPlaceholder" :tagPlaceholderProp="tagPlaceholder" :formProp="form"
              :showColsProp="showCols" :tagProp="tagSelected" :tagsProp="tags" @onNext="onNext" @onPrevious="onPrevious"
              @onAddTag="addTag" @onRefreshTags="refreshTags" @onCheckTag="checkTagExist"
              :organizationId="organizationId" />

            <StepFinish ref="step-finish" @onHideModal="resetAddModal" />


          </b-form>

        </b-col>
      </b-row>
    </b-modal>
    <b-modal ref="expert-sender-test" centered hide-footer title="ExpertSender Custom Event Test">
      <b-row class="mb-3">
        <b-col sm="12" class="mb-3">
          <label for="range-1">To confirm set up, please enter an email address that is included in this list</label>
          <b-form-input id="input-small" size="sm" v-model="expert_sender.email"
            placeholder="Enter Email"></b-form-input>
        </b-col>
        <b-col sm="12">
          <b-button variant="primary" @click="isValidCustomId" size="lg"><i class="fa fa-paper-plane m-0"
              title="Test the event"></i> Test<b-spinner small type="grow"
              v-show="expert_sender.loading"></b-spinner></b-button>
        </b-col>
      </b-row>
    </b-modal>
  </b-container>
</template>

<script>
import { sofbox } from '@/config/pluginInit'
import Multiselect from 'vue-multiselect'

import StepPlatform from './fieldsets/StepPlatform.vue'
import StepList from './fieldsets/StepList.vue'
import StepIntegration from './fieldsets/StepIntegration.vue'
import StepFinish from './fieldsets/StepFinish.vue'
import TestFlow from '@/components/inboxgeek/sendflow'
import { Add } from '@/actions/modules/Integrations/AddIntegrations';
import { Main } from '@/actions/modules/Integrations/';
import { PLATFORMS_CODE, COLUMNS_GRID, PLATFORMS_ITEMS } from "@/constantes";
import _ from 'lodash';
import api from '@/api/RestClient';
import store from '@/store'
import { helper } from '@/helpers'
import { services } from '@/helpers';
import {
  mapGetters
} from "vuex";
import axios from 'axios';

export default {
  name: 'ModalAddIntegration',
  components: {
    Multiselect,
    TestFlow,

    StepPlatform,
    StepList,
    StepIntegration,
    StepFinish,
  },
  props: {
    organizationId: {
      type: [Number, String],
      default: () => null,
    },
  },
  computed: {
    ...mapGetters('Auth', ['user'])
  },
  mounted() {
    this.oauthInstall();
  },
  data() {
    return {
      expert_sender: {
        email: null,
        loading: false,
        btn: null,
        step: 0,
        compStep: null
      },
      existList: true,
      renderComponent: true,
      listLabel: 'List: *',
      listPlaceholder: 'Select Lists',
      sendFlow: false,
      submitNextBtn: false,
      tagSelected: [],
      tags: [],
      checkapi: false,
      showSpinnerStep1: false,
      disabledNextBtnStep1: false,
      nextStep1: false,
      currentStep: 0,

      oauthPlatforms: [PLATFORMS_CODE.AWEBER, PLATFORMS_CODE.KEAP, PLATFORMS_CODE.MAILCHIMP, PLATFORMS_CODE.HUBSPOT],
      auth2: {
        addNew: true,
        btnAuthorize: false,
        inProgress: false,
        state: '',
        btnLabel: '',
        listAccounts: [],
        platforms: [],
        platform: null,
      },

      // For Blastable custom link
      custom: {
        isVisible: false,
        checkbox: false,
        link: ''
      },

      organization: {
        accountid: null,
      },
      integration: {
        recurrence: null,
        recurrence_title: 'Every 0 jours'
      },
      form: {
        accountid: '',
        apikey: null,
        oAccountID: "",
        platform: null,
        name: null,
        tag: null,
        list: null,
        integrationName: null,
        limit: null,
        time_limit_from: null,
        time_limit_to: null,
        ableLimitPerDay: false,
        ableLimitTime: false,
        limitDisable: true,
        timeLimit: true,
        recurrence: null,
        CustomEventName: null,
        CustomEventId: null,
        sendin_blue_event: null,
        customTag: null,
        p_id: null,
      },
      cols: {
        platformCol: 12,
        accoundIdCol: 12,
        apiKeyCol: 12,
      },
      showCols: {
        accountId: false,
        apiKey: true,
        groupCol: false,
        auth2Btn: false,
        tags: true,
        event: false,
        sendin_blue_event: false,
        event_link: false,
        customTag: false
      },
      listdisabled: true,
      list: [],
      lists: [],
      platforms: PLATFORMS_ITEMS,
      plaformNoTags: [PLATFORMS_CODE.KLAVIYO, PLATFORMS_CODE.CAMPAIGNER],
      plaformNoTagsNoAlert: [PLATFORMS_CODE.CAMPAIGNER, PLATFORMS_CODE.BLASTABLE],
      isDisabledTag: false,
      tagLabel: 'Tag: *',
      tagPlaceholder: 'Create tag',
      selectTagPlaceholder: 'Select tag',
      accountLabel: 'Account ID:',
      accountPlaceholder: 'Account ID',
      apiTokenLabel: 'API key/Token: *',
      apiTokenPlaceholder: 'API key',
      spinnerAddTag: false,
      timeFromAlert: false,
      timeToAlert: false,
      isSuccess: false,
      integrationId: null,
      errors: [],
      _list: [],
      help: {
        'platform_name': null,
        'base_url': 'https://help.inboxgeek.com/',
        'link': 'https://help.inboxgeek.com/lo-integration-guides/',
        'hrefs': [
          { 'name': 'maropost', 'platform_uri': 'docs-category/maropost/', 'list_uri': 'docs/get-list-id-3/', 'integration_uri': 'docs/integration-2-2/' },
          { 'name': 'activecampaign', 'platform_uri': 'docs-category/activecampaign-integration/', 'list_uri': 'docs/list-2/', 'integration_uri': 'docs/integration-4/' },

          { 'name': 'campaigner', 'platform_uri': 'docs-category/campaigner-integration/', 'list_uri': 'docs/list-6/', 'integration_uri': 'docs/integration-6/' },
          { 'name': 'ontraport', 'platform_uri': 'docs-category/ontraport-integration/', 'list_uri': 'docs/list-4/', 'integration_uri': 'docs/integration-3/' },

          { 'name': 'klaviyo', 'platform_uri': 'docs-category/klaviyo/', 'list_uri': 'docs/get-list-id-2/', 'integration_uri': 'docs/set-up-your-api-key/' },
          { 'name': 'convertkit', 'platform_uri': 'docs-category/convertkit-integration/', 'list_uri': 'docs/list-3/', 'integration_uri': 'docs/integration-5/' },

          { 'name': 'brevo', 'platform_uri': 'docs-category/brevo-integration/', 'list_uri': 'docs/list-15/', 'integration_uri': 'docs/integration-15/' },
          { 'name': 'inbox', 'platform_uri': 'docs-category/inbox-integration/', 'list_uri': 'docs/list-16/', 'integration_uri': 'docs/integration-16/' },

          { 'name': 'inboxsuite', 'platform_uri': 'docs-category/maropost/', 'list_uri': 'docs/get-list-id-3/', 'integration_uri': 'docs/integration-2-2/' },
          { 'name': 'emailoctopus', 'platform_uri': 'emailoctopus-integration/', 'list_uri': 'docs/list-14/', 'integration_uri': 'docs/integration-14/' },

          { 'name': 'aweber', 'platform_uri': 'docs-category/aweber-integration/', 'list_uri': 'docs/list-5/', 'integration_uri': 'docs/integration-2/' },
          { 'name': 'hubspot', 'platform_uri': 'docs-category/hubspot-integration/', 'list_uri': 'docs/list-9/', 'integration_uri': 'docs/integration-9/' },

          { 'name': 'mailchimp', 'platform_uri': 'docs-category/mailchimp-integration/', 'list_uri': '/docs/list-11/', 'integration_uri': 'docs/integration-11/' },
          { 'name': 'keap', 'platform_uri': 'docs-category/keap-integration/', 'list_uri': 'docs/list-8/', 'integration_uri': 'docs/integration-8/' },

          { 'name': 'gohighlevel', 'platform_uri': 'docs-category/highlevel-integration/', 'list_uri': 'docs/list-18/', 'integration_uri': 'docs/integration-18/' },
          { 'name': 'listrak', 'platform_uri': 'docs-category/listrak-integration/', 'list_uri': 'docs/list-19/', 'integration_uri': 'docs/integration-19/' },

          { 'name': 'sendgrid', 'platform_uri': 'docs-category/sendgrid-integration', 'list_uri': 'docs/list-17/', 'integration_uri': 'docs/integration-17' },
          { 'name': 'omnisend', 'platform_uri': '/docs-category/omnisend-integration/', 'list_uri': 'docs/omnisend-list/', 'integration_uri': 'docs/omnisend-integration/' },

          { 'name': 'ongage', 'platform_uri': 'docs-category/ongage-integration/', 'list_uri': 'docs/list-ongage/', 'integration_uri': 'docs/integration-ongage/' },
          { 'name': 'blastable', 'platform_uri': 'docs-category/blastable-integration/', 'list_uri': 'docs/list-7/', 'integration_uri': 'docs/integration-7/' },

          { 'name': 'sendrapp', 'platform_uri': 'docs-category/activecampaign-integration/', 'list_uri': 'docs/list-2/', 'integration_uri': 'docs/integration-4/' },
          { 'name': 'beehiiv', 'platform_uri': 'docs-category/beehiiv-integration/', 'list_uri': 'docs/list-beehiiv/', 'integration_uri': 'docs/integration-beehiiv/' },

          { 'name': 'getresponse', 'platform_uri': 'docs-category/getresponse-integration/', 'list_uri': 'docs/list-10/', 'integration_uri': 'docs/integration-10/' },
          { 'name': 'expertsender', 'platform_uri': 'docs-category/expertsender-integration/', 'list_uri': 'docs/list-12/', 'integration_uri': 'docs/integration-12/' },

          { 'name': 'sendlane', 'platform_uri': 'docs-category/sendlane/', 'list_uri': 'docs/list/', 'integration_uri': 'docs/integration/' },
          { 'name': 'mailsend', 'platform_uri': 'docs-category/mailsend-integration/', 'list_uri': 'docs/mailsend-list/', 'integration_uri': 'docs/mailsend-integration/' },

        ]
      }
    }
  },

  methods: {
    hiddenModalIntegration(e){
      var swalObj = {
        title: 'Are you sure?',
        html: 'You are about to leave the integration setup form. Any unsaved changes will be lost.\n' +
            '\n' +
            'click<a href="https://help.inboxgeek.com/lo-integration-guides/" target="_blank">here</a> if you need help completing your integration setup.',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ok',
        cancelButtonText: 'Cancel!',
        showLoaderOnConfirm: true
      };
      this.$swal(swalObj).then((result) => {

        if (result.value) {
          this.$refs['add_integration'].hide()
        } 

      });
    },
    getHelpLinkResource(resource = null) {

      this.help.link = 'https://help.inboxgeek.com/lo-integration-guides/';
      const the_platform_name = this.help.platform_name;
      if (the_platform_name) {
        let data = _.find(this.help.hrefs, function (q) { return q.name == the_platform_name; })
        if (data) {
          const item = `${resource}_uri`
          this.help.link = this.help.base_url + data[item]//eric
        }
        }
    },
    isValidCustomId() {

      this.expert_sender.loading = true;
      let type = 'error';
      let message = '***';

      const platformId = this.form.platform.p_id;
      const email = this.expert_sender.email;
      const eventId = this.form.CustomEventId;

      api.platforms.validate_expert_sender_customId(eventId, email, platformId).then(((result) => {
        type = 'success';
        message = result.data.message;
        this.$bvToast.toast(message, {
              title: 'Good!',
              variant: type
          })
        services.default.onNext(this, this.expert_sender.btn, this.expert_sender.step,  this.expert_sender.compStep)

      })).catch((error) => {
        this.is_disabled_btn_next = true;
        message = error.response.data.message;
        this.$swal('Error', message, type);
      }).finally(() => {
        this.expert_sender.loading = false;
        this.$refs['expert-sender-test'].hide()
      })
    },
    setOrganization(data = {}) {
      if (typeof data.code !== "undefined") this.organization.accountid = data.code
    },
    onOauth({ btn, step, compStep }) {
      const STEP_PLATFORM = 0
      // $(click).attr('disabled', true);
      btn.disabled = true
      var code = this.form.platform.code;
      store.dispatch('updateOAuth2AuthUrl', null);
      store.dispatch('updateOAuth2AuthParams', null);

      // Intercept the first step specific to oauthPlatforms Integration type
      if ((step == STEP_PLATFORM) && this.oauthPlatforms.includes(code)) {
        services.default.mainOAuth(this, btn, code)
      }
    },
    refreshTags() {
      this.fetchTags(this, selectList.code);
    },
    onNext({ btn, step, compStep }) {
      this.forceRerender()
      if (this.form.platform.code == PLATFORMS_CODE.LISTRAK && step == 1) {
        let selectList = this._list[0];
        this.fetchTags(this, selectList.code);
      }
      if (this.form.platform.code == PLATFORMS_CODE.EXPERTSENDER && step == 2) {
        this.expert_sender.btn = btn;
        this.expert_sender.step = step;
        this.expert_sender.compStep = compStep;
        this.$refs['expert-sender-test'].show()
        return;
      }
      services.default.onNext(this, btn, step, compStep)
    },
    onPrevious({ btn, step, compStep }) {
      var currentFs, previousFs;
      this.spinnerAddTag = false
      this.sendFlow = false

      btn.disabled = false

      currentFs = $(btn).parent()
      previousFs = $(btn).parent().prev()

      if (step > 0) this.previousStep(btn, currentFs, previousFs)
      if (step - 1 == 0) {
        this.getHelpLinkResource('platform')
      } else if (step - 1 == 1) {
        this.getHelpLinkResource('list');
      } else if (step - 1 == 2) {
        this.getHelpLinkResource('integration');
      } else {
        this.getHelpLinkResource()
      }
    },
    setProgressBar(curStep, steps) {
      var percent = parseFloat(100 / steps) * curStep
      percent = percent.toFixed()
      $('.progress-bar').css('width', percent + '%')
    },
    nextStep(nextFs, currentFs, current) {
      if(current == 0) {
        this.getHelpLinkResource('list');
      } else if (current == 1) {
        this.getHelpLinkResource('integration');
      }
      var opacity
      // Add Class Active
      this.nextStep1 = false;
      let nextIndex = $('fieldset').index(nextFs);
      let currentIndex = $('fieldset').index(currentFs);

      $('#top-tab-list li').eq(nextIndex).addClass('active')
      $('#top-tab-list li').eq(currentIndex).addClass('done')

      // show the next fieldset
      nextFs.show()
      // hide the current fieldset with style
      currentFs.animate({
        opacity: 0
      }, {
        step: function (now) {
          // for making fielset appear animation
          opacity = 1 - now
          currentFs.css({ 'display': 'none', 'position': 'relative' })
          nextFs.css({ 'opacity': opacity })
        },
        duration: 500
      })
      this.setProgressBar(++current)
    },
    previousStep(btn, currentFs, previousFs) {
      var opacity

      $('#top-tab-list li').eq($('fieldset').index(currentFs)).removeClass('active')
      $('#top-tab-list li').eq($('fieldset').index(previousFs)).removeClass('done')


      // show the previous fieldset
      previousFs.show()
      // hide the current fieldset with style
      currentFs.animate({
        opacity: 0
      }, {
        step: function (now) {
          // for making fielset appear animation
          opacity = 1 - now
          currentFs.css({
            'display': 'none',
            'position': 'relative'
          })
          previousFs.css({
            'opacity': opacity
          })
        },
        duration: 500
      })
      this.setProgressBar(--this.currentStep)
    },
    formatLists(items) {
      var servicePlatform = null
      var platformName = this.form.platform.name.toLowerCase();
      var defaultService = services.default

      if (typeof this.form.platform === "undefined" || !this.form.platform) return [];

      let strId = 'id';
      let strName = 'name'

      if ((typeof services[platformName] !== "undefined")) servicePlatform = services[platformName]
      if (servicePlatform?.labels?.listID) strId = servicePlatform.labels.listID
      if (servicePlatform?.labels?.listName) strName = servicePlatform.labels.listName

      return _.map(items, (obj, key) => {
        let item = {}

        if (typeof servicePlatform?.maps?.listsMap === "function") {
          item = servicePlatform.maps.listsMap(obj)
        } else {
          item = defaultService.maps.listsMap(obj, strId, strName)
        }
        return item
      })
    },
    formatTags(items) {
      var servicePlatform = null
      var platformName = this.form.platform.name.toLowerCase();
      var defaultService = services.default

      if (typeof this.form.platform === "undefined" || !this.form.platform) return [];
      let strId = 'id'
      let strName = 'name'

      let labels = null
      if ((typeof services[platformName] !== "undefined")) servicePlatform = services[platformName]
      if (servicePlatform?.labels?.tagID) strId = servicePlatform.labels.tagID
      if (servicePlatform?.labels?.tagName) strName = servicePlatform.labels.tagName

      return _.map(items, (obj, key) => {
        let item = {}

        if (typeof servicePlatform?.maps?.tagsMap === "function") {
          item = servicePlatform.maps.tagsMap(obj)
        } else {
          item = defaultService.maps.tagsMap(obj, strId, strName)
        }
        return item
      })
    },
    fetchLists(that, compStep, btn, currentFs, nextFs) {
      var current = 0
      compStep.nextSpinner = true
      var message = 'This Api Token is not valid';
      var success = 'error';

      // check API token is valise
      let service = null;
      if (that.form.platform.code == PLATFORMS_CODE.CONVERKIT) service = 'tags';

      // Fetch lists
      var fetchLists = services.default.fetchPlatformData(that, service);

      fetchLists.then((result) => {
        var data = result.data
        if (result.success) {

          if (that.form.platform.code == PLATFORMS_CODE.CONVERKIT) data = result.data.tags;
          if (that.form.platform.code == PLATFORMS_CODE.SENDLANE) data = result.data;
          if (that.form.platform.code == PLATFORMS_CODE.BLASTABLE) data = result.data.data.records;

          that.lists = services.default.formatLists(data, that.form.platform)
          that.listdisabled = false

          that.$refs['step-list'].cleanForm()
          that.$refs['step-list'].setLists(that.lists);
          that.$refs['step-list'].listdisabled = that.listdisabled;

          that.nextStep(nextFs, currentFs, current)
        } else {
          if (that.form.platform.code == PLATFORMS_CODE.BLASTABLE) {
            const data = result.json();
            data.then(value => {
              that.$swal('Checking Api Result', value?.data?.error ?? message, success);
            })
          } else that.$swal('Checking Api Result', message, success);
          that.currentStep = 0;
        }

      }).catch((error) => {
        console.log(error);
        that.$swal('Checking Api Failed', message, success);
      }).finally(() => {
        compStep.nextSpinner = false
        if (btn) btn.disabled = false
        // Fetch tags
        if (that.form.platform.code != PLATFORMS_CODE.LISTRAK) {
          if (that.showCols.tags) that.fetchTags(that)
        }
      })
    },
    fetchTags(that, listId = '', btn = null) {
      // TEST FOR FIX step no define
      // let step = 2;
      // let compStep = this.$refs["step-list"];

      // Fetch tags
      var fetchtags = services.default.fetchPlatformData(that, 'tags', listId);
      that.isDisabledTag = true
      that.$refs['step-integration'].isLoading = true;
      fetchtags.then((result) => {
        if (result.status == 200 || result.success) {
          if (that.form.platform.code == PLATFORMS_CODE.CONVERKIT) result.data = result.data.tags;
          if (that.form.platform.code == PLATFORMS_CODE.BLASTABLE) result.data = result.data.data.records;
          that.tags = that.formatTags(result.data)
          that.$refs['step-integration'].cleanForm()
          that.$refs['step-integration'].setTags(that.tags);
        }
      })
        .finally(() => {
          that.isDisabledTag = false
          that.$refs['step-integration'].isLoading = false
          that.$refs['step-integration'].isDisabledTag = false;

          if (this.form.platform.code != PLATFORMS_CODE.LISTRAK) {
            services.default.onNext(this, btn, that.currentStep + 1, that)//kiwila
          }
        })
    },
    addTag(newTag) {
      let list_id = (typeof this._list !== 'undefined' && this._list.length) ? this._list[0]?.code : null;

      services.default.addTagPlatform(this, newTag, list_id);
    },
    checkTagExist(tag) {
      let check = false;
      let accountId = this.hasRoles(['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_ACCOUNTS_MANAGER']) ? this.organization.accountid : this.user.account_id;
      api.integrations.checkTag(tag.code, accountId).then(response => {
        if (!_.isEmpty(response.data)) {
          check = true;
        }
      }).catch((error) => {
        console.log(error);
      }).finally(() => {
        this.$refs['step-integration'].finishCheckTag(check, this.form);
      })

    },
    hasRoles(roles = []) {
      return !_.isEmpty(_.filter(this.user.roles, function (r) {
        return roles.includes(r.slug);
      }))
    },
    selectPlatformConfig(platformCode) {
      // Add.getOAuthPlatform(this, platformCode);
      if (typeof services.default.getOAuthPlatform === "function") services.default.getOAuthPlatform(this, platformCode)
    },
    showAnotherCols(platformSelected) {
      this.help.platform_name = platformSelected.name.toLowerCase();
      if (typeof platformSelected.code !== "undefined") this.form.platform = platformSelected
      if (typeof platformSelected.id !== "undefined") this.form.platform.p_id = platformSelected.id
      services.default.showAnotherCols(this);
    },
    oauthInstall() {
      let action = this.$route.query.action;
      let uriData = { ...this.$route.query };
      let continuePrevAction = false;
      if (action) {
        if (['hubspot_integration', 'aweber_integration', 'keap_integration', 'mailchimp_integration'].includes(action)) {
          this.auth2.state = 'oauth.install'
          continuePrevAction = true
        }
        if (continuePrevAction) {
          this.$swal('Authorization IG', 'IG App successfully authorized', 'success');
          localStorage.setItem('oauth.params', JSON.stringify(uriData));
          localStorage.setItem('oauth.refresh', true);

          try {
            window.opener.postMessage({ "data" : uriData }, process.env.VUE_APP_URL);
          } catch (e) {
            console.log(action, uriData)
          }

          window.close();

        }
      }
    },
    cleanError() {
      this.$refs.alertCustomApiLink.classList.add('d-none')
      this.custom.link = '';
    },
    selectedListOption(value) { // Edit to listen $emit event from step 2
      this._list = value;
      this.disabledTags()
    },
    disabledTags() {
      const that = this

      let stepList = that.$refs["step-list"]
      let stepIntegration = that.$refs["step-integration"]
      let code = that.form.platform.code

      setTimeout(() => {

        // Automation on single list
        that.disabledForSingleList(stepList, code);

        // For disabled tags which use same items that lists
        that.disabledForSameItems(stepIntegration, stepList, code)

        // For tags which depend of list_id
        that.refreshTags(code)
      }, 400)
    },
    disabledForSingleList(COMP, code) {
      if ([PLATFORMS_CODE.AWEBER, PLATFORMS_CODE.EMAIL_OCTOPUS].includes(code)) {

        let lists = _.map(COMP.lists, (list) => {
          list.$isDisabled = (COMP.list.length == 1)
            ? COMP.list[0].code != list.code : false
          return list
        })

        COMP.setLists(lists)
      }
    },
    disabledForSameItems(integComp, listComp, code) {
      if ([
        PLATFORMS_CODE.KEAP,
        PLATFORMS_CODE.CONVERKIT,
        PLATFORMS_CODE.BLASTABLE,
        PLATFORMS_CODE.HUBSPOT,
        PLATFORMS_CODE.MUMARA,
        PLATFORMS_CODE.GET_RESPONSE,
        PLATFORMS_CODE.CAMPAIGNER,
        PLATFORMS_CODE.SENDGRID,
        PLATFORMS_CODE.GOHIGHLEVEL,
      ].includes(code)) {
        var tags = _.map(integComp.$data.tags, (tag) => {
          var alreadySelected = _.find(listComp.$data.list, function (o) { return o.code == tag.code; });
          tag.$isDisabled = (typeof alreadySelected !== "undefined")
          return tag
        })
        integComp.setTags(tags)
      }
    },
    refreshTags(code) {
      if ([PLATFORMS_CODE.MAILCHIMP, PLATFORMS_CODE.EMAIL_OCTOPUS].includes(code)) {
        services.default.getPlatformService(this, 'tags')
      }
    },
    resetAddModal(test) {
      this.$emit('hide', test)
    },
    async forceRerender() {
      this.renderComponent = false;
      await this.$nextTick();
      this.renderComponent = true;
    },

  },
  watch: {
    "form.recurrence": {
      handler(newValue, oldValue) {
        const title = "Every " + this.form.recurrence + " days"
        this.integration.recurrence_title = title;
      }
    },
    "help.platform_name": {
      handler(newValue, oldValue) {
        console.log('success');
        this.getHelpLinkResource('platform');
      }
    },
  }
}
</script>

<style>
#top-tab-list li i {
  background: #a4b8cb;
}

#top-tab-list li a {
  color: #87919d;
}

#top-tab-list li.active.done a {
  background: rgb(30, 41, 68);
}

#top-tab-list li.active.done i {
  color: #384f77;
  background-color: #1a213f;
}

select.form-control[size],
select.form-control[multiple] {
  height: 45px !important;
}

.pt-0,
.py-0 {
  padding-top: 6px !important;
}

.select2-container {
  width: 100% !important;
}

.select2-search--dropdown .select2-search__field {
  width: 98%;
}

h3 {
  font-size: 1.5em;
}

#top-tab-list {
  margin: 0 -10px 45px;
  overflow: hidden;
  color: #777D74;
}

.spinnerAddTag {
  position: relative;
  top: -3.5rem;
  left: 95.9%;
}

.fit-image {
  width: 50% !important;
}

.iq-card {
  background: #ffffff;
  border-radius: 5px;
  margin-bottom: 30px;
  border: none;
  box-shadow: 0px 2px 6px 0px #c1d4f1 !important;
}

.iq-card .iq-card-header {
  padding: 0 20px;
  min-height: 60px;
  border-bottom: 0px solid #f2edff;
  align-items: center !important;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0.8rem 1rem;
  border-bottom: 0px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

div.swal2-html-container,
div#swal2-html-container {
  overflow-y: hidden;
}

@media only screen and (max-width: 768px) {
  #top-tab-list li a {
    background: #e5f2ff;
    color: #0084ff;
    display: block;
    padding: 15px;
    margin: 0 1px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    text-decoration: none;
    font-size: 12px;
  }



  .justify-content-center .col-7 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .spinnerAddTag {
    position: relative;
    top: -3rem;
    left: 85.9%;
  }
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
