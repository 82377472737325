<template>
    <b-container fluid>
        <b-row>
            <b-col md="12">
                <iq-card class="">
                    <template v-slot:headerTitle>
                        <b-row class="mt-4">
                            <b-col md="4">
                                <b-form-group label="From" label-for="from">
                                    <b-form-input id="from" type="date" v-model="dates.from" :max="dates.to"
                                        :value="dates.from"></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col md="4">
                                <b-form-group label="To" label-for="to">
                                    <b-form-input id="to" type="date" v-model="dates.to" :min="dates.from" :max="dates.max"
                                        :value="dates.to"></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col md="4">
                                <b-form-group label="To" label-for="submit">
                                    <b-button class="action" variant="primary" :disabled="submitload" @click="onSubmit">
                                        <b-spinner small type="grow" v-if="submitload"></b-spinner>
                                        Submit
                                    </b-button>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </template>
                </iq-card>
                <iq-card>
                    <template v-slot:headerAction class="mt-3">
                        <b-form v-if="is_manager" class="mx-3">
                            <AccountsMultiselect @onSelect="onSelectAccount" />
                        </b-form>
                    </template>

                    <template v-slot:body>
                        <b-row class="d-none" style="justify-content: end;">
                            <b-col lg="12">
                                <div class="container-fluid">
                                    <b-row style="justify-content: end; padding:10px 0px;">
                                        <b-col lg="11" md="11" v-show="isFilter" class="alert-secondary">
                                            <Filters ref="report-filters" @onFilter="onFilter"></Filters>
                                        </b-col>
                                        <b-col lg="1" class="mt-2">
                                            <b-button class="float-right" v-b-tooltip.hover title="Filter" variant="primary"
                                                @click="toggleInputsFilter"> <i class="fa fa-filter mx-0"></i> </b-button>
                                        </b-col>
                                    </b-row>
                                </div>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col lg="12">

                                <!-- organization: organization, -->
                                <DataTable v-if="renderDT" 
                                    ref="dt-liveopens-reports" 
                                    :url="'/liveopens-report'" 
                                    v-bind="{ 
                                        columns: columns, customCells: dtCells, filters: filters, sort: 'account_name',
                                        theadTop: [
                                            { colspan: 1, variant: null, srOnly: true, title: 'Name' },
                                            { colspan: 1, variant: null, srOnly: false, title: 'Generated Events' },
                                            { colspan: 2, variant: 'light', srOnly: false, title: 'Sent' },
                                            { colspan: 2, variant: 'light', srOnly: false, title: 'Balance' },
                                            { colspan: 2, variant: 'light', srOnly: false, title: 'Already Sent' },
                                            { colspan: 2, variant: 'light', srOnly: false, title: 'Already Tagged' },
                                            { colspan: 2, variant: 'light', srOnly: false, title: 'Daily Limit' },
                                            { colspan: 2, variant: 'light', srOnly: false, title: 'Time Limit' },
                                            { colspan: 2, variant: 'light', srOnly: false, title: 'Others' },
                                        ]
                                     }" 
                                     />
                                
                            </b-col>
                        </b-row>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
        
    </b-container>
</template>

<script>
import { sofbox } from "@/config/pluginInit";

import AccountsMultiselect from "@/views/children/AccountsMultiselect.vue";

import Spinner1 from '@/components/loaders/spinner1'
import _ from 'lodash'

import { mapGetters } from 'vuex'
import api from '@/api/RestClient'

import { helper } from '@/helpers'
import store from '@/store/index'

import Filters from "./children/filters.vue";
import DataTable from '@/components/inboxgeek/tables/DataTable.vue'

export default {
    name: "LiveOpensReport",
    components: {
        AccountsMultiselect,
        Spinner1,
        Filters,
        DataTable
    },
    mounted() {
        sofbox.index();
        this.initDates();
        
    },
    computed: {
        ...mapGetters({
            isAdmin: 'Auth/isAdmin',
            isClient: 'Auth/isClient',
            user: 'Auth/user',
        }),
        columns() {
            let columns = [
                { label: 'Account Name', key: 'account_name', class: 'text-left', sortable: true, sortDirection: 'desc' },
                { label: 'Events', key: 'received_events', class: 'text-center', sortable: true, sortDirection: 'desc' },
                { label: 'Event Sent', key: 'events_sent', class: 'text-center', sortable: true, sortDirection: 'desc' },
                { 
                    label: '%', key: 'events_sent_percent', class: 'text-center', sortable: true, sortDirection: 'desc',
                    formatter: (value, key, item) => {
                        return this.getPercentDecimal(value)
                    }
                },
                { label: 'Insufficient Balance', key: 'insufficient_balance', class: 'text-center', sortable: true, sortDirection: 'desc' },
                { 
                    label: '%', key: 'insufficient_balance_percent', class: 'text-center', sortable: true, sortDirection: 'desc',
                    formatter: (value, key, item) => {
                        return this.getPercentDecimal(value)
                    }
                },
                { label: 'Already Sent', key: 'already_sent', class: 'text-center', sortable: true, sortDirection: 'desc' },
                { 
                    label: '%', key: 'already_sent_percent', class: 'text-center', sortable: true, sortDirection: 'desc',
                    formatter: (value, key, item) => {
                        return this.getPercentDecimal(value)
                    }
                },
                { label: 'Already Tagged', key: 'contact_already_tagged', class: 'text-center', sortable: true, sortDirection: 'desc' },
                { 
                    label: '%', key: 'contact_already_tagged_percent', class: 'text-center', sortable: true, sortDirection: 'desc',
                    formatter: (value, key, item) => {
                        return this.getPercentDecimal(value)
                    }
                },
                { label: 'Daily Limit', key: 'daily_limit', class: 'text-center', sortable: true, sortDirection: 'desc' },
                { 
                    label: '%', key: 'daily_limit_percent', class: 'text-center', sortable: true, sortDirection: 'desc',
                    formatter: (value, key, item) => {
                        return this.getPercentDecimal(value)
                    }
                },
                { label: 'Time Limit', key: 'time_limit', class: 'text-center', sortable: true, sortDirection: 'desc' },
                { 
                    label: '%', key: 'time_limit_percent', class: 'text-center', sortable: true, sortDirection: 'desc',
                    formatter: (value, key, item) => {
                        return this.getPercentDecimal(value)
                    }
                },
                { label: 'Others', key: 'others', class: 'text-center', sortable: true, sortDirection: 'desc' },
                { 
                    label: '%', key: 'others_percent', class: 'text-center', sortable: true, sortDirection: 'desc',
                    formatter: (value, key, item) => {
                        return this.getPercentDecimal(value)
                    }
                },

            ];

            return columns;
        }
    },
    methods: {
        isManager() {
            this.is_manager = helper.hasRoles(['ROLE_ADMIN', 'ROLE_SUPER_ADMIN', 'ROLE_ACCOUNTS_MANAGER']);
        },
        onSelectAccount(accountSelected) {
            let account = -1;

            this.showChart = false;
            this.loadAdminStats = false;

            this.btnAddIsDisabled = false
            if (this.is_manager) {
                if (accountSelected) {
                    account = accountSelected.code;
                    this.$refs['dt-liveopens-reports'].setAccount(account)
                } else {
                    this.$refs['dt-liveopens-reports'].setAccount(account)
                }
            }
        },
        onSubmit() {
            this.submitload = true;

            // this.showChart = false;
            // this.loadAdminStats = false;
            let dates = {
                from: this.dates.from,
                to: this.dates.to
            }
            // this.$refs['dt-liveopens-reports'].setDates(date)
            // this.dates = dates;
            this.filters.from = this.dates.from;
            this.filters.to = this.dates.to;
            this.renderDT = false;

            this.$nextTick(() => {
                this.submitload = false;
                this.renderDT = true;
            });
        },
        getDateAndTime(date, i) {
            return date + ' ' + (i.toString().length > 1 ? i.toString() : '0' + i.toString()) + ':00:00';
        },
        initDates() {

            var date = helper.dateToYMD(new Date());
            var minDate = helper.dateToYMD(helper.AddOrSubractDays(new Date(), 6, false));

            this.dates.to = date;
            this.dates.from = minDate;
            this.dates.max = date;
        },
        finishedFilter() {
            this.$refs['report-filters'].finishFilter();
        },
        onFilter(form) {
            this.$refs['dt-liveopens-reports'].filterTable(form);
        },
        toggleInputsFilter(event) {
            const parentElement = event.target.parentElement;
            if (this.isFilter) $(parentElement).parents('.row').removeClass('alert-secondary');
            else $(parentElement).parents('.row').addClass('alert-secondary');
            this.isFilter = !this.isFilter
        },
        hasRoles(roles = []) {
            return !_.isEmpty(_.filter(this.user.roles, function (r) {
                return roles.includes(r.slug);
            }))
        },
        getPercentage(item, value) {
            let generatedEvents = item.received_events;
            let percent = null;

            if(parseInt(generatedEvents) !== 0) {
                percent = (parseInt(value) / generatedEvents) * 100; 
            }

            return this.getPercentDecimal(percent)
        },
        getPercentDecimal(value) {
            const decimal = value && !(value % 1) ? 0 : 3;
            return value ? parseFloat(value).toFixed(decimal) + '%' : 0;
        }
    },
    watch: {
        // "organizations": {
        //     handler(newVal, oldVal) { },
        //     deep: true
        // },
    },
    data() {
        return {
            renderDT: true,
            dates: {
                to: null,
                from: null,
            },
            filters: {
                from: null,
                to: null,
            },
            dtCells: [
            ],
            isFilter: false,
            submitload: false,

            is_manager: false,

        };
    },
};
</script>

<style>
#myTabContent-5 {
    padding-bottom: 2em !important;
    /* margin: 0 5px; */
}

.actionClass {
    width: 18%;
}

.timeClass,
.createdatClass,
.accountClass {
    width: 10%;
}

textarea.form-control {
    line-height: 25px;
}

.cursor-default {
    cursor: default;
}

.table th,
.table td {
    padding: 0.55rem;
    vertical-align: top;
}

.action {
    width: 100%;
    height: 43.5px;
}

label[for=submit] {
    visibility: hidden;
}
</style>
