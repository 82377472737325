<template>
  <b-modal ref="updateContactsInUploadedListModalRef" size="lg" id="update-contacts-in-uploaded-list-modal" title="Update Contacts from Uploaded List" hide-footer>
    <div class="p-4 pt-0"  >
      <div v-if="list">
        <div class="p-4 mb-4 d-flex justify-content-between bg-light rounded " cols="12" style="background-color: #f8f8f8 !important;">
          <h6 class="mx-2">
            <span  class="badge badge-pill badge-light" v-if="list.state === 0">Processing</span>
            <span  class="badge badge-pill badge-success" v-else-if="list.state === 1">Finish</span>
            <span  class="badge badge-pill badge-danger" v-else>Error</span>

            {{ list.list_name }}

           </h6>

          <span class="mx-2">Total contacts: {{ list.total_contacts }} </span>

        </div>
      </div>

      <!--<div >
        <div v-if="logs?.deleted_contact_lists_files" v-for="list_log in logs?.deleted_contact_lists_files" class="d-flex justify-content-between my">
          <span>
            Action delete
          </span>
          <span>{{list_log.total_contacts}}</span>
        </div>

        <div v-if="logs?.added_contact_list_files" v-for="list_log in logs?.added_contact_list_files" class="d-flex justify-content-between">
          <span>
           Action add
          </span>
          <span>{{list_log.total_contacts}}</span>
        </div>
      </div>-->

      <div>

        <div class="p-4 shadow-sm border rounded my-2"  v-for="uploaded_list in uploaded_lists">
          <div >
            <h5>{{uploaded_list.name}}</h5>
            <p class="m-0"><small>{{uploaded_list.list_id}}</small></p>
          </div>
        </div>

      </div>



      <UploadFileFormElement :accepted_type="['text/csv','application/zip']"
                             :uploadRequestFunction="api.webHookPlatform.uploadList"
                             @listUploadedEvent="handelFileUploadedEvent"
      ></UploadFileFormElement>

    </div>
    <hr>
    <div class="d-flex justify-content-end" style="gap: 5px;">
      <button name="close" @click="cancel" type="button" class="btn action-button btn-light">
        cancel
      </button>

      <button
          name="apply"
          @click="apply"
          type="button"
          class="btn action-button float-right"
          :class=" (action==='delete')  ? 'btn-danger' : 'btn-success'"
          :disabled="loading"
      >
        <b-spinner v-if="loading" small type="grow"></b-spinner>
       {{  (action==='delete') ? 'Delete Contacts' : ' Add Contacts'}}
      <span v-if="loading">...</span>
      </button>


    </div>

  </b-modal>
</template>

<script>
import UploadFileFiled from "@/components/inboxgeek/fileds/UploadFileFiled";
import UploadFileFormElement from "@/components/inboxgeek/fileds/uploadFileFieldElemets/UploadFileFormElement";
import api from "@/api";
export default {
  name: "UpdateContactsInUploadedListModal",
  props:['list','action'],
  components:{UploadFileFormElement, UploadFileFiled},
  data(){
    return{
      api:api,
      uploaded_lists:[],
      loading:false,
    }
  },
  methods:{
    handelFileUploadedEvent(response){

       response.uploaded_files[response.last_uploaded_file.index].list_id= response.response.list_id;

       this.uploaded_lists = response.uploaded_files.filter((file)=>{
         return (  typeof file.list_id !== 'undefined' );
       });
    },

    apply(){

      this.loading = true;

      api.webHookPlatform
          .updateList({
            action:this.action,
            lists:this.uploaded_lists,
            id:this.list.id
          })
          .then(response=>{
            this.loading=false;
            this.uploaded_lists = [];
            this.$refs['updateContactsInUploadedListModalRef'].hide();
          })
          .catch(error=>{
            this.loading=false;
          });
    },
    cancel(){
      this.$refs['updateContactsInUploadedListModalRef'].hide();
    }
  },
  computed:{
    logs(){
      if(this.list){
        return JSON.parse(this.list.custom_columns)
      }else{
        return null;
      }

    }
  }
}
</script>

<style scoped>

</style>