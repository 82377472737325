<template>
    <b-container fluid>

        <iq-card>
            <template v-slot:body>

                <DataTable v-if="renderDT" ref="dt-notifications" :url="'/notification_events'"
                    v-bind="{ columns: dtColumns, customCells: dtCells, organization: organization, filters: filters, sort: 'event_time' }" />
            </template>
        </iq-card>

        <b-modal id="modal-prevent-closing" ref="modal" title="Notification" :hide-footer=true>
            <h4 class="mb-2">{{ modalItem.courtesy }},</h4>
            <br>
            <p>{{ modalItem.message }}</p>
            <div v-if="modalItem.link" class="mt-4 notification-redirect">
                <b-button :href="modalItem.link" variant="primary iq-waves-effect" size="lg">
                    <span v-if='modalItem.type == "balance-alert"'>Update Your Plan!</span>
                    <span v-if='modalItem.type == "integration-alert"'>Update The Integration!</span>
                    <span v-if='modalItem.type == "checkout_session"'>Link checkout</span>
                </b-button>
            </div>
        </b-modal>
    </b-container>
</template>

<script>
import Spinner1 from '@/components/loaders/spinner1'
import store from '@/store/index'
import api from '@/api/RestClient'
import { INVOICE_STATES } from "../../../constantes";
import DataTable from "@/components/inboxgeek/tables/DataTable.vue";
import StatusSeen from '@/components/inboxgeek/tables/StateAttrCell.vue'
import StatusIsMail from '@/components/inboxgeek/tables/StateAttrCell.vue'
import MessageCell from '@/components/inboxgeek/tables/HtmlCell.vue'
import CellWithModal from '@/components/inboxgeek/tables/CellWithModal.vue'

import { helper } from '@/helpers'
import moment from 'moment'

export default {
    name: 'DtNotification',
    components: {
        Spinner1,
        DataTable
    },
    data() {
        return {
            loading: true,
            isFilter: false,
            renderDT: true,
            organization: null,
            filters: {},
            dtColumns: [
                { label: 'Title', key: 'title', class: 'text-left', sortable: true, sortDirection: 'desc' },
                { label: 'Message', key: 'message', class: 'text-left', sortable: true, sortDirection: 'desc' },
                { label: 'User', key: 'user.name', class: 'text-left', sortable: false, sortDirection: 'desc' },
                { label: 'Seen', key: 'seen', class: 'text-left', sortable: true, sortDirection: 'desc' },
                { label: 'Is Mail', key: 'is_mail', class: 'text-left', sortable: true, sortDirection: 'desc' },
                {
                    label: 'Date', key: 'event_time', class: 'text-center', sortable: true, sortDirection: 'desc',
                    formatter: (value, key, item) => {
                        let dt = new Date(value);
                        return moment(dt).format('YYYY-MM-DD HH:mm');
                    }
                },
            ],
            dtCells: [
                {
                    key: 'message',
                    component: CellWithModal,
                    props: {
                        attribute: 'message',
                    }
                },
                {
                    key: 'seen',
                    component: StatusSeen,
                    event: null,
                    props: {
                        attribute: 'seen',
                        check: { label: 'Seen' },
                        unCheck: { label: 'UnSeen' },
                    }
                },
                {
                    key: 'is_mail',
                    component: StatusIsMail,
                    event: null,
                    props: {
                        attribute: 'is_mail',
                        check: { label: 'Mail', class: 'iq-bg-success' },
                        unCheck: { label: 'No Mail', class: 'iq-bg-secondary' },
                    }
                },
            ],
            dates: null,
            $search: '',
            selected: null,
            invoice_id: null,
            options: INVOICE_STATES,
            onSubmit: false,
            modalItem: {
                title: '',
                message: ''
            }
        }
    },
    computed: {
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return {
                        text: f.label,
                        value: f.key
                    }
                })
        }
    },
    mounted() {
    },
    methods: {
        loadFields() {
            let fields = _.map(this.fields, (item) => {
                if (store.getters['Auth/isAdmin']) {
                    return item;
                } else if (!["account"].includes(item.key)) {
                    return item;
                }
                    
            })
            this.fields = fields;
        },

        rechargeInvoice(item) {
            item.state = 101;
            let data = {
                amount: item.amount,
            }
            api.invoices.pay(item.id, data).then((response) => {
                let title = 'Error';
                let variant = 'error';
                if (response.success) {
                    item.state = 1;
                    title = 'Success';
                    variant = 'success';
                } else item.state = -1;
                this.$bvToast.toast(response.message, {
                    title: title,
                    variant: variant
                });
            }).catch((err) => {
                console.log(err)
                item.state = -1;
                this.$bvToast.toast('Something went wrong!', {
                    title: 'Error',
                    variant: 'danger'
                });
            }).finally(() => { })
        },
        recharge(item) {
            this.$swal({
                title: 'Are You Sure?',
                type: 'warning',
                text: 'This action will charge the current card on file for the amount listed on this invoice.',
                showCancelButton: true,
                confirmButtonText: 'Yes, Recharge',
                cancelButtonText: 'Cancel',
                showCloseButton: true,
                showLoaderOnConfirm: true
            }).then((result) => {
                if (result.value) {
                    this.rechargeInvoice(item)
                }
            })

        },
        update(item) {
            this.selected = item.state;
            this.invoice_id = item.id;
            this.$refs['modal'].show();
        },
        handleSubmit() {
            this.onSubmit = true;
            const data = {
                status: this.selected
            }
            const id = this.invoice_id;
            api.invoices.update(id, data).then((response) => {
                this.$bvToast.toast(response.message, {
                    title: 'Success',
                    variant: 'success'
                });
                this.$root.$emit('bv::refresh::table', this.id)
            }).catch((error) => {
                console.log(error);
            }).finally(() => {
                this.onSubmit = false;
            })
        },
        statusClass(status = 1) {
            let indicator = 'iq-bg-success';
            switch (status) {
                case 0:
                    indicator = 'iq-bg-danger'
                    break;

                default:
                    break;
            }
            let color = 'badge cursor-pointer badge-secondary badge-pill '
            return color + indicator;
        },
        statusTitle(status = 1) {
            return status == 0 ? 'unread' : 'read';
        },
        show(item) {
            this.modalItem = item;
            this.$refs['modal'].show();
        },
        dateFormat(date, format = '-') {
            var d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2)
                month = '0' + month;
            if (day.length < 2)
                day = '0' + day;

            return [year, month, day].join('-');
        },
        setAccount(code) {
            this.organization = code;
            this.forceRenderTable();
        },
        forceRenderTable() {
            this.renderDT = false;

            this.$nextTick(() => {
                this.renderDT = true;
            });
        },
    },
    watch: {}
}
</script>

<style>
td.lists>div,
td.integration>div {
    width: 10em;
}

td.tag>div,
td.account>div {
    width: 8em;
}

.cursor-pointer-ek {
    cursor: pointer;
}
</style>
