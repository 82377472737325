<template>
    <b-form @submit="handleSubmit">
        <b-row class="mb-3 px-3">

            <b-form-group class="col-md-12 mb-2 px-1" label="" label-for="setting_name">
                <b-row align-h="between" class="px-3">
                    <label for="setting_type">Type: *</label>
                    <b-form-checkbox switch v-model="form.status" :disabled="form.status == -1">{{
                        form.status ? 'Active' : 'Inactive'
                    }}</b-form-checkbox>
                </b-row>

                <multiselect v-model="form.type" deselect-label="Can't remove this value" track-by="value" label="name"
                    placeholder="Select Setting Type" :options="types" :searchable="true" :allow-empty="false"
                    :disabled="type !== null" />
                <div class="text-danger" id="error_setting_type">{{ errors.setting_type }}</div>
            </b-form-group>

            <b-form-group v-if="form.type && form.type.value === 'emails'" class="col-md-12 my-2 px-1"
                label="Emails Type: *" label-for="setting_name">

                <!-- <b-form-radio-group v-model="form.name" name="setting_name" placeholder="Select Email Type"
                    :options="emailsOptions" class="mb-3" value-field="value" text-field="name"
                    disabled-field="notEnabled"></b-form-radio-group> -->

                <b-form-checkbox-group v-model="form.name" name="setting_name" placeholder="Select Email Type"
                    :options="emailsOptions" class="mb-3" value-field="value" text-field="name"
                    disabled-field="notEnabled"></b-form-checkbox-group>

                <div class="text-danger" id="error_setting_name">{{ errors.setting_name }}</div>

            </b-form-group>

            <b-form-group v-else class="col-md-12 my-2 px-1" label="Name: *" label-for="setting_name">
                <b-form-input id="setting_name" name="setting_name" placeholder="Enter Name" v-model="form.name"
                    required></b-form-input>
                <div class="text-danger" id="error_setting_name">{{ errors.setting_name }}</div>
            </b-form-group>

            <b-form-group v-if="form.type && form.type.value === 'credentials'" class="col-md-12 my-2 px-1"
                label="Add Setting Item: *" label-for="setting_credentials">
                <!-- <b-row align-h="end" class="px-3">
                    <label for="setting_type">Credentials Type: *</label>
                    <b-button class="ml-auto mb-2" variant="primary" type="button">
                        Add Item
                        <i class="ri-add-fill fa-lg mr-0"></i>
                    </b-button>
                </b-row> -->
                <b-row>
                    <b-col md="5">
                        <b-input-group class="" label="Setting Env Name:" label-for="setting_env_name">
                            <b-form-input name="setting_param_name" placeholder="Param Name" v-model="param.name"
                                size="sm"></b-form-input>
                        </b-input-group>
                    </b-col>

                    <b-col md="5">
                        <b-input-group class="" label="Setting Env Value:" label-for="setting_env_value">
                            <b-form-input name="setting_param_value" placeholder="Param Value" v-model="param.value"
                                size="sm"></b-form-input>
                        </b-input-group>
                    </b-col>
                    <b-col md="1">
                        <b-button class="my-auto text-center h-100 py-0" variant="primary" type="button" v-b-tooltip.hover
                            title="Add Setting Parameter" @click="addSettingParam">
                            <i class="ri-save-fill fa-lg mr-0"></i>
                        </b-button>
                    </b-col>

                </b-row>
                <b-row v-for="(item, index) in params" :key="index">
                    <b-col :id="`param-${item.name}`" md="12" class="my-auto py-2">
                        <span class="h-100 py-0">
                            {{ item.name }} | <span>{{ item.value }}</span>
                        </span>
                        <button class="btn btn-sm iq-bg-danger pull-right px-2 py-1" type="button"
                            @click="deleteParam($event, `${item.name}`)"> <i class="ri-delete-bin-line fa-1x mr-0"></i>
                        </button>
                    </b-col>
                </b-row>
                <div class="text-danger">{{ errors.setting_value }}</div>

            </b-form-group>

            <b-form-group v-if="form.type && form.type.value !== 'credentials'" class="col-md-12 my-2 px-1"
                label="Setting Value: *" label-for="setting_value">
                <b-input-group class="col-md-12 my-1 px-0" label="Setting Value:" label-for="setting_value">
                    <b-form-input id="setting_value" name="setting_value" placeholder="Enter Setting Value"
                        v-model="form.value" required></b-form-input>
                </b-input-group>

                <div class="text-danger" id="error_setting_type">{{ errors.setting_value }}</div>
            </b-form-group>
        </b-row>

        <b-row class="pull-right px-0 mr-1">
            <b-button ref="btn-submit" variant="primary" type="submit" :disabled="onsubmit">
                <b-spinner small type="grow" v-show="onsubmit"></b-spinner>
                {{ submitLabel }}
            </b-button>
        </b-row>

    </b-form>
</template>

<script>
import { helper } from '@/helpers'
import api from '@/api/RestClient'
import _ from 'lodash'
import Vue from 'vue'
import moment from 'moment'
import Multiselect from "vue-multiselect"


export default {
    name: 'FormAppSetting',
    props: {
        item: {
            type: [Object],
            default: null
        },
        types: {
            type: [Array],
            default: () => []
        },
        type: {
            type: [String],
            default: null
        }
    },
    components: {
        Multiselect
    },
    mounted() {
        this.setForm()
        this.setSubmitLabel()
    },
    data() {
        return {
            submitLabel: '',
            onsubmit: false,
            form: {
                code: null,
                name: null,
                type: null,
                value: null,
                status: true,
            },
            param: { name: null, value: null },
            params: [],
            emailsOptions: [
                { name: 'Support', value: 'support' },
                { name: 'Tech', value: 'tech' },
                { name: 'Administration', value: 'administration' },
                { name: 'Reports', value: 'reports' },
            ],
            formInit: {},
            errors: {},
        }
    },
    methods: {
        setForm() {
            let form = this.getForm()
            if (form) {
                this.form = form
            }

            const that = this;
            let currType = _.find(this.types, function (o) { return that.type == o.value; });
            if (currType) {
                this.form.type = currType
            }

            if (this.form.type.value === 'emails') {
                this.form.name = []
            }

            this.formInit = Vue.util.extend({}, this.form);
        },
        getForm() {
            if (!this.item || typeof this.item == "undefined") {
                return null
            }

            let form = {
                code: this.item.id,
                name: this.item.name,
                type: this.item.type,
                value: this.item.value,
                status: this.item.status == 1 ? true : false,
            }

            return form
        },
        setSubmitLabel() {
            this.submitLabel = 'Add Setting'
            if (this.item && (typeof this?.item !== 'undefined') && this.item?.id) {
                this.submitLabel = `Update Setting`
            }
        },
        emitHandleSubmit(data, isNew = true) {
            this.resetForm()
            this.$emit('handleSubmit', { ...data, new: isNew })
        },
        addNewItem() {
            let isValidForm = helper.isEmpty(this.errors)
            let data = _.omit(this.form, ['type'])
            data.type = this.form.type.value

            const that = this

            if (!isValidForm) {
                this.onsubmit = false;
                return;
            }

            // Format setting value for credentials type
            if (data.type === 'credentials') {
                let value = _.map(this.params, (param) => {
                    let item = { name: param.name, value: param.value }

                    return item
                })

                data.value = JSON.stringify(value)
            }

            api.settings.post(data)
                .then(data => {
                    if ('success' in data) {
                        this.emitHandleSubmit(data.data, true)
                    } else {
                        that.handleError(data)
                    }
                })
                .catch(errors => {
                    that.handleError(errors)
                })
                .finally(() => {
                    this.onsubmit = false;
                })
        },
        updateItem() {
            let isValidForm = helper.isEmpty(this.errors)
            const id = this.form.code
            let data = this.form
            const that = this

            if (!isValidForm) {
                this.onsubmit = false;
                return;
            }

            // Replace bu api settings
            return;

            api.settings.put(id, data)
                .then(data => {
                    if ('success' in data) {
                        this.emitHandleSubmit(data.data, false)
                    } else {
                        that.handleError(data)
                    }
                })
                .catch(errors => {
                    that.handleError(errors)
                })
                .finally(() => {
                    this.onsubmit = false;
                })
        },
        addSettingParam(e) {
            this.resetErrors();

            let param = Vue.util.extend({}, this.param);

            if (param.name && param.value) {
                this.params.push(param)
                this.param.name = null
                this.param.value = null
            } else {
                // Error case
                this.errors.setting_value = 'Enter name and value of the param to added.';
            }
        },
        resetErrors() {
            this.errors = {}
        },
        checkForm() {
            this.resetErrors();

            let nameLabel = 'Name';

            if (typeof this.form.name === 'string' && (!this.form.name || !this.form.name.trim())) {
                this.errors.setting_name = nameLabel + ' is required.';
            }

            if (typeof this.form.name === 'object' && (!this.form.name.length)) {
                this.errors.setting_name = nameLabel + ' is required.';
            }

            if ((!this.form.value || !this.form.value.trim()) && (this.form.type.value !== 'credentials')) {
                this.errors.setting_value = 'Value is required.';
            }

            if (this.form.type && this.form.type.value.trim() === 'emails') {
                nameLabel = 'Emails Type';
            }

            if (
                this.form.type
                && !this.form.type.value.trim() === 'emails'
                && (typeof this.form.name === 'string' && !this.emailTypeOptions.includes(this.form.name))
            ) {
                this.errors.setting_name = 'Please select email type.';
            }

            if (!this.form.type) {
                this.errors.setting_type = 'Type is required.';
            }

            if (this.form.type && this.form.type.value.trim() === 'emails' && !helper.validMail(this.form.value.trim())) {
                this.errors.setting_value = 'Email valid is required.';
            }

            // Customs checking - [credentials]
            if (this.form.type.value.trim() === 'credentials' && !this.params.length) {
                this.errors.setting_value = 'Please Add Setting Parameter(s).';
            }


            // if (this.form.type.value.trim() === 'credentials' && (!this.param.name || !this.param.name.trim())) {
            //     this.errors.setting_value = 'Param name is required.';
            // }
            // if (this.form.type.value.trim() === 'credentials' && !this.param.value || !this.param.value.trim()) {
            //     this.errors.setting_value = 'Param value is required.';
            // }
        },
        formatErrorResponse(errors) {
            if (errors.response) {
                errors = errors.response.data.errors
            } else if (errors.request) {
                errors = errors.request
            } else {
                errors = errors.message;
            }
            return errors
        },
        handleSubmit(e) {
            e.preventDefault()

            this.onsubmit = true;
            this.checkForm();

            if (helper.isEmpty(this.errors)) {
                if (this.form.code) {
                    this.updateItem()
                } else {
                    this.addNewItem()
                }
            } else {
                // Can emit error
                this.onsubmit = false;
            }
        },
        handleError(errors) {
            this.resetErrors()
            const that = this

            errors = this.formatErrorResponse(errors)
            // Make loop
            _.forOwn(errors, function (value, key) {
                that.errors[`setting_${key}`] = errors[key][0]
            });

            this.$emit('handleSubmit', null)
        },
        checkAppSetting() {
            this.onsubmit = true;
            this.errors.setting_value = null

            const id = this.form.code ?? null
            const value = this.form.value ?? null

            // api.settings.checkCodeAvailable(id, value)
            //     .catch(errors => {
            //         this.errors.setting_value = 'Discount code is already taken'
            //     })
            //     .finally(() => {
            //         this.onsubmit = false;
            //     })
        },
        resetForm() {
            this.form = {
                code: null,
                name: null,
                type: null,
                value: null,
                status: true,
            }
        },
        deleteParam(e, ref) {
            document.getElementById(`param-${ref}`).remove()
            _.remove(this.params, function (param) {
                return param.name === ref;
            });
        }
    },
    computed: {
        isSameInit() {
            return _.isEqual(this.form, this.formInit);
        },
        emailTypeOptions() {
            let options = _.map(this.emailsOptions, (option) => {
                return option.value
            })
            return options
        }
    },
    watch: {
        "form": {
            handler(newVal, oldVal) {
                if (!this.isSameInit) {
                    this.$refs['btn-submit'].disabled = false
                } else {
                    this.$refs['btn-submit'].disabled = true
                }
            },
            deep: true
        },
        "form.setting_value": {
            handler(newValue, oldValue) {
                this.checkAppSetting();
            }
        }
    },
}
</script>

<style lang="scss" scoped>
#setting_type {
    div.custom-control {
        padding-right: 20px !important
    }
}

label.d-block {
    margin-right: 0 !important
}
</style>></style>