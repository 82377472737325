import moment from 'moment';
import 'moment-timezone';
import {APP_TIMEZONE} from "@/constantes";


async function fetchServerTimeZone() { 
    const response = await fetch('/api/timezone'); 

    // Adjust the endpoint as necessary 
    if (!response.ok) { 
        throw new Error('Failed to fetch server time zone'); 
    } 

    const data = await response.json(); return data.timezone;
} 

async function configureMomentTimezone() {
    try {
        // To use timezone from Backend
        // const serverTimeZone = await fetchServerTimeZone();
        
        moment.tz.setDefault(APP_TIMEZONE);
    } catch (error) {
        console.error('Error setting up moment-timezone:', error);
    }
} 

export default configureMomentTimezone;