<template>
    <b-container fluid>


        <!-- User Interface controls -->
        <b-row class="mb-3">

            <b-col lg="4" class="my-1">
                <b-input-group size="md">
                    <b-form-input id="filter-input" v-model="filter" type="search" placeholder="Search"></b-form-input>
                </b-input-group>
            </b-col>

        </b-row>

        <!-- Main table element -->
        <b-table :ref="id" :id="id" striped bordered :busy="isBusy" :items="getData" :fields="fields"
            :current-page="currentPage" :per-page="perPage" :filter="filter" :filter-included-fields="filterOn"
            :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" stacked="md" show-empty small
            @filtered="onFiltered" @sort-changed="sortingChanged">

            <template #table-busy>
                <div class="text-center d-inline-block my-2">
                    <span class='fa-stack fa-lg'>
                        <i class='fa fa-spinner fa-spin fa-stack-2x fa-fw'></i>
                    </span>&emsp;Processing ...
                </div>
            </template>

            <template #empty="scope">
                <p class="mb-1">No matching records found</p>
            </template>

            <template v-for="(item, index) in customCells" v-slot:[`#cell(${item.key})`]="{ row }">
                <span v-if="item.render" :v-html="item.render(row.value, index, row)"></span>
            </template>

            <!-- Cell type -->
            <!-- <template #cell(type)="row">
                <div class="badge"
                    :class="{ 'badge-info': row.item.type == 'amount', 'badge-primary': row.item.type == 'percentage' }">
                    {{ row.item.type }}
                </div>
            </template> -->

            <!-- Cell usage_limit -->
            <template #cell(usage_limit)="row">
                <div
                    :class="{ 'badge badge-secondary': row.item.usage_limit == -1, 'badge badge-danger': row.item.usage_limit == 0 }">
                    {{ row.item.usage_limit == -1 ? 'Unlimited' : row.item.usage_limit }}
                </div>
            </template>

            <!-- Cell status -->
            <template #cell(status)="row">
                <span class="badge cursor-pointer badge-secondary badge-pill"
                    :class="{ 'iq-bg-success': row.item.status > 0, 'iq-bg-danger': row.item.status == -1, 'iq-bg-secondary': row.item.status == 0 }">
                    {{ row.item.status == -1 ? 'Delete' : (row.item.status > 0 ? 'Active' : 'Inactive') }}
                </span>
            </template>

            <!-- Actions -->
            <template #cell(action)="row">
                <div class="text-center d-flex user-flex">
                    <button v-if="row.item.discount_code" type="button" class="btn iq-bg-info mr-1 px-2 btn-sm"
                        :id="`btn-copy-${row.item.id}`" :ref="`btn-copy-${row.item.id}`" v-b-tooltip v-b-tooltip.hover
                        title="Copy Discount Code" @click="copyDiscountCode($event, row.item)">
                        <i class="ri-file-copy-2-fill"></i>
                    </button>
                    <button v-if="row.item.status == -1" type="button" class="btn iq-bg-success mr-1 px-2 btn-sm"
                        data-toggle="tooltip" data-placement="top" title="Restore" data-original-title="Test"
                        @click="restore(row.item)">
                        <i class="ri-restart-line"></i>
                    </button>
                    <button v-if="row.item.status != -1" type="button" class="btn iq-bg-primary mr-1 px-2 btn-sm"
                        data-toggle="tooltip" data-placement="top" title="Edit" data-original-title="Test"
                        @click="edit(row.item)">
                        <i class="fa fa-edit mx-0" v-show="true"></i>
                    </button>
                    <button v-if="row.item.status !== -1" type="button" class="btn iq-bg-danger mr-1 px-2 btn-sm"
                        data-toggle="tooltip" data-placement="top" title="Delete" data-original-title="Test"
                        @click="remove(row.item)">
                        <i class="fa fa-trash mx-0" v-show="true"></i>
                    </button>
                </div>
            </template>

        </b-table>

        <!-- DataTable pagination -->
        <b-row class="justify-content-between mt-3">
            <b-col md="9" class="my-1">
                <div class="dataTables_info">
                    <b-row class="dataTables_info justify-content-between" id="lists_info" role="status" aria-live="polite">
                        <b-col md="10" class="">
                            Showing {{ from }}
                            to {{ to }} of {{ totalRows }} entries
                        </b-col>
                        <b-col md="2" class="">
                            <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm">
                            </b-form-select>
                        </b-col>
                    </b-row>
                </div>
            </b-col>
            <b-col md="3" class="my-1">
                <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm"
                    class="my-0"></b-pagination>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import Spinner1 from '@/components/loaders/spinner1'
import store from '@/store/index'
import api from '@/api/RestClient'
import { helper } from '@/helpers'

export default {
    name: 'DataTable',
    components: {
        Spinner1,
    },
    props: {
        id: {
            type: [String],
            default: 'dt-table'
        },
        columns: {
            type: [Array],
            default: () => [{ key: 'Name' }]
        },
        callback: {
            type: [Function],
            default: () => null
        },
        customCells: {
            type: [Array],
            default: () => []
        },
    },
    data() {
        return {
            isBusy: false,
            items: [],
            fields: [],
            totalRows: 1,
            currentPage: 1,
            perPage: 5,
            pageOptions: [5, 10, 25, 50, 100, {
                value: 250,
                text: "Show a lot"
            }],
            sortBy: 'created_at',
            sortDesc: true,
            sortDirection: 'asc',
            filter: null,
            filterOn: ["name"],
            account: -1,
            dates: null,
            $search: '',
            from: null,
            to: null,
        }
    },
    computed: {
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return {
                        text: f.label,
                        value: f.key
                    }
                })
        }
    },
    beforeMount() {
        console.log(this.customCells);
        if (Array.isArray(this.columns)) {
            this.fields = this.columns
        }
    },
    mounted() {
    },
    methods: {
        async getData(ctx) {
            await this.delay();
            return await this.fetchData(ctx, this.callback);
        },
        async fetchData(ctx, callback) {

            var items = []
            this.items = [];
            this.isBusy = true;
            let queryParams = {}
            if (typeof ctx !== "undefined") {
                queryParams = {
                    search: this.filter,
                    page: ctx.currentPage,
                    per_page: ctx.perPage,
                    sort_by: ctx.sortBy,
                    sort_desc: ctx.sortDesc,
                }
            }

            return api.discounts.list(queryParams)
                .then((response) => {
                    items = response.data.data;
                    this.totalRows = response.data.total
                    this.currentPage = response.data.current_page
                    this.perPage = response.data.per_page
                    this.items = items
                    this.from = response.data.from
                    this.to = response.data.to
                    return items
                })
                .catch((err) => {
                    console.log(err)
                    return []
                })
                .finally(() => {
                    setTimeout(() => {
                        this.isBusy = false;
                        this.$root.$emit('updatedTable');
                    }, 400);
                })
        },
        delay: () => {
            return new Promise(resolve => setTimeout(resolve, 2000));
        },
        onFiltered(filteredItems) {
        },
        sortingChanged(ctx) {
            this.currentPage = 1
        },
        restore(item) {
            this.$swal({
                title: 'Are you sure?',
                text: 'You can\'t revert your action',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes Delete it!',
                cancelButtonText: 'No, Keep it!',
                showCloseButton: true,
                showLoaderOnConfirm: true
            }).then((result) => {
                const id = item.id;

                if (result.value && parseInt(id) > 0) {

                    api.discounts.restore(id)
                        .then(response => {
                            let varian = response.success ? 'success' : 'danger';
                            this.$bvToast.toast('Discount Restore', varian, response.message);
                            if (response.success) this.$root.$emit('bv::refresh::table', this.id);
                        })
                        .catch(error => {
                            let errors = helper.formatErrorResponse(error)
                            console.log(errors)
                            this.$bvToast.toast('message', {
                                title: 'Error',
                                variant: 'Something went wrong!'
                            })
                        })
                }
            })
        },
        copyDiscountCode(e, item) {

            if (!item || !item.discount_code) return

            navigator.clipboard.writeText(item.discount_code)

            this.$bvToast.toast('Discount Code Copied', {
                title: 'Copy Discount Code',
                variant: 'info'
            })
        },
        edit(item) {
            this.$emit('handleEdit', item)
        },
        remove(item) {
            this.$swal({
                title: 'Are you sure?',
                text: 'You can\'t revert your action',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes Delete it!',
                cancelButtonText: 'No, Keep it!',
                showCloseButton: true,
                showLoaderOnConfirm: true
            }).then((result) => {
                if (result.value) {
                    api.discounts.delete(item.id).then(response => {
                        let varian = response.success ? 'success' : 'danger';
                        this.toast('Delete user', varian, response.message);
                        if (response.success) this.$root.$emit('bv::refresh::table', this.id);
                    })
                } else {
                    this.$swal('Cancelled', 'Your file is still intact', 'info')
                }
            })
        },
        toast(title = "success", variant = "success", message = "Discount added") {
            this.$bvToast.toast(message, {
                title: title,
                variant: variant
            })
        },
    },
    watch: {}
}
</script>

<style>
td.lists>div,
td.integration>div {
    width: 10em;
}

td.tag>div,
td.account>div {
    width: 8em;
}

.user-flex {
    justify-content: center;
}
</style>
