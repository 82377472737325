import Vue from 'vue';
import VueAnalytics from 'vue-analytics';
import router from "@/router";

Vue.use(VueAnalytics, {
  id: process.env.GOOGLE_ANALYTICS_ID,
  router,
  debug: {
    enabled: process.env.NODE_ENV !== 'production',
    trace: process.env.NODE_ENV !== 'production',
    sendHitTask: process.env.NODE_ENV === 'production',
  },
});
