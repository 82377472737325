export default function (axios) {
  return {
    list: (params = {}) => {
      return axios.get(`/chat_questions`, { params })
        .then(response => response.data);
    },
    get: (id) => {
      return axios.get(`/chat_questions/${id}`)
        .then(response => response.data);
    },
    post: (data) => {
      return axios.post(`/chat_questions`, data)
        .then(response => response.data)
    },
    put: (id, data) => {
      return axios.put(`/chat_questions/${id}`, data)
        .then(response => response.data)
    },
    delete: (id) => {
      return axios.delete(`/chat_questions/${id}`)
        .then(response => response.data);
    },
    restore: (id) => {
      return axios.post(`/chat_questions/${id}/restore`, {})
        .then(response => response.data);
    },
    getQuestions: (search = null) => {
      let url = `/chat_questions/get-questions`;
      if(search) { 
        url += `?search=${encodeURIComponent(search)}`;
      }
      return axios.get(url, {})
        .then(response => response.data);
    },
    getQuestionChildren: (questionId, chatRoomId = null) => {
      let url = `/chat_questions/${questionId}/children`;
      if(chatRoomId) { 
        url += `/${chatRoomId}`;
      }
      return axios.get(url, {})
        .then(response => response.data);
    },
  }
}
