<template>
    <b-container fluid>

        <!-- Table title -->
        <b-row class="mb-3">
            <b-col lg="6">
                <h4 class="card-title float-start">Revenue</h4>
            </b-col>
        </b-row>

        <!-- User Interface controls -->
        <b-row class="mb-3">

            <b-col lg="4" class="my-1">
                <b-input-group size="md">
                    <b-form-input id="filter-input" v-model="filter" type="search" placeholder="Search"></b-form-input>
                </b-input-group>
            </b-col>

        </b-row>

        <!-- Main table element -->
        <b-table class="ig-text-wrap" ref="table" :id="id" striped bordered :responsive="$store.getters['Auth/isAdmin']"
            :busy="isBusy" :items="getData" :fields="fields" :current-page="currentPage" :per-page="perPage"
            :filter="filter" :filter-included-fields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection" stacked="md" show-empty small @filtered="onFiltered"
            @sort-changed="sortingChanged">

            <template #table-busy>
                <div class="text-center d-inline-block my-2">
                    <span class='fa-stack fa-lg'>
                        <i class='fa fa-spinner fa-spin fa-stack-2x fa-fw'></i>
                    </span>&emsp;Processing ...
                </div>
            </template>

            <template #empty="scope">
                <p class="mb-1">No matching records found</p>
            </template>

        </b-table>

        <!-- Data table pagination -->
        <b-row class="justify-content-between mt-3">
            <b-col md="9" class="my-1">
                <div class="dataTables_info">
                    <b-row class="dataTables_info justify-content-between" id="lists_info" role="status" aria-live="polite">
                        <b-col md="10" class="">
                            Showing {{ from }}
                            to {{ to }} of {{ totalRows }} entries
                        </b-col>
                        <b-col md="2" class="">
                            <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm">
                            </b-form-select>
                        </b-col>
                    </b-row>
                </div>
            </b-col>
            <b-col md="3" class="my-1">
                <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm"
                    class="my-0"></b-pagination>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import Spinner1 from '@/components/loaders/spinner1'
import store from '@/store/index'
import api from '@/api/RestClient'
import { helper } from '@/helpers'
import {
    mapGetters
} from "vuex";


export default {
    name: 'DtAccountManagement',
    components: {
        Spinner1,
    },
    props: {
        dates: {
            type: Object,
            default: () => {
                return {from: null, to: null}
            }
        },
    },
    data() {
        return {
            id: 'dt-revenue-reports',
            isBusy: false,
            items: [],
            fields: [
                {
                    key: 'date',
                    label: 'Date',
                    sortable: true,
                    sortDirection: 'desc',
                    formatter: (value, key, item) => {
                        var dt = new Date(value);
                        return helper.formatDate(dt)
                    },
                },
                {
                    key: 'account_name',
                    label: 'Customer',
                    sortable: true,
                    sortDirection: 'desc',
                    class: 'text-center',
                },
                {
                    key: 'plan_name',
                    label: 'Product',
                    sortable: false,
                    class: 'text-center',
                },
                {
                    key: 'revenue',
                    label: 'Total',
                    sortable: true,
                    class: 'text-right',
                    formatter: (value, key, item) => {
                        return this.moneyDefaultFormat(value)
                    }
                },
                {
                    key: 'tax_gst',
                    label: 'Tax GST',
                    sortable: true,
                    class: 'text-right',
                     formatter: (value, key, item) => {
                        return this.moneyDefaultFormat(value);
                    }
                },
                {
                    key: 'tax_qst',
                    label: 'Tax QST',
                    sortable: true,
                    class: 'text-right',
                     formatter: (value, key, item) => {
                        return this.moneyDefaultFormat(value);
                    }
                },
                 {
                    key: 'refund_value',
                    label: 'Refund',
                    sortable: true,
                    class: 'text-center',
                    formatter: (value, key, item) => {
                        return this.moneyDefaultFormat(value);
                    }
                },
                {
                    key: 'overconsumption',
                    label: 'Overconsumption fee',
                    sortable: true,
                    class: 'text-right',
                     formatter: (value, key, item) => {
                        return this.moneyDefaultFormat(value);
                    }
                },
                {
                    key: 'consumption',
                    label: 'Total Events',
                    sortable: true,
                    class: 'text-center',
                    formatter: (value, key, item) => {
                        return this.formatDecimal(parseFloat(value));
                    }
                },
            ],
            totalRows: 1,
            currentPage: 1,
            perPage: 100,
            pageOptions: [100, 200, 500, {
                value: 10000,
                text: "Show a lot"
            }],
            sortBy: 'id',
            sortDesc: true,
            sortDirection: 'asc',
            filter: null,
            filterOn: ["name"],
            accounts: [],
            plans: [],
            from: null,
            to: null,
            $search: '',
            reportDays: null,
            groupByProduct: false,
        }
    },
    computed: {
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return {
                        text: f.label,
                        value: f.key
                    }
                })
        },
        ...mapGetters('Auth', ['user']),
        ...mapGetters('Auth', ['isAdmin'])
    },
    mounted() {
        this.loadFields()
        if (!this.isAdmin) this.account = this.user.account_id;
    },
    methods: {
        formatDecimal(nStr) {
            nStr += '';
            var x = nStr.split('.');
            var x1 = x[0];
            var x2 = x.length > 1 ? '.' + x[1] : '';
            var rgx = /(\d+)(\d{3})/;
            while (rgx.test(x1)) {
                x1 = x1.replace(rgx, '$1' + ',' + '$2');
            }
            return x1 + x2;
        },
        moneyDefaultFormat(amount) {
            return amount ? amount.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : 0;
        },
        setGroupByProduct(status) {
            this.groupByProduct = status;
        },
        sumInArrayByKey(items = [], key = 'events') {
            let sum = 0;
            items.forEach(item => {
                sum += item[key]
            });
            return sum;
        },
        eventCalculStats(array, key) {
            return this.sumInArrayByKey(array, key);
        },
        stringInArrayByKey(items = [], key = 'list_name') {
            let name = '';
            let i = 0;
            const length = items.length;
            items.forEach(item => {
                i++;
                name += i != length ? item[key] + ', ' : item[key];

            });
            return name;
        },
        formatDate(date) {
            var d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2)
                month = '0' + month;
            if (day.length < 2)
                day = '0' + day;

            return [year, month, day].join('-');
        },
        async getData(ctx) {
            await this.delay();
            return await this.fetchData(ctx);
        },
        async fetchData(ctx) {
            var items = []
            this.items = [];
            this.isBusy = true;
            let queryParams = {}
            if (typeof ctx !== "undefined") {
                queryParams = {
                    search: this.filter,
                    page: ctx.currentPage,
                    per_page: ctx.perPage,
                    sort_by: ctx.sortBy,
                    sort_desc: ctx.sortDesc,
                    filter_on: JSON.stringify(this.filterOn),
                    from: this.dates.from,
                    to: this.dates.to,
                    accounts: this.accounts.length ? JSON.stringify(this.accounts) : -1,
                    plans: this.plans.length  ? JSON.stringify(this.plans) : -1,
                    $search: this.search,
                    groupByProduct: this.groupByProduct
                }
            }

            return api.revenues.list(queryParams)
                .then((response) => {

                    items = response.data.data;
                    const reportDays = response.data.days;
                    this.totalRows = response.data.total
                    this.currentPage = response.data.current_page
                    this.perPage = response.data.per_page
                    if (this.groupByProduct == true) {
                        this.items.length = 0
                       items = _(items)
                            .groupBy('plan_id')
                            .map((rev, id) => ({
                                id: id,
                                account_name: rev[0].account_name,
                                date: rev[0].date,
                                plan_name: rev[0].plan_name,
                                revenue: _.sumBy(rev, 'revenue'),
                                overconsumption: _.sumBy(rev, 'overconsumption'),
                                consumption: _.sumBy(rev, 'consumption')
                            }))
                            .value()
                        this.totalRows = items.length;
                    }
                    this.items = items
                    this.reportDays = reportDays != 0 ? reportDays : 1;
                    this.from = response.data.from
                    this.to = response.data.to
                    this.$emit('onFetchData', items);
                    return items
                    // return []
                })
                .catch((err) => {
                    console.log(err)
                    return []
                })
                .finally(() => {
                    setTimeout(() => {
                        this.isBusy = false;
                        this.$root.$emit('updatedTable');
                    }, 400);
                })

        },
        delay: () => {
            return new Promise(resolve => setTimeout(resolve, 2000));
        },
        loadFields() {
            let fields = _.map(this.fields, (item) => {
                if (store.getters['Auth/isAdmin']) {
                    return item;
                } else
                    if (![
                        "account_id",
                        "billing_date",
                        "events_cycle",
                        "current_plan",
                        "time_restrictions",
                        "created_by",
                        "monthly_events",
                        "list_quality"
                    ].includes(item.key)) return item;
            })
            this.fields = fields;
        },
        setAccounts(accounts) {
            this.accounts = accounts
            this.currentPage = 1
            this.$root.$emit('bv::refresh::table', this.id)// eric
        },
        setPlans(plans) {
            this.plans = plans
            this.currentPage = 1
            this.$root.$emit('bv::refresh::table', this.id)// eric
        },
        setDates(dates) {
            this.dates = dates
            this.currentPage = 1
            this.$root.$emit('bv::refresh::table', this.id)
        },

        // Filter
        onFiltered(filteredItems) {
        },
        sortingChanged(ctx) {
            this.currentPage = 1
        },
    },
    watch: {}
}
</script>

<style>
td.lists>div,
td.integration>div {
    width: 16em;
}

td.tag>div,
td.account>div {
    width: 14em;
}

.ig-text-wrap {
    word-wrap: break-word;
}
</style>
