<template>
    <b-container fluid>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">Affiliates</h4>
                    </template>

                    <template v-slot:body>
                        <b-row>
                            <b-col lg="12">
                                <DtReferrals ref="dt-referrals" @handleEdit="handleEdit" />
                            </b-col>
                        </b-row>
                    </template>
                </iq-card>
            </b-col>
        </b-row>

        <!-- Start Add Referral Modal-->
        <b-modal :id="modal.id" :ref="modal.id" :title="modal.title" size="md" hide-footer
            class="bg-secondary py-0">
        <AddOrEditReferral :options="discounts" :referral="item" :referralId="id" @onSaved="savedReferral"></AddOrEditReferral>
        </b-modal>
        <!-- END Edit Referral Modal-->
    </b-container>
</template>

    
<script>
import { sofbox } from "@/config/pluginInit";
import { mapGetters } from 'vuex'
import DtReferrals from './children/DtReferrals';
import AddOrEditReferral from './children/forms';
import Spinner1 from '@/components/loaders/spinner1'
import _ from 'lodash'
import api from '@/api/RestClient'

export default {
    name: "ReferralList", 
    components: {
        Spinner1,
        DtReferrals,
        AddOrEditReferral
    },
    created() {
        this.isAdmin = this.admin;
        this.modal = this.addModal;
    },
    mounted() {
        sofbox.index();
        this.discount();
    },
    data() {
        return {
            isAdmin: false,
            addModal: {
                id: 'modal-add-referrals',
                title: 'Add Referral',
                item: {}
            },
            editModal: {
                id: 'modal-edit-referrals',
                title: 'Edit Referral',
                item: {}
            },
            modal: {},
            discounts: [],
            item: {
                name: '',
                status: 0,
                discount_id: ''
            },
            id: null
        };
    },
    computed: {
        ...mapGetters({
            admin: 'Auth/isAdmin',
            client: 'Auth/isClient',
        })
    },
    methods: {
        closeModal(item) {
            this.$root.$emit('bv::refresh::table', this.$refs['dt-referrals'].id)
        },
        handleAdd(event) {
            this.addModal.title = `Add New Referral`
            this.modal = this.addModal;
            this.item = {
                name: '',
                status: 0,
                discount_id: null,
            }
            this.$root.$emit('bv::show::modal', this.addModal.id)
        },
        handleEdit(item) {
            if (!item) {
                return
            }
            this.editModal.title = `Edit Referral [${item?.name}]`
            this.editModal.item = item
            this.modal = this.editModal;
            this.item = {
                name: item.name,
                status: item.status,
                discount_id: item.discount.id
            }
            this.id = item.id;
            this.$root.$emit('bv::show::modal', this.modal.id)
        },
        discount() {
            api.discounts.list({per_page: 100}).then(response => { 
                let discounts = response.data.data;
                discounts = _.map(discounts, function (discount) {
                    return {
                        value: discount.id,
                        text: discount.name
                    }
                });

                this.discounts = discounts;
            })
        },
        savedReferral(status) {
            console.log(status);
            let variant = status ? 'success' : 'error';
            let message = status ? 'Referral created successfully' : 'An error occurred';
            let title = status ? 'Success' : 'Error';
            this.$bvToast.toast(message, {
                title: title,
                variant: variant
            });
            this.$root.$emit('bv::refresh::table', this.$refs['dt-referrals'].id)
            if(this.$refs['modal-add-referrals']) this.$refs['modal-add-referrals'].hide();
            if (this.$refs['modal-edit-referrals']) this.$refs['modal-edit-referrals'].hide();
        }
    },
    watch: {},
};
</script>

    
<style>
.actionClass {
    width: 18%;
}

.timeClass,
.createdatClass,
.accountClass {
    width: 10%;
}

textarea.form-control {
    line-height: 25px;
}

.cursor-default {
    cursor: default;
}

.table th,
.table td {
    padding: 0.55rem;
    vertical-align: top;
}

.action {
    width: 100%;
    height: 43.5px;
}
</style>
