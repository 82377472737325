<template>
  <iq-card class="p-2">
    <template v-slot:headerTitle>
      <h4 class="card-title">
        <i class=""></i> Support Chat
      </h4>
    </template>

    <template v-slot:headerAction>
      
      <div class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color mx-3">

        <label class="my-auto mr-2" for="support-chat-setting-status">Display PopUp Chat</label>

        <div class="custom-switch-inner my-auto pt-1">
          <input type="checkbox" data-toggle="tooltip" data-placement="top" title="Active Status"
            id="support-chat-setting-status" name="support-chat-setting-status" data-id="support-chat-setting-status"
            data-name="support-chat-setting-status" v-model="isEnabled"
            @change="updateChatSetting" class="custom-control-input bg-primary">

          <label for="support-chat-setting-status" data-on-label="ON" data-off-label="OFF" class="custom-control-label"></label>

        </div>
      </div>
      
    </template>

    <template v-slot:body>

      <div class="platform-config-container p-2">
        <div class="row">
          <b-col><label for="">Working Hours</label></b-col>
          <b-col class="col input-group" md="3">

            <div class="col">
              <label for="time-from" class="my-auto mx-3">From</label>
              <span class="my-auto mx-3">{{ from ?? '--:--' }}</span>
            </div>

            <b-form-input class="col form-control mx-1" name="fromH" type="number" v-model="hours.fromH" min="00"
              max="23" @change="formatterTime($event, 'fromH')" style="margin: 0 1px !important;"></b-form-input>

            <b-form-input class="col form-control mx-1" name="fromM" type="number" v-model="hours.fromM" min="00"
              max="59" @change="formatterTime($event, 'fromM')" style="margin: 0 1px !important;"></b-form-input>

            <small v-if="errors.from" class="col col-md-12 text-danger">"From" Time must be valid</small>
            <small v-if="errors.fromEmpty" class="col col-md-12 text-danger">"From" Time can't to be empty</small>

          </b-col>

          <b-col class="col input-group" md="3">

            <div class="col">
              <label for="time-to" class="my-auto mx-3">To</label>
              <span class="my-auto mx-3">{{ to ?? '--:--' }}</span>
            </div>

            <b-form-input class="form-control mx-1" name="toH" type="number" v-model="hours.toH" min="00" max="23"
              @change="formatterTime($event, 'toH')" style="margin: 0 1px !important;"></b-form-input>

            <b-form-input class="form-control mx-1" name="toM" type="number" v-model="hours.toM" min="00" max="59"
              @change="formatterTime($event, 'toM')" style="margin: 0 1px !important;"></b-form-input>

            <small v-if="errors.to" class="col col-md-12 text-danger">"To" Time must be valid</small>
            <small v-if="errors.toEmpty" class="col col-md-12 text-danger">"To" Time can't to be empty</small>

          </b-col>
        </div>

      </div>

    </template>

    <div class="row justify-content-end">
      <div class="col p-2 px-3">
        <button class="btn btn-secondary float-right mx-2" @click.prevent="resetTime" :disabled="isUpdating">
          Reset Time
        </button>

        <button class="btn btn-primary float-right" @click="saveChanges" :disabled="isUpdating">
          <b-spinner small type="grow" v-show="isUpdating"></b-spinner>
          Save
        </button>
      </div>
    </div>
  </iq-card>
</template>


<script>

import api from "@/api";

export default {
  name: "SupportChatConfigurationSettings",
  props: {},
  data() {
    return {
      platformConfigs: [],
      filterConfigValue: null,
      isEnabled: false,
      from: null,
      to: null,
      hours: {
        fromH: null,
        fromM: null,
        toH: null,
        toM: null,
      },
      isUpdating: false,
      errors: {
        from: false,
        to: false,
        fromEmpty: false,
        toEmpty: false,
      }
    }
  },
  mounted() {

    // api.settings.getSettingStatus('Support_chat').then(response=>{
    api.default.get('settings/support_chat').then((response) => {

      const data = response.data;

      if (data.state.status === 1) {
        this.isEnabled = true;
      }

      if (data.time.value) {

        let time = JSON.parse(data.time.value);

        if (time.from) {
          this.from = time.from;
          const FROM = time.from.split(':');

          this.hours.fromH = FROM[0];
          this.hours.fromM = FROM[1];

        }

        if (time.to) {
          this.to = time.to;
          const TO = time.to.split(':');

          this.hours.toH = TO[0];
          this.hours.toM = TO[1];

        }
      }

    });
  },
  methods: {
    updateChatSetting() {

      if(this.timeIsValid()) {
      
        let settings = {
          status: this.isEnabled,
          time: { from: this.from, to: this.to }
        }

        this.isUpdating = true;

        api.default.put('settings/support_chat', settings)
          .then((response) => {
            this.$bvToast.toast(response.message, {
              variant: 'success',
              title: 'Updated'
            });
          }).finally(() => {
            this.isUpdating = false;
          })

      }
    },
    saveChanges() {

      this.updateChatSetting();

    },
    resetTime() {

      this.hours = {
        fromH: null,
        fromM: null,
        toH: null,
        toM: null,
      };
    },
    formatterTime(value, key) {
      if (value && parseInt(value) < 10) {
        value = `0${value}`;
        this.hours[key] = value
      }

      return value;
    },
    isValidTime24HrFormat(time) {
      const regex = /^([01]?[0-9]|2[0-3]):([0-5][0-9])$/;
      return regex.test(time);
    },
    timeIsValid() {
      this.errors = { from: false, to: false, fromEmpty: false, toEmpty: false };
      
      if(this.from && !this.isValidTime24HrFormat(this.from)) {
        this.errors.from = true;
      }
      if(this.to && !this.isValidTime24HrFormat(this.to)) {
        this.errors.to = true;
      }

      if(this.from && !this.to) {
        this.errors.toEmpty = true;
      }

      if(this.to && !this.from) {
        this.errors.fromEmpty = true;
      }

      return !this.errors.from && !this.errors.to && !this.errors.fromEmpty && !this.errors.toEmpty
    }
  },
  computed: {
  },
  watch: {
      'hours': {
          handler(newValue, oldValue) {

              this.from = newValue.fromH && newValue.fromM ? `${newValue.fromH}:${newValue.fromM}` : null;
              this.to = newValue.toH && newValue.toM ? `${newValue.toH}:${newValue.toM}` : null;

              this.timeIsValid();
          },
          deep: true
      },
  },
}


</script>


<style scoped>
.platform-config-container {
  max-height: 300px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.platform-config-row:hover {
  background: #92a7cf17 !important;
  border-radius: 0.5rem;
}
</style>