<template>
    <b-container fluid>
        <b-row>
            <b-col md="12">
                <iq-card class="">
                    <template v-slot:headerTitle>
                        <b-row class="mt-4">
                            <b-col md="6">
                                <b-form-group label="Date" label-for="date">
                                    <b-form-input type="month" v-model="date" :max="dateMax"></b-form-input>
                                </b-form-group>
                            </b-col>
                            <!-- <b-col md="4">
                                <b-form-group label="To" label-for="to">
                                    <b-form-input id="to" type="month" :min="dates.from" v-model="dates.to"></b-form-input>
                                </b-form-group>
                            </b-col> -->
                            <b-col md="4">
                                <b-form-group label="To" label-for="submit">
                                    <b-button class="action" variant="primary" :disabled="submitLoad" @click="onSubmit">
                                        <b-spinner small type="grow" v-if="submitLoad"></b-spinner>
                                        Submit
                                    </b-button>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col ld="12">
                                <b-form-checkbox v-model="showFreePlan" name="show-free" switch>
                                    Show Free Plan?
                                </b-form-checkbox>
                            </b-col>
                        </b-row>
                    </template>
                    <template v-if="this.isAdmin" v-slot:headerAction>
                        <b-form class="mx-3 mt-3">
                            <AccountsMultiselect @onSelect="onSelectAccounts" />
                        </b-form>
                    </template>
                </iq-card>
                <iq-card>

                    <template v-slot:body>
                        <b-row>
                            <b-col lg="12">

                                <!-- rowSelect: [] -->
                                <DataTable v-if="renderDT" ref="dt-subscriptions-summary" :url="'/subscriptions_summary'"
                                    v-bind="{
                                        columns: columns, customCells: dtCells, filters: filters, organization: accountId,
                                        theadTop: [
                                            { colspan: 1, variant: null, srOnly: true, title: 'Account' },
                                            { colspan: 5, variant: 'danger', srOnly: false, title: 'LiveOpens' },
                                            { colspan: 4, variant: 'primary', srOnly: false, title: 'LiveLeads' },
                                        ]
                                    }" />
                            </b-col>
                        </b-row>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import {
    sofbox
} from "@/config/pluginInit";

import DataTable from '@/components/inboxgeek/tables/DataTable.vue'

import AccountsMultiselect from "./children/AccountsMultiselect.vue";
import PlansMultiselect from "./children/PlansMultiselect.vue";
import DtRevenues from "./children/DtRevenues.vue";

import Spinner1 from '@/components/loaders/spinner1'
import TestFlow from '@/components/inboxgeek/sendflow'
import _ from 'lodash'

import {
    mapGetters
} from 'vuex'
import api from '@/api/RestClient'
import Ibgmorris from '../../components/inboxgeek/IbgMorris'
import ApexCharts from "../LiveReports/children/ApexCharts.vue"

import {
    helper
} from '@/helpers'
import store from '@/store/index'
import { SERVICES } from "@/constantes";

var moment = require('moment');

export default {
    name: "SubscriptionsSummary",
    components: {
        DataTable,
        DtRevenues,
        Spinner1,
        TestFlow,
        Ibgmorris,
        ApexCharts,
        PlansMultiselect,
        AccountsMultiselect,
    },
    mounted() {
        sofbox.index();
        this.initDates();
    },
    computed: {
        ...mapGetters({
            isAdmin: 'Auth/isAdmin',
            isAccountManager: 'Auth/isAccountManager',
            user: 'Auth/user'
        }),
        columns() {
            let columns = [
                { label: 'Organization', key: 'account_name', class: 'text-left', sortable: false, sortDirection: 'desc' },
                { 
                    label: 'Event Used', key: 'events_sent', class: 'text-center', sortable: false, sortDirection: 'desc',
                    formatter: (value, key, item) => {
                        let loSubscriptionSummary = _.find(item.subscription_summaries, (o) => { return o.service_id == SERVICES.LIVE_OPENS.id });
                        let loServiceBalance = _.find(item.balances, (o) => { return o.service_id == SERVICES.LIVE_OPENS.id });

                        let plan = this.getItemPlan(item, SERVICES.LIVE_OPENS.id);
                        
                        if(!loSubscriptionSummary) {

                            return loServiceBalance && plan ? loServiceBalance.consumption : '--';

                        }

                        return loSubscriptionSummary && plan ? loSubscriptionSummary.consumption : '--';
                    }
                },
                {
                    label: 'Event Balance Remaining', key: 'lo_balances', class: 'text-center', sortable: false, sortDirection: 'desc',
                    formatter: (value, key, item) => {
                        let loSubscriptionSummary = _.find(item.subscription_summaries, (o) => { return o.service_id == SERVICES.LIVE_OPENS.id });
                        let loServiceBalance = _.find(item.balances, (o) => { return o.service_id == SERVICES.LIVE_OPENS.id });

                        let plan = this.getItemPlan(item, SERVICES.LIVE_OPENS.id);

                        if(!loSubscriptionSummary) {

                            return loServiceBalance && plan ? loServiceBalance.balance : '--';
                                
                        }

                        return loSubscriptionSummary && plan ? loSubscriptionSummary.balance : '--';
                    }
                },
                {
                    label: 'Overconsumption Balance ', key: 'overconsumption_balance', class: 'text-center', sortable: false, sortDirection: 'desc',
                    formatter: (value, key, item) => {
                        let loSubscriptionSummary = _.find(item.subscription_summaries, (o) => { return o.service_id == SERVICES.LIVE_OPENS.id });
                        let loServiceBalance = _.find(item.balances, (o) => { return o.service_id == SERVICES.LIVE_OPENS.id });

                        let plan = this.getItemPlan(item, SERVICES.LIVE_OPENS.id);

                        if(!loSubscriptionSummary) {

                            return loServiceBalance && plan ? (loServiceBalance.balance < 0 ? loServiceBalance.balance : '--' ) : '--';

                        }
                        
                        return loSubscriptionSummary && plan ? (loSubscriptionSummary.balance < 0 ? loSubscriptionSummary.balance : '--' ) : '--';
                    }
                },
                {
                    label: 'LO Billing Date', key: 'lo_billing_date', class: 'text-center', sortable: true, sortDirection: 'desc',
                    formatter: (value, key, item) => {

                        let subscriptionSummary = _.find(item.subscription_summaries, (o) => {
                            return o.service_id == SERVICES.LIVE_OPENS.id
                        });

                        let loServiceBalance = _.find(item.balances, (o) => { return o.service_id == SERVICES.LIVE_OPENS.id });

                        let plan = this.getItemPlan(item, SERVICES.LIVE_OPENS.id);

                        if(!subscriptionSummary) {

                            return loServiceBalance && plan ? helper.formatDate( new Date(loServiceBalance.ends_at) ) : '--';

                        }

                        return subscriptionSummary && plan ? helper.formatDate( new Date(subscriptionSummary.billing_date) ) : '--';
                    }
                },
                {
                    label: 'LiveOpens Plan', key: 'current_lo_sub', class: 'text-center', sortable: true, sortDirection: 'desc',
                    formatter: (value, key, item) => {

                        let plan = this.getItemPlan(item, SERVICES.LIVE_OPENS.id);
                        
                        return plan ? plan?.name : '--';
                    }
                },
                { 
                    label: 'Records Captured', key: 'records', class: 'text-center', sortable: false, sortDirection: 'desc',
                    formatter: (value, key, item) => {
                        let llSubscriptionSummary = _.find(item.subscription_summaries, (o) => { return o.service_id == SERVICES.LIVE_LEADS.id });
                        let llServiceBalance = _.find(item.balances, (o) => { return o.service_id == SERVICES.LIVE_LEADS.id });

                        let plan = this.getItemPlan(item, SERVICES.LIVE_LEADS.id);

                        if(!llSubscriptionSummary) {
                            
                            return llServiceBalance && plan ? llServiceBalance.consumption : '--';

                        }
                        
                        return llSubscriptionSummary && plan ? llSubscriptionSummary.consumption : '--';

                    }
                },
                {
                    label: 'Records Balance Remaining', key: 'll_balances', class: 'text-center', sortable: false, sortDirection: 'desc',
                    formatter: (value, key, item) => {
                        let llSubscriptionSummary = _.find(item.subscription_summaries, (o) => { return o.service_id == SERVICES.LIVE_LEADS.id });
                        let llServiceBalance = _.find(item.balances, (o) => { return o.service_id == SERVICES.LIVE_LEADS.id });

                        let plan = this.getItemPlan(item, SERVICES.LIVE_LEADS.id);

                        if(!llSubscriptionSummary) {

                            return llServiceBalance && plan ? llServiceBalance.balance : '--';

                        }

                        return llSubscriptionSummary && plan ? llSubscriptionSummary.balance : '--';

                    }
                },
                {
                    label: 'LL Billing Date', key: 'll_billing_date', class: 'text-center', sortable: true, sortDirection: 'desc',
                    formatter: (value, key, item) => {

                        let subscriptionSummary = _.find(item.subscription_summaries, (o) => {
                            return o.service_id == SERVICES.LIVE_LEADS.id
                        });

                        let llServiceBalance = _.find(item.balances, (o) => { return o.service_id == SERVICES.LIVE_LEADS.id });

                        let plan = this.getItemPlan(item, SERVICES.LIVE_LEADS.id);

                        if(!subscriptionSummary) {
                            
                            return llServiceBalance && plan ? helper.formatDate( new Date(llServiceBalance.ends_at) ) : '--';

                        }

                        return subscriptionSummary && plan ? helper.formatDate( new Date(subscriptionSummary.billing_date) ) : '--';
                    }
                },
                {
                    label: 'LiveLeads Plan', key: 'current_ll_sub', class: 'text-center', sortable: true, sortDirection: 'desc',
                    formatter: (value, key, item) => {

                        let plan = this.getItemPlan(item, SERVICES.LIVE_LEADS.id);

                        return plan ? plan?.name : '--';
                    }
                }
            ];


            return columns;
        },
        dateIsCurrentMonth() {
            if(this.date) return this.date == moment().format('YYYY-MM');

            return false;
        }
    },
    methods: {
        onSelectAccounts(accountSelected) {

            if (this.isAdmin) {
                if (accountSelected) {
                    this.filters.accounts = [];
                    accountSelected.forEach(account => {
                        this.filters.accounts.push(account.code);
                    });
                } else {
                    accountSelected = [];
                }
            }

            this.forceRenderTable();

        },
        onSubmit() {
            this.submitLoad = true;

            this.forceRenderTable();
        },
        forceRenderTable() {
            this.renderDT = false;
            this.submitLoad = false;

            this.$nextTick(() => {
                this.renderDT = true;
            });
        },
        initDates() {

            let date = helper.dateToYMD(new Date());
            date = moment(date).format('YYYY-MM');
            this.date = date;
            // this.dates.to = date;
            // this.dates.from = date;
            this.dateMax = date;

        },
        getItemPlan(item, serviceId) {
            let subscriptionSummary = _.find(item.subscription_summaries, (o) => {
                return o.service_id == serviceId
            });
            let currentSubscription = _.find(item.subscriptions, (o) => {
                return o.service_id == serviceId
            });

            return subscriptionSummary && subscriptionSummary?.subscription && subscriptionSummary.subscription?.plan
                ? subscriptionSummary.subscription?.plan 
                : (currentSubscription && currentSubscription?.plan ? currentSubscription.plan : null);
        }
    },
    watch: {
        "date": {
            handler(newVal, oldVal) {
                this.filters.date = newVal;
            },
            deep: true
        },
        "showFreePlan": {
            handler(newVal, oldVal) {
                this.filters.showFree = newVal;
            },
            deep: true
        },
    },
    data() {
        return {
            renderDT: true,
            accountId: null,
            submitLoad: false,
            dtCells: [
            ],
            filters: {
                showFree: false
            },
            item: {},
            date: null,
            dateMax: null,
            dates: {
                to: null,
                from: null,
            },
            showFreePlan: false,
        };
    },
};
</script>

<style>
#myTabContent-5 {
    padding-bottom: 2em !important;
    /* margin: 0 5px; */
}

.actionClass {
    width: 18%;
}

.timeClass,
.createdatClass,
.accountClass {
    width: 10%;
}

textarea.form-control {
    line-height: 25px;
}

.cursor-default {
    cursor: default;
}

.table th,
.table td {
    padding: 0.55rem;
    vertical-align: top;
}

.action {
    width: 100%;
    height: 43.5px;
}

label[for=submit] {
    visibility: hidden;
}</style>
