<template>
<b-container fluid>

    <b-col sm="12">
        <iq-card class="p-2">

            <template v-slot:headerTitle>
                <h4 class="card-title">Plans</h4>

            </template>
            <template v-slot:headerAction>
                <services @onSelect="filterTable"></services>
                <b-button v-if="isAdmin" :disabled="addIsDisable" v-b-modal.modal-new-plan variant="primary" @click="initPlanFormModal" class="text-right_ mr-1">Add </b-button>
            </template>
            <template v-slot:body>
                <!-- START FILTER OPTIONS ROW  -->
                <b-row class="my-4" align-h="between">

                    <!-- START Sow Option Input -->
                    <b-col md="2">
                        <b-form-group label-for="per-page-select" label-align-sm="left" class="mb-0">
                            <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions"></b-form-select>
                        </b-form-group>
                    </b-col>
                    <!-- END Sow Option Input -->

                    <!-- START Filter Input -->
                    <b-col md="4">
                        <b-form-group class="mb-0">

                            <b-input-group>

                                <b-form-input id="filter-input" v-model="filter" type="search" placeholder="Type to Search"></b-form-input>

                            </b-input-group>

                        </b-form-group>

                    </b-col>
                    <!-- END Filter Input -->

                </b-row>
                <!-- END FILTER OPTIONS ROW  -->

                <b-table borderless hover :items="plans" :fields="fields" :current-page="currentPage" :per-page="perPage" :filter="filter" :filter-included-fields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" :busy="loading.table" stacked="md" show-empty @filtered="onFiltered">

                    <template #table-busy>
                        <div class="text-center p-4 d-flex justify-content-center align-items-center">
                            <b-spinner variant="primary"> </b-spinner>
                            <span class="text-primary p-2"><small> Fetching Plans, Please wait...</small></span>
                        </div>
                    </template>

                    <template #cell(service)="row">
                        <span v-if="row.item.service" class="badge cursor-pointer badge-primary badge-pill iq-bg-primary">
                            {{ row.item.service.name || '' }}
                        </span>

                    </template>

                    <template #cell(parent_plan)="row">
                        <span v-if="row.item.parent" class="badge cursor-pointer badge-secondary badge-pill iq-bg-secondary">
                            {{ row.item.parent.name  || '' }}
                        </span>

                    </template>

                    <template #cell(status)="row">

                        <span v-if="row.item.active  == 1" class="badge cursor-pointer badge-secondary badge-pill iq-bg-success">Active</span>
                        <span v-else class="badge cursor-pointer badge-secondary badge-pill iq-bg-danger">Inactive</span>

                    </template>
                    
                    <template #cell(is_visible)="row">

                        <IBGSwitchCell 
                            v-bind="{row: row, attribute: 'is_visible', label: row.item.is_visible == 1 ? 'Visible' : 'Hidden'}" 
                            @onSwitch="handleSwitchVisible"></IBGSwitchCell>

                    </template>

                    <template #cell(actions)="row">

                        <b-button variant=" iq-bg-info mr-1 mb-1" size="sm" @click="edit(row.item)"><i class="ri-ball-pen-fill m-0"></i></b-button>
                        <b-button variant=" iq-bg-danger" size="sm" @click="destroy(row.item)"><i class="ri-delete-bin-line m-0"></i></b-button>

                    </template>

                </b-table>

                <!-- START PAGINATION TABLE-->
                <b-row align-h="end">

                    <b-col md="3" class="my-1">
                        <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm" class="my-0"></b-pagination>
                    </b-col>
                </b-row>
                <!-- END PAGINATION TABLE-->

            </template>

        </iq-card>

    </b-col>

    <!-- START CREATE/UPDATE PLAN MODAL -->

    <b-row>
        <b-col lg="12" md="12">

            <b-modal id="modal-new-plan" ref="plan" class="p-2" hide-footer :title="modalTitle">
                <b-form @submit="onSubmit" class="p-2">

                    <b-row>
                        <b-col lg="6" md="6">
                            <b-form-group>
                                <div class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color">
                                    <div class="custom-switch-inner">
                                        <label class="mr-2">Status</label>
                                        <input type="checkbox" class="custom-control-input bg-primary" v-model="form.active" id="customSwitch-13">
                                        <label class="custom-control-label" for="customSwitch-13" data-on-label="On" data-off-label="Off">
                                        </label>
                                    </div>
                                </div>
                            </b-form-group>
                        </b-col>
                    </b-row>

                    <b-form-group id="input-group-1" label="Name:" label-for="input-1" description="">
                        <b-form-input id="input-1" v-model="form.name" type="text" placeholder="plan name" required></b-form-input>
                    </b-form-group>

                    <b-form-group id="input-group-2" label="Interval:" label-for="input-2" description="">

                        <multiselect ref="interval-ref" tag-placeholder="Interval" placeholder="Interval" v-model="form.interval" :options="intervals" :multiple="false">
                        </multiselect>

                        </b-form-group>


                        <b-form-group id="input-group-2" label="Service:" label-for="input-2" description="">

                            <multiselect  placeholder="Choose service" :taggable="true" v-model="service" :options="services" label="name" track-by="code" :multiple="false" @input="onServiceSelected">
                            </multiselect>

                        </b-form-group>


                  <b-form-group id="input-group-2" label="Default options:" label-for="input-2" description="">

                    <multiselect ref="default-options-ref" tag-placeholder="Default options" placeholder="Default options" v-model="form.options" :options="plan_default_options" :multiple="true">
                    </multiselect>

                  </b-form-group>

                    <b-form-group id="input-group-4" label="Initial Balance:" label-for="input-4" description="">
                        <b-form-input id="input-1" v-model="form.balance" type="text" placeholder="Initial balance" required></b-form-input>
                    </b-form-group>

                    <b-row>
                        <b-col lg="6" md="6">
                            <b-form-group id="input-group-3" label="Initial price:" label-for="input-3" description="">
                                <b-form-input id="input-1" v-model="form.price" type="text" placeholder="Initial price" required></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col lg="6" md="6">
                            <b-form-group id="input-group-3" label="price/unit:" label-for="input-3" description="">
                                <b-form-input id="input-1" v-model="form.unit_price" type="text" placeholder="Unit/price"></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>

                    <b-button variant="primary" type="submit" :disabled="loader">
                        Submit
                        <b-spinner small type="grow" v-show="loader"></b-spinner>
                    </b-button>
                </b-form>
            </b-modal>

        </b-col>
    </b-row>

    <!-- END CREATE/UPDATE PLAN MODAL -->

</b-container>
</template>

<script>
import {
    sofbox
} from '../../config/pluginInit'
import Spinner1 from '../../components/loaders/spinner1'
import DataTablePlan from '../../components/inboxgeek/Datatables'
import services from './children/ServicesMultiselect'
import Multiselect from 'vue-multiselect'
import api from '@/api/RestClient';
import { mapGetters } from 'vuex'
import IBGSwitchCell from '@/components/inboxgeek/tables/IBGSwitchCell.vue'
import {SERVICES} from "@/constantes";

export default {
    name: 'LogKw',
    components: {
        Spinner1,
        services,
        Multiselect,
        DataTablePlan,
        IBGSwitchCell
    },
    async mounted() {
      sofbox.index()
        this.fetchOptionVariables();

        window.parent = this;
        this.fetchPlans();
        this.fetchServices();
        this.loadFields();



    },

    data() {
        return {
          existed_options:[],
          loading: {
                modal: false,
                table: false,
            },

            loader: false,
            headerTitle: 'Plan list',
            notOrderable: [],
            plan_id: null,
            add_new_plan: false,
            columnsTable: null,
            plans: [],
            intervals: [
                "monthly"
            ],
            services: [],
            service: {
              code : 1,
              name : 'LiveOpens'
            },
            form: {
                "name": null,
                "id": null,
                "active": false,
                "stripe_plan": null,
                "parent_id": null,
                "price": null,
                "balance": null,
                "unit_price": null,
                "interval": "month",
                "service_id": this.service ? this.service.code : SERVICES.LIVE_OPENS.id
            },

            table: null,

            // Table data : 
            fields: [{
                    key: 'id',
                    label: '#',
                    sortable: true,
                    sortDirection: 'desc'
                },
                {
                    key: 'service',
                    label: 'Service',
                    sortable: true,
                    class: 'text-center'
                },
                {
                    key: 'name',
                    label: 'Name',
                    sortable: true,
                    class: 'text-left'
                },
                {
                    key: 'parent_plan',
                    label: 'Parent Plan',
                    sortable: true,
                    class: 'text-center'
                },
                {
                    key: 'interval',
                    label: 'Interval',
                    sortable: true,
                    class: 'text-center'
                },
                {
                    key: 'balance',
                    label: 'Balance',
                    sortable: true,
                    class: 'text-center'
                },
                {
                    key: 'price',
                    label: 'Price',
                    sortable: true,
                    class: 'text-center'
                },
                {
                    key: 'unit_price',
                    label: 'Price/Unit',
                    sortable: true,
                    class: 'text-center'
                },
                {
                    key: 'status',
                    label: 'Status',
                    sortable: true,
                    class: 'text-center'
                },
                {
                    key: 'is_visible',
                    label: 'Visible',
                    sortable: true,
                    class: 'text-center'
                },
                {
                key: 'actions',
                label: 'Actions',
                class: 'text-center'
            }
               
            ],

            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 15, {
                value: 100,
                text: "Show a lot"
            }],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            addIsDisable: true
        }

    },
    methods: {
      initPlanFormModal(){
        this.form = {
          "name": null,
              "id": null,
              "active": false,
              "stripe_plan": null,
              "parent_id": null,
              "price": null,
              "balance": null,
              "unit_price": null,
              "interval": "month",
              "service_id": this.service ? this.service.code : SERVICES.LIVE_OPENS.id,
              "options" : []
        };

        let current_options = [];
        Object.keys(this.existed_options[this.service.code]).forEach(key => {
          console.log(this.existed_options[this.service.code][key],this.existed_options[this.service.code])
          if(this.existed_options[this.service.code][key]){
            current_options.push(key);
          }
        });

        this.form.options = current_options;

        this.add_new_plan = true;
      },
        edit(plan) {
            this.add_new_plan = false;
            this.add_new_plan = false;
            this.plan_id = plan.id;
            let parent_id = null;

            if (plan.parent) parent_id = plan.parent.id;
          let current_options = [];

          if(plan.options) {

            let options = JSON.parse(plan.options)

            Object.keys(options).forEach(key => {
              if(options[key]){
                current_options.push(key);
              }
            });

            }

            this.form = {
                "name": plan.name,
                "id": plan.id,
                "active": plan.active,
                "price": plan.price,
                "parent_id": parent_id,
                "interval": plan.interval,
                "balance": plan.balance,
                "unit_price": plan.unit_price,
                "service_id": plan.service_id,
                "options" : current_options
            };
            this.service = {
                code: plan.service.id,
                name: plan.service.name
            }
            this.$refs['plan'].show()

        },
        loadFields() {
            let fields = _.map(this.fields, (item) => {
                if (this.isAdmin) {
                    return item;
                } else
                    if (!["actions"].includes(item.key)) return item;
            })
            this.fields = fields;
        },
        onSubmit(e) {

            e.preventDefault();

            this.loader = true;
            const planId = this.plan_id;
            const data = this.form;

            if (this.add_new_plan) {

                api.plans.post(data).then(response => {

                    this.loader = false;
                    let variant = 'danger';
                    let message = 'Something went wrong'

                    if ('success' in response) {
                        variant = response.success ? 'success' : 'danger';
                        message = response.message;
                        this.toast('plan', variant, message);
                        if (response.success) {

                            this.fetchPlans();
                            this.$refs['plan'].hide();

                        }
                    }

                }).catch(err => {
                    this.$bvToast.toast(err.response.data.message, {
                        title: 'Error',
                        variant: 'danger',
                    });
                })

            } else {

                api.plans.put(planId, data).then(response => {
                    this.loader = false;
                    let variant = 'danger';
                    let message = 'Something went wrong'

                    if ('success' in response) {
                        variant = response.success ? 'success' : 'danger';
                        message = response.message;
                        this.toast('plan', variant, message);
                        if (response.success) {

                            this.fetchPlans();

                            this.$refs['plan'].hide();
                        }
                    }
                }).catch(err => {
                    this.$bvToast.toast(err.response.data.message, {
                        title: 'Error',
                        variant: 'danger',
                    });
                })

            }

        },
        destroy(plan) {
            this.$swal({
                title: 'Are you sure?',
                text: 'You can\'t revert your action',
                type: 'danger',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                showCloseButton: true,
                showLoaderOnConfirm: true
            }).then((result) => {

                if (result.value) {

                    api.plans.delete(plan.id).then(response => {
                        let varian = response.success ? 'success' : 'danger';
                        this.toast('Delete user', varian, response.message);
                        if (response.success) {
                            this.fetchPlans();
                        }
                    }).catch(err => {
                        this.$bvToast.toast(err.response.data.message, {
                            title: 'Error',
                            variant: 'danger',
                        });
                    })
                }
            })
        },
        handleSwitchVisible(data) {
            // console.log( 'handleSwitchVisible', data ); return;

            let itemCode = data.row.id
            let form = {}
            form[data.key] = data.input.checked ? 1 : 0

            let toast = { message: 'Something went wrong!', title: 'Error', variant: 'danger' }

            return api.plans.put(itemCode, form)
                .then(result => {
                    data.input.checked = result.data[data.key];
                    toast = { message: result?.message, title: 'Success', variant: 'success' }
                })
                .catch(err => {
                    this.$bvToast.toast(err.response.data.message, {
                        title: 'Error',
                        variant: 'danger',
                    });
                })
                .finally(() => {
                    this.$root.$bvToast.toast(toast.message, toast);
                });
        },
        setNotOrderableColumns() {
            let n = this.columns.length
            for (let index = 0; index < n; index++) {
                if (index != this.filter) this.notOrderable.push(index)
            }
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        fetchPlans() {

            this.loading.table = true;

            api.plans.list().then((response) => {

                this.loading.table = false;
                this.plans = response.data.data.map((account) => {

                    return account;
                });

                this.totalRows = this.plans.length;

            }).catch(err => {
                this.$bvToast.toast(err.response.data.message, {
                    title: 'Error',
                    variant: 'danger',
                });
            });

        },
        toast(title = "success", variant = "success", message = "Organization updated successfully") {
            this.$bvToast.toast(message, {
                title: title,
                variant: variant
            })
        },
        onServiceSelected() {
            
            this.form.service_id = this.service.code;
        },
        fetchServices() {
            var services = [];
            api.services.list()
                .then(response => {
                    response.data.forEach((serv) => {
                      console.log()
                        let service = {};
                        service.name = serv.name;
                        service.code = serv.id;
                        services.push(service);
                    });
                })
                .catch(err => {
                    this.$bvToast.toast(err.response.data.message, {
                        title: 'Error',
                        variant: 'danger',
                    });
                })
                .finally(() => {
                    setTimeout(() => {
                        this.services = services;
                        let service = this.services.find(service => service.code === 1);
                        //console.log(service);
                        //this.service = service;
                        this.isLoading = false;
                    }, 1000)
                })
        },
        filterTable(data) {
            
            if (data) {
                this.filter = data.name;

                this.service = {
                    code: data.code,
                    name: data.name
                }


                this.form.service_id = data.code;

                this.addIsDisable = false
            } else {
                this.filter = '';
                this.addIsDisable = true
            }
        },
      fetchOptionVariables(){
        api.subscriptions.fetchOptionVariables().then((response)=>{

          this.existed_options = response.data;
        })
    },
    },
    computed: {
    ...mapGetters({
      isAdmin: 'Auth/isAdmin',
      isClient: 'Auth/isClient',
      isAccounting: 'Auth/isAccounting',
      user: 'Auth/user',
      liveOpenersSubscribed: 'Auth/liveOpenersSubscribed',
      liveLeadsSubscribed: 'Auth/liveLeadsSubscribed',
    }),
      plan_default_options(){

      if(this.existed_options[this.service.code]){


let current_options = [];
        Object.keys(this.existed_options[this.service.code]).forEach(key => {

          console.log(key);


          current_options.push(key);

        });

        return current_options;
      }

      return [];
      },
      modalTitle(){
        return this.add_new_plan ? 'Create a new plan' : 'update an Existing plan';
      }
  },
}

</script>

<style>
.dataTables_filter>label {
    width: 50% !important;
    text-align: left;
}

.dataTables_filter {
    text-align: right;
}

ul.pagination {
    justify-content: end;
    ;
}
</style>
