<template>
    <b-container fluid>
        <PaymentMethodAlert v-if="renderServiceCards" @openModal="openModal"></PaymentMethodAlert>
        <b-row v-if="false">
            <WelcomeWidget></WelcomeWidget>
        </b-row>
        <b-row v-if="renderServiceCards">
            <b-col lg="12" ref="service-liveopens">
                <ServiceConsumptionWidget v-if="!$store.getters['Auth/isAdmin']" :service_id="1"
                    service_name="LiveOpens">
                </ServiceConsumptionWidget>
            </b-col>
            <b-col lg="12" ref="service-liveleads">
                <ServiceConsumptionWidget v-if="!$store.getters['Auth/isAdmin']" :service_id="2"
                    service_name="LiveLeads">
                </ServiceConsumptionWidget>
            </b-col>
        </b-row>

        <b-row>
            <div class="col-lg-7">
                <div class="iq-card">
                    <div class="iq-card-header d-flex justify-content-between">
                        <div class="iq-header-title">
                            <h4 class="card-title">Payment Methods</h4>
                        </div>
                    </div>
                    <div class="iq-card-body">
                        <div class="acc-edit">
                            <div id="table">
                                <span class="table-add float-right mb-3 mr-2">
                                    <button @click='editPaymentMethod = null; openModal()' type="button"
                                        class="btn mb-3 btn-success"><i class="fa fa-plus"></i>Add
                                        New</button>
                                </span>
                                <table class="table table-responsive-md text-center billing-card">
                                    <thead>
                                        <tr>
                                            <th>Card</th>
                                            <th>Expires</th>
                                            <th>Name on card</th>
                                            <th>Card Nickname</th>
                                            <th>Billing Address</th>
                                            <th>Default</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(method, index) in paymentsMethod">
                                            <td>{{ method.brand }}</td>
                                            <td>{{ method.exp_month }}/{{ method.exp_year }}</td>
                                            <td>{{ method.name }}</td>
                                            <td>{{ method.card_nickname }}</td>
                                            <td>{{ method.billing_details.line1 }}</td>
                                            <td>
                                                <div
                                                    class="custom-control custom-switch custom-switch-text custom-control-inline default">
                                                    <div class="custom-switch-inner">
                                                        <input :id="`checkbox-${index}`" type="checkbox"
                                                            class="custom-control-input" v-model='method.default'
                                                            v-bind:key="method.id" :disabled='method.default'
                                                            @change='changeDefault($event, method.id)'>
                                                        <label :for="`checkbox-${index}`" class="custom-control-label"
                                                            data-on-label="Yes" data-off-label="No">
                                                        </label>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <span class="table-remove">
                                                    <button v-if="!method.default"
                                                        @click='DeletePaymentMethod(method.id, index)' type="button"
                                                        class="btn btn-danger btn-sm mb-3 mx-1">
                                                        <i
                                                            class="ri-delete-bin-2-fill mx-auto delete-payment-method"></i>
                                                    </button>
                                                </span>

                                                <span class="table-update">
                                                    <button @click="updatePaymentMethod(method)" type="button"
                                                        class="btn btn-info btn-sm mb-3 mx-1">
                                                        <i class="ri-edit-box-line mx-auto update-payment-method"></i>
                                                    </button>
                                                </span>
                                            </td>
                                        </tr>
                                        <tr v-if="!paymentsMethod.length">
                                            <td colspan="7">No saved payment methods</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>


                <b-modal id="payment-method" size="lg">
                    <template #modal-title>{{ editPaymentMethod ? 'Update' : 'Add' }} Payment Method</template>

                    <b-container>
                        <div class="success text-center" v-if='success'>
                            <i class="fas fa-check-circle fa-8x text-success"></i>
                            <h4>GREAT</h4>
                            <h5>Payment method successfully added</h5>
                        </div>

                        <div class="alert alert-danger" v-if='fails' role="alert" v-for="error in errors">
                            <div class="iq-alert-text">• {{ error }}</div> <br>
                        </div>

                        <div v-if='!success'>
                            <div class="modal-header require-PM" v-if='!paymentsMethod.length'>
                                <h5 class="modal-title text-danger" id="exampleModalScrollableTitle">You do not have
                                    a
                                    credit card set
                                    up yet</h5>
                            </div>
                            <form onsubmit="required()">
                                <div class="row mt-3">
                                    <div class="col-md-6">
                                        <h5 class="name-card">Name on card :</h5>
                                    </div>
                                </div>
                                <div class="row mt-3">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label for="fname">First Name:<span class="text-danger">*</span></label>
                                            <input type="text" class="form-control" required="required"
                                                v-model='address.first_name'>

                                            <small ref="b-errors-first_name" class="text-danger small d-none">{{
                                                billingErrors.first_name }}</small>

                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label for="fname">Last Name:<span class="text-danger">*</span></label>
                                            <input type="text" class="form-control" required="required"
                                                v-model='address.last_name'>

                                            <small ref="b-errors-last_name" class="text-danger small d-none">{{
                                                billingErrors.last_name }}</small>

                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-3">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label for="fname">Street Address:<span class="text-danger">*</span></label>
                                            <textarea name="address" class="form-control" style="width: 100%;"
                                                v-model='address.address' required="required"></textarea>

                                            <small ref="b-errors-line1" class="text-danger small d-none">{{
                                                billingErrors.line1 }}</small>

                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-1">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label for="fname">Country:<span class="text-danger">*</span></label>
                                            <multiselect v-model="address.country"
                                                deselect-label="Can't remove this value" track-by="code" label="name"
                                                placeholder="Select Country" :options="countries" :searchable="true"
                                                :allow-empty="false" :loading="loading" required="required"
                                                @input="updateBillingCountry">
                                            </multiselect>

                                            <small ref="b-errors-country" class="text-danger small d-none">{{
                                                billingErrors.country }}</small>

                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label for="fname">City:<span class="text-danger">*</span></label>
                                            <input type="text" class="form-control" required="required"
                                                v-model='address.city'>
                                            <small ref="b-errors-city" class="text-danger small d-none">{{
                                                billingErrors.city }}</small>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-1" v-if="is_taxable">
                                    <div class="col-md-6">
                                        <b-form-checkbox name="check-button" v-model="has_gst_tax" switch size="sm">
                                            is exempt GST tax?
                                            <label data-on-label="On" data-off-label="Off"></label>
                                        </b-form-checkbox>

                                        <div class="form-group" v-show="has_gst_tax">
                                            <label for="fname">GST Num:<span class="text-danger">*</span></label>
                                            <input type="text" class="form-control" placeholder="GST tax num"
                                                required="required" v-model='gst_tax_num'>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <b-form-checkbox name="check-button" v-model="has_qst_tax" switch size="sm">
                                            is exempt QST tax?
                                            <label data-on-label="On" data-off-label="Off"></label>
                                        </b-form-checkbox>

                                        <div class="form-group" v-show="has_qst_tax">
                                            <label for="fname">QST Num:<span class="text-danger">*</span></label>
                                            <input type="text" class="form-control" placeholder="QST tax num"
                                                required="required" v-model='qst_tax_num'>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-1">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label for="fname">State:<span class="text-danger">*</span></label>
                                            <input type="text" class="form-control" required="required"
                                                v-model='address.state'>

                                            <small ref="b-errors-state" class="text-danger small d-none">{{
                                                billingErrors.state }}</small>

                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label for="fname">ZIP or Postal Code:<span
                                                    class="text-danger">*</span></label>
                                            <input type="text" class="form-control" required="required"
                                                v-model='address.postal_code'>

                                            <small ref="b-errors-postal_code" class="text-danger small d-none">{{
                                                billingErrors.postal_code }}</small>

                                        </div>

                                    </div>
                                    <div class="col-md-12 mt-3">
                                        <div class="form-group">
                                            <label for="nickname">Nickname:<span class="text-danger">*</span></label>
                                            <input type="text" class="form-control" required="required"
                                                v-model='nickname' placeholder="Add Nickname">
                                            <small ref="b-errors-nickname" class="text-danger small d-none">{{
                                                billingErrors.nickname }}</small>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <div v-show="!editPaymentMethod" class="row mt-3">
                                <div class="col-md-12">
                                    <label for="fname">Card:<span class="text-danger">*</span></label>
                                    <div id="card"></div>
                                </div>
                            </div>
                            <br>

                        </div>
                        <b-row v-if='!success' class="mt-3">
                            <div class="col-12 text-danger text-center mb-4">
                                **InboxGeek is a Canada-based company that may require you to enable international
                                credit card usage when purchasing our services. Learn more <a
                                    href="https://help.inboxgeek.com/faqs_billing/" target="_blank"
                                    class="text-dark">here</a>
                            </div>
                        </b-row>
                    </b-container>


                    <template #modal-footer>
                        <div class="ml-auto">
                            <button v-if='!success' type="button" @click='saveNewPaymentMethod'
                                class="btn btn-primary mx-2" :disabled='loading'>
                                <b-spinner small type="grow" v-show="loading"></b-spinner>
                                Save
                            </button>
                            <button type="button" @click='close' class="btn btn-dark mx-2"
                                data-dismiss="modal">Close</button>
                        </div>
                    </template>
                </b-modal>

            </div>
            <div class="col-lg-5">
                <div class="iq-card">
                    <div class="iq-card-body">
                        <span class="table-add float-right mb-3 mr-2">
                            <p>&nbsp;</p>
                        </span>
                        <simpleIvoiceTable title="Invoices" :headers="invoice_columns" :account="account_id">
                        </simpleIvoiceTable>
                    </div>
                </div>
            </div>
        </b-row>

    </b-container>
</template>

<script>
import {
    sofbox
} from '../../config/pluginInit'
import ConsumptionBar from '@/components/charts/ConsumptionBar.vue'
// import GetEmailConsumption from '@/components/charts/GetEmailConsumption.vue'
import ServiceConsumptionWidget from '@/components/inboxgeek/widgets/ServiceConsumptionWidget.vue'
import PaymentMethodAlert from '@/components/inboxgeek/alerts/PaymentMethodAlert.vue'

import simpleIvoiceTable from './children/ibgSimpleIvoiceTable.vue'
import WelcomeWidget from '@/views/Account/widgets/WelcomeWidget.vue'

import {
    loadStripe
} from '@stripe/stripe-js'
import api from '@/api/RestClient'
import {
    mapGetters
} from "vuex";
import moment from 'moment'
import FreeAccountProgress from '@/components/users/FreeAccountProgress'
import Multiselect from "vue-multiselect"
import { BILLING_COUNTRIES } from '@/countries';
import { helper } from '@/helpers';

const LiveOpensServiceID = 1;
const LiveLeadsServiceID = 2;


export default {
    name: 'Billing',
    components: {
        PaymentMethodAlert,
        WelcomeWidget,
        ConsumptionBar,
        ServiceConsumptionWidget,
        simpleIvoiceTable,
        FreeAccountProgress,
        Multiselect,
    },
    beforeCreate() {
        sofbox.openLoader();
    },
    computed: {
        ...mapGetters({
            isAdmin: 'Auth/isAdmin',
            client: 'Auth/isClient',
            user: 'Auth/user',
            liveOpenersSubscribed: 'Auth/liveOpenersSubscribed',
            liveLeadsSubscribed: 'Auth/liveLeadsSubscribed',
            lastSubscriptions: 'Auth/lastSubscriptions'
        })
    },
    created() {
        this.account_id = this.user.account_id;
    },
    mounted() {
        sofbox.index();
        window.that = this;
        let name = this.user.name.split(' ');
        this.address = {
            first_name: name[0],
            last_name: name[1],
            address: this.user.address,
        }
        //this.currentPlan();
        this.initPaymentMethod();
        this.iniInvoices();


        if (this.accountAlreadyUsedService(LiveLeadsServiceID)) {
            this.isAllowedAccount = true;
        }


    },
    data() {
        return {
            isAllowedAccount: false,
            plan: [],
            errors: [],
            billingErrors: [],
            paymentsMethod: [],
            invoices: [],
            modal: false,
            fails: false,
            success: false,
            address: [],
            no_payment_method: true,
            loading: false,
            invoice_columns: [{
                key: 'date',
                label: 'Date',
                tdClass: 'text-center'
            },
            {
                key: 'amount',
                label: 'Amount',
                tdClass: 'text-center'
            },
            {
                key: 'status',
                label: 'Status',
                tdClass: 'text-center'
            },
            {
                key: 'description',
                label: 'Description',
                tdClass: ''
            },
            {
                key: 'action',
                label: 'Action',
                tdClass: 'text-center'
            },
            ],
            account_id: null,
            subscription_loader: false,
            cancelSubscriptionText: 'Cancel My Subscription',
            remainingDays: 0,
            percentDay: 0,
            countries: BILLING_COUNTRIES,
            editPaymentMethod: null,
            renderServiceCards: true,
            has_qst_tax: false,
            has_gst_tax: false,
            gst_tax_num: null,
            qst_tax_num: null,
            is_taxable: false,
            nickname: ''
        }
    },
    methods: {
        currentPlan() {
            api.accounts.getCurrentPlan(0, this.service_id)
                .then(resp => {
                    this.plan = resp.data

                    let today = moment();
                    let nextBilling = moment(this.plan.next_billing_date, 'YYYY-MM-DD HH:mm:ss');
                    let remainingDays = nextBilling.diff(today, 'days') // Remaining days
                    this.remainingDays = remainingDays;


                    if (remainingDays < 0) {
                    }

                    this.percentDay = ((30 - remainingDays) * 100) / 30;

                });

        },
        initPaymentMethod() {
            api.billing.getPaymentMethods()
                .then(resp => {
                    let data = resp.data;
                    if (data.success && data.data) {
                        this.paymentsMethod = data.data;
                    }
                })
                .catch(error => {
                    if (error.response) {
                        if (error.response.status == 401) {
                            this.$store.dispatch('Auth/resetUser', false);
                            this.$router.go(this.$router.currentRoute)
                        } else if (error.response.status == 500) {

                            this.$bvToast.toast('something went wrong!', {
                                title: 'Error',
                                variant: 'danger'
                            });
                        }

                        this.$bvToast.toast('something went wrong!', {
                            title: 'Error',
                            variant: 'danger'
                        })
                    }
                })
                .finally(() => {
                    sofbox.closeLoader();
                });
        },
        DeletePaymentMethod(id, index) {
            this.$bvModal.msgBoxConfirm('Are you sure you want to delete this payment method.', {
                title: 'Please Confirm',
                size: 'sm',
                bodyClass: 'delete-payment-method-confirmation',
                okVariant: 'danger',
                cancelVariant: 'dark',
                okTitle: 'YES',
                cancelTitle: 'NO',
                footerClass: 'p-2 footer-modal-deleter-payment-method',
                hideHeaderClose: false,
                centered: true
            })
                .then(confirmation => {
                    if (confirmation)
                        api.billing.deletePaymentMethod(id).then(resp => {
                            let data = resp.data;
                            if (data.success) {
                                this.paymentsMethod.splice(index, 1)
                                this.$bvToast.toast(data.message, {
                                    title: 'Success',
                                    variant: 'success'
                                })
                            }
                        });
                })
                .catch(error => {
                    if (error.response) {
                        if (error.response.status == 401) {
                            this.$store.dispatch('Auth/resetUser', false);
                            this.$router.go(this.$router.currentRoute)
                        } else if (error.response.status == 500) { }

                        this.$bvToast.toast('something went wrong!', {
                            title: 'Error',
                            variant: 'danger'
                        })
                    }
                });

        },
        async updatePaymentMethod(method) {
            // Show Modal Update
            if (typeof method.billing_details != 'undefined') {

                let countryCode = typeof method.billing_details.country != 'undefined' ? method.billing_details.country : null;
                
                this.address = {
                    first_name: method.billing_details.first_name,
                    last_name: method.billing_details.last_name,
                    address: method.billing_details.line1 ?? this.user.address,
                    city: method.billing_details.city,
                    postal_code: method.billing_details.postal_code,
                    state: method.billing_details.state,
                    nickname:  this.nickname
                }
                this.nickname = method.card_nickname;
                let name = method.name.split(' ');
                this.address.first_name = name[0];
                this.address.last_name = name[1];
                this.editPaymentMethod = method;
                

                const account = this.user.account;
                const qst_tax_num = account.qst_tax_num
                const gst_tax_num = account.gst_tax_num

                if (qst_tax_num) {
                    this.qst_tax_num = qst_tax_num;
                    this.has_qst_tax = true;
                    this.is_taxable = true;
                }

                if (gst_tax_num) {
                    this.gst_tax_num = gst_tax_num;
                    this.has_gst_tax = true;
                    this.is_taxable = true;
                }

                let country = this.selectBillingCountry(countryCode);
                if(country) this.address.country = country;

                this.openModal();
            }


        },
        async initcard() {
            this.stripe = await loadStripe(process.env.VUE_APP_STRIPESECRET);
            const elements = this.stripe.elements()
            this.cardElement = elements.create('card')
            this.cardElement.mount('#card');
            // this.cardElement.mount('#card-second');
        },
        showErrors(errors) {
            _.forOwn(errors, (error, key) => {
                const ref = `b-errors-${key}`;
                if (typeof this.$refs[ref] != 'undefined') this.$refs[ref].classList.remove('d-none')
            });
        },
        resetErrors(errors) {
            _.forOwn(errors, (error, key) => {
                const ref = `b-errors-${key}`;
                if (typeof this.$refs[ref] !== 'undefined') this.$refs[ref].classList.add('d-none')
            });
        },
        async saveNewPaymentMethod(e) {
            this.loading = true;
            this.resetErrors(this.billingErrors);
            let on_update = false; 
            if(this.editPaymentMethod) {
                on_update = true;
            }
            const data = this.getBillingDetails(on_update);

            // Check if form is valid
            if(this.billingFormIsValid(data)) {

                // Update payment Method
                if (this.editPaymentMethod) {

                    const paymentMethodID = this.editPaymentMethod.id;

                    api.billing.updateBillingDetails(paymentMethodID, data)
                        .then(resp => {
                            if (resp.success) {

                                this.$swal('Update Billing Details', resp.message, 'success')
                                this.close(e)
                                this.initPaymentMethod();
                                // save qst and gst tax 
                                if (this.qst_tax_num || this.gst_tax_num) {
                                    this.saveQstOrGST(this.user.account_id, this.qst_tax_num, this.gst_tax_num)
                                }

                            }
                        })
                        .catch((error) => {
                            this.$swal('Update Billing Details', error.response.data.message, 'error')
                        })
                        .finally(() => {
                            this.renderServiceCards = false;
                            this.$nextTick(() => {
                                this.renderServiceCards = true;
                            })
                        });

                } else {
                    // Add payment Method

                    const {
                        paymentMethod,
                        error
                    } = await this.stripe.createPaymentMethod(
                        'card', this.cardElement, {
                        billing_details: this.getBillingDetails(),
                        metadata:[`card_nickname-${this.nickname}`]
                    }
                    );

                    if (paymentMethod) {
                        this.callbackCreatePaymentMethod(paymentMethod)
                    }

                    if (error) {
                        this.loading = false;
                        this.errors.push(error.message);
                        this.fails = true;
                        console.log(error);
                    }
                }
            } else {
                this.showErrors(this.billingErrors);
                this.loading = false;
            }

        },
        billingFormIsValid(form) {
            let errors = {};
            this.billingErrors = {};

            if (!form.name || !form.name.trim()) {
                errors.name = 'Name is required.';
            }
            if (!form.email || !form.email.trim()) {
                errors.email = 'Email is required.';
            }

            if (!this.address.first_name || !this.address.first_name.trim()) {
                this.billingErrors.first_name = 'First name is required.';
            }
            if (!this.address.last_name || !this.address.last_name.trim()) {
                this.billingErrors.last_name = 'Last Name is required.';
            }

            if (!this.nickname) {
                this.billingErrors.nickname = 'Card Nickname is required.';
            }

            const someAddressTruthy = this.checkProperties(form.address);

            if (!form.address || typeof form.address != 'object' || !someAddressTruthy) {
                errors.address = 'Address is required.';
            }

            this.billingErrors = _.merge(this.billingErrors, errors); 

            return helper.isEmpty(errors);
        },
        checkProperties(obj) {
            let state = true;
            for (var key in obj) {
                let label = this.replaceAllOccurrences(key, '_', ' ');
                label = helper.capitalizeFirstLetter(label);

                if (obj[key] == null || obj[key] == "") {
                    this.billingErrors[key] = label + ' is required.'
                    state = false;
                }
            }
            return state;
        },
        replaceAllOccurrences(string, searchString, replaceString) {
            return string.split(searchString).join(replaceString);
        },
        getBillingDetails(on_update = false) {
            let billingDetails = {
                name: this.address.first_name + ' ' + this.address.last_name,
                email: this.user.email,
                address: {
                    line1: this.address.address,
                    // line2: null,
                    country: this.address.country && typeof this.address.country.code != 'undefined' ? this.address.country.code : null,
                    city: this.address.city,
                    postal_code: this.address.postal_code,
                    state: this.address.state,
                }
            }

            if(on_update == true) {
                billingDetails.nickname = this.nickname
            }

            return billingDetails;
        },
        callbackCreatePaymentMethod(paymentMethod) {
            api.billing.savePaymentMethod({
                paymentMethod: paymentMethod
            })
                .then((response) => {
                    let data = response.data; //eric

                    if (data.success) {
                        this.initPaymentMethod();
                        this.loading = false;
                        this.success = true;
                        this.errors = [];
                        this.fails = false;
                        // save qst and gst tax 
                        this.saveQstOrGST(this.user.account_id, this.qst_tax_num, this.gst_tax_num)
                        
                    }
                })
                .catch(error => {
                    if (error.response) {
                        if (error.response.status == 401) {
                            this.$store.dispatch('Auth/resetUser', false);
                            this.$router.go(this.$router.currentRoute)
                        } else if (error.response.status == 500) {

                            this.$bvToast.toast('something went wrong!', {
                                title: 'Error',
                                variant: 'danger'
                            });
                        } else if (error.code == 'incorrect_number') {
                            this.loading = false;
                            this.errors = error.message;
                            this.fails = true;
                            this.success = false;
                        }

                        this.$bvToast.toast('something went wrong!', {
                            title: 'Error',
                            variant: 'danger'
                        })
                    }
                })
                .finally(() => {
                    this.loading = false;

                    this.renderServiceCards = false;
                    this.$nextTick(() => {
                        this.renderServiceCards = true;
                    })
                });
        },
        saveQstOrGST(account_id, qst, gst) {
                let data = {
                    'qst_tax_num': qst,
                    'gst_tax_num': gst
                }
            if (!this.is_taxable) {
                data.is_exempt_gst_tax = 0;
                data.is_exempt_qst_tax = 0;
                }
            api.accounts.update(account_id, data).then(response => {
                console.log(response.data);
            });
        },
        close(e) {
            e.stopPropagation();
            this.modal = false;
            this.loading = false;
            this.success = false;
            this.errors = [];
            this.fails = false;
            this.$root.$emit('bv::hide::modal', 'payment-method')
        },
        openModal() {
            if (!this.editPaymentMethod) {
                this.address = {};
            }
            // this.modal = true;
            this.$root.$emit('bv::show::modal', 'payment-method')
            this.initcard();
        },
        changeDefault(element, id) {
            if (element.target.checked)
                api.billing.changeDefaultMethod({
                    id: id,
                }).then((response) => {
                    let data = response.data;
                    if (data.success) {
                        this.paymentsMethod = data.data;
                    }
                });
            $.each(this.paymentsMethod, (index, pm) => {
                pm.default = false;
                if (pm.id == id)
                    pm.default = true;
            });
        },
        iniInvoices() {
            api.billing.getInvoices().then((response) => {
                let data = response.data;
                if (data.success) {
                    this.invoices = data.data;
                }
            });
        },
        cancelSubscription(item, index, button) {

            let title = "Please confirm the reactivation of the subscription";
            let text = `Click yes to enable`
            let type = 'info'
            let confirmBtnText = "Yes, please resubscribe"
            let cancelBtnText = "No, I've changed my mind"
            let endSubscriptionDate = this.plan.subscription_end_date;
            if (endSubscriptionDate != null && this.date_diff(endSubscriptionDate) <= 0) {
                window.location.href = '/user/plan';
                return;
            }
            if (this.plan.next_billing_date) {
                let nextBillingDate = moment(this.plan.next_billing_date).format('YYYY-MM-DD')
                title = "Please Confirm Your Cancellation";
                text = `Click below to cancel your subscription (You will still receive events until ${nextBillingDate})`
                type = 'danger'
                confirmBtnText = "Yes, please cancel"
            }
            this.$swal({
                title: title,
                text: text,
                type: type,
                showCancelButton: true,
                confirmButtonText: confirmBtnText,
                cancelButtonText: cancelBtnText,
                showCloseButton: true,
                showLoaderOnConfirm: true
            }).then((result) => {

                if (result.value) {
                    this.subscription_loader = true;
                    if (this.plan.next_billing_date) this.cancel()
                    else this.resubscribe();
                }
            })
        },
        cancel() {
            api.subscriptions.cancelSubscription(this.user.account_id, {})
                .then(result => {
                    const title = 'You have successfully cancelled your subscription';
                    const message = 'You can subscribe at any point by returning to your billing dashboard';
                    this.$swal(title, message, 'success');
                    this.currentPlan();
                })
                .catch(function (error) {
                    window.that.$bvToast.toast('Something went wrong!', { message: 'Something went wrong!', title: 'Error', variant: 'danger' });
                })
                .finally(() => {
                    this.subscription_loader = false;
                });
        },
        resubscribe() {
            let plan = this.plan;
            api.subscriptions.reSubscription(this.user.account_id, { plan })
                .then(result => {
                    const title = result.success ? 'Good!' : 'Ooops!';
                    const message = result.message;
                    const type = result.success ? 'success' : 'info';
                    this.$swal(title, message, type);
                    this.currentPlan();
                })
                .catch(function (error) {
                    window.that.$bvToast.toast('Something went wrong!', { message: 'Something went wrong!', title: 'Error', variant: 'danger' });
                })
                .finally(() => {
                    this.subscription_loader = false;
                });
        },
        date_diff(date) {
            var now = moment(new Date()); //todays date
            var end = moment(date); // another date
            var duration = moment.duration(end.diff(now));
            var days = duration.asDays();
            return days;
        },

        isResubscribe(plan) {
            let date = plan.subscription_end_date ? plan.subscription_end_date : plan.next_billing_date;
            if (date == null) return false;
            const check = this.date_diff(date) > 0 ? true : false;
            console.log(this.date_diff(date));
            return check;
        },
        serviceIsActive(serviceID) {

            if (serviceID == LiveOpensServiceID) {
                return this.liveOpenersSubscribed
            }
            if (serviceID == LiveLeadsServiceID) {
                return this.liveLeadsSubscribed
            }
        },
        selectBillingCountry(countryCode = null) {
            let paymentMethod = this.editPaymentMethod;
            let country = null;

            if(!countryCode && paymentMethod) {
                if (typeof paymentMethod.card_country != 'undefined' && paymentMethod.card_country != '') countryCode = paymentMethod.card_country;
                else if( typeof paymentMethod.billing_details.country != 'undefined' && paymentMethod.billing_details.country != '') countryCode = paymentMethod.billing_details.country;
            }

            if (countryCode) {
                country = _.find(this.countries, (o) => { return o.code == countryCode })
                // if (country) this.address.country = country;
            }

            return country;
        },
        updateBillingCountry(value) {

            if(typeof value.code != 'undefined') {
                let country = this.selectBillingCountry(value.code);
                if(country) this.address.country = country;
            }
            this.is_taxable = ['CA'].includes(value.code)
            if (!this.is_taxable) {
                this.gst_tax_num = null;
                this.qst_tax_num = null;
                this.has_gst_tax = false;
                this.has_qst_tax = false;
            }
            this.$forceUpdate();
        },
        accountAlreadyUsedService(serviceID) {
            return typeof this.lastSubscriptions[serviceID] !== 'undefined';
        },
    },
    watch: {
        'has_qst_tax': function (newVal, oldVal) {
            if (!newVal) this.qst_tax_num = null
        },
        'has_gst_tax': function (newVal, oldVal) {
            if (!newVal) this.gst_tax_num = null
        }
    }
}
</script>
<style>
.fa-infinity {
    font-size: 25px !important;
}

thead {
    text-align: center;
}
</style>
