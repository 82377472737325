<template>
  <div>

    <b-form-group class="col-md-12">
      <!-- LoDailyLimitAutoUpdate name Section -->
      <b-row class="my-2">
        <b-col>
          <label for="">Logic Name: *</label>
          <input type="text" class="form-control" v-model="name">
          <small v-if="errors?.name" class="text-danger">Logic Name is required</small>
        </b-col>
      </b-row>
    </b-form-group>


    <!-- Navigation buttons -->
    <hr>

    <div class="d-flex justify-content-end" style="gap:5px">
      <button class="btn btn-light" @click="$emit('cancel')">Cancel</button>
      <button class="btn btn-primary d-flex align-items-center " :disabled="!valid" @click="next">Next  <i class="ri-arrow-right-line"></i> </button>
    </div>

  </div>


</template>

<script>

export default {
  name: "LogicLoDailyLimitAutoUpdateWizardModal",
  props:['account','form'],
  components: {},
  mounted() {
    console.log('mounted:Logic Step', this.form);
    if(this.form.initial){
      this.name = this.form.initial.name;
    }

    if(this.form.logic) {
      this.name =  this.form.logic.name
    }
  },
  data(){
    return {
      name: null,
      // value: null,
      errors:{
        name:false,
        // value:false,
      },
    }
  },
  methods:{

    next(){

      this.errors = {name:false};

      if(!this.name){
        this.errors.name = true;
        return;
      }

      // if(!this.value){
      //   this.errors.value = true;
      //   return;
      // }

      this.$emit('next',{
        name:this.name,
        // value:this.value
      });

    },
  },
  computed:{
    valid(){

      return ! (!this.name);

    }
  }

}

</script>

<style scoped>

</style>
