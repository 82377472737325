<template>
    <!-- <b-container fluid> -->
        <iq-card class="mb-0 pb-0">
            <!-- <template v-slot:headerTitle>
                <h4 class="card-title">{{ title }}</h4>
            </template> -->
            <template v-slot:body>
                <!-- <div class="new-discount-info"> -->
                    <FormDiscount ref="form" :item="item" @handleSubmit="handleSubmit" />
                <!-- </div> -->
            </template>
        </iq-card>
    <!-- </b-container> -->
</template>

<script>
import {
    sofbox
} from '@/config/pluginInit'
import store from '@/store/index'
import FormDiscount from './FormDiscount'
import {
    helper
} from '@/helpers'
import api from '@/api/RestClient'
export default {
    name: 'AddEditDiscount',
    props: {
        title: {
            type: [String],
            default: null
        },
        modal: {
            type: [Object],
            default: () => null
        },
        item: {
            type: [Object],
            default: () => null
        },
    },
    components: {
        FormDiscount
    },
    data() {
        return {
        }
    },
    methods: {
        handleSubmit(data) {

            let swal = {
                icon: 'success',
                title: 'Discount Added',
                text: 'Discount added successfully',
                type: 'success',
                confirmButtonText: 'OK',
                showCloseButton: true,
            }

            if (!data || !data.id) {
                swal.text = 'Something went wrong'
                swal.type = 'danger'
                swal.icon = 'warning'
                this.$swal(swal).then((result) => {
                    return
                })
                return
            }

            if (data.id && !data.new) {
                swal.title = 'Discount Updated'
                swal.text = 'Discount updated successfully'
            }

            const that = this
            this.$swal(swal).then((result) => {
                that.$root.$emit('bv::hide::modal', this.modal.id)
                that.$emit('modalHide', swal)
            })
        },
    }
}
</script>
