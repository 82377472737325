<template>
    <span>
        <span 
            v-html="truncate" 
            :id="`${row.field.key}-${row.index}`"></span>

        <!-- HTML tooltip content -->
        <b-tooltip :target="`${row.field.key}-${row.index}`" placement="bottom">
            <span v-html="row.item[row.field.key]"></span>
        </b-tooltip>
    </span>
</template>

<script>
export default {
    name: 'HtmlCell',
    props: ['row'],

    computed: {
        truncate() {
            let strLen = 100;
            let separator = '...';
            let fullStr = this.row.item[this.row.field.key];

            if (fullStr.length <= strLen) return fullStr;

            separator = separator || '...';

            let sepLen = separator.length,
                charsToShow = strLen - sepLen,
                frontChars = Math.ceil(charsToShow / 2),
                backChars = Math.floor(charsToShow / 2);

            return fullStr.substr(0, frontChars) +
                separator +
                fullStr.substr(fullStr.length - backChars);
        }
    }
}

</script>
