<template>
<div>
    <b-row>
        <b-col lg="12">
            <iq-card>
                <template v-slot:body>
                    <TimeLine :items="timelineItems" v-show="haveHistories" />
                    <p class="text-center" v-show="!haveHistories">No data founded</p>
                </template>
                 <p class="text-primary text-center cursor-pointer-ek" v-show="haveHistories" @click="all">Show More <b-spinner small label="Small Spinner" type="grow" v-show="spinner"></b-spinner> </p>
            </iq-card>
        </b-col>
    </b-row>

</div>
</template>

<script>
export default {
    props: {
        sourceLogs: {
            type: Array,
            default: () => []
        },
        name: {
             type: String,
            default: '***'
        },
        paymentLogs: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            form: {
                email: '',
                name: '',
                food: null,
                checked: []
            },
            integration_columns: [
                'status',
                'name',
                'triggers',
                'lists'
            ],
            show: true,
            timelineItems: [],
            COLORS: [
                'primary',
                'secondary',
                'success',
                'danger',
                'warning',
                'info',
                'dark '
            ],
            LOGS_LIMIT: 6,
            spinner: false,
            haveHistories: false,
            dataLogs: [],
            logs: []
        }
    },
    mounted() {
        this.mergePaymentWithInvoiceLogs();
        this.addTimeLineItems();
        this.addOverFlowClass()
    },
    created() {

    },
    methods: {
        colorsByActions(action) {
            let color = 'info'
            switch (action) {
                case 'created':
                    color = 'success'
                    break
                case 'updated':
                    color = 'primary'
                    break
                case 'deleted':
                    color = 'danger'
                    break
            }
            return color;
        },
        subArray(array = [], start = 0, end = 6) {
            if (!end) {
                end = -1;
            }
            return array.slice(start, end);
        },
        addOverFlowClass() {
            if (this.logs.length > this.LOGS_LIMIT) {
                $('.iq-timeline').parent().addClass('iq-overflow');
            }
        },
        timeLineDescription(item) {
            let label = item.action + ' successfully';
            return `The automation ${label}`
        },
        getMessage(columns, item) {
            let message = '';
            switch (item.action) {
                case 'updated':
                     this.integration_columns.forEach(column => {
                        if (column == 'status' && typeof columns[column] !== 'undefined') {
                            if (columns[column] == -1) message += 'The automation is removed, '
                            else if (columns[column] == 0) message += 'The automation is disabled, '
                            else if (columns[column] == 1) message += 'The automation is enabled, '
                         }
                         if (column == 'triggers' && typeof columns[column] !== 'undefined') {
                             message += 'The triggers is changed,';
                         }
                         if (column == 'lists' && typeof columns[column] !== 'undefined') {
                             message += 'The lists is changed,';
                         }

                         if (column == 'name' && typeof columns[column] !== 'undefined') {
                            message += 'The name is changed,';
                        }

                    });
                    break;
                case 'restored':
                    message = 'The automation is restored, '
                    break;
                case 'deleted':
                    message = `The automation is removed but can be restored, `
                    break;
                case 'created':
                    this.integration_columns.forEach(column => {
                        message += columns && typeof columns[column] !== 'undefined' ? `The ${column} created with value ${columns[column]}, ` : ``
                    });

                    break;
                default:
                    message = `***, `;
                    break;
            }
            return message;
        },
        formatColumnAction(item) {
            let log_detail = '';
            let columns = JSON.parse(item.columns);
            log_detail = columns ? this.getMessage(columns, item) : ''
            return log_detail;
        },
        timeLineIntegrationColumnsAction(item) {
            let log_detail = this.formatColumnAction(item);
            let collapse = ` <a href='#' class='log_detail'>More <i class="fa fa-angle-down"></i></a> <div style='display: none' class='mt-2'>${log_detail}</div > `

            return log_detail;
        },
        addTimeLineItems() {
            var moment = require('moment');
            let i = 0;
            this.spinner = false;
            this.haveHistories = false;
            if (this.dataLogs.length > 0) this.haveHistories = true;
            this.logs = _.orderBy(this.logs, ['date'], ['desc'])
            this.logs.forEach(item => {

                let description = this.timeLineDescription(item);
                let collapse = this.timeLineIntegrationColumnsAction(item);

                description = `${collapse}`
                let title = `${item.action}`;
                let right =  moment(item.created_at).format('MMM Do YYYY HH:mm') + `, by ${item.completed_by}`;
                let line = {
                    color: this.colorsByActions(item.action),
                    title: title,
                    right: right,
                    description: description,
                    child: {
                        type: 'img',
                        items: []
                    }
                }
                this.timelineItems.push(line);
                i++;
            });
            setTimeout(function () {
                $('.log_detail').on('click', function (e) {
                    e.preventDefault();
                    $('.log_detail').find('i').removeClass('fa fa-angle-up').addClass('fa fa-angle-down');
                    $(this).siblings('div').toggle('slow');
                    if ($(this).find('i').hasClass('fa-angle-down')) $(this).find('i').removeClass('fa fa-angle-down').addClass('fa fa-angle-up');
                    else if ($(this).find('i').hasClass('fa-angle-up')) $(this).find('i').removeClass('fa fa-angle-up').addClass('fa fa-angle-down');
                })
            }, 1500)

        },
        formatDateYMD(date) {
            setT
            var d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2)
                month = '0' + month;
            if (day.length < 2)
                day = '0' + day;

            return [year, month, day].join('-');
        },
        all() {
            this.spinner = true;
            setTimeout(() => {
                this.timelineItems = []
                this.logs = [...this.dataLogs];
                this.addTimeLineItems();
                this.addOverFlowClass();
                this.spinner = false;
            }, 2000);
        },
        mergePaymentWithInvoiceLogs() {
            let payment = {
                action: 'paid',
                status: 1,
                completed_by: '',
                type: 'invoice',
                date: '',
                id: ''
            };
            this.dataLogs = [];
            this.dataLogs = [...this.sourceLogs];
            this.logs = this.subArray(this.dataLogs, 0, this.LOGS_LIMIT);
        }
    },
    destroyed() {
        this.dataLogs = []
        this.timelineItems = [];
    },
}
</script>

<style>
.cursor-pointer-ek {
    cursor: pointer;
}

.iq-overflow {
    overflow-y: scroll;
    height: 50em;
}
</style>
