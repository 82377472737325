<template>
  <b-row class="text-left">

    <div   class="col-md-12">
        <label for="list">List</label>
        <multiselect 

        v-model="list" 
        tag-placeholder="Empty" 
        placeholder="List" 
        label="name"
        track-by="code" 
        :options="lists" 
        return="code">


        <template slot="option" slot-scope="props">
            <div class="option__desc">

                <span class="option__title">

                    <span v-if="props.option.type">

                        <i v-if="props.option.type == 'segment'" class="ri-flashlight-line"
                            style="color:#5ad"></i>
                        <i v-else class="ri-list-check color-primary" style="color:#5ad"></i>

                    </span>

                    <span v-else><i class="ri-list-check color-primary" style="color:#5ad"></i></span>

                    <span style="font-wight:bold;"> {{ props.option.name }} </span>

                    <span style="font-size:11px; color:#888"> {{ props.option.code }}</span>


                </span>

            </div>

        </template>


        </multiselect>
    </div>

    <div  v-if="list_selected" class="col-md-12 mt-3">
      <label for="segment" style="display:flex;justify-content: space-between;">
          <span>Segment</span>  
      </label>
      
  <multiselect v-model="selected_segments" multiple taggable tag-placeholder="Segments" placeholder="select segments" label="name"
  track-by="code" :options="segments" :loading="isLoading" return="code" :allow-empty="true">


  <template slot="option" slot-scope="props">
      <div class="option__desc">

          <span class="option__title">

              <span v-if="props.option.type">

                  <i class="ri-flashlight-line" style="color:#5ad"></i>
                  

              </span>

              <span v-else><i class="ri-list-check color-primary" style="color:#5ad"></i></span>

              <span style="font-wight:bold;"> {{ props.option.name }} </span>

              <span style="font-size:11px; color:#888"> {{ props.option.id }}</span>


          </span>

      </div>

  </template>


  </multiselect>
    </div>
    <div  v-show="showWarning" class="col-md-12 p-3">
      <div class="alert danger" style="    color: #da1e1e;border-color: #f7c6c6;background-color: #fcecec4d;">At least one segment must be selected  </div>
    </div>
  </b-row>

</template>

<script>

import Multiselect from 'vue-multiselect'
import _ from 'lodash';
import api from '@/api/RestClient';

export default {

  name: 'ListStepOngage',

  components: {
      Multiselect
  },
  props:['lists','platform_id','platform','oAccountId','auth2','listAlready'],
  data(){
    return {
      showWarning:false,
      list: null,
      use_segments:false,
      segments:[],
      selected_segments:[],
      list_selected:false,
      isLoading: false
    }
  },
  mounted() {
      if(this.listAlready) {
          this.list = this.listAlready
      }
  },
  methods:{
      fetchSegments(){

          this.isLoading = true;

          api.platforms.fetchServices({

              "platform_name" : 'ongage',
              "entities"      : "segments",
              "platform_id"   : this.platform.p_id ,
              "platform"      : this.platform.code,
              "list_id"       : (typeof this.listAlready !== 'undefined') ? this.listAlready.code : this.list.code

          }).then(( response ) => {

              this.segments = response.data.map((segment)=>{
                  return {
                      'code' : segment.id,
                      'name' : segment.name
                  };
              });

              this.isLoading = false;

          }).catch((error)=>{
              this.isLoading = false;
              console.log('failed to fetch segments');
          }).finally(() => {

              this.selectCurrentSegment()
              
          });
      },

      selectCurrentSegment() {

          const that = this

          if( typeof  that.listAlready !== 'undefined' ) {
              this.isLoading = true;

              setTimeout(() => {
                  let activeSegment = _.find(that.segments, function (o) { return o.name == that.listAlready.list_name });

                  if(activeSegment) {
                      that.selected_segments = []
                      that.use_segments = true
                      that.selected_segments.push(activeSegment)
                  }

                  this.isLoading = false;
              }, 400)
          }
      }
  },  
  watch : {
      list : {
          handler(newValue, oldValue) {

              if(newValue){

                  this.list_selected = true;
                  this.fetchSegments();  

              }else{
                  
                  this.selected_segments = [];
                  this.segments = [];
              }  
              if(this.selected_segments.length > 0){
                this.$emit('listSelected',this.selected_segments,this.list);
                this.showWarning = false;
              }else{
                this.$emit('segmentSelected',null);
                this.showWarning = true;
              }
          },
      },
      selected_segments : {
          handler(newValue,oldValue) {
            if(this.selected_segments.length > 0) {
              this.$emit('segmentSelected', this.selected_segments, this.list);
              this.showWarning = false;
            }else{
              this.$emit('segmentSelected',null);
              this.showWarning = true;
            }

          }
      }


  }
}


</script>