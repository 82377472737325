<template>
    <div class="d-flex justify-content-center my-auto" v-b-tooltip v-b-tooltip.hover :title="`${row.value.name}${(row.item.platform && row.item.platform.account ? ' • ' + row.item.platform.account : '')}`">

        <span v-if="!row.value" class="badge badge-light mx-2">Not Defined</span>
        <span v-else class="badge badge-light mx-1 my-auto">

            <span v-if="getPlatformImage(row.value.name)" class="mx-2">
                <img style="width: 1.3em;" :src="getPlatformImage(row.value.name)">
            </span>

            <span class="mx-2">{{ row.item.platform.nickname ?? '--' }}</span>

        </span>

    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    name: 'IBGPlatformCell',
    props: ['row'],
    data() {
        return {
            platform: null
        }
    },
    mounted() {
        this.platform = this.row.value ? this.row.value : null;
    },
    computed: {
        ...mapGetters('Auth', ['user', 'isOwnerAccount']),
    },
    methods: {
        getPlatformImage(platform) {
            try {
                const imgFileName = platform.toLowerCase();
                return require(`@/assets/images/platforms/${imgFileName}.png`);
            } catch (e) {
                return null;
            }
        },
    }
}

</script>
