import { COLUMNS_GRID } from "@/constantes";

export default function platform(axios) {
    return {
        labels: {
            listID: "id",
            listName: "listName",
            tagID: "id",
            tagName: "name",
        },
        maps: {
            listsMap: (obj) => {
                let item = {}
                item.code = obj.id
                item.name = obj.listName
                return item
            },
        },
        customShowAnotherCols(COMP) {
            if (typeof COMP.cols.apiKeyCol !== 'undefined') COMP.cols.apiKeyCol = COLUMNS_GRID.COL6;
            COMP.cols.accoundIdCol = COLUMNS_GRID.COL6;

            if (typeof COMP.showCols.apiKey !== 'undefined') COMP.showCols.apiKey = true;
            if (typeof COMP.showCols.accountId !== 'undefined') COMP.showCols.accountId = true;

            COMP.showCols.tags = false;
            COMP.showCols.customTag = true;

            if (typeof COMP.$refs["step-platform"] !== 'undefined') COMP.$refs["step-platform"].accountLabel = 'Login: *';
            if (typeof COMP.$refs["step-platform"] !== 'undefined') COMP.$refs["step-platform"].accountPlaceholder = 'Email';
            if (typeof COMP.$refs["step-platform"] !== 'undefined') COMP.$refs["step-platform"].apiTokenLabel = 'Password: *';
            if (typeof COMP.$refs["step-platform"] !== 'undefined') COMP.$refs["step-platform"].apiTokenPlaceholder = 'password';

            if (typeof COMP.accountLabel !== 'undefined') COMP.accountLabel = 'Login: *';
            if (typeof COMP.accountPlaceholder !== 'undefined') COMP.accountPlaceholder = 'Email';
            if (typeof COMP.apiKeyLabel !== 'undefined') COMP.apiKeyLabel = 'Password: *';
            if (typeof COMP.apiKeyPlaceholder !== 'undefined') COMP.apiKeyPlaceholder = 'password';

        },
        getTagAdded(result) {
            result = 'data' in result ? result.data : result;
            return { code: result.ListID, name: result.Name }
        },
    }
}