<template>
<div>
     <div class="mb-3">
        <label for="range-1">Tag *</label>
        <multiselect id="range-1" v-model="list" tag-placeholder="Add this as new tag" placeholder="Add Tags" label="name" track-by="id" :options="lists" :multiple="false" :taggable="true" :allow-empty="false" :loading="omnisend_isLoading" @tag="tagInput"  @select="tagSelected"></multiselect>
    </div>
    <small v-if="error.show">{{error.message}}</small>
</div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import api from "@/api";
export default {
    name: "OmniSendListInput",
    props: ['form', 'listsProp'],
    components: {
        Multiselect
    },
    mounted() {
        if (this.lists) {
            this.lists = _.map(this.listsProp, (obj, key) => {
                return {
                    id: obj.id,
                    name: obj.name
                }
            });    
        }
        
    },
    data() {
        return {
            list: null,
            lists: [],
            error: {
                show: false,
                message: ''
            },
            omnisend_isLoading:false
        }
    },
    methods: {
        tagInput(tag) {

            if (tag) {
                this.omnisend_isLoading = true
                let code = tag;
                let platform_id = this.platform.p_id
                api.integrations.checkOmniTag(code, platform_id).then(response => {
                    let total = response.data.length;
                    if (total == 0) {
                        this.$swal('Error tag', `${tag ?? 'undefined'} tag doesn't exist`, 'error')
                    } else {
                        this.omnisend_tag.push({
                            'code': tag,
                            'name': tag,
                            'type': 'list'
                        });
                        this.list = this.omnisend_tag;
                        this.$emit('ListSelected', this.list);
                    }
                    this.omnisend_isLoading = false;
                }).catch((error) => {
                    console.log(error);
                    this.$bvToast.toast(`Something want wrong`, {
                        title: 'Error',
                        variant: 'danger'
                    });
                    this.omnisend_isLoading = false;
                }).finally(() => {
                    //this.omnisend_isLoading = false;
                })
            } else {
                this.list = [];
            }
        },
        tagSelected() {
            if (this.list) {
                this.$emit('ListSelected', this.list);
            }
        }
    }
}
</script>

<style scoped>

</style>
