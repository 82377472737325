<template>
    <span>
        <span 
            v-html="truncate" 
            :id="`${row.field.key}-${row.index}`"></span>

        <!-- BTN Show modal content -->
        <b-button v-if="!isNotTooLongContent" class="pull-right" 
            @click.prevent="showModalContent(`cell-content-${row.item}-${row.index}-${row.field.key}-modal`)" 
            variant="light">Show Content</b-button>

        <!-- Modal content -->
        <b-modal :id="`cell-content-${row.item}-${row.index}-${row.field.key}-modal`"
            :ref="`cell-content-${row.item}-${row.index}-${row.field.key}-modal`" 
            title="Content" 
            cancel-title="Close" 
            hide-footer>
                <span v-html="row.item[row.field.key]"></span>
        </b-modal>

    </span>
</template>

<script>
export default {
    name: 'CellWithModal',
    props: ['row'],
    data() {
        return {
            strLen: 150
        }
    },
    computed: {
        truncate() {

            let separator = '...';
            let fullStr = this.row.item[this.row.field.key];

            if (this.isNotTooLongContent) return fullStr;

            separator = separator || '...';

            let sepLen = separator.length,
                charsToShow = this.strLen - sepLen,
                frontChars = Math.ceil(charsToShow / 2),
                backChars = Math.floor(charsToShow / 2);

            return fullStr.substr(0, frontChars) +
                separator +
                fullStr.substr(fullStr.length - backChars);
        },
        isNotTooLongContent() {
            let fullStr = this.row.item[this.row.field.key];

            return fullStr.length <= this.strLen;
        }
    },
    methods: {
        showModalContent(modalId) {
            this.$root.$emit('bv::show::modal', modalId)
        }
    }
}

</script>
