<template>
    <b-container fluid class="mt-4 mt-md-0">
        <!-- CLIENT -->
        <b-col v-if="client">

            <b-card class="my-3" header="">
                <div class="row">
                    <div class="align-self-center col-md-6">
                        <h2 class="">Welcome <span class="text-bold">{{ profileFirstname | filterCapitalize(true) }}</span></h2>
                    </div>
                </div>

            </b-card>

            <b-tabs :pills="true" class="nav-fill mt-5 mt-md-0" id="pills-tab-1">
                <b-tab :active="true" id="pills-oe-service-tab-fill" href="#pills-oe-service-fill"
                    ariaControls="pills-oe-service-fill" role="tab" :ariaSelected="true" title="LiveOpens" class="mb-3">

                    <b-row v-if="!accountAlreadyUsedService(LIVE_OPENS)">
                        <b-col md="12">
                            <AnnounceService :service-code="LIVE_OPENS"></AnnounceService>
                        </b-col>
                    </b-row>

                    <b-row v-else-if="accountAlreadyUsedService(LIVE_OPENS)">
                        <b-col md="12">
                            <LiveOpenersConsumptionBar v-if="showLiveOpenersDetails" :service="LIVE_OPENS" :options="openEventsData">
                            </LiveOpenersConsumptionBar>
                        </b-col>
                    </b-row>

                    <b-row v-if="accountAlreadyUsedService(LIVE_OPENS)">
                        <b-col lg="12" md="12">
                            <EventsChart :dates="option"></EventsChart>
                        </b-col>
                    </b-row>

                </b-tab>
                <b-tab :active="false" id="pills-ge-service-tab-fill" :disabled="!accountAlreadyUsedService(LIVE_LEADS)"
                    href="#pills-ge-service-fill" ariaControls="pills-ge-service-fill" role="tab" :ariaSelected="false"
                    title="LiveLeads" class="mb-3">

                    <b-row v-if="!accountAlreadyUsedService(LIVE_LEADS)">
                        <b-col md="12">
                            <AnnounceService :service-code="LIVE_LEADS"></AnnounceService>
                        </b-col>
                    </b-row>

                    <b-row v-else-if="accountAlreadyUsedService(LIVE_LEADS)">
                        <b-col md="12">
                            <LiveLeadsConsumptionBar v-if="showLiveLeadsDetails" :service="LIVE_LEADS" :options="liveLeadsData">
                            </LiveLeadsConsumptionBar>
                        </b-col>
                    </b-row>

                    <b-row v-if="accountAlreadyUsedService(LIVE_LEADS)">
                        <b-col md="12" class="text-center">

                            <RecordsChart :dates="option"></RecordsChart>

                        </b-col>
                    </b-row>

                </b-tab>
            </b-tabs>

        </b-col>

        <!-- ADMIN -->
        <b-col v-if="isAdmin 
                    || isAccounting 
                    || isAccountManager">

            <b-row class="row">
                <!-- Daily events-->
                <b-col lg="12" md="12" v-if="isAdmin || isAccountManager">
                    <b-tabs :pills="true" class="nav-fill" id="pills-chart">
                        <b-tab :active="true" id="liveOpenersChart-tab-fill" href="#liveOpenersChart"
                            ariaControls="liveOpenersChart" role="tab" :ariaSelected="true" title="LiveOpens" class="mb-3">

                            <!-- Top Revenue Stats LiveOpens -->
                            <b-row class="my-3">
                                <b-col v-for='(card, index) in cards' :key='index' md="6" lg="3">
                                    <DashboardStatsCard :period="card.period" :valueUnit="card.valueUnit"
                                        :label="card.label" :itemClass="card.itemClass" :iconClass="card.iconClass">
                                    </DashboardStatsCard>
                                </b-col>
                            </b-row>

                            <!-- LiveOpens Stats Events -->
                            <CustomKpis :options="events_daily"></CustomKpis>


                            <b-row>

                                <!-- LiveOpens Organization per subscription-->
                                <b-col lg="12" md="12">
                                    <DashChart :options="lo_organization_per_subscription"></DashChart>
                                </b-col>
                                <!-- End LiveOpens Organization per subscription-->


                                <!-- active organization-->
                                <b-col lg="7" md="12">
                                    <DashChart :options="lo_organization_stats"></DashChart>
                                </b-col>
                                <!-- end active organization-->

                                <!-- Organization paused/cancelled-->
                                <b-col lg="5" md="12">
                                    <DashChart :options="lo_organization_cancelled_or_paused"></DashChart>
                                </b-col>
                                <!--end  Organization paused/cancelled-->

                            </b-row>

                        </b-tab>

                        <b-tab :active="false" id="liveLeadsChart-tab-fill" href="#liveLeadsChart-fill"
                            ariaControls="liveLeadsChart-fill" role="tab" :ariaSelected="false" title="LiveLeads"
                            class="mb-3" @click="loadLiveLeadsChart">

                            <!-- Top Revenue Stats LiveLeads -->
                            <b-row class="my-3">
                                <b-col v-for='(card, index) in cards' :key='index' md="6" lg="3">
                                    <LlDashboardStatsCard :period="card.period" :valueUnit="card.valueUnit"
                                        :label="card.label" :itemClass="card.itemClass" :iconClass="card.iconClass"
                                        :service="LIVE_LEADS">
                                    </LlDashboardStatsCard>
                                </b-col>
                            </b-row>

                            <!-- LiveLeads Stats Events -->
                            <LiveLeadsChart :options="liveLeads_daily" ref="liveLead"></LiveLeadsChart>

                            <b-row>

                                <!-- LiveLeads Organization per subscription-->
                                <b-col lg="12" md="12">
                                    <DashChart :options="ll_organization_per_subscription"></DashChart>
                                </b-col>
                                <!-- End LiveLeads organization per subscription-->


                                <!-- active organization-->
                                <b-col lg="7" md="12">
                                    <DashChart :options="ll_organization_stats"></DashChart>
                                </b-col>
                                <!-- end active organization-->

                                <!-- Organization paused/cancelled-->
                                <b-col lg="5" md="12">
                                    <DashChart :options="ll_organization_cancelled_or_paused"></DashChart>
                                </b-col>
                                <!--end  Organization paused/cancelled-->

                            </b-row>

                        </b-tab>
                    </b-tabs>

                </b-col>


                <!-- Revenues -->
                <b-col v-if="isAdmin" lg="12" md="12">
                    <RevenueStatsChart :options="revenues"></RevenueStatsChart>
                </b-col>
                <!-- end Revenues-->
            </b-row>
        </b-col>
    </b-container>
</template>

<script>
import {
    sofbox
} from '@/config/pluginInit'

import WelcomeVideoCard from '@/components/inboxgeek/WelcomeVideoCard.vue'
import AnnounceCard from '@/components/inboxgeek/AnnounceCard.vue'
import ConsumptionBar from '@/components/charts/ConsumptionBar.vue'
import LiveOpenersConsumptionBar from '@/components/charts/ServiceConsumptionBar.vue'
import LiveLeadsConsumptionBar from '@/components/charts/ServiceConsumptionBar.vue'
import ServiceConsumptionWidget from '@/components/inboxgeek/widgets/ServiceConsumptionWidget.vue'
import DashboardStatsCard from '@/components/charts/DashboardStatsCard.vue'
import LlDashboardStatsCard from '@/components/charts/LlDashboardStatsCard.vue'
import ApexDashCharts from '@/components/charts/kpis.vue'
import RevenueStatsChart from '@/components/charts/RevenueStatsChart.vue'
import EventsChart from '@/components/charts/EventsChart.vue'
import RecordsChart from '@/components/charts/RecordsChart.vue'
import DashCard from '@/components/inboxgeek/DashCard.vue'
import DashChart from './children/chart.vue'
import api from '@/api/RestClient'
import { mapGetters,mapState,mapMutations } from 'vuex'
import { helper } from '@/helpers'
import CustomKpis from '@/components/charts/CustomKpis.vue'
import LiveLeadsChart from '@/components/charts/CustomKpis.vue'
import ApexChart from "@/components/sofbox/charts/ApexChart.vue"
import AnnounceService from "@/components/inboxgeek/sections/AnnounceService.vue"
import Vue from 'vue'
import {SERVICES } from "@/constantes";

Vue.filter('filterCapitalize', function (value, all = null) {
    return helper.customCapitalize(value, all)
})

const LiveOpensServiceID = 1;
const LiveLeadsServiceID = 2;

const week = 1
const month = 2
const day = 0
const year = 3

export default {
    name: 'Dashboard',
    props: {
        logo: { type: String, default: require('../../assets/images/logo_dark.png') },
    },
    components: {
        WelcomeVideoCard,
        AnnounceCard,
        ConsumptionBar,
        LiveOpenersConsumptionBar,
        LiveLeadsConsumptionBar,
        ServiceConsumptionWidget,
        DashboardStatsCard,
        EventsChart,
        RecordsChart,
        DashCard,
        ApexDashCharts,
        RevenueStatsChart,
        CustomKpis,
        LiveLeadsChart,
        DashChart,
        ApexChart,
        AnnounceService,
        LlDashboardStatsCard
    },
    computed: {
        ...mapState(['subscription']),
        ...mapGetters({
            isAdmin: 'Auth/isAdmin',
            client: 'Auth/isClient',
            isAccounting: 'Auth/isAccounting',
            isAccountManager: 'Auth/isAccountManager',
            user: 'Auth/user',
            liveOpenersSubscribed: 'Auth/liveOpenersSubscribed',
            liveLeadsSubscribed: 'Auth/liveLeadsSubscribed',
            UserSubscriptions: 'Auth/UserSubscriptions',
            lastSubscriptions: 'Auth/lastSubscriptions'
        }),
        profileFirstname() {
            if (!this.profile) {
                return ';)';
            }
            return this.profile?.first_name;
        }
    },
    mounted() {
        sofbox.index()
        if (!this.isAdmin) {

            // if (this.accountAlreadyUsedService(this.LIVE_LEADS)) {

            //     this.isAllowedAccount = true;
            // }

            if (this.user.profile) {
                this.profile = this.user.profile
            }

            this.getConsumptionData(this.LIVE_OPENS);
            this.getConsumptionData(this.LIVE_LEADS);
        }

    },
    data: () => ({
        isAllowedAccount: false,
        cards: [{
            label: 'Today',
            itemClass: 'iq-bg-success',
            iconClass: 'ri-calendar-line',
            valueUnit: 'Events',
            period: "today"
        },
        {
            label: 'Yesterday',
            itemClass: 'iq-bg-danger',
            iconClass: 'ri-calendar-line',
            valueUnit: 'Events',
            period: "yesterday"
        },
        {
            label: 'This Week',
            itemClass: 'iq-bg-warning',
            iconClass: 'ri-calendar-line',
            valueUnit: 'Events',
            period: "week"
        },
        {
            label: 'This Month',
            itemClass: 'iq-bg-primary',
            iconClass: 'ri-calendar-line',
            valueUnit: 'Events',
            period: "month"
        },

        ],
        option: {
            to: helper.dateToYMD(new Date()),
            from: helper.dateToYMD(helper.AddOrSubractDays(new Date(), 6, false))
        },
        organization_total: {
            url: 'dashboard/admin/stats',
            period: week,
            title: 'Organizations',
            element: 'oxu1',
            axis_type: 'category',
            key: 1,
        },
        lo_organization_stats: {
            url: 'dashboard/admin/stats',
            period: week,
            title: 'LiveOpens Active Organizations',
            element: 'oxu2',
            axis_type: 'category',
            key: 2,
            service: 1,
        },
        ll_organization_stats: {
            url: 'dashboard/admin/stats',
            period: week,
            title: 'LiveLeads Active Organizations',
            element: 'oxu22',
            axis_type: 'category',
            key: 2,
            service: 2,
        },
        lo_organization_per_subscription: {
            url: 'dashboard/admin/stats',
            period: week,
            title: 'LiveOpens Organization/Subscription',
            element: 'oxu8',
            axis_type: 'category',
            key: 3,
            service: 1,
        },
        ll_organization_per_subscription: {
            url: 'dashboard/admin/stats',
            period: week,
            title: 'LiveLeads Organization/Subscription',
            element: 'oxu88',
            axis_type: 'category',
            key: 3,
            service: 2,
        },
        lo_organization_cancelled_or_paused: {
            url: 'dashboard/admin/stats',
            period: week,
            title: 'LiveOpens Orgs cancelled/paused',
            element: 'oxu4',
            axis_type: 'category',
            key: 4,
            service: 1,
        },
        ll_organization_cancelled_or_paused: {
            url: 'dashboard/admin/stats',
            period: week,
            title: 'LiveLeads Orgs cancelled/paused',
            element: 'oxu44',
            axis_type: 'category',
            key: 4,
            service: 2,
        },
        events_daily: {
            url: 'dashboard/admin/charts',
            period: day,
            title: 'Events',
            element: 'oxu5',
            axis_type: 'category',
            key: 5,
            itemLabel: "event_id",
            exceptPeriod: [year],
            exceptDatesFor: [week, month],
            indicator: ""
        },
        revenues: {
            url: 'stats/charts/revenues',
            period: day,
            title: 'Revenues',
            element: 'oxu6',
            axis_type: 'category',
            key: 6,
            exceptPeriod: [week],
            itemLabel: "plan_id",
            indicator: "$"
        },
        liveLeads_daily: {
            url: 'dashboard/admin/charts',
            period: day,
            title: 'LiveLeads Events',
            element: 'oxu7',
            axis_type: 'category',
            key: 7,
            itemLabel: "event_id",
            exceptPeriod: [year],
            exceptDatesFor: [week, month],
            indicator: ""
        },
        organizations: [],
        openEventsData: null,
        liveLeadsData: null,
        showLiveOpenersDetails: false,
        showLiveLeadsDetails: false,
        showCardService: false,
        services: [
            { name: 'LiveOpens', chart: {}, showChart: false, chartLoading: true },
            { name: 'LiveLeads', chart: {}, showChart: false, chartLoading: true },
        ],
        profile: null,
        LIVE_OPENS: SERVICES.LIVE_OPENS.id,
        LIVE_LEADS: SERVICES.LIVE_LEADS.id,
        CLEAN_EMAIL: 3,
        subscriptionUsedLiveOpens: null,
        subscriptionUsedLiveLeads: null
    }),
    methods: {
        ...mapMutations(['updateSubscriptionOptions']),
        getConsumptionData(service = this.LIVE_OPENS) {

            api.stats.getConsumptionDataService(service)
                .then(response => {
                    if (response.success) {
                        const data = response.data;

                        let dataFormatted = this.formatSeviceData(data);
                        let serviceName = null;

                        if(this.client) {
                            let result = null;
                            
                            if (service == this.LIVE_OPENS) {
                                this.openEventsData = dataFormatted;
                                result = this.isNotServicePlanTrial(service, this.openEventsData);
                            } else if (service == this.LIVE_LEADS) {
                                this.liveLeadsData = dataFormatted;
                                result = this.isNotServicePlanTrial(service, this.liveLeadsData);
                            }

                            if(!result.state) {

                                this.showAlertEndingTrial(service, result);
                            }
                        }
                        
                    }
                })
                .catch(function (error) {
                    if (error.response) {
                        // handle error
                    }
                })
                .finally(() => {

                    this.fetchSubscriptionAlreadyUsed(service);

                })
        },
        showAlertEndingTrial(service, data) {

                let date = data.subscription ? data.subscription.ends_at : '--';

                let labelFirst = '--';
                let labelSecond = '--';

                if (service == 1) {
                    labelFirst = 'events';
                    labelSecond = 'sending event';
                } else if (service == 2) {
                    labelFirst = 'records';
                    labelSecond = 'receiving records';

                }

                let alertMessage = ``;
                alertMessage += `<p>It appears <span class="badge badge-primary">${serviceName}</span> is out of <span class="font-italic">${labelFirst}</span> until <strong>${date}</strong>. If you\'d like to activate your overconsumption feature to continue <span class="font-italic">${labelSecond}</span> until your next billing cycle, please visit your dashboard</p>`;

                this.$swal({
                    title: 'Uh, oh...',
                    customClass: "remaining-alert",
                    html: alertMessage,
                    type: 'info',
                    confirmButtonText: 'Got it!',
                    showCloseButton: true,
                })
            // },
        },
        formatSeviceData(data) {

            let options = {
                events: data.events,
                limit: data.limit ? data.limit : 0,
                remaining: data.remaining,
                contacts: data.contacts,
                records: data.records,
                percent: data.percent,
                balance: data.plan && data.plan.balance ? data.plan.balance : 0,
                plan: data.plan,
                freeAccount: (data.plan && data.plan.is_trial) || !data.plan,
                account: data.account ? data.account : null,
            }

            return options;
        },
        serviceIsActive(serviceID) {
            if (serviceID === SERVICES.LIVE_OPENS.id) {
                return this.liveOpenersSubscribed
            }
            if (serviceID == SERVICES.LIVE_LEADS.id) {
                return this.liveLeadsSubscribed
            }
        },
        loadLiveLeadsChart() {
            if (this.$refs['liveLead'] && typeof this.$refs['liveLead'].getStats == 'function') {
                helper.delay(2000).then(() => {
                    this.$refs['liveLead'].getStats();
                })
            }
        },
        isNotServicePlanTrial(service = this.LIVE_OPENS, serviceData) {
            let subscription = this.UserSubscriptions[service];
            let result = {state: typeof service.plan !== 'undefined' && !service.plan.is_trial, data: serviceData, subscription: subscription}

            return result;
        },
        accountAlreadyUsedService(serviceID) {

            if(serviceID == this.LIVE_OPENS) {
                return typeof this.subscriptionUsedLiveOpens !== 'undefined' && this.subscriptionUsedLiveOpens;
            }

            if(serviceID == this.LIVE_LEADS) {
                return typeof this.subscriptionUsedLiveLeads !== 'undefined' && this.subscriptionUsedLiveLeads;
            }
            
            return false;
        },
        fetchSubscriptionAlreadyUsed(serviceId) {
            if(this.client) {
                api.subscriptions.accountAlreadyUseService(this.user.account_id, serviceId)
                .then((response) => {
                    if (response.success && response.data) {
                        if(serviceId == this.LIVE_OPENS) {
                            this.subscriptionUsedLiveOpens = response.data;
                        }
                        if(serviceId == this.LIVE_LEADS) {
                            this.subscriptionUsedLiveLeads = response.data;
                        }

                    }
                })
                .finally(() => {
                    this.showCards();
                })
            }
            
        },
        showCards() {
            this.showCardService = false;
            this.showLiveOpenersDetails = false;
            this.showLiveLeadsDetails = false;

            setTimeout(() => {
                this.$nextTick(() => {
                    this.showCardService = true;
                    this.showLiveOpenersDetails = true;
                    this.showLiveLeadsDetails = true;
                });
            }, 1300);

            
        }
    }
}
</script>
<style>
div.remaining-alert h2.swal2-title {
    background-color: #f6386b !important;
    padding: 0.5rem !important;
    color: white;
}
.an-img-two {
    width: 700px;
    position: absolute;
    right: -35%;
    top: 0;
    z-index: -1;
}
</style>
