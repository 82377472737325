<template>
   <!--  MODAL Add Balances  -->
        <!-- START MODAL Balances  -->
        <b-row>
          <b-col lg="12" md="12">
              <b-modal  id="modal-edit-balance"  ref="update-limit-balance" hide-footer title="Update Balance's Overconsumption limit:">
                  <b-form @submit="submit" class="p-4">
                  
                  <b-form-group  id="input-group-1" label=""  description="">

                    <b-row align-h="between" class="px-3">
                <label for="input-1" >Overconsumption limit :</label>  

                <b-form-checkbox switch  v-model="is_unlimited">Unlimited</b-form-checkbox>

               
              </b-row>
 
                    
                    <b-form-input  v-if="!is_unlimited" id="input-limit" v-model="formData.limit" type="number" placeholder="Over consumption Limit" required></b-form-input>
                    <b-form-input  v-else id="input-limit" readonly placeholder="unlimited"></b-form-input>
                  </b-form-group>
             

                    <b-form-group id="input-group-1" label="Ends at:" label-for="input-1" description="">
                      <b-form-input type="date" id="input-ends_at" v-model="formData.ends_at"  class="mb-2"></b-form-input>
                      
                  </b-form-group>

                  <b-form-group id="input-group-1" label="Description :" label-for="input-1" description="">
                    <b-form-textarea rows="2" max-rows="2" id="input-description" v-model="formData.description" placeholder="Describe why the balance is updated" class="mb-2" required></b-form-textarea>
                      
                  </b-form-group>

                  <div class="text-right">
                      <b-button variant="primary" type="submit" :disabled="loading.modal"> Submit <b-spinner small type="grow" v-show="loading.modal"></b-spinner>
                      </b-button>
                  </div>
                  </b-form>

              </b-modal>
          </b-col>
      </b-row>
      <!-- END MODAL Balances  -->


</template>

<script>
import api from '@/api/RestClient';
var moment = require('moment'); 

export default {

  name:'add-balance-modal-too',
  props:['account','balance'],

  mounted() {
  },
  computed: {
    endsAt() {
      return "2022-01-01";
    }
  },
  data(){
    return {
      today_date: moment().format("YYYY-MM-DD"),
      loading: {
        modal:false
      },
      formData : {
        limit : null, 
        event_amount : null,
        description: null,
        service_id: null,
        ends_at: null
      },
      is_unlimited:false

    }
  },
  methods : {
    submit( event ) {

      event.preventDefault();

      this.loading.modal = true;

      if(this.is_unlimited) this.formData.limit = -1;
      const account_id          = this.account.id;
      this.formData.service_id  = this.balance.service_id;
      const data                = this.formData;

      api.balances.update(account_id,this.balance.id,data).then(response => {

          
          let variant = 'danger';
          let message = 'Something went wrong'

          if ('success' in response) {

              variant = response.success ? 'success' : 'danger';
              message = response.message;
              this.toast('account', variant, message);

              if (response.success){

                  this.$refs['update-limit-balance'].hide();
                  this.$emit('balanceLimitUpdated');
                  
              }
          }

          this.loading.modal = false;


      }).catch( (error) => {

          this.toast('Balance', 'error', error.response.data.message);
          this.loading.modal = false;

      });


    },
    toast(title = "success", variant = "success", message = "Organization updated successfully") {
      this.$bvToast.toast(message, {
          title: title,
          variant: variant
      })
    },
  },
  watch: {
  'balance': {
    handler(newVal, oldVal) {
      this.formData.ends_at =  newVal.ends_at;
    }
  }
  }
  
}
</script>