<template>
    <div class="iq-progress-bar-linear d-inline-block w-100 float-left mt-3">
        <b-progress :max="100" height=".6rem" data-toggle="tooltip" v-b-tooltip.hover top
            :title="remainingDays">
            <b-progress-bar :value="percent"
                :label="remainingDays"></b-progress-bar>
        </b-progress>
    </div>
</template>

<script>
export default {
    name: 'FreeAccountProgress',
    props: ['percent', 'day'],
    computed: {
        remainingDays() {
            return `${(this.day < 0 ? '0' : this.day)} day${(this.day > 1?'s':'')} left (${this.percent >= 100 ? 'Full use' : (100 - Number(this.percent)).toFixed(2)+ ' %'})`
        },
    }
}
</script>
