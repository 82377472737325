<template>
<b-container fluid>
    <b-row>
        <b-col class="checkout-page" sm="12">
            <a href="/" class="router-link-active"><img :src="require('@/assets/images/logo.png')" alt="InboxGeek" class="img-fluid checkout-logo"></a>
        </b-col>
    </b-row>
    <b-row class="justify-content-md-center">
        <b-col sm="11">
            <ul id="progressbar" class="text-center">
                <li class="active step0" id="step1" @click="back()">Plan</li>
                <li class="step0" id="step2">Payment</li>
                <li class="step0" id="step3">Status</li>
            </ul>
            <div id="cart" class="card-block show b-0">
                <b-row>
                    <b-col lg="3" v-for="(item,index) in plans" :key="index">
                        <iq-card body-class="border text-center" :class="item.name == 'STANDARD' ? 'bg-primary text-white' : ''">
                            <template v-slot:body>
                                <span class="font-size-16 text-uppercase" v-if="item.plan !== ''">{{ item.name }}</span>
                                <h2 class="mb-4 display-3 font-weight-bolder" :class="item.name == 'STANDARD' ? 'text-white' : ''">
                                    <span v-if="item.price == 0">FREE</span>
                                    <span v-if="item.price > 0">${{ item.price }}</span>
                                    <small class="font-size-14 " :class="item.name == 'STANDARD' ? 'text-white' : 'text-muted'" v-if="item.price > 0">Month</small>
                                </h2>
                                <h3 class="font-size-13" :class="item.name == 'STANDARD' ? 'text-white' : 'text-muted'">{{item.balance}} <span class="font-size-12">events</span></h3>
                                <ul class="list-unstyled line-height-4 mb-0">
                                    <li><i class="fa fa-check"></i> Free Integration Onboarding</li>
                                    <li><i class="fa fa-check"></i> User Management Tools</li>
                                    <li><i class="fa fa-check"></i> Daily Usage Management</li>
                                    <li><i class="fa fa-check"></i> Premier Account Management Support</li>
                                    <li><i class="fa fa-check"></i> Exclusive Access to Upcoming Beta Features</li>
                                    <li><i class="fa fa-check"></i> Discount on Email Management Services</li>
                                </ul>
                                <b-button v-if='item.price !=0' :variant="item.active ? 'light' : 'primary'" class="mt-5 " :class="item.buttonClass" @click="choosePlan(item,item.price == 0)">Choose
                                </b-button>
                                <b-button v-else :variant="item.active ? 'light' : 'primary'" class="mt-5 " :class="item.buttonClass" @click="paymentProcessusWhitoutCard(item)">Choose
                                </b-button>
                            </template>
                        </iq-card>
                    </b-col>
                </b-row>
                <div class="row justify-content-center">
                    <div class="col-sm-8 text-center">
                        <p>Need more than 50,000 events a month? Schedule a call for details <a href="#">InboxGeek Enterprise Call - Team InboxGeek</a> or email support@inboxgeek.com</p>
                    </div>
                </div>
            </div>
            <div id="address" class="card-block b-0">
                <b-row class="justify-content-md-center">
                    <b-col lg="8">
                        <iq-card>
                            <template v-slot:headerTitle>
                                <h4 class="card-title">Address</h4>
                            </template>
                            <template v-slot:body>
                                <form onsubmit="required()">
                                    <div class="row mt-3">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="fname">First Name: *</label>
                                                <input type="text" class="form-control" required="" v-model='address.first_name'>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="fname">Last Name: *</label>
                                                <input type="text" class="form-control" required="" v-model='address.last_name'>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mt-3">
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label for="fname">Address: *</label>
                                                <textarea name="address" class="form-control" style="width: 100%;" v-model='address.address'></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                <div class="row mt-3">
                                    <div class="col-md-12">
                                        <label for="fname">Card: *</label>
                                        <div id="card"></div>
                                    </div>
                                </div>
                                <div class="row mt-3" v-show="false">
                                    <div class="col-md-12">
                                        <div class="custom-control custom-checkbox custom-control-inline">
                                            <input type="checkbox" class="custom-control-input" @change='acceptTerms=!acceptTerms' id="acceptTerms">
                                            <label class="custom-control-label" for="acceptTerms">By clicking this checkbox, you accept InboxGeek's <a href="#">Terms Of Use</a>.</label>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </iq-card>
                    </b-col>
                    <b-col lg="4">
                        <iq-card>
                            <template v-slot:body>
                                <p>Order</p>
                                <div class="d-flex justify-content-between">
                                    <span>Coupons</span>
                                    <span><a href="#"><strong>Apply</strong></a></span>
                                </div>
                                <hr>
                                <p><b>Plan Details</b></p>
                                <div class="d-flex justify-content-between">
                                    <span>Plan type</span>
                                    <span>{{plan.name}}</span>
                                </div>
                                <div class="d-flex justify-content-between">
                                    <span>Balance</span>
                                    <span class="text-success">{{plan.balance}}</span>
                                </div>
                                <div class="d-flex justify-content-between">
                                    <span>Interval</span>
                                    <span><a href="#">{{plan.interval}}</a></span>
                                </div>
                                <hr>
                                <div class="d-flex justify-content-between">
                                    <span class="text-dark"><strong>Total</strong></span>
                                    <span class="text-dark"><strong>${{plan.price}}</strong></span>
                                </div>
                                <br>
                                <div class="row">
                                    <div v-bind:class="is_free ? 'col-md-6' : 'col-md-12'">
                                        <a id="place-order" type="button" class="btn btn-primary d-block mt-1 next" @click="paymentProcessus()" :class="{disabled:acceptTerms}">Place order</a>
                                    </div>
                                </div>
                            </template>
                        </iq-card>
                    </b-col>
                </b-row>
                <div class="spiner" v-if='loading'>
                    wait please ...
                </div>
            </div>
            <div id="payment" class="card-block b-0">
                <b-row>
                    <b-col lg="12">
                        <iq-card>
                            <template v-slot:headerTitle>
                                <h4 class="card-title">Payment successful</h4>
                            </template>
                            <template v-slot:body>
                                <div class="d-flex justify-content-between align-items-center">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <span><i class="fa fa-check-square plan-successful-shoosed"></i>&nbsp;you have been successfully choose your plan</span>
                                    </div>
                                    <span>{{address.first_name}} {{address.last_name}}</span>
                                </div>
                                <br>
                                <p>Thank you for your recent purchase. You are the reason why we do what we do. We know you have many options and sincerely thank you for choosing us. </p><br>
                                <a class="btn btn-primary mt-3" href="/"><i class="ri-home-4-line"></i>Back to Home</a>
                            </template>
                        </iq-card>
                    </b-col>
                </b-row>
            </div>
        </b-col>
    </b-row>
</b-container>
</template>

<script>
import {
    sofbox
} from '../../../config/pluginInit'
import {
    loadStripe
} from '@stripe/stripe-js'
import axios from 'axios'

export default {
    data() {
        return {
            plans: [],
            address: [],
            paymentMethod: '',
            cardElement: null,
            plan: [],
            is_free: false,
            acceptTerms: true,
            loading: false
        }
    },
    name: 'checkout',
    mounted() {
        if (this.$store.getters['Auth/isSubscribed']
             && this.$store.getters['Auth/loggedIn'] 
            || this.$store.getters['Auth/isAdmin'])
            this.$router.push('/')

        sofbox.index();
        let name = this.$store.getters['Auth/user'].name.split(' ');
        this.address = {
            first_name: name[0],
            last_name: name[1],
            address: this.$store.getters['Auth/user'].address,
        }
        axios.get(process.env.VUE_APP_BASE_URL + '/api' + '/plans/get', {
            headers: {
                'Authorization': `Bearer ${this.$store.getters['Auth/token']}`
            }
        }).then(resp => {
            let data = resp.data;
            if (data.success)
                this.plans = data.data;
        });

    },
    methods: {
        async paymentProcessusWhitoutCard(plan) {
            this.loading = true;
            axios.post(process.env.VUE_APP_BASE_URL + '/api' + '/free_checkouts', {
                    plan: plan.stripe_plan,
                    balance: plan.balance,
                    address: {
                        first_name: this.address.first_name,
                        last_name: this.address.last_name,
                        address: this.address.address,
                    },
                }, {
                    headers: {
                        'Authorization': `Bearer ${this.$store.getters['Auth/token']}`
                    }
                })
                .then((response) => {
                    if (response.data.success) {
                        this.loading = false;
                        this.$store.dispatch('Auth/updateIsSubscribed', true);
                        this.$router.push({
                            name: 'dashboard1.home'
                        })
                    }
                })
                .catch(error => {
                    if (error.response) {
                        if (error.response.status == 401) {
                            this.$store.dispatch('resetUser', false);
                            this.$router.go(this.$router.currentRoute)
                        } else if (error.response.status == 500) {
                            alert('Oops, something went wrong!  The team have been notified.');
                        }
                    }

                    this.$bvToast.toast('something went wrong!', {
                        title: 'Error',
                        variant: 'danger'
                    })
                });
        },
        async paymentProcessus() {
            const cardHolderName = document.getElementById('card-holder-name');
            const {
                paymentMethod,
                error
            } = await this.stripe.createPaymentMethod(
                'card', this.cardElement, {
                    billing_details: {
                        name: this.address.first_name + ' ' + this.address.last_name,
                        address: {
                            line1: this.address.address
                        }
                    }
                }
            );

            if (paymentMethod) {
                this.loading = true;
                axios.post(process.env.VUE_APP_BASE_URL + '/api' + '/checkouts', {
                        paymentMethodId: paymentMethod,
                        plan: this.plan.stripe_plan,
                        balance: this.plan.balance,
                        address: {
                            first_name: this.address.first_name,
                            last_name: this.address.last_name,
                            address: this.address.address,
                        },
                    }, {
                        headers: {
                            'Authorization': `Bearer ${this.$store.getters['Auth/token']}`
                        }
                    })
                    .then((response) => {
                        if (response.data.success) {
                            $('#address').removeClass('show')
                            $('#payment').addClass('show')
                            $('#step2').removeClass('active')
                            $('#step2').addClass('done')
                            $('#step3').addClass('active')
                            this.$store.dispatch('updateIsSubscribed', true);
                        }
                    })
                    .catch(error => {
                        if (error.response) {
                            if (error.response.status == 401) {
                                this.$store.dispatch('resetUser', false);
                                this.$router.go(this.$router.currentRoute)
                            } else if (error.response.status == 500) {
                                alert('Oops, something went wrong!  The team have been notified.');
                            }
                            this.$data.message = 'Error';
                            this.$data.alert = true;
                            this.$bvToast.toast('something went wrong!', {
                                title: 'Error',
                                variant: 'danger'
                            })
                        }
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            } else {
                console.log('Error')
            }
        },
        choosePlan(plan, free = null) {
            this.is_free = free;
            this.initcard();

            if (plan) {
                this.plan = plan
                $('#cart').removeClass('show')
                $('#address').addClass('show')
                $('#step1').removeClass('active')
                $('#step1').addClass('done')
                $('#step2').addClass('active')
            }
        },
        async initcard() {
            this.stripe = await loadStripe(process.env.VUE_APP_STRIPESECRET);
            const elements = this.stripe.elements()
            this.cardElement = elements.create('card')
            this.cardElement.mount('#card');
        },
        back() {
            $('#cart').addClass('show')
            $('#address').removeClass('show')
            $('#step1').addClass('active')
            $('#step1').removeClass('done')
            $('#step2').removeClass('active')
        }
    },
    computed: {

    }
}
</script>

<style lang="scss" scoped>
.checkout-logo {
    max-width: 320px;
}
</style>
