<template>
<fieldset>
    <div class="form-card text-left">
        <b-form @submit="onSubmit">
            <b-row>

                <b-form-group class="col-md-12" label="Name: *" label-for="integration-name">
                    <b-form-input v-model="form.integrationName" id="integration-name" name="integration-name" placeholder="Enter Integration Name">
                    </b-form-input>
                    <small id="alertName" class="text-danger d-none"></small>
                </b-form-group>

                <!-- START | Custom Event trigger - ExpertSender -->
                <b-form-group class="col-md-6" label="Custom Event ID: *" label-for="name" v-show="showCols.event">
                    <b-form-input v-model="form.CustomEventId" id="tag" name="tag" placeholder="Enter Name">
                    </b-form-input>
                    <small id="alertEventID" class="text-danger d-none"></small>
                </b-form-group>

                <b-form-group class="col-md-6" label="Custom Event Name: *" label-for="name" v-show="showCols.event">
                    <b-form-input v-model="form.CustomEventName" id="tag" name="tag" placeholder="Enter Name">
                    </b-form-input>
                    <small id="alertEventName" class="text-danger d-none"></small>
                </b-form-group>
                <!-- END | Custom Event trigger - ExpertSender -->

                <!-- SendinBlue Platform -->
                <b-form-group class="col-md-12" label="Custom Track Event: *" label-for="name" v-show="showCols.sendin_blue_event">
                    <b-form-input v-model="form.sendin_blue_event" id="tag" name="tag" placeholder="Custom Track Event">
                    </b-form-input>
                    <small id="alertEventName" class="text-danger d-none"></small>
                </b-form-group>

                <!-- UseInbox Platform -->
                <b-form-group class="col-md-12" :label="customTagLabel" label-for="name" v-show="showCols.customTag">
                    <b-form-input v-model="form.customTag" name="tag" :placeholder="customTagPlaceHolder">
                    </b-form-input>
                    <small id="alertTag" class="text-danger d-none"></small>
                </b-form-group>

                <!-- START | Custom Event trigger - Webhook or InboxSuite -->
                <b-form-group class="col-md-12" :label="tagLabel" label-for="trigger_link" v-if="tagSelected && showCols.event_link">
                    <b-form-input v-model="tagSelected.name" id="trigger_link" name="trigger_link" :placeholder="tagPlaceholderProp">
                    </b-form-input>
                    <small id="alertTag" class="text-danger d-none"></small>
                </b-form-group>
                <!-- END | Custom Event trigger - Webhook or InboxSuite -->

                <b-form-group class="col-md-12 mb-3" :label="tagLabel" label-for="tag" v-show="showCols.tags">
                    <div>
                        <multiselect v-model="tagSelected" :loading="isLoading" :tag-placeholder="tagPlaceholder" :allow-empty="false" deselect-label="Can't remove this value" :placeholder="selectTagPlaceholder" label="name" track-by="code" return="code" :options="tags" :multiple="false" :taggable="true" @tag="handleAddTag" :disabled="isDisabledTag">

                            <template slot="singleLabel" slot-scope="props">
                                <div class="option__desc">

                                    <span class="option__title">

                                        <span v-if="props.option.type">
                                            <i v-if="props.option.type == 'segment'" class="ri-flashlight-line" style="color:#5ad"></i>
                                            <i v-else class="ri-list-check color-primary" style="color:#5ad"></i>
                                        </span>
                                        <span v-else>
                                            <i class="ri-list-check color-primary" style="color:#5ad"></i>
                                        </span>

                                        <span style="font-wight:bold;"> {{ props.option.name }} </span>

                                        <span style="font-size:11px; color:#888"> {{ props.option.code }}</span>

                                    </span>

                                </div>
                            </template>

                            <template slot="option" slot-scope="props">
                                <div class="option__desc">

                                    <span class="option__title">

                                        <span v-if="props.option.type">

                                            <i v-if="props.option.type == 'segment'" class="ri-flashlight-line" style="color:#5ad"></i>
                                            <i v-else class="ri-list-check color-primary" style="color:#5ad"></i>

                                        </span>

                                        <span v-else><i class="ri-list-check color-primary" style="color:#5ad"></i></span>

                                        <span style="font-wight:bold;"> {{ props.option.name }} </span>

                                        <span style="font-size:11px; color:#888"> {{ props.option.code }}</span>

                                    </span>

                                </div>

                            </template>

                        </multiselect>
                        <small id="alertTag" class="text-danger d-none"></small>
                    </div>
                </b-form-group>

                <div class="spinnerAddTag" v-show="spinnerAddTag">
                    <b-spinner small label="Small Spinner" type="grow"></b-spinner>
                </div>
                <b-form-group class="col-md-12">
                    <b-row>
                        <b-col :md="showReccurence ? 6 : 12" class="mb-3">
                            <div class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color">
                                <div class="custom-switch-inner">
                                    <label class="mr-2">Limit per day</label>
                                    <input type="checkbox" class="custom-control-input bg-primary" v-model="form.ableLimitPerDay" id="limit-per-day" @input="onSwitchLimitPerDay($event.target)">
                                    <label class="custom-control-label" for="limit-per-day" data-on-label="On" data-off-label="Off">
                                    </label>
                                </div>
                            </div>
                            <b-form-input v-model="limit" type="number" id="limit" name="limit" placeholder="limit per day" :disabled="!form.ableLimitPerDay"></b-form-input>
                        </b-col>
                        <b-col :md="showReccurence ? 4 : 6" v-show="false">
                            <b-row>
                                <b-col md="12">
                                    <div class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color">
                                        <div class="custom-switch-inner">
                                            <label class="mr-2">Time limit</label>
                                            <input type="checkbox" class="custom-control-input bg-primary" v-model="form.ableLimitTime" id="limit-limit" @input="onSwitchTimeLimit($event.target)">
                                            <label class="custom-control-label" for="limit-limit" data-on-label="On" data-off-label="Off">
                                            </label>
                                        </div>
                                    </div>
                                </b-col>
                                <b-col md="6" class="mb-3">
                                    <b-form-input v-model="form.time_limit_from" id="time_limit_from" :type="timeType" value="13:45" placeholder="time from" :disabled="!form.ableLimitTime"></b-form-input>
                                    <small v-show="timeFromAlert" class="text-danger">This field is required in its
                                        correct
                                        time
                                        format</small>
                                </b-col>
                                <b-col md="6" class="mb-3">
                                    <b-form-input v-model="form.time_limit_to" id="time_limit_to" :type="timeType" value="13:45" placeholder="time to" :disabled="!form.ableLimitTime"></b-form-input>
                                    <small v-show="timeToAlert" class="text-danger">This field is required in its
                                        correct time
                                        format</small>
                                </b-col>
                            </b-row>
                        </b-col>
                        <b-col :md="6" v-if="showReccurence">
                            <div class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color">
                                <div class="custom-switch-inner">
                                    <label class="mr-2">Repeat Unique Events</label>
                                    <input type="checkbox" class="custom-control-input bg-primary" v-model="integration.recurrence" id="customSwitch-23" @input="onSwitchRepeatEvent($event.target)">
                                    <label class="custom-control-label" for="customSwitch-23" data-on-label="On" data-off-label="Off">
                                    </label>
                                </div>
                            </div>
                            <b-form-input v-model="form.recurrence" type="number" id="recurrence" name="recurrence" placeholder="Recurrence Events" :disabled="!integration.recurrence"></b-form-input>
                        </b-col>
                    </b-row>
                </b-form-group>

            </b-row>

            <b-row>
                <b-col md="12">
                    <b-form-group>
                        <b-button type="submit" class="next action-button float-right" variant="primary">
                            <b-spinner small type="grow" v-show="nextSpinner"></b-spinner>
                            Submit
                        </b-button>
                    </b-form-group>
                </b-col>
            </b-row>
        </b-form>
    </div>
</fieldset>
</template>

<script>
import {
    PLATFORMS_CODE
} from "@/constantes";
import Multiselect from 'vue-multiselect'
import api from '@/api/RestClient'
import _ from 'lodash';

export default {
    name: 'StepIntegration',
    components: {
        Multiselect,
    },
    props: {
        tagLabelProp: {
            default: () => 'Tag: *',
        },
        tagPlaceholderProp: {
            default: () => 'Create tag',
        },
        selectTagPlaceholderProp: {
            default: () => 'Select tag',
        },

        formProp: {
            type: [Object],
            default: () => null,
        },
        tagProp: {
            type: [Array],
            default: () => null,
        },
        tagsProp: {
            type: [Array],
            default: () => null,
        },
        showColsProp: {
            type: [Object],
            default: () => null,
        },
    },
    data() {
        return {
            step: 2,
            isLoading: true,
            timeType: 'text',

            showReccurence: false,
            isDisabledTag: false,
            spinnerAddTag: false,
            timeFromAlert: false,
            timeToAlert: false,

            form: {},
            limit: null,
            integration: {
                time_limit: {
                    time_limit_from: null,
                    time_limit_to: null
                },
                limit: -1,
                recurrence: 0
            },
            tagSelected: null,
            tags: [],
            selectedtag: {},
            customTagLabel: 'Tag: *',
            customTagPlaceHolder: 'Tag Tracked',
            tagLabel: 'Tag: *',
            tagPlaceholder: 'Create Tag',
            selectTagPlaceholder: "Select or create Tag",
            showCols: {
                fields:false,
                accountId: false,
                apiKey: true,
                groupCol: false,
                auth2Btn: false,
                tags: true,
                event: false,
                sendin_blue_event: false,
                event_link: false,
                customTag: false
            },

            nextSpinner: false,
            prevSpinner: false,
            platformName: ''
        }
    },
    methods: {
        initStep() {
            if (typeof this.formProp !== "undefined") this.form = this.formProp
            if (typeof this.tagProp !== "undefined") this.tagSelected = this.tagProp
        },
        updateRender() {
            if (this.form.platform && this.form.platform.code) {
                // For [Klaviyo - Maropost - Active Campaign - Webhook]
                if ([PLATFORMS_CODE.KLAVIYO, PLATFORMS_CODE.MAROPOST, PLATFORMS_CODE.ACTIVE_CAMPAIGN, PLATFORMS_CODE.WEBHOOK].includes(this.form.platform.code)) {
                    this.showReccurence = true
                }
            }
        },
        cleanForm() {
            this.nextSpinner = false
            this.tagSelected = null
            this.tags = []
            this.form.integrationName = null
            this.form.ableLimitTime = null
            this.form.ableLimitPerDay = null
            this.integration.recurrence = null
            this.cleanTimeLimit()
        },
        cleanTimeLimit() {
            this.timeType = "text"
            this.form.time_limit_from = null
            this.form.time_limit_to = null
        },
        handleSelectTagOption(itemSelected) {
            this.$emit('onTagSelectedOption', itemSelected)
        },
        handleAddTag(newTag) {
            this.isLoading = true;
            let resource = {};
            let title = 'Warning';
            let message = 'Something wrong';
            let alert = 'error';
            let data = {
                tag: newTag, platform_id: this.form.platform_recorded.id, list_id: this.form.list.code, list_uid:  this.form.list.code
            }
            let url = `platforms/${this.platformName}/tags`
            return api.platforms.createTag(url, data)
                .then(result => {
                    title = 'Success';
                    alert = 'success';
                    message = result.data.message ?? 'Tag created successfully';
                    switch (this.platformName) {
                        case 'klaviyo':
                            resource = {
                                code: newTag,
                            }
                            break;
                        case 'sendlane':
                            resource = {
                               code: result.data.data.id,
                            }
                            break;
                        case 'emailoctopus':
                            resource = {
                                code: result.data.list_id,
                            }
                            break;

                        case 'getresponse':
                            resource = {
                                code: result.data.tagId,
                            }
                            break;

                        case 'ontraport':
                            resource = {
                                code: result.data.data.tag_id,
                            }
                            break;
                        case 'hubspot':
                            resource = {
                                code: result.data.portalId,
                            }
                        case 'blastable':
                            resource = {
                                code: result.data.general.list_uid,
                            }
                            break; 
                            
                        default:
                           resource = {
                                code: result.data.id,
                            }
                            break;
                    }
                    resource.name = newTag;
                    this.tagSelected = resource;
                    this.tags.push(resource)
                })
                .catch(error => {}) 
                .finally(() => {
                    this.isLoading = false;
                    this.$swal(title, message, alert);
                })
        },
        setTags(items) {
            if (Array.isArray(items)) this.tags = items
        },

        onSwitchLimitPerDay($event) {
            if (!$event.checked) {
                this.form.limitPerDay = null
                this.form.limit = null
            } else this.form.limit = 0
        },
        onSwitchTimeLimit($event) {
            if (!$event.checked) {
                this.cleanTimeLimit()
            } else this.timeType = "time"
        },
        onSwitchRepeatEvent($event) {
            if (!$event.checked) {
                this.form.recurrence = null
                this.integration.recurrence = null
            } else {
                this.form.recurrence = 0
                this.integration.recurrence = true
            }
        },
        setLimitTime(integration, form) {
            var timeLimit = JSON.parse(integration.time_limit)
            if (helper.validateTime(timeLimit.time_limit_to) && helper.validateTime(timeLimit.time_limit_from)) {
                form.timelimitchecked = true
                form.time_to = timeLimit.time_limit_to;
                form.time_from = timeLimit.time_limit_from;
            }
        },
        getTags() {

            let form = this.form;
            if (this.limit) form.limit = his.limit;
            let platformName = (form.platform_recorded.name).toLowerCase();
            let platformId = form.platform_recorded.id;
            let listId = `list_ids=${this.form.list.code}`;
            if ( ['aweber'].includes(platformName)) {
                listId = `list_id=${this.form.list.code}`;
            }
            if (['listrak'].includes(platformName)) {
                listId = `listId=${this.form.list.code}`;
            }
            
            let url = `platforms/${platformName}/tags?token=***&platform_id=${platformId}&platform=23&entities=tags&${listId}`

            return api.platforms.tags(url)
                .then(result => {
                    let resource = {};
                    let data = result.data;
                    if (platformName == 'convertkit') data = result.data.tags;
                    if (platformName == 'blastable') data = result.data.data.records;
                    let tags = _.map(data, function (tag) {
                        if (platformName == 'ontraport') {
                            resource = {
                                code: tag.tag_id,
                                name: tag.tag_name
                            }
                        } else if (platformName == 'getresponce') {
                            resource = {
                                code: tag.tagId,
                                name: tag.tag_name
                            }
                        } else if (platformName == 'emailoctopus') {
                             resource = {
                                code: tag.list_id,
                                name: tag.tag
                            }
                        } else if (platformName == 'getresponse') {
                            resource = {
                                code: tag.tagId,
                                name: tag.name
                            }
                        } else if (platformName == 'activecampaign') {
                            resource = {
                                code: tag.id,
                                name: tag.tag
                            }
                        } else if (platformName == 'blastable') {
                            resource = {
                                code: tag.general.list_uid,
                                name: tag.general.name
                            }
                        }else {
                            resource = {
                                code: tag.id,
                                name: tag.name
                            }

                        }
                        return resource
                    });
                    this.tags = tags;
                })
                .catch(function (error) {})
                .finally(() => {
                    this.isLoading = false;
                });
        },
        manageCols() {
            let platformName = (this.form.platform_recorded.name).toLowerCase();
            this.platformName = platformName;
            switch (platformName) {
                case 'sendgrid':
                case 'blastable':
                case 'campaigner':
                case 'hubspot':
                case 'listrak':
                    this.tagLabel = "List: *";
                    this.tagPlaceholder = "Create List";
                    this.selectTagPlaceholder = "Select List";
                    break;
                case 'expertsender':
                    this.showCols.event = true;
                    this.showCols.tags = false;
                    this.showCols.customTag = false;
                    break;
                case 'inboxsuite':
                    this.showCols.tags = false;
                    this.customTagLabel = "Campaign ID *";
                    this.customTagPlaceHolder = "Campaign ID";
                    this.showCols.customTag = true;
                    break;
                case 'klaviyo':
                    this.tagLabel = "Event: *";
                    this.tagPlaceholder = "Create Event";
                    this.selectTagPlaceholder = "Select Event";
                    break;
                case 'sendinblue':
                    this.showCols.tags = false;
                    this.showCols.customTag = false;
                    this.showCols.sendin_blue_event = true;
                    break;
                case 'useinbox':
                    this.showCols.tags = false;
                    this.showCols.customTag = true;
                    this.showCols.sendin_blue_event = false;
                    break;

                default:
                    break;
            }
        },
        onSubmit(e) {
            e.preventDefault();
            this.nextSpinner = true;
            if ('integrationName' in this.form) this.integration.name = this.form.integrationName;
            let platformName = (this.form.platform_recorded.name).toLowerCase();
            if (this.limit) this.form.limit = this.limit;
            if (platformName == 'useinbox') {
                this.integration.tag_name = this.form.customTag;
            } else if (platformName == 'sendinblue') {
                this.integration.tag_name = this.form.sendin_blue_event;
            } else if (platformName == 'expertsender') {
                this.integration.tag_name = this.form.CustomEventName;
                this.integration.tag_id = this.form.CustomEventId;
            } 
            else if (platformName == 'inboxsuite') {
                this.integration.tag_name = this.form.customTag;
                this.integration.tag_id = this.form.customTag;
            } else {
                this.integration.tag_name = this.tagSelected.name;
                this.integration.tag_id = this.tagSelected.code;
            }
            if ('recurrence' in this.form) this.integration.recurrence = this.form.recurrence;
            if ('limit' in this.form && this.form.limit != null) this.integration.limit = this.form.limit;
            if ('time_limit_from' in this.form && 'time_limit_to' in this.form) this.integration.time_limit = {
                time_limit_from: this.form.time_limit_from,
                time_limit_to: this.form.time_limit_to
            };
            let dataIntegration = {
                organizationId: this.form.organization,
                integration: this.integration,
                list: [this.form.list],
                platform: this.form.platform_recorded.id
            }
            let title = 'Error!';
            let alert = 'danger!';
            let message = '';
            var bodyFormData = new FormData();
            bodyFormData.append('data', JSON.stringify(dataIntegration));
            api.integrations.post(bodyFormData)
                .then((resp) => {
                    var responseData = resp.data;
                    if (responseData.id) {
                        title = 'Success!';
                        alert = 'success';
                        message = 'Your integration is turned on and will begin sending events once contacts have synced. If your email platform automation is not ready, turn off this integration now. To learn more, click <a target="_blank" href="https://help.inboxgeek.com/your-inboxgeek-account/#1679424669960-cc4ec555-bc63">here</a>'
                    }
                })
                .catch((error) => {
                    if (error.response.data.message) message = error.response.data.message
                })
                .finally(() => {
                    this.nextSpinner = false;
                    this.$swal(title, message, alert);
                    this.$emit('closeIntegrationModal');
                })
        },


    },
    watch: {},
    mounted() {
        this.initStep()
        this.getTags();
        this.manageCols();
        delete this.form['limit'];
    },
    updated() {
        this.updateRender()
    },
}
</script>
