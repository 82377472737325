<template>
  <!-- TOP Nav Bar -->
  <div class="iq-top-navbar mb-4">
    <div class="iq-navbar-custom" style="background: #161e32; color: white !important; box-shadow: 0 0 29px 11px #6d82a9a3;">
      <HeaderLoginAs />
      <AnnouncementNav />
      <div class="iq-sidebar-logo px-2 my-auto py-0">
        <div class="top-logo">
          <router-link :to="homeURL">
            <img :src="logo" class="img-fluid" alt="logo">
          </router-link>
        </div>
      </div>
      <div class="navbar-breadcrumb">
        <h5 class="mb-0 text-white">{{ title }}</h5>
      </div>
      <nav class="navbar navbar-expand-lg navbar-light p-0">
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        </button>
        <b-navbar-toggle target="nav-collapse">
          <i class="ri-menu-3-line"></i>
        </b-navbar-toggle>
        <div class="iq-menu-bt align-self-center mr-0">
          <div class="wrapper-menu">
            <div class="line-menu half start"></div>
            <div class="line-menu"></div>
            <div class="line-menu half end"></div>
          </div>
        </div>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav ml-auto navbar-list">

            <!-- Chat Notification -->
            <ChatNotifications v-if="isAdmin"></ChatNotifications>

            <!-- App Notification -->

            <TicketNotifications v-if="isAdmin"></TicketNotifications>

            <Notifications></Notifications>

            <li class="nav-item">
              <p>&nbsp;</p>
            </li>
            <!-- <li class="nav-item iq-full-screen"><a href="#" class="iq-waves-effect" id="btnFullscreen"><i class="ri-fullscreen-line"></i></a></li> -->
          </ul>
        </div>
        <!-- <b-collapse id="nav-collapse" is-nav>
          <slot name="responsiveRight" />
        </b-collapse> -->
        <slot name="right" />
      </nav>
    </div>
  </div>
  <!-- TOP Nav Bar END -->
</template>
<script>
import BreadCrumbStyle1 from '../breadcrumbs/BreadCrumbStyle1'
import Notifications from '@/components/notifications/Notifications.vue'
import ChatNotifications from '@/components/notifications/ChatNotifications.vue'
import HeaderLoginAs from '@/components/inboxgeek/alerts/HeaderLoginAs'
import AnnouncementNav from '@/components/inboxgeek/AnnouncementNav'
import TicketNotifications from '@/components/notifications/TicketNotifications.vue'
import {mapGetters} from 'vuex'

// homeURL: { type: Object, default: () => ({ name: 'layout1.dashboard' }) },
export default {
  name: 'NavBarStyle1',
  props: {
    homeURL: { type: Object, default: () => ({ name: 'dashboard1.home' }) },
    title: { type: String, default: 'Dashboard' },
    breadCrumb: {
      type: Array,
      default: () => ([
        {
          text: 'Home',
          href: '#'
        },
        {
          text: 'Library',
          href: '#'
        },
        {
          text: 'data',
          active: true
        }
      ])
    },
    logo: { type: String, default: require('../../../assets/images/logo.png') }
  },
  computed: {
    ...mapGetters({
        isAdmin: 'Auth/isAdmin',
      isClient: 'Auth/isClient',
    }),
  },
  components: {
    BreadCrumbStyle1,
    Notifications,
    HeaderLoginAs,
    AnnouncementNav,
    ChatNotifications,
    TicketNotifications,
  },
  data() {
    return {
    }
  }
}
</script>
