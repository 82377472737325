<template>
  <b-container fluid>
    <b-modal id="editLoDailyLimitAutoUpdateWizardModal" ref="editLoDailyLimitAutoUpdateWizardModal" title="Edit LiveOpens Daily Limit Auto Update Logic" size="xl" centered hide-footer :no-close-on-backdrop="true">

      <AddLoDailyLimitAutoUpdateWizardModalNav :steps="steps" :current_step_index="current_step_index"></AddLoDailyLimitAutoUpdateWizardModalNav>

      <b-row>
        <b-col v-if="renderWizard" lg="12" sm="12" class="p-4">
            <div v-if="currentStep">
              <component
                  :account="account"
                  :is="currentStep.component"
                  :form="form"
                  :lists="lists"
                  :loadingProp="loading"
                  @back="handelBackEvent"
                  @cancel="handelCancelEvent"
                  @next="nextStep"
                  @finish="finishWizard"
                  @preloadLists="handelPreloadListsEvent"

              />
            </div>

        </b-col>
      </b-row>
    </b-modal>
  </b-container>
</template>


<script>

import FinishLoDailyLimitAutoUpdateWizardModal from '@/components/inboxgeek/modals/Wizards/LoDailyLimitAutoUpdate/steps/FinishLoDailyLimitAutoUpdateWizardModal.vue';
import LogicLoDailyLimitAutoUpdateWizardModal from '@/components/inboxgeek/modals/Wizards/LoDailyLimitAutoUpdate/steps/LogicLoDailyLimitAutoUpdateWizardModal.vue';
import OptionsLoDailyLimitAutoUpdateWizardModal from '@/components/inboxgeek/modals/Wizards/LoDailyLimitAutoUpdate/steps/OptionsLoDailyLimitAutoUpdateWizardModal.vue';
import AddLoDailyLimitAutoUpdateWizardModalNav from '@/components/inboxgeek/modals/Wizards/LoDailyLimitAutoUpdate/AddLoDailyLimitAutoUpdateWizardModalNav.vue';
import api from "@/api";

export default {
  name:'CreateLoDailyLimitAutoUpdateWizardModal',
  props:['account', 'item'],
  components:{
    FinishLoDailyLimitAutoUpdateWizardModal,
    LogicLoDailyLimitAutoUpdateWizardModal,
    OptionsLoDailyLimitAutoUpdateWizardModal,
    AddLoDailyLimitAutoUpdateWizardModalNav
  },
  data(){
    return{
      current_step_index:0,
      form:{},
      lists:[],
      loading:false,
      steps:[{
        index:0,
        title:'Logic Information',
        name:'logic',
          component:LogicLoDailyLimitAutoUpdateWizardModal.name,
        icon:'ri-profile-line'
      },{
        index:1,
        title:'Conditions',
        name:'options',
        component:OptionsLoDailyLimitAutoUpdateWizardModal.name,
        icon:'ri-file-list-line'
      },{
        index:2,
        title:'Summary',
        name:'finish-step',
        component:FinishLoDailyLimitAutoUpdateWizardModal.name,
        icon:'ri-check-fill'
      }
      ],
      renderWizard: true

    }
  },
  mounted() {

    this.$root.$on('bv::show::modal', this.onModalShow);
    
  },
  methods:{
    onModalShow(event){

      if(this.item) {
        
        let formInitial = {
          code: this.item.id,
          name: this.item.name,
          valuesOptions: []
        }


        // TODO loop on values array
        for (let key in this.item.values) {
          let valueItem = {
            isDefault: typeof this.item.values[key].is_default !== 'undefined' && this.item.values[key].is_default, 
            value: this.item.values[key].value, 
            options: [],
            code: this.item.values[key].id,
          };
          const valueItemOptions = _.filter(this.item.options, o => o.lo_daily_limit_auto_update_value_id == this.item.values[key].id);

          // TODO found options for each value loop
          
          valueItem.options = _.map(valueItemOptions, (optionData) => {
              let option = { 
                option_name: optionData.option_name, 
                min: optionData.min, 
                max: optionData.max, 
                code: optionData.id 
              }
              return option
          })

          formInitial.valuesOptions.push(valueItem);
        }


        this.form.initial = formInitial;


        this.renderWizard = false;
        this.$nextTick(() => {
          this.renderWizard = true;
        })


      }
      
    },
    nextStep(data){

      // save emitted data into the form

      if(data){
        this.form[this.steps[this.current_step_index].name] = data;

      }

      this.current_step_index++;

    },

    handelBackEvent(){

      // remove current step data if exist:

      if(this.form[this.steps[this.current_step_index].name]){
        delete this.form[this.steps[this.current_step_index].name];
      }

      this.current_step_index--;
    },

    handelPreloadListsEvent(platform){

      this.loading=true;
      // fetch lists directly after selecting platform ( saves time to user in the upcoming steps )
      this.lists = [];

      let params = {
        platform_name : platform.name.toLowerCase(),
        entities      : 'lists',
        platform_id   : platform.id
      };

      api
          .platforms
          .fetchServices(params)
          .then((response)=>{
            this.loading=false;
            this.lists = response.data;
          }).catch(error=>{
            this.loading = false;
      });
    },

    finishWizard(){

      // after user confirms data form , send create request, returns response and close the modal.
        this.loading = true;

        let formData = {
          name: this.form.logic.name, 
          options: this.form.options.options, 
          valuesDeleted: this.form.options.valuesDeleted, 
          optionsDeleted: this.form.options.optionsDeleted, 
          account: this.isAdmin ? this.account : null
        };

        api.loDailyLimitAutoUpdates.put(this.item.id, formData)
            .then((response)=>{

                this.loading = false;

                this.$swal('success', 'LiveOpens Daily Limit Auto Update successfully updated', 'success');
                this.$emit('LoDailyLimitAutoUpdateUpdated',  response.data);
                this.handelCancelEvent();


            }).catch((error)=>{
              this.loading = false;
              let message = 'Something went wrong!';

              if (error.response && error.response.status !== 500) {
                message = error.response.data.message
              }
              
              this.$swal('error', message, 'error');

        });
    },

    handelCancelEvent(){
      this.form = {};
      this.$refs['editLoDailyLimitAutoUpdateWizardModal'].hide();
      this.current_step_index=0;

    }

  },
  computed:{
    currentStep(){

      // update current steps when the index is changed

      let current_step = null;

      this.steps
          .forEach((step)=>{
            if(this.current_step_index === step.index){
              current_step = step;
            }
          });

      return current_step;
    }
  }
}

</script>