export default function (axios) {
  return {
    list: () => {
      return axios.get('/services')
        .then(response => response.data);
    },
    get: (serviceId) => {
      return axios.get(`/services/${serviceId}`)
        .then(response => response.data);
    },

  }
}
