<template>

  <div>
    <b-modal size="xl"  id="SuppressionListsModal" ref="SuppressionListsModal" hide-footer
 class="custom-modal-content modal-xl-custom" title="Suppression Lists">

      <div class="custom-modal-content px-3">
      <!-- START FILTER OPTIONS ROW  -->
      <b-row class="mb-3" align-h="between">


        <!-- START Filter Input -->
        <b-col md="10">
          <b-form-group class="mb-0">
            <b-input-group>
              <b-form-input id="filter-input" v-model="filter" type="search"
                            placeholder="Type to Search"></b-form-input>

            </b-input-group>
          </b-form-group>
        </b-col>
        <!-- END Filter Input -->


        <b-col md="2" class="text-right">
          <button class="btn btn-primary" v-b-modal.add-suppression-wizard-modal>Add new</button>
        </b-col>


      </b-row>
      <!-- END FILTER OPTIONS ROW  -->

      <b-table borderless hover :items="suppression_lists" :fields="fields" :current-page="currentPage"
               :per-page="perPage" :filter="filter" :filter-included-fields="filterOn" :busy="loading.table"
               stacked="md" show-empty @filtered="onFiltered">

        <template #table-busy>
          <div class="text-center p-4 d-flex justify-content-center align-items-center">
            <b-spinner variant="primary"> </b-spinner>
            <span class="text-primary p-2"><small> Fetching Suppression lists, Please wait...</small></span>
          </div>
        </template>


        <template #cell(name)="row" class="w-100">

          <div class="row align-items-center">
            <div class="">
              <img :src="fetchPlatformImage(row.item.platform)"  width="40px" alt="">
            </div>
            <div class="col">
              <h4>{{ row.item.name }}</h4>
              <small class="text-muted">{{ row.item.type }} {{ row.item.list_id }}</small>
            </div>
          </div>



        </template>

        <template #cell(state)="row">

                        <span v-if="row.value === 1 " class="badge cursor-pointer badge-success badge-pill">
                         <i class="ri-check-line"></i> Active
                        </span>

                      <span v-if="row.value === 0  || row.value === 3" class="badge cursor-pointer badge-light badge-pill">
                         <i class="ri-loader-2-fill"></i> Processing
                        </span>

                      <span v-if="row.value === -3 " class="badge cursor-pointer badge-danger badge-pill">
                         <i class="ri-alert-line"></i> Error
                        </span>

                      <span v-if="row.value === -2 " class="badge cursor-pointer badge-danger badge-pill">
                         <i class="ri-close-line"></i> Deleted
                        </span>

        </template>

        <template #cell(actions)="row">
        <div class="d-flex justify-content-center " style="gap:5px;">

          <button
              class="btn btn-sm iq-bg-primary"
              @click="selected_list=row.item"
              v-b-modal.apply-suppression-source-modal
              v-b-tooltip.hover
              title="Apply to source">
            <i class="ri-links-line"></i>
          </button>

          <button
              class="btn iq-bg-info"
              v-b-tooltip.hover
              title="update suppression list"
              @click="updateSuppressionList(row.item)"
              style="padding: 0px 0px 0px 5px;">
            <i class="ri-pencil-line"></i>
          </button>

          <button
              class="btn btn-sm iq-bg-danger"
              v-b-tooltip.hover
              title="delete suppression list"
              @click="deleteSuppressionList(row.item)"
              style="padding: 0px 5px 0px 10px;">
            <i class="ri-delete-bin-7-line"></i>
          </button>

        </div>
        </template>
          <!-- Additional rows can be added here -->
      </b-table>

      <!-- START PAGINATION TABLE-->
      <b-row align-h="between">


        <!-- START Sow Option Input -->
        <b-col md="1">
          <b-form-group label-for="per-page-select" label-align-sm="left" class="mb-0">
            <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions"></b-form-select>
          </b-form-group>
        </b-col>
        <!-- END Sow Option Input -->

        <b-col md="3" class="my-1">
          <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill"
                        size="sm" class="my-0"></b-pagination>
        </b-col>
      </b-row>
      <!-- END PAGINATION TABLE-->

        <hr>
        <div class="text-right">
          <a class="btn btn-light" @click="$refs['SuppressionListsModal'].hide()">Cancel</a>
        </div>
      </div>
    </b-modal>


    <CreateSuppressionListWizardModal :account="account" @ListCreated="fetchExistingSuppressionLists(account)" ></CreateSuppressionListWizardModal>
    <ApplySuppressionListToSourceModal :account="account" :list="selected_list"></ApplySuppressionListToSourceModal>
    <EditSuppressionList :suppression_list="updated_suppression_list"></EditSuppressionList>
  </div>




</template>


<script>

import CreateSuppressionListWizardModal from '@/components/inboxgeek/modals/Wizards/SuppressionList/CreateSuppressionListWizardModal.vue'
import EditSuppressionList from '@/components/inboxgeek/modals/SuppressionLists/EditSuppressionList'
import ApplySuppressionListToSourceModal
  from "@/components/inboxgeek/modals/SuppressionLists/ApplySuppressionListToSourceModal";
import api from "@/api";

export default {

  name : 'ModalApplySuppressionList',
  props: ['source',"account"],
  components : {
   // SuppressionListField,
    ApplySuppressionListToSourceModal,
    CreateSuppressionListWizardModal,
    EditSuppressionList
  },
  mounted(){
    this.fetchExistingSuppressionLists( this.account);
  },
  data(){
    return {
      selected_list:null,
      updated_suppression_list:null,
      suppression_lists:[],
      error: false,
      error_message: 'an error accrued, try again later',
      loading:{
        table:false
      },
      fields: [
        {
          key: 'id',
          label: '#ID',
          sortable: true,
          sortDirection: 'asc',
          class: 'text-left',
        },
        {
          key: 'name',
          label: 'Name',
          sortable: true,
          sortDirection: 'desc',
          class: 'text-left',
          formatter: (value, key, item) => {
            return `${item.name}`;
          }

        },
        {
          key: 'state',
          label: 'State',
          sortable: true,
          sortDirection: 'desc',
          class: 'text-center'
        },
        {
          key: 'total_contacts',
          label: 'Total Contacts',
          sortable: true,
          sortDirection: 'desc',
          class: 'text-center'
        },
        {
          key: 'actions',
          label: 'Actions',
          sortable: false,
          class: 'text-center'

        },

      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 50, 100, {
        value: 100,
        text: "Show a lot"
      }],
      sortBy: 'created_at',
      sortDesc: true,
      sortDirection: 'desc',
      filter: null,
      filterOn: ["name"],

    }
  },
  methods : {

    fetchExistingSuppressionLists(account) {


      this.loading.table = true;
        api.suppressionList.index(account).then((response)=>{



          this.suppression_lists  = response.data;

          this.totalRows = this.suppression_lists.length
          this.currentPage = 1

          this.loading.table = false;

        }).catch((error)=>{
          this.loading.table = false;
        });

    },
    fetchPlatformImage(platform_name){
      try {
        return require(`@/assets/images/platforms/${platform_name.toLowerCase()}.png`)
      } catch (e) {
        return require(`@/assets/images/platforms/file-upload.svg`)
      }
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    updateSuppressionList(list){
      this.updated_suppression_list = list;
      this.$bvModal.show('edit-suppression-list-modal')
    },
    deleteSuppressionList(list){
      this.$swal({
        title: "Are you sure?",
        text: "You want to proceed with deleting this suppression list?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#f80a0a",
        cancelButtonColor: "#b1b2b7",
        confirmButtonText: "Yes, delete it!"
      }).then((result) => {
        if (result.isConfirmed) {

           api.suppressionList.delete(list.id).then((response)=>{

              if(response.success === true) {

                this.suppression_lists = this.suppression_lists.filter(filter_list=>{return filter_list.id !== list.id;});

              }

           });
        }
      });
    }
  },
  watch:{
    account(newValue,oldValue)  {
      this.fetchExistingSuppressionLists( newValue );
    }
  }

}



</script>

<style>
 #SuppressionListsModal .modal-xl{
  /* Set a custom width for the modal content */
  max-width: 90vw !important; /* Adjust the width as needed */
}
</style>