<template>
<b-container fluid>
    <b-row>
        <b-col lg="12">
            <iq-card body-class="p-0">
                <template v-slot:body>
                    <div class="iq-edit-list">
                        <tab-nav :pills="true" class="iq-edit-profile d-flex">
                            <tab-nav-items class="col-md-6 p-0" :active="true" href="#personal-information" title="Personal Information" />
                            <tab-nav-items class="col-md-6 p-0" :active="false" href="#chang-pwd" title=" Change Password" />
                        </tab-nav>
                    </div>
                </template>
            </iq-card>
        </b-col>
        <b-col lg="12">
            <div class="iq-edit-list-data">
                <tab-content id="pills-tabContent-2">
                    <tab-content-item :active="true" id="personal-information">
                        <iq-card>
                            <template v-slot:headerTitle>
                                <h4 class="card-title">Personal Information</h4>
                            </template>
                            <template v-slot:body>
                                <b-row>
                                    <b-form-group class="col-md-12">
                                        <div class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color">
                                            <div class="custom-switch-inner">
                                                <label class="mr-2">Status</label>
                                                <input type="checkbox" class="custom-control-input bg-primary" v-model="form.status" id="customSwitch-13">
                                                <label class="custom-control-label" for="customSwitch-13" data-on-label="On" data-off-label="Off">
                                                </label>
                                            </div>
                                        </div>
                                    </b-form-group>
                                </b-row>
                                <div class="new-user-info">
                                    <b-form @submit="update">
                                        <b-row>
                                            <b-form-group class="col-md-6" label="Name: *" label-for="name">
                                                <b-form-input id="name" placeholder="Enter Name" v-model="form.name" required></b-form-input>
                                                <div class="text-danger" id="full_name">{{errors.full_name}}</div>
                                            </b-form-group>
                                            <b-form-group class="col-md-6" label="Email: *" label-for="email">
                                                <b-form-input type="email" id="email" placeholder="Email" v-model="form.email" required>
                                                </b-form-input>
                                                <div class="text-danger" id="email_address">{{errors.email_address}}</div>
                                            </b-form-group>
                                            <b-form-group class="col-sm-12" label="Organization" label-for="Organization">
                                                <b-form-input type="text" id="account" placeholder="Enter Account" v-model="form.account" required>
                                                </b-form-input>
                                                <div class="text-danger" id="organization">{{errors.organization}}</div>
                                            </b-form-group>

                                            <b-form-group class="col-sm-12" label-for="selectcountry" label="Role:">

                                                <multiselect v-model="form.roles" tag-placeholder="Add this as new role" placeholder="Select platform" label="name" track-by="code" :options="roleo" :multiple="true" :taggable="true"></multiselect>
                                                <div class="text-danger" id="roles">{{errors.roles}}</div>

                                            </b-form-group>
                                            <b-form-group v-if="canManageAccount" class="col-sm-12" label-for="Accounts"
                                                    label="Accounts:">
                                                <multiselect v-model="form.accounts" tag-placeholder="Add as new Accounts"
                                                    placeholder="Select Accounts" label="name" track-by="code"
                                                    :options="accounts" :multiple="true" :taggable="true" :disabled="!isAdmin">
                                                </multiselect>
                                            </b-form-group>
                                            <b-col sm="12" lg="12">
                                                <div class="form-group">
                                                    <label for="address">Address</label>
                                                    <b-form-textarea id="address" placeholder="Enter Address" rows="6" v-model="form.address">
                                                    </b-form-textarea>
                                                </div>
                                            </b-col>
                                        </b-row>
                                        <b-button variant="primary" type="submit" :disabled="onsubmit">
                                            Edit user
                                            <b-spinner small type="grow" v-show="onsubmit"></b-spinner>
                                        </b-button>
                                    </b-form>
                                </div>
                            </template>
                        </iq-card>
                    </tab-content-item>
                    <tab-content-item :active="false" id="chang-pwd">
                        <iq-card>
                            <template v-slot:headerTitle>
                                <h4 class="card-title">Change Password</h4>
                            </template>
                            <template v-slot:body>
                                <b-form @submit="reset">
                                    <b-form-group>
                                        <label for="npass">New Password:</label>
                                        <b-form-input type="password" id="npass" placeholder="Password" v-model="pass.password" required>
                                        </b-form-input>
                                        <div class="text-danger" id="password">{{errors.password}}</div>
                                    </b-form-group>
                                    <b-form-group>
                                        <label for="vpass">Verify Password:</label>
                                        <b-form-input type="password" id="vpass" placeholder="Repeat Password" v-model="pass.password_confirmation" required></b-form-input>
                                        <div class="text-danger" id="password">{{errors.repeat_password}}</div>
                                    </b-form-group>
                                    <b-button variant="primary" type="submit" :disabled="change">
                                        Change password
                                        <b-spinner small type="grow" v-show="change"></b-spinner>
                                    </b-button>
                                </b-form>
                            </template>
                        </iq-card>
                    </tab-content-item>
                </tab-content>
            </div>
        </b-col>
    </b-row>
</b-container>
</template>

<script>
import {
    sofbox
} from '../../config/pluginInit'
import Multiselect from "vue-multiselect"
import api from '@/api/RestClient';
import {
    helper
} from '@/helpers'
export default {
    name: 'ProfileEdit',
    components: {
        Multiselect,
    },
    mounted() {
        sofbox.index()
        this.getRoles();
        this.setUserId();
        this.getUser();
    },
    data() {
        return {
            onsubmit: false,
            change: false,
            user_id: null,
            form: {
                name: null,
                email: null,
                account: null,
                roles: null,
                address: null,
                status: 0,
                accounts: []
            },
            pass: {
                password: null,
                password_confirmation: null,
            },
            roles: [],
            accounts: [],
            errors: {},
            canManageAccount: false
        }
    },
    methods: {
        update(e) {
            e.preventDefault();
            this.checkUpdateForm();
            if (helper.isEmpty(this.errors)) {
                if (this.user_id) {
                    this.onsubmit = true;
                    const user_id = this.user_id;
                    const data = this.form;
                    api.users.put(user_id, data).then(response => {
                        this.onsubmit = false;
                        let variant = 'danger';
                        let message = 'Something went wrong'
                        if ('error' in response) {
                            const keys = Object.keys(response.error);
                            keys.forEach(key => {
                                message = response.error[key][0];
                                this.toast('User', variant, message);
                            });
                        }
                        if ('success' in response && response.success) {
                            variant = 'success';
                            message = response.message;
                            this.toast('User', variant, message);
                        }
                    }).catch(err => {
                        this.toast('Warning', 'danger', 'Something went wrong');
                        console.log(err);
                    }).finally(() => {
                        this.onsubmit = false;
                    })
                } else {
                    this.toast('Warning', 'danger', 'Ensure user exists please');
                }
            }
        },
        reset(e) {
            e.preventDefault();
            this.checkPassWordForm();
            if (helper.isEmpty(this.errors)) {
                if (this.user_id) {
                    this.change = true;
                    const user_id = this.user_id;
                    const data = this.pass;
                    api.auth.adminResetPass(user_id, data).then(response => {
                            this.change = false;
                            let variant = 'danger';
                            let message = 'Something went wrong'
                            if ('error' in response) {
                                const keys = Object.keys(response.error);
                                keys.forEach(key => {
                                    message = response.error[key][0];
                                    this.toast('Password', variant, message);
                                });
                            }
                            if ('success' in response && response.success) {
                                variant = 'success';
                                message = response.message;
                                this.toast('Password', variant, message);
                            }
                        }).catch(err => {
                            this.toast('Success!', 'success', response.message)
                        }).catch(err => {
                            this.toast('Oh!', 'danger', 'Something went wrong')
                            console.log(err);
                        })
                        .finally(() => {
                            this.change = false;
                        })
                } else {
                    this.toast('Warning', 'danger', 'Ensure user exists please');
                }
            }
        },
        getRoles() {
            api.roles.all().then(response => {
                this.roles = _.filter(response.data, function (r) {
                    return { code: r.id, name: r.name };
                });
                console.log(this.roles);
            }).catch(err => {
                console.log(err);
            }).finally(() => {
                // here code ...
            })
        },
        getUser() {
            if (this.user_id) {
                api.users.get(this.user_id).then(response => {
                    let user = response.data;
                    this.form.name = user.name;
                    this.form.email = user.email;
                    this.form.account = user.account.name;
                    this.form.address = user?.profile?.address;
                    this.form.status = user.status;
                    let roles = [];
                    user.roles.forEach(role => {
                        roles.push({
                            code: role.id,
                            name: role.name
                        })
                    });
                    this.form.roles = roles;
                }).catch(err => {
                    this.toast('Warning', 'danger', 'Something went wrong');
                }).finally(() => {
                    // here code ...
                })
            } else {
                this.toast('Warning', 'danger', 'Ensure user exists please');
            }

        },
        setUserId() {
            this.user_id = this.$route.query.id;
        },
        resetErrors() {
            this.errors = {}
        },
        checkUpdateForm() {
            this.resetErrors();
            if (!this.form.name) {
                this.errors.full_name = 'Name required.';
            }
            if (!this.form.email) {
                this.errors.email_address = 'Email required.';
            }
            if (!this.form.account) {
                this.errors.organization = 'Organizarion required.';
            }
            if (this.form.roles.length == 0) {
                this.errors.roles = 'Role(s) requiered';
            }
            if (!helper.validMail(this.form.email)) {
                this.errors.email_address = 'Email format did not matched.';
            }
        },
        checkPassWordForm() {
            this.resetErrors();
            if (!this.pass.password) {
                this.errors.password = 'Password required.';
            }
            if (!this.pass.password_confirmation) {
                this.errors.repeat_password = 'Repeat password required.';
            }
            if (!helper.equal(this.pass.password, this.pass.password_confirmation)) {
                this.errors.repeat_password = 'Password did not matched';
            }
            if (helper.checkStringLegth(this.pass.password, 6)) {
                this.errors.password = 'Password must be at least 6 characters';
            }
        },
        toast(title = "success", variant = "success", message = "Account chargerd") {
            this.$bvToast.toast(message, {
                title: title,
                variant: variant
            })
        }
    },
    watch: {
        'form.roles': {
            handler(oldVal, newVal) {
                console.log(newVal);
            }
        }
    }
}
</script>
