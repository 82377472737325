<template>

  <div>
    
     <b-row>
                  <b-col>

                    <b-row v-show="false">
                      <b-col>
                        <b-form-group label="Account" label-for="account_id">

                          <div ref="auth2AccountsGroup" >
 
                              <multiselect  v-model="auth2.platform" tag-placeholder="Empty"
                                  deselect-label="Can't remove this value"  label="name"
                                  :custom-label="({ name, code }) => `${name} - [${code}]`" track-by="code"
                                  :options="auth2.platforms" :multiple="false" :disabled="false" :taggable="true"
                                  @select="selectPlatformConfig($event)">
                              </multiselect>

                          </div>
  
                          <small id="alertAccountId" class="text-danger d-none"></small>
                      </b-form-group>
  
                      </b-col>
                      <b-col>
                        <b-form-group label="Mailchimp user">
                        
                          <!-- <b-form-input v-if="false" id="platform" type="text" v-model="form.accountid"
                              placeholder="Accounts" :disabled="false"
                              @change="handleOnChangeAccountID($event)" /> -->
  
                          <div ref="auth2AccountsGroup" >

                            <multiselect v-model="form.oAccountID" tag-placeholder="Empty"
                                deselect-label="Can't remove this value" placeholder="accountPlaceholder" label="name"
                                :custom-label="({ name, code }) => `${name} - [${code}]`" track-by="code"
                                :options="auth2.listAccounts" :multiple="false" :disabled="false" :taggable="true">
                            </multiselect>
                          </div>
  
                          <small id="alertAccountId" class="text-danger d-none"></small>
                      </b-form-group>
                      </b-col>
                    </b-row>
                     
                      <b-button name="auth2Btn" variant="primary" ref="auth2Btn" :disabled="!isAllowed" id="auth2Btn" class="auth2 action-button float-right"
                          :data-action="auth2.state"
                          @click="handleOauth($event)">
                          <b-spinner small type="grow" v-if="auth2.inProgress"></b-spinner>
                          Add New Mailchimp Account
                      </b-button>
                  </b-col>
    </b-row>
  
  </div>
  
  
  </template>
  
  <script>
  import Multiselect from 'vue-multiselect'
  import api from '@/api/RestClient';
  
  
  
    export default {
      name: 'MailchimpInput',
      components: {
        Multiselect
      },
      props:['account','platform_nickname'],
      mounted(){
      },
      
      data(){
        return {
          form : {
  
            platform : null,
            oAccountID:null
          },
          auth2 : {
            listAccounts:[],
            platforms: [],
            state : '',
            inProgress:false,
  
          },
          platform : {
            id:null
          },
          auth_url : null
        } 
      },
      methods : {
      
        handleOauth(){
  
  
          this.auth2.inProgress   = true;
       
              let platform_id = null;
              let auth2ServiceApi = null;
              var swalObj = {
                  text: 'You will be redirected to the authentication page. Click "Ok" to continue',
                  type: 'info',
                  showCancelButton: true,
                  confirmButtonText: 'Ok',
                  cancelButtonText: 'Cancel!',
                  showCloseButton: true,
                  showLoaderOnConfirm: true
              };
  
              this.auth2.state = '';

              swalObj.title = `InboxGeek Mailchimp Integration`
      
              auth2ServiceApi =   api.default
                                  .get(`/platforms/mailchimp/oauth/authorize${(platform_id ? '?platform_id=' + platform_id : '')}`)
                                  .then(response => response.data);

              auth2ServiceApi.then((result)=> {


                this.auth_url =  result.url;
                this.auth_params = result.params;

                localStorage.setItem('oauth.install', this.auth_params);
                
              })
              .catch( (error) =>{

                      this.$swal({
                          title: swalObj.title,
                          text: 'An error has occurred. Please try again',
                          type: 'warning',
                          confirmButtonText: 'Ok'
                      })

                      this.auth2.inProgress   = false;
              })
              .finally(() => {

                    this.$swal(swalObj).then((result) => {

                        if (result.value) {

                          this.showWindowOauth(result)

                        } else{

                          this.auth2.inProgress   = false;
                        }
             
                                  
                    });
              })
              
        },
        showWindowOauth(result) {

              let fetched_data = false;
              if (!result.value) {
                
                  this.$bvToast.toast('Your Integration process has been cancel', {
                      title: 'Warning',
                      variant: 'warning'
                  });

                  this.auth2.inProgress   = false;

              }

              let oauthWindow = window.open(this.auth_url, 'oauth', this.OauthWindowFeatures());

              window.addEventListener('message', (event) => {
                fetched_data = true;
                // Ensure the message is from the OAuth window
                if (event.source === oauthWindow) {
                  const data = event.data;

                  let _params = data.data;
                   _params.account = this.account
                   _params.nickname = this.platform_nickname

                  if(_params.action === 'mailchimp_integration') {


                    api.default.post(`/platforms/mailchimp/oauth/access_token`, _params).then( (result) => {

                      this.$emit('OauthPlatformAdded',{id: result.data.platform_id});

                    });


                  } else {

                    this.$bvToast.toast('Please authenticate to complete your integration', {
                      title: 'Warning', variant: 'warning'
                    });

                    this.auth2.inProgress   = false;

                  }
                }
              });


          const interval = setInterval(() => {

            if (oauthWindow.closed && !fetched_data ) {
              this.$bvToast.toast('Please authenticate to complete your integration', {
                title: 'Warning', variant: 'warning'
              });
              this.auth2.inProgress   = false;

              clearInterval(interval);
            }

            if(fetched_data){
              clearInterval(interval);
            }
          }, 500);

        },
        OauthWindowFeatures(w =800 ,h = 600 ){

          const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
          const dualScreenTop  = window.screenTop !== undefined  ? window.screenTop  : window.screenY;

          const width  = window.innerWidth  ? window.innerWidth  : document.documentElement.clientWidth  ? document.documentElement.clientWidth  : screen.width;
          const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

          const systemZoom = width / window.screen.availWidth;
          const left       = (width - w) / 2 / systemZoom  + dualScreenLeft;
          const top        = (height - h) / 2 / systemZoom + dualScreenTop;

          return `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=${w / systemZoom},height=${h / systemZoom},left=${left},top=${top}`;

        }
      },
        computed : {
            isAllowed(){
                return this.platform_nickname != null && this.platform_nickname.trim() != '' && ! this.auth2.inProgress ; 
            }
        }
    }
  
  </script>