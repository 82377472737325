<template>
    <fieldset>
        <h3 class="text-left mb-4">{{ title }} </h3>


        <b-row class="text-left">


            <b-form-group class="col-md-12" :class="platform_codes.WEBHOOK == form.platform_code ? 'd-none' : ''"
                :label="title" label-for="list">
                <div v-if="![platform_codes.INBOXSUITE, platform_codes.WEBHOOK].includes(form.platform_code)">
                    <multiselect v-model="list" tag-placeholder="Empty" :placeholder="listPlaceholder" label="name"
                        track-by="code" :options="formatLists" :multiple="false" return="code" :allow-empty="true"
                        :taggable="false" :disabled="listsDisabled">


                        <template slot="option" slot-scope="props">
                            <div class="option__desc">

                                <span class="option__title">

                                    <span v-if="props.option.type">

                                        <i v-if="props.option.type == 'segment'" class="ri-flashlight-line"
                                            style="color:#5ad"></i>
                                        <i v-else class="ri-list-check color-primary" style="color:#5ad"></i>

                                    </span>

                                    <span v-else><i class="ri-list-check color-primary" style="color:#5ad"></i></span>

                                    <span style="font-wight:bold;"> {{ props.option.name }} </span>

                                    <span style="font-size:11px; color:#888"> {{ props.option.code }}</span>


                                </span>

                            </div>

                        </template>


                    </multiselect>

                    <small v-if="errors.lists" ref="errors_lists" class="text-danger"
                        :class="{ 'd-none': !errors.lists }">{{
                            errors.lists
                        }}</small>
                </div>
                <div v-else-if="platform_codes.INBOXSUITE == form.platform_code">
                    <b-form-input id="input-list" type="text" placeholder="source url" value="inboxgeek.com"
                        @input="inputList" required></b-form-input>
                </div>

            </b-form-group>

            <!-- WEBHOOK Custom Fields -->
            <b-form-group v-if="platform_codes.WEBHOOK == form.platform_code" class="col-md-12" label="WebHook Link"
                label-for="list_link">
                <div>
                    <b-form-input type="text" placeholder="WebHook Link" v-model="webhook.link" @change="inputListWebHook"
                        required></b-form-input>
                </div>
                <small v-if="errors.list_link" ref="errors_lists" class="text-danger"
                    :class="{ 'd-none': !errors.list_link }">{{
                        errors.list_link
                    }}</small>
            </b-form-group>
            <b-form-group v-if="platform_codes.WEBHOOK == form.platform_code" class="col-md-12" label="Reception List Name"
                label-for="list_name">
                <div>
                    <b-form-input type="text" placeholder="List Name" v-model="webhook.name" @change="inputListWebHook"
                        required></b-form-input>
                </div>
                <small v-if="errors.list_name" ref="errors_list_name" class="text-danger"
                    :class="{ 'd-none': !errors.list_name }">
                    {{ errors.list_name }}
                </small>
            </b-form-group>
            <b-form-group v-if="platform_codes.WEBHOOK == form.platform_code" class="col-md-12" label="Reception List ID"
                label-for="list_id">

                <b-input-group>
                    <b-form-input id="input-list" type="text" placeholder="List ID" v-model="webhook.listID" required
                        readonly></b-form-input>

                    <b-input-group-append v-if="webhook.listID" class="cursor-pointer">
                        <b-input-group-text>
                            <i class="ri-clipboard-line" @click.prevent="clickboardCopy(webhook.listID)"></i>
                        </b-input-group-text>
                    </b-input-group-append>
                </b-input-group>

                <!-- <small v-if="errors.list_name" ref="errors_list_name" class="text-danger"
                    :class="{ 'd-none': !errors.list_name }">
                    {{ errors.list_name }}
                </small> -->
            </b-form-group>
            <!-- END WEBHOOK Custom Fields -->


        </b-row>

    </fieldset>
</template>
<script>
import Multiselect from 'vue-multiselect'
import { services } from '@/helpers';
import { PLATFORMS_CODE } from "@/constantes";

export default {
    name: "PartialIntegration",
    props: ['form', 'platform', 'errors'],
    components: {
        Multiselect
    },
    data() {
        return {
            platform_codes: PLATFORMS_CODE,
            addNewInteg: true,
            listLabel: 'List: *',
            listPlaceholder: 'Select Lists',
            listsDisabled: false,
            list: null,
            lists: [],
            title: 'Select Your Reception List:',
            webhook: { listID: '', name: '', link: '' }
        }
    },
    mounted() {
        this.list = { "code": this.form.list ? this.form.list.code : null, "name": this.form.list ? this.form.list.name : null };
        this.showErrors();
        this.setWebHookList();

        if (this.form.isListOfTags) {
            this.title = 'Select Your Tag:';
            this.listPlaceholder = 'Select Tag';
        }
        
        if (!this.webhook.listID && this.platform_codes.WEBHOOK == this.form.platform_code) this.webhook.listID = this.create_UUID;

    },
    computed: {
        formatLists() {

            let platformName = typeof this.form.platform == 'number' && this.form.platform_recorded?.name
                ? this.form.platform_recorded.name.toLowerCase()
                : this.form.platform?.name ? this.form.platform.name.toLowerCase() : '--';

            if (typeof platformName === "undefined" || !platformName || this.form.lists.length < 0) return [];

            var servicePlatform = null

            const defaultService = services.default


            let strId = 'id';
            let strName = 'name'

            if ((typeof services[platformName] !== "undefined")) servicePlatform = services[platformName]
            if (servicePlatform?.labels?.listID && servicePlatform?.maps?.listsMap) strId = servicePlatform.labels.listID
            if (servicePlatform?.labels?.listName) strName = servicePlatform.labels.listName

            if (servicePlatform?.labels?.tagID && servicePlatform?.maps?.tagsMap) strId = servicePlatform.labels.tagID
            if (servicePlatform?.labels?.tagName) strName = servicePlatform.labels.tagName

            let data = _.map(this.form.lists, (obj, key) => {
                let item = {}

                if (typeof servicePlatform?.maps?.listsMap === "function" ||
                    typeof servicePlatform?.maps?.tagsMap === "function") {
                    item = ['ontraport', 'keap'].includes(platformName) ? servicePlatform.maps.tagsMap(obj) : servicePlatform.maps.listsMap(obj)
                } else {

                    item = ['ontraport', 'keap'].includes(platformName) ? defaultService.maps.tagsMap(obj, strId, strName) : defaultService.maps.listsMap(obj, strId, strName)
                }
                return item
            });

            return data
        },
        create_UUID() {
            let dt = new Date().getTime();
            let uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                let r = (dt + Math.random() * 16) % 16 | 0;
                dt = Math.floor(dt / 16);
                return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
            });
            return uuid;
        }
    },
    methods: {
        showErrors() {
            const that = this
            _.forOwn(that.errors, function (error, key) {
                const ref = `errors_${key}`
                if (typeof that.$refs[ref] !== 'undefined') that.$refs[ref].classList.remove('d-none')
            });
        },
        resetErrors() {
            const that = this
            _.forOwn(this.errors, function (error, key) {
                const ref = `errors_${key}`
                if (typeof that.$refs[ref] !== 'undefined') that.$refs[ref].classList.add('d-none')
            });
        },
        inputList(input) {
            this.list = {
                code: input,
                name: input
            }
        },
        inputListWebHook(input) {

            let listID = this.create_UUID;
            if (this.form.lists.length && this.platform_codes.WEBHOOK == this.form.platform_code) {
                let receptionList = this.form.lists[0];
                if (receptionList.p_list_id) listID = receptionList.p_list_id;
            }


            if (this.platform_codes.WEBHOOK != this.form.platform_code) return;

            if (!this.webhook.listID && this.platform_codes.WEBHOOK == this.form.platform_code) this.webhook.listID = listID;

            this.list = {
                code: this.webhook.listID, // replace By uuid
                name: this.webhook.name,
                link: this.webhook.link,
            }
        },
        setReceptionList(list = null) {

            if (!list) {
                this.list = null;
                return;
            }

            const that = this
            let receptionList = _.find(this.formatLists, function (o) { return list.p_list_id == o.code; });

            if (receptionList) {
                this.list = receptionList;
            }
        },
        setWebHookList() {

            if (this.platform_codes.WEBHOOK == this.form.platform_code && !this.form.list) {
                if (this.form.lists.length) {
                    let receptionList = this.form.lists[0];
                    let link = JSON.parse(receptionList.custom_columns) ? JSON.parse(receptionList.custom_columns).link : null;

                    this.webhook = {
                        listID: receptionList.p_list_id ?? this.create_UUID,
                        name: receptionList.list_name,
                        link: link
                    }
                    this.inputListWebHook();

                }
            } else if (this.form.list) {
                this.webhook = {
                    listID: this.form.list.code ?? this.create_UUID,
                    name: this.form.list.name,
                    link: this.form.list.link
                }
            }
        },
        clickboardCopy(text) {
            navigator.clipboard.writeText(text)
            this.$bvToast.toast('Reception List ID Copied', {
                title: 'Copy Reception List ID',
                variant: 'info'
            })
        }
    },
    watch: {
        "errors": {
            handler(newVal, oldVal) {
                this.showErrors()
            },
            deep: true
        },
        "list": {
            handler(newVal, oldVal) {
                this.$emit('updateList', newVal)
            },
            deep: true
        },
    }
};
</script>
