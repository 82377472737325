export default function (axios) {
  return {

    list: (account_id) => {
      return axios.get(`accounts/${account_id}/balances`).then(response => response.data);
    },

    update: (account_id, balance_id, data) => {
      return axios.put(`accounts/${account_id}/balances/${balance_id}/overconsumption-limit`, data)
        .then(response => response.data);
    },

    add: (account_id, balance_id, data) => {
      return axios.put(`accounts/${account_id}/balances/${balance_id}/add`, data)
        .then(response => response.data);
    },

    remove: (account_id, balance_id, data) => {
      return axios.put(`accounts/${account_id}/balances/${balance_id}/remove`, data)
        .then(response => response.data);
    },

    logs: (account_id, service_id) => {

      return axios.get(`accounts/${account_id}/balances/logs?service_id=${service_id}`).then(response => response.data);
    },

    updateLimit: (data) => { // client request 
      return axios.put(`/balances/limit`, data)
        .then(response => response.data);
    },
  }
}